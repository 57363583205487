import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  bigint: any;
  date: any;
  float8: any;
  interval: any;
  jsonb: any;
  numeric: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};

export type AnswerResponse = {
  __typename?: 'AnswerResponse';
  answerId: Scalars['String'];
  customer_answer?: Maybe<Customer_Answer>;
  oneTimeToken?: Maybe<Scalars['String']>;
};

export type BooleanFilter = {
  /** Checks if the value is equal to the given input. */
  eq?: InputMaybe<Scalars['Boolean']>;
  /** Checks if the value is not equal to the given input. */
  neq?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type Chapter = {
  __typename?: 'Chapter';
  _key?: Maybe<Scalars['String']>;
  _type?: Maybe<Scalars['String']>;
  cloudflare_video?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_i18n?: Maybe<LocaleString>;
  question?: Maybe<Question>;
  /** Soviele Minuten braucht man um das Kapitel fertigzustellen. */
  requiredMinutes?: Maybe<Scalars['Float']>;
  textRaw?: Maybe<Scalars['JSON']>;
  text_i18n?: Maybe<LocaleBlock>;
  title?: Maybe<Scalars['String']>;
  title_i18n?: Maybe<LocaleString>;
  video_i18n?: Maybe<LocaleVideo>;
};

export type Choice = {
  __typename?: 'Choice';
  _key?: Maybe<Scalars['String']>;
  _type?: Maybe<Scalars['String']>;
  isCorrect?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_i18n?: Maybe<LocaleString>;
};

export type CommissionResponse = {
  __typename?: 'CommissionResponse';
  success: Scalars['Boolean'];
};

export type ContactPoint = Document & {
  __typename?: 'ContactPoint';
  /** Date the document was created */
  _createdAt: Scalars['DateTime'];
  /** Document ID */
  _id: Scalars['ID'];
  _key?: Maybe<Scalars['String']>;
  /** Current document revision */
  _rev: Scalars['String'];
  /** Document type */
  _type: Scalars['String'];
  /** Date the document was last modified */
  _updatedAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  description_i18n?: Maybe<LocaleString>;
  /** Abschlusstests sind optional. Falls es für die Kontaktstelle kein Zertifikat gibt, bitte leer lassen. */
  finalQuiz?: Maybe<Quiz>;
  image?: Maybe<Image>;
  lections?: Maybe<Array<Maybe<Lection>>>;
  /** Fähigkeit, die nach Abschluss dieser Kontaktstelle im Zertifikat gelistet wird. Falls die Kontaktstelle kein Zertifikat hat, bitte leer lassen. */
  skillName?: Maybe<Scalars['String']>;
  skillName_i18n?: Maybe<LocaleString>;
  title?: Maybe<Scalars['String']>;
  title_i18n?: Maybe<LocaleString>;
};

export type ContactPointFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  description?: InputMaybe<StringFilter>;
  description_i18n?: InputMaybe<LocaleStringFilter>;
  finalQuiz?: InputMaybe<QuizFilter>;
  image?: InputMaybe<ImageFilter>;
  skillName?: InputMaybe<StringFilter>;
  skillName_i18n?: InputMaybe<LocaleStringFilter>;
  title?: InputMaybe<StringFilter>;
  title_i18n?: InputMaybe<LocaleStringFilter>;
};

export type ContactPointSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  description_i18n?: InputMaybe<LocaleStringSorting>;
  image?: InputMaybe<ImageSorting>;
  skillName?: InputMaybe<SortOrder>;
  skillName_i18n?: InputMaybe<LocaleStringSorting>;
  title?: InputMaybe<SortOrder>;
  title_i18n?: InputMaybe<LocaleStringSorting>;
};

export type DatetimeFilter = {
  /** Checks if the value is equal to the given input. */
  eq?: InputMaybe<Scalars['DateTime']>;
  /** Checks if the value is greater than the given input. */
  gt?: InputMaybe<Scalars['DateTime']>;
  /** Checks if the value is greater than or equal to the given input. */
  gte?: InputMaybe<Scalars['DateTime']>;
  /** Checks if the value is lesser than the given input. */
  lt?: InputMaybe<Scalars['DateTime']>;
  /** Checks if the value is lesser than or equal to the given input. */
  lte?: InputMaybe<Scalars['DateTime']>;
  /** Checks if the value is not equal to the given input. */
  neq?: InputMaybe<Scalars['DateTime']>;
};

/** A Sanity document */
export type Document = {
  /** Date the document was created */
  _createdAt: Scalars['DateTime'];
  /** Document ID */
  _id: Scalars['ID'];
  /** Current document revision */
  _rev: Scalars['String'];
  /** Document type */
  _type: Scalars['String'];
  /** Date the document was last modified */
  _updatedAt: Scalars['DateTime'];
};

export type DocumentFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
};

export type DocumentSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
};

export type EmailRecipient = {
  emailAddress: Scalars['String'];
  name: Scalars['String'];
};

export type EmailResult = {
  __typename?: 'EmailResult';
  success: Scalars['Boolean'];
};

export type EvaluateResponse = {
  __typename?: 'EvaluateResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type FloatFilter = {
  /** Checks if the value is equal to the given input. */
  eq?: InputMaybe<Scalars['Float']>;
  /** Checks if the value is greater than the given input. */
  gt?: InputMaybe<Scalars['Float']>;
  /** Checks if the value is greater than or equal to the given input. */
  gte?: InputMaybe<Scalars['Float']>;
  /** Checks if the value is lesser than the given input. */
  lt?: InputMaybe<Scalars['Float']>;
  /** Checks if the value is lesser than or equal to the given input. */
  lte?: InputMaybe<Scalars['Float']>;
  /** Checks if the value is not equal to the given input. */
  neq?: InputMaybe<Scalars['Float']>;
};

export type Geopoint = {
  __typename?: 'Geopoint';
  _key?: Maybe<Scalars['String']>;
  _type?: Maybe<Scalars['String']>;
  alt?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type GeopointFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  alt?: InputMaybe<FloatFilter>;
  lat?: InputMaybe<FloatFilter>;
  lng?: InputMaybe<FloatFilter>;
};

export type GeopointSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  alt?: InputMaybe<SortOrder>;
  lat?: InputMaybe<SortOrder>;
  lng?: InputMaybe<SortOrder>;
};

export type IdFilter = {
  /** Checks if the value is equal to the given input. */
  eq?: InputMaybe<Scalars['ID']>;
  in?: InputMaybe<Array<Scalars['ID']>>;
  /** Checks if the value matches the given word/words. */
  matches?: InputMaybe<Scalars['ID']>;
  /** Checks if the value is not equal to the given input. */
  neq?: InputMaybe<Scalars['ID']>;
  nin?: InputMaybe<Array<Scalars['ID']>>;
};

export type Image = {
  __typename?: 'Image';
  _key?: Maybe<Scalars['String']>;
  _type?: Maybe<Scalars['String']>;
  asset?: Maybe<SanityImageAsset>;
  crop?: Maybe<SanityImageCrop>;
  hotspot?: Maybe<SanityImageHotspot>;
};

export type ImageFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  asset?: InputMaybe<SanityImageAssetFilter>;
  crop?: InputMaybe<SanityImageCropFilter>;
  hotspot?: InputMaybe<SanityImageHotspotFilter>;
};

export type ImageSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  crop?: InputMaybe<SanityImageCropSorting>;
  hotspot?: InputMaybe<SanityImageHotspotSorting>;
};

export type InsertRegistrationOutput = {
  __typename?: 'InsertRegistrationOutput';
  vendorId: Scalars['Int'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type Lection = Document & {
  __typename?: 'Lection';
  /** Date the document was created */
  _createdAt: Scalars['DateTime'];
  /** Document ID */
  _id: Scalars['ID'];
  _key?: Maybe<Scalars['String']>;
  /** Current document revision */
  _rev: Scalars['String'];
  /** Document type */
  _type: Scalars['String'];
  /** Date the document was last modified */
  _updatedAt: Scalars['DateTime'];
  chapters?: Maybe<Array<Maybe<Chapter>>>;
  image?: Maybe<Image>;
  requirements?: Maybe<Array<Maybe<Lection>>>;
  title?: Maybe<Scalars['String']>;
  title_i18n?: Maybe<LocaleString>;
};

export type LectionFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  image?: InputMaybe<ImageFilter>;
  title?: InputMaybe<StringFilter>;
  title_i18n?: InputMaybe<LocaleStringFilter>;
};

export type LectionSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  image?: InputMaybe<ImageSorting>;
  title?: InputMaybe<SortOrder>;
  title_i18n?: InputMaybe<LocaleStringSorting>;
};

export type Level = Document & {
  __typename?: 'Level';
  /** Date the document was created */
  _createdAt: Scalars['DateTime'];
  /** Document ID */
  _id: Scalars['ID'];
  _key?: Maybe<Scalars['String']>;
  /** Current document revision */
  _rev: Scalars['String'];
  /** Document type */
  _type: Scalars['String'];
  /** Date the document was last modified */
  _updatedAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  description_i18n?: Maybe<LocaleString>;
  requiredPoints?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  title_i18n?: Maybe<LocaleString>;
  voucherType?: Maybe<Scalars['String']>;
};

export type LevelFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  description?: InputMaybe<StringFilter>;
  description_i18n?: InputMaybe<LocaleStringFilter>;
  requiredPoints?: InputMaybe<FloatFilter>;
  title?: InputMaybe<StringFilter>;
  title_i18n?: InputMaybe<LocaleStringFilter>;
  voucherType?: InputMaybe<StringFilter>;
};

export type LevelSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  description_i18n?: InputMaybe<LocaleStringSorting>;
  requiredPoints?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  title_i18n?: InputMaybe<LocaleStringSorting>;
  voucherType?: InputMaybe<SortOrder>;
};

export type LocaleBlock = {
  __typename?: 'LocaleBlock';
  _key?: Maybe<Scalars['String']>;
  _type?: Maybe<Scalars['String']>;
  deRaw?: Maybe<Scalars['JSON']>;
  enRaw?: Maybe<Scalars['JSON']>;
  nlRaw?: Maybe<Scalars['JSON']>;
};

export type LocaleBlockFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
};

export type LocaleBlockSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
};

export type LocaleString = {
  __typename?: 'LocaleString';
  _key?: Maybe<Scalars['String']>;
  _type?: Maybe<Scalars['String']>;
  de?: Maybe<Scalars['String']>;
  en?: Maybe<Scalars['String']>;
  nl?: Maybe<Scalars['String']>;
};

export type LocaleStringFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  de?: InputMaybe<StringFilter>;
  en?: InputMaybe<StringFilter>;
  nl?: InputMaybe<StringFilter>;
};

export type LocaleStringSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  de?: InputMaybe<SortOrder>;
  en?: InputMaybe<SortOrder>;
  nl?: InputMaybe<SortOrder>;
};

export type LocaleVideo = {
  __typename?: 'LocaleVideo';
  _key?: Maybe<Scalars['String']>;
  _type?: Maybe<Scalars['String']>;
  de?: Maybe<Scalars['String']>;
  en?: Maybe<Scalars['String']>;
  nl?: Maybe<Scalars['String']>;
};

export type MediaTag = Document & {
  __typename?: 'MediaTag';
  /** Date the document was created */
  _createdAt: Scalars['DateTime'];
  /** Document ID */
  _id: Scalars['ID'];
  _key?: Maybe<Scalars['String']>;
  /** Current document revision */
  _rev: Scalars['String'];
  /** Document type */
  _type: Scalars['String'];
  /** Date the document was last modified */
  _updatedAt: Scalars['DateTime'];
  name?: Maybe<Slug>;
};

export type MediaTagFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  name?: InputMaybe<SlugFilter>;
};

export type MediaTagSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  name?: InputMaybe<SlugSorting>;
};

export type OneTimePasswordResponse = {
  __typename?: 'OneTimePasswordResponse';
  success: Scalars['Boolean'];
};

export type PasswordResetResult = {
  __typename?: 'PasswordResetResult';
  userId: Scalars['String'];
};

export type Question = {
  __typename?: 'Question';
  _key?: Maybe<Scalars['String']>;
  _type?: Maybe<Scalars['String']>;
  choices?: Maybe<Array<Maybe<Choice>>>;
  hint_i18n?: Maybe<LocaleString>;
  image?: Maybe<Image>;
  title?: Maybe<Scalars['String']>;
  title_i18n?: Maybe<LocaleString>;
};

export type Quiz = Document & {
  __typename?: 'Quiz';
  /** Date the document was created */
  _createdAt: Scalars['DateTime'];
  /** Document ID */
  _id: Scalars['ID'];
  _key?: Maybe<Scalars['String']>;
  /** Current document revision */
  _rev: Scalars['String'];
  /** Document type */
  _type: Scalars['String'];
  /** Date the document was last modified */
  _updatedAt: Scalars['DateTime'];
  descriptionRaw?: Maybe<Scalars['JSON']>;
  description_i18n?: Maybe<LocaleBlock>;
  image?: Maybe<Image>;
  questions?: Maybe<Array<Maybe<Question>>>;
  /** Man muss mindestens die folgende Punktzahl erreichen, um das Zertifikat zu bekommen. Siehe die Punkte pro richtige Antwort Einstellung. */
  requiredPoints?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  title_i18n?: Maybe<LocaleString>;
};

export type QuizFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  description_i18n?: InputMaybe<LocaleBlockFilter>;
  image?: InputMaybe<ImageFilter>;
  requiredPoints?: InputMaybe<FloatFilter>;
  title?: InputMaybe<StringFilter>;
  title_i18n?: InputMaybe<LocaleStringFilter>;
};

export type QuizResult = {
  __typename?: 'QuizResult';
  correctCount: Scalars['Int'];
  expPoints: Scalars['Int'];
  passed: Scalars['Boolean'];
  resultMap: Scalars['jsonb'];
  totalCount: Scalars['Int'];
};

export type QuizSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  description_i18n?: InputMaybe<LocaleBlockSorting>;
  image?: InputMaybe<ImageSorting>;
  requiredPoints?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  title_i18n?: InputMaybe<LocaleStringSorting>;
};

export type RecommendationEmailResponse = {
  __typename?: 'RecommendationEmailResponse';
  success: Scalars['Boolean'];
};

export type SanityAssetSourceData = {
  __typename?: 'SanityAssetSourceData';
  _key?: Maybe<Scalars['String']>;
  _type?: Maybe<Scalars['String']>;
  /** The unique ID for the asset within the originating source so you can programatically find back to it */
  id?: Maybe<Scalars['String']>;
  /** A canonical name for the source this asset is originating from */
  name?: Maybe<Scalars['String']>;
  /** A URL to find more information about this asset in the originating source */
  url?: Maybe<Scalars['String']>;
};

export type SanityAssetSourceDataFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  url?: InputMaybe<StringFilter>;
};

export type SanityAssetSourceDataSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type SanityFileAsset = Document & {
  __typename?: 'SanityFileAsset';
  /** Date the document was created */
  _createdAt: Scalars['DateTime'];
  /** Document ID */
  _id: Scalars['ID'];
  _key?: Maybe<Scalars['String']>;
  /** Current document revision */
  _rev: Scalars['String'];
  /** Document type */
  _type: Scalars['String'];
  /** Date the document was last modified */
  _updatedAt: Scalars['DateTime'];
  altText?: Maybe<Scalars['String']>;
  assetId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  originalFilename?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  sha1hash?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  source?: Maybe<SanityAssetSourceData>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type SanityFileAssetFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  altText?: InputMaybe<StringFilter>;
  assetId?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringFilter>;
  extension?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  mimeType?: InputMaybe<StringFilter>;
  originalFilename?: InputMaybe<StringFilter>;
  path?: InputMaybe<StringFilter>;
  sha1hash?: InputMaybe<StringFilter>;
  size?: InputMaybe<FloatFilter>;
  source?: InputMaybe<SanityAssetSourceDataFilter>;
  title?: InputMaybe<StringFilter>;
  url?: InputMaybe<StringFilter>;
};

export type SanityFileAssetSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  altText?: InputMaybe<SortOrder>;
  assetId?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  extension?: InputMaybe<SortOrder>;
  label?: InputMaybe<SortOrder>;
  mimeType?: InputMaybe<SortOrder>;
  originalFilename?: InputMaybe<SortOrder>;
  path?: InputMaybe<SortOrder>;
  sha1hash?: InputMaybe<SortOrder>;
  size?: InputMaybe<SortOrder>;
  source?: InputMaybe<SanityAssetSourceDataSorting>;
  title?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type SanityImageAsset = Document & {
  __typename?: 'SanityImageAsset';
  /** Date the document was created */
  _createdAt: Scalars['DateTime'];
  /** Document ID */
  _id: Scalars['ID'];
  _key?: Maybe<Scalars['String']>;
  /** Current document revision */
  _rev: Scalars['String'];
  /** Document type */
  _type: Scalars['String'];
  /** Date the document was last modified */
  _updatedAt: Scalars['DateTime'];
  altText?: Maybe<Scalars['String']>;
  assetId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  metadata?: Maybe<SanityImageMetadata>;
  mimeType?: Maybe<Scalars['String']>;
  originalFilename?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  sha1hash?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  source?: Maybe<SanityAssetSourceData>;
  title?: Maybe<Scalars['String']>;
  uploadId?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type SanityImageAssetFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  altText?: InputMaybe<StringFilter>;
  assetId?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringFilter>;
  extension?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  metadata?: InputMaybe<SanityImageMetadataFilter>;
  mimeType?: InputMaybe<StringFilter>;
  originalFilename?: InputMaybe<StringFilter>;
  path?: InputMaybe<StringFilter>;
  sha1hash?: InputMaybe<StringFilter>;
  size?: InputMaybe<FloatFilter>;
  source?: InputMaybe<SanityAssetSourceDataFilter>;
  title?: InputMaybe<StringFilter>;
  uploadId?: InputMaybe<StringFilter>;
  url?: InputMaybe<StringFilter>;
};

export type SanityImageAssetSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  altText?: InputMaybe<SortOrder>;
  assetId?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  extension?: InputMaybe<SortOrder>;
  label?: InputMaybe<SortOrder>;
  metadata?: InputMaybe<SanityImageMetadataSorting>;
  mimeType?: InputMaybe<SortOrder>;
  originalFilename?: InputMaybe<SortOrder>;
  path?: InputMaybe<SortOrder>;
  sha1hash?: InputMaybe<SortOrder>;
  size?: InputMaybe<SortOrder>;
  source?: InputMaybe<SanityAssetSourceDataSorting>;
  title?: InputMaybe<SortOrder>;
  uploadId?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type SanityImageCrop = {
  __typename?: 'SanityImageCrop';
  _key?: Maybe<Scalars['String']>;
  _type?: Maybe<Scalars['String']>;
  bottom?: Maybe<Scalars['Float']>;
  left?: Maybe<Scalars['Float']>;
  right?: Maybe<Scalars['Float']>;
  top?: Maybe<Scalars['Float']>;
};

export type SanityImageCropFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  bottom?: InputMaybe<FloatFilter>;
  left?: InputMaybe<FloatFilter>;
  right?: InputMaybe<FloatFilter>;
  top?: InputMaybe<FloatFilter>;
};

export type SanityImageCropSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  bottom?: InputMaybe<SortOrder>;
  left?: InputMaybe<SortOrder>;
  right?: InputMaybe<SortOrder>;
  top?: InputMaybe<SortOrder>;
};

export type SanityImageDimensions = {
  __typename?: 'SanityImageDimensions';
  _key?: Maybe<Scalars['String']>;
  _type?: Maybe<Scalars['String']>;
  aspectRatio?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

export type SanityImageDimensionsFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  aspectRatio?: InputMaybe<FloatFilter>;
  height?: InputMaybe<FloatFilter>;
  width?: InputMaybe<FloatFilter>;
};

export type SanityImageDimensionsSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  aspectRatio?: InputMaybe<SortOrder>;
  height?: InputMaybe<SortOrder>;
  width?: InputMaybe<SortOrder>;
};

export type SanityImageHotspot = {
  __typename?: 'SanityImageHotspot';
  _key?: Maybe<Scalars['String']>;
  _type?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

export type SanityImageHotspotFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  height?: InputMaybe<FloatFilter>;
  width?: InputMaybe<FloatFilter>;
  x?: InputMaybe<FloatFilter>;
  y?: InputMaybe<FloatFilter>;
};

export type SanityImageHotspotSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  height?: InputMaybe<SortOrder>;
  width?: InputMaybe<SortOrder>;
  x?: InputMaybe<SortOrder>;
  y?: InputMaybe<SortOrder>;
};

export type SanityImageMetadata = {
  __typename?: 'SanityImageMetadata';
  _key?: Maybe<Scalars['String']>;
  _type?: Maybe<Scalars['String']>;
  blurHash?: Maybe<Scalars['String']>;
  dimensions?: Maybe<SanityImageDimensions>;
  hasAlpha?: Maybe<Scalars['Boolean']>;
  isOpaque?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Geopoint>;
  lqip?: Maybe<Scalars['String']>;
  palette?: Maybe<SanityImagePalette>;
};

export type SanityImageMetadataFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  blurHash?: InputMaybe<StringFilter>;
  dimensions?: InputMaybe<SanityImageDimensionsFilter>;
  hasAlpha?: InputMaybe<BooleanFilter>;
  isOpaque?: InputMaybe<BooleanFilter>;
  location?: InputMaybe<GeopointFilter>;
  lqip?: InputMaybe<StringFilter>;
  palette?: InputMaybe<SanityImagePaletteFilter>;
};

export type SanityImageMetadataSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  blurHash?: InputMaybe<SortOrder>;
  dimensions?: InputMaybe<SanityImageDimensionsSorting>;
  hasAlpha?: InputMaybe<SortOrder>;
  isOpaque?: InputMaybe<SortOrder>;
  location?: InputMaybe<GeopointSorting>;
  lqip?: InputMaybe<SortOrder>;
  palette?: InputMaybe<SanityImagePaletteSorting>;
};

export type SanityImagePalette = {
  __typename?: 'SanityImagePalette';
  _key?: Maybe<Scalars['String']>;
  _type?: Maybe<Scalars['String']>;
  darkMuted?: Maybe<SanityImagePaletteSwatch>;
  darkVibrant?: Maybe<SanityImagePaletteSwatch>;
  dominant?: Maybe<SanityImagePaletteSwatch>;
  lightMuted?: Maybe<SanityImagePaletteSwatch>;
  lightVibrant?: Maybe<SanityImagePaletteSwatch>;
  muted?: Maybe<SanityImagePaletteSwatch>;
  vibrant?: Maybe<SanityImagePaletteSwatch>;
};

export type SanityImagePaletteFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  darkMuted?: InputMaybe<SanityImagePaletteSwatchFilter>;
  darkVibrant?: InputMaybe<SanityImagePaletteSwatchFilter>;
  dominant?: InputMaybe<SanityImagePaletteSwatchFilter>;
  lightMuted?: InputMaybe<SanityImagePaletteSwatchFilter>;
  lightVibrant?: InputMaybe<SanityImagePaletteSwatchFilter>;
  muted?: InputMaybe<SanityImagePaletteSwatchFilter>;
  vibrant?: InputMaybe<SanityImagePaletteSwatchFilter>;
};

export type SanityImagePaletteSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  darkMuted?: InputMaybe<SanityImagePaletteSwatchSorting>;
  darkVibrant?: InputMaybe<SanityImagePaletteSwatchSorting>;
  dominant?: InputMaybe<SanityImagePaletteSwatchSorting>;
  lightMuted?: InputMaybe<SanityImagePaletteSwatchSorting>;
  lightVibrant?: InputMaybe<SanityImagePaletteSwatchSorting>;
  muted?: InputMaybe<SanityImagePaletteSwatchSorting>;
  vibrant?: InputMaybe<SanityImagePaletteSwatchSorting>;
};

export type SanityImagePaletteSwatch = {
  __typename?: 'SanityImagePaletteSwatch';
  _key?: Maybe<Scalars['String']>;
  _type?: Maybe<Scalars['String']>;
  background?: Maybe<Scalars['String']>;
  foreground?: Maybe<Scalars['String']>;
  population?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
};

export type SanityImagePaletteSwatchFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  background?: InputMaybe<StringFilter>;
  foreground?: InputMaybe<StringFilter>;
  population?: InputMaybe<FloatFilter>;
  title?: InputMaybe<StringFilter>;
};

export type SanityImagePaletteSwatchSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  background?: InputMaybe<SortOrder>;
  foreground?: InputMaybe<SortOrder>;
  population?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export type Sanity_DocumentFilter = {
  /** All documents that are drafts. */
  is_draft?: InputMaybe<Scalars['Boolean']>;
  /** All documents referencing the given document ID. */
  references?: InputMaybe<Scalars['ID']>;
};

export type SendErgonomicsTicketResponse = {
  __typename?: 'SendErgonomicsTicketResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type Slug = {
  __typename?: 'Slug';
  _key?: Maybe<Scalars['String']>;
  _type?: Maybe<Scalars['String']>;
  current?: Maybe<Scalars['String']>;
};

export type SlugFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  current?: InputMaybe<StringFilter>;
};

export type SlugSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  current?: InputMaybe<SortOrder>;
};

export enum SortOrder {
  /** Sorts on the value in ascending order. */
  Asc = 'ASC',
  /** Sorts on the value in descending order. */
  Desc = 'DESC'
}

export type StringFilter = {
  /** Checks if the value is equal to the given input. */
  eq?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  /** Checks if the value matches the given word/words. */
  matches?: InputMaybe<Scalars['String']>;
  /** Checks if the value is not equal to the given input. */
  neq?: InputMaybe<Scalars['String']>;
  nin?: InputMaybe<Array<Scalars['String']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export type TransactionOutput = {
  __typename?: 'TransactionOutput';
  transactionId: Scalars['Int'];
};

export type UserDetails = {
  __typename?: 'UserDetails';
  exp_points: Scalars['Int'];
  level?: Maybe<Content_Levels>;
  level_id: Scalars['Int'];
  user_id: Scalars['ID'];
};

export type UserInfo = {
  __typename?: 'UserInfo';
  userId: Scalars['String'];
  vendorId: Scalars['String'];
};

/** columns and relationships of "aggregation.lectures_completed_by_vendor_per_year" */
export type Aggregation_Lectures_Completed_By_Vendor_Per_Year = {
  __typename?: 'aggregation_lectures_completed_by_vendor_per_year';
  number_of_lectures_completed?: Maybe<Scalars['bigint']>;
  vendor_id?: Maybe<Scalars['String']>;
  year_completed?: Maybe<Scalars['float8']>;
};

/** aggregated selection of "aggregation.lectures_completed_by_vendor_per_year" */
export type Aggregation_Lectures_Completed_By_Vendor_Per_Year_Aggregate = {
  __typename?: 'aggregation_lectures_completed_by_vendor_per_year_aggregate';
  aggregate?: Maybe<Aggregation_Lectures_Completed_By_Vendor_Per_Year_Aggregate_Fields>;
  nodes: Array<Aggregation_Lectures_Completed_By_Vendor_Per_Year>;
};

/** aggregate fields of "aggregation.lectures_completed_by_vendor_per_year" */
export type Aggregation_Lectures_Completed_By_Vendor_Per_Year_Aggregate_Fields = {
  __typename?: 'aggregation_lectures_completed_by_vendor_per_year_aggregate_fields';
  avg?: Maybe<Aggregation_Lectures_Completed_By_Vendor_Per_Year_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Aggregation_Lectures_Completed_By_Vendor_Per_Year_Max_Fields>;
  min?: Maybe<Aggregation_Lectures_Completed_By_Vendor_Per_Year_Min_Fields>;
  stddev?: Maybe<Aggregation_Lectures_Completed_By_Vendor_Per_Year_Stddev_Fields>;
  stddev_pop?: Maybe<Aggregation_Lectures_Completed_By_Vendor_Per_Year_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Aggregation_Lectures_Completed_By_Vendor_Per_Year_Stddev_Samp_Fields>;
  sum?: Maybe<Aggregation_Lectures_Completed_By_Vendor_Per_Year_Sum_Fields>;
  var_pop?: Maybe<Aggregation_Lectures_Completed_By_Vendor_Per_Year_Var_Pop_Fields>;
  var_samp?: Maybe<Aggregation_Lectures_Completed_By_Vendor_Per_Year_Var_Samp_Fields>;
  variance?: Maybe<Aggregation_Lectures_Completed_By_Vendor_Per_Year_Variance_Fields>;
};


/** aggregate fields of "aggregation.lectures_completed_by_vendor_per_year" */
export type Aggregation_Lectures_Completed_By_Vendor_Per_Year_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Aggregation_Lectures_Completed_By_Vendor_Per_Year_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Aggregation_Lectures_Completed_By_Vendor_Per_Year_Avg_Fields = {
  __typename?: 'aggregation_lectures_completed_by_vendor_per_year_avg_fields';
  number_of_lectures_completed?: Maybe<Scalars['Float']>;
  year_completed?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "aggregation.lectures_completed_by_vendor_per_year". All fields are combined with a logical 'AND'. */
export type Aggregation_Lectures_Completed_By_Vendor_Per_Year_Bool_Exp = {
  _and?: InputMaybe<Array<Aggregation_Lectures_Completed_By_Vendor_Per_Year_Bool_Exp>>;
  _not?: InputMaybe<Aggregation_Lectures_Completed_By_Vendor_Per_Year_Bool_Exp>;
  _or?: InputMaybe<Array<Aggregation_Lectures_Completed_By_Vendor_Per_Year_Bool_Exp>>;
  number_of_lectures_completed?: InputMaybe<Bigint_Comparison_Exp>;
  vendor_id?: InputMaybe<String_Comparison_Exp>;
  year_completed?: InputMaybe<Float8_Comparison_Exp>;
};

/** aggregate max on columns */
export type Aggregation_Lectures_Completed_By_Vendor_Per_Year_Max_Fields = {
  __typename?: 'aggregation_lectures_completed_by_vendor_per_year_max_fields';
  number_of_lectures_completed?: Maybe<Scalars['bigint']>;
  vendor_id?: Maybe<Scalars['String']>;
  year_completed?: Maybe<Scalars['float8']>;
};

/** aggregate min on columns */
export type Aggregation_Lectures_Completed_By_Vendor_Per_Year_Min_Fields = {
  __typename?: 'aggregation_lectures_completed_by_vendor_per_year_min_fields';
  number_of_lectures_completed?: Maybe<Scalars['bigint']>;
  vendor_id?: Maybe<Scalars['String']>;
  year_completed?: Maybe<Scalars['float8']>;
};

/** Ordering options when selecting data from "aggregation.lectures_completed_by_vendor_per_year". */
export type Aggregation_Lectures_Completed_By_Vendor_Per_Year_Order_By = {
  number_of_lectures_completed?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
  year_completed?: InputMaybe<Order_By>;
};

/** select columns of table "aggregation.lectures_completed_by_vendor_per_year" */
export enum Aggregation_Lectures_Completed_By_Vendor_Per_Year_Select_Column {
  /** column name */
  NumberOfLecturesCompleted = 'number_of_lectures_completed',
  /** column name */
  VendorId = 'vendor_id',
  /** column name */
  YearCompleted = 'year_completed'
}

/** aggregate stddev on columns */
export type Aggregation_Lectures_Completed_By_Vendor_Per_Year_Stddev_Fields = {
  __typename?: 'aggregation_lectures_completed_by_vendor_per_year_stddev_fields';
  number_of_lectures_completed?: Maybe<Scalars['Float']>;
  year_completed?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Aggregation_Lectures_Completed_By_Vendor_Per_Year_Stddev_Pop_Fields = {
  __typename?: 'aggregation_lectures_completed_by_vendor_per_year_stddev_pop_fields';
  number_of_lectures_completed?: Maybe<Scalars['Float']>;
  year_completed?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Aggregation_Lectures_Completed_By_Vendor_Per_Year_Stddev_Samp_Fields = {
  __typename?: 'aggregation_lectures_completed_by_vendor_per_year_stddev_samp_fields';
  number_of_lectures_completed?: Maybe<Scalars['Float']>;
  year_completed?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "aggregation_lectures_completed_by_vendor_per_year" */
export type Aggregation_Lectures_Completed_By_Vendor_Per_Year_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Aggregation_Lectures_Completed_By_Vendor_Per_Year_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Aggregation_Lectures_Completed_By_Vendor_Per_Year_Stream_Cursor_Value_Input = {
  number_of_lectures_completed?: InputMaybe<Scalars['bigint']>;
  vendor_id?: InputMaybe<Scalars['String']>;
  year_completed?: InputMaybe<Scalars['float8']>;
};

/** aggregate sum on columns */
export type Aggregation_Lectures_Completed_By_Vendor_Per_Year_Sum_Fields = {
  __typename?: 'aggregation_lectures_completed_by_vendor_per_year_sum_fields';
  number_of_lectures_completed?: Maybe<Scalars['bigint']>;
  year_completed?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type Aggregation_Lectures_Completed_By_Vendor_Per_Year_Var_Pop_Fields = {
  __typename?: 'aggregation_lectures_completed_by_vendor_per_year_var_pop_fields';
  number_of_lectures_completed?: Maybe<Scalars['Float']>;
  year_completed?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Aggregation_Lectures_Completed_By_Vendor_Per_Year_Var_Samp_Fields = {
  __typename?: 'aggregation_lectures_completed_by_vendor_per_year_var_samp_fields';
  number_of_lectures_completed?: Maybe<Scalars['Float']>;
  year_completed?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Aggregation_Lectures_Completed_By_Vendor_Per_Year_Variance_Fields = {
  __typename?: 'aggregation_lectures_completed_by_vendor_per_year_variance_fields';
  number_of_lectures_completed?: Maybe<Scalars['Float']>;
  year_completed?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "aggregation.required_lections_count" */
export type Aggregation_Required_Lections_Count = {
  __typename?: 'aggregation_required_lections_count';
  lection_id?: Maybe<Scalars['Int']>;
  required_count?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "aggregation.required_lections_count" */
export type Aggregation_Required_Lections_Count_Aggregate = {
  __typename?: 'aggregation_required_lections_count_aggregate';
  aggregate?: Maybe<Aggregation_Required_Lections_Count_Aggregate_Fields>;
  nodes: Array<Aggregation_Required_Lections_Count>;
};

/** aggregate fields of "aggregation.required_lections_count" */
export type Aggregation_Required_Lections_Count_Aggregate_Fields = {
  __typename?: 'aggregation_required_lections_count_aggregate_fields';
  avg?: Maybe<Aggregation_Required_Lections_Count_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Aggregation_Required_Lections_Count_Max_Fields>;
  min?: Maybe<Aggregation_Required_Lections_Count_Min_Fields>;
  stddev?: Maybe<Aggregation_Required_Lections_Count_Stddev_Fields>;
  stddev_pop?: Maybe<Aggregation_Required_Lections_Count_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Aggregation_Required_Lections_Count_Stddev_Samp_Fields>;
  sum?: Maybe<Aggregation_Required_Lections_Count_Sum_Fields>;
  var_pop?: Maybe<Aggregation_Required_Lections_Count_Var_Pop_Fields>;
  var_samp?: Maybe<Aggregation_Required_Lections_Count_Var_Samp_Fields>;
  variance?: Maybe<Aggregation_Required_Lections_Count_Variance_Fields>;
};


/** aggregate fields of "aggregation.required_lections_count" */
export type Aggregation_Required_Lections_Count_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Aggregation_Required_Lections_Count_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Aggregation_Required_Lections_Count_Avg_Fields = {
  __typename?: 'aggregation_required_lections_count_avg_fields';
  lection_id?: Maybe<Scalars['Float']>;
  required_count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "aggregation.required_lections_count". All fields are combined with a logical 'AND'. */
export type Aggregation_Required_Lections_Count_Bool_Exp = {
  _and?: InputMaybe<Array<Aggregation_Required_Lections_Count_Bool_Exp>>;
  _not?: InputMaybe<Aggregation_Required_Lections_Count_Bool_Exp>;
  _or?: InputMaybe<Array<Aggregation_Required_Lections_Count_Bool_Exp>>;
  lection_id?: InputMaybe<Int_Comparison_Exp>;
  required_count?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Aggregation_Required_Lections_Count_Max_Fields = {
  __typename?: 'aggregation_required_lections_count_max_fields';
  lection_id?: Maybe<Scalars['Int']>;
  required_count?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Aggregation_Required_Lections_Count_Min_Fields = {
  __typename?: 'aggregation_required_lections_count_min_fields';
  lection_id?: Maybe<Scalars['Int']>;
  required_count?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "aggregation.required_lections_count". */
export type Aggregation_Required_Lections_Count_Order_By = {
  lection_id?: InputMaybe<Order_By>;
  required_count?: InputMaybe<Order_By>;
};

/** select columns of table "aggregation.required_lections_count" */
export enum Aggregation_Required_Lections_Count_Select_Column {
  /** column name */
  LectionId = 'lection_id',
  /** column name */
  RequiredCount = 'required_count'
}

/** aggregate stddev on columns */
export type Aggregation_Required_Lections_Count_Stddev_Fields = {
  __typename?: 'aggregation_required_lections_count_stddev_fields';
  lection_id?: Maybe<Scalars['Float']>;
  required_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Aggregation_Required_Lections_Count_Stddev_Pop_Fields = {
  __typename?: 'aggregation_required_lections_count_stddev_pop_fields';
  lection_id?: Maybe<Scalars['Float']>;
  required_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Aggregation_Required_Lections_Count_Stddev_Samp_Fields = {
  __typename?: 'aggregation_required_lections_count_stddev_samp_fields';
  lection_id?: Maybe<Scalars['Float']>;
  required_count?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "aggregation_required_lections_count" */
export type Aggregation_Required_Lections_Count_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Aggregation_Required_Lections_Count_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Aggregation_Required_Lections_Count_Stream_Cursor_Value_Input = {
  lection_id?: InputMaybe<Scalars['Int']>;
  required_count?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Aggregation_Required_Lections_Count_Sum_Fields = {
  __typename?: 'aggregation_required_lections_count_sum_fields';
  lection_id?: Maybe<Scalars['Int']>;
  required_count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Aggregation_Required_Lections_Count_Var_Pop_Fields = {
  __typename?: 'aggregation_required_lections_count_var_pop_fields';
  lection_id?: Maybe<Scalars['Float']>;
  required_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Aggregation_Required_Lections_Count_Var_Samp_Fields = {
  __typename?: 'aggregation_required_lections_count_var_samp_fields';
  lection_id?: Maybe<Scalars['Float']>;
  required_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Aggregation_Required_Lections_Count_Variance_Fields = {
  __typename?: 'aggregation_required_lections_count_variance_fields';
  lection_id?: Maybe<Scalars['Float']>;
  required_count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "aggregation.requirements_overview" */
export type Aggregation_Requirements_Overview = {
  __typename?: 'aggregation_requirements_overview';
  completed_count?: Maybe<Scalars['bigint']>;
  lection_id?: Maybe<Scalars['Int']>;
  required_count?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "aggregation.requirements_overview" */
export type Aggregation_Requirements_Overview_Aggregate = {
  __typename?: 'aggregation_requirements_overview_aggregate';
  aggregate?: Maybe<Aggregation_Requirements_Overview_Aggregate_Fields>;
  nodes: Array<Aggregation_Requirements_Overview>;
};

/** aggregate fields of "aggregation.requirements_overview" */
export type Aggregation_Requirements_Overview_Aggregate_Fields = {
  __typename?: 'aggregation_requirements_overview_aggregate_fields';
  avg?: Maybe<Aggregation_Requirements_Overview_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Aggregation_Requirements_Overview_Max_Fields>;
  min?: Maybe<Aggregation_Requirements_Overview_Min_Fields>;
  stddev?: Maybe<Aggregation_Requirements_Overview_Stddev_Fields>;
  stddev_pop?: Maybe<Aggregation_Requirements_Overview_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Aggregation_Requirements_Overview_Stddev_Samp_Fields>;
  sum?: Maybe<Aggregation_Requirements_Overview_Sum_Fields>;
  var_pop?: Maybe<Aggregation_Requirements_Overview_Var_Pop_Fields>;
  var_samp?: Maybe<Aggregation_Requirements_Overview_Var_Samp_Fields>;
  variance?: Maybe<Aggregation_Requirements_Overview_Variance_Fields>;
};


/** aggregate fields of "aggregation.requirements_overview" */
export type Aggregation_Requirements_Overview_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Aggregation_Requirements_Overview_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Aggregation_Requirements_Overview_Avg_Fields = {
  __typename?: 'aggregation_requirements_overview_avg_fields';
  completed_count?: Maybe<Scalars['Float']>;
  lection_id?: Maybe<Scalars['Float']>;
  required_count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "aggregation.requirements_overview". All fields are combined with a logical 'AND'. */
export type Aggregation_Requirements_Overview_Bool_Exp = {
  _and?: InputMaybe<Array<Aggregation_Requirements_Overview_Bool_Exp>>;
  _not?: InputMaybe<Aggregation_Requirements_Overview_Bool_Exp>;
  _or?: InputMaybe<Array<Aggregation_Requirements_Overview_Bool_Exp>>;
  completed_count?: InputMaybe<Bigint_Comparison_Exp>;
  lection_id?: InputMaybe<Int_Comparison_Exp>;
  required_count?: InputMaybe<Bigint_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type Aggregation_Requirements_Overview_Max_Fields = {
  __typename?: 'aggregation_requirements_overview_max_fields';
  completed_count?: Maybe<Scalars['bigint']>;
  lection_id?: Maybe<Scalars['Int']>;
  required_count?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Aggregation_Requirements_Overview_Min_Fields = {
  __typename?: 'aggregation_requirements_overview_min_fields';
  completed_count?: Maybe<Scalars['bigint']>;
  lection_id?: Maybe<Scalars['Int']>;
  required_count?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "aggregation.requirements_overview". */
export type Aggregation_Requirements_Overview_Order_By = {
  completed_count?: InputMaybe<Order_By>;
  lection_id?: InputMaybe<Order_By>;
  required_count?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "aggregation.requirements_overview" */
export enum Aggregation_Requirements_Overview_Select_Column {
  /** column name */
  CompletedCount = 'completed_count',
  /** column name */
  LectionId = 'lection_id',
  /** column name */
  RequiredCount = 'required_count',
  /** column name */
  UserId = 'user_id'
}

/** aggregate stddev on columns */
export type Aggregation_Requirements_Overview_Stddev_Fields = {
  __typename?: 'aggregation_requirements_overview_stddev_fields';
  completed_count?: Maybe<Scalars['Float']>;
  lection_id?: Maybe<Scalars['Float']>;
  required_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Aggregation_Requirements_Overview_Stddev_Pop_Fields = {
  __typename?: 'aggregation_requirements_overview_stddev_pop_fields';
  completed_count?: Maybe<Scalars['Float']>;
  lection_id?: Maybe<Scalars['Float']>;
  required_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Aggregation_Requirements_Overview_Stddev_Samp_Fields = {
  __typename?: 'aggregation_requirements_overview_stddev_samp_fields';
  completed_count?: Maybe<Scalars['Float']>;
  lection_id?: Maybe<Scalars['Float']>;
  required_count?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "aggregation_requirements_overview" */
export type Aggregation_Requirements_Overview_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Aggregation_Requirements_Overview_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Aggregation_Requirements_Overview_Stream_Cursor_Value_Input = {
  completed_count?: InputMaybe<Scalars['bigint']>;
  lection_id?: InputMaybe<Scalars['Int']>;
  required_count?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Aggregation_Requirements_Overview_Sum_Fields = {
  __typename?: 'aggregation_requirements_overview_sum_fields';
  completed_count?: Maybe<Scalars['bigint']>;
  lection_id?: Maybe<Scalars['Int']>;
  required_count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Aggregation_Requirements_Overview_Var_Pop_Fields = {
  __typename?: 'aggregation_requirements_overview_var_pop_fields';
  completed_count?: Maybe<Scalars['Float']>;
  lection_id?: Maybe<Scalars['Float']>;
  required_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Aggregation_Requirements_Overview_Var_Samp_Fields = {
  __typename?: 'aggregation_requirements_overview_var_samp_fields';
  completed_count?: Maybe<Scalars['Float']>;
  lection_id?: Maybe<Scalars['Float']>;
  required_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Aggregation_Requirements_Overview_Variance_Fields = {
  __typename?: 'aggregation_requirements_overview_variance_fields';
  completed_count?: Maybe<Scalars['Float']>;
  lection_id?: Maybe<Scalars['Float']>;
  required_count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "aggregation.users_chapters" */
export type Aggregation_Users_Chapters = {
  __typename?: 'aggregation_users_chapters';
  chapter_id?: Maybe<Scalars['Int']>;
  lection_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "aggregation.users_chapters" */
export type Aggregation_Users_Chapters_Aggregate = {
  __typename?: 'aggregation_users_chapters_aggregate';
  aggregate?: Maybe<Aggregation_Users_Chapters_Aggregate_Fields>;
  nodes: Array<Aggregation_Users_Chapters>;
};

/** aggregate fields of "aggregation.users_chapters" */
export type Aggregation_Users_Chapters_Aggregate_Fields = {
  __typename?: 'aggregation_users_chapters_aggregate_fields';
  avg?: Maybe<Aggregation_Users_Chapters_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Aggregation_Users_Chapters_Max_Fields>;
  min?: Maybe<Aggregation_Users_Chapters_Min_Fields>;
  stddev?: Maybe<Aggregation_Users_Chapters_Stddev_Fields>;
  stddev_pop?: Maybe<Aggregation_Users_Chapters_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Aggregation_Users_Chapters_Stddev_Samp_Fields>;
  sum?: Maybe<Aggregation_Users_Chapters_Sum_Fields>;
  var_pop?: Maybe<Aggregation_Users_Chapters_Var_Pop_Fields>;
  var_samp?: Maybe<Aggregation_Users_Chapters_Var_Samp_Fields>;
  variance?: Maybe<Aggregation_Users_Chapters_Variance_Fields>;
};


/** aggregate fields of "aggregation.users_chapters" */
export type Aggregation_Users_Chapters_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Aggregation_Users_Chapters_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Aggregation_Users_Chapters_Avg_Fields = {
  __typename?: 'aggregation_users_chapters_avg_fields';
  chapter_id?: Maybe<Scalars['Float']>;
  lection_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "aggregation.users_chapters". All fields are combined with a logical 'AND'. */
export type Aggregation_Users_Chapters_Bool_Exp = {
  _and?: InputMaybe<Array<Aggregation_Users_Chapters_Bool_Exp>>;
  _not?: InputMaybe<Aggregation_Users_Chapters_Bool_Exp>;
  _or?: InputMaybe<Array<Aggregation_Users_Chapters_Bool_Exp>>;
  chapter_id?: InputMaybe<Int_Comparison_Exp>;
  lection_id?: InputMaybe<Int_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type Aggregation_Users_Chapters_Max_Fields = {
  __typename?: 'aggregation_users_chapters_max_fields';
  chapter_id?: Maybe<Scalars['Int']>;
  lection_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Aggregation_Users_Chapters_Min_Fields = {
  __typename?: 'aggregation_users_chapters_min_fields';
  chapter_id?: Maybe<Scalars['Int']>;
  lection_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "aggregation.users_chapters". */
export type Aggregation_Users_Chapters_Order_By = {
  chapter_id?: InputMaybe<Order_By>;
  lection_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "aggregation.users_chapters" */
export enum Aggregation_Users_Chapters_Select_Column {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  LectionId = 'lection_id',
  /** column name */
  UserId = 'user_id'
}

/** aggregate stddev on columns */
export type Aggregation_Users_Chapters_Stddev_Fields = {
  __typename?: 'aggregation_users_chapters_stddev_fields';
  chapter_id?: Maybe<Scalars['Float']>;
  lection_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Aggregation_Users_Chapters_Stddev_Pop_Fields = {
  __typename?: 'aggregation_users_chapters_stddev_pop_fields';
  chapter_id?: Maybe<Scalars['Float']>;
  lection_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Aggregation_Users_Chapters_Stddev_Samp_Fields = {
  __typename?: 'aggregation_users_chapters_stddev_samp_fields';
  chapter_id?: Maybe<Scalars['Float']>;
  lection_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "aggregation_users_chapters" */
export type Aggregation_Users_Chapters_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Aggregation_Users_Chapters_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Aggregation_Users_Chapters_Stream_Cursor_Value_Input = {
  chapter_id?: InputMaybe<Scalars['Int']>;
  lection_id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Aggregation_Users_Chapters_Sum_Fields = {
  __typename?: 'aggregation_users_chapters_sum_fields';
  chapter_id?: Maybe<Scalars['Int']>;
  lection_id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Aggregation_Users_Chapters_Var_Pop_Fields = {
  __typename?: 'aggregation_users_chapters_var_pop_fields';
  chapter_id?: Maybe<Scalars['Float']>;
  lection_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Aggregation_Users_Chapters_Var_Samp_Fields = {
  __typename?: 'aggregation_users_chapters_var_samp_fields';
  chapter_id?: Maybe<Scalars['Float']>;
  lection_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Aggregation_Users_Chapters_Variance_Fields = {
  __typename?: 'aggregation_users_chapters_variance_fields';
  chapter_id?: Maybe<Scalars['Float']>;
  lection_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "aggregation.users_lections" */
export type Aggregation_Users_Lections = {
  __typename?: 'aggregation_users_lections';
  contact_point_id?: Maybe<Scalars['Int']>;
  lection_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "aggregation.users_lections" */
export type Aggregation_Users_Lections_Aggregate = {
  __typename?: 'aggregation_users_lections_aggregate';
  aggregate?: Maybe<Aggregation_Users_Lections_Aggregate_Fields>;
  nodes: Array<Aggregation_Users_Lections>;
};

/** aggregate fields of "aggregation.users_lections" */
export type Aggregation_Users_Lections_Aggregate_Fields = {
  __typename?: 'aggregation_users_lections_aggregate_fields';
  avg?: Maybe<Aggregation_Users_Lections_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Aggregation_Users_Lections_Max_Fields>;
  min?: Maybe<Aggregation_Users_Lections_Min_Fields>;
  stddev?: Maybe<Aggregation_Users_Lections_Stddev_Fields>;
  stddev_pop?: Maybe<Aggregation_Users_Lections_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Aggregation_Users_Lections_Stddev_Samp_Fields>;
  sum?: Maybe<Aggregation_Users_Lections_Sum_Fields>;
  var_pop?: Maybe<Aggregation_Users_Lections_Var_Pop_Fields>;
  var_samp?: Maybe<Aggregation_Users_Lections_Var_Samp_Fields>;
  variance?: Maybe<Aggregation_Users_Lections_Variance_Fields>;
};


/** aggregate fields of "aggregation.users_lections" */
export type Aggregation_Users_Lections_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Aggregation_Users_Lections_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Aggregation_Users_Lections_Avg_Fields = {
  __typename?: 'aggregation_users_lections_avg_fields';
  contact_point_id?: Maybe<Scalars['Float']>;
  lection_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "aggregation.users_lections". All fields are combined with a logical 'AND'. */
export type Aggregation_Users_Lections_Bool_Exp = {
  _and?: InputMaybe<Array<Aggregation_Users_Lections_Bool_Exp>>;
  _not?: InputMaybe<Aggregation_Users_Lections_Bool_Exp>;
  _or?: InputMaybe<Array<Aggregation_Users_Lections_Bool_Exp>>;
  contact_point_id?: InputMaybe<Int_Comparison_Exp>;
  lection_id?: InputMaybe<Int_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type Aggregation_Users_Lections_Max_Fields = {
  __typename?: 'aggregation_users_lections_max_fields';
  contact_point_id?: Maybe<Scalars['Int']>;
  lection_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Aggregation_Users_Lections_Min_Fields = {
  __typename?: 'aggregation_users_lections_min_fields';
  contact_point_id?: Maybe<Scalars['Int']>;
  lection_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "aggregation.users_lections". */
export type Aggregation_Users_Lections_Order_By = {
  contact_point_id?: InputMaybe<Order_By>;
  lection_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "aggregation.users_lections" */
export enum Aggregation_Users_Lections_Select_Column {
  /** column name */
  ContactPointId = 'contact_point_id',
  /** column name */
  LectionId = 'lection_id',
  /** column name */
  UserId = 'user_id'
}

/** aggregate stddev on columns */
export type Aggregation_Users_Lections_Stddev_Fields = {
  __typename?: 'aggregation_users_lections_stddev_fields';
  contact_point_id?: Maybe<Scalars['Float']>;
  lection_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Aggregation_Users_Lections_Stddev_Pop_Fields = {
  __typename?: 'aggregation_users_lections_stddev_pop_fields';
  contact_point_id?: Maybe<Scalars['Float']>;
  lection_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Aggregation_Users_Lections_Stddev_Samp_Fields = {
  __typename?: 'aggregation_users_lections_stddev_samp_fields';
  contact_point_id?: Maybe<Scalars['Float']>;
  lection_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "aggregation_users_lections" */
export type Aggregation_Users_Lections_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Aggregation_Users_Lections_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Aggregation_Users_Lections_Stream_Cursor_Value_Input = {
  contact_point_id?: InputMaybe<Scalars['Int']>;
  lection_id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Aggregation_Users_Lections_Sum_Fields = {
  __typename?: 'aggregation_users_lections_sum_fields';
  contact_point_id?: Maybe<Scalars['Int']>;
  lection_id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Aggregation_Users_Lections_Var_Pop_Fields = {
  __typename?: 'aggregation_users_lections_var_pop_fields';
  contact_point_id?: Maybe<Scalars['Float']>;
  lection_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Aggregation_Users_Lections_Var_Samp_Fields = {
  __typename?: 'aggregation_users_lections_var_samp_fields';
  contact_point_id?: Maybe<Scalars['Float']>;
  lection_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Aggregation_Users_Lections_Variance_Fields = {
  __typename?: 'aggregation_users_lections_variance_fields';
  contact_point_id?: Maybe<Scalars['Float']>;
  lection_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "aggregation.users_requirements" */
export type Aggregation_Users_Requirements = {
  __typename?: 'aggregation_users_requirements';
  lection_id?: Maybe<Scalars['Int']>;
  required_lection_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "aggregation.users_requirements" */
export type Aggregation_Users_Requirements_Aggregate = {
  __typename?: 'aggregation_users_requirements_aggregate';
  aggregate?: Maybe<Aggregation_Users_Requirements_Aggregate_Fields>;
  nodes: Array<Aggregation_Users_Requirements>;
};

/** aggregate fields of "aggregation.users_requirements" */
export type Aggregation_Users_Requirements_Aggregate_Fields = {
  __typename?: 'aggregation_users_requirements_aggregate_fields';
  avg?: Maybe<Aggregation_Users_Requirements_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Aggregation_Users_Requirements_Max_Fields>;
  min?: Maybe<Aggregation_Users_Requirements_Min_Fields>;
  stddev?: Maybe<Aggregation_Users_Requirements_Stddev_Fields>;
  stddev_pop?: Maybe<Aggregation_Users_Requirements_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Aggregation_Users_Requirements_Stddev_Samp_Fields>;
  sum?: Maybe<Aggregation_Users_Requirements_Sum_Fields>;
  var_pop?: Maybe<Aggregation_Users_Requirements_Var_Pop_Fields>;
  var_samp?: Maybe<Aggregation_Users_Requirements_Var_Samp_Fields>;
  variance?: Maybe<Aggregation_Users_Requirements_Variance_Fields>;
};


/** aggregate fields of "aggregation.users_requirements" */
export type Aggregation_Users_Requirements_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Aggregation_Users_Requirements_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Aggregation_Users_Requirements_Avg_Fields = {
  __typename?: 'aggregation_users_requirements_avg_fields';
  lection_id?: Maybe<Scalars['Float']>;
  required_lection_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "aggregation.users_requirements". All fields are combined with a logical 'AND'. */
export type Aggregation_Users_Requirements_Bool_Exp = {
  _and?: InputMaybe<Array<Aggregation_Users_Requirements_Bool_Exp>>;
  _not?: InputMaybe<Aggregation_Users_Requirements_Bool_Exp>;
  _or?: InputMaybe<Array<Aggregation_Users_Requirements_Bool_Exp>>;
  lection_id?: InputMaybe<Int_Comparison_Exp>;
  required_lection_id?: InputMaybe<Int_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type Aggregation_Users_Requirements_Max_Fields = {
  __typename?: 'aggregation_users_requirements_max_fields';
  lection_id?: Maybe<Scalars['Int']>;
  required_lection_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Aggregation_Users_Requirements_Min_Fields = {
  __typename?: 'aggregation_users_requirements_min_fields';
  lection_id?: Maybe<Scalars['Int']>;
  required_lection_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "aggregation.users_requirements". */
export type Aggregation_Users_Requirements_Order_By = {
  lection_id?: InputMaybe<Order_By>;
  required_lection_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "aggregation.users_requirements" */
export enum Aggregation_Users_Requirements_Select_Column {
  /** column name */
  LectionId = 'lection_id',
  /** column name */
  RequiredLectionId = 'required_lection_id',
  /** column name */
  UserId = 'user_id'
}

/** aggregate stddev on columns */
export type Aggregation_Users_Requirements_Stddev_Fields = {
  __typename?: 'aggregation_users_requirements_stddev_fields';
  lection_id?: Maybe<Scalars['Float']>;
  required_lection_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Aggregation_Users_Requirements_Stddev_Pop_Fields = {
  __typename?: 'aggregation_users_requirements_stddev_pop_fields';
  lection_id?: Maybe<Scalars['Float']>;
  required_lection_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Aggregation_Users_Requirements_Stddev_Samp_Fields = {
  __typename?: 'aggregation_users_requirements_stddev_samp_fields';
  lection_id?: Maybe<Scalars['Float']>;
  required_lection_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "aggregation_users_requirements" */
export type Aggregation_Users_Requirements_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Aggregation_Users_Requirements_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Aggregation_Users_Requirements_Stream_Cursor_Value_Input = {
  lection_id?: InputMaybe<Scalars['Int']>;
  required_lection_id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Aggregation_Users_Requirements_Sum_Fields = {
  __typename?: 'aggregation_users_requirements_sum_fields';
  lection_id?: Maybe<Scalars['Int']>;
  required_lection_id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Aggregation_Users_Requirements_Var_Pop_Fields = {
  __typename?: 'aggregation_users_requirements_var_pop_fields';
  lection_id?: Maybe<Scalars['Float']>;
  required_lection_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Aggregation_Users_Requirements_Var_Samp_Fields = {
  __typename?: 'aggregation_users_requirements_var_samp_fields';
  lection_id?: Maybe<Scalars['Float']>;
  required_lection_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Aggregation_Users_Requirements_Variance_Fields = {
  __typename?: 'aggregation_users_requirements_variance_fields';
  lection_id?: Maybe<Scalars['Float']>;
  required_lection_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "answer_enums.all" */
export type Answer_Enums_All = {
  __typename?: 'answer_enums_all';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregated selection of "answer_enums.all" */
export type Answer_Enums_All_Aggregate = {
  __typename?: 'answer_enums_all_aggregate';
  aggregate?: Maybe<Answer_Enums_All_Aggregate_Fields>;
  nodes: Array<Answer_Enums_All>;
};

/** aggregate fields of "answer_enums.all" */
export type Answer_Enums_All_Aggregate_Fields = {
  __typename?: 'answer_enums_all_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Answer_Enums_All_Max_Fields>;
  min?: Maybe<Answer_Enums_All_Min_Fields>;
};


/** aggregate fields of "answer_enums.all" */
export type Answer_Enums_All_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Answer_Enums_All_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "answer_enums.all". All fields are combined with a logical 'AND'. */
export type Answer_Enums_All_Bool_Exp = {
  _and?: InputMaybe<Array<Answer_Enums_All_Bool_Exp>>;
  _not?: InputMaybe<Answer_Enums_All_Bool_Exp>;
  _or?: InputMaybe<Array<Answer_Enums_All_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Answer_Enums_All_Max_Fields = {
  __typename?: 'answer_enums_all_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Answer_Enums_All_Min_Fields = {
  __typename?: 'answer_enums_all_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "answer_enums.all". */
export type Answer_Enums_All_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "answer_enums.all" */
export enum Answer_Enums_All_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "answer_enums_all" */
export type Answer_Enums_All_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Answer_Enums_All_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Answer_Enums_All_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "answer_enums.bicycle_type" */
export type Answer_Enums_Bicycle_Type = {
  __typename?: 'answer_enums_bicycle_type';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "answer_enums.bicycle_type" */
export type Answer_Enums_Bicycle_Type_Aggregate = {
  __typename?: 'answer_enums_bicycle_type_aggregate';
  aggregate?: Maybe<Answer_Enums_Bicycle_Type_Aggregate_Fields>;
  nodes: Array<Answer_Enums_Bicycle_Type>;
};

/** aggregate fields of "answer_enums.bicycle_type" */
export type Answer_Enums_Bicycle_Type_Aggregate_Fields = {
  __typename?: 'answer_enums_bicycle_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Answer_Enums_Bicycle_Type_Max_Fields>;
  min?: Maybe<Answer_Enums_Bicycle_Type_Min_Fields>;
};


/** aggregate fields of "answer_enums.bicycle_type" */
export type Answer_Enums_Bicycle_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Answer_Enums_Bicycle_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "answer_enums.bicycle_type". All fields are combined with a logical 'AND'. */
export type Answer_Enums_Bicycle_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Answer_Enums_Bicycle_Type_Bool_Exp>>;
  _not?: InputMaybe<Answer_Enums_Bicycle_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Answer_Enums_Bicycle_Type_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "answer_enums.bicycle_type" */
export enum Answer_Enums_Bicycle_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  BicycleTypePkey = 'bicycle_type_pkey'
}

export enum Answer_Enums_Bicycle_Type_Enum {
  /** City Comfort */
  CityComfort = 'city_comfort',
  /** Gravel */
  Gravel = 'gravel',
  /** MTB All Mountain */
  MtbAllMountain = 'mtb_all_mountain',
  /** MTB - Dirt & Downhill */
  MtbDownhill = 'mtb_downhill',
  /** Marathon & Cross Country */
  MtbMarathon = 'mtb_marathon',
  /** MTB XC */
  MtbXc = 'mtb_xc',
  /** Rennrad */
  RacingBike = 'racing_bike',
  /** Road Race */
  RoadRace = 'road_race',
  /** Trekking Comfort */
  TrekkingComfort = 'trekking_comfort',
  /** Trekking Cross */
  TrekkingCross = 'trekking_cross',
  /** Trial & BMX */
  TrialBmx = 'trial_bmx',
  /** Triathlon */
  Triathlon = 'triathlon',
  /** Triathlon */
  TriathlonBike = 'triathlon_bike'
}

/** Boolean expression to compare columns of type "answer_enums_bicycle_type_enum". All fields are combined with logical 'AND'. */
export type Answer_Enums_Bicycle_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Answer_Enums_Bicycle_Type_Enum>;
  _in?: InputMaybe<Array<Answer_Enums_Bicycle_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Answer_Enums_Bicycle_Type_Enum>;
  _nin?: InputMaybe<Array<Answer_Enums_Bicycle_Type_Enum>>;
};

/** input type for inserting data into table "answer_enums.bicycle_type" */
export type Answer_Enums_Bicycle_Type_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Answer_Enums_Bicycle_Type_Max_Fields = {
  __typename?: 'answer_enums_bicycle_type_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Answer_Enums_Bicycle_Type_Min_Fields = {
  __typename?: 'answer_enums_bicycle_type_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "answer_enums.bicycle_type" */
export type Answer_Enums_Bicycle_Type_Mutation_Response = {
  __typename?: 'answer_enums_bicycle_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Answer_Enums_Bicycle_Type>;
};

/** input type for inserting object relation for remote table "answer_enums.bicycle_type" */
export type Answer_Enums_Bicycle_Type_Obj_Rel_Insert_Input = {
  data: Answer_Enums_Bicycle_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Answer_Enums_Bicycle_Type_On_Conflict>;
};

/** on_conflict condition type for table "answer_enums.bicycle_type" */
export type Answer_Enums_Bicycle_Type_On_Conflict = {
  constraint: Answer_Enums_Bicycle_Type_Constraint;
  update_columns?: Array<Answer_Enums_Bicycle_Type_Update_Column>;
  where?: InputMaybe<Answer_Enums_Bicycle_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "answer_enums.bicycle_type". */
export type Answer_Enums_Bicycle_Type_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: answer_enums.bicycle_type */
export type Answer_Enums_Bicycle_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "answer_enums.bicycle_type" */
export enum Answer_Enums_Bicycle_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "answer_enums.bicycle_type" */
export type Answer_Enums_Bicycle_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "answer_enums_bicycle_type" */
export type Answer_Enums_Bicycle_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Answer_Enums_Bicycle_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Answer_Enums_Bicycle_Type_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "answer_enums.bicycle_type" */
export enum Answer_Enums_Bicycle_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type Answer_Enums_Bicycle_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Answer_Enums_Bicycle_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Answer_Enums_Bicycle_Type_Bool_Exp;
};

/** columns and relationships of "answer_enums.driving_duration" */
export type Answer_Enums_Driving_Duration = {
  __typename?: 'answer_enums_driving_duration';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "answer_enums.driving_duration" */
export type Answer_Enums_Driving_Duration_Aggregate = {
  __typename?: 'answer_enums_driving_duration_aggregate';
  aggregate?: Maybe<Answer_Enums_Driving_Duration_Aggregate_Fields>;
  nodes: Array<Answer_Enums_Driving_Duration>;
};

/** aggregate fields of "answer_enums.driving_duration" */
export type Answer_Enums_Driving_Duration_Aggregate_Fields = {
  __typename?: 'answer_enums_driving_duration_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Answer_Enums_Driving_Duration_Max_Fields>;
  min?: Maybe<Answer_Enums_Driving_Duration_Min_Fields>;
};


/** aggregate fields of "answer_enums.driving_duration" */
export type Answer_Enums_Driving_Duration_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Answer_Enums_Driving_Duration_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "answer_enums.driving_duration". All fields are combined with a logical 'AND'. */
export type Answer_Enums_Driving_Duration_Bool_Exp = {
  _and?: InputMaybe<Array<Answer_Enums_Driving_Duration_Bool_Exp>>;
  _not?: InputMaybe<Answer_Enums_Driving_Duration_Bool_Exp>;
  _or?: InputMaybe<Array<Answer_Enums_Driving_Duration_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "answer_enums.driving_duration" */
export enum Answer_Enums_Driving_Duration_Constraint {
  /** unique or primary key constraint on columns "value" */
  DrivingDurationPkey = 'driving_duration_pkey'
}

export enum Answer_Enums_Driving_Duration_Enum {
  /** Weniger als 45 Minuten */
  Between45minAnd2h = 'between45minAnd2h',
  /** 45 Minuten bis 2 Stunden */
  LessThan45min = 'lessThan45min',
  /** Mehr als 2 Stunden */
  MoreThan2h = 'moreThan2h'
}

/** Boolean expression to compare columns of type "answer_enums_driving_duration_enum". All fields are combined with logical 'AND'. */
export type Answer_Enums_Driving_Duration_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Answer_Enums_Driving_Duration_Enum>;
  _in?: InputMaybe<Array<Answer_Enums_Driving_Duration_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Answer_Enums_Driving_Duration_Enum>;
  _nin?: InputMaybe<Array<Answer_Enums_Driving_Duration_Enum>>;
};

/** input type for inserting data into table "answer_enums.driving_duration" */
export type Answer_Enums_Driving_Duration_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Answer_Enums_Driving_Duration_Max_Fields = {
  __typename?: 'answer_enums_driving_duration_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Answer_Enums_Driving_Duration_Min_Fields = {
  __typename?: 'answer_enums_driving_duration_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "answer_enums.driving_duration" */
export type Answer_Enums_Driving_Duration_Mutation_Response = {
  __typename?: 'answer_enums_driving_duration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Answer_Enums_Driving_Duration>;
};

/** input type for inserting object relation for remote table "answer_enums.driving_duration" */
export type Answer_Enums_Driving_Duration_Obj_Rel_Insert_Input = {
  data: Answer_Enums_Driving_Duration_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Answer_Enums_Driving_Duration_On_Conflict>;
};

/** on_conflict condition type for table "answer_enums.driving_duration" */
export type Answer_Enums_Driving_Duration_On_Conflict = {
  constraint: Answer_Enums_Driving_Duration_Constraint;
  update_columns?: Array<Answer_Enums_Driving_Duration_Update_Column>;
  where?: InputMaybe<Answer_Enums_Driving_Duration_Bool_Exp>;
};

/** Ordering options when selecting data from "answer_enums.driving_duration". */
export type Answer_Enums_Driving_Duration_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: answer_enums.driving_duration */
export type Answer_Enums_Driving_Duration_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "answer_enums.driving_duration" */
export enum Answer_Enums_Driving_Duration_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "answer_enums.driving_duration" */
export type Answer_Enums_Driving_Duration_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "answer_enums_driving_duration" */
export type Answer_Enums_Driving_Duration_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Answer_Enums_Driving_Duration_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Answer_Enums_Driving_Duration_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "answer_enums.driving_duration" */
export enum Answer_Enums_Driving_Duration_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type Answer_Enums_Driving_Duration_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Answer_Enums_Driving_Duration_Set_Input>;
  /** filter the rows which have to be updated */
  where: Answer_Enums_Driving_Duration_Bool_Exp;
};

/** columns and relationships of "answer_enums.driving_frequency" */
export type Answer_Enums_Driving_Frequency = {
  __typename?: 'answer_enums_driving_frequency';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "answer_enums.driving_frequency" */
export type Answer_Enums_Driving_Frequency_Aggregate = {
  __typename?: 'answer_enums_driving_frequency_aggregate';
  aggregate?: Maybe<Answer_Enums_Driving_Frequency_Aggregate_Fields>;
  nodes: Array<Answer_Enums_Driving_Frequency>;
};

/** aggregate fields of "answer_enums.driving_frequency" */
export type Answer_Enums_Driving_Frequency_Aggregate_Fields = {
  __typename?: 'answer_enums_driving_frequency_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Answer_Enums_Driving_Frequency_Max_Fields>;
  min?: Maybe<Answer_Enums_Driving_Frequency_Min_Fields>;
};


/** aggregate fields of "answer_enums.driving_frequency" */
export type Answer_Enums_Driving_Frequency_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Answer_Enums_Driving_Frequency_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "answer_enums.driving_frequency". All fields are combined with a logical 'AND'. */
export type Answer_Enums_Driving_Frequency_Bool_Exp = {
  _and?: InputMaybe<Array<Answer_Enums_Driving_Frequency_Bool_Exp>>;
  _not?: InputMaybe<Answer_Enums_Driving_Frequency_Bool_Exp>;
  _or?: InputMaybe<Array<Answer_Enums_Driving_Frequency_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "answer_enums.driving_frequency" */
export enum Answer_Enums_Driving_Frequency_Constraint {
  /** unique or primary key constraint on columns "value" */
  DrivingFrequencyPkey = 'driving_frequency_pkey'
}

export enum Answer_Enums_Driving_Frequency_Enum {
  /** Alle 4 Wochen */
  EveryFourWeeks = 'every_four_weeks',
  /** Alle 2 Wochen */
  EveryTwoWeeks = 'every_two_weeks',
  /** Einmal die Woche */
  OneTimeAWeek = 'one_time_a_week',
  /** Mehrmals die Woche */
  SeveralTimesAWeek = 'several_times_a_week'
}

/** Boolean expression to compare columns of type "answer_enums_driving_frequency_enum". All fields are combined with logical 'AND'. */
export type Answer_Enums_Driving_Frequency_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Answer_Enums_Driving_Frequency_Enum>;
  _in?: InputMaybe<Array<Answer_Enums_Driving_Frequency_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Answer_Enums_Driving_Frequency_Enum>;
  _nin?: InputMaybe<Array<Answer_Enums_Driving_Frequency_Enum>>;
};

/** input type for inserting data into table "answer_enums.driving_frequency" */
export type Answer_Enums_Driving_Frequency_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Answer_Enums_Driving_Frequency_Max_Fields = {
  __typename?: 'answer_enums_driving_frequency_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Answer_Enums_Driving_Frequency_Min_Fields = {
  __typename?: 'answer_enums_driving_frequency_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "answer_enums.driving_frequency" */
export type Answer_Enums_Driving_Frequency_Mutation_Response = {
  __typename?: 'answer_enums_driving_frequency_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Answer_Enums_Driving_Frequency>;
};

/** input type for inserting object relation for remote table "answer_enums.driving_frequency" */
export type Answer_Enums_Driving_Frequency_Obj_Rel_Insert_Input = {
  data: Answer_Enums_Driving_Frequency_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Answer_Enums_Driving_Frequency_On_Conflict>;
};

/** on_conflict condition type for table "answer_enums.driving_frequency" */
export type Answer_Enums_Driving_Frequency_On_Conflict = {
  constraint: Answer_Enums_Driving_Frequency_Constraint;
  update_columns?: Array<Answer_Enums_Driving_Frequency_Update_Column>;
  where?: InputMaybe<Answer_Enums_Driving_Frequency_Bool_Exp>;
};

/** Ordering options when selecting data from "answer_enums.driving_frequency". */
export type Answer_Enums_Driving_Frequency_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: answer_enums.driving_frequency */
export type Answer_Enums_Driving_Frequency_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "answer_enums.driving_frequency" */
export enum Answer_Enums_Driving_Frequency_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "answer_enums.driving_frequency" */
export type Answer_Enums_Driving_Frequency_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "answer_enums_driving_frequency" */
export type Answer_Enums_Driving_Frequency_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Answer_Enums_Driving_Frequency_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Answer_Enums_Driving_Frequency_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "answer_enums.driving_frequency" */
export enum Answer_Enums_Driving_Frequency_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type Answer_Enums_Driving_Frequency_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Answer_Enums_Driving_Frequency_Set_Input>;
  /** filter the rows which have to be updated */
  where: Answer_Enums_Driving_Frequency_Bool_Exp;
};

/** columns and relationships of "answer_enums.e_bike" */
export type Answer_Enums_E_Bike = {
  __typename?: 'answer_enums_e_bike';
  description: Scalars['String'];
  value: Scalars['Boolean'];
};

/** aggregated selection of "answer_enums.e_bike" */
export type Answer_Enums_E_Bike_Aggregate = {
  __typename?: 'answer_enums_e_bike_aggregate';
  aggregate?: Maybe<Answer_Enums_E_Bike_Aggregate_Fields>;
  nodes: Array<Answer_Enums_E_Bike>;
};

/** aggregate fields of "answer_enums.e_bike" */
export type Answer_Enums_E_Bike_Aggregate_Fields = {
  __typename?: 'answer_enums_e_bike_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Answer_Enums_E_Bike_Max_Fields>;
  min?: Maybe<Answer_Enums_E_Bike_Min_Fields>;
};


/** aggregate fields of "answer_enums.e_bike" */
export type Answer_Enums_E_Bike_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Answer_Enums_E_Bike_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "answer_enums.e_bike". All fields are combined with a logical 'AND'. */
export type Answer_Enums_E_Bike_Bool_Exp = {
  _and?: InputMaybe<Array<Answer_Enums_E_Bike_Bool_Exp>>;
  _not?: InputMaybe<Answer_Enums_E_Bike_Bool_Exp>;
  _or?: InputMaybe<Array<Answer_Enums_E_Bike_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "answer_enums.e_bike" */
export enum Answer_Enums_E_Bike_Constraint {
  /** unique or primary key constraint on columns "value" */
  EBikePkey = 'e_bike_pkey'
}

/** input type for inserting data into table "answer_enums.e_bike" */
export type Answer_Enums_E_Bike_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Answer_Enums_E_Bike_Max_Fields = {
  __typename?: 'answer_enums_e_bike_max_fields';
  description?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Answer_Enums_E_Bike_Min_Fields = {
  __typename?: 'answer_enums_e_bike_min_fields';
  description?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "answer_enums.e_bike" */
export type Answer_Enums_E_Bike_Mutation_Response = {
  __typename?: 'answer_enums_e_bike_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Answer_Enums_E_Bike>;
};

/** input type for inserting object relation for remote table "answer_enums.e_bike" */
export type Answer_Enums_E_Bike_Obj_Rel_Insert_Input = {
  data: Answer_Enums_E_Bike_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Answer_Enums_E_Bike_On_Conflict>;
};

/** on_conflict condition type for table "answer_enums.e_bike" */
export type Answer_Enums_E_Bike_On_Conflict = {
  constraint: Answer_Enums_E_Bike_Constraint;
  update_columns?: Array<Answer_Enums_E_Bike_Update_Column>;
  where?: InputMaybe<Answer_Enums_E_Bike_Bool_Exp>;
};

/** Ordering options when selecting data from "answer_enums.e_bike". */
export type Answer_Enums_E_Bike_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: answer_enums.e_bike */
export type Answer_Enums_E_Bike_Pk_Columns_Input = {
  value: Scalars['Boolean'];
};

/** select columns of table "answer_enums.e_bike" */
export enum Answer_Enums_E_Bike_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "answer_enums.e_bike" */
export type Answer_Enums_E_Bike_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Boolean']>;
};

/** Streaming cursor of the table "answer_enums_e_bike" */
export type Answer_Enums_E_Bike_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Answer_Enums_E_Bike_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Answer_Enums_E_Bike_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Boolean']>;
};

/** update columns of table "answer_enums.e_bike" */
export enum Answer_Enums_E_Bike_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type Answer_Enums_E_Bike_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Answer_Enums_E_Bike_Set_Input>;
  /** filter the rows which have to be updated */
  where: Answer_Enums_E_Bike_Bool_Exp;
};

/** columns and relationships of "answer_enums.foot_position" */
export type Answer_Enums_Foot_Position = {
  __typename?: 'answer_enums_foot_position';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "answer_enums.foot_position" */
export type Answer_Enums_Foot_Position_Aggregate = {
  __typename?: 'answer_enums_foot_position_aggregate';
  aggregate?: Maybe<Answer_Enums_Foot_Position_Aggregate_Fields>;
  nodes: Array<Answer_Enums_Foot_Position>;
};

/** aggregate fields of "answer_enums.foot_position" */
export type Answer_Enums_Foot_Position_Aggregate_Fields = {
  __typename?: 'answer_enums_foot_position_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Answer_Enums_Foot_Position_Max_Fields>;
  min?: Maybe<Answer_Enums_Foot_Position_Min_Fields>;
};


/** aggregate fields of "answer_enums.foot_position" */
export type Answer_Enums_Foot_Position_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Answer_Enums_Foot_Position_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "answer_enums.foot_position". All fields are combined with a logical 'AND'. */
export type Answer_Enums_Foot_Position_Bool_Exp = {
  _and?: InputMaybe<Array<Answer_Enums_Foot_Position_Bool_Exp>>;
  _not?: InputMaybe<Answer_Enums_Foot_Position_Bool_Exp>;
  _or?: InputMaybe<Array<Answer_Enums_Foot_Position_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "answer_enums.foot_position" */
export enum Answer_Enums_Foot_Position_Constraint {
  /** unique or primary key constraint on columns "value" */
  FootPositionPkey = 'foot_position_pkey'
}

export enum Answer_Enums_Foot_Position_Enum {
  /** Gerade */
  Straight = 'straight',
  /** V-förmig */
  V = 'v'
}

/** Boolean expression to compare columns of type "answer_enums_foot_position_enum". All fields are combined with logical 'AND'. */
export type Answer_Enums_Foot_Position_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Answer_Enums_Foot_Position_Enum>;
  _in?: InputMaybe<Array<Answer_Enums_Foot_Position_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Answer_Enums_Foot_Position_Enum>;
  _nin?: InputMaybe<Array<Answer_Enums_Foot_Position_Enum>>;
};

/** input type for inserting data into table "answer_enums.foot_position" */
export type Answer_Enums_Foot_Position_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Answer_Enums_Foot_Position_Max_Fields = {
  __typename?: 'answer_enums_foot_position_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Answer_Enums_Foot_Position_Min_Fields = {
  __typename?: 'answer_enums_foot_position_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "answer_enums.foot_position" */
export type Answer_Enums_Foot_Position_Mutation_Response = {
  __typename?: 'answer_enums_foot_position_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Answer_Enums_Foot_Position>;
};

/** input type for inserting object relation for remote table "answer_enums.foot_position" */
export type Answer_Enums_Foot_Position_Obj_Rel_Insert_Input = {
  data: Answer_Enums_Foot_Position_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Answer_Enums_Foot_Position_On_Conflict>;
};

/** on_conflict condition type for table "answer_enums.foot_position" */
export type Answer_Enums_Foot_Position_On_Conflict = {
  constraint: Answer_Enums_Foot_Position_Constraint;
  update_columns?: Array<Answer_Enums_Foot_Position_Update_Column>;
  where?: InputMaybe<Answer_Enums_Foot_Position_Bool_Exp>;
};

/** Ordering options when selecting data from "answer_enums.foot_position". */
export type Answer_Enums_Foot_Position_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: answer_enums.foot_position */
export type Answer_Enums_Foot_Position_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "answer_enums.foot_position" */
export enum Answer_Enums_Foot_Position_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "answer_enums.foot_position" */
export type Answer_Enums_Foot_Position_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "answer_enums_foot_position" */
export type Answer_Enums_Foot_Position_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Answer_Enums_Foot_Position_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Answer_Enums_Foot_Position_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "answer_enums.foot_position" */
export enum Answer_Enums_Foot_Position_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type Answer_Enums_Foot_Position_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Answer_Enums_Foot_Position_Set_Input>;
  /** filter the rows which have to be updated */
  where: Answer_Enums_Foot_Position_Bool_Exp;
};

/** columns and relationships of "answer_enums.foot_problems" */
export type Answer_Enums_Foot_Problems = {
  __typename?: 'answer_enums_foot_problems';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "answer_enums.foot_problems" */
export type Answer_Enums_Foot_Problems_Aggregate = {
  __typename?: 'answer_enums_foot_problems_aggregate';
  aggregate?: Maybe<Answer_Enums_Foot_Problems_Aggregate_Fields>;
  nodes: Array<Answer_Enums_Foot_Problems>;
};

/** aggregate fields of "answer_enums.foot_problems" */
export type Answer_Enums_Foot_Problems_Aggregate_Fields = {
  __typename?: 'answer_enums_foot_problems_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Answer_Enums_Foot_Problems_Max_Fields>;
  min?: Maybe<Answer_Enums_Foot_Problems_Min_Fields>;
};


/** aggregate fields of "answer_enums.foot_problems" */
export type Answer_Enums_Foot_Problems_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Answer_Enums_Foot_Problems_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "answer_enums.foot_problems". All fields are combined with a logical 'AND'. */
export type Answer_Enums_Foot_Problems_Bool_Exp = {
  _and?: InputMaybe<Array<Answer_Enums_Foot_Problems_Bool_Exp>>;
  _not?: InputMaybe<Answer_Enums_Foot_Problems_Bool_Exp>;
  _or?: InputMaybe<Array<Answer_Enums_Foot_Problems_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "answer_enums.foot_problems" */
export enum Answer_Enums_Foot_Problems_Constraint {
  /** unique or primary key constraint on columns "value" */
  FootProblemsPkey = 'foot_problems_pkey'
}

export enum Answer_Enums_Foot_Problems_Enum {
  /** Knie Beschwerden */
  KneeDiscomfort = 'knee_discomfort',
  /** Taubheitsgefühle Zehen */
  NumbToes = 'numb_toes',
  /** Fuß Beschwerden */
  YesFootProblems = 'yes_foot_problems'
}

/** Boolean expression to compare columns of type "answer_enums_foot_problems_enum". All fields are combined with logical 'AND'. */
export type Answer_Enums_Foot_Problems_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Answer_Enums_Foot_Problems_Enum>;
  _in?: InputMaybe<Array<Answer_Enums_Foot_Problems_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Answer_Enums_Foot_Problems_Enum>;
  _nin?: InputMaybe<Array<Answer_Enums_Foot_Problems_Enum>>;
};

/** input type for inserting data into table "answer_enums.foot_problems" */
export type Answer_Enums_Foot_Problems_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Answer_Enums_Foot_Problems_Max_Fields = {
  __typename?: 'answer_enums_foot_problems_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Answer_Enums_Foot_Problems_Min_Fields = {
  __typename?: 'answer_enums_foot_problems_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "answer_enums.foot_problems" */
export type Answer_Enums_Foot_Problems_Mutation_Response = {
  __typename?: 'answer_enums_foot_problems_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Answer_Enums_Foot_Problems>;
};

/** input type for inserting object relation for remote table "answer_enums.foot_problems" */
export type Answer_Enums_Foot_Problems_Obj_Rel_Insert_Input = {
  data: Answer_Enums_Foot_Problems_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Answer_Enums_Foot_Problems_On_Conflict>;
};

/** on_conflict condition type for table "answer_enums.foot_problems" */
export type Answer_Enums_Foot_Problems_On_Conflict = {
  constraint: Answer_Enums_Foot_Problems_Constraint;
  update_columns?: Array<Answer_Enums_Foot_Problems_Update_Column>;
  where?: InputMaybe<Answer_Enums_Foot_Problems_Bool_Exp>;
};

/** Ordering options when selecting data from "answer_enums.foot_problems". */
export type Answer_Enums_Foot_Problems_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: answer_enums.foot_problems */
export type Answer_Enums_Foot_Problems_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "answer_enums.foot_problems" */
export enum Answer_Enums_Foot_Problems_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "answer_enums.foot_problems" */
export type Answer_Enums_Foot_Problems_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "answer_enums_foot_problems" */
export type Answer_Enums_Foot_Problems_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Answer_Enums_Foot_Problems_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Answer_Enums_Foot_Problems_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "answer_enums.foot_problems" */
export enum Answer_Enums_Foot_Problems_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type Answer_Enums_Foot_Problems_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Answer_Enums_Foot_Problems_Set_Input>;
  /** filter the rows which have to be updated */
  where: Answer_Enums_Foot_Problems_Bool_Exp;
};

/** columns and relationships of "answer_enums.foot_type" */
export type Answer_Enums_Foot_Type = {
  __typename?: 'answer_enums_foot_type';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "answer_enums.foot_type" */
export type Answer_Enums_Foot_Type_Aggregate = {
  __typename?: 'answer_enums_foot_type_aggregate';
  aggregate?: Maybe<Answer_Enums_Foot_Type_Aggregate_Fields>;
  nodes: Array<Answer_Enums_Foot_Type>;
};

/** aggregate fields of "answer_enums.foot_type" */
export type Answer_Enums_Foot_Type_Aggregate_Fields = {
  __typename?: 'answer_enums_foot_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Answer_Enums_Foot_Type_Max_Fields>;
  min?: Maybe<Answer_Enums_Foot_Type_Min_Fields>;
};


/** aggregate fields of "answer_enums.foot_type" */
export type Answer_Enums_Foot_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Answer_Enums_Foot_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "answer_enums.foot_type". All fields are combined with a logical 'AND'. */
export type Answer_Enums_Foot_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Answer_Enums_Foot_Type_Bool_Exp>>;
  _not?: InputMaybe<Answer_Enums_Foot_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Answer_Enums_Foot_Type_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "answer_enums.foot_type" */
export enum Answer_Enums_Foot_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  FootTypePkey = 'foot_type_pkey'
}

export enum Answer_Enums_Foot_Type_Enum {
  /** Senkfuß (C) */
  ArchedFoot = 'arched_foot',
  /** Hohlfuß (A) */
  ArchesFoot = 'arches_foot',
  /** Plattfuß (D) */
  FlatFoot = 'flat_foot',
  /** Normalfuß (B) */
  Normal = 'normal'
}

/** Boolean expression to compare columns of type "answer_enums_foot_type_enum". All fields are combined with logical 'AND'. */
export type Answer_Enums_Foot_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Answer_Enums_Foot_Type_Enum>;
  _in?: InputMaybe<Array<Answer_Enums_Foot_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Answer_Enums_Foot_Type_Enum>;
  _nin?: InputMaybe<Array<Answer_Enums_Foot_Type_Enum>>;
};

/** input type for inserting data into table "answer_enums.foot_type" */
export type Answer_Enums_Foot_Type_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Answer_Enums_Foot_Type_Max_Fields = {
  __typename?: 'answer_enums_foot_type_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Answer_Enums_Foot_Type_Min_Fields = {
  __typename?: 'answer_enums_foot_type_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "answer_enums.foot_type" */
export type Answer_Enums_Foot_Type_Mutation_Response = {
  __typename?: 'answer_enums_foot_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Answer_Enums_Foot_Type>;
};

/** input type for inserting object relation for remote table "answer_enums.foot_type" */
export type Answer_Enums_Foot_Type_Obj_Rel_Insert_Input = {
  data: Answer_Enums_Foot_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Answer_Enums_Foot_Type_On_Conflict>;
};

/** on_conflict condition type for table "answer_enums.foot_type" */
export type Answer_Enums_Foot_Type_On_Conflict = {
  constraint: Answer_Enums_Foot_Type_Constraint;
  update_columns?: Array<Answer_Enums_Foot_Type_Update_Column>;
  where?: InputMaybe<Answer_Enums_Foot_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "answer_enums.foot_type". */
export type Answer_Enums_Foot_Type_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: answer_enums.foot_type */
export type Answer_Enums_Foot_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "answer_enums.foot_type" */
export enum Answer_Enums_Foot_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "answer_enums.foot_type" */
export type Answer_Enums_Foot_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "answer_enums_foot_type" */
export type Answer_Enums_Foot_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Answer_Enums_Foot_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Answer_Enums_Foot_Type_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "answer_enums.foot_type" */
export enum Answer_Enums_Foot_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type Answer_Enums_Foot_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Answer_Enums_Foot_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Answer_Enums_Foot_Type_Bool_Exp;
};

/** columns and relationships of "answer_enums.gripping_distance" */
export type Answer_Enums_Gripping_Distance = {
  __typename?: 'answer_enums_gripping_distance';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "answer_enums.gripping_distance" */
export type Answer_Enums_Gripping_Distance_Aggregate = {
  __typename?: 'answer_enums_gripping_distance_aggregate';
  aggregate?: Maybe<Answer_Enums_Gripping_Distance_Aggregate_Fields>;
  nodes: Array<Answer_Enums_Gripping_Distance>;
};

/** aggregate fields of "answer_enums.gripping_distance" */
export type Answer_Enums_Gripping_Distance_Aggregate_Fields = {
  __typename?: 'answer_enums_gripping_distance_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Answer_Enums_Gripping_Distance_Max_Fields>;
  min?: Maybe<Answer_Enums_Gripping_Distance_Min_Fields>;
};


/** aggregate fields of "answer_enums.gripping_distance" */
export type Answer_Enums_Gripping_Distance_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Answer_Enums_Gripping_Distance_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "answer_enums.gripping_distance". All fields are combined with a logical 'AND'. */
export type Answer_Enums_Gripping_Distance_Bool_Exp = {
  _and?: InputMaybe<Array<Answer_Enums_Gripping_Distance_Bool_Exp>>;
  _not?: InputMaybe<Answer_Enums_Gripping_Distance_Bool_Exp>;
  _or?: InputMaybe<Array<Answer_Enums_Gripping_Distance_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "answer_enums.gripping_distance" */
export enum Answer_Enums_Gripping_Distance_Constraint {
  /** unique or primary key constraint on columns "value" */
  GrippingDistancePkey = 'gripping_distance_pkey'
}

export enum Answer_Enums_Gripping_Distance_Enum {
  /** L */
  L = 'L',
  /** M */
  M = 'M',
  /** S */
  S = 'S'
}

/** Boolean expression to compare columns of type "answer_enums_gripping_distance_enum". All fields are combined with logical 'AND'. */
export type Answer_Enums_Gripping_Distance_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Answer_Enums_Gripping_Distance_Enum>;
  _in?: InputMaybe<Array<Answer_Enums_Gripping_Distance_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Answer_Enums_Gripping_Distance_Enum>;
  _nin?: InputMaybe<Array<Answer_Enums_Gripping_Distance_Enum>>;
};

/** input type for inserting data into table "answer_enums.gripping_distance" */
export type Answer_Enums_Gripping_Distance_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Answer_Enums_Gripping_Distance_Max_Fields = {
  __typename?: 'answer_enums_gripping_distance_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Answer_Enums_Gripping_Distance_Min_Fields = {
  __typename?: 'answer_enums_gripping_distance_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "answer_enums.gripping_distance" */
export type Answer_Enums_Gripping_Distance_Mutation_Response = {
  __typename?: 'answer_enums_gripping_distance_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Answer_Enums_Gripping_Distance>;
};

/** on_conflict condition type for table "answer_enums.gripping_distance" */
export type Answer_Enums_Gripping_Distance_On_Conflict = {
  constraint: Answer_Enums_Gripping_Distance_Constraint;
  update_columns?: Array<Answer_Enums_Gripping_Distance_Update_Column>;
  where?: InputMaybe<Answer_Enums_Gripping_Distance_Bool_Exp>;
};

/** Ordering options when selecting data from "answer_enums.gripping_distance". */
export type Answer_Enums_Gripping_Distance_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: answer_enums.gripping_distance */
export type Answer_Enums_Gripping_Distance_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "answer_enums.gripping_distance" */
export enum Answer_Enums_Gripping_Distance_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "answer_enums.gripping_distance" */
export type Answer_Enums_Gripping_Distance_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "answer_enums_gripping_distance" */
export type Answer_Enums_Gripping_Distance_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Answer_Enums_Gripping_Distance_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Answer_Enums_Gripping_Distance_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "answer_enums.gripping_distance" */
export enum Answer_Enums_Gripping_Distance_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type Answer_Enums_Gripping_Distance_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Answer_Enums_Gripping_Distance_Set_Input>;
  /** filter the rows which have to be updated */
  where: Answer_Enums_Gripping_Distance_Bool_Exp;
};

/** columns and relationships of "answer_enums.hand_problems" */
export type Answer_Enums_Hand_Problems = {
  __typename?: 'answer_enums_hand_problems';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "answer_enums.hand_problems" */
export type Answer_Enums_Hand_Problems_Aggregate = {
  __typename?: 'answer_enums_hand_problems_aggregate';
  aggregate?: Maybe<Answer_Enums_Hand_Problems_Aggregate_Fields>;
  nodes: Array<Answer_Enums_Hand_Problems>;
};

/** aggregate fields of "answer_enums.hand_problems" */
export type Answer_Enums_Hand_Problems_Aggregate_Fields = {
  __typename?: 'answer_enums_hand_problems_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Answer_Enums_Hand_Problems_Max_Fields>;
  min?: Maybe<Answer_Enums_Hand_Problems_Min_Fields>;
};


/** aggregate fields of "answer_enums.hand_problems" */
export type Answer_Enums_Hand_Problems_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Answer_Enums_Hand_Problems_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "answer_enums.hand_problems". All fields are combined with a logical 'AND'. */
export type Answer_Enums_Hand_Problems_Bool_Exp = {
  _and?: InputMaybe<Array<Answer_Enums_Hand_Problems_Bool_Exp>>;
  _not?: InputMaybe<Answer_Enums_Hand_Problems_Bool_Exp>;
  _or?: InputMaybe<Array<Answer_Enums_Hand_Problems_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "answer_enums.hand_problems" */
export enum Answer_Enums_Hand_Problems_Constraint {
  /** unique or primary key constraint on columns "value" */
  HandProblemsPkey = 'hand_problems_pkey'
}

export enum Answer_Enums_Hand_Problems_Enum {
  /** Tauber Kleiner und Ringfinger */
  NumbLittleAndRingFinger = 'numb_little_and_ring_finger',
  /** Tauber Daumen, Zeige- und Mittelfinger */
  NumbThumbIndexAndMiddleFinger = 'numb_thumb_index_and_middle_finger',
  /** Schmerzen oberer Rücken (Schultern, Nacken, Halswirbel) */
  UpperBack = 'upper_back',
  /** Handgelenk Schmerzen */
  Wrist = 'wrist'
}

/** Boolean expression to compare columns of type "answer_enums_hand_problems_enum". All fields are combined with logical 'AND'. */
export type Answer_Enums_Hand_Problems_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Answer_Enums_Hand_Problems_Enum>;
  _in?: InputMaybe<Array<Answer_Enums_Hand_Problems_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Answer_Enums_Hand_Problems_Enum>;
  _nin?: InputMaybe<Array<Answer_Enums_Hand_Problems_Enum>>;
};

/** input type for inserting data into table "answer_enums.hand_problems" */
export type Answer_Enums_Hand_Problems_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Answer_Enums_Hand_Problems_Max_Fields = {
  __typename?: 'answer_enums_hand_problems_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Answer_Enums_Hand_Problems_Min_Fields = {
  __typename?: 'answer_enums_hand_problems_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "answer_enums.hand_problems" */
export type Answer_Enums_Hand_Problems_Mutation_Response = {
  __typename?: 'answer_enums_hand_problems_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Answer_Enums_Hand_Problems>;
};

/** input type for inserting object relation for remote table "answer_enums.hand_problems" */
export type Answer_Enums_Hand_Problems_Obj_Rel_Insert_Input = {
  data: Answer_Enums_Hand_Problems_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Answer_Enums_Hand_Problems_On_Conflict>;
};

/** on_conflict condition type for table "answer_enums.hand_problems" */
export type Answer_Enums_Hand_Problems_On_Conflict = {
  constraint: Answer_Enums_Hand_Problems_Constraint;
  update_columns?: Array<Answer_Enums_Hand_Problems_Update_Column>;
  where?: InputMaybe<Answer_Enums_Hand_Problems_Bool_Exp>;
};

/** Ordering options when selecting data from "answer_enums.hand_problems". */
export type Answer_Enums_Hand_Problems_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: answer_enums.hand_problems */
export type Answer_Enums_Hand_Problems_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "answer_enums.hand_problems" */
export enum Answer_Enums_Hand_Problems_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "answer_enums.hand_problems" */
export type Answer_Enums_Hand_Problems_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "answer_enums_hand_problems" */
export type Answer_Enums_Hand_Problems_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Answer_Enums_Hand_Problems_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Answer_Enums_Hand_Problems_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "answer_enums.hand_problems" */
export enum Answer_Enums_Hand_Problems_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type Answer_Enums_Hand_Problems_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Answer_Enums_Hand_Problems_Set_Input>;
  /** filter the rows which have to be updated */
  where: Answer_Enums_Hand_Problems_Bool_Exp;
};

/** columns and relationships of "answer_enums.leg_axis" */
export type Answer_Enums_Leg_Axis = {
  __typename?: 'answer_enums_leg_axis';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "answer_enums.leg_axis" */
export type Answer_Enums_Leg_Axis_Aggregate = {
  __typename?: 'answer_enums_leg_axis_aggregate';
  aggregate?: Maybe<Answer_Enums_Leg_Axis_Aggregate_Fields>;
  nodes: Array<Answer_Enums_Leg_Axis>;
};

/** aggregate fields of "answer_enums.leg_axis" */
export type Answer_Enums_Leg_Axis_Aggregate_Fields = {
  __typename?: 'answer_enums_leg_axis_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Answer_Enums_Leg_Axis_Max_Fields>;
  min?: Maybe<Answer_Enums_Leg_Axis_Min_Fields>;
};


/** aggregate fields of "answer_enums.leg_axis" */
export type Answer_Enums_Leg_Axis_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Answer_Enums_Leg_Axis_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "answer_enums.leg_axis". All fields are combined with a logical 'AND'. */
export type Answer_Enums_Leg_Axis_Bool_Exp = {
  _and?: InputMaybe<Array<Answer_Enums_Leg_Axis_Bool_Exp>>;
  _not?: InputMaybe<Answer_Enums_Leg_Axis_Bool_Exp>;
  _or?: InputMaybe<Array<Answer_Enums_Leg_Axis_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "answer_enums.leg_axis" */
export enum Answer_Enums_Leg_Axis_Constraint {
  /** unique or primary key constraint on columns "value" */
  LegAxisPkey = 'leg_axis_pkey'
}

export enum Answer_Enums_Leg_Axis_Enum {
  /** O-Beine (1) */
  OLegs = 'o_legs',
  /** Gerade Beine (2) */
  StraightLegs = 'straight_legs',
  /** X-Beine (3) */
  XLegs = 'x_legs'
}

/** Boolean expression to compare columns of type "answer_enums_leg_axis_enum". All fields are combined with logical 'AND'. */
export type Answer_Enums_Leg_Axis_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Answer_Enums_Leg_Axis_Enum>;
  _in?: InputMaybe<Array<Answer_Enums_Leg_Axis_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Answer_Enums_Leg_Axis_Enum>;
  _nin?: InputMaybe<Array<Answer_Enums_Leg_Axis_Enum>>;
};

/** input type for inserting data into table "answer_enums.leg_axis" */
export type Answer_Enums_Leg_Axis_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Answer_Enums_Leg_Axis_Max_Fields = {
  __typename?: 'answer_enums_leg_axis_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Answer_Enums_Leg_Axis_Min_Fields = {
  __typename?: 'answer_enums_leg_axis_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "answer_enums.leg_axis" */
export type Answer_Enums_Leg_Axis_Mutation_Response = {
  __typename?: 'answer_enums_leg_axis_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Answer_Enums_Leg_Axis>;
};

/** input type for inserting object relation for remote table "answer_enums.leg_axis" */
export type Answer_Enums_Leg_Axis_Obj_Rel_Insert_Input = {
  data: Answer_Enums_Leg_Axis_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Answer_Enums_Leg_Axis_On_Conflict>;
};

/** on_conflict condition type for table "answer_enums.leg_axis" */
export type Answer_Enums_Leg_Axis_On_Conflict = {
  constraint: Answer_Enums_Leg_Axis_Constraint;
  update_columns?: Array<Answer_Enums_Leg_Axis_Update_Column>;
  where?: InputMaybe<Answer_Enums_Leg_Axis_Bool_Exp>;
};

/** Ordering options when selecting data from "answer_enums.leg_axis". */
export type Answer_Enums_Leg_Axis_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: answer_enums.leg_axis */
export type Answer_Enums_Leg_Axis_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "answer_enums.leg_axis" */
export enum Answer_Enums_Leg_Axis_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "answer_enums.leg_axis" */
export type Answer_Enums_Leg_Axis_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "answer_enums_leg_axis" */
export type Answer_Enums_Leg_Axis_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Answer_Enums_Leg_Axis_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Answer_Enums_Leg_Axis_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "answer_enums.leg_axis" */
export enum Answer_Enums_Leg_Axis_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type Answer_Enums_Leg_Axis_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Answer_Enums_Leg_Axis_Set_Input>;
  /** filter the rows which have to be updated */
  where: Answer_Enums_Leg_Axis_Bool_Exp;
};

/** columns and relationships of "answer_enums.pelvic_problems" */
export type Answer_Enums_Pelvic_Problems = {
  __typename?: 'answer_enums_pelvic_problems';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "answer_enums.pelvic_problems" */
export type Answer_Enums_Pelvic_Problems_Aggregate = {
  __typename?: 'answer_enums_pelvic_problems_aggregate';
  aggregate?: Maybe<Answer_Enums_Pelvic_Problems_Aggregate_Fields>;
  nodes: Array<Answer_Enums_Pelvic_Problems>;
};

/** aggregate fields of "answer_enums.pelvic_problems" */
export type Answer_Enums_Pelvic_Problems_Aggregate_Fields = {
  __typename?: 'answer_enums_pelvic_problems_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Answer_Enums_Pelvic_Problems_Max_Fields>;
  min?: Maybe<Answer_Enums_Pelvic_Problems_Min_Fields>;
};


/** aggregate fields of "answer_enums.pelvic_problems" */
export type Answer_Enums_Pelvic_Problems_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Answer_Enums_Pelvic_Problems_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "answer_enums.pelvic_problems". All fields are combined with a logical 'AND'. */
export type Answer_Enums_Pelvic_Problems_Bool_Exp = {
  _and?: InputMaybe<Array<Answer_Enums_Pelvic_Problems_Bool_Exp>>;
  _not?: InputMaybe<Answer_Enums_Pelvic_Problems_Bool_Exp>;
  _or?: InputMaybe<Array<Answer_Enums_Pelvic_Problems_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "answer_enums.pelvic_problems" */
export enum Answer_Enums_Pelvic_Problems_Constraint {
  /** unique or primary key constraint on columns "value" */
  PelvicProblemsPkey = 'pelvic_problems_pkey'
}

export enum Answer_Enums_Pelvic_Problems_Enum {
  /** Steißbein Schmerzen */
  Coccyx = 'coccyx',
  /** Hitze, Schweiß */
  HeatSweat = 'heat_sweat',
  /** Schmerzen unterer Rücken */
  LowerBack = 'lower_back',
  /** Taubheitsgefühle */
  Numbness = 'numbness',
  /** Beckenfehlstellung */
  PelvicMalalignment = 'pelvic_malalignment',
  /** Druck im Intimbereich */
  PressureIntimateArea = 'pressure_intimate_area',
  /** Prostata (Mann) */
  Prostate = 'prostate',
  /** Prostata Beschwerden */
  ProstateProblems = 'prostate_problems',
  /** Schambeinbogen (Frau) */
  PubicBone = 'pubic_bone',
  /** Sitzknochen Schmerzen */
  Sitbones = 'sitbones',
  /** Wundreibungen */
  WoundFriction = 'wound_friction'
}

/** Boolean expression to compare columns of type "answer_enums_pelvic_problems_enum". All fields are combined with logical 'AND'. */
export type Answer_Enums_Pelvic_Problems_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Answer_Enums_Pelvic_Problems_Enum>;
  _in?: InputMaybe<Array<Answer_Enums_Pelvic_Problems_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Answer_Enums_Pelvic_Problems_Enum>;
  _nin?: InputMaybe<Array<Answer_Enums_Pelvic_Problems_Enum>>;
};

/** input type for inserting data into table "answer_enums.pelvic_problems" */
export type Answer_Enums_Pelvic_Problems_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Answer_Enums_Pelvic_Problems_Max_Fields = {
  __typename?: 'answer_enums_pelvic_problems_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Answer_Enums_Pelvic_Problems_Min_Fields = {
  __typename?: 'answer_enums_pelvic_problems_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "answer_enums.pelvic_problems" */
export type Answer_Enums_Pelvic_Problems_Mutation_Response = {
  __typename?: 'answer_enums_pelvic_problems_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Answer_Enums_Pelvic_Problems>;
};

/** input type for inserting object relation for remote table "answer_enums.pelvic_problems" */
export type Answer_Enums_Pelvic_Problems_Obj_Rel_Insert_Input = {
  data: Answer_Enums_Pelvic_Problems_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Answer_Enums_Pelvic_Problems_On_Conflict>;
};

/** on_conflict condition type for table "answer_enums.pelvic_problems" */
export type Answer_Enums_Pelvic_Problems_On_Conflict = {
  constraint: Answer_Enums_Pelvic_Problems_Constraint;
  update_columns?: Array<Answer_Enums_Pelvic_Problems_Update_Column>;
  where?: InputMaybe<Answer_Enums_Pelvic_Problems_Bool_Exp>;
};

/** Ordering options when selecting data from "answer_enums.pelvic_problems". */
export type Answer_Enums_Pelvic_Problems_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: answer_enums.pelvic_problems */
export type Answer_Enums_Pelvic_Problems_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "answer_enums.pelvic_problems" */
export enum Answer_Enums_Pelvic_Problems_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "answer_enums.pelvic_problems" */
export type Answer_Enums_Pelvic_Problems_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "answer_enums_pelvic_problems" */
export type Answer_Enums_Pelvic_Problems_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Answer_Enums_Pelvic_Problems_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Answer_Enums_Pelvic_Problems_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "answer_enums.pelvic_problems" */
export enum Answer_Enums_Pelvic_Problems_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type Answer_Enums_Pelvic_Problems_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Answer_Enums_Pelvic_Problems_Set_Input>;
  /** filter the rows which have to be updated */
  where: Answer_Enums_Pelvic_Problems_Bool_Exp;
};

/** columns and relationships of "answer_enums.seat_position" */
export type Answer_Enums_Seat_Position = {
  __typename?: 'answer_enums_seat_position';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "answer_enums.seat_position" */
export type Answer_Enums_Seat_Position_Aggregate = {
  __typename?: 'answer_enums_seat_position_aggregate';
  aggregate?: Maybe<Answer_Enums_Seat_Position_Aggregate_Fields>;
  nodes: Array<Answer_Enums_Seat_Position>;
};

/** aggregate fields of "answer_enums.seat_position" */
export type Answer_Enums_Seat_Position_Aggregate_Fields = {
  __typename?: 'answer_enums_seat_position_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Answer_Enums_Seat_Position_Max_Fields>;
  min?: Maybe<Answer_Enums_Seat_Position_Min_Fields>;
};


/** aggregate fields of "answer_enums.seat_position" */
export type Answer_Enums_Seat_Position_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Answer_Enums_Seat_Position_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "answer_enums.seat_position". All fields are combined with a logical 'AND'. */
export type Answer_Enums_Seat_Position_Bool_Exp = {
  _and?: InputMaybe<Array<Answer_Enums_Seat_Position_Bool_Exp>>;
  _not?: InputMaybe<Answer_Enums_Seat_Position_Bool_Exp>;
  _or?: InputMaybe<Array<Answer_Enums_Seat_Position_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "answer_enums.seat_position" */
export enum Answer_Enums_Seat_Position_Constraint {
  /** unique or primary key constraint on columns "value" */
  SeatPositionPkey = 'seat_position_pkey'
}

export enum Answer_Enums_Seat_Position_Enum {
  /** Moderat (+2) */
  Moderate = 'moderate',
  /** Leicht gebeugt (+3) */
  SlightlyBent = 'slightly_bent',
  /** Sportlich (+1) */
  Sportive = 'sportive',
  /** Triathlon (+0) */
  Triathlon = 'triathlon',
  /** Aufrecht (+4) */
  Upright = 'upright'
}

/** Boolean expression to compare columns of type "answer_enums_seat_position_enum". All fields are combined with logical 'AND'. */
export type Answer_Enums_Seat_Position_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Answer_Enums_Seat_Position_Enum>;
  _in?: InputMaybe<Array<Answer_Enums_Seat_Position_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Answer_Enums_Seat_Position_Enum>;
  _nin?: InputMaybe<Array<Answer_Enums_Seat_Position_Enum>>;
};

/** input type for inserting data into table "answer_enums.seat_position" */
export type Answer_Enums_Seat_Position_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Answer_Enums_Seat_Position_Max_Fields = {
  __typename?: 'answer_enums_seat_position_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Answer_Enums_Seat_Position_Min_Fields = {
  __typename?: 'answer_enums_seat_position_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "answer_enums.seat_position" */
export type Answer_Enums_Seat_Position_Mutation_Response = {
  __typename?: 'answer_enums_seat_position_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Answer_Enums_Seat_Position>;
};

/** input type for inserting object relation for remote table "answer_enums.seat_position" */
export type Answer_Enums_Seat_Position_Obj_Rel_Insert_Input = {
  data: Answer_Enums_Seat_Position_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Answer_Enums_Seat_Position_On_Conflict>;
};

/** on_conflict condition type for table "answer_enums.seat_position" */
export type Answer_Enums_Seat_Position_On_Conflict = {
  constraint: Answer_Enums_Seat_Position_Constraint;
  update_columns?: Array<Answer_Enums_Seat_Position_Update_Column>;
  where?: InputMaybe<Answer_Enums_Seat_Position_Bool_Exp>;
};

/** Ordering options when selecting data from "answer_enums.seat_position". */
export type Answer_Enums_Seat_Position_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: answer_enums.seat_position */
export type Answer_Enums_Seat_Position_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "answer_enums.seat_position" */
export enum Answer_Enums_Seat_Position_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "answer_enums.seat_position" */
export type Answer_Enums_Seat_Position_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "answer_enums_seat_position" */
export type Answer_Enums_Seat_Position_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Answer_Enums_Seat_Position_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Answer_Enums_Seat_Position_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "answer_enums.seat_position" */
export enum Answer_Enums_Seat_Position_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type Answer_Enums_Seat_Position_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Answer_Enums_Seat_Position_Set_Input>;
  /** filter the rows which have to be updated */
  where: Answer_Enums_Seat_Position_Bool_Exp;
};

/** columns and relationships of "answer_enums.sex" */
export type Answer_Enums_Sex = {
  __typename?: 'answer_enums_sex';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "answer_enums.sex" */
export type Answer_Enums_Sex_Aggregate = {
  __typename?: 'answer_enums_sex_aggregate';
  aggregate?: Maybe<Answer_Enums_Sex_Aggregate_Fields>;
  nodes: Array<Answer_Enums_Sex>;
};

/** aggregate fields of "answer_enums.sex" */
export type Answer_Enums_Sex_Aggregate_Fields = {
  __typename?: 'answer_enums_sex_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Answer_Enums_Sex_Max_Fields>;
  min?: Maybe<Answer_Enums_Sex_Min_Fields>;
};


/** aggregate fields of "answer_enums.sex" */
export type Answer_Enums_Sex_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Answer_Enums_Sex_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "answer_enums.sex". All fields are combined with a logical 'AND'. */
export type Answer_Enums_Sex_Bool_Exp = {
  _and?: InputMaybe<Array<Answer_Enums_Sex_Bool_Exp>>;
  _not?: InputMaybe<Answer_Enums_Sex_Bool_Exp>;
  _or?: InputMaybe<Array<Answer_Enums_Sex_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "answer_enums.sex" */
export enum Answer_Enums_Sex_Constraint {
  /** unique or primary key constraint on columns "value" */
  SexPkey = 'sex_pkey'
}

export enum Answer_Enums_Sex_Enum {
  /** Divers */
  Divers = 'divers',
  /** Frau */
  Female = 'female',
  /** Mann */
  Male = 'male'
}

/** Boolean expression to compare columns of type "answer_enums_sex_enum". All fields are combined with logical 'AND'. */
export type Answer_Enums_Sex_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Answer_Enums_Sex_Enum>;
  _in?: InputMaybe<Array<Answer_Enums_Sex_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Answer_Enums_Sex_Enum>;
  _nin?: InputMaybe<Array<Answer_Enums_Sex_Enum>>;
};

/** input type for inserting data into table "answer_enums.sex" */
export type Answer_Enums_Sex_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Answer_Enums_Sex_Max_Fields = {
  __typename?: 'answer_enums_sex_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Answer_Enums_Sex_Min_Fields = {
  __typename?: 'answer_enums_sex_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "answer_enums.sex" */
export type Answer_Enums_Sex_Mutation_Response = {
  __typename?: 'answer_enums_sex_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Answer_Enums_Sex>;
};

/** input type for inserting object relation for remote table "answer_enums.sex" */
export type Answer_Enums_Sex_Obj_Rel_Insert_Input = {
  data: Answer_Enums_Sex_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Answer_Enums_Sex_On_Conflict>;
};

/** on_conflict condition type for table "answer_enums.sex" */
export type Answer_Enums_Sex_On_Conflict = {
  constraint: Answer_Enums_Sex_Constraint;
  update_columns?: Array<Answer_Enums_Sex_Update_Column>;
  where?: InputMaybe<Answer_Enums_Sex_Bool_Exp>;
};

/** Ordering options when selecting data from "answer_enums.sex". */
export type Answer_Enums_Sex_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: answer_enums.sex */
export type Answer_Enums_Sex_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "answer_enums.sex" */
export enum Answer_Enums_Sex_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "answer_enums.sex" */
export type Answer_Enums_Sex_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "answer_enums_sex" */
export type Answer_Enums_Sex_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Answer_Enums_Sex_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Answer_Enums_Sex_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "answer_enums.sex" */
export enum Answer_Enums_Sex_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type Answer_Enums_Sex_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Answer_Enums_Sex_Set_Input>;
  /** filter the rows which have to be updated */
  where: Answer_Enums_Sex_Bool_Exp;
};

/** This view returns answers for those there are no corresponding enum values in the answer enum tables. */
export type Answer_Enums_Unmatched_Answers = {
  __typename?: 'answer_enums_unmatched_answers';
  answer?: Maybe<Scalars['String']>;
  question?: Maybe<Scalars['String']>;
};

/** aggregated selection of "answer_enums.unmatched_answers" */
export type Answer_Enums_Unmatched_Answers_Aggregate = {
  __typename?: 'answer_enums_unmatched_answers_aggregate';
  aggregate?: Maybe<Answer_Enums_Unmatched_Answers_Aggregate_Fields>;
  nodes: Array<Answer_Enums_Unmatched_Answers>;
};

/** aggregate fields of "answer_enums.unmatched_answers" */
export type Answer_Enums_Unmatched_Answers_Aggregate_Fields = {
  __typename?: 'answer_enums_unmatched_answers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Answer_Enums_Unmatched_Answers_Max_Fields>;
  min?: Maybe<Answer_Enums_Unmatched_Answers_Min_Fields>;
};


/** aggregate fields of "answer_enums.unmatched_answers" */
export type Answer_Enums_Unmatched_Answers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Answer_Enums_Unmatched_Answers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "answer_enums.unmatched_answers". All fields are combined with a logical 'AND'. */
export type Answer_Enums_Unmatched_Answers_Bool_Exp = {
  _and?: InputMaybe<Array<Answer_Enums_Unmatched_Answers_Bool_Exp>>;
  _not?: InputMaybe<Answer_Enums_Unmatched_Answers_Bool_Exp>;
  _or?: InputMaybe<Array<Answer_Enums_Unmatched_Answers_Bool_Exp>>;
  answer?: InputMaybe<String_Comparison_Exp>;
  question?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Answer_Enums_Unmatched_Answers_Max_Fields = {
  __typename?: 'answer_enums_unmatched_answers_max_fields';
  answer?: Maybe<Scalars['String']>;
  question?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Answer_Enums_Unmatched_Answers_Min_Fields = {
  __typename?: 'answer_enums_unmatched_answers_min_fields';
  answer?: Maybe<Scalars['String']>;
  question?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "answer_enums.unmatched_answers". */
export type Answer_Enums_Unmatched_Answers_Order_By = {
  answer?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
};

/** select columns of table "answer_enums.unmatched_answers" */
export enum Answer_Enums_Unmatched_Answers_Select_Column {
  /** column name */
  Answer = 'answer',
  /** column name */
  Question = 'question'
}

/** Streaming cursor of the table "answer_enums_unmatched_answers" */
export type Answer_Enums_Unmatched_Answers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Answer_Enums_Unmatched_Answers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Answer_Enums_Unmatched_Answers_Stream_Cursor_Value_Input = {
  answer?: InputMaybe<Scalars['String']>;
  question?: InputMaybe<Scalars['String']>;
};

/** This view returns the enums that are present in the answer enum tables, but are not present in the matrix used for the recommendation process. */
export type Answer_Enums_Unmatched_Enums = {
  __typename?: 'answer_enums_unmatched_enums';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregated selection of "answer_enums.unmatched_enums" */
export type Answer_Enums_Unmatched_Enums_Aggregate = {
  __typename?: 'answer_enums_unmatched_enums_aggregate';
  aggregate?: Maybe<Answer_Enums_Unmatched_Enums_Aggregate_Fields>;
  nodes: Array<Answer_Enums_Unmatched_Enums>;
};

/** aggregate fields of "answer_enums.unmatched_enums" */
export type Answer_Enums_Unmatched_Enums_Aggregate_Fields = {
  __typename?: 'answer_enums_unmatched_enums_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Answer_Enums_Unmatched_Enums_Max_Fields>;
  min?: Maybe<Answer_Enums_Unmatched_Enums_Min_Fields>;
};


/** aggregate fields of "answer_enums.unmatched_enums" */
export type Answer_Enums_Unmatched_Enums_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Answer_Enums_Unmatched_Enums_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "answer_enums.unmatched_enums". All fields are combined with a logical 'AND'. */
export type Answer_Enums_Unmatched_Enums_Bool_Exp = {
  _and?: InputMaybe<Array<Answer_Enums_Unmatched_Enums_Bool_Exp>>;
  _not?: InputMaybe<Answer_Enums_Unmatched_Enums_Bool_Exp>;
  _or?: InputMaybe<Array<Answer_Enums_Unmatched_Enums_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Answer_Enums_Unmatched_Enums_Max_Fields = {
  __typename?: 'answer_enums_unmatched_enums_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Answer_Enums_Unmatched_Enums_Min_Fields = {
  __typename?: 'answer_enums_unmatched_enums_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "answer_enums.unmatched_enums". */
export type Answer_Enums_Unmatched_Enums_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "answer_enums.unmatched_enums" */
export enum Answer_Enums_Unmatched_Enums_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "answer_enums_unmatched_enums" */
export type Answer_Enums_Unmatched_Enums_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Answer_Enums_Unmatched_Enums_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Answer_Enums_Unmatched_Enums_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "answers" */
export type Answers = {
  __typename?: 'answers';
  /** An object relationship */
  choice: Content_Choices;
  choice_id: Scalars['Int'];
  /** An object relationship */
  session: Sessions;
  session_id: Scalars['uuid'];
};

/** aggregated selection of "answers" */
export type Answers_Aggregate = {
  __typename?: 'answers_aggregate';
  aggregate?: Maybe<Answers_Aggregate_Fields>;
  nodes: Array<Answers>;
};

export type Answers_Aggregate_Bool_Exp = {
  count?: InputMaybe<Answers_Aggregate_Bool_Exp_Count>;
};

export type Answers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Answers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Answers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "answers" */
export type Answers_Aggregate_Fields = {
  __typename?: 'answers_aggregate_fields';
  avg?: Maybe<Answers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Answers_Max_Fields>;
  min?: Maybe<Answers_Min_Fields>;
  stddev?: Maybe<Answers_Stddev_Fields>;
  stddev_pop?: Maybe<Answers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Answers_Stddev_Samp_Fields>;
  sum?: Maybe<Answers_Sum_Fields>;
  var_pop?: Maybe<Answers_Var_Pop_Fields>;
  var_samp?: Maybe<Answers_Var_Samp_Fields>;
  variance?: Maybe<Answers_Variance_Fields>;
};


/** aggregate fields of "answers" */
export type Answers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Answers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "answers" */
export type Answers_Aggregate_Order_By = {
  avg?: InputMaybe<Answers_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Answers_Max_Order_By>;
  min?: InputMaybe<Answers_Min_Order_By>;
  stddev?: InputMaybe<Answers_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Answers_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Answers_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Answers_Sum_Order_By>;
  var_pop?: InputMaybe<Answers_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Answers_Var_Samp_Order_By>;
  variance?: InputMaybe<Answers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "answers" */
export type Answers_Arr_Rel_Insert_Input = {
  data: Array<Answers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Answers_On_Conflict>;
};

/** aggregate avg on columns */
export type Answers_Avg_Fields = {
  __typename?: 'answers_avg_fields';
  choice_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "answers" */
export type Answers_Avg_Order_By = {
  choice_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "answers". All fields are combined with a logical 'AND'. */
export type Answers_Bool_Exp = {
  _and?: InputMaybe<Array<Answers_Bool_Exp>>;
  _not?: InputMaybe<Answers_Bool_Exp>;
  _or?: InputMaybe<Array<Answers_Bool_Exp>>;
  choice?: InputMaybe<Content_Choices_Bool_Exp>;
  choice_id?: InputMaybe<Int_Comparison_Exp>;
  session?: InputMaybe<Sessions_Bool_Exp>;
  session_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "answers" */
export enum Answers_Constraint {
  /** unique or primary key constraint on columns "session_id", "choice_id" */
  AnswersPkey = 'answers_pkey'
}

/** input type for incrementing numeric columns in table "answers" */
export type Answers_Inc_Input = {
  choice_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "answers" */
export type Answers_Insert_Input = {
  choice?: InputMaybe<Content_Choices_Obj_Rel_Insert_Input>;
  choice_id?: InputMaybe<Scalars['Int']>;
  session?: InputMaybe<Sessions_Obj_Rel_Insert_Input>;
  session_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Answers_Max_Fields = {
  __typename?: 'answers_max_fields';
  choice_id?: Maybe<Scalars['Int']>;
  session_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "answers" */
export type Answers_Max_Order_By = {
  choice_id?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Answers_Min_Fields = {
  __typename?: 'answers_min_fields';
  choice_id?: Maybe<Scalars['Int']>;
  session_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "answers" */
export type Answers_Min_Order_By = {
  choice_id?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "answers" */
export type Answers_Mutation_Response = {
  __typename?: 'answers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Answers>;
};

/** on_conflict condition type for table "answers" */
export type Answers_On_Conflict = {
  constraint: Answers_Constraint;
  update_columns?: Array<Answers_Update_Column>;
  where?: InputMaybe<Answers_Bool_Exp>;
};

/** Ordering options when selecting data from "answers". */
export type Answers_Order_By = {
  choice?: InputMaybe<Content_Choices_Order_By>;
  choice_id?: InputMaybe<Order_By>;
  session?: InputMaybe<Sessions_Order_By>;
  session_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: answers */
export type Answers_Pk_Columns_Input = {
  choice_id: Scalars['Int'];
  session_id: Scalars['uuid'];
};

/** select columns of table "answers" */
export enum Answers_Select_Column {
  /** column name */
  ChoiceId = 'choice_id',
  /** column name */
  SessionId = 'session_id'
}

/** input type for updating data in table "answers" */
export type Answers_Set_Input = {
  choice_id?: InputMaybe<Scalars['Int']>;
  session_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Answers_Stddev_Fields = {
  __typename?: 'answers_stddev_fields';
  choice_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "answers" */
export type Answers_Stddev_Order_By = {
  choice_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Answers_Stddev_Pop_Fields = {
  __typename?: 'answers_stddev_pop_fields';
  choice_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "answers" */
export type Answers_Stddev_Pop_Order_By = {
  choice_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Answers_Stddev_Samp_Fields = {
  __typename?: 'answers_stddev_samp_fields';
  choice_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "answers" */
export type Answers_Stddev_Samp_Order_By = {
  choice_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "answers" */
export type Answers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Answers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Answers_Stream_Cursor_Value_Input = {
  choice_id?: InputMaybe<Scalars['Int']>;
  session_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Answers_Sum_Fields = {
  __typename?: 'answers_sum_fields';
  choice_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "answers" */
export type Answers_Sum_Order_By = {
  choice_id?: InputMaybe<Order_By>;
};

/** update columns of table "answers" */
export enum Answers_Update_Column {
  /** column name */
  ChoiceId = 'choice_id',
  /** column name */
  SessionId = 'session_id'
}

export type Answers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Answers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Answers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Answers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Answers_Var_Pop_Fields = {
  __typename?: 'answers_var_pop_fields';
  choice_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "answers" */
export type Answers_Var_Pop_Order_By = {
  choice_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Answers_Var_Samp_Fields = {
  __typename?: 'answers_var_samp_fields';
  choice_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "answers" */
export type Answers_Var_Samp_Order_By = {
  choice_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Answers_Variance_Fields = {
  __typename?: 'answers_variance_fields';
  choice_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "answers" */
export type Answers_Variance_Order_By = {
  choice_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "article_group" */
export type Article_Group = {
  __typename?: 'article_group';
  value: Scalars['String'];
};

/** aggregated selection of "article_group" */
export type Article_Group_Aggregate = {
  __typename?: 'article_group_aggregate';
  aggregate?: Maybe<Article_Group_Aggregate_Fields>;
  nodes: Array<Article_Group>;
};

/** aggregate fields of "article_group" */
export type Article_Group_Aggregate_Fields = {
  __typename?: 'article_group_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Article_Group_Max_Fields>;
  min?: Maybe<Article_Group_Min_Fields>;
};


/** aggregate fields of "article_group" */
export type Article_Group_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Article_Group_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "article_group". All fields are combined with a logical 'AND'. */
export type Article_Group_Bool_Exp = {
  _and?: InputMaybe<Array<Article_Group_Bool_Exp>>;
  _not?: InputMaybe<Article_Group_Bool_Exp>;
  _or?: InputMaybe<Array<Article_Group_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "article_group" */
export enum Article_Group_Constraint {
  /** unique or primary key constraint on columns "value" */
  ArticleGroupPkey = 'article_group_pkey'
}

/** input type for inserting data into table "article_group" */
export type Article_Group_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Article_Group_Max_Fields = {
  __typename?: 'article_group_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Article_Group_Min_Fields = {
  __typename?: 'article_group_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "article_group" */
export type Article_Group_Mutation_Response = {
  __typename?: 'article_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Article_Group>;
};

/** on_conflict condition type for table "article_group" */
export type Article_Group_On_Conflict = {
  constraint: Article_Group_Constraint;
  update_columns?: Array<Article_Group_Update_Column>;
  where?: InputMaybe<Article_Group_Bool_Exp>;
};

/** Ordering options when selecting data from "article_group". */
export type Article_Group_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: article_group */
export type Article_Group_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "article_group" */
export enum Article_Group_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "article_group" */
export type Article_Group_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "article_group" */
export type Article_Group_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Article_Group_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Article_Group_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "article_group" */
export enum Article_Group_Update_Column {
  /** column name */
  Value = 'value'
}

export type Article_Group_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Article_Group_Set_Input>;
  /** filter the rows which have to be updated */
  where: Article_Group_Bool_Exp;
};

/** In this table all article information which is not needed for the recommendation process is stored in different languages. */
export type Article_Information_I18n = {
  __typename?: 'article_information_i18n';
  article_id: Scalars['String'];
  article_type: Article_Type_Enum;
  brand: Scalars['String'];
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  images?: Maybe<Scalars['jsonb']>;
  language: Country_Code_Enum;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['float8']>;
  shop_url?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** In this table all article information which is not needed for the recommendation process is stored in different languages. */
export type Article_Information_I18nImagesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "article_information_i18n" */
export type Article_Information_I18n_Aggregate = {
  __typename?: 'article_information_i18n_aggregate';
  aggregate?: Maybe<Article_Information_I18n_Aggregate_Fields>;
  nodes: Array<Article_Information_I18n>;
};

export type Article_Information_I18n_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Article_Information_I18n_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Article_Information_I18n_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Article_Information_I18n_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Article_Information_I18n_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Article_Information_I18n_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Article_Information_I18n_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Article_Information_I18n_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Article_Information_I18n_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Article_Information_I18n_Aggregate_Bool_Exp_Var_Samp>;
};

export type Article_Information_I18n_Aggregate_Bool_Exp_Avg = {
  arguments: Article_Information_I18n_Select_Column_Article_Information_I18n_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Article_Information_I18n_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Article_Information_I18n_Aggregate_Bool_Exp_Corr = {
  arguments: Article_Information_I18n_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Article_Information_I18n_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Article_Information_I18n_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Article_Information_I18n_Select_Column_Article_Information_I18n_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Article_Information_I18n_Select_Column_Article_Information_I18n_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Article_Information_I18n_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Article_Information_I18n_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Article_Information_I18n_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Article_Information_I18n_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Article_Information_I18n_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Article_Information_I18n_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Article_Information_I18n_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Article_Information_I18n_Select_Column_Article_Information_I18n_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Article_Information_I18n_Select_Column_Article_Information_I18n_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Article_Information_I18n_Aggregate_Bool_Exp_Max = {
  arguments: Article_Information_I18n_Select_Column_Article_Information_I18n_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Article_Information_I18n_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Article_Information_I18n_Aggregate_Bool_Exp_Min = {
  arguments: Article_Information_I18n_Select_Column_Article_Information_I18n_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Article_Information_I18n_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Article_Information_I18n_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Article_Information_I18n_Select_Column_Article_Information_I18n_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Article_Information_I18n_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Article_Information_I18n_Aggregate_Bool_Exp_Sum = {
  arguments: Article_Information_I18n_Select_Column_Article_Information_I18n_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Article_Information_I18n_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Article_Information_I18n_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Article_Information_I18n_Select_Column_Article_Information_I18n_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Article_Information_I18n_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "article_information_i18n" */
export type Article_Information_I18n_Aggregate_Fields = {
  __typename?: 'article_information_i18n_aggregate_fields';
  avg?: Maybe<Article_Information_I18n_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Article_Information_I18n_Max_Fields>;
  min?: Maybe<Article_Information_I18n_Min_Fields>;
  stddev?: Maybe<Article_Information_I18n_Stddev_Fields>;
  stddev_pop?: Maybe<Article_Information_I18n_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Article_Information_I18n_Stddev_Samp_Fields>;
  sum?: Maybe<Article_Information_I18n_Sum_Fields>;
  var_pop?: Maybe<Article_Information_I18n_Var_Pop_Fields>;
  var_samp?: Maybe<Article_Information_I18n_Var_Samp_Fields>;
  variance?: Maybe<Article_Information_I18n_Variance_Fields>;
};


/** aggregate fields of "article_information_i18n" */
export type Article_Information_I18n_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Article_Information_I18n_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "article_information_i18n" */
export type Article_Information_I18n_Aggregate_Order_By = {
  avg?: InputMaybe<Article_Information_I18n_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Article_Information_I18n_Max_Order_By>;
  min?: InputMaybe<Article_Information_I18n_Min_Order_By>;
  stddev?: InputMaybe<Article_Information_I18n_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Article_Information_I18n_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Article_Information_I18n_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Article_Information_I18n_Sum_Order_By>;
  var_pop?: InputMaybe<Article_Information_I18n_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Article_Information_I18n_Var_Samp_Order_By>;
  variance?: InputMaybe<Article_Information_I18n_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Article_Information_I18n_Append_Input = {
  images?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "article_information_i18n" */
export type Article_Information_I18n_Arr_Rel_Insert_Input = {
  data: Array<Article_Information_I18n_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Article_Information_I18n_On_Conflict>;
};

/** aggregate avg on columns */
export type Article_Information_I18n_Avg_Fields = {
  __typename?: 'article_information_i18n_avg_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "article_information_i18n" */
export type Article_Information_I18n_Avg_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "article_information_i18n". All fields are combined with a logical 'AND'. */
export type Article_Information_I18n_Bool_Exp = {
  _and?: InputMaybe<Array<Article_Information_I18n_Bool_Exp>>;
  _not?: InputMaybe<Article_Information_I18n_Bool_Exp>;
  _or?: InputMaybe<Array<Article_Information_I18n_Bool_Exp>>;
  article_id?: InputMaybe<String_Comparison_Exp>;
  article_type?: InputMaybe<Article_Type_Enum_Comparison_Exp>;
  brand?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  currency?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  ean?: InputMaybe<String_Comparison_Exp>;
  images?: InputMaybe<Jsonb_Comparison_Exp>;
  language?: InputMaybe<Country_Code_Enum_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  price?: InputMaybe<Float8_Comparison_Exp>;
  shop_url?: InputMaybe<String_Comparison_Exp>;
  size?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "article_information_i18n" */
export enum Article_Information_I18n_Constraint {
  /** unique or primary key constraint on columns "language", "article_id" */
  ArticleInformationI18nPkey = 'article_information_i18n_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Article_Information_I18n_Delete_At_Path_Input = {
  images?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Article_Information_I18n_Delete_Elem_Input = {
  images?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Article_Information_I18n_Delete_Key_Input = {
  images?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "article_information_i18n" */
export type Article_Information_I18n_Inc_Input = {
  price?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "article_information_i18n" */
export type Article_Information_I18n_Insert_Input = {
  article_id?: InputMaybe<Scalars['String']>;
  article_type?: InputMaybe<Article_Type_Enum>;
  brand?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  currency?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  ean?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Scalars['jsonb']>;
  language?: InputMaybe<Country_Code_Enum>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['float8']>;
  shop_url?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Article_Information_I18n_Max_Fields = {
  __typename?: 'article_information_i18n_max_fields';
  article_id?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['float8']>;
  shop_url?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "article_information_i18n" */
export type Article_Information_I18n_Max_Order_By = {
  article_id?: InputMaybe<Order_By>;
  brand?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  ean?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  shop_url?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Article_Information_I18n_Min_Fields = {
  __typename?: 'article_information_i18n_min_fields';
  article_id?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['float8']>;
  shop_url?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "article_information_i18n" */
export type Article_Information_I18n_Min_Order_By = {
  article_id?: InputMaybe<Order_By>;
  brand?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  ean?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  shop_url?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "article_information_i18n" */
export type Article_Information_I18n_Mutation_Response = {
  __typename?: 'article_information_i18n_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Article_Information_I18n>;
};

/** on_conflict condition type for table "article_information_i18n" */
export type Article_Information_I18n_On_Conflict = {
  constraint: Article_Information_I18n_Constraint;
  update_columns?: Array<Article_Information_I18n_Update_Column>;
  where?: InputMaybe<Article_Information_I18n_Bool_Exp>;
};

/** Ordering options when selecting data from "article_information_i18n". */
export type Article_Information_I18n_Order_By = {
  article_id?: InputMaybe<Order_By>;
  article_type?: InputMaybe<Order_By>;
  brand?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  ean?: InputMaybe<Order_By>;
  images?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  shop_url?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: article_information_i18n */
export type Article_Information_I18n_Pk_Columns_Input = {
  article_id: Scalars['String'];
  language: Country_Code_Enum;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Article_Information_I18n_Prepend_Input = {
  images?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "article_information_i18n" */
export enum Article_Information_I18n_Select_Column {
  /** column name */
  ArticleId = 'article_id',
  /** column name */
  ArticleType = 'article_type',
  /** column name */
  Brand = 'brand',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  Description = 'description',
  /** column name */
  Ean = 'ean',
  /** column name */
  Images = 'images',
  /** column name */
  Language = 'language',
  /** column name */
  Name = 'name',
  /** column name */
  Price = 'price',
  /** column name */
  ShopUrl = 'shop_url',
  /** column name */
  Size = 'size',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "article_information_i18n_aggregate_bool_exp_avg_arguments_columns" columns of table "article_information_i18n" */
export enum Article_Information_I18n_Select_Column_Article_Information_I18n_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Price = 'price'
}

/** select "article_information_i18n_aggregate_bool_exp_corr_arguments_columns" columns of table "article_information_i18n" */
export enum Article_Information_I18n_Select_Column_Article_Information_I18n_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Price = 'price'
}

/** select "article_information_i18n_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "article_information_i18n" */
export enum Article_Information_I18n_Select_Column_Article_Information_I18n_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Price = 'price'
}

/** select "article_information_i18n_aggregate_bool_exp_max_arguments_columns" columns of table "article_information_i18n" */
export enum Article_Information_I18n_Select_Column_Article_Information_I18n_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Price = 'price'
}

/** select "article_information_i18n_aggregate_bool_exp_min_arguments_columns" columns of table "article_information_i18n" */
export enum Article_Information_I18n_Select_Column_Article_Information_I18n_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Price = 'price'
}

/** select "article_information_i18n_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "article_information_i18n" */
export enum Article_Information_I18n_Select_Column_Article_Information_I18n_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Price = 'price'
}

/** select "article_information_i18n_aggregate_bool_exp_sum_arguments_columns" columns of table "article_information_i18n" */
export enum Article_Information_I18n_Select_Column_Article_Information_I18n_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Price = 'price'
}

/** select "article_information_i18n_aggregate_bool_exp_var_samp_arguments_columns" columns of table "article_information_i18n" */
export enum Article_Information_I18n_Select_Column_Article_Information_I18n_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Price = 'price'
}

/** input type for updating data in table "article_information_i18n" */
export type Article_Information_I18n_Set_Input = {
  article_id?: InputMaybe<Scalars['String']>;
  article_type?: InputMaybe<Article_Type_Enum>;
  brand?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  currency?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  ean?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Scalars['jsonb']>;
  language?: InputMaybe<Country_Code_Enum>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['float8']>;
  shop_url?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Article_Information_I18n_Stddev_Fields = {
  __typename?: 'article_information_i18n_stddev_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "article_information_i18n" */
export type Article_Information_I18n_Stddev_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Article_Information_I18n_Stddev_Pop_Fields = {
  __typename?: 'article_information_i18n_stddev_pop_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "article_information_i18n" */
export type Article_Information_I18n_Stddev_Pop_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Article_Information_I18n_Stddev_Samp_Fields = {
  __typename?: 'article_information_i18n_stddev_samp_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "article_information_i18n" */
export type Article_Information_I18n_Stddev_Samp_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "article_information_i18n" */
export type Article_Information_I18n_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Article_Information_I18n_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Article_Information_I18n_Stream_Cursor_Value_Input = {
  article_id?: InputMaybe<Scalars['String']>;
  article_type?: InputMaybe<Article_Type_Enum>;
  brand?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  currency?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  ean?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Scalars['jsonb']>;
  language?: InputMaybe<Country_Code_Enum>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['float8']>;
  shop_url?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Article_Information_I18n_Sum_Fields = {
  __typename?: 'article_information_i18n_sum_fields';
  price?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "article_information_i18n" */
export type Article_Information_I18n_Sum_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** update columns of table "article_information_i18n" */
export enum Article_Information_I18n_Update_Column {
  /** column name */
  ArticleId = 'article_id',
  /** column name */
  ArticleType = 'article_type',
  /** column name */
  Brand = 'brand',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  Description = 'description',
  /** column name */
  Ean = 'ean',
  /** column name */
  Images = 'images',
  /** column name */
  Language = 'language',
  /** column name */
  Name = 'name',
  /** column name */
  Price = 'price',
  /** column name */
  ShopUrl = 'shop_url',
  /** column name */
  Size = 'size',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Article_Information_I18n_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Article_Information_I18n_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Article_Information_I18n_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Article_Information_I18n_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Article_Information_I18n_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Article_Information_I18n_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Article_Information_I18n_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Article_Information_I18n_Set_Input>;
  /** filter the rows which have to be updated */
  where: Article_Information_I18n_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Article_Information_I18n_Var_Pop_Fields = {
  __typename?: 'article_information_i18n_var_pop_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "article_information_i18n" */
export type Article_Information_I18n_Var_Pop_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Article_Information_I18n_Var_Samp_Fields = {
  __typename?: 'article_information_i18n_var_samp_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "article_information_i18n" */
export type Article_Information_I18n_Var_Samp_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Article_Information_I18n_Variance_Fields = {
  __typename?: 'article_information_i18n_variance_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "article_information_i18n" */
export type Article_Information_I18n_Variance_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** ranking answers for the given values */
export type Article_Ranking = {
  __typename?: 'article_ranking';
  answer: Scalars['String'];
  article_id: Scalars['String'];
  factor: Scalars['Int'];
  question?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  weighting: Scalars['Int'];
};

/** aggregated selection of "article_ranking" */
export type Article_Ranking_Aggregate = {
  __typename?: 'article_ranking_aggregate';
  aggregate?: Maybe<Article_Ranking_Aggregate_Fields>;
  nodes: Array<Article_Ranking>;
};

/** aggregate fields of "article_ranking" */
export type Article_Ranking_Aggregate_Fields = {
  __typename?: 'article_ranking_aggregate_fields';
  avg?: Maybe<Article_Ranking_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Article_Ranking_Max_Fields>;
  min?: Maybe<Article_Ranking_Min_Fields>;
  stddev?: Maybe<Article_Ranking_Stddev_Fields>;
  stddev_pop?: Maybe<Article_Ranking_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Article_Ranking_Stddev_Samp_Fields>;
  sum?: Maybe<Article_Ranking_Sum_Fields>;
  var_pop?: Maybe<Article_Ranking_Var_Pop_Fields>;
  var_samp?: Maybe<Article_Ranking_Var_Samp_Fields>;
  variance?: Maybe<Article_Ranking_Variance_Fields>;
};


/** aggregate fields of "article_ranking" */
export type Article_Ranking_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Article_Ranking_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Article_Ranking_Avg_Fields = {
  __typename?: 'article_ranking_avg_fields';
  factor?: Maybe<Scalars['Float']>;
  weighting?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "article_ranking". All fields are combined with a logical 'AND'. */
export type Article_Ranking_Bool_Exp = {
  _and?: InputMaybe<Array<Article_Ranking_Bool_Exp>>;
  _not?: InputMaybe<Article_Ranking_Bool_Exp>;
  _or?: InputMaybe<Array<Article_Ranking_Bool_Exp>>;
  answer?: InputMaybe<String_Comparison_Exp>;
  article_id?: InputMaybe<String_Comparison_Exp>;
  factor?: InputMaybe<Int_Comparison_Exp>;
  question?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  weighting?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "article_ranking" */
export enum Article_Ranking_Constraint {
  /** unique or primary key constraint on columns "article_id", "answer" */
  ArticleRankingPkey = 'article_ranking_pkey'
}

/** input type for incrementing numeric columns in table "article_ranking" */
export type Article_Ranking_Inc_Input = {
  factor?: InputMaybe<Scalars['Int']>;
  weighting?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "article_ranking" */
export type Article_Ranking_Insert_Input = {
  answer?: InputMaybe<Scalars['String']>;
  article_id?: InputMaybe<Scalars['String']>;
  factor?: InputMaybe<Scalars['Int']>;
  question?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  weighting?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Article_Ranking_Max_Fields = {
  __typename?: 'article_ranking_max_fields';
  answer?: Maybe<Scalars['String']>;
  article_id?: Maybe<Scalars['String']>;
  factor?: Maybe<Scalars['Int']>;
  question?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  weighting?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Article_Ranking_Min_Fields = {
  __typename?: 'article_ranking_min_fields';
  answer?: Maybe<Scalars['String']>;
  article_id?: Maybe<Scalars['String']>;
  factor?: Maybe<Scalars['Int']>;
  question?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  weighting?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "article_ranking" */
export type Article_Ranking_Mutation_Response = {
  __typename?: 'article_ranking_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Article_Ranking>;
};

/** on_conflict condition type for table "article_ranking" */
export type Article_Ranking_On_Conflict = {
  constraint: Article_Ranking_Constraint;
  update_columns?: Array<Article_Ranking_Update_Column>;
  where?: InputMaybe<Article_Ranking_Bool_Exp>;
};

/** Ordering options when selecting data from "article_ranking". */
export type Article_Ranking_Order_By = {
  answer?: InputMaybe<Order_By>;
  article_id?: InputMaybe<Order_By>;
  factor?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  weighting?: InputMaybe<Order_By>;
};

/** primary key columns input for table: article_ranking */
export type Article_Ranking_Pk_Columns_Input = {
  answer: Scalars['String'];
  article_id: Scalars['String'];
};

/** select columns of table "article_ranking" */
export enum Article_Ranking_Select_Column {
  /** column name */
  Answer = 'answer',
  /** column name */
  ArticleId = 'article_id',
  /** column name */
  Factor = 'factor',
  /** column name */
  Question = 'question',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Weighting = 'weighting'
}

/** input type for updating data in table "article_ranking" */
export type Article_Ranking_Set_Input = {
  answer?: InputMaybe<Scalars['String']>;
  article_id?: InputMaybe<Scalars['String']>;
  factor?: InputMaybe<Scalars['Int']>;
  question?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  weighting?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Article_Ranking_Stddev_Fields = {
  __typename?: 'article_ranking_stddev_fields';
  factor?: Maybe<Scalars['Float']>;
  weighting?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Article_Ranking_Stddev_Pop_Fields = {
  __typename?: 'article_ranking_stddev_pop_fields';
  factor?: Maybe<Scalars['Float']>;
  weighting?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Article_Ranking_Stddev_Samp_Fields = {
  __typename?: 'article_ranking_stddev_samp_fields';
  factor?: Maybe<Scalars['Float']>;
  weighting?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "article_ranking" */
export type Article_Ranking_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Article_Ranking_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Article_Ranking_Stream_Cursor_Value_Input = {
  answer?: InputMaybe<Scalars['String']>;
  article_id?: InputMaybe<Scalars['String']>;
  factor?: InputMaybe<Scalars['Int']>;
  question?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  weighting?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Article_Ranking_Sum_Fields = {
  __typename?: 'article_ranking_sum_fields';
  factor?: Maybe<Scalars['Int']>;
  weighting?: Maybe<Scalars['Int']>;
};

/** update columns of table "article_ranking" */
export enum Article_Ranking_Update_Column {
  /** column name */
  Answer = 'answer',
  /** column name */
  ArticleId = 'article_id',
  /** column name */
  Factor = 'factor',
  /** column name */
  Question = 'question',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Weighting = 'weighting'
}

export type Article_Ranking_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Article_Ranking_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Article_Ranking_Set_Input>;
  /** filter the rows which have to be updated */
  where: Article_Ranking_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Article_Ranking_Var_Pop_Fields = {
  __typename?: 'article_ranking_var_pop_fields';
  factor?: Maybe<Scalars['Float']>;
  weighting?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Article_Ranking_Var_Samp_Fields = {
  __typename?: 'article_ranking_var_samp_fields';
  factor?: Maybe<Scalars['Float']>;
  weighting?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Article_Ranking_Variance_Fields = {
  __typename?: 'article_ranking_variance_fields';
  factor?: Maybe<Scalars['Float']>;
  weighting?: Maybe<Scalars['Float']>;
};

/** different article types */
export type Article_Type = {
  __typename?: 'article_type';
  /** An array relationship */
  article_information_i18n: Array<Article_Information_I18n>;
  /** An aggregate relationship */
  article_information_i18n_aggregate: Article_Information_I18n_Aggregate;
  description: Scalars['String'];
  value: Scalars['String'];
};


/** different article types */
export type Article_TypeArticle_Information_I18nArgs = {
  distinct_on?: InputMaybe<Array<Article_Information_I18n_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Article_Information_I18n_Order_By>>;
  where?: InputMaybe<Article_Information_I18n_Bool_Exp>;
};


/** different article types */
export type Article_TypeArticle_Information_I18n_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Article_Information_I18n_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Article_Information_I18n_Order_By>>;
  where?: InputMaybe<Article_Information_I18n_Bool_Exp>;
};

/** aggregated selection of "article_type" */
export type Article_Type_Aggregate = {
  __typename?: 'article_type_aggregate';
  aggregate?: Maybe<Article_Type_Aggregate_Fields>;
  nodes: Array<Article_Type>;
};

/** aggregate fields of "article_type" */
export type Article_Type_Aggregate_Fields = {
  __typename?: 'article_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Article_Type_Max_Fields>;
  min?: Maybe<Article_Type_Min_Fields>;
};


/** aggregate fields of "article_type" */
export type Article_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Article_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "article_type". All fields are combined with a logical 'AND'. */
export type Article_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Article_Type_Bool_Exp>>;
  _not?: InputMaybe<Article_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Article_Type_Bool_Exp>>;
  article_information_i18n?: InputMaybe<Article_Information_I18n_Bool_Exp>;
  article_information_i18n_aggregate?: InputMaybe<Article_Information_I18n_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "article_type" */
export enum Article_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  ArticleTypePkey = 'article_type_pkey'
}

export enum Article_Type_Enum {
  /** Zubehör */
  Accessory = 'accessory',
  /** Fahrrad */
  Bike = 'bike',
  /** Bekleidung */
  Clothing = 'clothing',
  /** Ellenbogen Protektoren */
  ElbowPads = 'elbow_pads',
  /** Handschuhe */
  Gloves = 'gloves',
  /** Griff */
  Handle = 'handle',
  /** Lenkerband */
  HandlebarTape = 'handlebar_tape',
  /** Lenker */
  Handlebars = 'handlebars',
  /** Helm */
  Helmet = 'helmet',
  /** Innerbarends */
  Innerbarends = 'innerbarends',
  /** Einlegesohle */
  Insole = 'insole',
  /** Trikot */
  Jersey = 'jersey',
  /** Knie Protektoren */
  KneePads = 'knee_pads',
  /** Lange Hose */
  Pants = 'pants',
  /** Pedal */
  Pedal = 'pedal',
  /** Sattel */
  Saddle = 'saddle',
  /** Schuhe */
  Shoes = 'shoes',
  /** Hose */
  Shorts = 'shorts',
  /** Socken */
  Socks = 'socks',
  /** Vorbau */
  Stem = 'stem'
}

/** Boolean expression to compare columns of type "article_type_enum". All fields are combined with logical 'AND'. */
export type Article_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Article_Type_Enum>;
  _in?: InputMaybe<Array<Article_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Article_Type_Enum>;
  _nin?: InputMaybe<Array<Article_Type_Enum>>;
};

/** input type for inserting data into table "article_type" */
export type Article_Type_Insert_Input = {
  article_information_i18n?: InputMaybe<Article_Information_I18n_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Article_Type_Max_Fields = {
  __typename?: 'article_type_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Article_Type_Min_Fields = {
  __typename?: 'article_type_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "article_type" */
export type Article_Type_Mutation_Response = {
  __typename?: 'article_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Article_Type>;
};

/** on_conflict condition type for table "article_type" */
export type Article_Type_On_Conflict = {
  constraint: Article_Type_Constraint;
  update_columns?: Array<Article_Type_Update_Column>;
  where?: InputMaybe<Article_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "article_type". */
export type Article_Type_Order_By = {
  article_information_i18n_aggregate?: InputMaybe<Article_Information_I18n_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: article_type */
export type Article_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "article_type" */
export enum Article_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "article_type" */
export type Article_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "article_type" */
export type Article_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Article_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Article_Type_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "article_type" */
export enum Article_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type Article_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Article_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Article_Type_Bool_Exp;
};

/** columns and relationships of "auth.one_time_token" */
export type Auth_One_Time_Token = {
  __typename?: 'auth_one_time_token';
  created_at: Scalars['timestamptz'];
  questionnaire_id: Scalars['String'];
  value: Scalars['uuid'];
};

/** aggregated selection of "auth.one_time_token" */
export type Auth_One_Time_Token_Aggregate = {
  __typename?: 'auth_one_time_token_aggregate';
  aggregate?: Maybe<Auth_One_Time_Token_Aggregate_Fields>;
  nodes: Array<Auth_One_Time_Token>;
};

/** aggregate fields of "auth.one_time_token" */
export type Auth_One_Time_Token_Aggregate_Fields = {
  __typename?: 'auth_one_time_token_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Auth_One_Time_Token_Max_Fields>;
  min?: Maybe<Auth_One_Time_Token_Min_Fields>;
};


/** aggregate fields of "auth.one_time_token" */
export type Auth_One_Time_Token_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_One_Time_Token_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "auth.one_time_token". All fields are combined with a logical 'AND'. */
export type Auth_One_Time_Token_Bool_Exp = {
  _and?: InputMaybe<Array<Auth_One_Time_Token_Bool_Exp>>;
  _not?: InputMaybe<Auth_One_Time_Token_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_One_Time_Token_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  questionnaire_id?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.one_time_token" */
export enum Auth_One_Time_Token_Constraint {
  /** unique or primary key constraint on columns "value" */
  OneTimeTokenPkey = 'one_time_token_pkey'
}

/** input type for inserting data into table "auth.one_time_token" */
export type Auth_One_Time_Token_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  questionnaire_id?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Auth_One_Time_Token_Max_Fields = {
  __typename?: 'auth_one_time_token_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  questionnaire_id?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Auth_One_Time_Token_Min_Fields = {
  __typename?: 'auth_one_time_token_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  questionnaire_id?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "auth.one_time_token" */
export type Auth_One_Time_Token_Mutation_Response = {
  __typename?: 'auth_one_time_token_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_One_Time_Token>;
};

/** on_conflict condition type for table "auth.one_time_token" */
export type Auth_One_Time_Token_On_Conflict = {
  constraint: Auth_One_Time_Token_Constraint;
  update_columns?: Array<Auth_One_Time_Token_Update_Column>;
  where?: InputMaybe<Auth_One_Time_Token_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.one_time_token". */
export type Auth_One_Time_Token_Order_By = {
  created_at?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth.one_time_token */
export type Auth_One_Time_Token_Pk_Columns_Input = {
  value: Scalars['uuid'];
};

/** select columns of table "auth.one_time_token" */
export enum Auth_One_Time_Token_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  QuestionnaireId = 'questionnaire_id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "auth.one_time_token" */
export type Auth_One_Time_Token_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  questionnaire_id?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "auth_one_time_token" */
export type Auth_One_Time_Token_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Auth_One_Time_Token_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Auth_One_Time_Token_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  questionnaire_id?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "auth.one_time_token" */
export enum Auth_One_Time_Token_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  QuestionnaireId = 'questionnaire_id',
  /** column name */
  Value = 'value'
}

export type Auth_One_Time_Token_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_One_Time_Token_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_One_Time_Token_Bool_Exp;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "bikecenter_user_info" */
export type Bikecenter_User_Info = {
  __typename?: 'bikecenter_user_info';
  account_number?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deactivated_at?: Maybe<Scalars['timestamp']>;
  email_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_temporary_password?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  profiler_access_enabled?: Maybe<Scalars['Boolean']>;
  profiler_commission_enabled?: Maybe<Scalars['Boolean']>;
  shop_name?: Maybe<Scalars['String']>;
  supervisor?: Maybe<Scalars['String']>;
  vendor_email_address?: Maybe<Scalars['String']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "bikecenter_user_info" */
export type Bikecenter_User_Info_Aggregate = {
  __typename?: 'bikecenter_user_info_aggregate';
  aggregate?: Maybe<Bikecenter_User_Info_Aggregate_Fields>;
  nodes: Array<Bikecenter_User_Info>;
};

/** aggregate fields of "bikecenter_user_info" */
export type Bikecenter_User_Info_Aggregate_Fields = {
  __typename?: 'bikecenter_user_info_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Bikecenter_User_Info_Max_Fields>;
  min?: Maybe<Bikecenter_User_Info_Min_Fields>;
};


/** aggregate fields of "bikecenter_user_info" */
export type Bikecenter_User_Info_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bikecenter_User_Info_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "bikecenter_user_info". All fields are combined with a logical 'AND'. */
export type Bikecenter_User_Info_Bool_Exp = {
  _and?: InputMaybe<Array<Bikecenter_User_Info_Bool_Exp>>;
  _not?: InputMaybe<Bikecenter_User_Info_Bool_Exp>;
  _or?: InputMaybe<Array<Bikecenter_User_Info_Bool_Exp>>;
  account_number?: InputMaybe<String_Comparison_Exp>;
  country_code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deactivated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  email_address?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_temporary_password?: InputMaybe<Boolean_Comparison_Exp>;
  language?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  profiler_access_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  profiler_commission_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  shop_name?: InputMaybe<String_Comparison_Exp>;
  supervisor?: InputMaybe<String_Comparison_Exp>;
  vendor_email_address?: InputMaybe<String_Comparison_Exp>;
  vendor_id?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Bikecenter_User_Info_Max_Fields = {
  __typename?: 'bikecenter_user_info_max_fields';
  account_number?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deactivated_at?: Maybe<Scalars['timestamp']>;
  email_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  shop_name?: Maybe<Scalars['String']>;
  supervisor?: Maybe<Scalars['String']>;
  vendor_email_address?: Maybe<Scalars['String']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Bikecenter_User_Info_Min_Fields = {
  __typename?: 'bikecenter_user_info_min_fields';
  account_number?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deactivated_at?: Maybe<Scalars['timestamp']>;
  email_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  shop_name?: Maybe<Scalars['String']>;
  supervisor?: Maybe<Scalars['String']>;
  vendor_email_address?: Maybe<Scalars['String']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "bikecenter_user_info". */
export type Bikecenter_User_Info_Order_By = {
  account_number?: InputMaybe<Order_By>;
  country_code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deactivated_at?: InputMaybe<Order_By>;
  email_address?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_temporary_password?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  profiler_access_enabled?: InputMaybe<Order_By>;
  profiler_commission_enabled?: InputMaybe<Order_By>;
  shop_name?: InputMaybe<Order_By>;
  supervisor?: InputMaybe<Order_By>;
  vendor_email_address?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** select columns of table "bikecenter_user_info" */
export enum Bikecenter_User_Info_Select_Column {
  /** column name */
  AccountNumber = 'account_number',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeactivatedAt = 'deactivated_at',
  /** column name */
  EmailAddress = 'email_address',
  /** column name */
  Id = 'id',
  /** column name */
  IsTemporaryPassword = 'is_temporary_password',
  /** column name */
  Language = 'language',
  /** column name */
  Name = 'name',
  /** column name */
  ProfilerAccessEnabled = 'profiler_access_enabled',
  /** column name */
  ProfilerCommissionEnabled = 'profiler_commission_enabled',
  /** column name */
  ShopName = 'shop_name',
  /** column name */
  Supervisor = 'supervisor',
  /** column name */
  VendorEmailAddress = 'vendor_email_address',
  /** column name */
  VendorId = 'vendor_id'
}

/** Streaming cursor of the table "bikecenter_user_info" */
export type Bikecenter_User_Info_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bikecenter_User_Info_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bikecenter_User_Info_Stream_Cursor_Value_Input = {
  account_number?: InputMaybe<Scalars['String']>;
  country_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deactivated_at?: InputMaybe<Scalars['timestamp']>;
  email_address?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_temporary_password?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  profiler_access_enabled?: InputMaybe<Scalars['Boolean']>;
  profiler_commission_enabled?: InputMaybe<Scalars['Boolean']>;
  shop_name?: InputMaybe<Scalars['String']>;
  supervisor?: InputMaybe<Scalars['String']>;
  vendor_email_address?: InputMaybe<Scalars['String']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** Each row represents one completed bikefitting instance */
export type Bikefitting_Bikefitting_Session = {
  __typename?: 'bikefitting_bikefitting_session';
  bikefitting_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  elbow_angle_3?: Maybe<Scalars['numeric']>;
  elbow_angle_6?: Maybe<Scalars['numeric']>;
  image_3?: Maybe<Scalars['String']>;
  image_3_raw?: Maybe<Scalars['String']>;
  image_6?: Maybe<Scalars['String']>;
  image_6_raw?: Maybe<Scalars['String']>;
  knee_angle?: Maybe<Scalars['numeric']>;
  knee_plumb_line?: Maybe<Scalars['String']>;
  recommendation_height?: Maybe<Scalars['String']>;
  recommendation_height_value?: Maybe<Scalars['numeric']>;
  recommendation_offset?: Maybe<Scalars['String']>;
  recommendation_offset_value?: Maybe<Scalars['numeric']>;
  recommendation_sitting_position?: Maybe<Scalars['String']>;
  shoulder_angle_3?: Maybe<Scalars['numeric']>;
  shoulder_angle_6?: Maybe<Scalars['numeric']>;
  sitting_position?: Maybe<Scalars['String']>;
  status_code?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
  upper_body_angle_3?: Maybe<Scalars['numeric']>;
  upper_body_angle_6?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  vendor?: Maybe<Organisation>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "bikefitting.bikefitting_session" */
export type Bikefitting_Bikefitting_Session_Aggregate = {
  __typename?: 'bikefitting_bikefitting_session_aggregate';
  aggregate?: Maybe<Bikefitting_Bikefitting_Session_Aggregate_Fields>;
  nodes: Array<Bikefitting_Bikefitting_Session>;
};

/** aggregate fields of "bikefitting.bikefitting_session" */
export type Bikefitting_Bikefitting_Session_Aggregate_Fields = {
  __typename?: 'bikefitting_bikefitting_session_aggregate_fields';
  avg?: Maybe<Bikefitting_Bikefitting_Session_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Bikefitting_Bikefitting_Session_Max_Fields>;
  min?: Maybe<Bikefitting_Bikefitting_Session_Min_Fields>;
  stddev?: Maybe<Bikefitting_Bikefitting_Session_Stddev_Fields>;
  stddev_pop?: Maybe<Bikefitting_Bikefitting_Session_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Bikefitting_Bikefitting_Session_Stddev_Samp_Fields>;
  sum?: Maybe<Bikefitting_Bikefitting_Session_Sum_Fields>;
  var_pop?: Maybe<Bikefitting_Bikefitting_Session_Var_Pop_Fields>;
  var_samp?: Maybe<Bikefitting_Bikefitting_Session_Var_Samp_Fields>;
  variance?: Maybe<Bikefitting_Bikefitting_Session_Variance_Fields>;
};


/** aggregate fields of "bikefitting.bikefitting_session" */
export type Bikefitting_Bikefitting_Session_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bikefitting_Bikefitting_Session_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Bikefitting_Bikefitting_Session_Avg_Fields = {
  __typename?: 'bikefitting_bikefitting_session_avg_fields';
  elbow_angle_3?: Maybe<Scalars['Float']>;
  elbow_angle_6?: Maybe<Scalars['Float']>;
  knee_angle?: Maybe<Scalars['Float']>;
  recommendation_height_value?: Maybe<Scalars['Float']>;
  recommendation_offset_value?: Maybe<Scalars['Float']>;
  shoulder_angle_3?: Maybe<Scalars['Float']>;
  shoulder_angle_6?: Maybe<Scalars['Float']>;
  status_code?: Maybe<Scalars['Float']>;
  upper_body_angle_3?: Maybe<Scalars['Float']>;
  upper_body_angle_6?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "bikefitting.bikefitting_session". All fields are combined with a logical 'AND'. */
export type Bikefitting_Bikefitting_Session_Bool_Exp = {
  _and?: InputMaybe<Array<Bikefitting_Bikefitting_Session_Bool_Exp>>;
  _not?: InputMaybe<Bikefitting_Bikefitting_Session_Bool_Exp>;
  _or?: InputMaybe<Array<Bikefitting_Bikefitting_Session_Bool_Exp>>;
  bikefitting_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  elbow_angle_3?: InputMaybe<Numeric_Comparison_Exp>;
  elbow_angle_6?: InputMaybe<Numeric_Comparison_Exp>;
  image_3?: InputMaybe<String_Comparison_Exp>;
  image_3_raw?: InputMaybe<String_Comparison_Exp>;
  image_6?: InputMaybe<String_Comparison_Exp>;
  image_6_raw?: InputMaybe<String_Comparison_Exp>;
  knee_angle?: InputMaybe<Numeric_Comparison_Exp>;
  knee_plumb_line?: InputMaybe<String_Comparison_Exp>;
  recommendation_height?: InputMaybe<String_Comparison_Exp>;
  recommendation_height_value?: InputMaybe<Numeric_Comparison_Exp>;
  recommendation_offset?: InputMaybe<String_Comparison_Exp>;
  recommendation_offset_value?: InputMaybe<Numeric_Comparison_Exp>;
  recommendation_sitting_position?: InputMaybe<String_Comparison_Exp>;
  shoulder_angle_3?: InputMaybe<Numeric_Comparison_Exp>;
  shoulder_angle_6?: InputMaybe<Numeric_Comparison_Exp>;
  sitting_position?: InputMaybe<String_Comparison_Exp>;
  status_code?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  upper_body_angle_3?: InputMaybe<Numeric_Comparison_Exp>;
  upper_body_angle_6?: InputMaybe<Numeric_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  vendor?: InputMaybe<Organisation_Bool_Exp>;
  vendor_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "bikefitting.bikefitting_session" */
export enum Bikefitting_Bikefitting_Session_Constraint {
  /** unique or primary key constraint on columns "bikefitting_id" */
  BikefittingSessionPkey = 'bikefitting_session_pkey'
}

/** input type for incrementing numeric columns in table "bikefitting.bikefitting_session" */
export type Bikefitting_Bikefitting_Session_Inc_Input = {
  elbow_angle_3?: InputMaybe<Scalars['numeric']>;
  elbow_angle_6?: InputMaybe<Scalars['numeric']>;
  knee_angle?: InputMaybe<Scalars['numeric']>;
  recommendation_height_value?: InputMaybe<Scalars['numeric']>;
  recommendation_offset_value?: InputMaybe<Scalars['numeric']>;
  shoulder_angle_3?: InputMaybe<Scalars['numeric']>;
  shoulder_angle_6?: InputMaybe<Scalars['numeric']>;
  status_code?: InputMaybe<Scalars['Int']>;
  upper_body_angle_3?: InputMaybe<Scalars['numeric']>;
  upper_body_angle_6?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "bikefitting.bikefitting_session" */
export type Bikefitting_Bikefitting_Session_Insert_Input = {
  bikefitting_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  elbow_angle_3?: InputMaybe<Scalars['numeric']>;
  elbow_angle_6?: InputMaybe<Scalars['numeric']>;
  image_3?: InputMaybe<Scalars['String']>;
  image_3_raw?: InputMaybe<Scalars['String']>;
  image_6?: InputMaybe<Scalars['String']>;
  image_6_raw?: InputMaybe<Scalars['String']>;
  knee_angle?: InputMaybe<Scalars['numeric']>;
  knee_plumb_line?: InputMaybe<Scalars['String']>;
  recommendation_height?: InputMaybe<Scalars['String']>;
  recommendation_height_value?: InputMaybe<Scalars['numeric']>;
  recommendation_offset?: InputMaybe<Scalars['String']>;
  recommendation_offset_value?: InputMaybe<Scalars['numeric']>;
  recommendation_sitting_position?: InputMaybe<Scalars['String']>;
  shoulder_angle_3?: InputMaybe<Scalars['numeric']>;
  shoulder_angle_6?: InputMaybe<Scalars['numeric']>;
  sitting_position?: InputMaybe<Scalars['String']>;
  status_code?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  upper_body_angle_3?: InputMaybe<Scalars['numeric']>;
  upper_body_angle_6?: InputMaybe<Scalars['numeric']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
  vendor?: InputMaybe<Organisation_Obj_Rel_Insert_Input>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Bikefitting_Bikefitting_Session_Max_Fields = {
  __typename?: 'bikefitting_bikefitting_session_max_fields';
  bikefitting_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  elbow_angle_3?: Maybe<Scalars['numeric']>;
  elbow_angle_6?: Maybe<Scalars['numeric']>;
  image_3?: Maybe<Scalars['String']>;
  image_3_raw?: Maybe<Scalars['String']>;
  image_6?: Maybe<Scalars['String']>;
  image_6_raw?: Maybe<Scalars['String']>;
  knee_angle?: Maybe<Scalars['numeric']>;
  knee_plumb_line?: Maybe<Scalars['String']>;
  recommendation_height?: Maybe<Scalars['String']>;
  recommendation_height_value?: Maybe<Scalars['numeric']>;
  recommendation_offset?: Maybe<Scalars['String']>;
  recommendation_offset_value?: Maybe<Scalars['numeric']>;
  recommendation_sitting_position?: Maybe<Scalars['String']>;
  shoulder_angle_3?: Maybe<Scalars['numeric']>;
  shoulder_angle_6?: Maybe<Scalars['numeric']>;
  sitting_position?: Maybe<Scalars['String']>;
  status_code?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  upper_body_angle_3?: Maybe<Scalars['numeric']>;
  upper_body_angle_6?: Maybe<Scalars['numeric']>;
  user_id?: Maybe<Scalars['uuid']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Bikefitting_Bikefitting_Session_Min_Fields = {
  __typename?: 'bikefitting_bikefitting_session_min_fields';
  bikefitting_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  elbow_angle_3?: Maybe<Scalars['numeric']>;
  elbow_angle_6?: Maybe<Scalars['numeric']>;
  image_3?: Maybe<Scalars['String']>;
  image_3_raw?: Maybe<Scalars['String']>;
  image_6?: Maybe<Scalars['String']>;
  image_6_raw?: Maybe<Scalars['String']>;
  knee_angle?: Maybe<Scalars['numeric']>;
  knee_plumb_line?: Maybe<Scalars['String']>;
  recommendation_height?: Maybe<Scalars['String']>;
  recommendation_height_value?: Maybe<Scalars['numeric']>;
  recommendation_offset?: Maybe<Scalars['String']>;
  recommendation_offset_value?: Maybe<Scalars['numeric']>;
  recommendation_sitting_position?: Maybe<Scalars['String']>;
  shoulder_angle_3?: Maybe<Scalars['numeric']>;
  shoulder_angle_6?: Maybe<Scalars['numeric']>;
  sitting_position?: Maybe<Scalars['String']>;
  status_code?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  upper_body_angle_3?: Maybe<Scalars['numeric']>;
  upper_body_angle_6?: Maybe<Scalars['numeric']>;
  user_id?: Maybe<Scalars['uuid']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "bikefitting.bikefitting_session" */
export type Bikefitting_Bikefitting_Session_Mutation_Response = {
  __typename?: 'bikefitting_bikefitting_session_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bikefitting_Bikefitting_Session>;
};

/** on_conflict condition type for table "bikefitting.bikefitting_session" */
export type Bikefitting_Bikefitting_Session_On_Conflict = {
  constraint: Bikefitting_Bikefitting_Session_Constraint;
  update_columns?: Array<Bikefitting_Bikefitting_Session_Update_Column>;
  where?: InputMaybe<Bikefitting_Bikefitting_Session_Bool_Exp>;
};

/** Ordering options when selecting data from "bikefitting.bikefitting_session". */
export type Bikefitting_Bikefitting_Session_Order_By = {
  bikefitting_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  elbow_angle_3?: InputMaybe<Order_By>;
  elbow_angle_6?: InputMaybe<Order_By>;
  image_3?: InputMaybe<Order_By>;
  image_3_raw?: InputMaybe<Order_By>;
  image_6?: InputMaybe<Order_By>;
  image_6_raw?: InputMaybe<Order_By>;
  knee_angle?: InputMaybe<Order_By>;
  knee_plumb_line?: InputMaybe<Order_By>;
  recommendation_height?: InputMaybe<Order_By>;
  recommendation_height_value?: InputMaybe<Order_By>;
  recommendation_offset?: InputMaybe<Order_By>;
  recommendation_offset_value?: InputMaybe<Order_By>;
  recommendation_sitting_position?: InputMaybe<Order_By>;
  shoulder_angle_3?: InputMaybe<Order_By>;
  shoulder_angle_6?: InputMaybe<Order_By>;
  sitting_position?: InputMaybe<Order_By>;
  status_code?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  upper_body_angle_3?: InputMaybe<Order_By>;
  upper_body_angle_6?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
  vendor?: InputMaybe<Organisation_Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: bikefitting.bikefitting_session */
export type Bikefitting_Bikefitting_Session_Pk_Columns_Input = {
  bikefitting_id: Scalars['uuid'];
};

/** select columns of table "bikefitting.bikefitting_session" */
export enum Bikefitting_Bikefitting_Session_Select_Column {
  /** column name */
  BikefittingId = 'bikefitting_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ElbowAngle_3 = 'elbow_angle_3',
  /** column name */
  ElbowAngle_6 = 'elbow_angle_6',
  /** column name */
  Image_3 = 'image_3',
  /** column name */
  Image_3Raw = 'image_3_raw',
  /** column name */
  Image_6 = 'image_6',
  /** column name */
  Image_6Raw = 'image_6_raw',
  /** column name */
  KneeAngle = 'knee_angle',
  /** column name */
  KneePlumbLine = 'knee_plumb_line',
  /** column name */
  RecommendationHeight = 'recommendation_height',
  /** column name */
  RecommendationHeightValue = 'recommendation_height_value',
  /** column name */
  RecommendationOffset = 'recommendation_offset',
  /** column name */
  RecommendationOffsetValue = 'recommendation_offset_value',
  /** column name */
  RecommendationSittingPosition = 'recommendation_sitting_position',
  /** column name */
  ShoulderAngle_3 = 'shoulder_angle_3',
  /** column name */
  ShoulderAngle_6 = 'shoulder_angle_6',
  /** column name */
  SittingPosition = 'sitting_position',
  /** column name */
  StatusCode = 'status_code',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpperBodyAngle_3 = 'upper_body_angle_3',
  /** column name */
  UpperBodyAngle_6 = 'upper_body_angle_6',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VendorId = 'vendor_id'
}

/** input type for updating data in table "bikefitting.bikefitting_session" */
export type Bikefitting_Bikefitting_Session_Set_Input = {
  bikefitting_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  elbow_angle_3?: InputMaybe<Scalars['numeric']>;
  elbow_angle_6?: InputMaybe<Scalars['numeric']>;
  image_3?: InputMaybe<Scalars['String']>;
  image_3_raw?: InputMaybe<Scalars['String']>;
  image_6?: InputMaybe<Scalars['String']>;
  image_6_raw?: InputMaybe<Scalars['String']>;
  knee_angle?: InputMaybe<Scalars['numeric']>;
  knee_plumb_line?: InputMaybe<Scalars['String']>;
  recommendation_height?: InputMaybe<Scalars['String']>;
  recommendation_height_value?: InputMaybe<Scalars['numeric']>;
  recommendation_offset?: InputMaybe<Scalars['String']>;
  recommendation_offset_value?: InputMaybe<Scalars['numeric']>;
  recommendation_sitting_position?: InputMaybe<Scalars['String']>;
  shoulder_angle_3?: InputMaybe<Scalars['numeric']>;
  shoulder_angle_6?: InputMaybe<Scalars['numeric']>;
  sitting_position?: InputMaybe<Scalars['String']>;
  status_code?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  upper_body_angle_3?: InputMaybe<Scalars['numeric']>;
  upper_body_angle_6?: InputMaybe<Scalars['numeric']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Bikefitting_Bikefitting_Session_Stddev_Fields = {
  __typename?: 'bikefitting_bikefitting_session_stddev_fields';
  elbow_angle_3?: Maybe<Scalars['Float']>;
  elbow_angle_6?: Maybe<Scalars['Float']>;
  knee_angle?: Maybe<Scalars['Float']>;
  recommendation_height_value?: Maybe<Scalars['Float']>;
  recommendation_offset_value?: Maybe<Scalars['Float']>;
  shoulder_angle_3?: Maybe<Scalars['Float']>;
  shoulder_angle_6?: Maybe<Scalars['Float']>;
  status_code?: Maybe<Scalars['Float']>;
  upper_body_angle_3?: Maybe<Scalars['Float']>;
  upper_body_angle_6?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Bikefitting_Bikefitting_Session_Stddev_Pop_Fields = {
  __typename?: 'bikefitting_bikefitting_session_stddev_pop_fields';
  elbow_angle_3?: Maybe<Scalars['Float']>;
  elbow_angle_6?: Maybe<Scalars['Float']>;
  knee_angle?: Maybe<Scalars['Float']>;
  recommendation_height_value?: Maybe<Scalars['Float']>;
  recommendation_offset_value?: Maybe<Scalars['Float']>;
  shoulder_angle_3?: Maybe<Scalars['Float']>;
  shoulder_angle_6?: Maybe<Scalars['Float']>;
  status_code?: Maybe<Scalars['Float']>;
  upper_body_angle_3?: Maybe<Scalars['Float']>;
  upper_body_angle_6?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Bikefitting_Bikefitting_Session_Stddev_Samp_Fields = {
  __typename?: 'bikefitting_bikefitting_session_stddev_samp_fields';
  elbow_angle_3?: Maybe<Scalars['Float']>;
  elbow_angle_6?: Maybe<Scalars['Float']>;
  knee_angle?: Maybe<Scalars['Float']>;
  recommendation_height_value?: Maybe<Scalars['Float']>;
  recommendation_offset_value?: Maybe<Scalars['Float']>;
  shoulder_angle_3?: Maybe<Scalars['Float']>;
  shoulder_angle_6?: Maybe<Scalars['Float']>;
  status_code?: Maybe<Scalars['Float']>;
  upper_body_angle_3?: Maybe<Scalars['Float']>;
  upper_body_angle_6?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "bikefitting_bikefitting_session" */
export type Bikefitting_Bikefitting_Session_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bikefitting_Bikefitting_Session_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bikefitting_Bikefitting_Session_Stream_Cursor_Value_Input = {
  bikefitting_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  elbow_angle_3?: InputMaybe<Scalars['numeric']>;
  elbow_angle_6?: InputMaybe<Scalars['numeric']>;
  image_3?: InputMaybe<Scalars['String']>;
  image_3_raw?: InputMaybe<Scalars['String']>;
  image_6?: InputMaybe<Scalars['String']>;
  image_6_raw?: InputMaybe<Scalars['String']>;
  knee_angle?: InputMaybe<Scalars['numeric']>;
  knee_plumb_line?: InputMaybe<Scalars['String']>;
  recommendation_height?: InputMaybe<Scalars['String']>;
  recommendation_height_value?: InputMaybe<Scalars['numeric']>;
  recommendation_offset?: InputMaybe<Scalars['String']>;
  recommendation_offset_value?: InputMaybe<Scalars['numeric']>;
  recommendation_sitting_position?: InputMaybe<Scalars['String']>;
  shoulder_angle_3?: InputMaybe<Scalars['numeric']>;
  shoulder_angle_6?: InputMaybe<Scalars['numeric']>;
  sitting_position?: InputMaybe<Scalars['String']>;
  status_code?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  upper_body_angle_3?: InputMaybe<Scalars['numeric']>;
  upper_body_angle_6?: InputMaybe<Scalars['numeric']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Bikefitting_Bikefitting_Session_Sum_Fields = {
  __typename?: 'bikefitting_bikefitting_session_sum_fields';
  elbow_angle_3?: Maybe<Scalars['numeric']>;
  elbow_angle_6?: Maybe<Scalars['numeric']>;
  knee_angle?: Maybe<Scalars['numeric']>;
  recommendation_height_value?: Maybe<Scalars['numeric']>;
  recommendation_offset_value?: Maybe<Scalars['numeric']>;
  shoulder_angle_3?: Maybe<Scalars['numeric']>;
  shoulder_angle_6?: Maybe<Scalars['numeric']>;
  status_code?: Maybe<Scalars['Int']>;
  upper_body_angle_3?: Maybe<Scalars['numeric']>;
  upper_body_angle_6?: Maybe<Scalars['numeric']>;
};

/** update columns of table "bikefitting.bikefitting_session" */
export enum Bikefitting_Bikefitting_Session_Update_Column {
  /** column name */
  BikefittingId = 'bikefitting_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ElbowAngle_3 = 'elbow_angle_3',
  /** column name */
  ElbowAngle_6 = 'elbow_angle_6',
  /** column name */
  Image_3 = 'image_3',
  /** column name */
  Image_3Raw = 'image_3_raw',
  /** column name */
  Image_6 = 'image_6',
  /** column name */
  Image_6Raw = 'image_6_raw',
  /** column name */
  KneeAngle = 'knee_angle',
  /** column name */
  KneePlumbLine = 'knee_plumb_line',
  /** column name */
  RecommendationHeight = 'recommendation_height',
  /** column name */
  RecommendationHeightValue = 'recommendation_height_value',
  /** column name */
  RecommendationOffset = 'recommendation_offset',
  /** column name */
  RecommendationOffsetValue = 'recommendation_offset_value',
  /** column name */
  RecommendationSittingPosition = 'recommendation_sitting_position',
  /** column name */
  ShoulderAngle_3 = 'shoulder_angle_3',
  /** column name */
  ShoulderAngle_6 = 'shoulder_angle_6',
  /** column name */
  SittingPosition = 'sitting_position',
  /** column name */
  StatusCode = 'status_code',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpperBodyAngle_3 = 'upper_body_angle_3',
  /** column name */
  UpperBodyAngle_6 = 'upper_body_angle_6',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VendorId = 'vendor_id'
}

export type Bikefitting_Bikefitting_Session_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Bikefitting_Bikefitting_Session_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Bikefitting_Bikefitting_Session_Set_Input>;
  /** filter the rows which have to be updated */
  where: Bikefitting_Bikefitting_Session_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Bikefitting_Bikefitting_Session_Var_Pop_Fields = {
  __typename?: 'bikefitting_bikefitting_session_var_pop_fields';
  elbow_angle_3?: Maybe<Scalars['Float']>;
  elbow_angle_6?: Maybe<Scalars['Float']>;
  knee_angle?: Maybe<Scalars['Float']>;
  recommendation_height_value?: Maybe<Scalars['Float']>;
  recommendation_offset_value?: Maybe<Scalars['Float']>;
  shoulder_angle_3?: Maybe<Scalars['Float']>;
  shoulder_angle_6?: Maybe<Scalars['Float']>;
  status_code?: Maybe<Scalars['Float']>;
  upper_body_angle_3?: Maybe<Scalars['Float']>;
  upper_body_angle_6?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Bikefitting_Bikefitting_Session_Var_Samp_Fields = {
  __typename?: 'bikefitting_bikefitting_session_var_samp_fields';
  elbow_angle_3?: Maybe<Scalars['Float']>;
  elbow_angle_6?: Maybe<Scalars['Float']>;
  knee_angle?: Maybe<Scalars['Float']>;
  recommendation_height_value?: Maybe<Scalars['Float']>;
  recommendation_offset_value?: Maybe<Scalars['Float']>;
  shoulder_angle_3?: Maybe<Scalars['Float']>;
  shoulder_angle_6?: Maybe<Scalars['Float']>;
  status_code?: Maybe<Scalars['Float']>;
  upper_body_angle_3?: Maybe<Scalars['Float']>;
  upper_body_angle_6?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Bikefitting_Bikefitting_Session_Variance_Fields = {
  __typename?: 'bikefitting_bikefitting_session_variance_fields';
  elbow_angle_3?: Maybe<Scalars['Float']>;
  elbow_angle_6?: Maybe<Scalars['Float']>;
  knee_angle?: Maybe<Scalars['Float']>;
  recommendation_height_value?: Maybe<Scalars['Float']>;
  recommendation_offset_value?: Maybe<Scalars['Float']>;
  shoulder_angle_3?: Maybe<Scalars['Float']>;
  shoulder_angle_6?: Maybe<Scalars['Float']>;
  status_code?: Maybe<Scalars['Float']>;
  upper_body_angle_3?: Maybe<Scalars['Float']>;
  upper_body_angle_6?: Maybe<Scalars['Float']>;
};

/** This table contains all 'bookings' regarding the commissions for the vendors, i.e. additional commission and payouts. */
export type Commission_Account = {
  __typename?: 'commission_account';
  amount: Scalars['float8'];
  /** An object relationship */
  answer?: Maybe<Customer_Answer>;
  answer_id: Scalars['String'];
  booking_time: Scalars['timestamptz'];
  booking_title: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  metadata?: Maybe<Scalars['jsonb']>;
  type: Commission_Type_Enum;
  user_id?: Maybe<Scalars['uuid']>;
  user_name?: Maybe<Scalars['String']>;
  vendor_id: Scalars['String'];
  vendor_name: Scalars['String'];
};


/** This table contains all 'bookings' regarding the commissions for the vendors, i.e. additional commission and payouts. */
export type Commission_AccountMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "commission.account" */
export type Commission_Account_Aggregate = {
  __typename?: 'commission_account_aggregate';
  aggregate?: Maybe<Commission_Account_Aggregate_Fields>;
  nodes: Array<Commission_Account>;
};

/** aggregate fields of "commission.account" */
export type Commission_Account_Aggregate_Fields = {
  __typename?: 'commission_account_aggregate_fields';
  avg?: Maybe<Commission_Account_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Commission_Account_Max_Fields>;
  min?: Maybe<Commission_Account_Min_Fields>;
  stddev?: Maybe<Commission_Account_Stddev_Fields>;
  stddev_pop?: Maybe<Commission_Account_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Commission_Account_Stddev_Samp_Fields>;
  sum?: Maybe<Commission_Account_Sum_Fields>;
  var_pop?: Maybe<Commission_Account_Var_Pop_Fields>;
  var_samp?: Maybe<Commission_Account_Var_Samp_Fields>;
  variance?: Maybe<Commission_Account_Variance_Fields>;
};


/** aggregate fields of "commission.account" */
export type Commission_Account_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Commission_Account_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Commission_Account_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Commission_Account_Avg_Fields = {
  __typename?: 'commission_account_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "commission.account_balance" */
export type Commission_Account_Balance = {
  __typename?: 'commission_account_balance';
  amount?: Maybe<Scalars['float8']>;
  quarter?: Maybe<Scalars['float8']>;
  vendor_id?: Maybe<Scalars['String']>;
  vendor_name?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['float8']>;
};

/** aggregated selection of "commission.account_balance" */
export type Commission_Account_Balance_Aggregate = {
  __typename?: 'commission_account_balance_aggregate';
  aggregate?: Maybe<Commission_Account_Balance_Aggregate_Fields>;
  nodes: Array<Commission_Account_Balance>;
};

/** aggregate fields of "commission.account_balance" */
export type Commission_Account_Balance_Aggregate_Fields = {
  __typename?: 'commission_account_balance_aggregate_fields';
  avg?: Maybe<Commission_Account_Balance_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Commission_Account_Balance_Max_Fields>;
  min?: Maybe<Commission_Account_Balance_Min_Fields>;
  stddev?: Maybe<Commission_Account_Balance_Stddev_Fields>;
  stddev_pop?: Maybe<Commission_Account_Balance_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Commission_Account_Balance_Stddev_Samp_Fields>;
  sum?: Maybe<Commission_Account_Balance_Sum_Fields>;
  var_pop?: Maybe<Commission_Account_Balance_Var_Pop_Fields>;
  var_samp?: Maybe<Commission_Account_Balance_Var_Samp_Fields>;
  variance?: Maybe<Commission_Account_Balance_Variance_Fields>;
};


/** aggregate fields of "commission.account_balance" */
export type Commission_Account_Balance_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Commission_Account_Balance_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Commission_Account_Balance_Avg_Fields = {
  __typename?: 'commission_account_balance_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "commission.account_balance". All fields are combined with a logical 'AND'. */
export type Commission_Account_Balance_Bool_Exp = {
  _and?: InputMaybe<Array<Commission_Account_Balance_Bool_Exp>>;
  _not?: InputMaybe<Commission_Account_Balance_Bool_Exp>;
  _or?: InputMaybe<Array<Commission_Account_Balance_Bool_Exp>>;
  amount?: InputMaybe<Float8_Comparison_Exp>;
  quarter?: InputMaybe<Float8_Comparison_Exp>;
  vendor_id?: InputMaybe<String_Comparison_Exp>;
  vendor_name?: InputMaybe<String_Comparison_Exp>;
  year?: InputMaybe<Float8_Comparison_Exp>;
};

/** aggregate max on columns */
export type Commission_Account_Balance_Max_Fields = {
  __typename?: 'commission_account_balance_max_fields';
  amount?: Maybe<Scalars['float8']>;
  quarter?: Maybe<Scalars['float8']>;
  vendor_id?: Maybe<Scalars['String']>;
  vendor_name?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['float8']>;
};

/** aggregate min on columns */
export type Commission_Account_Balance_Min_Fields = {
  __typename?: 'commission_account_balance_min_fields';
  amount?: Maybe<Scalars['float8']>;
  quarter?: Maybe<Scalars['float8']>;
  vendor_id?: Maybe<Scalars['String']>;
  vendor_name?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['float8']>;
};

/** Ordering options when selecting data from "commission.account_balance". */
export type Commission_Account_Balance_Order_By = {
  amount?: InputMaybe<Order_By>;
  quarter?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
  vendor_name?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** select columns of table "commission.account_balance" */
export enum Commission_Account_Balance_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Quarter = 'quarter',
  /** column name */
  VendorId = 'vendor_id',
  /** column name */
  VendorName = 'vendor_name',
  /** column name */
  Year = 'year'
}

/** aggregate stddev on columns */
export type Commission_Account_Balance_Stddev_Fields = {
  __typename?: 'commission_account_balance_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Commission_Account_Balance_Stddev_Pop_Fields = {
  __typename?: 'commission_account_balance_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Commission_Account_Balance_Stddev_Samp_Fields = {
  __typename?: 'commission_account_balance_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "commission_account_balance" */
export type Commission_Account_Balance_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Commission_Account_Balance_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Commission_Account_Balance_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  quarter?: InputMaybe<Scalars['float8']>;
  vendor_id?: InputMaybe<Scalars['String']>;
  vendor_name?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['float8']>;
};

/** aggregate sum on columns */
export type Commission_Account_Balance_Sum_Fields = {
  __typename?: 'commission_account_balance_sum_fields';
  amount?: Maybe<Scalars['float8']>;
  quarter?: Maybe<Scalars['float8']>;
  year?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type Commission_Account_Balance_Var_Pop_Fields = {
  __typename?: 'commission_account_balance_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Commission_Account_Balance_Var_Samp_Fields = {
  __typename?: 'commission_account_balance_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Commission_Account_Balance_Variance_Fields = {
  __typename?: 'commission_account_balance_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "commission.account". All fields are combined with a logical 'AND'. */
export type Commission_Account_Bool_Exp = {
  _and?: InputMaybe<Array<Commission_Account_Bool_Exp>>;
  _not?: InputMaybe<Commission_Account_Bool_Exp>;
  _or?: InputMaybe<Array<Commission_Account_Bool_Exp>>;
  amount?: InputMaybe<Float8_Comparison_Exp>;
  answer?: InputMaybe<Customer_Answer_Bool_Exp>;
  answer_id?: InputMaybe<String_Comparison_Exp>;
  booking_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  booking_title?: InputMaybe<String_Comparison_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  type?: InputMaybe<Commission_Type_Enum_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_name?: InputMaybe<String_Comparison_Exp>;
  vendor_id?: InputMaybe<String_Comparison_Exp>;
  vendor_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "commission.account" */
export enum Commission_Account_Constraint {
  /** unique or primary key constraint on columns "id" */
  AccountIdKey = 'account_id_key',
  /** unique or primary key constraint on columns "vendor_id", "booking_time", "answer_id", "type" */
  AccountPkey = 'account_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Commission_Account_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Commission_Account_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Commission_Account_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "commission.account" */
export type Commission_Account_Inc_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "commission.account" */
export type Commission_Account_Insert_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  answer?: InputMaybe<Customer_Answer_Obj_Rel_Insert_Input>;
  answer_id?: InputMaybe<Scalars['String']>;
  booking_time?: InputMaybe<Scalars['timestamptz']>;
  booking_title?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Commission_Type_Enum>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_name?: InputMaybe<Scalars['String']>;
  vendor_id?: InputMaybe<Scalars['String']>;
  vendor_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Commission_Account_Max_Fields = {
  __typename?: 'commission_account_max_fields';
  amount?: Maybe<Scalars['float8']>;
  answer_id?: Maybe<Scalars['String']>;
  booking_time?: Maybe<Scalars['timestamptz']>;
  booking_title?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_name?: Maybe<Scalars['String']>;
  vendor_id?: Maybe<Scalars['String']>;
  vendor_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Commission_Account_Min_Fields = {
  __typename?: 'commission_account_min_fields';
  amount?: Maybe<Scalars['float8']>;
  answer_id?: Maybe<Scalars['String']>;
  booking_time?: Maybe<Scalars['timestamptz']>;
  booking_title?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_name?: Maybe<Scalars['String']>;
  vendor_id?: Maybe<Scalars['String']>;
  vendor_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "commission.account" */
export type Commission_Account_Mutation_Response = {
  __typename?: 'commission_account_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Commission_Account>;
};

/** on_conflict condition type for table "commission.account" */
export type Commission_Account_On_Conflict = {
  constraint: Commission_Account_Constraint;
  update_columns?: Array<Commission_Account_Update_Column>;
  where?: InputMaybe<Commission_Account_Bool_Exp>;
};

/** Ordering options when selecting data from "commission.account". */
export type Commission_Account_Order_By = {
  amount?: InputMaybe<Order_By>;
  answer?: InputMaybe<Customer_Answer_Order_By>;
  answer_id?: InputMaybe<Order_By>;
  booking_time?: InputMaybe<Order_By>;
  booking_title?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_name?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
  vendor_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: commission.account */
export type Commission_Account_Pk_Columns_Input = {
  answer_id: Scalars['String'];
  booking_time: Scalars['timestamptz'];
  type: Commission_Type_Enum;
  vendor_id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Commission_Account_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "commission.account" */
export enum Commission_Account_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  AnswerId = 'answer_id',
  /** column name */
  BookingTime = 'booking_time',
  /** column name */
  BookingTitle = 'booking_title',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserName = 'user_name',
  /** column name */
  VendorId = 'vendor_id',
  /** column name */
  VendorName = 'vendor_name'
}

/** input type for updating data in table "commission.account" */
export type Commission_Account_Set_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  answer_id?: InputMaybe<Scalars['String']>;
  booking_time?: InputMaybe<Scalars['timestamptz']>;
  booking_title?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Commission_Type_Enum>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_name?: InputMaybe<Scalars['String']>;
  vendor_id?: InputMaybe<Scalars['String']>;
  vendor_name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Commission_Account_Stddev_Fields = {
  __typename?: 'commission_account_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Commission_Account_Stddev_Pop_Fields = {
  __typename?: 'commission_account_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Commission_Account_Stddev_Samp_Fields = {
  __typename?: 'commission_account_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "commission_account" */
export type Commission_Account_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Commission_Account_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Commission_Account_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  answer_id?: InputMaybe<Scalars['String']>;
  booking_time?: InputMaybe<Scalars['timestamptz']>;
  booking_title?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Commission_Type_Enum>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_name?: InputMaybe<Scalars['String']>;
  vendor_id?: InputMaybe<Scalars['String']>;
  vendor_name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Commission_Account_Sum_Fields = {
  __typename?: 'commission_account_sum_fields';
  amount?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "commission.account" */
export enum Commission_Account_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  AnswerId = 'answer_id',
  /** column name */
  BookingTime = 'booking_time',
  /** column name */
  BookingTitle = 'booking_title',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserName = 'user_name',
  /** column name */
  VendorId = 'vendor_id',
  /** column name */
  VendorName = 'vendor_name'
}

export type Commission_Account_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Commission_Account_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Commission_Account_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Commission_Account_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Commission_Account_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Commission_Account_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Commission_Account_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Commission_Account_Set_Input>;
  /** filter the rows which have to be updated */
  where: Commission_Account_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Commission_Account_Var_Pop_Fields = {
  __typename?: 'commission_account_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Commission_Account_Var_Samp_Fields = {
  __typename?: 'commission_account_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Commission_Account_Variance_Fields = {
  __typename?: 'commission_account_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** stores booster for the comission for a certain vendor */
export type Commission_Boost = {
  __typename?: 'commission_boost';
  /** checks if the booster is currently active */
  active?: Maybe<Scalars['Boolean']>;
  comment?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  end_date: Scalars['date'];
  factor: Scalars['float8'];
  start_date: Scalars['date'];
  /** An object relationship */
  vendor?: Maybe<Organisation>;
  vendor_id: Scalars['String'];
};

/** aggregated selection of "commission.boost" */
export type Commission_Boost_Aggregate = {
  __typename?: 'commission_boost_aggregate';
  aggregate?: Maybe<Commission_Boost_Aggregate_Fields>;
  nodes: Array<Commission_Boost>;
};

/** aggregate fields of "commission.boost" */
export type Commission_Boost_Aggregate_Fields = {
  __typename?: 'commission_boost_aggregate_fields';
  avg?: Maybe<Commission_Boost_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Commission_Boost_Max_Fields>;
  min?: Maybe<Commission_Boost_Min_Fields>;
  stddev?: Maybe<Commission_Boost_Stddev_Fields>;
  stddev_pop?: Maybe<Commission_Boost_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Commission_Boost_Stddev_Samp_Fields>;
  sum?: Maybe<Commission_Boost_Sum_Fields>;
  var_pop?: Maybe<Commission_Boost_Var_Pop_Fields>;
  var_samp?: Maybe<Commission_Boost_Var_Samp_Fields>;
  variance?: Maybe<Commission_Boost_Variance_Fields>;
};


/** aggregate fields of "commission.boost" */
export type Commission_Boost_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Commission_Boost_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Commission_Boost_Avg_Fields = {
  __typename?: 'commission_boost_avg_fields';
  factor?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "commission.boost". All fields are combined with a logical 'AND'. */
export type Commission_Boost_Bool_Exp = {
  _and?: InputMaybe<Array<Commission_Boost_Bool_Exp>>;
  _not?: InputMaybe<Commission_Boost_Bool_Exp>;
  _or?: InputMaybe<Array<Commission_Boost_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  end_date?: InputMaybe<Date_Comparison_Exp>;
  factor?: InputMaybe<Float8_Comparison_Exp>;
  start_date?: InputMaybe<Date_Comparison_Exp>;
  vendor?: InputMaybe<Organisation_Bool_Exp>;
  vendor_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "commission.boost" */
export enum Commission_Boost_Constraint {
  /** unique or primary key constraint on columns "vendor_id", "start_date" */
  BoostPkey = 'boost_pkey'
}

/** input type for incrementing numeric columns in table "commission.boost" */
export type Commission_Boost_Inc_Input = {
  factor?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "commission.boost" */
export type Commission_Boost_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  end_date?: InputMaybe<Scalars['date']>;
  factor?: InputMaybe<Scalars['float8']>;
  start_date?: InputMaybe<Scalars['date']>;
  vendor?: InputMaybe<Organisation_Obj_Rel_Insert_Input>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Commission_Boost_Max_Fields = {
  __typename?: 'commission_boost_max_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  end_date?: Maybe<Scalars['date']>;
  factor?: Maybe<Scalars['float8']>;
  start_date?: Maybe<Scalars['date']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Commission_Boost_Min_Fields = {
  __typename?: 'commission_boost_min_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  end_date?: Maybe<Scalars['date']>;
  factor?: Maybe<Scalars['float8']>;
  start_date?: Maybe<Scalars['date']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "commission.boost" */
export type Commission_Boost_Mutation_Response = {
  __typename?: 'commission_boost_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Commission_Boost>;
};

/** on_conflict condition type for table "commission.boost" */
export type Commission_Boost_On_Conflict = {
  constraint: Commission_Boost_Constraint;
  update_columns?: Array<Commission_Boost_Update_Column>;
  where?: InputMaybe<Commission_Boost_Bool_Exp>;
};

/** Ordering options when selecting data from "commission.boost". */
export type Commission_Boost_Order_By = {
  active?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  factor?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  vendor?: InputMaybe<Organisation_Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: commission.boost */
export type Commission_Boost_Pk_Columns_Input = {
  start_date: Scalars['date'];
  vendor_id: Scalars['String'];
};

/** select columns of table "commission.boost" */
export enum Commission_Boost_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Factor = 'factor',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  VendorId = 'vendor_id'
}

/** input type for updating data in table "commission.boost" */
export type Commission_Boost_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  end_date?: InputMaybe<Scalars['date']>;
  factor?: InputMaybe<Scalars['float8']>;
  start_date?: InputMaybe<Scalars['date']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Commission_Boost_Stddev_Fields = {
  __typename?: 'commission_boost_stddev_fields';
  factor?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Commission_Boost_Stddev_Pop_Fields = {
  __typename?: 'commission_boost_stddev_pop_fields';
  factor?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Commission_Boost_Stddev_Samp_Fields = {
  __typename?: 'commission_boost_stddev_samp_fields';
  factor?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "commission_boost" */
export type Commission_Boost_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Commission_Boost_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Commission_Boost_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  end_date?: InputMaybe<Scalars['date']>;
  factor?: InputMaybe<Scalars['float8']>;
  start_date?: InputMaybe<Scalars['date']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Commission_Boost_Sum_Fields = {
  __typename?: 'commission_boost_sum_fields';
  factor?: Maybe<Scalars['float8']>;
};

/** update columns of table "commission.boost" */
export enum Commission_Boost_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Factor = 'factor',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  VendorId = 'vendor_id'
}

export type Commission_Boost_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Commission_Boost_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Commission_Boost_Set_Input>;
  /** filter the rows which have to be updated */
  where: Commission_Boost_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Commission_Boost_Var_Pop_Fields = {
  __typename?: 'commission_boost_var_pop_fields';
  factor?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Commission_Boost_Var_Samp_Fields = {
  __typename?: 'commission_boost_var_samp_fields';
  factor?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Commission_Boost_Variance_Fields = {
  __typename?: 'commission_boost_variance_fields';
  factor?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "commission.detailed_account_balance" */
export type Commission_Detailed_Account_Balance = {
  __typename?: 'commission_detailed_account_balance';
  amount?: Maybe<Scalars['float8']>;
  quarter?: Maybe<Scalars['float8']>;
  type?: Maybe<Scalars['String']>;
  /** An object relationship */
  vendor?: Maybe<Organisation>;
  vendor_id?: Maybe<Scalars['String']>;
  vendor_name?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['float8']>;
};

/** aggregated selection of "commission.detailed_account_balance" */
export type Commission_Detailed_Account_Balance_Aggregate = {
  __typename?: 'commission_detailed_account_balance_aggregate';
  aggregate?: Maybe<Commission_Detailed_Account_Balance_Aggregate_Fields>;
  nodes: Array<Commission_Detailed_Account_Balance>;
};

/** aggregate fields of "commission.detailed_account_balance" */
export type Commission_Detailed_Account_Balance_Aggregate_Fields = {
  __typename?: 'commission_detailed_account_balance_aggregate_fields';
  avg?: Maybe<Commission_Detailed_Account_Balance_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Commission_Detailed_Account_Balance_Max_Fields>;
  min?: Maybe<Commission_Detailed_Account_Balance_Min_Fields>;
  stddev?: Maybe<Commission_Detailed_Account_Balance_Stddev_Fields>;
  stddev_pop?: Maybe<Commission_Detailed_Account_Balance_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Commission_Detailed_Account_Balance_Stddev_Samp_Fields>;
  sum?: Maybe<Commission_Detailed_Account_Balance_Sum_Fields>;
  var_pop?: Maybe<Commission_Detailed_Account_Balance_Var_Pop_Fields>;
  var_samp?: Maybe<Commission_Detailed_Account_Balance_Var_Samp_Fields>;
  variance?: Maybe<Commission_Detailed_Account_Balance_Variance_Fields>;
};


/** aggregate fields of "commission.detailed_account_balance" */
export type Commission_Detailed_Account_Balance_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Commission_Detailed_Account_Balance_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Commission_Detailed_Account_Balance_Avg_Fields = {
  __typename?: 'commission_detailed_account_balance_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "commission.detailed_account_balance". All fields are combined with a logical 'AND'. */
export type Commission_Detailed_Account_Balance_Bool_Exp = {
  _and?: InputMaybe<Array<Commission_Detailed_Account_Balance_Bool_Exp>>;
  _not?: InputMaybe<Commission_Detailed_Account_Balance_Bool_Exp>;
  _or?: InputMaybe<Array<Commission_Detailed_Account_Balance_Bool_Exp>>;
  amount?: InputMaybe<Float8_Comparison_Exp>;
  quarter?: InputMaybe<Float8_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  vendor?: InputMaybe<Organisation_Bool_Exp>;
  vendor_id?: InputMaybe<String_Comparison_Exp>;
  vendor_name?: InputMaybe<String_Comparison_Exp>;
  year?: InputMaybe<Float8_Comparison_Exp>;
};

/** aggregate max on columns */
export type Commission_Detailed_Account_Balance_Max_Fields = {
  __typename?: 'commission_detailed_account_balance_max_fields';
  amount?: Maybe<Scalars['float8']>;
  quarter?: Maybe<Scalars['float8']>;
  type?: Maybe<Scalars['String']>;
  vendor_id?: Maybe<Scalars['String']>;
  vendor_name?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['float8']>;
};

/** aggregate min on columns */
export type Commission_Detailed_Account_Balance_Min_Fields = {
  __typename?: 'commission_detailed_account_balance_min_fields';
  amount?: Maybe<Scalars['float8']>;
  quarter?: Maybe<Scalars['float8']>;
  type?: Maybe<Scalars['String']>;
  vendor_id?: Maybe<Scalars['String']>;
  vendor_name?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['float8']>;
};

/** Ordering options when selecting data from "commission.detailed_account_balance". */
export type Commission_Detailed_Account_Balance_Order_By = {
  amount?: InputMaybe<Order_By>;
  quarter?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  vendor?: InputMaybe<Organisation_Order_By>;
  vendor_id?: InputMaybe<Order_By>;
  vendor_name?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** select columns of table "commission.detailed_account_balance" */
export enum Commission_Detailed_Account_Balance_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Quarter = 'quarter',
  /** column name */
  Type = 'type',
  /** column name */
  VendorId = 'vendor_id',
  /** column name */
  VendorName = 'vendor_name',
  /** column name */
  Year = 'year'
}

/** aggregate stddev on columns */
export type Commission_Detailed_Account_Balance_Stddev_Fields = {
  __typename?: 'commission_detailed_account_balance_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Commission_Detailed_Account_Balance_Stddev_Pop_Fields = {
  __typename?: 'commission_detailed_account_balance_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Commission_Detailed_Account_Balance_Stddev_Samp_Fields = {
  __typename?: 'commission_detailed_account_balance_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "commission_detailed_account_balance" */
export type Commission_Detailed_Account_Balance_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Commission_Detailed_Account_Balance_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Commission_Detailed_Account_Balance_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  quarter?: InputMaybe<Scalars['float8']>;
  type?: InputMaybe<Scalars['String']>;
  vendor_id?: InputMaybe<Scalars['String']>;
  vendor_name?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['float8']>;
};

/** aggregate sum on columns */
export type Commission_Detailed_Account_Balance_Sum_Fields = {
  __typename?: 'commission_detailed_account_balance_sum_fields';
  amount?: Maybe<Scalars['float8']>;
  quarter?: Maybe<Scalars['float8']>;
  year?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type Commission_Detailed_Account_Balance_Var_Pop_Fields = {
  __typename?: 'commission_detailed_account_balance_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Commission_Detailed_Account_Balance_Var_Samp_Fields = {
  __typename?: 'commission_detailed_account_balance_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Commission_Detailed_Account_Balance_Variance_Fields = {
  __typename?: 'commission_detailed_account_balance_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "commission.eligible_answers" */
export type Commission_Eligible_Answers = {
  __typename?: 'commission_eligible_answers';
  article_group_count?: Maybe<Scalars['Int']>;
  article_groups?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  short_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  vendor?: Maybe<Organisation>;
  vendor_id?: Maybe<Scalars['String']>;
};


/** columns and relationships of "commission.eligible_answers" */
export type Commission_Eligible_AnswersArticle_GroupsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "commission.eligible_answers" */
export type Commission_Eligible_Answers_Aggregate = {
  __typename?: 'commission_eligible_answers_aggregate';
  aggregate?: Maybe<Commission_Eligible_Answers_Aggregate_Fields>;
  nodes: Array<Commission_Eligible_Answers>;
};

/** aggregate fields of "commission.eligible_answers" */
export type Commission_Eligible_Answers_Aggregate_Fields = {
  __typename?: 'commission_eligible_answers_aggregate_fields';
  avg?: Maybe<Commission_Eligible_Answers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Commission_Eligible_Answers_Max_Fields>;
  min?: Maybe<Commission_Eligible_Answers_Min_Fields>;
  stddev?: Maybe<Commission_Eligible_Answers_Stddev_Fields>;
  stddev_pop?: Maybe<Commission_Eligible_Answers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Commission_Eligible_Answers_Stddev_Samp_Fields>;
  sum?: Maybe<Commission_Eligible_Answers_Sum_Fields>;
  var_pop?: Maybe<Commission_Eligible_Answers_Var_Pop_Fields>;
  var_samp?: Maybe<Commission_Eligible_Answers_Var_Samp_Fields>;
  variance?: Maybe<Commission_Eligible_Answers_Variance_Fields>;
};


/** aggregate fields of "commission.eligible_answers" */
export type Commission_Eligible_Answers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Commission_Eligible_Answers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Commission_Eligible_Answers_Avg_Fields = {
  __typename?: 'commission_eligible_answers_avg_fields';
  article_group_count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "commission.eligible_answers". All fields are combined with a logical 'AND'. */
export type Commission_Eligible_Answers_Bool_Exp = {
  _and?: InputMaybe<Array<Commission_Eligible_Answers_Bool_Exp>>;
  _not?: InputMaybe<Commission_Eligible_Answers_Bool_Exp>;
  _or?: InputMaybe<Array<Commission_Eligible_Answers_Bool_Exp>>;
  article_group_count?: InputMaybe<Int_Comparison_Exp>;
  article_groups?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  short_id?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  vendor?: InputMaybe<Organisation_Bool_Exp>;
  vendor_id?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Commission_Eligible_Answers_Max_Fields = {
  __typename?: 'commission_eligible_answers_max_fields';
  article_group_count?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  short_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Commission_Eligible_Answers_Min_Fields = {
  __typename?: 'commission_eligible_answers_min_fields';
  article_group_count?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  short_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "commission.eligible_answers". */
export type Commission_Eligible_Answers_Order_By = {
  article_group_count?: InputMaybe<Order_By>;
  article_groups?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  short_id?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
  vendor?: InputMaybe<Organisation_Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** select columns of table "commission.eligible_answers" */
export enum Commission_Eligible_Answers_Select_Column {
  /** column name */
  ArticleGroupCount = 'article_group_count',
  /** column name */
  ArticleGroups = 'article_groups',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ShortId = 'short_id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VendorId = 'vendor_id'
}

/** aggregate stddev on columns */
export type Commission_Eligible_Answers_Stddev_Fields = {
  __typename?: 'commission_eligible_answers_stddev_fields';
  article_group_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Commission_Eligible_Answers_Stddev_Pop_Fields = {
  __typename?: 'commission_eligible_answers_stddev_pop_fields';
  article_group_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Commission_Eligible_Answers_Stddev_Samp_Fields = {
  __typename?: 'commission_eligible_answers_stddev_samp_fields';
  article_group_count?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "commission_eligible_answers" */
export type Commission_Eligible_Answers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Commission_Eligible_Answers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Commission_Eligible_Answers_Stream_Cursor_Value_Input = {
  article_group_count?: InputMaybe<Scalars['Int']>;
  article_groups?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  short_id?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Commission_Eligible_Answers_Sum_Fields = {
  __typename?: 'commission_eligible_answers_sum_fields';
  article_group_count?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Commission_Eligible_Answers_Var_Pop_Fields = {
  __typename?: 'commission_eligible_answers_var_pop_fields';
  article_group_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Commission_Eligible_Answers_Var_Samp_Fields = {
  __typename?: 'commission_eligible_answers_var_samp_fields';
  article_group_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Commission_Eligible_Answers_Variance_Fields = {
  __typename?: 'commission_eligible_answers_variance_fields';
  article_group_count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "commission.eligible_orders" */
export type Commission_Eligible_Orders = {
  __typename?: 'commission_eligible_orders';
  answer_created_at?: Maybe<Scalars['timestamptz']>;
  answer_id?: Maybe<Scalars['String']>;
  commission_value?: Maybe<Scalars['float8']>;
  email_address?: Maybe<Scalars['String']>;
  order_id?: Maybe<Scalars['Int']>;
  order_value?: Maybe<Scalars['float8']>;
  ordered_at?: Maybe<Scalars['timestamptz']>;
  ox_order_id?: Maybe<Scalars['Int']>;
  short_id?: Maybe<Scalars['String']>;
  time_after_answer?: Maybe<Scalars['interval']>;
  /** An object relationship */
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  vendor?: Maybe<Organisation>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "commission.eligible_orders" */
export type Commission_Eligible_Orders_Aggregate = {
  __typename?: 'commission_eligible_orders_aggregate';
  aggregate?: Maybe<Commission_Eligible_Orders_Aggregate_Fields>;
  nodes: Array<Commission_Eligible_Orders>;
};

/** aggregate fields of "commission.eligible_orders" */
export type Commission_Eligible_Orders_Aggregate_Fields = {
  __typename?: 'commission_eligible_orders_aggregate_fields';
  avg?: Maybe<Commission_Eligible_Orders_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Commission_Eligible_Orders_Max_Fields>;
  min?: Maybe<Commission_Eligible_Orders_Min_Fields>;
  stddev?: Maybe<Commission_Eligible_Orders_Stddev_Fields>;
  stddev_pop?: Maybe<Commission_Eligible_Orders_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Commission_Eligible_Orders_Stddev_Samp_Fields>;
  sum?: Maybe<Commission_Eligible_Orders_Sum_Fields>;
  var_pop?: Maybe<Commission_Eligible_Orders_Var_Pop_Fields>;
  var_samp?: Maybe<Commission_Eligible_Orders_Var_Samp_Fields>;
  variance?: Maybe<Commission_Eligible_Orders_Variance_Fields>;
};


/** aggregate fields of "commission.eligible_orders" */
export type Commission_Eligible_Orders_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Commission_Eligible_Orders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Commission_Eligible_Orders_Avg_Fields = {
  __typename?: 'commission_eligible_orders_avg_fields';
  commission_value?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  order_value?: Maybe<Scalars['Float']>;
  ox_order_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "commission.eligible_orders". All fields are combined with a logical 'AND'. */
export type Commission_Eligible_Orders_Bool_Exp = {
  _and?: InputMaybe<Array<Commission_Eligible_Orders_Bool_Exp>>;
  _not?: InputMaybe<Commission_Eligible_Orders_Bool_Exp>;
  _or?: InputMaybe<Array<Commission_Eligible_Orders_Bool_Exp>>;
  answer_created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  answer_id?: InputMaybe<String_Comparison_Exp>;
  commission_value?: InputMaybe<Float8_Comparison_Exp>;
  email_address?: InputMaybe<String_Comparison_Exp>;
  order_id?: InputMaybe<Int_Comparison_Exp>;
  order_value?: InputMaybe<Float8_Comparison_Exp>;
  ordered_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  ox_order_id?: InputMaybe<Int_Comparison_Exp>;
  short_id?: InputMaybe<String_Comparison_Exp>;
  time_after_answer?: InputMaybe<Interval_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  vendor?: InputMaybe<Organisation_Bool_Exp>;
  vendor_id?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Commission_Eligible_Orders_Max_Fields = {
  __typename?: 'commission_eligible_orders_max_fields';
  answer_created_at?: Maybe<Scalars['timestamptz']>;
  answer_id?: Maybe<Scalars['String']>;
  commission_value?: Maybe<Scalars['float8']>;
  email_address?: Maybe<Scalars['String']>;
  order_id?: Maybe<Scalars['Int']>;
  order_value?: Maybe<Scalars['float8']>;
  ordered_at?: Maybe<Scalars['timestamptz']>;
  ox_order_id?: Maybe<Scalars['Int']>;
  short_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Commission_Eligible_Orders_Min_Fields = {
  __typename?: 'commission_eligible_orders_min_fields';
  answer_created_at?: Maybe<Scalars['timestamptz']>;
  answer_id?: Maybe<Scalars['String']>;
  commission_value?: Maybe<Scalars['float8']>;
  email_address?: Maybe<Scalars['String']>;
  order_id?: Maybe<Scalars['Int']>;
  order_value?: Maybe<Scalars['float8']>;
  ordered_at?: Maybe<Scalars['timestamptz']>;
  ox_order_id?: Maybe<Scalars['Int']>;
  short_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "commission.eligible_orders". */
export type Commission_Eligible_Orders_Order_By = {
  answer_created_at?: InputMaybe<Order_By>;
  answer_id?: InputMaybe<Order_By>;
  commission_value?: InputMaybe<Order_By>;
  email_address?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  order_value?: InputMaybe<Order_By>;
  ordered_at?: InputMaybe<Order_By>;
  ox_order_id?: InputMaybe<Order_By>;
  short_id?: InputMaybe<Order_By>;
  time_after_answer?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
  vendor?: InputMaybe<Organisation_Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** select columns of table "commission.eligible_orders" */
export enum Commission_Eligible_Orders_Select_Column {
  /** column name */
  AnswerCreatedAt = 'answer_created_at',
  /** column name */
  AnswerId = 'answer_id',
  /** column name */
  CommissionValue = 'commission_value',
  /** column name */
  EmailAddress = 'email_address',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  OrderValue = 'order_value',
  /** column name */
  OrderedAt = 'ordered_at',
  /** column name */
  OxOrderId = 'ox_order_id',
  /** column name */
  ShortId = 'short_id',
  /** column name */
  TimeAfterAnswer = 'time_after_answer',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VendorId = 'vendor_id'
}

/** aggregate stddev on columns */
export type Commission_Eligible_Orders_Stddev_Fields = {
  __typename?: 'commission_eligible_orders_stddev_fields';
  commission_value?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  order_value?: Maybe<Scalars['Float']>;
  ox_order_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Commission_Eligible_Orders_Stddev_Pop_Fields = {
  __typename?: 'commission_eligible_orders_stddev_pop_fields';
  commission_value?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  order_value?: Maybe<Scalars['Float']>;
  ox_order_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Commission_Eligible_Orders_Stddev_Samp_Fields = {
  __typename?: 'commission_eligible_orders_stddev_samp_fields';
  commission_value?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  order_value?: Maybe<Scalars['Float']>;
  ox_order_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "commission_eligible_orders" */
export type Commission_Eligible_Orders_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Commission_Eligible_Orders_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Commission_Eligible_Orders_Stream_Cursor_Value_Input = {
  answer_created_at?: InputMaybe<Scalars['timestamptz']>;
  answer_id?: InputMaybe<Scalars['String']>;
  commission_value?: InputMaybe<Scalars['float8']>;
  email_address?: InputMaybe<Scalars['String']>;
  order_id?: InputMaybe<Scalars['Int']>;
  order_value?: InputMaybe<Scalars['float8']>;
  ordered_at?: InputMaybe<Scalars['timestamptz']>;
  ox_order_id?: InputMaybe<Scalars['Int']>;
  short_id?: InputMaybe<Scalars['String']>;
  time_after_answer?: InputMaybe<Scalars['interval']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Commission_Eligible_Orders_Sum_Fields = {
  __typename?: 'commission_eligible_orders_sum_fields';
  commission_value?: Maybe<Scalars['float8']>;
  order_id?: Maybe<Scalars['Int']>;
  order_value?: Maybe<Scalars['float8']>;
  ox_order_id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Commission_Eligible_Orders_Var_Pop_Fields = {
  __typename?: 'commission_eligible_orders_var_pop_fields';
  commission_value?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  order_value?: Maybe<Scalars['Float']>;
  ox_order_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Commission_Eligible_Orders_Var_Samp_Fields = {
  __typename?: 'commission_eligible_orders_var_samp_fields';
  commission_value?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  order_value?: Maybe<Scalars['Float']>;
  ox_order_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Commission_Eligible_Orders_Variance_Fields = {
  __typename?: 'commission_eligible_orders_variance_fields';
  commission_value?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  order_value?: Maybe<Scalars['Float']>;
  ox_order_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "commission.payout_log" */
export type Commission_Payout_Log = {
  __typename?: 'commission_payout_log';
  attachment_url?: Maybe<Scalars['String']>;
  bank_transfer_generated_at?: Maybe<Scalars['timestamptz']>;
  be_reference_id?: Maybe<Scalars['String']>;
  be_request_successful_at?: Maybe<Scalars['timestamptz']>;
  created_at: Scalars['timestamptz'];
  metadata?: Maybe<Scalars['jsonb']>;
  payment_reference?: Maybe<Scalars['String']>;
  payout_id?: Maybe<Scalars['Int']>;
  quarter: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  vendor_id: Scalars['String'];
  year: Scalars['Int'];
};


/** columns and relationships of "commission.payout_log" */
export type Commission_Payout_LogMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "commission.payout_log" */
export type Commission_Payout_Log_Aggregate = {
  __typename?: 'commission_payout_log_aggregate';
  aggregate?: Maybe<Commission_Payout_Log_Aggregate_Fields>;
  nodes: Array<Commission_Payout_Log>;
};

/** aggregate fields of "commission.payout_log" */
export type Commission_Payout_Log_Aggregate_Fields = {
  __typename?: 'commission_payout_log_aggregate_fields';
  avg?: Maybe<Commission_Payout_Log_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Commission_Payout_Log_Max_Fields>;
  min?: Maybe<Commission_Payout_Log_Min_Fields>;
  stddev?: Maybe<Commission_Payout_Log_Stddev_Fields>;
  stddev_pop?: Maybe<Commission_Payout_Log_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Commission_Payout_Log_Stddev_Samp_Fields>;
  sum?: Maybe<Commission_Payout_Log_Sum_Fields>;
  var_pop?: Maybe<Commission_Payout_Log_Var_Pop_Fields>;
  var_samp?: Maybe<Commission_Payout_Log_Var_Samp_Fields>;
  variance?: Maybe<Commission_Payout_Log_Variance_Fields>;
};


/** aggregate fields of "commission.payout_log" */
export type Commission_Payout_Log_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Commission_Payout_Log_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Commission_Payout_Log_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Commission_Payout_Log_Avg_Fields = {
  __typename?: 'commission_payout_log_avg_fields';
  payout_id?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "commission.payout_log". All fields are combined with a logical 'AND'. */
export type Commission_Payout_Log_Bool_Exp = {
  _and?: InputMaybe<Array<Commission_Payout_Log_Bool_Exp>>;
  _not?: InputMaybe<Commission_Payout_Log_Bool_Exp>;
  _or?: InputMaybe<Array<Commission_Payout_Log_Bool_Exp>>;
  attachment_url?: InputMaybe<String_Comparison_Exp>;
  bank_transfer_generated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  be_reference_id?: InputMaybe<String_Comparison_Exp>;
  be_request_successful_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  payment_reference?: InputMaybe<String_Comparison_Exp>;
  payout_id?: InputMaybe<Int_Comparison_Exp>;
  quarter?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vendor_id?: InputMaybe<String_Comparison_Exp>;
  year?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "commission.payout_log" */
export enum Commission_Payout_Log_Constraint {
  /** unique or primary key constraint on columns "attachment_url" */
  PayoutLoggingAttachmentUrlKey = 'payout_logging_attachment_url_key',
  /** unique or primary key constraint on columns "be_reference_id" */
  PayoutLoggingBeReferenceIdKey = 'payout_logging_be_reference_id_key',
  /** unique or primary key constraint on columns "payment_reference" */
  PayoutLoggingPaymentReferenceKey = 'payout_logging_payment_reference_key',
  /** unique or primary key constraint on columns "payout_id" */
  PayoutLoggingPayoutIdKey = 'payout_logging_payout_id_key',
  /** unique or primary key constraint on columns "vendor_id", "quarter", "year" */
  PayoutLoggingPkey = 'payout_logging_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Commission_Payout_Log_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Commission_Payout_Log_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Commission_Payout_Log_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "commission.payout_log" */
export type Commission_Payout_Log_Inc_Input = {
  payout_id?: InputMaybe<Scalars['Int']>;
  quarter?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "commission.payout_log" */
export type Commission_Payout_Log_Insert_Input = {
  attachment_url?: InputMaybe<Scalars['String']>;
  bank_transfer_generated_at?: InputMaybe<Scalars['timestamptz']>;
  be_reference_id?: InputMaybe<Scalars['String']>;
  be_request_successful_at?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  payment_reference?: InputMaybe<Scalars['String']>;
  payout_id?: InputMaybe<Scalars['Int']>;
  quarter?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vendor_id?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Commission_Payout_Log_Max_Fields = {
  __typename?: 'commission_payout_log_max_fields';
  attachment_url?: Maybe<Scalars['String']>;
  bank_transfer_generated_at?: Maybe<Scalars['timestamptz']>;
  be_reference_id?: Maybe<Scalars['String']>;
  be_request_successful_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  payment_reference?: Maybe<Scalars['String']>;
  payout_id?: Maybe<Scalars['Int']>;
  quarter?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vendor_id?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Commission_Payout_Log_Min_Fields = {
  __typename?: 'commission_payout_log_min_fields';
  attachment_url?: Maybe<Scalars['String']>;
  bank_transfer_generated_at?: Maybe<Scalars['timestamptz']>;
  be_reference_id?: Maybe<Scalars['String']>;
  be_request_successful_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  payment_reference?: Maybe<Scalars['String']>;
  payout_id?: Maybe<Scalars['Int']>;
  quarter?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vendor_id?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "commission.payout_log" */
export type Commission_Payout_Log_Mutation_Response = {
  __typename?: 'commission_payout_log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Commission_Payout_Log>;
};

/** on_conflict condition type for table "commission.payout_log" */
export type Commission_Payout_Log_On_Conflict = {
  constraint: Commission_Payout_Log_Constraint;
  update_columns?: Array<Commission_Payout_Log_Update_Column>;
  where?: InputMaybe<Commission_Payout_Log_Bool_Exp>;
};

/** Ordering options when selecting data from "commission.payout_log". */
export type Commission_Payout_Log_Order_By = {
  attachment_url?: InputMaybe<Order_By>;
  bank_transfer_generated_at?: InputMaybe<Order_By>;
  be_reference_id?: InputMaybe<Order_By>;
  be_request_successful_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  payment_reference?: InputMaybe<Order_By>;
  payout_id?: InputMaybe<Order_By>;
  quarter?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** primary key columns input for table: commission.payout_log */
export type Commission_Payout_Log_Pk_Columns_Input = {
  quarter: Scalars['Int'];
  vendor_id: Scalars['String'];
  year: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Commission_Payout_Log_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "commission.payout_log" */
export enum Commission_Payout_Log_Select_Column {
  /** column name */
  AttachmentUrl = 'attachment_url',
  /** column name */
  BankTransferGeneratedAt = 'bank_transfer_generated_at',
  /** column name */
  BeReferenceId = 'be_reference_id',
  /** column name */
  BeRequestSuccessfulAt = 'be_request_successful_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  PaymentReference = 'payment_reference',
  /** column name */
  PayoutId = 'payout_id',
  /** column name */
  Quarter = 'quarter',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VendorId = 'vendor_id',
  /** column name */
  Year = 'year'
}

/** input type for updating data in table "commission.payout_log" */
export type Commission_Payout_Log_Set_Input = {
  attachment_url?: InputMaybe<Scalars['String']>;
  bank_transfer_generated_at?: InputMaybe<Scalars['timestamptz']>;
  be_reference_id?: InputMaybe<Scalars['String']>;
  be_request_successful_at?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  payment_reference?: InputMaybe<Scalars['String']>;
  payout_id?: InputMaybe<Scalars['Int']>;
  quarter?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vendor_id?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Commission_Payout_Log_Stddev_Fields = {
  __typename?: 'commission_payout_log_stddev_fields';
  payout_id?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Commission_Payout_Log_Stddev_Pop_Fields = {
  __typename?: 'commission_payout_log_stddev_pop_fields';
  payout_id?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Commission_Payout_Log_Stddev_Samp_Fields = {
  __typename?: 'commission_payout_log_stddev_samp_fields';
  payout_id?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "commission_payout_log" */
export type Commission_Payout_Log_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Commission_Payout_Log_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Commission_Payout_Log_Stream_Cursor_Value_Input = {
  attachment_url?: InputMaybe<Scalars['String']>;
  bank_transfer_generated_at?: InputMaybe<Scalars['timestamptz']>;
  be_reference_id?: InputMaybe<Scalars['String']>;
  be_request_successful_at?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  payment_reference?: InputMaybe<Scalars['String']>;
  payout_id?: InputMaybe<Scalars['Int']>;
  quarter?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vendor_id?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Commission_Payout_Log_Sum_Fields = {
  __typename?: 'commission_payout_log_sum_fields';
  payout_id?: Maybe<Scalars['Int']>;
  quarter?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

/** update columns of table "commission.payout_log" */
export enum Commission_Payout_Log_Update_Column {
  /** column name */
  AttachmentUrl = 'attachment_url',
  /** column name */
  BankTransferGeneratedAt = 'bank_transfer_generated_at',
  /** column name */
  BeReferenceId = 'be_reference_id',
  /** column name */
  BeRequestSuccessfulAt = 'be_request_successful_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  PaymentReference = 'payment_reference',
  /** column name */
  PayoutId = 'payout_id',
  /** column name */
  Quarter = 'quarter',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VendorId = 'vendor_id',
  /** column name */
  Year = 'year'
}

export type Commission_Payout_Log_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Commission_Payout_Log_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Commission_Payout_Log_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Commission_Payout_Log_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Commission_Payout_Log_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Commission_Payout_Log_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Commission_Payout_Log_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Commission_Payout_Log_Set_Input>;
  /** filter the rows which have to be updated */
  where: Commission_Payout_Log_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Commission_Payout_Log_Var_Pop_Fields = {
  __typename?: 'commission_payout_log_var_pop_fields';
  payout_id?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Commission_Payout_Log_Var_Samp_Fields = {
  __typename?: 'commission_payout_log_var_samp_fields';
  payout_id?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Commission_Payout_Log_Variance_Fields = {
  __typename?: 'commission_payout_log_variance_fields';
  payout_id?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** In this table the different types of events stored in the account table are specified. */
export type Commission_Type = {
  __typename?: 'commission_type';
  value: Scalars['String'];
};

/** aggregated selection of "commission.type" */
export type Commission_Type_Aggregate = {
  __typename?: 'commission_type_aggregate';
  aggregate?: Maybe<Commission_Type_Aggregate_Fields>;
  nodes: Array<Commission_Type>;
};

/** aggregate fields of "commission.type" */
export type Commission_Type_Aggregate_Fields = {
  __typename?: 'commission_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Commission_Type_Max_Fields>;
  min?: Maybe<Commission_Type_Min_Fields>;
};


/** aggregate fields of "commission.type" */
export type Commission_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Commission_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "commission.type". All fields are combined with a logical 'AND'. */
export type Commission_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Commission_Type_Bool_Exp>>;
  _not?: InputMaybe<Commission_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Commission_Type_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "commission.type" */
export enum Commission_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  TypePkey = 'type_pkey'
}

export enum Commission_Type_Enum {
  AnswerCommission = 'answer_commission',
  AnswerCommissionTransfer = 'answer_commission_transfer',
  BenefitPointsConversion = 'benefit_points_conversion',
  OnlineCommission = 'online_commission',
  OnlineCommissionTransfer = 'online_commission_transfer',
  Payout = 'payout',
  Transfer = 'transfer'
}

/** Boolean expression to compare columns of type "commission_type_enum". All fields are combined with logical 'AND'. */
export type Commission_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Commission_Type_Enum>;
  _in?: InputMaybe<Array<Commission_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Commission_Type_Enum>;
  _nin?: InputMaybe<Array<Commission_Type_Enum>>;
};

/** input type for inserting data into table "commission.type" */
export type Commission_Type_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Commission_Type_Max_Fields = {
  __typename?: 'commission_type_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Commission_Type_Min_Fields = {
  __typename?: 'commission_type_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "commission.type" */
export type Commission_Type_Mutation_Response = {
  __typename?: 'commission_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Commission_Type>;
};

/** on_conflict condition type for table "commission.type" */
export type Commission_Type_On_Conflict = {
  constraint: Commission_Type_Constraint;
  update_columns?: Array<Commission_Type_Update_Column>;
  where?: InputMaybe<Commission_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "commission.type". */
export type Commission_Type_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: commission.type */
export type Commission_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "commission.type" */
export enum Commission_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "commission.type" */
export type Commission_Type_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "commission_type" */
export type Commission_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Commission_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Commission_Type_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "commission.type" */
export enum Commission_Type_Update_Column {
  /** column name */
  Value = 'value'
}

export type Commission_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Commission_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Commission_Type_Bool_Exp;
};

/** columns and relationships of "commission.user_overview" */
export type Commission_User_Overview = {
  __typename?: 'commission_user_overview';
  amount?: Maybe<Scalars['float8']>;
  quarter?: Maybe<Scalars['float8']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_name?: Maybe<Scalars['String']>;
  vendor_id?: Maybe<Scalars['String']>;
  vendor_name?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['float8']>;
};

/** aggregated selection of "commission.user_overview" */
export type Commission_User_Overview_Aggregate = {
  __typename?: 'commission_user_overview_aggregate';
  aggregate?: Maybe<Commission_User_Overview_Aggregate_Fields>;
  nodes: Array<Commission_User_Overview>;
};

/** aggregate fields of "commission.user_overview" */
export type Commission_User_Overview_Aggregate_Fields = {
  __typename?: 'commission_user_overview_aggregate_fields';
  avg?: Maybe<Commission_User_Overview_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Commission_User_Overview_Max_Fields>;
  min?: Maybe<Commission_User_Overview_Min_Fields>;
  stddev?: Maybe<Commission_User_Overview_Stddev_Fields>;
  stddev_pop?: Maybe<Commission_User_Overview_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Commission_User_Overview_Stddev_Samp_Fields>;
  sum?: Maybe<Commission_User_Overview_Sum_Fields>;
  var_pop?: Maybe<Commission_User_Overview_Var_Pop_Fields>;
  var_samp?: Maybe<Commission_User_Overview_Var_Samp_Fields>;
  variance?: Maybe<Commission_User_Overview_Variance_Fields>;
};


/** aggregate fields of "commission.user_overview" */
export type Commission_User_Overview_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Commission_User_Overview_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Commission_User_Overview_Avg_Fields = {
  __typename?: 'commission_user_overview_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "commission.user_overview". All fields are combined with a logical 'AND'. */
export type Commission_User_Overview_Bool_Exp = {
  _and?: InputMaybe<Array<Commission_User_Overview_Bool_Exp>>;
  _not?: InputMaybe<Commission_User_Overview_Bool_Exp>;
  _or?: InputMaybe<Array<Commission_User_Overview_Bool_Exp>>;
  amount?: InputMaybe<Float8_Comparison_Exp>;
  quarter?: InputMaybe<Float8_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_name?: InputMaybe<String_Comparison_Exp>;
  vendor_id?: InputMaybe<String_Comparison_Exp>;
  vendor_name?: InputMaybe<String_Comparison_Exp>;
  year?: InputMaybe<Float8_Comparison_Exp>;
};

/** aggregate max on columns */
export type Commission_User_Overview_Max_Fields = {
  __typename?: 'commission_user_overview_max_fields';
  amount?: Maybe<Scalars['float8']>;
  quarter?: Maybe<Scalars['float8']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_name?: Maybe<Scalars['String']>;
  vendor_id?: Maybe<Scalars['String']>;
  vendor_name?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['float8']>;
};

/** aggregate min on columns */
export type Commission_User_Overview_Min_Fields = {
  __typename?: 'commission_user_overview_min_fields';
  amount?: Maybe<Scalars['float8']>;
  quarter?: Maybe<Scalars['float8']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_name?: Maybe<Scalars['String']>;
  vendor_id?: Maybe<Scalars['String']>;
  vendor_name?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['float8']>;
};

/** Ordering options when selecting data from "commission.user_overview". */
export type Commission_User_Overview_Order_By = {
  amount?: InputMaybe<Order_By>;
  quarter?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_name?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
  vendor_name?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** select columns of table "commission.user_overview" */
export enum Commission_User_Overview_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Quarter = 'quarter',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserName = 'user_name',
  /** column name */
  VendorId = 'vendor_id',
  /** column name */
  VendorName = 'vendor_name',
  /** column name */
  Year = 'year'
}

/** aggregate stddev on columns */
export type Commission_User_Overview_Stddev_Fields = {
  __typename?: 'commission_user_overview_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Commission_User_Overview_Stddev_Pop_Fields = {
  __typename?: 'commission_user_overview_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Commission_User_Overview_Stddev_Samp_Fields = {
  __typename?: 'commission_user_overview_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "commission_user_overview" */
export type Commission_User_Overview_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Commission_User_Overview_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Commission_User_Overview_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  quarter?: InputMaybe<Scalars['float8']>;
  type?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_name?: InputMaybe<Scalars['String']>;
  vendor_id?: InputMaybe<Scalars['String']>;
  vendor_name?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['float8']>;
};

/** aggregate sum on columns */
export type Commission_User_Overview_Sum_Fields = {
  __typename?: 'commission_user_overview_sum_fields';
  amount?: Maybe<Scalars['float8']>;
  quarter?: Maybe<Scalars['float8']>;
  year?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type Commission_User_Overview_Var_Pop_Fields = {
  __typename?: 'commission_user_overview_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Commission_User_Overview_Var_Samp_Fields = {
  __typename?: 'commission_user_overview_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Commission_User_Overview_Variance_Fields = {
  __typename?: 'commission_user_overview_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "commission.vendor_overview" */
export type Commission_Vendor_Overview = {
  __typename?: 'commission_vendor_overview';
  amount?: Maybe<Scalars['float8']>;
  quarter?: Maybe<Scalars['float8']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_name?: Maybe<Scalars['String']>;
  vendor_id?: Maybe<Scalars['String']>;
  vendor_name?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['float8']>;
};

/** aggregated selection of "commission.vendor_overview" */
export type Commission_Vendor_Overview_Aggregate = {
  __typename?: 'commission_vendor_overview_aggregate';
  aggregate?: Maybe<Commission_Vendor_Overview_Aggregate_Fields>;
  nodes: Array<Commission_Vendor_Overview>;
};

/** aggregate fields of "commission.vendor_overview" */
export type Commission_Vendor_Overview_Aggregate_Fields = {
  __typename?: 'commission_vendor_overview_aggregate_fields';
  avg?: Maybe<Commission_Vendor_Overview_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Commission_Vendor_Overview_Max_Fields>;
  min?: Maybe<Commission_Vendor_Overview_Min_Fields>;
  stddev?: Maybe<Commission_Vendor_Overview_Stddev_Fields>;
  stddev_pop?: Maybe<Commission_Vendor_Overview_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Commission_Vendor_Overview_Stddev_Samp_Fields>;
  sum?: Maybe<Commission_Vendor_Overview_Sum_Fields>;
  var_pop?: Maybe<Commission_Vendor_Overview_Var_Pop_Fields>;
  var_samp?: Maybe<Commission_Vendor_Overview_Var_Samp_Fields>;
  variance?: Maybe<Commission_Vendor_Overview_Variance_Fields>;
};


/** aggregate fields of "commission.vendor_overview" */
export type Commission_Vendor_Overview_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Commission_Vendor_Overview_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Commission_Vendor_Overview_Avg_Fields = {
  __typename?: 'commission_vendor_overview_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "commission.vendor_overview". All fields are combined with a logical 'AND'. */
export type Commission_Vendor_Overview_Bool_Exp = {
  _and?: InputMaybe<Array<Commission_Vendor_Overview_Bool_Exp>>;
  _not?: InputMaybe<Commission_Vendor_Overview_Bool_Exp>;
  _or?: InputMaybe<Array<Commission_Vendor_Overview_Bool_Exp>>;
  amount?: InputMaybe<Float8_Comparison_Exp>;
  quarter?: InputMaybe<Float8_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_name?: InputMaybe<String_Comparison_Exp>;
  vendor_id?: InputMaybe<String_Comparison_Exp>;
  vendor_name?: InputMaybe<String_Comparison_Exp>;
  year?: InputMaybe<Float8_Comparison_Exp>;
};

/** aggregate max on columns */
export type Commission_Vendor_Overview_Max_Fields = {
  __typename?: 'commission_vendor_overview_max_fields';
  amount?: Maybe<Scalars['float8']>;
  quarter?: Maybe<Scalars['float8']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_name?: Maybe<Scalars['String']>;
  vendor_id?: Maybe<Scalars['String']>;
  vendor_name?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['float8']>;
};

/** aggregate min on columns */
export type Commission_Vendor_Overview_Min_Fields = {
  __typename?: 'commission_vendor_overview_min_fields';
  amount?: Maybe<Scalars['float8']>;
  quarter?: Maybe<Scalars['float8']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_name?: Maybe<Scalars['String']>;
  vendor_id?: Maybe<Scalars['String']>;
  vendor_name?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['float8']>;
};

/** Ordering options when selecting data from "commission.vendor_overview". */
export type Commission_Vendor_Overview_Order_By = {
  amount?: InputMaybe<Order_By>;
  quarter?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_name?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
  vendor_name?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** select columns of table "commission.vendor_overview" */
export enum Commission_Vendor_Overview_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Quarter = 'quarter',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserName = 'user_name',
  /** column name */
  VendorId = 'vendor_id',
  /** column name */
  VendorName = 'vendor_name',
  /** column name */
  Year = 'year'
}

/** aggregate stddev on columns */
export type Commission_Vendor_Overview_Stddev_Fields = {
  __typename?: 'commission_vendor_overview_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Commission_Vendor_Overview_Stddev_Pop_Fields = {
  __typename?: 'commission_vendor_overview_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Commission_Vendor_Overview_Stddev_Samp_Fields = {
  __typename?: 'commission_vendor_overview_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "commission_vendor_overview" */
export type Commission_Vendor_Overview_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Commission_Vendor_Overview_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Commission_Vendor_Overview_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  quarter?: InputMaybe<Scalars['float8']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_name?: InputMaybe<Scalars['String']>;
  vendor_id?: InputMaybe<Scalars['String']>;
  vendor_name?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['float8']>;
};

/** aggregate sum on columns */
export type Commission_Vendor_Overview_Sum_Fields = {
  __typename?: 'commission_vendor_overview_sum_fields';
  amount?: Maybe<Scalars['float8']>;
  quarter?: Maybe<Scalars['float8']>;
  year?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type Commission_Vendor_Overview_Var_Pop_Fields = {
  __typename?: 'commission_vendor_overview_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Commission_Vendor_Overview_Var_Samp_Fields = {
  __typename?: 'commission_vendor_overview_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Commission_Vendor_Overview_Variance_Fields = {
  __typename?: 'commission_vendor_overview_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** In this table the orders from the webshop are stored to calculate the commission for online purchases */
export type Commission_Webshop_Data = {
  __typename?: 'commission_webshop_data';
  created_at: Scalars['timestamptz'];
  customer_id: Scalars['Int'];
  email: Scalars['String'];
  order_id: Scalars['Int'];
  order_value: Scalars['float8'];
  ordered_at: Scalars['timestamptz'];
  ox_order_id: Scalars['Int'];
  supervisor: Scalars['String'];
};

/** aggregated selection of "commission.webshop_data" */
export type Commission_Webshop_Data_Aggregate = {
  __typename?: 'commission_webshop_data_aggregate';
  aggregate?: Maybe<Commission_Webshop_Data_Aggregate_Fields>;
  nodes: Array<Commission_Webshop_Data>;
};

/** aggregate fields of "commission.webshop_data" */
export type Commission_Webshop_Data_Aggregate_Fields = {
  __typename?: 'commission_webshop_data_aggregate_fields';
  avg?: Maybe<Commission_Webshop_Data_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Commission_Webshop_Data_Max_Fields>;
  min?: Maybe<Commission_Webshop_Data_Min_Fields>;
  stddev?: Maybe<Commission_Webshop_Data_Stddev_Fields>;
  stddev_pop?: Maybe<Commission_Webshop_Data_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Commission_Webshop_Data_Stddev_Samp_Fields>;
  sum?: Maybe<Commission_Webshop_Data_Sum_Fields>;
  var_pop?: Maybe<Commission_Webshop_Data_Var_Pop_Fields>;
  var_samp?: Maybe<Commission_Webshop_Data_Var_Samp_Fields>;
  variance?: Maybe<Commission_Webshop_Data_Variance_Fields>;
};


/** aggregate fields of "commission.webshop_data" */
export type Commission_Webshop_Data_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Commission_Webshop_Data_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Commission_Webshop_Data_Avg_Fields = {
  __typename?: 'commission_webshop_data_avg_fields';
  customer_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  order_value?: Maybe<Scalars['Float']>;
  ox_order_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "commission.webshop_data". All fields are combined with a logical 'AND'. */
export type Commission_Webshop_Data_Bool_Exp = {
  _and?: InputMaybe<Array<Commission_Webshop_Data_Bool_Exp>>;
  _not?: InputMaybe<Commission_Webshop_Data_Bool_Exp>;
  _or?: InputMaybe<Array<Commission_Webshop_Data_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  customer_id?: InputMaybe<Int_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  order_id?: InputMaybe<Int_Comparison_Exp>;
  order_value?: InputMaybe<Float8_Comparison_Exp>;
  ordered_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  ox_order_id?: InputMaybe<Int_Comparison_Exp>;
  supervisor?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "commission.webshop_data" */
export enum Commission_Webshop_Data_Constraint {
  /** unique or primary key constraint on columns "ox_order_id" */
  WebshopDataPkey = 'webshop_data_pkey'
}

/** input type for incrementing numeric columns in table "commission.webshop_data" */
export type Commission_Webshop_Data_Inc_Input = {
  customer_id?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['Int']>;
  order_value?: InputMaybe<Scalars['float8']>;
  ox_order_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "commission.webshop_data" */
export type Commission_Webshop_Data_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_id?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['String']>;
  order_id?: InputMaybe<Scalars['Int']>;
  order_value?: InputMaybe<Scalars['float8']>;
  ordered_at?: InputMaybe<Scalars['timestamptz']>;
  ox_order_id?: InputMaybe<Scalars['Int']>;
  supervisor?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Commission_Webshop_Data_Max_Fields = {
  __typename?: 'commission_webshop_data_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  order_id?: Maybe<Scalars['Int']>;
  order_value?: Maybe<Scalars['float8']>;
  ordered_at?: Maybe<Scalars['timestamptz']>;
  ox_order_id?: Maybe<Scalars['Int']>;
  supervisor?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Commission_Webshop_Data_Min_Fields = {
  __typename?: 'commission_webshop_data_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  order_id?: Maybe<Scalars['Int']>;
  order_value?: Maybe<Scalars['float8']>;
  ordered_at?: Maybe<Scalars['timestamptz']>;
  ox_order_id?: Maybe<Scalars['Int']>;
  supervisor?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "commission.webshop_data" */
export type Commission_Webshop_Data_Mutation_Response = {
  __typename?: 'commission_webshop_data_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Commission_Webshop_Data>;
};

/** on_conflict condition type for table "commission.webshop_data" */
export type Commission_Webshop_Data_On_Conflict = {
  constraint: Commission_Webshop_Data_Constraint;
  update_columns?: Array<Commission_Webshop_Data_Update_Column>;
  where?: InputMaybe<Commission_Webshop_Data_Bool_Exp>;
};

/** Ordering options when selecting data from "commission.webshop_data". */
export type Commission_Webshop_Data_Order_By = {
  created_at?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  order_value?: InputMaybe<Order_By>;
  ordered_at?: InputMaybe<Order_By>;
  ox_order_id?: InputMaybe<Order_By>;
  supervisor?: InputMaybe<Order_By>;
};

/** primary key columns input for table: commission.webshop_data */
export type Commission_Webshop_Data_Pk_Columns_Input = {
  ox_order_id: Scalars['Int'];
};

/** select columns of table "commission.webshop_data" */
export enum Commission_Webshop_Data_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Email = 'email',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  OrderValue = 'order_value',
  /** column name */
  OrderedAt = 'ordered_at',
  /** column name */
  OxOrderId = 'ox_order_id',
  /** column name */
  Supervisor = 'supervisor'
}

/** input type for updating data in table "commission.webshop_data" */
export type Commission_Webshop_Data_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_id?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['String']>;
  order_id?: InputMaybe<Scalars['Int']>;
  order_value?: InputMaybe<Scalars['float8']>;
  ordered_at?: InputMaybe<Scalars['timestamptz']>;
  ox_order_id?: InputMaybe<Scalars['Int']>;
  supervisor?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Commission_Webshop_Data_Stddev_Fields = {
  __typename?: 'commission_webshop_data_stddev_fields';
  customer_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  order_value?: Maybe<Scalars['Float']>;
  ox_order_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Commission_Webshop_Data_Stddev_Pop_Fields = {
  __typename?: 'commission_webshop_data_stddev_pop_fields';
  customer_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  order_value?: Maybe<Scalars['Float']>;
  ox_order_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Commission_Webshop_Data_Stddev_Samp_Fields = {
  __typename?: 'commission_webshop_data_stddev_samp_fields';
  customer_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  order_value?: Maybe<Scalars['Float']>;
  ox_order_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "commission_webshop_data" */
export type Commission_Webshop_Data_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Commission_Webshop_Data_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Commission_Webshop_Data_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_id?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['String']>;
  order_id?: InputMaybe<Scalars['Int']>;
  order_value?: InputMaybe<Scalars['float8']>;
  ordered_at?: InputMaybe<Scalars['timestamptz']>;
  ox_order_id?: InputMaybe<Scalars['Int']>;
  supervisor?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Commission_Webshop_Data_Sum_Fields = {
  __typename?: 'commission_webshop_data_sum_fields';
  customer_id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  order_value?: Maybe<Scalars['float8']>;
  ox_order_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "commission.webshop_data" */
export enum Commission_Webshop_Data_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Email = 'email',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  OrderValue = 'order_value',
  /** column name */
  OrderedAt = 'ordered_at',
  /** column name */
  OxOrderId = 'ox_order_id',
  /** column name */
  Supervisor = 'supervisor'
}

export type Commission_Webshop_Data_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Commission_Webshop_Data_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Commission_Webshop_Data_Set_Input>;
  /** filter the rows which have to be updated */
  where: Commission_Webshop_Data_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Commission_Webshop_Data_Var_Pop_Fields = {
  __typename?: 'commission_webshop_data_var_pop_fields';
  customer_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  order_value?: Maybe<Scalars['Float']>;
  ox_order_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Commission_Webshop_Data_Var_Samp_Fields = {
  __typename?: 'commission_webshop_data_var_samp_fields';
  customer_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  order_value?: Maybe<Scalars['Float']>;
  ox_order_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Commission_Webshop_Data_Variance_Fields = {
  __typename?: 'commission_webshop_data_variance_fields';
  customer_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  order_value?: Maybe<Scalars['Float']>;
  ox_order_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "content.chapters" */
export type Content_Chapters = {
  __typename?: 'content_chapters';
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  description_i18n: Scalars['jsonb'];
  id: Scalars['Int'];
  index: Scalars['Int'];
  /** An object relationship */
  lection: Content_Lections;
  lection_id: Scalars['Int'];
  /** An object relationship */
  progress?: Maybe<Progress_Completed_Chapters>;
  /** An object relationship */
  quiz: Content_Quizzes;
  quiz_id: Scalars['Int'];
  required_minutes: Scalars['Int'];
  sanity_path: Scalars['String'];
  text_i18n: Scalars['jsonb'];
  text_raw: Scalars['jsonb'];
  title: Scalars['String'];
  title_i18n: Scalars['jsonb'];
  updated_at: Scalars['timestamptz'];
  video_i18n: Scalars['jsonb'];
  video_id?: Maybe<Scalars['String']>;
};


/** columns and relationships of "content.chapters" */
export type Content_ChaptersDescription_I18nArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "content.chapters" */
export type Content_ChaptersText_I18nArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "content.chapters" */
export type Content_ChaptersText_RawArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "content.chapters" */
export type Content_ChaptersTitle_I18nArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "content.chapters" */
export type Content_ChaptersVideo_I18nArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "content.chapters" */
export type Content_Chapters_Aggregate = {
  __typename?: 'content_chapters_aggregate';
  aggregate?: Maybe<Content_Chapters_Aggregate_Fields>;
  nodes: Array<Content_Chapters>;
};

export type Content_Chapters_Aggregate_Bool_Exp = {
  count?: InputMaybe<Content_Chapters_Aggregate_Bool_Exp_Count>;
};

export type Content_Chapters_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Content_Chapters_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Content_Chapters_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "content.chapters" */
export type Content_Chapters_Aggregate_Fields = {
  __typename?: 'content_chapters_aggregate_fields';
  avg?: Maybe<Content_Chapters_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Content_Chapters_Max_Fields>;
  min?: Maybe<Content_Chapters_Min_Fields>;
  stddev?: Maybe<Content_Chapters_Stddev_Fields>;
  stddev_pop?: Maybe<Content_Chapters_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Content_Chapters_Stddev_Samp_Fields>;
  sum?: Maybe<Content_Chapters_Sum_Fields>;
  var_pop?: Maybe<Content_Chapters_Var_Pop_Fields>;
  var_samp?: Maybe<Content_Chapters_Var_Samp_Fields>;
  variance?: Maybe<Content_Chapters_Variance_Fields>;
};


/** aggregate fields of "content.chapters" */
export type Content_Chapters_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Content_Chapters_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "content.chapters" */
export type Content_Chapters_Aggregate_Order_By = {
  avg?: InputMaybe<Content_Chapters_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Content_Chapters_Max_Order_By>;
  min?: InputMaybe<Content_Chapters_Min_Order_By>;
  stddev?: InputMaybe<Content_Chapters_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Content_Chapters_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Content_Chapters_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Content_Chapters_Sum_Order_By>;
  var_pop?: InputMaybe<Content_Chapters_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Content_Chapters_Var_Samp_Order_By>;
  variance?: InputMaybe<Content_Chapters_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Content_Chapters_Append_Input = {
  description_i18n?: InputMaybe<Scalars['jsonb']>;
  text_i18n?: InputMaybe<Scalars['jsonb']>;
  text_raw?: InputMaybe<Scalars['jsonb']>;
  title_i18n?: InputMaybe<Scalars['jsonb']>;
  video_i18n?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "content.chapters" */
export type Content_Chapters_Arr_Rel_Insert_Input = {
  data: Array<Content_Chapters_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Content_Chapters_On_Conflict>;
};

/** aggregate avg on columns */
export type Content_Chapters_Avg_Fields = {
  __typename?: 'content_chapters_avg_fields';
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  lection_id?: Maybe<Scalars['Float']>;
  quiz_id?: Maybe<Scalars['Float']>;
  required_minutes?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "content.chapters" */
export type Content_Chapters_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  lection_id?: InputMaybe<Order_By>;
  quiz_id?: InputMaybe<Order_By>;
  required_minutes?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "content.chapters". All fields are combined with a logical 'AND'. */
export type Content_Chapters_Bool_Exp = {
  _and?: InputMaybe<Array<Content_Chapters_Bool_Exp>>;
  _not?: InputMaybe<Content_Chapters_Bool_Exp>;
  _or?: InputMaybe<Array<Content_Chapters_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  description_i18n?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  index?: InputMaybe<Int_Comparison_Exp>;
  lection?: InputMaybe<Content_Lections_Bool_Exp>;
  lection_id?: InputMaybe<Int_Comparison_Exp>;
  progress?: InputMaybe<Progress_Completed_Chapters_Bool_Exp>;
  quiz?: InputMaybe<Content_Quizzes_Bool_Exp>;
  quiz_id?: InputMaybe<Int_Comparison_Exp>;
  required_minutes?: InputMaybe<Int_Comparison_Exp>;
  sanity_path?: InputMaybe<String_Comparison_Exp>;
  text_i18n?: InputMaybe<Jsonb_Comparison_Exp>;
  text_raw?: InputMaybe<Jsonb_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  title_i18n?: InputMaybe<Jsonb_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  video_i18n?: InputMaybe<Jsonb_Comparison_Exp>;
  video_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "content.chapters" */
export enum Content_Chapters_Constraint {
  /** unique or primary key constraint on columns "id" */
  ChaptersPkey = 'chapters_pkey',
  /** unique or primary key constraint on columns "sanity_path" */
  ChaptersSanityPathKey = 'chapters_sanity_path_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Content_Chapters_Delete_At_Path_Input = {
  description_i18n?: InputMaybe<Array<Scalars['String']>>;
  text_i18n?: InputMaybe<Array<Scalars['String']>>;
  text_raw?: InputMaybe<Array<Scalars['String']>>;
  title_i18n?: InputMaybe<Array<Scalars['String']>>;
  video_i18n?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Content_Chapters_Delete_Elem_Input = {
  description_i18n?: InputMaybe<Scalars['Int']>;
  text_i18n?: InputMaybe<Scalars['Int']>;
  text_raw?: InputMaybe<Scalars['Int']>;
  title_i18n?: InputMaybe<Scalars['Int']>;
  video_i18n?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Content_Chapters_Delete_Key_Input = {
  description_i18n?: InputMaybe<Scalars['String']>;
  text_i18n?: InputMaybe<Scalars['String']>;
  text_raw?: InputMaybe<Scalars['String']>;
  title_i18n?: InputMaybe<Scalars['String']>;
  video_i18n?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "content.chapters" */
export type Content_Chapters_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  index?: InputMaybe<Scalars['Int']>;
  lection_id?: InputMaybe<Scalars['Int']>;
  quiz_id?: InputMaybe<Scalars['Int']>;
  required_minutes?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "content.chapters" */
export type Content_Chapters_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  description_i18n?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  index?: InputMaybe<Scalars['Int']>;
  lection?: InputMaybe<Content_Lections_Obj_Rel_Insert_Input>;
  lection_id?: InputMaybe<Scalars['Int']>;
  progress?: InputMaybe<Progress_Completed_Chapters_Obj_Rel_Insert_Input>;
  quiz?: InputMaybe<Content_Quizzes_Obj_Rel_Insert_Input>;
  quiz_id?: InputMaybe<Scalars['Int']>;
  required_minutes?: InputMaybe<Scalars['Int']>;
  sanity_path?: InputMaybe<Scalars['String']>;
  text_i18n?: InputMaybe<Scalars['jsonb']>;
  text_raw?: InputMaybe<Scalars['jsonb']>;
  title?: InputMaybe<Scalars['String']>;
  title_i18n?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  video_i18n?: InputMaybe<Scalars['jsonb']>;
  video_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Content_Chapters_Max_Fields = {
  __typename?: 'content_chapters_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  index?: Maybe<Scalars['Int']>;
  lection_id?: Maybe<Scalars['Int']>;
  quiz_id?: Maybe<Scalars['Int']>;
  required_minutes?: Maybe<Scalars['Int']>;
  sanity_path?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  video_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "content.chapters" */
export type Content_Chapters_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  lection_id?: InputMaybe<Order_By>;
  quiz_id?: InputMaybe<Order_By>;
  required_minutes?: InputMaybe<Order_By>;
  sanity_path?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  video_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Content_Chapters_Min_Fields = {
  __typename?: 'content_chapters_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  index?: Maybe<Scalars['Int']>;
  lection_id?: Maybe<Scalars['Int']>;
  quiz_id?: Maybe<Scalars['Int']>;
  required_minutes?: Maybe<Scalars['Int']>;
  sanity_path?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  video_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "content.chapters" */
export type Content_Chapters_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  lection_id?: InputMaybe<Order_By>;
  quiz_id?: InputMaybe<Order_By>;
  required_minutes?: InputMaybe<Order_By>;
  sanity_path?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  video_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "content.chapters" */
export type Content_Chapters_Mutation_Response = {
  __typename?: 'content_chapters_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Content_Chapters>;
};

/** input type for inserting object relation for remote table "content.chapters" */
export type Content_Chapters_Obj_Rel_Insert_Input = {
  data: Content_Chapters_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Content_Chapters_On_Conflict>;
};

/** on_conflict condition type for table "content.chapters" */
export type Content_Chapters_On_Conflict = {
  constraint: Content_Chapters_Constraint;
  update_columns?: Array<Content_Chapters_Update_Column>;
  where?: InputMaybe<Content_Chapters_Bool_Exp>;
};

/** Ordering options when selecting data from "content.chapters". */
export type Content_Chapters_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  description_i18n?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  lection?: InputMaybe<Content_Lections_Order_By>;
  lection_id?: InputMaybe<Order_By>;
  progress?: InputMaybe<Progress_Completed_Chapters_Order_By>;
  quiz?: InputMaybe<Content_Quizzes_Order_By>;
  quiz_id?: InputMaybe<Order_By>;
  required_minutes?: InputMaybe<Order_By>;
  sanity_path?: InputMaybe<Order_By>;
  text_i18n?: InputMaybe<Order_By>;
  text_raw?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  title_i18n?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  video_i18n?: InputMaybe<Order_By>;
  video_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: content.chapters */
export type Content_Chapters_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Content_Chapters_Prepend_Input = {
  description_i18n?: InputMaybe<Scalars['jsonb']>;
  text_i18n?: InputMaybe<Scalars['jsonb']>;
  text_raw?: InputMaybe<Scalars['jsonb']>;
  title_i18n?: InputMaybe<Scalars['jsonb']>;
  video_i18n?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "content.chapters" */
export enum Content_Chapters_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DescriptionI18n = 'description_i18n',
  /** column name */
  Id = 'id',
  /** column name */
  Index = 'index',
  /** column name */
  LectionId = 'lection_id',
  /** column name */
  QuizId = 'quiz_id',
  /** column name */
  RequiredMinutes = 'required_minutes',
  /** column name */
  SanityPath = 'sanity_path',
  /** column name */
  TextI18n = 'text_i18n',
  /** column name */
  TextRaw = 'text_raw',
  /** column name */
  Title = 'title',
  /** column name */
  TitleI18n = 'title_i18n',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VideoI18n = 'video_i18n',
  /** column name */
  VideoId = 'video_id'
}

/** input type for updating data in table "content.chapters" */
export type Content_Chapters_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  description_i18n?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  index?: InputMaybe<Scalars['Int']>;
  lection_id?: InputMaybe<Scalars['Int']>;
  quiz_id?: InputMaybe<Scalars['Int']>;
  required_minutes?: InputMaybe<Scalars['Int']>;
  sanity_path?: InputMaybe<Scalars['String']>;
  text_i18n?: InputMaybe<Scalars['jsonb']>;
  text_raw?: InputMaybe<Scalars['jsonb']>;
  title?: InputMaybe<Scalars['String']>;
  title_i18n?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  video_i18n?: InputMaybe<Scalars['jsonb']>;
  video_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Content_Chapters_Stddev_Fields = {
  __typename?: 'content_chapters_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  lection_id?: Maybe<Scalars['Float']>;
  quiz_id?: Maybe<Scalars['Float']>;
  required_minutes?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "content.chapters" */
export type Content_Chapters_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  lection_id?: InputMaybe<Order_By>;
  quiz_id?: InputMaybe<Order_By>;
  required_minutes?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Content_Chapters_Stddev_Pop_Fields = {
  __typename?: 'content_chapters_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  lection_id?: Maybe<Scalars['Float']>;
  quiz_id?: Maybe<Scalars['Float']>;
  required_minutes?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "content.chapters" */
export type Content_Chapters_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  lection_id?: InputMaybe<Order_By>;
  quiz_id?: InputMaybe<Order_By>;
  required_minutes?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Content_Chapters_Stddev_Samp_Fields = {
  __typename?: 'content_chapters_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  lection_id?: Maybe<Scalars['Float']>;
  quiz_id?: Maybe<Scalars['Float']>;
  required_minutes?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "content.chapters" */
export type Content_Chapters_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  lection_id?: InputMaybe<Order_By>;
  quiz_id?: InputMaybe<Order_By>;
  required_minutes?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "content_chapters" */
export type Content_Chapters_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Content_Chapters_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Content_Chapters_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  description_i18n?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  index?: InputMaybe<Scalars['Int']>;
  lection_id?: InputMaybe<Scalars['Int']>;
  quiz_id?: InputMaybe<Scalars['Int']>;
  required_minutes?: InputMaybe<Scalars['Int']>;
  sanity_path?: InputMaybe<Scalars['String']>;
  text_i18n?: InputMaybe<Scalars['jsonb']>;
  text_raw?: InputMaybe<Scalars['jsonb']>;
  title?: InputMaybe<Scalars['String']>;
  title_i18n?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  video_i18n?: InputMaybe<Scalars['jsonb']>;
  video_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Content_Chapters_Sum_Fields = {
  __typename?: 'content_chapters_sum_fields';
  id?: Maybe<Scalars['Int']>;
  index?: Maybe<Scalars['Int']>;
  lection_id?: Maybe<Scalars['Int']>;
  quiz_id?: Maybe<Scalars['Int']>;
  required_minutes?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "content.chapters" */
export type Content_Chapters_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  lection_id?: InputMaybe<Order_By>;
  quiz_id?: InputMaybe<Order_By>;
  required_minutes?: InputMaybe<Order_By>;
};

/** update columns of table "content.chapters" */
export enum Content_Chapters_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DescriptionI18n = 'description_i18n',
  /** column name */
  Id = 'id',
  /** column name */
  Index = 'index',
  /** column name */
  LectionId = 'lection_id',
  /** column name */
  QuizId = 'quiz_id',
  /** column name */
  RequiredMinutes = 'required_minutes',
  /** column name */
  SanityPath = 'sanity_path',
  /** column name */
  TextI18n = 'text_i18n',
  /** column name */
  TextRaw = 'text_raw',
  /** column name */
  Title = 'title',
  /** column name */
  TitleI18n = 'title_i18n',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VideoI18n = 'video_i18n',
  /** column name */
  VideoId = 'video_id'
}

export type Content_Chapters_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Content_Chapters_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Content_Chapters_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Content_Chapters_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Content_Chapters_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Content_Chapters_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Content_Chapters_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Content_Chapters_Set_Input>;
  /** filter the rows which have to be updated */
  where: Content_Chapters_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Content_Chapters_Var_Pop_Fields = {
  __typename?: 'content_chapters_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  lection_id?: Maybe<Scalars['Float']>;
  quiz_id?: Maybe<Scalars['Float']>;
  required_minutes?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "content.chapters" */
export type Content_Chapters_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  lection_id?: InputMaybe<Order_By>;
  quiz_id?: InputMaybe<Order_By>;
  required_minutes?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Content_Chapters_Var_Samp_Fields = {
  __typename?: 'content_chapters_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  lection_id?: Maybe<Scalars['Float']>;
  quiz_id?: Maybe<Scalars['Float']>;
  required_minutes?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "content.chapters" */
export type Content_Chapters_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  lection_id?: InputMaybe<Order_By>;
  quiz_id?: InputMaybe<Order_By>;
  required_minutes?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Content_Chapters_Variance_Fields = {
  __typename?: 'content_chapters_variance_fields';
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  lection_id?: Maybe<Scalars['Float']>;
  quiz_id?: Maybe<Scalars['Float']>;
  required_minutes?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "content.chapters" */
export type Content_Chapters_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  lection_id?: InputMaybe<Order_By>;
  quiz_id?: InputMaybe<Order_By>;
  required_minutes?: InputMaybe<Order_By>;
};

/** columns and relationships of "content.choices" */
export type Content_Choices = {
  __typename?: 'content_choices';
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  is_correct: Scalars['Boolean'];
  /** An object relationship */
  question: Content_Questions;
  question_id: Scalars['Int'];
  sanity_path: Scalars['String'];
  title: Scalars['String'];
  title_i18n: Scalars['jsonb'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "content.choices" */
export type Content_ChoicesTitle_I18nArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "content.choices" */
export type Content_Choices_Aggregate = {
  __typename?: 'content_choices_aggregate';
  aggregate?: Maybe<Content_Choices_Aggregate_Fields>;
  nodes: Array<Content_Choices>;
};

export type Content_Choices_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Content_Choices_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Content_Choices_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Content_Choices_Aggregate_Bool_Exp_Count>;
};

export type Content_Choices_Aggregate_Bool_Exp_Bool_And = {
  arguments: Content_Choices_Select_Column_Content_Choices_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Content_Choices_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Content_Choices_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Content_Choices_Select_Column_Content_Choices_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Content_Choices_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Content_Choices_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Content_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Content_Choices_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "content.choices" */
export type Content_Choices_Aggregate_Fields = {
  __typename?: 'content_choices_aggregate_fields';
  avg?: Maybe<Content_Choices_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Content_Choices_Max_Fields>;
  min?: Maybe<Content_Choices_Min_Fields>;
  stddev?: Maybe<Content_Choices_Stddev_Fields>;
  stddev_pop?: Maybe<Content_Choices_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Content_Choices_Stddev_Samp_Fields>;
  sum?: Maybe<Content_Choices_Sum_Fields>;
  var_pop?: Maybe<Content_Choices_Var_Pop_Fields>;
  var_samp?: Maybe<Content_Choices_Var_Samp_Fields>;
  variance?: Maybe<Content_Choices_Variance_Fields>;
};


/** aggregate fields of "content.choices" */
export type Content_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Content_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "content.choices" */
export type Content_Choices_Aggregate_Order_By = {
  avg?: InputMaybe<Content_Choices_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Content_Choices_Max_Order_By>;
  min?: InputMaybe<Content_Choices_Min_Order_By>;
  stddev?: InputMaybe<Content_Choices_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Content_Choices_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Content_Choices_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Content_Choices_Sum_Order_By>;
  var_pop?: InputMaybe<Content_Choices_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Content_Choices_Var_Samp_Order_By>;
  variance?: InputMaybe<Content_Choices_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Content_Choices_Append_Input = {
  title_i18n?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "content.choices" */
export type Content_Choices_Arr_Rel_Insert_Input = {
  data: Array<Content_Choices_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Content_Choices_On_Conflict>;
};

/** aggregate avg on columns */
export type Content_Choices_Avg_Fields = {
  __typename?: 'content_choices_avg_fields';
  id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "content.choices" */
export type Content_Choices_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "content.choices". All fields are combined with a logical 'AND'. */
export type Content_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Content_Choices_Bool_Exp>>;
  _not?: InputMaybe<Content_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Content_Choices_Bool_Exp>>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_correct?: InputMaybe<Boolean_Comparison_Exp>;
  question?: InputMaybe<Content_Questions_Bool_Exp>;
  question_id?: InputMaybe<Int_Comparison_Exp>;
  sanity_path?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  title_i18n?: InputMaybe<Jsonb_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "content.choices" */
export enum Content_Choices_Constraint {
  /** unique or primary key constraint on columns "id" */
  ChoicesPkey = 'choices_pkey',
  /** unique or primary key constraint on columns "sanity_path" */
  ChoicesSanityPathKey = 'choices_sanity_path_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Content_Choices_Delete_At_Path_Input = {
  title_i18n?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Content_Choices_Delete_Elem_Input = {
  title_i18n?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Content_Choices_Delete_Key_Input = {
  title_i18n?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "content.choices" */
export type Content_Choices_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  question_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "content.choices" */
export type Content_Choices_Insert_Input = {
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  is_correct?: InputMaybe<Scalars['Boolean']>;
  question?: InputMaybe<Content_Questions_Obj_Rel_Insert_Input>;
  question_id?: InputMaybe<Scalars['Int']>;
  sanity_path?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  title_i18n?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Content_Choices_Max_Fields = {
  __typename?: 'content_choices_max_fields';
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  question_id?: Maybe<Scalars['Int']>;
  sanity_path?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "content.choices" */
export type Content_Choices_Max_Order_By = {
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
  sanity_path?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Content_Choices_Min_Fields = {
  __typename?: 'content_choices_min_fields';
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  question_id?: Maybe<Scalars['Int']>;
  sanity_path?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "content.choices" */
export type Content_Choices_Min_Order_By = {
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
  sanity_path?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "content.choices" */
export type Content_Choices_Mutation_Response = {
  __typename?: 'content_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Content_Choices>;
};

/** input type for inserting object relation for remote table "content.choices" */
export type Content_Choices_Obj_Rel_Insert_Input = {
  data: Content_Choices_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Content_Choices_On_Conflict>;
};

/** on_conflict condition type for table "content.choices" */
export type Content_Choices_On_Conflict = {
  constraint: Content_Choices_Constraint;
  update_columns?: Array<Content_Choices_Update_Column>;
  where?: InputMaybe<Content_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "content.choices". */
export type Content_Choices_Order_By = {
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_correct?: InputMaybe<Order_By>;
  question?: InputMaybe<Content_Questions_Order_By>;
  question_id?: InputMaybe<Order_By>;
  sanity_path?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  title_i18n?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: content.choices */
export type Content_Choices_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Content_Choices_Prepend_Input = {
  title_i18n?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "content.choices" */
export enum Content_Choices_Select_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsCorrect = 'is_correct',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  SanityPath = 'sanity_path',
  /** column name */
  Title = 'title',
  /** column name */
  TitleI18n = 'title_i18n',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "content_choices_aggregate_bool_exp_bool_and_arguments_columns" columns of table "content.choices" */
export enum Content_Choices_Select_Column_Content_Choices_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsCorrect = 'is_correct'
}

/** select "content_choices_aggregate_bool_exp_bool_or_arguments_columns" columns of table "content.choices" */
export enum Content_Choices_Select_Column_Content_Choices_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsCorrect = 'is_correct'
}

/** input type for updating data in table "content.choices" */
export type Content_Choices_Set_Input = {
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  is_correct?: InputMaybe<Scalars['Boolean']>;
  question_id?: InputMaybe<Scalars['Int']>;
  sanity_path?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  title_i18n?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Content_Choices_Stddev_Fields = {
  __typename?: 'content_choices_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "content.choices" */
export type Content_Choices_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Content_Choices_Stddev_Pop_Fields = {
  __typename?: 'content_choices_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "content.choices" */
export type Content_Choices_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Content_Choices_Stddev_Samp_Fields = {
  __typename?: 'content_choices_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "content.choices" */
export type Content_Choices_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "content_choices" */
export type Content_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Content_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Content_Choices_Stream_Cursor_Value_Input = {
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  is_correct?: InputMaybe<Scalars['Boolean']>;
  question_id?: InputMaybe<Scalars['Int']>;
  sanity_path?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  title_i18n?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Content_Choices_Sum_Fields = {
  __typename?: 'content_choices_sum_fields';
  id?: Maybe<Scalars['Int']>;
  question_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "content.choices" */
export type Content_Choices_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
};

/** update columns of table "content.choices" */
export enum Content_Choices_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsCorrect = 'is_correct',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  SanityPath = 'sanity_path',
  /** column name */
  Title = 'title',
  /** column name */
  TitleI18n = 'title_i18n',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Content_Choices_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Content_Choices_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Content_Choices_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Content_Choices_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Content_Choices_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Content_Choices_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Content_Choices_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Content_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Content_Choices_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Content_Choices_Var_Pop_Fields = {
  __typename?: 'content_choices_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "content.choices" */
export type Content_Choices_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Content_Choices_Var_Samp_Fields = {
  __typename?: 'content_choices_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "content.choices" */
export type Content_Choices_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Content_Choices_Variance_Fields = {
  __typename?: 'content_choices_variance_fields';
  id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "content.choices" */
export type Content_Choices_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "content.contactpoints" */
export type Content_Contactpoints = {
  __typename?: 'content_contactpoints';
  description: Scalars['String'];
  description_i18n: Scalars['jsonb'];
  final_quiz_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  img_url: Scalars['String'];
  /** An array relationship */
  lections: Array<Content_Lections>;
  /** An aggregate relationship */
  lections_aggregate: Content_Lections_Aggregate;
  /** An object relationship */
  progress?: Maybe<Progress_Completed_Contactpoints>;
  /** An object relationship */
  quiz?: Maybe<Content_Quizzes>;
  sanity_id: Scalars['String'];
  skill_name?: Maybe<Scalars['String']>;
  skill_name_i18n?: Maybe<Scalars['jsonb']>;
  title: Scalars['String'];
  title_i18n: Scalars['jsonb'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "content.contactpoints" */
export type Content_ContactpointsDescription_I18nArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "content.contactpoints" */
export type Content_ContactpointsLectionsArgs = {
  distinct_on?: InputMaybe<Array<Content_Lections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Lections_Order_By>>;
  where?: InputMaybe<Content_Lections_Bool_Exp>;
};


/** columns and relationships of "content.contactpoints" */
export type Content_ContactpointsLections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Content_Lections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Lections_Order_By>>;
  where?: InputMaybe<Content_Lections_Bool_Exp>;
};


/** columns and relationships of "content.contactpoints" */
export type Content_ContactpointsSkill_Name_I18nArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "content.contactpoints" */
export type Content_ContactpointsTitle_I18nArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "content.contactpoints" */
export type Content_Contactpoints_Aggregate = {
  __typename?: 'content_contactpoints_aggregate';
  aggregate?: Maybe<Content_Contactpoints_Aggregate_Fields>;
  nodes: Array<Content_Contactpoints>;
};

/** aggregate fields of "content.contactpoints" */
export type Content_Contactpoints_Aggregate_Fields = {
  __typename?: 'content_contactpoints_aggregate_fields';
  avg?: Maybe<Content_Contactpoints_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Content_Contactpoints_Max_Fields>;
  min?: Maybe<Content_Contactpoints_Min_Fields>;
  stddev?: Maybe<Content_Contactpoints_Stddev_Fields>;
  stddev_pop?: Maybe<Content_Contactpoints_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Content_Contactpoints_Stddev_Samp_Fields>;
  sum?: Maybe<Content_Contactpoints_Sum_Fields>;
  var_pop?: Maybe<Content_Contactpoints_Var_Pop_Fields>;
  var_samp?: Maybe<Content_Contactpoints_Var_Samp_Fields>;
  variance?: Maybe<Content_Contactpoints_Variance_Fields>;
};


/** aggregate fields of "content.contactpoints" */
export type Content_Contactpoints_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Content_Contactpoints_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Content_Contactpoints_Append_Input = {
  description_i18n?: InputMaybe<Scalars['jsonb']>;
  skill_name_i18n?: InputMaybe<Scalars['jsonb']>;
  title_i18n?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Content_Contactpoints_Avg_Fields = {
  __typename?: 'content_contactpoints_avg_fields';
  final_quiz_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "content.contactpoints". All fields are combined with a logical 'AND'. */
export type Content_Contactpoints_Bool_Exp = {
  _and?: InputMaybe<Array<Content_Contactpoints_Bool_Exp>>;
  _not?: InputMaybe<Content_Contactpoints_Bool_Exp>;
  _or?: InputMaybe<Array<Content_Contactpoints_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  description_i18n?: InputMaybe<Jsonb_Comparison_Exp>;
  final_quiz_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  img_url?: InputMaybe<String_Comparison_Exp>;
  lections?: InputMaybe<Content_Lections_Bool_Exp>;
  lections_aggregate?: InputMaybe<Content_Lections_Aggregate_Bool_Exp>;
  progress?: InputMaybe<Progress_Completed_Contactpoints_Bool_Exp>;
  quiz?: InputMaybe<Content_Quizzes_Bool_Exp>;
  sanity_id?: InputMaybe<String_Comparison_Exp>;
  skill_name?: InputMaybe<String_Comparison_Exp>;
  skill_name_i18n?: InputMaybe<Jsonb_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  title_i18n?: InputMaybe<Jsonb_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "content.contactpoints" */
export enum Content_Contactpoints_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContactpointsPkey = 'contactpoints_pkey',
  /** unique or primary key constraint on columns "sanity_id" */
  ContactpointsSanityIdKey = 'contactpoints_sanity_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Content_Contactpoints_Delete_At_Path_Input = {
  description_i18n?: InputMaybe<Array<Scalars['String']>>;
  skill_name_i18n?: InputMaybe<Array<Scalars['String']>>;
  title_i18n?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Content_Contactpoints_Delete_Elem_Input = {
  description_i18n?: InputMaybe<Scalars['Int']>;
  skill_name_i18n?: InputMaybe<Scalars['Int']>;
  title_i18n?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Content_Contactpoints_Delete_Key_Input = {
  description_i18n?: InputMaybe<Scalars['String']>;
  skill_name_i18n?: InputMaybe<Scalars['String']>;
  title_i18n?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "content.contactpoints" */
export type Content_Contactpoints_Inc_Input = {
  final_quiz_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "content.contactpoints" */
export type Content_Contactpoints_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  description_i18n?: InputMaybe<Scalars['jsonb']>;
  final_quiz_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  img_url?: InputMaybe<Scalars['String']>;
  lections?: InputMaybe<Content_Lections_Arr_Rel_Insert_Input>;
  progress?: InputMaybe<Progress_Completed_Contactpoints_Obj_Rel_Insert_Input>;
  quiz?: InputMaybe<Content_Quizzes_Obj_Rel_Insert_Input>;
  sanity_id?: InputMaybe<Scalars['String']>;
  skill_name?: InputMaybe<Scalars['String']>;
  skill_name_i18n?: InputMaybe<Scalars['jsonb']>;
  title?: InputMaybe<Scalars['String']>;
  title_i18n?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Content_Contactpoints_Max_Fields = {
  __typename?: 'content_contactpoints_max_fields';
  description?: Maybe<Scalars['String']>;
  final_quiz_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  img_url?: Maybe<Scalars['String']>;
  sanity_id?: Maybe<Scalars['String']>;
  skill_name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Content_Contactpoints_Min_Fields = {
  __typename?: 'content_contactpoints_min_fields';
  description?: Maybe<Scalars['String']>;
  final_quiz_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  img_url?: Maybe<Scalars['String']>;
  sanity_id?: Maybe<Scalars['String']>;
  skill_name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "content.contactpoints" */
export type Content_Contactpoints_Mutation_Response = {
  __typename?: 'content_contactpoints_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Content_Contactpoints>;
};

/** input type for inserting object relation for remote table "content.contactpoints" */
export type Content_Contactpoints_Obj_Rel_Insert_Input = {
  data: Content_Contactpoints_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Content_Contactpoints_On_Conflict>;
};

/** on_conflict condition type for table "content.contactpoints" */
export type Content_Contactpoints_On_Conflict = {
  constraint: Content_Contactpoints_Constraint;
  update_columns?: Array<Content_Contactpoints_Update_Column>;
  where?: InputMaybe<Content_Contactpoints_Bool_Exp>;
};

/** Ordering options when selecting data from "content.contactpoints". */
export type Content_Contactpoints_Order_By = {
  description?: InputMaybe<Order_By>;
  description_i18n?: InputMaybe<Order_By>;
  final_quiz_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  img_url?: InputMaybe<Order_By>;
  lections_aggregate?: InputMaybe<Content_Lections_Aggregate_Order_By>;
  progress?: InputMaybe<Progress_Completed_Contactpoints_Order_By>;
  quiz?: InputMaybe<Content_Quizzes_Order_By>;
  sanity_id?: InputMaybe<Order_By>;
  skill_name?: InputMaybe<Order_By>;
  skill_name_i18n?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  title_i18n?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: content.contactpoints */
export type Content_Contactpoints_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Content_Contactpoints_Prepend_Input = {
  description_i18n?: InputMaybe<Scalars['jsonb']>;
  skill_name_i18n?: InputMaybe<Scalars['jsonb']>;
  title_i18n?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "content.contactpoints" */
export enum Content_Contactpoints_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  DescriptionI18n = 'description_i18n',
  /** column name */
  FinalQuizId = 'final_quiz_id',
  /** column name */
  Id = 'id',
  /** column name */
  ImgUrl = 'img_url',
  /** column name */
  SanityId = 'sanity_id',
  /** column name */
  SkillName = 'skill_name',
  /** column name */
  SkillNameI18n = 'skill_name_i18n',
  /** column name */
  Title = 'title',
  /** column name */
  TitleI18n = 'title_i18n',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "content.contactpoints" */
export type Content_Contactpoints_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  description_i18n?: InputMaybe<Scalars['jsonb']>;
  final_quiz_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  img_url?: InputMaybe<Scalars['String']>;
  sanity_id?: InputMaybe<Scalars['String']>;
  skill_name?: InputMaybe<Scalars['String']>;
  skill_name_i18n?: InputMaybe<Scalars['jsonb']>;
  title?: InputMaybe<Scalars['String']>;
  title_i18n?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Content_Contactpoints_Stddev_Fields = {
  __typename?: 'content_contactpoints_stddev_fields';
  final_quiz_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Content_Contactpoints_Stddev_Pop_Fields = {
  __typename?: 'content_contactpoints_stddev_pop_fields';
  final_quiz_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Content_Contactpoints_Stddev_Samp_Fields = {
  __typename?: 'content_contactpoints_stddev_samp_fields';
  final_quiz_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "content_contactpoints" */
export type Content_Contactpoints_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Content_Contactpoints_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Content_Contactpoints_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  description_i18n?: InputMaybe<Scalars['jsonb']>;
  final_quiz_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  img_url?: InputMaybe<Scalars['String']>;
  sanity_id?: InputMaybe<Scalars['String']>;
  skill_name?: InputMaybe<Scalars['String']>;
  skill_name_i18n?: InputMaybe<Scalars['jsonb']>;
  title?: InputMaybe<Scalars['String']>;
  title_i18n?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Content_Contactpoints_Sum_Fields = {
  __typename?: 'content_contactpoints_sum_fields';
  final_quiz_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "content.contactpoints" */
export enum Content_Contactpoints_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  DescriptionI18n = 'description_i18n',
  /** column name */
  FinalQuizId = 'final_quiz_id',
  /** column name */
  Id = 'id',
  /** column name */
  ImgUrl = 'img_url',
  /** column name */
  SanityId = 'sanity_id',
  /** column name */
  SkillName = 'skill_name',
  /** column name */
  SkillNameI18n = 'skill_name_i18n',
  /** column name */
  Title = 'title',
  /** column name */
  TitleI18n = 'title_i18n',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Content_Contactpoints_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Content_Contactpoints_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Content_Contactpoints_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Content_Contactpoints_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Content_Contactpoints_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Content_Contactpoints_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Content_Contactpoints_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Content_Contactpoints_Set_Input>;
  /** filter the rows which have to be updated */
  where: Content_Contactpoints_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Content_Contactpoints_Var_Pop_Fields = {
  __typename?: 'content_contactpoints_var_pop_fields';
  final_quiz_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Content_Contactpoints_Var_Samp_Fields = {
  __typename?: 'content_contactpoints_var_samp_fields';
  final_quiz_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Content_Contactpoints_Variance_Fields = {
  __typename?: 'content_contactpoints_variance_fields';
  final_quiz_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "content.lection_requirements" */
export type Content_Lection_Requirements = {
  __typename?: 'content_lection_requirements';
  /** An object relationship */
  lection: Content_Lections;
  lection_id: Scalars['Int'];
  /** An object relationship */
  required_lection: Content_Lections;
  required_lection_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "content.lection_requirements" */
export type Content_Lection_Requirements_Aggregate = {
  __typename?: 'content_lection_requirements_aggregate';
  aggregate?: Maybe<Content_Lection_Requirements_Aggregate_Fields>;
  nodes: Array<Content_Lection_Requirements>;
};

export type Content_Lection_Requirements_Aggregate_Bool_Exp = {
  count?: InputMaybe<Content_Lection_Requirements_Aggregate_Bool_Exp_Count>;
};

export type Content_Lection_Requirements_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Content_Lection_Requirements_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Content_Lection_Requirements_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "content.lection_requirements" */
export type Content_Lection_Requirements_Aggregate_Fields = {
  __typename?: 'content_lection_requirements_aggregate_fields';
  avg?: Maybe<Content_Lection_Requirements_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Content_Lection_Requirements_Max_Fields>;
  min?: Maybe<Content_Lection_Requirements_Min_Fields>;
  stddev?: Maybe<Content_Lection_Requirements_Stddev_Fields>;
  stddev_pop?: Maybe<Content_Lection_Requirements_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Content_Lection_Requirements_Stddev_Samp_Fields>;
  sum?: Maybe<Content_Lection_Requirements_Sum_Fields>;
  var_pop?: Maybe<Content_Lection_Requirements_Var_Pop_Fields>;
  var_samp?: Maybe<Content_Lection_Requirements_Var_Samp_Fields>;
  variance?: Maybe<Content_Lection_Requirements_Variance_Fields>;
};


/** aggregate fields of "content.lection_requirements" */
export type Content_Lection_Requirements_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Content_Lection_Requirements_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "content.lection_requirements" */
export type Content_Lection_Requirements_Aggregate_Order_By = {
  avg?: InputMaybe<Content_Lection_Requirements_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Content_Lection_Requirements_Max_Order_By>;
  min?: InputMaybe<Content_Lection_Requirements_Min_Order_By>;
  stddev?: InputMaybe<Content_Lection_Requirements_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Content_Lection_Requirements_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Content_Lection_Requirements_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Content_Lection_Requirements_Sum_Order_By>;
  var_pop?: InputMaybe<Content_Lection_Requirements_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Content_Lection_Requirements_Var_Samp_Order_By>;
  variance?: InputMaybe<Content_Lection_Requirements_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "content.lection_requirements" */
export type Content_Lection_Requirements_Arr_Rel_Insert_Input = {
  data: Array<Content_Lection_Requirements_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Content_Lection_Requirements_On_Conflict>;
};

/** aggregate avg on columns */
export type Content_Lection_Requirements_Avg_Fields = {
  __typename?: 'content_lection_requirements_avg_fields';
  lection_id?: Maybe<Scalars['Float']>;
  required_lection_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "content.lection_requirements" */
export type Content_Lection_Requirements_Avg_Order_By = {
  lection_id?: InputMaybe<Order_By>;
  required_lection_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "content.lection_requirements". All fields are combined with a logical 'AND'. */
export type Content_Lection_Requirements_Bool_Exp = {
  _and?: InputMaybe<Array<Content_Lection_Requirements_Bool_Exp>>;
  _not?: InputMaybe<Content_Lection_Requirements_Bool_Exp>;
  _or?: InputMaybe<Array<Content_Lection_Requirements_Bool_Exp>>;
  lection?: InputMaybe<Content_Lections_Bool_Exp>;
  lection_id?: InputMaybe<Int_Comparison_Exp>;
  required_lection?: InputMaybe<Content_Lections_Bool_Exp>;
  required_lection_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "content.lection_requirements" */
export enum Content_Lection_Requirements_Constraint {
  /** unique or primary key constraint on columns "required_lection_id", "lection_id" */
  LectionRequirementsPkey = 'lection_requirements_pkey'
}

/** columns and relationships of "content.lection_requirements_hierarchical_structure" */
export type Content_Lection_Requirements_Hierarchical_Structure = {
  __typename?: 'content_lection_requirements_hierarchical_structure';
  lection_id?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  required_lection_id?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "content.lection_requirements_hierarchical_structure" */
export type Content_Lection_Requirements_Hierarchical_Structure_Aggregate = {
  __typename?: 'content_lection_requirements_hierarchical_structure_aggregate';
  aggregate?: Maybe<Content_Lection_Requirements_Hierarchical_Structure_Aggregate_Fields>;
  nodes: Array<Content_Lection_Requirements_Hierarchical_Structure>;
};

/** aggregate fields of "content.lection_requirements_hierarchical_structure" */
export type Content_Lection_Requirements_Hierarchical_Structure_Aggregate_Fields = {
  __typename?: 'content_lection_requirements_hierarchical_structure_aggregate_fields';
  avg?: Maybe<Content_Lection_Requirements_Hierarchical_Structure_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Content_Lection_Requirements_Hierarchical_Structure_Max_Fields>;
  min?: Maybe<Content_Lection_Requirements_Hierarchical_Structure_Min_Fields>;
  stddev?: Maybe<Content_Lection_Requirements_Hierarchical_Structure_Stddev_Fields>;
  stddev_pop?: Maybe<Content_Lection_Requirements_Hierarchical_Structure_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Content_Lection_Requirements_Hierarchical_Structure_Stddev_Samp_Fields>;
  sum?: Maybe<Content_Lection_Requirements_Hierarchical_Structure_Sum_Fields>;
  var_pop?: Maybe<Content_Lection_Requirements_Hierarchical_Structure_Var_Pop_Fields>;
  var_samp?: Maybe<Content_Lection_Requirements_Hierarchical_Structure_Var_Samp_Fields>;
  variance?: Maybe<Content_Lection_Requirements_Hierarchical_Structure_Variance_Fields>;
};


/** aggregate fields of "content.lection_requirements_hierarchical_structure" */
export type Content_Lection_Requirements_Hierarchical_Structure_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Content_Lection_Requirements_Hierarchical_Structure_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Content_Lection_Requirements_Hierarchical_Structure_Avg_Fields = {
  __typename?: 'content_lection_requirements_hierarchical_structure_avg_fields';
  lection_id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  required_lection_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "content.lection_requirements_hierarchical_structure". All fields are combined with a logical 'AND'. */
export type Content_Lection_Requirements_Hierarchical_Structure_Bool_Exp = {
  _and?: InputMaybe<Array<Content_Lection_Requirements_Hierarchical_Structure_Bool_Exp>>;
  _not?: InputMaybe<Content_Lection_Requirements_Hierarchical_Structure_Bool_Exp>;
  _or?: InputMaybe<Array<Content_Lection_Requirements_Hierarchical_Structure_Bool_Exp>>;
  lection_id?: InputMaybe<Int_Comparison_Exp>;
  level?: InputMaybe<Int_Comparison_Exp>;
  required_lection_id?: InputMaybe<Int_Comparison_Exp>;
};

/** input type for inserting data into table "content.lection_requirements_hierarchical_structure" */
export type Content_Lection_Requirements_Hierarchical_Structure_Insert_Input = {
  lection_id?: InputMaybe<Scalars['Int']>;
  level?: InputMaybe<Scalars['Int']>;
  required_lection_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Content_Lection_Requirements_Hierarchical_Structure_Max_Fields = {
  __typename?: 'content_lection_requirements_hierarchical_structure_max_fields';
  lection_id?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  required_lection_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Content_Lection_Requirements_Hierarchical_Structure_Min_Fields = {
  __typename?: 'content_lection_requirements_hierarchical_structure_min_fields';
  lection_id?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  required_lection_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting object relation for remote table "content.lection_requirements_hierarchical_structure" */
export type Content_Lection_Requirements_Hierarchical_Structure_Obj_Rel_Insert_Input = {
  data: Content_Lection_Requirements_Hierarchical_Structure_Insert_Input;
};

/** Ordering options when selecting data from "content.lection_requirements_hierarchical_structure". */
export type Content_Lection_Requirements_Hierarchical_Structure_Order_By = {
  lection_id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  required_lection_id?: InputMaybe<Order_By>;
};

/** select columns of table "content.lection_requirements_hierarchical_structure" */
export enum Content_Lection_Requirements_Hierarchical_Structure_Select_Column {
  /** column name */
  LectionId = 'lection_id',
  /** column name */
  Level = 'level',
  /** column name */
  RequiredLectionId = 'required_lection_id'
}

/** aggregate stddev on columns */
export type Content_Lection_Requirements_Hierarchical_Structure_Stddev_Fields = {
  __typename?: 'content_lection_requirements_hierarchical_structure_stddev_fields';
  lection_id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  required_lection_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Content_Lection_Requirements_Hierarchical_Structure_Stddev_Pop_Fields = {
  __typename?: 'content_lection_requirements_hierarchical_structure_stddev_pop_fields';
  lection_id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  required_lection_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Content_Lection_Requirements_Hierarchical_Structure_Stddev_Samp_Fields = {
  __typename?: 'content_lection_requirements_hierarchical_structure_stddev_samp_fields';
  lection_id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  required_lection_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "content_lection_requirements_hierarchical_structure" */
export type Content_Lection_Requirements_Hierarchical_Structure_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Content_Lection_Requirements_Hierarchical_Structure_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Content_Lection_Requirements_Hierarchical_Structure_Stream_Cursor_Value_Input = {
  lection_id?: InputMaybe<Scalars['Int']>;
  level?: InputMaybe<Scalars['Int']>;
  required_lection_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Content_Lection_Requirements_Hierarchical_Structure_Sum_Fields = {
  __typename?: 'content_lection_requirements_hierarchical_structure_sum_fields';
  lection_id?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  required_lection_id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Content_Lection_Requirements_Hierarchical_Structure_Var_Pop_Fields = {
  __typename?: 'content_lection_requirements_hierarchical_structure_var_pop_fields';
  lection_id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  required_lection_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Content_Lection_Requirements_Hierarchical_Structure_Var_Samp_Fields = {
  __typename?: 'content_lection_requirements_hierarchical_structure_var_samp_fields';
  lection_id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  required_lection_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Content_Lection_Requirements_Hierarchical_Structure_Variance_Fields = {
  __typename?: 'content_lection_requirements_hierarchical_structure_variance_fields';
  lection_id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  required_lection_id?: Maybe<Scalars['Float']>;
};

/** input type for incrementing numeric columns in table "content.lection_requirements" */
export type Content_Lection_Requirements_Inc_Input = {
  lection_id?: InputMaybe<Scalars['Int']>;
  required_lection_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "content.lection_requirements" */
export type Content_Lection_Requirements_Insert_Input = {
  lection?: InputMaybe<Content_Lections_Obj_Rel_Insert_Input>;
  lection_id?: InputMaybe<Scalars['Int']>;
  required_lection?: InputMaybe<Content_Lections_Obj_Rel_Insert_Input>;
  required_lection_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Content_Lection_Requirements_Max_Fields = {
  __typename?: 'content_lection_requirements_max_fields';
  lection_id?: Maybe<Scalars['Int']>;
  required_lection_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "content.lection_requirements" */
export type Content_Lection_Requirements_Max_Order_By = {
  lection_id?: InputMaybe<Order_By>;
  required_lection_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Content_Lection_Requirements_Min_Fields = {
  __typename?: 'content_lection_requirements_min_fields';
  lection_id?: Maybe<Scalars['Int']>;
  required_lection_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "content.lection_requirements" */
export type Content_Lection_Requirements_Min_Order_By = {
  lection_id?: InputMaybe<Order_By>;
  required_lection_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "content.lection_requirements" */
export type Content_Lection_Requirements_Mutation_Response = {
  __typename?: 'content_lection_requirements_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Content_Lection_Requirements>;
};

/** on_conflict condition type for table "content.lection_requirements" */
export type Content_Lection_Requirements_On_Conflict = {
  constraint: Content_Lection_Requirements_Constraint;
  update_columns?: Array<Content_Lection_Requirements_Update_Column>;
  where?: InputMaybe<Content_Lection_Requirements_Bool_Exp>;
};

/** Ordering options when selecting data from "content.lection_requirements". */
export type Content_Lection_Requirements_Order_By = {
  lection?: InputMaybe<Content_Lections_Order_By>;
  lection_id?: InputMaybe<Order_By>;
  required_lection?: InputMaybe<Content_Lections_Order_By>;
  required_lection_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: content.lection_requirements */
export type Content_Lection_Requirements_Pk_Columns_Input = {
  lection_id: Scalars['Int'];
  required_lection_id: Scalars['Int'];
};

/** select columns of table "content.lection_requirements" */
export enum Content_Lection_Requirements_Select_Column {
  /** column name */
  LectionId = 'lection_id',
  /** column name */
  RequiredLectionId = 'required_lection_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "content.lection_requirements" */
export type Content_Lection_Requirements_Set_Input = {
  lection_id?: InputMaybe<Scalars['Int']>;
  required_lection_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Content_Lection_Requirements_Stddev_Fields = {
  __typename?: 'content_lection_requirements_stddev_fields';
  lection_id?: Maybe<Scalars['Float']>;
  required_lection_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "content.lection_requirements" */
export type Content_Lection_Requirements_Stddev_Order_By = {
  lection_id?: InputMaybe<Order_By>;
  required_lection_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Content_Lection_Requirements_Stddev_Pop_Fields = {
  __typename?: 'content_lection_requirements_stddev_pop_fields';
  lection_id?: Maybe<Scalars['Float']>;
  required_lection_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "content.lection_requirements" */
export type Content_Lection_Requirements_Stddev_Pop_Order_By = {
  lection_id?: InputMaybe<Order_By>;
  required_lection_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Content_Lection_Requirements_Stddev_Samp_Fields = {
  __typename?: 'content_lection_requirements_stddev_samp_fields';
  lection_id?: Maybe<Scalars['Float']>;
  required_lection_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "content.lection_requirements" */
export type Content_Lection_Requirements_Stddev_Samp_Order_By = {
  lection_id?: InputMaybe<Order_By>;
  required_lection_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "content_lection_requirements" */
export type Content_Lection_Requirements_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Content_Lection_Requirements_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Content_Lection_Requirements_Stream_Cursor_Value_Input = {
  lection_id?: InputMaybe<Scalars['Int']>;
  required_lection_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Content_Lection_Requirements_Sum_Fields = {
  __typename?: 'content_lection_requirements_sum_fields';
  lection_id?: Maybe<Scalars['Int']>;
  required_lection_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "content.lection_requirements" */
export type Content_Lection_Requirements_Sum_Order_By = {
  lection_id?: InputMaybe<Order_By>;
  required_lection_id?: InputMaybe<Order_By>;
};

/** update columns of table "content.lection_requirements" */
export enum Content_Lection_Requirements_Update_Column {
  /** column name */
  LectionId = 'lection_id',
  /** column name */
  RequiredLectionId = 'required_lection_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Content_Lection_Requirements_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Content_Lection_Requirements_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Content_Lection_Requirements_Set_Input>;
  /** filter the rows which have to be updated */
  where: Content_Lection_Requirements_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Content_Lection_Requirements_Var_Pop_Fields = {
  __typename?: 'content_lection_requirements_var_pop_fields';
  lection_id?: Maybe<Scalars['Float']>;
  required_lection_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "content.lection_requirements" */
export type Content_Lection_Requirements_Var_Pop_Order_By = {
  lection_id?: InputMaybe<Order_By>;
  required_lection_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Content_Lection_Requirements_Var_Samp_Fields = {
  __typename?: 'content_lection_requirements_var_samp_fields';
  lection_id?: Maybe<Scalars['Float']>;
  required_lection_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "content.lection_requirements" */
export type Content_Lection_Requirements_Var_Samp_Order_By = {
  lection_id?: InputMaybe<Order_By>;
  required_lection_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Content_Lection_Requirements_Variance_Fields = {
  __typename?: 'content_lection_requirements_variance_fields';
  lection_id?: Maybe<Scalars['Float']>;
  required_lection_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "content.lection_requirements" */
export type Content_Lection_Requirements_Variance_Order_By = {
  lection_id?: InputMaybe<Order_By>;
  required_lection_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "content.lections" */
export type Content_Lections = {
  __typename?: 'content_lections';
  /** An array relationship */
  chapters: Array<Content_Chapters>;
  /** An aggregate relationship */
  chapters_aggregate: Content_Chapters_Aggregate;
  contact_point_id: Scalars['Int'];
  /** An object relationship */
  contactpoint: Content_Contactpoints;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  hierarchical_level?: Maybe<Content_Lection_Requirements_Hierarchical_Structure>;
  id: Scalars['Int'];
  img_url: Scalars['String'];
  /** An object relationship */
  progress?: Maybe<Progress_Completed_Lections>;
  /** An array relationship */
  requirements: Array<Content_Lection_Requirements>;
  /** An aggregate relationship */
  requirements_aggregate: Content_Lection_Requirements_Aggregate;
  sanity_id: Scalars['String'];
  title: Scalars['String'];
  title_i18n: Scalars['jsonb'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "content.lections" */
export type Content_LectionsChaptersArgs = {
  distinct_on?: InputMaybe<Array<Content_Chapters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Chapters_Order_By>>;
  where?: InputMaybe<Content_Chapters_Bool_Exp>;
};


/** columns and relationships of "content.lections" */
export type Content_LectionsChapters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Content_Chapters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Chapters_Order_By>>;
  where?: InputMaybe<Content_Chapters_Bool_Exp>;
};


/** columns and relationships of "content.lections" */
export type Content_LectionsRequirementsArgs = {
  distinct_on?: InputMaybe<Array<Content_Lection_Requirements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Lection_Requirements_Order_By>>;
  where?: InputMaybe<Content_Lection_Requirements_Bool_Exp>;
};


/** columns and relationships of "content.lections" */
export type Content_LectionsRequirements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Content_Lection_Requirements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Lection_Requirements_Order_By>>;
  where?: InputMaybe<Content_Lection_Requirements_Bool_Exp>;
};


/** columns and relationships of "content.lections" */
export type Content_LectionsTitle_I18nArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "content.lections" */
export type Content_Lections_Aggregate = {
  __typename?: 'content_lections_aggregate';
  aggregate?: Maybe<Content_Lections_Aggregate_Fields>;
  nodes: Array<Content_Lections>;
};

export type Content_Lections_Aggregate_Bool_Exp = {
  count?: InputMaybe<Content_Lections_Aggregate_Bool_Exp_Count>;
};

export type Content_Lections_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Content_Lections_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Content_Lections_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "content.lections" */
export type Content_Lections_Aggregate_Fields = {
  __typename?: 'content_lections_aggregate_fields';
  avg?: Maybe<Content_Lections_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Content_Lections_Max_Fields>;
  min?: Maybe<Content_Lections_Min_Fields>;
  stddev?: Maybe<Content_Lections_Stddev_Fields>;
  stddev_pop?: Maybe<Content_Lections_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Content_Lections_Stddev_Samp_Fields>;
  sum?: Maybe<Content_Lections_Sum_Fields>;
  var_pop?: Maybe<Content_Lections_Var_Pop_Fields>;
  var_samp?: Maybe<Content_Lections_Var_Samp_Fields>;
  variance?: Maybe<Content_Lections_Variance_Fields>;
};


/** aggregate fields of "content.lections" */
export type Content_Lections_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Content_Lections_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "content.lections" */
export type Content_Lections_Aggregate_Order_By = {
  avg?: InputMaybe<Content_Lections_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Content_Lections_Max_Order_By>;
  min?: InputMaybe<Content_Lections_Min_Order_By>;
  stddev?: InputMaybe<Content_Lections_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Content_Lections_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Content_Lections_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Content_Lections_Sum_Order_By>;
  var_pop?: InputMaybe<Content_Lections_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Content_Lections_Var_Samp_Order_By>;
  variance?: InputMaybe<Content_Lections_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Content_Lections_Append_Input = {
  title_i18n?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "content.lections" */
export type Content_Lections_Arr_Rel_Insert_Input = {
  data: Array<Content_Lections_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Content_Lections_On_Conflict>;
};

/** aggregate avg on columns */
export type Content_Lections_Avg_Fields = {
  __typename?: 'content_lections_avg_fields';
  contact_point_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "content.lections" */
export type Content_Lections_Avg_Order_By = {
  contact_point_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "content.lections". All fields are combined with a logical 'AND'. */
export type Content_Lections_Bool_Exp = {
  _and?: InputMaybe<Array<Content_Lections_Bool_Exp>>;
  _not?: InputMaybe<Content_Lections_Bool_Exp>;
  _or?: InputMaybe<Array<Content_Lections_Bool_Exp>>;
  chapters?: InputMaybe<Content_Chapters_Bool_Exp>;
  chapters_aggregate?: InputMaybe<Content_Chapters_Aggregate_Bool_Exp>;
  contact_point_id?: InputMaybe<Int_Comparison_Exp>;
  contactpoint?: InputMaybe<Content_Contactpoints_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  hierarchical_level?: InputMaybe<Content_Lection_Requirements_Hierarchical_Structure_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  img_url?: InputMaybe<String_Comparison_Exp>;
  progress?: InputMaybe<Progress_Completed_Lections_Bool_Exp>;
  requirements?: InputMaybe<Content_Lection_Requirements_Bool_Exp>;
  requirements_aggregate?: InputMaybe<Content_Lection_Requirements_Aggregate_Bool_Exp>;
  sanity_id?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  title_i18n?: InputMaybe<Jsonb_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "content.lections" */
export enum Content_Lections_Constraint {
  /** unique or primary key constraint on columns "id" */
  LectionsPkey = 'lections_pkey',
  /** unique or primary key constraint on columns "sanity_id" */
  LectionsSanityIdKey = 'lections_sanity_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Content_Lections_Delete_At_Path_Input = {
  title_i18n?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Content_Lections_Delete_Elem_Input = {
  title_i18n?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Content_Lections_Delete_Key_Input = {
  title_i18n?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "content.lections" */
export type Content_Lections_Inc_Input = {
  contact_point_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "content.lections" */
export type Content_Lections_Insert_Input = {
  chapters?: InputMaybe<Content_Chapters_Arr_Rel_Insert_Input>;
  contact_point_id?: InputMaybe<Scalars['Int']>;
  contactpoint?: InputMaybe<Content_Contactpoints_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  hierarchical_level?: InputMaybe<Content_Lection_Requirements_Hierarchical_Structure_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  img_url?: InputMaybe<Scalars['String']>;
  progress?: InputMaybe<Progress_Completed_Lections_Obj_Rel_Insert_Input>;
  requirements?: InputMaybe<Content_Lection_Requirements_Arr_Rel_Insert_Input>;
  sanity_id?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  title_i18n?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Content_Lections_Max_Fields = {
  __typename?: 'content_lections_max_fields';
  contact_point_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  img_url?: Maybe<Scalars['String']>;
  sanity_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "content.lections" */
export type Content_Lections_Max_Order_By = {
  contact_point_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  img_url?: InputMaybe<Order_By>;
  sanity_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Content_Lections_Min_Fields = {
  __typename?: 'content_lections_min_fields';
  contact_point_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  img_url?: Maybe<Scalars['String']>;
  sanity_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "content.lections" */
export type Content_Lections_Min_Order_By = {
  contact_point_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  img_url?: InputMaybe<Order_By>;
  sanity_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "content.lections" */
export type Content_Lections_Mutation_Response = {
  __typename?: 'content_lections_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Content_Lections>;
};

/** input type for inserting object relation for remote table "content.lections" */
export type Content_Lections_Obj_Rel_Insert_Input = {
  data: Content_Lections_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Content_Lections_On_Conflict>;
};

/** on_conflict condition type for table "content.lections" */
export type Content_Lections_On_Conflict = {
  constraint: Content_Lections_Constraint;
  update_columns?: Array<Content_Lections_Update_Column>;
  where?: InputMaybe<Content_Lections_Bool_Exp>;
};

/** Ordering options when selecting data from "content.lections". */
export type Content_Lections_Order_By = {
  chapters_aggregate?: InputMaybe<Content_Chapters_Aggregate_Order_By>;
  contact_point_id?: InputMaybe<Order_By>;
  contactpoint?: InputMaybe<Content_Contactpoints_Order_By>;
  created_at?: InputMaybe<Order_By>;
  hierarchical_level?: InputMaybe<Content_Lection_Requirements_Hierarchical_Structure_Order_By>;
  id?: InputMaybe<Order_By>;
  img_url?: InputMaybe<Order_By>;
  progress?: InputMaybe<Progress_Completed_Lections_Order_By>;
  requirements_aggregate?: InputMaybe<Content_Lection_Requirements_Aggregate_Order_By>;
  sanity_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  title_i18n?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: content.lections */
export type Content_Lections_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Content_Lections_Prepend_Input = {
  title_i18n?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "content.lections" */
export enum Content_Lections_Select_Column {
  /** column name */
  ContactPointId = 'contact_point_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ImgUrl = 'img_url',
  /** column name */
  SanityId = 'sanity_id',
  /** column name */
  Title = 'title',
  /** column name */
  TitleI18n = 'title_i18n',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "content.lections" */
export type Content_Lections_Set_Input = {
  contact_point_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  img_url?: InputMaybe<Scalars['String']>;
  sanity_id?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  title_i18n?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Content_Lections_Stddev_Fields = {
  __typename?: 'content_lections_stddev_fields';
  contact_point_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "content.lections" */
export type Content_Lections_Stddev_Order_By = {
  contact_point_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Content_Lections_Stddev_Pop_Fields = {
  __typename?: 'content_lections_stddev_pop_fields';
  contact_point_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "content.lections" */
export type Content_Lections_Stddev_Pop_Order_By = {
  contact_point_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Content_Lections_Stddev_Samp_Fields = {
  __typename?: 'content_lections_stddev_samp_fields';
  contact_point_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "content.lections" */
export type Content_Lections_Stddev_Samp_Order_By = {
  contact_point_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "content_lections" */
export type Content_Lections_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Content_Lections_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Content_Lections_Stream_Cursor_Value_Input = {
  contact_point_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  img_url?: InputMaybe<Scalars['String']>;
  sanity_id?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  title_i18n?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Content_Lections_Sum_Fields = {
  __typename?: 'content_lections_sum_fields';
  contact_point_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "content.lections" */
export type Content_Lections_Sum_Order_By = {
  contact_point_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "content.lections" */
export enum Content_Lections_Update_Column {
  /** column name */
  ContactPointId = 'contact_point_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ImgUrl = 'img_url',
  /** column name */
  SanityId = 'sanity_id',
  /** column name */
  Title = 'title',
  /** column name */
  TitleI18n = 'title_i18n',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Content_Lections_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Content_Lections_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Content_Lections_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Content_Lections_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Content_Lections_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Content_Lections_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Content_Lections_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Content_Lections_Set_Input>;
  /** filter the rows which have to be updated */
  where: Content_Lections_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Content_Lections_Var_Pop_Fields = {
  __typename?: 'content_lections_var_pop_fields';
  contact_point_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "content.lections" */
export type Content_Lections_Var_Pop_Order_By = {
  contact_point_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Content_Lections_Var_Samp_Fields = {
  __typename?: 'content_lections_var_samp_fields';
  contact_point_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "content.lections" */
export type Content_Lections_Var_Samp_Order_By = {
  contact_point_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Content_Lections_Variance_Fields = {
  __typename?: 'content_lections_variance_fields';
  contact_point_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "content.lections" */
export type Content_Lections_Variance_Order_By = {
  contact_point_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "content.levels" */
export type Content_Levels = {
  __typename?: 'content_levels';
  description: Scalars['String'];
  description_i18n: Scalars['jsonb'];
  id: Scalars['Int'];
  required_points: Scalars['Int'];
  sanity_id: Scalars['String'];
  title: Scalars['String'];
  title_i18n: Scalars['jsonb'];
  /** An object relationship */
  type?: Maybe<Iqlab_Rewards_Types>;
  updated_at: Scalars['timestamptz'];
  voucher_type?: Maybe<Scalars['String']>;
};


/** columns and relationships of "content.levels" */
export type Content_LevelsDescription_I18nArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "content.levels" */
export type Content_LevelsTitle_I18nArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "content.levels" */
export type Content_Levels_Aggregate = {
  __typename?: 'content_levels_aggregate';
  aggregate?: Maybe<Content_Levels_Aggregate_Fields>;
  nodes: Array<Content_Levels>;
};

/** aggregate fields of "content.levels" */
export type Content_Levels_Aggregate_Fields = {
  __typename?: 'content_levels_aggregate_fields';
  avg?: Maybe<Content_Levels_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Content_Levels_Max_Fields>;
  min?: Maybe<Content_Levels_Min_Fields>;
  stddev?: Maybe<Content_Levels_Stddev_Fields>;
  stddev_pop?: Maybe<Content_Levels_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Content_Levels_Stddev_Samp_Fields>;
  sum?: Maybe<Content_Levels_Sum_Fields>;
  var_pop?: Maybe<Content_Levels_Var_Pop_Fields>;
  var_samp?: Maybe<Content_Levels_Var_Samp_Fields>;
  variance?: Maybe<Content_Levels_Variance_Fields>;
};


/** aggregate fields of "content.levels" */
export type Content_Levels_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Content_Levels_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Content_Levels_Append_Input = {
  description_i18n?: InputMaybe<Scalars['jsonb']>;
  title_i18n?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Content_Levels_Avg_Fields = {
  __typename?: 'content_levels_avg_fields';
  id?: Maybe<Scalars['Float']>;
  required_points?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "content.levels". All fields are combined with a logical 'AND'. */
export type Content_Levels_Bool_Exp = {
  _and?: InputMaybe<Array<Content_Levels_Bool_Exp>>;
  _not?: InputMaybe<Content_Levels_Bool_Exp>;
  _or?: InputMaybe<Array<Content_Levels_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  description_i18n?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  required_points?: InputMaybe<Int_Comparison_Exp>;
  sanity_id?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  title_i18n?: InputMaybe<Jsonb_Comparison_Exp>;
  type?: InputMaybe<Iqlab_Rewards_Types_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  voucher_type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "content.levels" */
export enum Content_Levels_Constraint {
  /** unique or primary key constraint on columns "id" */
  LevelsPkey = 'levels_pkey',
  /** unique or primary key constraint on columns "required_points" */
  LevelsRequiredPointsKey = 'levels_required_points_key',
  /** unique or primary key constraint on columns "sanity_id" */
  LevelsSanityIdKey = 'levels_sanity_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Content_Levels_Delete_At_Path_Input = {
  description_i18n?: InputMaybe<Array<Scalars['String']>>;
  title_i18n?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Content_Levels_Delete_Elem_Input = {
  description_i18n?: InputMaybe<Scalars['Int']>;
  title_i18n?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Content_Levels_Delete_Key_Input = {
  description_i18n?: InputMaybe<Scalars['String']>;
  title_i18n?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "content.levels" */
export type Content_Levels_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  required_points?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "content.levels" */
export type Content_Levels_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  description_i18n?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  required_points?: InputMaybe<Scalars['Int']>;
  sanity_id?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  title_i18n?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Iqlab_Rewards_Types_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  voucher_type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Content_Levels_Max_Fields = {
  __typename?: 'content_levels_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  required_points?: Maybe<Scalars['Int']>;
  sanity_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  voucher_type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Content_Levels_Min_Fields = {
  __typename?: 'content_levels_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  required_points?: Maybe<Scalars['Int']>;
  sanity_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  voucher_type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "content.levels" */
export type Content_Levels_Mutation_Response = {
  __typename?: 'content_levels_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Content_Levels>;
};

/** input type for inserting object relation for remote table "content.levels" */
export type Content_Levels_Obj_Rel_Insert_Input = {
  data: Content_Levels_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Content_Levels_On_Conflict>;
};

/** on_conflict condition type for table "content.levels" */
export type Content_Levels_On_Conflict = {
  constraint: Content_Levels_Constraint;
  update_columns?: Array<Content_Levels_Update_Column>;
  where?: InputMaybe<Content_Levels_Bool_Exp>;
};

/** Ordering options when selecting data from "content.levels". */
export type Content_Levels_Order_By = {
  description?: InputMaybe<Order_By>;
  description_i18n?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  required_points?: InputMaybe<Order_By>;
  sanity_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  title_i18n?: InputMaybe<Order_By>;
  type?: InputMaybe<Iqlab_Rewards_Types_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  voucher_type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: content.levels */
export type Content_Levels_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Content_Levels_Prepend_Input = {
  description_i18n?: InputMaybe<Scalars['jsonb']>;
  title_i18n?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "content.levels" */
export enum Content_Levels_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  DescriptionI18n = 'description_i18n',
  /** column name */
  Id = 'id',
  /** column name */
  RequiredPoints = 'required_points',
  /** column name */
  SanityId = 'sanity_id',
  /** column name */
  Title = 'title',
  /** column name */
  TitleI18n = 'title_i18n',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VoucherType = 'voucher_type'
}

/** input type for updating data in table "content.levels" */
export type Content_Levels_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  description_i18n?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  required_points?: InputMaybe<Scalars['Int']>;
  sanity_id?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  title_i18n?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  voucher_type?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Content_Levels_Stddev_Fields = {
  __typename?: 'content_levels_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  required_points?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Content_Levels_Stddev_Pop_Fields = {
  __typename?: 'content_levels_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  required_points?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Content_Levels_Stddev_Samp_Fields = {
  __typename?: 'content_levels_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  required_points?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "content_levels" */
export type Content_Levels_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Content_Levels_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Content_Levels_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  description_i18n?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  required_points?: InputMaybe<Scalars['Int']>;
  sanity_id?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  title_i18n?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  voucher_type?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Content_Levels_Sum_Fields = {
  __typename?: 'content_levels_sum_fields';
  id?: Maybe<Scalars['Int']>;
  required_points?: Maybe<Scalars['Int']>;
};

/** update columns of table "content.levels" */
export enum Content_Levels_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  DescriptionI18n = 'description_i18n',
  /** column name */
  Id = 'id',
  /** column name */
  RequiredPoints = 'required_points',
  /** column name */
  SanityId = 'sanity_id',
  /** column name */
  Title = 'title',
  /** column name */
  TitleI18n = 'title_i18n',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VoucherType = 'voucher_type'
}

export type Content_Levels_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Content_Levels_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Content_Levels_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Content_Levels_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Content_Levels_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Content_Levels_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Content_Levels_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Content_Levels_Set_Input>;
  /** filter the rows which have to be updated */
  where: Content_Levels_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Content_Levels_Var_Pop_Fields = {
  __typename?: 'content_levels_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  required_points?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Content_Levels_Var_Samp_Fields = {
  __typename?: 'content_levels_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  required_points?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Content_Levels_Variance_Fields = {
  __typename?: 'content_levels_variance_fields';
  id?: Maybe<Scalars['Float']>;
  required_points?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "content.questions" */
export type Content_Questions = {
  __typename?: 'content_questions';
  /** An array relationship */
  choices: Array<Content_Choices>;
  /** An aggregate relationship */
  choices_aggregate: Content_Choices_Aggregate;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  hint_i18n: Scalars['jsonb'];
  id: Scalars['Int'];
  img_url?: Maybe<Scalars['String']>;
  /** An object relationship */
  quiz: Content_Quizzes;
  quiz_id: Scalars['Int'];
  sanity_path: Scalars['String'];
  title: Scalars['String'];
  title_i18n: Scalars['jsonb'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "content.questions" */
export type Content_QuestionsChoicesArgs = {
  distinct_on?: InputMaybe<Array<Content_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Choices_Order_By>>;
  where?: InputMaybe<Content_Choices_Bool_Exp>;
};


/** columns and relationships of "content.questions" */
export type Content_QuestionsChoices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Content_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Choices_Order_By>>;
  where?: InputMaybe<Content_Choices_Bool_Exp>;
};


/** columns and relationships of "content.questions" */
export type Content_QuestionsHint_I18nArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "content.questions" */
export type Content_QuestionsTitle_I18nArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "content.questions" */
export type Content_Questions_Aggregate = {
  __typename?: 'content_questions_aggregate';
  aggregate?: Maybe<Content_Questions_Aggregate_Fields>;
  nodes: Array<Content_Questions>;
};

export type Content_Questions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Content_Questions_Aggregate_Bool_Exp_Count>;
};

export type Content_Questions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Content_Questions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Content_Questions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "content.questions" */
export type Content_Questions_Aggregate_Fields = {
  __typename?: 'content_questions_aggregate_fields';
  avg?: Maybe<Content_Questions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Content_Questions_Max_Fields>;
  min?: Maybe<Content_Questions_Min_Fields>;
  stddev?: Maybe<Content_Questions_Stddev_Fields>;
  stddev_pop?: Maybe<Content_Questions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Content_Questions_Stddev_Samp_Fields>;
  sum?: Maybe<Content_Questions_Sum_Fields>;
  var_pop?: Maybe<Content_Questions_Var_Pop_Fields>;
  var_samp?: Maybe<Content_Questions_Var_Samp_Fields>;
  variance?: Maybe<Content_Questions_Variance_Fields>;
};


/** aggregate fields of "content.questions" */
export type Content_Questions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Content_Questions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "content.questions" */
export type Content_Questions_Aggregate_Order_By = {
  avg?: InputMaybe<Content_Questions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Content_Questions_Max_Order_By>;
  min?: InputMaybe<Content_Questions_Min_Order_By>;
  stddev?: InputMaybe<Content_Questions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Content_Questions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Content_Questions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Content_Questions_Sum_Order_By>;
  var_pop?: InputMaybe<Content_Questions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Content_Questions_Var_Samp_Order_By>;
  variance?: InputMaybe<Content_Questions_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Content_Questions_Append_Input = {
  hint_i18n?: InputMaybe<Scalars['jsonb']>;
  title_i18n?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "content.questions" */
export type Content_Questions_Arr_Rel_Insert_Input = {
  data: Array<Content_Questions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Content_Questions_On_Conflict>;
};

/** aggregate avg on columns */
export type Content_Questions_Avg_Fields = {
  __typename?: 'content_questions_avg_fields';
  id?: Maybe<Scalars['Float']>;
  quiz_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "content.questions" */
export type Content_Questions_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  quiz_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "content.questions". All fields are combined with a logical 'AND'. */
export type Content_Questions_Bool_Exp = {
  _and?: InputMaybe<Array<Content_Questions_Bool_Exp>>;
  _not?: InputMaybe<Content_Questions_Bool_Exp>;
  _or?: InputMaybe<Array<Content_Questions_Bool_Exp>>;
  choices?: InputMaybe<Content_Choices_Bool_Exp>;
  choices_aggregate?: InputMaybe<Content_Choices_Aggregate_Bool_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  hint_i18n?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  img_url?: InputMaybe<String_Comparison_Exp>;
  quiz?: InputMaybe<Content_Quizzes_Bool_Exp>;
  quiz_id?: InputMaybe<Int_Comparison_Exp>;
  sanity_path?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  title_i18n?: InputMaybe<Jsonb_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "content.questions" */
export enum Content_Questions_Constraint {
  /** unique or primary key constraint on columns "id" */
  QuestionsPkey = 'questions_pkey',
  /** unique or primary key constraint on columns "sanity_path" */
  QuestionsSanityPathKey = 'questions_sanity_path_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Content_Questions_Delete_At_Path_Input = {
  hint_i18n?: InputMaybe<Array<Scalars['String']>>;
  title_i18n?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Content_Questions_Delete_Elem_Input = {
  hint_i18n?: InputMaybe<Scalars['Int']>;
  title_i18n?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Content_Questions_Delete_Key_Input = {
  hint_i18n?: InputMaybe<Scalars['String']>;
  title_i18n?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "content.questions" */
export type Content_Questions_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  quiz_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "content.questions" */
export type Content_Questions_Insert_Input = {
  choices?: InputMaybe<Content_Choices_Arr_Rel_Insert_Input>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  hint_i18n?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  img_url?: InputMaybe<Scalars['String']>;
  quiz?: InputMaybe<Content_Quizzes_Obj_Rel_Insert_Input>;
  quiz_id?: InputMaybe<Scalars['Int']>;
  sanity_path?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  title_i18n?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Content_Questions_Max_Fields = {
  __typename?: 'content_questions_max_fields';
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  img_url?: Maybe<Scalars['String']>;
  quiz_id?: Maybe<Scalars['Int']>;
  sanity_path?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "content.questions" */
export type Content_Questions_Max_Order_By = {
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  img_url?: InputMaybe<Order_By>;
  quiz_id?: InputMaybe<Order_By>;
  sanity_path?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Content_Questions_Min_Fields = {
  __typename?: 'content_questions_min_fields';
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  img_url?: Maybe<Scalars['String']>;
  quiz_id?: Maybe<Scalars['Int']>;
  sanity_path?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "content.questions" */
export type Content_Questions_Min_Order_By = {
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  img_url?: InputMaybe<Order_By>;
  quiz_id?: InputMaybe<Order_By>;
  sanity_path?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "content.questions" */
export type Content_Questions_Mutation_Response = {
  __typename?: 'content_questions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Content_Questions>;
};

/** input type for inserting object relation for remote table "content.questions" */
export type Content_Questions_Obj_Rel_Insert_Input = {
  data: Content_Questions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Content_Questions_On_Conflict>;
};

/** on_conflict condition type for table "content.questions" */
export type Content_Questions_On_Conflict = {
  constraint: Content_Questions_Constraint;
  update_columns?: Array<Content_Questions_Update_Column>;
  where?: InputMaybe<Content_Questions_Bool_Exp>;
};

/** Ordering options when selecting data from "content.questions". */
export type Content_Questions_Order_By = {
  choices_aggregate?: InputMaybe<Content_Choices_Aggregate_Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  hint_i18n?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  img_url?: InputMaybe<Order_By>;
  quiz?: InputMaybe<Content_Quizzes_Order_By>;
  quiz_id?: InputMaybe<Order_By>;
  sanity_path?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  title_i18n?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: content.questions */
export type Content_Questions_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Content_Questions_Prepend_Input = {
  hint_i18n?: InputMaybe<Scalars['jsonb']>;
  title_i18n?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "content.questions" */
export enum Content_Questions_Select_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  HintI18n = 'hint_i18n',
  /** column name */
  Id = 'id',
  /** column name */
  ImgUrl = 'img_url',
  /** column name */
  QuizId = 'quiz_id',
  /** column name */
  SanityPath = 'sanity_path',
  /** column name */
  Title = 'title',
  /** column name */
  TitleI18n = 'title_i18n',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "content.questions" */
export type Content_Questions_Set_Input = {
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  hint_i18n?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  img_url?: InputMaybe<Scalars['String']>;
  quiz_id?: InputMaybe<Scalars['Int']>;
  sanity_path?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  title_i18n?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Content_Questions_Stddev_Fields = {
  __typename?: 'content_questions_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  quiz_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "content.questions" */
export type Content_Questions_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  quiz_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Content_Questions_Stddev_Pop_Fields = {
  __typename?: 'content_questions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  quiz_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "content.questions" */
export type Content_Questions_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  quiz_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Content_Questions_Stddev_Samp_Fields = {
  __typename?: 'content_questions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  quiz_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "content.questions" */
export type Content_Questions_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  quiz_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "content_questions" */
export type Content_Questions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Content_Questions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Content_Questions_Stream_Cursor_Value_Input = {
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  hint_i18n?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  img_url?: InputMaybe<Scalars['String']>;
  quiz_id?: InputMaybe<Scalars['Int']>;
  sanity_path?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  title_i18n?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Content_Questions_Sum_Fields = {
  __typename?: 'content_questions_sum_fields';
  id?: Maybe<Scalars['Int']>;
  quiz_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "content.questions" */
export type Content_Questions_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  quiz_id?: InputMaybe<Order_By>;
};

/** update columns of table "content.questions" */
export enum Content_Questions_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  HintI18n = 'hint_i18n',
  /** column name */
  Id = 'id',
  /** column name */
  ImgUrl = 'img_url',
  /** column name */
  QuizId = 'quiz_id',
  /** column name */
  SanityPath = 'sanity_path',
  /** column name */
  Title = 'title',
  /** column name */
  TitleI18n = 'title_i18n',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Content_Questions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Content_Questions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Content_Questions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Content_Questions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Content_Questions_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Content_Questions_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Content_Questions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Content_Questions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Content_Questions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Content_Questions_Var_Pop_Fields = {
  __typename?: 'content_questions_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  quiz_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "content.questions" */
export type Content_Questions_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  quiz_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Content_Questions_Var_Samp_Fields = {
  __typename?: 'content_questions_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  quiz_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "content.questions" */
export type Content_Questions_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  quiz_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Content_Questions_Variance_Fields = {
  __typename?: 'content_questions_variance_fields';
  id?: Maybe<Scalars['Float']>;
  quiz_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "content.questions" */
export type Content_Questions_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  quiz_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "content.quiz_type" */
export type Content_Quiz_Type = {
  __typename?: 'content_quiz_type';
  id: Scalars['String'];
};

/** aggregated selection of "content.quiz_type" */
export type Content_Quiz_Type_Aggregate = {
  __typename?: 'content_quiz_type_aggregate';
  aggregate?: Maybe<Content_Quiz_Type_Aggregate_Fields>;
  nodes: Array<Content_Quiz_Type>;
};

/** aggregate fields of "content.quiz_type" */
export type Content_Quiz_Type_Aggregate_Fields = {
  __typename?: 'content_quiz_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Content_Quiz_Type_Max_Fields>;
  min?: Maybe<Content_Quiz_Type_Min_Fields>;
};


/** aggregate fields of "content.quiz_type" */
export type Content_Quiz_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Content_Quiz_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "content.quiz_type". All fields are combined with a logical 'AND'. */
export type Content_Quiz_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Content_Quiz_Type_Bool_Exp>>;
  _not?: InputMaybe<Content_Quiz_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Content_Quiz_Type_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "content.quiz_type" */
export enum Content_Quiz_Type_Constraint {
  /** unique or primary key constraint on columns "id" */
  QuizTypePkey = 'quiz_type_pkey'
}

/** input type for inserting data into table "content.quiz_type" */
export type Content_Quiz_Type_Insert_Input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Content_Quiz_Type_Max_Fields = {
  __typename?: 'content_quiz_type_max_fields';
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Content_Quiz_Type_Min_Fields = {
  __typename?: 'content_quiz_type_min_fields';
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "content.quiz_type" */
export type Content_Quiz_Type_Mutation_Response = {
  __typename?: 'content_quiz_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Content_Quiz_Type>;
};

/** on_conflict condition type for table "content.quiz_type" */
export type Content_Quiz_Type_On_Conflict = {
  constraint: Content_Quiz_Type_Constraint;
  update_columns?: Array<Content_Quiz_Type_Update_Column>;
  where?: InputMaybe<Content_Quiz_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "content.quiz_type". */
export type Content_Quiz_Type_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: content.quiz_type */
export type Content_Quiz_Type_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "content.quiz_type" */
export enum Content_Quiz_Type_Select_Column {
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "content.quiz_type" */
export type Content_Quiz_Type_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "content_quiz_type" */
export type Content_Quiz_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Content_Quiz_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Content_Quiz_Type_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "content.quiz_type" */
export enum Content_Quiz_Type_Update_Column {
  /** column name */
  Id = 'id'
}

export type Content_Quiz_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Content_Quiz_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Content_Quiz_Type_Bool_Exp;
};

/** columns and relationships of "content.quizzes" */
export type Content_Quizzes = {
  __typename?: 'content_quizzes';
  /** An object relationship */
  chapter?: Maybe<Content_Chapters>;
  /** An object relationship */
  contactpoint?: Maybe<Content_Contactpoints>;
  description: Scalars['jsonb'];
  description_i18n: Scalars['jsonb'];
  id: Scalars['Int'];
  img_url?: Maybe<Scalars['String']>;
  /** An array relationship */
  questions: Array<Content_Questions>;
  /** An aggregate relationship */
  questions_aggregate: Content_Questions_Aggregate;
  required_points: Scalars['Int'];
  sanity_path: Scalars['String'];
  /** An array relationship */
  sessions: Array<Sessions>;
  /** An aggregate relationship */
  sessions_aggregate: Sessions_Aggregate;
  title: Scalars['String'];
  title_i18n: Scalars['jsonb'];
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "content.quizzes" */
export type Content_QuizzesDescriptionArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "content.quizzes" */
export type Content_QuizzesDescription_I18nArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "content.quizzes" */
export type Content_QuizzesQuestionsArgs = {
  distinct_on?: InputMaybe<Array<Content_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Questions_Order_By>>;
  where?: InputMaybe<Content_Questions_Bool_Exp>;
};


/** columns and relationships of "content.quizzes" */
export type Content_QuizzesQuestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Content_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Questions_Order_By>>;
  where?: InputMaybe<Content_Questions_Bool_Exp>;
};


/** columns and relationships of "content.quizzes" */
export type Content_QuizzesSessionsArgs = {
  distinct_on?: InputMaybe<Array<Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sessions_Order_By>>;
  where?: InputMaybe<Sessions_Bool_Exp>;
};


/** columns and relationships of "content.quizzes" */
export type Content_QuizzesSessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sessions_Order_By>>;
  where?: InputMaybe<Sessions_Bool_Exp>;
};


/** columns and relationships of "content.quizzes" */
export type Content_QuizzesTitle_I18nArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "content.quizzes" */
export type Content_Quizzes_Aggregate = {
  __typename?: 'content_quizzes_aggregate';
  aggregate?: Maybe<Content_Quizzes_Aggregate_Fields>;
  nodes: Array<Content_Quizzes>;
};

/** aggregate fields of "content.quizzes" */
export type Content_Quizzes_Aggregate_Fields = {
  __typename?: 'content_quizzes_aggregate_fields';
  avg?: Maybe<Content_Quizzes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Content_Quizzes_Max_Fields>;
  min?: Maybe<Content_Quizzes_Min_Fields>;
  stddev?: Maybe<Content_Quizzes_Stddev_Fields>;
  stddev_pop?: Maybe<Content_Quizzes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Content_Quizzes_Stddev_Samp_Fields>;
  sum?: Maybe<Content_Quizzes_Sum_Fields>;
  var_pop?: Maybe<Content_Quizzes_Var_Pop_Fields>;
  var_samp?: Maybe<Content_Quizzes_Var_Samp_Fields>;
  variance?: Maybe<Content_Quizzes_Variance_Fields>;
};


/** aggregate fields of "content.quizzes" */
export type Content_Quizzes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Content_Quizzes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Content_Quizzes_Append_Input = {
  description?: InputMaybe<Scalars['jsonb']>;
  description_i18n?: InputMaybe<Scalars['jsonb']>;
  title_i18n?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Content_Quizzes_Avg_Fields = {
  __typename?: 'content_quizzes_avg_fields';
  id?: Maybe<Scalars['Float']>;
  required_points?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "content.quizzes". All fields are combined with a logical 'AND'. */
export type Content_Quizzes_Bool_Exp = {
  _and?: InputMaybe<Array<Content_Quizzes_Bool_Exp>>;
  _not?: InputMaybe<Content_Quizzes_Bool_Exp>;
  _or?: InputMaybe<Array<Content_Quizzes_Bool_Exp>>;
  chapter?: InputMaybe<Content_Chapters_Bool_Exp>;
  contactpoint?: InputMaybe<Content_Contactpoints_Bool_Exp>;
  description?: InputMaybe<Jsonb_Comparison_Exp>;
  description_i18n?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  img_url?: InputMaybe<String_Comparison_Exp>;
  questions?: InputMaybe<Content_Questions_Bool_Exp>;
  questions_aggregate?: InputMaybe<Content_Questions_Aggregate_Bool_Exp>;
  required_points?: InputMaybe<Int_Comparison_Exp>;
  sanity_path?: InputMaybe<String_Comparison_Exp>;
  sessions?: InputMaybe<Sessions_Bool_Exp>;
  sessions_aggregate?: InputMaybe<Sessions_Aggregate_Bool_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  title_i18n?: InputMaybe<Jsonb_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "content.quizzes" */
export enum Content_Quizzes_Constraint {
  /** unique or primary key constraint on columns "id" */
  QuizzesPkey = 'quizzes_pkey',
  /** unique or primary key constraint on columns "sanity_path" */
  QuizzesSanityPathKey = 'quizzes_sanity_path_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Content_Quizzes_Delete_At_Path_Input = {
  description?: InputMaybe<Array<Scalars['String']>>;
  description_i18n?: InputMaybe<Array<Scalars['String']>>;
  title_i18n?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Content_Quizzes_Delete_Elem_Input = {
  description?: InputMaybe<Scalars['Int']>;
  description_i18n?: InputMaybe<Scalars['Int']>;
  title_i18n?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Content_Quizzes_Delete_Key_Input = {
  description?: InputMaybe<Scalars['String']>;
  description_i18n?: InputMaybe<Scalars['String']>;
  title_i18n?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "content.quizzes" */
export type Content_Quizzes_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  required_points?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "content.quizzes" */
export type Content_Quizzes_Insert_Input = {
  chapter?: InputMaybe<Content_Chapters_Obj_Rel_Insert_Input>;
  contactpoint?: InputMaybe<Content_Contactpoints_Obj_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['jsonb']>;
  description_i18n?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  img_url?: InputMaybe<Scalars['String']>;
  questions?: InputMaybe<Content_Questions_Arr_Rel_Insert_Input>;
  required_points?: InputMaybe<Scalars['Int']>;
  sanity_path?: InputMaybe<Scalars['String']>;
  sessions?: InputMaybe<Sessions_Arr_Rel_Insert_Input>;
  title?: InputMaybe<Scalars['String']>;
  title_i18n?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Content_Quizzes_Max_Fields = {
  __typename?: 'content_quizzes_max_fields';
  id?: Maybe<Scalars['Int']>;
  img_url?: Maybe<Scalars['String']>;
  required_points?: Maybe<Scalars['Int']>;
  sanity_path?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Content_Quizzes_Min_Fields = {
  __typename?: 'content_quizzes_min_fields';
  id?: Maybe<Scalars['Int']>;
  img_url?: Maybe<Scalars['String']>;
  required_points?: Maybe<Scalars['Int']>;
  sanity_path?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "content.quizzes" */
export type Content_Quizzes_Mutation_Response = {
  __typename?: 'content_quizzes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Content_Quizzes>;
};

/** input type for inserting object relation for remote table "content.quizzes" */
export type Content_Quizzes_Obj_Rel_Insert_Input = {
  data: Content_Quizzes_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Content_Quizzes_On_Conflict>;
};

/** on_conflict condition type for table "content.quizzes" */
export type Content_Quizzes_On_Conflict = {
  constraint: Content_Quizzes_Constraint;
  update_columns?: Array<Content_Quizzes_Update_Column>;
  where?: InputMaybe<Content_Quizzes_Bool_Exp>;
};

/** Ordering options when selecting data from "content.quizzes". */
export type Content_Quizzes_Order_By = {
  chapter?: InputMaybe<Content_Chapters_Order_By>;
  contactpoint?: InputMaybe<Content_Contactpoints_Order_By>;
  description?: InputMaybe<Order_By>;
  description_i18n?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  img_url?: InputMaybe<Order_By>;
  questions_aggregate?: InputMaybe<Content_Questions_Aggregate_Order_By>;
  required_points?: InputMaybe<Order_By>;
  sanity_path?: InputMaybe<Order_By>;
  sessions_aggregate?: InputMaybe<Sessions_Aggregate_Order_By>;
  title?: InputMaybe<Order_By>;
  title_i18n?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: content.quizzes */
export type Content_Quizzes_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Content_Quizzes_Prepend_Input = {
  description?: InputMaybe<Scalars['jsonb']>;
  description_i18n?: InputMaybe<Scalars['jsonb']>;
  title_i18n?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "content.quizzes" */
export enum Content_Quizzes_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  DescriptionI18n = 'description_i18n',
  /** column name */
  Id = 'id',
  /** column name */
  ImgUrl = 'img_url',
  /** column name */
  RequiredPoints = 'required_points',
  /** column name */
  SanityPath = 'sanity_path',
  /** column name */
  Title = 'title',
  /** column name */
  TitleI18n = 'title_i18n',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "content.quizzes" */
export type Content_Quizzes_Set_Input = {
  description?: InputMaybe<Scalars['jsonb']>;
  description_i18n?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  img_url?: InputMaybe<Scalars['String']>;
  required_points?: InputMaybe<Scalars['Int']>;
  sanity_path?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  title_i18n?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Content_Quizzes_Stddev_Fields = {
  __typename?: 'content_quizzes_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  required_points?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Content_Quizzes_Stddev_Pop_Fields = {
  __typename?: 'content_quizzes_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  required_points?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Content_Quizzes_Stddev_Samp_Fields = {
  __typename?: 'content_quizzes_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  required_points?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "content_quizzes" */
export type Content_Quizzes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Content_Quizzes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Content_Quizzes_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['jsonb']>;
  description_i18n?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  img_url?: InputMaybe<Scalars['String']>;
  required_points?: InputMaybe<Scalars['Int']>;
  sanity_path?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  title_i18n?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Content_Quizzes_Sum_Fields = {
  __typename?: 'content_quizzes_sum_fields';
  id?: Maybe<Scalars['Int']>;
  required_points?: Maybe<Scalars['Int']>;
};

/** update columns of table "content.quizzes" */
export enum Content_Quizzes_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  DescriptionI18n = 'description_i18n',
  /** column name */
  Id = 'id',
  /** column name */
  ImgUrl = 'img_url',
  /** column name */
  RequiredPoints = 'required_points',
  /** column name */
  SanityPath = 'sanity_path',
  /** column name */
  Title = 'title',
  /** column name */
  TitleI18n = 'title_i18n',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Content_Quizzes_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Content_Quizzes_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Content_Quizzes_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Content_Quizzes_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Content_Quizzes_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Content_Quizzes_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Content_Quizzes_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Content_Quizzes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Content_Quizzes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Content_Quizzes_Var_Pop_Fields = {
  __typename?: 'content_quizzes_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  required_points?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Content_Quizzes_Var_Samp_Fields = {
  __typename?: 'content_quizzes_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  required_points?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Content_Quizzes_Variance_Fields = {
  __typename?: 'content_quizzes_variance_fields';
  id?: Maybe<Scalars['Float']>;
  required_points?: Maybe<Scalars['Float']>;
};

/** US: United States, Canada, Rest of World; EN: Rest of Europe, Fallback */
export type Country_Code = {
  __typename?: 'country_code';
  value: Scalars['String'];
};

/** aggregated selection of "country_code" */
export type Country_Code_Aggregate = {
  __typename?: 'country_code_aggregate';
  aggregate?: Maybe<Country_Code_Aggregate_Fields>;
  nodes: Array<Country_Code>;
};

/** aggregate fields of "country_code" */
export type Country_Code_Aggregate_Fields = {
  __typename?: 'country_code_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Country_Code_Max_Fields>;
  min?: Maybe<Country_Code_Min_Fields>;
};


/** aggregate fields of "country_code" */
export type Country_Code_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Country_Code_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "country_code". All fields are combined with a logical 'AND'. */
export type Country_Code_Bool_Exp = {
  _and?: InputMaybe<Array<Country_Code_Bool_Exp>>;
  _not?: InputMaybe<Country_Code_Bool_Exp>;
  _or?: InputMaybe<Array<Country_Code_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "country_code" */
export enum Country_Code_Constraint {
  /** unique or primary key constraint on columns "value" */
  CountryCodePkey = 'country_code_pkey'
}

export enum Country_Code_Enum {
  Ch = 'CH',
  De = 'DE',
  En = 'EN',
  Es = 'ES',
  Eu = 'EU',
  Fr = 'FR',
  It = 'IT',
  Nl = 'NL',
  Uk = 'UK',
  Us = 'US'
}

/** Boolean expression to compare columns of type "country_code_enum". All fields are combined with logical 'AND'. */
export type Country_Code_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Country_Code_Enum>;
  _in?: InputMaybe<Array<Country_Code_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Country_Code_Enum>;
  _nin?: InputMaybe<Array<Country_Code_Enum>>;
};

/** input type for inserting data into table "country_code" */
export type Country_Code_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Country_Code_Max_Fields = {
  __typename?: 'country_code_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Country_Code_Min_Fields = {
  __typename?: 'country_code_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "country_code" */
export type Country_Code_Mutation_Response = {
  __typename?: 'country_code_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Country_Code>;
};

/** on_conflict condition type for table "country_code" */
export type Country_Code_On_Conflict = {
  constraint: Country_Code_Constraint;
  update_columns?: Array<Country_Code_Update_Column>;
  where?: InputMaybe<Country_Code_Bool_Exp>;
};

/** Ordering options when selecting data from "country_code". */
export type Country_Code_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: country_code */
export type Country_Code_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "country_code" */
export enum Country_Code_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "country_code" */
export type Country_Code_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "country_code" */
export type Country_Code_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Country_Code_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Country_Code_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "country_code" */
export enum Country_Code_Update_Column {
  /** column name */
  Value = 'value'
}

export type Country_Code_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Country_Code_Set_Input>;
  /** filter the rows which have to be updated */
  where: Country_Code_Bool_Exp;
};

/** columns and relationships of "currency" */
export type Currency = {
  __typename?: 'currency';
  value: Scalars['String'];
};

/** aggregated selection of "currency" */
export type Currency_Aggregate = {
  __typename?: 'currency_aggregate';
  aggregate?: Maybe<Currency_Aggregate_Fields>;
  nodes: Array<Currency>;
};

/** aggregate fields of "currency" */
export type Currency_Aggregate_Fields = {
  __typename?: 'currency_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Currency_Max_Fields>;
  min?: Maybe<Currency_Min_Fields>;
};


/** aggregate fields of "currency" */
export type Currency_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Currency_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "currency". All fields are combined with a logical 'AND'. */
export type Currency_Bool_Exp = {
  _and?: InputMaybe<Array<Currency_Bool_Exp>>;
  _not?: InputMaybe<Currency_Bool_Exp>;
  _or?: InputMaybe<Array<Currency_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "currency" */
export enum Currency_Constraint {
  /** unique or primary key constraint on columns "value" */
  CurrencyPkey = 'currency_pkey'
}

/** input type for inserting data into table "currency" */
export type Currency_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Currency_Max_Fields = {
  __typename?: 'currency_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Currency_Min_Fields = {
  __typename?: 'currency_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "currency" */
export type Currency_Mutation_Response = {
  __typename?: 'currency_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Currency>;
};

/** on_conflict condition type for table "currency" */
export type Currency_On_Conflict = {
  constraint: Currency_Constraint;
  update_columns?: Array<Currency_Update_Column>;
  where?: InputMaybe<Currency_Bool_Exp>;
};

/** Ordering options when selecting data from "currency". */
export type Currency_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: currency */
export type Currency_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "currency" */
export enum Currency_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "currency" */
export type Currency_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "currency" */
export type Currency_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Currency_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Currency_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "currency" */
export enum Currency_Update_Column {
  /** column name */
  Value = 'value'
}

export type Currency_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Currency_Set_Input>;
  /** filter the rows which have to be updated */
  where: Currency_Bool_Exp;
};

/** columns and relationships of "current_user" */
export type Current_User = {
  __typename?: 'current_user';
  created_at?: Maybe<Scalars['timestamptz']>;
  email_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_temporary_password?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  secured?: Maybe<Scalars['Boolean']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "current_user" */
export type Current_User_Aggregate = {
  __typename?: 'current_user_aggregate';
  aggregate?: Maybe<Current_User_Aggregate_Fields>;
  nodes: Array<Current_User>;
};

/** aggregate fields of "current_user" */
export type Current_User_Aggregate_Fields = {
  __typename?: 'current_user_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Current_User_Max_Fields>;
  min?: Maybe<Current_User_Min_Fields>;
};


/** aggregate fields of "current_user" */
export type Current_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Current_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "current_user". All fields are combined with a logical 'AND'. */
export type Current_User_Bool_Exp = {
  _and?: InputMaybe<Array<Current_User_Bool_Exp>>;
  _not?: InputMaybe<Current_User_Bool_Exp>;
  _or?: InputMaybe<Array<Current_User_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email_address?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_temporary_password?: InputMaybe<Boolean_Comparison_Exp>;
  language?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  secured?: InputMaybe<Boolean_Comparison_Exp>;
  vendor_id?: InputMaybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "current_user" */
export type Current_User_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email_address?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_temporary_password?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  secured?: InputMaybe<Scalars['Boolean']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Current_User_Max_Fields = {
  __typename?: 'current_user_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Current_User_Min_Fields = {
  __typename?: 'current_user_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "current_user" */
export type Current_User_Mutation_Response = {
  __typename?: 'current_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Current_User>;
};

/** Ordering options when selecting data from "current_user". */
export type Current_User_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email_address?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_temporary_password?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  secured?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** select columns of table "current_user" */
export enum Current_User_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmailAddress = 'email_address',
  /** column name */
  Id = 'id',
  /** column name */
  IsTemporaryPassword = 'is_temporary_password',
  /** column name */
  Language = 'language',
  /** column name */
  Name = 'name',
  /** column name */
  Secured = 'secured',
  /** column name */
  VendorId = 'vendor_id'
}

/** input type for updating data in table "current_user" */
export type Current_User_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email_address?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_temporary_password?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  secured?: InputMaybe<Scalars['Boolean']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "current_user" */
export type Current_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Current_User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Current_User_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email_address?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_temporary_password?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  secured?: InputMaybe<Scalars['Boolean']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

export type Current_User_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Current_User_Set_Input>;
  /** filter the rows which have to be updated */
  where: Current_User_Bool_Exp;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** tracks a user response */
export type Customer_Answer = {
  __typename?: 'customer_answer';
  /** An object relationship */
  User?: Maybe<User>;
  additional_data_processing_consent_given_at?: Maybe<Scalars['timestamptz']>;
  age?: Maybe<Scalars['Int']>;
  arm_length_cm?: Maybe<Scalars['float8']>;
  /** Article groups used in this answer (see: public.article_groups) */
  article_groups?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  bicycle_type?: Maybe<Answer_Enums_Bicycle_Type>;
  bicycle_type_id?: Maybe<Answer_Enums_Bicycle_Type_Enum>;
  /** Body height in centimetres */
  body_height?: Maybe<Scalars['float8']>;
  comment?: Maybe<Scalars['String']>;
  commission?: Maybe<Scalars['float8']>;
  created_at: Scalars['timestamptz'];
  customer_name?: Maybe<Scalars['String']>;
  /** An array relationship */
  cv_body: Array<Cv_Body>;
  /** An aggregate relationship */
  cv_body_aggregate: Cv_Body_Aggregate;
  /** An array relationship */
  cv_handmarks: Array<Cv_Handmarks>;
  /** An aggregate relationship */
  cv_handmarks_aggregate: Cv_Handmarks_Aggregate;
  /** An array relationship */
  cv_sitbones: Array<Cv_Sitbones>;
  /** An aggregate relationship */
  cv_sitbones_aggregate: Cv_Sitbones_Aggregate;
  /** An object relationship */
  driving_duration?: Maybe<Answer_Enums_Driving_Duration>;
  driving_duration_id?: Maybe<Answer_Enums_Driving_Duration_Enum>;
  /** An object relationship */
  driving_frequency?: Maybe<Answer_Enums_Driving_Frequency>;
  driving_frequency_id?: Maybe<Answer_Enums_Driving_Frequency_Enum>;
  /** An object relationship */
  e_bike?: Maybe<Answer_Enums_E_Bike>;
  e_bike_id?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  /** An array relationship */
  events: Array<Metric_App_Events>;
  /** An aggregate relationship */
  events_aggregate: Metric_App_Events_Aggregate;
  /** An object relationship */
  foot_position?: Maybe<Answer_Enums_Foot_Position>;
  foot_position_id?: Maybe<Answer_Enums_Foot_Position_Enum>;
  /** An array relationship */
  foot_problems: Array<Customer_Answer_Foot_Problems>;
  /** An aggregate relationship */
  foot_problems_aggregate: Customer_Answer_Foot_Problems_Aggregate;
  /** An object relationship */
  foot_type?: Maybe<Answer_Enums_Foot_Type>;
  foot_type_id?: Maybe<Answer_Enums_Foot_Type_Enum>;
  /** distance from the tip of the middle finger to the middle of the hand (corresponds to hand_height in the cv table) */
  grip_width_cm?: Maybe<Scalars['float8']>;
  /** Size of the grip (S, M, L) */
  gripping_distance?: Maybe<Answer_Enums_Gripping_Distance_Enum>;
  /** An array relationship */
  hand_problems: Array<Customer_Answer_Hand_Problems>;
  /** An aggregate relationship */
  hand_problems_aggregate: Customer_Answer_Hand_Problems_Aggregate;
  /** distance across the hand over the knuckles */
  hand_width_cm?: Maybe<Scalars['float8']>;
  hip_circumference?: Maybe<Scalars['float8']>;
  hip_width?: Maybe<Scalars['float8']>;
  id: Scalars['String'];
  knee_height_cm?: Maybe<Scalars['float8']>;
  /** An object relationship */
  leg_axis?: Maybe<Answer_Enums_Leg_Axis>;
  leg_axis_id?: Maybe<Answer_Enums_Leg_Axis_Enum>;
  /** An array relationship */
  pelvic_problems: Array<Customer_Answer_Pelvic_Problems>;
  /** An aggregate relationship */
  pelvic_problems_aggregate: Customer_Answer_Pelvic_Problems_Aggregate;
  /** An array relationship */
  question_durations: Array<Metric_Question_Duration>;
  /** An aggregate relationship */
  question_durations_aggregate: Metric_Question_Duration_Aggregate;
  /** An array relationship */
  recommened_own_articles: Array<Recommendation_Own_Articles>;
  /** An aggregate relationship */
  recommened_own_articles_aggregate: Recommendation_Own_Articles_Aggregate;
  /** corrensponds to "sitbone_distance" in cv table "sitbones" */
  seat_bone_distance_cm?: Maybe<Scalars['Int']>;
  /** An object relationship */
  seat_position?: Maybe<Answer_Enums_Seat_Position>;
  seat_position_id?: Maybe<Answer_Enums_Seat_Position_Enum>;
  /** An object relationship */
  sex?: Maybe<Answer_Enums_Sex>;
  sex_id?: Maybe<Answer_Enums_Sex_Enum>;
  shoe_size?: Maybe<Scalars['Int']>;
  short_id?: Maybe<Scalars['String']>;
  shoulder_width_cm?: Maybe<Scalars['float8']>;
  /** An array relationship */
  signatures: Array<Customer_Signature>;
  /** An aggregate relationship */
  signatures_aggregate: Customer_Signature_Aggregate;
  /** crotch length in centimetres (distance from hip to the ground) */
  step_length?: Maybe<Scalars['float8']>;
  user_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  user_preferences?: Maybe<Preferences_User_Preferences>;
  /** An object relationship */
  vendor?: Maybe<Organisation>;
  vendor_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  vendor_preferences?: Maybe<Preferences_Vendor_Preferences>;
  weight?: Maybe<Scalars['Int']>;
};


/** tracks a user response */
export type Customer_AnswerArticle_GroupsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** tracks a user response */
export type Customer_AnswerCv_BodyArgs = {
  distinct_on?: InputMaybe<Array<Cv_Body_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cv_Body_Order_By>>;
  where?: InputMaybe<Cv_Body_Bool_Exp>;
};


/** tracks a user response */
export type Customer_AnswerCv_Body_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cv_Body_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cv_Body_Order_By>>;
  where?: InputMaybe<Cv_Body_Bool_Exp>;
};


/** tracks a user response */
export type Customer_AnswerCv_HandmarksArgs = {
  distinct_on?: InputMaybe<Array<Cv_Handmarks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cv_Handmarks_Order_By>>;
  where?: InputMaybe<Cv_Handmarks_Bool_Exp>;
};


/** tracks a user response */
export type Customer_AnswerCv_Handmarks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cv_Handmarks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cv_Handmarks_Order_By>>;
  where?: InputMaybe<Cv_Handmarks_Bool_Exp>;
};


/** tracks a user response */
export type Customer_AnswerCv_SitbonesArgs = {
  distinct_on?: InputMaybe<Array<Cv_Sitbones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cv_Sitbones_Order_By>>;
  where?: InputMaybe<Cv_Sitbones_Bool_Exp>;
};


/** tracks a user response */
export type Customer_AnswerCv_Sitbones_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cv_Sitbones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cv_Sitbones_Order_By>>;
  where?: InputMaybe<Cv_Sitbones_Bool_Exp>;
};


/** tracks a user response */
export type Customer_AnswerEventsArgs = {
  distinct_on?: InputMaybe<Array<Metric_App_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Metric_App_Events_Order_By>>;
  where?: InputMaybe<Metric_App_Events_Bool_Exp>;
};


/** tracks a user response */
export type Customer_AnswerEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Metric_App_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Metric_App_Events_Order_By>>;
  where?: InputMaybe<Metric_App_Events_Bool_Exp>;
};


/** tracks a user response */
export type Customer_AnswerFoot_ProblemsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Answer_Foot_Problems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Answer_Foot_Problems_Order_By>>;
  where?: InputMaybe<Customer_Answer_Foot_Problems_Bool_Exp>;
};


/** tracks a user response */
export type Customer_AnswerFoot_Problems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Answer_Foot_Problems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Answer_Foot_Problems_Order_By>>;
  where?: InputMaybe<Customer_Answer_Foot_Problems_Bool_Exp>;
};


/** tracks a user response */
export type Customer_AnswerHand_ProblemsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Answer_Hand_Problems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Answer_Hand_Problems_Order_By>>;
  where?: InputMaybe<Customer_Answer_Hand_Problems_Bool_Exp>;
};


/** tracks a user response */
export type Customer_AnswerHand_Problems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Answer_Hand_Problems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Answer_Hand_Problems_Order_By>>;
  where?: InputMaybe<Customer_Answer_Hand_Problems_Bool_Exp>;
};


/** tracks a user response */
export type Customer_AnswerPelvic_ProblemsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Answer_Pelvic_Problems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Answer_Pelvic_Problems_Order_By>>;
  where?: InputMaybe<Customer_Answer_Pelvic_Problems_Bool_Exp>;
};


/** tracks a user response */
export type Customer_AnswerPelvic_Problems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Answer_Pelvic_Problems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Answer_Pelvic_Problems_Order_By>>;
  where?: InputMaybe<Customer_Answer_Pelvic_Problems_Bool_Exp>;
};


/** tracks a user response */
export type Customer_AnswerQuestion_DurationsArgs = {
  distinct_on?: InputMaybe<Array<Metric_Question_Duration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Metric_Question_Duration_Order_By>>;
  where?: InputMaybe<Metric_Question_Duration_Bool_Exp>;
};


/** tracks a user response */
export type Customer_AnswerQuestion_Durations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Metric_Question_Duration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Metric_Question_Duration_Order_By>>;
  where?: InputMaybe<Metric_Question_Duration_Bool_Exp>;
};


/** tracks a user response */
export type Customer_AnswerRecommened_Own_ArticlesArgs = {
  distinct_on?: InputMaybe<Array<Recommendation_Own_Articles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendation_Own_Articles_Order_By>>;
  where?: InputMaybe<Recommendation_Own_Articles_Bool_Exp>;
};


/** tracks a user response */
export type Customer_AnswerRecommened_Own_Articles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Recommendation_Own_Articles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendation_Own_Articles_Order_By>>;
  where?: InputMaybe<Recommendation_Own_Articles_Bool_Exp>;
};


/** tracks a user response */
export type Customer_AnswerSignaturesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Signature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Signature_Order_By>>;
  where?: InputMaybe<Customer_Signature_Bool_Exp>;
};


/** tracks a user response */
export type Customer_AnswerSignatures_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Signature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Signature_Order_By>>;
  where?: InputMaybe<Customer_Signature_Bool_Exp>;
};

/** aggregated selection of "customer.answer" */
export type Customer_Answer_Aggregate = {
  __typename?: 'customer_answer_aggregate';
  aggregate?: Maybe<Customer_Answer_Aggregate_Fields>;
  nodes: Array<Customer_Answer>;
};

/** aggregate fields of "customer.answer" */
export type Customer_Answer_Aggregate_Fields = {
  __typename?: 'customer_answer_aggregate_fields';
  avg?: Maybe<Customer_Answer_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Answer_Max_Fields>;
  min?: Maybe<Customer_Answer_Min_Fields>;
  stddev?: Maybe<Customer_Answer_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Answer_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Answer_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Answer_Sum_Fields>;
  var_pop?: Maybe<Customer_Answer_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Answer_Var_Samp_Fields>;
  variance?: Maybe<Customer_Answer_Variance_Fields>;
};


/** aggregate fields of "customer.answer" */
export type Customer_Answer_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Answer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Customer_Answer_Append_Input = {
  /** Article groups used in this answer (see: public.article_groups) */
  article_groups?: InputMaybe<Scalars['jsonb']>;
};

/** columns and relationships of "customer.answer_article_event" */
export type Customer_Answer_Article_Event = {
  __typename?: 'customer_answer_article_event';
  /** An object relationship */
  answer: Customer_Answer;
  answer_id: Scalars['String'];
  article_id: Scalars['String'];
  /** An array relationship */
  article_information_i18n: Array<Article_Information_I18n>;
  /** An aggregate relationship */
  article_information_i18n_aggregate: Article_Information_I18n_Aggregate;
  created_at: Scalars['timestamptz'];
  event_type: Event_Enums_Article_Event_Enum;
  source_type: Event_Enums_Event_Source_Enum;
};


/** columns and relationships of "customer.answer_article_event" */
export type Customer_Answer_Article_EventArticle_Information_I18nArgs = {
  distinct_on?: InputMaybe<Array<Article_Information_I18n_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Article_Information_I18n_Order_By>>;
  where?: InputMaybe<Article_Information_I18n_Bool_Exp>;
};


/** columns and relationships of "customer.answer_article_event" */
export type Customer_Answer_Article_EventArticle_Information_I18n_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Article_Information_I18n_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Article_Information_I18n_Order_By>>;
  where?: InputMaybe<Article_Information_I18n_Bool_Exp>;
};

/** aggregated selection of "customer.answer_article_event" */
export type Customer_Answer_Article_Event_Aggregate = {
  __typename?: 'customer_answer_article_event_aggregate';
  aggregate?: Maybe<Customer_Answer_Article_Event_Aggregate_Fields>;
  nodes: Array<Customer_Answer_Article_Event>;
};

/** aggregate fields of "customer.answer_article_event" */
export type Customer_Answer_Article_Event_Aggregate_Fields = {
  __typename?: 'customer_answer_article_event_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Customer_Answer_Article_Event_Max_Fields>;
  min?: Maybe<Customer_Answer_Article_Event_Min_Fields>;
};


/** aggregate fields of "customer.answer_article_event" */
export type Customer_Answer_Article_Event_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Answer_Article_Event_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "customer.answer_article_event". All fields are combined with a logical 'AND'. */
export type Customer_Answer_Article_Event_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Answer_Article_Event_Bool_Exp>>;
  _not?: InputMaybe<Customer_Answer_Article_Event_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Answer_Article_Event_Bool_Exp>>;
  answer?: InputMaybe<Customer_Answer_Bool_Exp>;
  answer_id?: InputMaybe<String_Comparison_Exp>;
  article_id?: InputMaybe<String_Comparison_Exp>;
  article_information_i18n?: InputMaybe<Article_Information_I18n_Bool_Exp>;
  article_information_i18n_aggregate?: InputMaybe<Article_Information_I18n_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  event_type?: InputMaybe<Event_Enums_Article_Event_Enum_Comparison_Exp>;
  source_type?: InputMaybe<Event_Enums_Event_Source_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer.answer_article_event" */
export enum Customer_Answer_Article_Event_Constraint {
  /** unique or primary key constraint on columns "answer_id", "created_at" */
  AnswerArticleEventPkey = 'answer_article_event_pkey'
}

/** input type for inserting data into table "customer.answer_article_event" */
export type Customer_Answer_Article_Event_Insert_Input = {
  answer?: InputMaybe<Customer_Answer_Obj_Rel_Insert_Input>;
  answer_id?: InputMaybe<Scalars['String']>;
  article_id?: InputMaybe<Scalars['String']>;
  article_information_i18n?: InputMaybe<Article_Information_I18n_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  event_type?: InputMaybe<Event_Enums_Article_Event_Enum>;
  source_type?: InputMaybe<Event_Enums_Event_Source_Enum>;
};

/** aggregate max on columns */
export type Customer_Answer_Article_Event_Max_Fields = {
  __typename?: 'customer_answer_article_event_max_fields';
  answer_id?: Maybe<Scalars['String']>;
  article_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Customer_Answer_Article_Event_Min_Fields = {
  __typename?: 'customer_answer_article_event_min_fields';
  answer_id?: Maybe<Scalars['String']>;
  article_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "customer.answer_article_event" */
export type Customer_Answer_Article_Event_Mutation_Response = {
  __typename?: 'customer_answer_article_event_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Answer_Article_Event>;
};

/** on_conflict condition type for table "customer.answer_article_event" */
export type Customer_Answer_Article_Event_On_Conflict = {
  constraint: Customer_Answer_Article_Event_Constraint;
  update_columns?: Array<Customer_Answer_Article_Event_Update_Column>;
  where?: InputMaybe<Customer_Answer_Article_Event_Bool_Exp>;
};

/** Ordering options when selecting data from "customer.answer_article_event". */
export type Customer_Answer_Article_Event_Order_By = {
  answer?: InputMaybe<Customer_Answer_Order_By>;
  answer_id?: InputMaybe<Order_By>;
  article_id?: InputMaybe<Order_By>;
  article_information_i18n_aggregate?: InputMaybe<Article_Information_I18n_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  event_type?: InputMaybe<Order_By>;
  source_type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customer.answer_article_event */
export type Customer_Answer_Article_Event_Pk_Columns_Input = {
  answer_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
};

/** select columns of table "customer.answer_article_event" */
export enum Customer_Answer_Article_Event_Select_Column {
  /** column name */
  AnswerId = 'answer_id',
  /** column name */
  ArticleId = 'article_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventType = 'event_type',
  /** column name */
  SourceType = 'source_type'
}

/** input type for updating data in table "customer.answer_article_event" */
export type Customer_Answer_Article_Event_Set_Input = {
  answer_id?: InputMaybe<Scalars['String']>;
  article_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  event_type?: InputMaybe<Event_Enums_Article_Event_Enum>;
  source_type?: InputMaybe<Event_Enums_Event_Source_Enum>;
};

/** Streaming cursor of the table "customer_answer_article_event" */
export type Customer_Answer_Article_Event_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Answer_Article_Event_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Answer_Article_Event_Stream_Cursor_Value_Input = {
  answer_id?: InputMaybe<Scalars['String']>;
  article_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  event_type?: InputMaybe<Event_Enums_Article_Event_Enum>;
  source_type?: InputMaybe<Event_Enums_Event_Source_Enum>;
};

/** update columns of table "customer.answer_article_event" */
export enum Customer_Answer_Article_Event_Update_Column {
  /** column name */
  AnswerId = 'answer_id',
  /** column name */
  ArticleId = 'article_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventType = 'event_type',
  /** column name */
  SourceType = 'source_type'
}

export type Customer_Answer_Article_Event_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Answer_Article_Event_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Answer_Article_Event_Bool_Exp;
};

/** aggregate avg on columns */
export type Customer_Answer_Avg_Fields = {
  __typename?: 'customer_answer_avg_fields';
  age?: Maybe<Scalars['Float']>;
  arm_length_cm?: Maybe<Scalars['Float']>;
  /** Body height in centimetres */
  body_height?: Maybe<Scalars['Float']>;
  commission?: Maybe<Scalars['Float']>;
  /** distance from the tip of the middle finger to the middle of the hand (corresponds to hand_height in the cv table) */
  grip_width_cm?: Maybe<Scalars['Float']>;
  /** distance across the hand over the knuckles */
  hand_width_cm?: Maybe<Scalars['Float']>;
  hip_circumference?: Maybe<Scalars['Float']>;
  hip_width?: Maybe<Scalars['Float']>;
  knee_height_cm?: Maybe<Scalars['Float']>;
  /** corrensponds to "sitbone_distance" in cv table "sitbones" */
  seat_bone_distance_cm?: Maybe<Scalars['Float']>;
  shoe_size?: Maybe<Scalars['Float']>;
  shoulder_width_cm?: Maybe<Scalars['Float']>;
  /** crotch length in centimetres (distance from hip to the ground) */
  step_length?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "customer.answer". All fields are combined with a logical 'AND'. */
export type Customer_Answer_Bool_Exp = {
  User?: InputMaybe<User_Bool_Exp>;
  _and?: InputMaybe<Array<Customer_Answer_Bool_Exp>>;
  _not?: InputMaybe<Customer_Answer_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Answer_Bool_Exp>>;
  additional_data_processing_consent_given_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  age?: InputMaybe<Int_Comparison_Exp>;
  arm_length_cm?: InputMaybe<Float8_Comparison_Exp>;
  article_groups?: InputMaybe<Jsonb_Comparison_Exp>;
  bicycle_type?: InputMaybe<Answer_Enums_Bicycle_Type_Bool_Exp>;
  bicycle_type_id?: InputMaybe<Answer_Enums_Bicycle_Type_Enum_Comparison_Exp>;
  body_height?: InputMaybe<Float8_Comparison_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  commission?: InputMaybe<Float8_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  customer_name?: InputMaybe<String_Comparison_Exp>;
  cv_body?: InputMaybe<Cv_Body_Bool_Exp>;
  cv_body_aggregate?: InputMaybe<Cv_Body_Aggregate_Bool_Exp>;
  cv_handmarks?: InputMaybe<Cv_Handmarks_Bool_Exp>;
  cv_handmarks_aggregate?: InputMaybe<Cv_Handmarks_Aggregate_Bool_Exp>;
  cv_sitbones?: InputMaybe<Cv_Sitbones_Bool_Exp>;
  cv_sitbones_aggregate?: InputMaybe<Cv_Sitbones_Aggregate_Bool_Exp>;
  driving_duration?: InputMaybe<Answer_Enums_Driving_Duration_Bool_Exp>;
  driving_duration_id?: InputMaybe<Answer_Enums_Driving_Duration_Enum_Comparison_Exp>;
  driving_frequency?: InputMaybe<Answer_Enums_Driving_Frequency_Bool_Exp>;
  driving_frequency_id?: InputMaybe<Answer_Enums_Driving_Frequency_Enum_Comparison_Exp>;
  e_bike?: InputMaybe<Answer_Enums_E_Bike_Bool_Exp>;
  e_bike_id?: InputMaybe<Boolean_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  events?: InputMaybe<Metric_App_Events_Bool_Exp>;
  events_aggregate?: InputMaybe<Metric_App_Events_Aggregate_Bool_Exp>;
  foot_position?: InputMaybe<Answer_Enums_Foot_Position_Bool_Exp>;
  foot_position_id?: InputMaybe<Answer_Enums_Foot_Position_Enum_Comparison_Exp>;
  foot_problems?: InputMaybe<Customer_Answer_Foot_Problems_Bool_Exp>;
  foot_problems_aggregate?: InputMaybe<Customer_Answer_Foot_Problems_Aggregate_Bool_Exp>;
  foot_type?: InputMaybe<Answer_Enums_Foot_Type_Bool_Exp>;
  foot_type_id?: InputMaybe<Answer_Enums_Foot_Type_Enum_Comparison_Exp>;
  grip_width_cm?: InputMaybe<Float8_Comparison_Exp>;
  gripping_distance?: InputMaybe<Answer_Enums_Gripping_Distance_Enum_Comparison_Exp>;
  hand_problems?: InputMaybe<Customer_Answer_Hand_Problems_Bool_Exp>;
  hand_problems_aggregate?: InputMaybe<Customer_Answer_Hand_Problems_Aggregate_Bool_Exp>;
  hand_width_cm?: InputMaybe<Float8_Comparison_Exp>;
  hip_circumference?: InputMaybe<Float8_Comparison_Exp>;
  hip_width?: InputMaybe<Float8_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  knee_height_cm?: InputMaybe<Float8_Comparison_Exp>;
  leg_axis?: InputMaybe<Answer_Enums_Leg_Axis_Bool_Exp>;
  leg_axis_id?: InputMaybe<Answer_Enums_Leg_Axis_Enum_Comparison_Exp>;
  pelvic_problems?: InputMaybe<Customer_Answer_Pelvic_Problems_Bool_Exp>;
  pelvic_problems_aggregate?: InputMaybe<Customer_Answer_Pelvic_Problems_Aggregate_Bool_Exp>;
  question_durations?: InputMaybe<Metric_Question_Duration_Bool_Exp>;
  question_durations_aggregate?: InputMaybe<Metric_Question_Duration_Aggregate_Bool_Exp>;
  recommened_own_articles?: InputMaybe<Recommendation_Own_Articles_Bool_Exp>;
  recommened_own_articles_aggregate?: InputMaybe<Recommendation_Own_Articles_Aggregate_Bool_Exp>;
  seat_bone_distance_cm?: InputMaybe<Int_Comparison_Exp>;
  seat_position?: InputMaybe<Answer_Enums_Seat_Position_Bool_Exp>;
  seat_position_id?: InputMaybe<Answer_Enums_Seat_Position_Enum_Comparison_Exp>;
  sex?: InputMaybe<Answer_Enums_Sex_Bool_Exp>;
  sex_id?: InputMaybe<Answer_Enums_Sex_Enum_Comparison_Exp>;
  shoe_size?: InputMaybe<Int_Comparison_Exp>;
  short_id?: InputMaybe<String_Comparison_Exp>;
  shoulder_width_cm?: InputMaybe<Float8_Comparison_Exp>;
  signatures?: InputMaybe<Customer_Signature_Bool_Exp>;
  signatures_aggregate?: InputMaybe<Customer_Signature_Aggregate_Bool_Exp>;
  step_length?: InputMaybe<Float8_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_preferences?: InputMaybe<Preferences_User_Preferences_Bool_Exp>;
  vendor?: InputMaybe<Organisation_Bool_Exp>;
  vendor_id?: InputMaybe<String_Comparison_Exp>;
  vendor_preferences?: InputMaybe<Preferences_Vendor_Preferences_Bool_Exp>;
  weight?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer.answer" */
export enum Customer_Answer_Constraint {
  /** unique or primary key constraint on columns "short_id" */
  AnswerShortIdKey = 'answer_short_id_key',
  /** unique or primary key constraint on columns "id" */
  UserResponsePkey = 'user_response_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Customer_Answer_Delete_At_Path_Input = {
  /** Article groups used in this answer (see: public.article_groups) */
  article_groups?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Customer_Answer_Delete_Elem_Input = {
  /** Article groups used in this answer (see: public.article_groups) */
  article_groups?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Customer_Answer_Delete_Key_Input = {
  /** Article groups used in this answer (see: public.article_groups) */
  article_groups?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "customer.answer_foot_problems" */
export type Customer_Answer_Foot_Problems = {
  __typename?: 'customer_answer_foot_problems';
  /** An object relationship */
  answer: Customer_Answer;
  answer_id: Scalars['String'];
  /** An object relationship */
  foot_problem: Answer_Enums_Foot_Problems;
  foot_problem_id: Answer_Enums_Foot_Problems_Enum;
};

/** aggregated selection of "customer.answer_foot_problems" */
export type Customer_Answer_Foot_Problems_Aggregate = {
  __typename?: 'customer_answer_foot_problems_aggregate';
  aggregate?: Maybe<Customer_Answer_Foot_Problems_Aggregate_Fields>;
  nodes: Array<Customer_Answer_Foot_Problems>;
};

export type Customer_Answer_Foot_Problems_Aggregate_Bool_Exp = {
  count?: InputMaybe<Customer_Answer_Foot_Problems_Aggregate_Bool_Exp_Count>;
};

export type Customer_Answer_Foot_Problems_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Customer_Answer_Foot_Problems_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Answer_Foot_Problems_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customer.answer_foot_problems" */
export type Customer_Answer_Foot_Problems_Aggregate_Fields = {
  __typename?: 'customer_answer_foot_problems_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Customer_Answer_Foot_Problems_Max_Fields>;
  min?: Maybe<Customer_Answer_Foot_Problems_Min_Fields>;
};


/** aggregate fields of "customer.answer_foot_problems" */
export type Customer_Answer_Foot_Problems_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Answer_Foot_Problems_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customer.answer_foot_problems" */
export type Customer_Answer_Foot_Problems_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Customer_Answer_Foot_Problems_Max_Order_By>;
  min?: InputMaybe<Customer_Answer_Foot_Problems_Min_Order_By>;
};

/** input type for inserting array relation for remote table "customer.answer_foot_problems" */
export type Customer_Answer_Foot_Problems_Arr_Rel_Insert_Input = {
  data: Array<Customer_Answer_Foot_Problems_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_Answer_Foot_Problems_On_Conflict>;
};

/** Boolean expression to filter rows from the table "customer.answer_foot_problems". All fields are combined with a logical 'AND'. */
export type Customer_Answer_Foot_Problems_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Answer_Foot_Problems_Bool_Exp>>;
  _not?: InputMaybe<Customer_Answer_Foot_Problems_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Answer_Foot_Problems_Bool_Exp>>;
  answer?: InputMaybe<Customer_Answer_Bool_Exp>;
  answer_id?: InputMaybe<String_Comparison_Exp>;
  foot_problem?: InputMaybe<Answer_Enums_Foot_Problems_Bool_Exp>;
  foot_problem_id?: InputMaybe<Answer_Enums_Foot_Problems_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer.answer_foot_problems" */
export enum Customer_Answer_Foot_Problems_Constraint {
  /** unique or primary key constraint on columns "answer_id", "foot_problem_id" */
  UserAnswerFootProblemsPkey = 'user_answer_foot_problems_pkey'
}

/** input type for inserting data into table "customer.answer_foot_problems" */
export type Customer_Answer_Foot_Problems_Insert_Input = {
  answer?: InputMaybe<Customer_Answer_Obj_Rel_Insert_Input>;
  answer_id?: InputMaybe<Scalars['String']>;
  foot_problem?: InputMaybe<Answer_Enums_Foot_Problems_Obj_Rel_Insert_Input>;
  foot_problem_id?: InputMaybe<Answer_Enums_Foot_Problems_Enum>;
};

/** aggregate max on columns */
export type Customer_Answer_Foot_Problems_Max_Fields = {
  __typename?: 'customer_answer_foot_problems_max_fields';
  answer_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "customer.answer_foot_problems" */
export type Customer_Answer_Foot_Problems_Max_Order_By = {
  answer_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Customer_Answer_Foot_Problems_Min_Fields = {
  __typename?: 'customer_answer_foot_problems_min_fields';
  answer_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "customer.answer_foot_problems" */
export type Customer_Answer_Foot_Problems_Min_Order_By = {
  answer_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "customer.answer_foot_problems" */
export type Customer_Answer_Foot_Problems_Mutation_Response = {
  __typename?: 'customer_answer_foot_problems_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Answer_Foot_Problems>;
};

/** on_conflict condition type for table "customer.answer_foot_problems" */
export type Customer_Answer_Foot_Problems_On_Conflict = {
  constraint: Customer_Answer_Foot_Problems_Constraint;
  update_columns?: Array<Customer_Answer_Foot_Problems_Update_Column>;
  where?: InputMaybe<Customer_Answer_Foot_Problems_Bool_Exp>;
};

/** Ordering options when selecting data from "customer.answer_foot_problems". */
export type Customer_Answer_Foot_Problems_Order_By = {
  answer?: InputMaybe<Customer_Answer_Order_By>;
  answer_id?: InputMaybe<Order_By>;
  foot_problem?: InputMaybe<Answer_Enums_Foot_Problems_Order_By>;
  foot_problem_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customer.answer_foot_problems */
export type Customer_Answer_Foot_Problems_Pk_Columns_Input = {
  answer_id: Scalars['String'];
  foot_problem_id: Answer_Enums_Foot_Problems_Enum;
};

/** select columns of table "customer.answer_foot_problems" */
export enum Customer_Answer_Foot_Problems_Select_Column {
  /** column name */
  AnswerId = 'answer_id',
  /** column name */
  FootProblemId = 'foot_problem_id'
}

/** input type for updating data in table "customer.answer_foot_problems" */
export type Customer_Answer_Foot_Problems_Set_Input = {
  answer_id?: InputMaybe<Scalars['String']>;
  foot_problem_id?: InputMaybe<Answer_Enums_Foot_Problems_Enum>;
};

/** Streaming cursor of the table "customer_answer_foot_problems" */
export type Customer_Answer_Foot_Problems_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Answer_Foot_Problems_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Answer_Foot_Problems_Stream_Cursor_Value_Input = {
  answer_id?: InputMaybe<Scalars['String']>;
  foot_problem_id?: InputMaybe<Answer_Enums_Foot_Problems_Enum>;
};

/** update columns of table "customer.answer_foot_problems" */
export enum Customer_Answer_Foot_Problems_Update_Column {
  /** column name */
  AnswerId = 'answer_id',
  /** column name */
  FootProblemId = 'foot_problem_id'
}

export type Customer_Answer_Foot_Problems_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Answer_Foot_Problems_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Answer_Foot_Problems_Bool_Exp;
};

/** columns and relationships of "customer.answer_hand_problems" */
export type Customer_Answer_Hand_Problems = {
  __typename?: 'customer_answer_hand_problems';
  /** An object relationship */
  answer: Customer_Answer;
  answer_id: Scalars['String'];
  /** An object relationship */
  hand_problem: Answer_Enums_Hand_Problems;
  hand_problem_id: Answer_Enums_Hand_Problems_Enum;
};

/** aggregated selection of "customer.answer_hand_problems" */
export type Customer_Answer_Hand_Problems_Aggregate = {
  __typename?: 'customer_answer_hand_problems_aggregate';
  aggregate?: Maybe<Customer_Answer_Hand_Problems_Aggregate_Fields>;
  nodes: Array<Customer_Answer_Hand_Problems>;
};

export type Customer_Answer_Hand_Problems_Aggregate_Bool_Exp = {
  count?: InputMaybe<Customer_Answer_Hand_Problems_Aggregate_Bool_Exp_Count>;
};

export type Customer_Answer_Hand_Problems_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Customer_Answer_Hand_Problems_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Answer_Hand_Problems_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customer.answer_hand_problems" */
export type Customer_Answer_Hand_Problems_Aggregate_Fields = {
  __typename?: 'customer_answer_hand_problems_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Customer_Answer_Hand_Problems_Max_Fields>;
  min?: Maybe<Customer_Answer_Hand_Problems_Min_Fields>;
};


/** aggregate fields of "customer.answer_hand_problems" */
export type Customer_Answer_Hand_Problems_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Answer_Hand_Problems_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customer.answer_hand_problems" */
export type Customer_Answer_Hand_Problems_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Customer_Answer_Hand_Problems_Max_Order_By>;
  min?: InputMaybe<Customer_Answer_Hand_Problems_Min_Order_By>;
};

/** input type for inserting array relation for remote table "customer.answer_hand_problems" */
export type Customer_Answer_Hand_Problems_Arr_Rel_Insert_Input = {
  data: Array<Customer_Answer_Hand_Problems_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_Answer_Hand_Problems_On_Conflict>;
};

/** Boolean expression to filter rows from the table "customer.answer_hand_problems". All fields are combined with a logical 'AND'. */
export type Customer_Answer_Hand_Problems_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Answer_Hand_Problems_Bool_Exp>>;
  _not?: InputMaybe<Customer_Answer_Hand_Problems_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Answer_Hand_Problems_Bool_Exp>>;
  answer?: InputMaybe<Customer_Answer_Bool_Exp>;
  answer_id?: InputMaybe<String_Comparison_Exp>;
  hand_problem?: InputMaybe<Answer_Enums_Hand_Problems_Bool_Exp>;
  hand_problem_id?: InputMaybe<Answer_Enums_Hand_Problems_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer.answer_hand_problems" */
export enum Customer_Answer_Hand_Problems_Constraint {
  /** unique or primary key constraint on columns "hand_problem_id", "answer_id" */
  UserAnswerHandProblemsPkey = 'user_answer_hand_problems_pkey'
}

/** input type for inserting data into table "customer.answer_hand_problems" */
export type Customer_Answer_Hand_Problems_Insert_Input = {
  answer?: InputMaybe<Customer_Answer_Obj_Rel_Insert_Input>;
  answer_id?: InputMaybe<Scalars['String']>;
  hand_problem?: InputMaybe<Answer_Enums_Hand_Problems_Obj_Rel_Insert_Input>;
  hand_problem_id?: InputMaybe<Answer_Enums_Hand_Problems_Enum>;
};

/** aggregate max on columns */
export type Customer_Answer_Hand_Problems_Max_Fields = {
  __typename?: 'customer_answer_hand_problems_max_fields';
  answer_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "customer.answer_hand_problems" */
export type Customer_Answer_Hand_Problems_Max_Order_By = {
  answer_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Customer_Answer_Hand_Problems_Min_Fields = {
  __typename?: 'customer_answer_hand_problems_min_fields';
  answer_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "customer.answer_hand_problems" */
export type Customer_Answer_Hand_Problems_Min_Order_By = {
  answer_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "customer.answer_hand_problems" */
export type Customer_Answer_Hand_Problems_Mutation_Response = {
  __typename?: 'customer_answer_hand_problems_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Answer_Hand_Problems>;
};

/** on_conflict condition type for table "customer.answer_hand_problems" */
export type Customer_Answer_Hand_Problems_On_Conflict = {
  constraint: Customer_Answer_Hand_Problems_Constraint;
  update_columns?: Array<Customer_Answer_Hand_Problems_Update_Column>;
  where?: InputMaybe<Customer_Answer_Hand_Problems_Bool_Exp>;
};

/** Ordering options when selecting data from "customer.answer_hand_problems". */
export type Customer_Answer_Hand_Problems_Order_By = {
  answer?: InputMaybe<Customer_Answer_Order_By>;
  answer_id?: InputMaybe<Order_By>;
  hand_problem?: InputMaybe<Answer_Enums_Hand_Problems_Order_By>;
  hand_problem_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customer.answer_hand_problems */
export type Customer_Answer_Hand_Problems_Pk_Columns_Input = {
  answer_id: Scalars['String'];
  hand_problem_id: Answer_Enums_Hand_Problems_Enum;
};

/** select columns of table "customer.answer_hand_problems" */
export enum Customer_Answer_Hand_Problems_Select_Column {
  /** column name */
  AnswerId = 'answer_id',
  /** column name */
  HandProblemId = 'hand_problem_id'
}

/** input type for updating data in table "customer.answer_hand_problems" */
export type Customer_Answer_Hand_Problems_Set_Input = {
  answer_id?: InputMaybe<Scalars['String']>;
  hand_problem_id?: InputMaybe<Answer_Enums_Hand_Problems_Enum>;
};

/** Streaming cursor of the table "customer_answer_hand_problems" */
export type Customer_Answer_Hand_Problems_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Answer_Hand_Problems_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Answer_Hand_Problems_Stream_Cursor_Value_Input = {
  answer_id?: InputMaybe<Scalars['String']>;
  hand_problem_id?: InputMaybe<Answer_Enums_Hand_Problems_Enum>;
};

/** update columns of table "customer.answer_hand_problems" */
export enum Customer_Answer_Hand_Problems_Update_Column {
  /** column name */
  AnswerId = 'answer_id',
  /** column name */
  HandProblemId = 'hand_problem_id'
}

export type Customer_Answer_Hand_Problems_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Answer_Hand_Problems_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Answer_Hand_Problems_Bool_Exp;
};

/** input type for incrementing numeric columns in table "customer.answer" */
export type Customer_Answer_Inc_Input = {
  age?: InputMaybe<Scalars['Int']>;
  arm_length_cm?: InputMaybe<Scalars['float8']>;
  /** Body height in centimetres */
  body_height?: InputMaybe<Scalars['float8']>;
  commission?: InputMaybe<Scalars['float8']>;
  /** distance from the tip of the middle finger to the middle of the hand (corresponds to hand_height in the cv table) */
  grip_width_cm?: InputMaybe<Scalars['float8']>;
  /** distance across the hand over the knuckles */
  hand_width_cm?: InputMaybe<Scalars['float8']>;
  hip_circumference?: InputMaybe<Scalars['float8']>;
  hip_width?: InputMaybe<Scalars['float8']>;
  knee_height_cm?: InputMaybe<Scalars['float8']>;
  /** corrensponds to "sitbone_distance" in cv table "sitbones" */
  seat_bone_distance_cm?: InputMaybe<Scalars['Int']>;
  shoe_size?: InputMaybe<Scalars['Int']>;
  shoulder_width_cm?: InputMaybe<Scalars['float8']>;
  /** crotch length in centimetres (distance from hip to the ground) */
  step_length?: InputMaybe<Scalars['float8']>;
  weight?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "customer.answer" */
export type Customer_Answer_Insert_Input = {
  User?: InputMaybe<User_Obj_Rel_Insert_Input>;
  additional_data_processing_consent_given_at?: InputMaybe<Scalars['timestamptz']>;
  age?: InputMaybe<Scalars['Int']>;
  arm_length_cm?: InputMaybe<Scalars['float8']>;
  /** Article groups used in this answer (see: public.article_groups) */
  article_groups?: InputMaybe<Scalars['jsonb']>;
  bicycle_type?: InputMaybe<Answer_Enums_Bicycle_Type_Obj_Rel_Insert_Input>;
  bicycle_type_id?: InputMaybe<Answer_Enums_Bicycle_Type_Enum>;
  /** Body height in centimetres */
  body_height?: InputMaybe<Scalars['float8']>;
  comment?: InputMaybe<Scalars['String']>;
  commission?: InputMaybe<Scalars['float8']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_name?: InputMaybe<Scalars['String']>;
  cv_body?: InputMaybe<Cv_Body_Arr_Rel_Insert_Input>;
  cv_handmarks?: InputMaybe<Cv_Handmarks_Arr_Rel_Insert_Input>;
  cv_sitbones?: InputMaybe<Cv_Sitbones_Arr_Rel_Insert_Input>;
  driving_duration?: InputMaybe<Answer_Enums_Driving_Duration_Obj_Rel_Insert_Input>;
  driving_duration_id?: InputMaybe<Answer_Enums_Driving_Duration_Enum>;
  driving_frequency?: InputMaybe<Answer_Enums_Driving_Frequency_Obj_Rel_Insert_Input>;
  driving_frequency_id?: InputMaybe<Answer_Enums_Driving_Frequency_Enum>;
  e_bike?: InputMaybe<Answer_Enums_E_Bike_Obj_Rel_Insert_Input>;
  e_bike_id?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<Metric_App_Events_Arr_Rel_Insert_Input>;
  foot_position?: InputMaybe<Answer_Enums_Foot_Position_Obj_Rel_Insert_Input>;
  foot_position_id?: InputMaybe<Answer_Enums_Foot_Position_Enum>;
  foot_problems?: InputMaybe<Customer_Answer_Foot_Problems_Arr_Rel_Insert_Input>;
  foot_type?: InputMaybe<Answer_Enums_Foot_Type_Obj_Rel_Insert_Input>;
  foot_type_id?: InputMaybe<Answer_Enums_Foot_Type_Enum>;
  /** distance from the tip of the middle finger to the middle of the hand (corresponds to hand_height in the cv table) */
  grip_width_cm?: InputMaybe<Scalars['float8']>;
  /** Size of the grip (S, M, L) */
  gripping_distance?: InputMaybe<Answer_Enums_Gripping_Distance_Enum>;
  hand_problems?: InputMaybe<Customer_Answer_Hand_Problems_Arr_Rel_Insert_Input>;
  /** distance across the hand over the knuckles */
  hand_width_cm?: InputMaybe<Scalars['float8']>;
  hip_circumference?: InputMaybe<Scalars['float8']>;
  hip_width?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['String']>;
  knee_height_cm?: InputMaybe<Scalars['float8']>;
  leg_axis?: InputMaybe<Answer_Enums_Leg_Axis_Obj_Rel_Insert_Input>;
  leg_axis_id?: InputMaybe<Answer_Enums_Leg_Axis_Enum>;
  pelvic_problems?: InputMaybe<Customer_Answer_Pelvic_Problems_Arr_Rel_Insert_Input>;
  question_durations?: InputMaybe<Metric_Question_Duration_Arr_Rel_Insert_Input>;
  recommened_own_articles?: InputMaybe<Recommendation_Own_Articles_Arr_Rel_Insert_Input>;
  /** corrensponds to "sitbone_distance" in cv table "sitbones" */
  seat_bone_distance_cm?: InputMaybe<Scalars['Int']>;
  seat_position?: InputMaybe<Answer_Enums_Seat_Position_Obj_Rel_Insert_Input>;
  seat_position_id?: InputMaybe<Answer_Enums_Seat_Position_Enum>;
  sex?: InputMaybe<Answer_Enums_Sex_Obj_Rel_Insert_Input>;
  sex_id?: InputMaybe<Answer_Enums_Sex_Enum>;
  shoe_size?: InputMaybe<Scalars['Int']>;
  short_id?: InputMaybe<Scalars['String']>;
  shoulder_width_cm?: InputMaybe<Scalars['float8']>;
  signatures?: InputMaybe<Customer_Signature_Arr_Rel_Insert_Input>;
  /** crotch length in centimetres (distance from hip to the ground) */
  step_length?: InputMaybe<Scalars['float8']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_preferences?: InputMaybe<Preferences_User_Preferences_Obj_Rel_Insert_Input>;
  vendor?: InputMaybe<Organisation_Obj_Rel_Insert_Input>;
  vendor_id?: InputMaybe<Scalars['String']>;
  vendor_preferences?: InputMaybe<Preferences_Vendor_Preferences_Obj_Rel_Insert_Input>;
  weight?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Customer_Answer_Max_Fields = {
  __typename?: 'customer_answer_max_fields';
  additional_data_processing_consent_given_at?: Maybe<Scalars['timestamptz']>;
  age?: Maybe<Scalars['Int']>;
  arm_length_cm?: Maybe<Scalars['float8']>;
  /** Body height in centimetres */
  body_height?: Maybe<Scalars['float8']>;
  comment?: Maybe<Scalars['String']>;
  commission?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  /** distance from the tip of the middle finger to the middle of the hand (corresponds to hand_height in the cv table) */
  grip_width_cm?: Maybe<Scalars['float8']>;
  /** distance across the hand over the knuckles */
  hand_width_cm?: Maybe<Scalars['float8']>;
  hip_circumference?: Maybe<Scalars['float8']>;
  hip_width?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['String']>;
  knee_height_cm?: Maybe<Scalars['float8']>;
  /** corrensponds to "sitbone_distance" in cv table "sitbones" */
  seat_bone_distance_cm?: Maybe<Scalars['Int']>;
  shoe_size?: Maybe<Scalars['Int']>;
  short_id?: Maybe<Scalars['String']>;
  shoulder_width_cm?: Maybe<Scalars['float8']>;
  /** crotch length in centimetres (distance from hip to the ground) */
  step_length?: Maybe<Scalars['float8']>;
  user_id?: Maybe<Scalars['uuid']>;
  vendor_id?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Customer_Answer_Min_Fields = {
  __typename?: 'customer_answer_min_fields';
  additional_data_processing_consent_given_at?: Maybe<Scalars['timestamptz']>;
  age?: Maybe<Scalars['Int']>;
  arm_length_cm?: Maybe<Scalars['float8']>;
  /** Body height in centimetres */
  body_height?: Maybe<Scalars['float8']>;
  comment?: Maybe<Scalars['String']>;
  commission?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  /** distance from the tip of the middle finger to the middle of the hand (corresponds to hand_height in the cv table) */
  grip_width_cm?: Maybe<Scalars['float8']>;
  /** distance across the hand over the knuckles */
  hand_width_cm?: Maybe<Scalars['float8']>;
  hip_circumference?: Maybe<Scalars['float8']>;
  hip_width?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['String']>;
  knee_height_cm?: Maybe<Scalars['float8']>;
  /** corrensponds to "sitbone_distance" in cv table "sitbones" */
  seat_bone_distance_cm?: Maybe<Scalars['Int']>;
  shoe_size?: Maybe<Scalars['Int']>;
  short_id?: Maybe<Scalars['String']>;
  shoulder_width_cm?: Maybe<Scalars['float8']>;
  /** crotch length in centimetres (distance from hip to the ground) */
  step_length?: Maybe<Scalars['float8']>;
  user_id?: Maybe<Scalars['uuid']>;
  vendor_id?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "customer.answer" */
export type Customer_Answer_Mutation_Response = {
  __typename?: 'customer_answer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Answer>;
};

/** input type for inserting object relation for remote table "customer.answer" */
export type Customer_Answer_Obj_Rel_Insert_Input = {
  data: Customer_Answer_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_Answer_On_Conflict>;
};

/** on_conflict condition type for table "customer.answer" */
export type Customer_Answer_On_Conflict = {
  constraint: Customer_Answer_Constraint;
  update_columns?: Array<Customer_Answer_Update_Column>;
  where?: InputMaybe<Customer_Answer_Bool_Exp>;
};

/** Ordering options when selecting data from "customer.answer". */
export type Customer_Answer_Order_By = {
  User?: InputMaybe<User_Order_By>;
  additional_data_processing_consent_given_at?: InputMaybe<Order_By>;
  age?: InputMaybe<Order_By>;
  arm_length_cm?: InputMaybe<Order_By>;
  article_groups?: InputMaybe<Order_By>;
  bicycle_type?: InputMaybe<Answer_Enums_Bicycle_Type_Order_By>;
  bicycle_type_id?: InputMaybe<Order_By>;
  body_height?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  commission?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer_name?: InputMaybe<Order_By>;
  cv_body_aggregate?: InputMaybe<Cv_Body_Aggregate_Order_By>;
  cv_handmarks_aggregate?: InputMaybe<Cv_Handmarks_Aggregate_Order_By>;
  cv_sitbones_aggregate?: InputMaybe<Cv_Sitbones_Aggregate_Order_By>;
  driving_duration?: InputMaybe<Answer_Enums_Driving_Duration_Order_By>;
  driving_duration_id?: InputMaybe<Order_By>;
  driving_frequency?: InputMaybe<Answer_Enums_Driving_Frequency_Order_By>;
  driving_frequency_id?: InputMaybe<Order_By>;
  e_bike?: InputMaybe<Answer_Enums_E_Bike_Order_By>;
  e_bike_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  events_aggregate?: InputMaybe<Metric_App_Events_Aggregate_Order_By>;
  foot_position?: InputMaybe<Answer_Enums_Foot_Position_Order_By>;
  foot_position_id?: InputMaybe<Order_By>;
  foot_problems_aggregate?: InputMaybe<Customer_Answer_Foot_Problems_Aggregate_Order_By>;
  foot_type?: InputMaybe<Answer_Enums_Foot_Type_Order_By>;
  foot_type_id?: InputMaybe<Order_By>;
  grip_width_cm?: InputMaybe<Order_By>;
  gripping_distance?: InputMaybe<Order_By>;
  hand_problems_aggregate?: InputMaybe<Customer_Answer_Hand_Problems_Aggregate_Order_By>;
  hand_width_cm?: InputMaybe<Order_By>;
  hip_circumference?: InputMaybe<Order_By>;
  hip_width?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  knee_height_cm?: InputMaybe<Order_By>;
  leg_axis?: InputMaybe<Answer_Enums_Leg_Axis_Order_By>;
  leg_axis_id?: InputMaybe<Order_By>;
  pelvic_problems_aggregate?: InputMaybe<Customer_Answer_Pelvic_Problems_Aggregate_Order_By>;
  question_durations_aggregate?: InputMaybe<Metric_Question_Duration_Aggregate_Order_By>;
  recommened_own_articles_aggregate?: InputMaybe<Recommendation_Own_Articles_Aggregate_Order_By>;
  seat_bone_distance_cm?: InputMaybe<Order_By>;
  seat_position?: InputMaybe<Answer_Enums_Seat_Position_Order_By>;
  seat_position_id?: InputMaybe<Order_By>;
  sex?: InputMaybe<Answer_Enums_Sex_Order_By>;
  sex_id?: InputMaybe<Order_By>;
  shoe_size?: InputMaybe<Order_By>;
  short_id?: InputMaybe<Order_By>;
  shoulder_width_cm?: InputMaybe<Order_By>;
  signatures_aggregate?: InputMaybe<Customer_Signature_Aggregate_Order_By>;
  step_length?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_preferences?: InputMaybe<Preferences_User_Preferences_Order_By>;
  vendor?: InputMaybe<Organisation_Order_By>;
  vendor_id?: InputMaybe<Order_By>;
  vendor_preferences?: InputMaybe<Preferences_Vendor_Preferences_Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** columns and relationships of "customer.answer_pelvic_problems" */
export type Customer_Answer_Pelvic_Problems = {
  __typename?: 'customer_answer_pelvic_problems';
  /** An object relationship */
  answer: Customer_Answer;
  answer_id: Scalars['String'];
  /** An object relationship */
  pelvic_problem: Answer_Enums_Pelvic_Problems;
  pelvic_problem_id: Answer_Enums_Pelvic_Problems_Enum;
};

/** aggregated selection of "customer.answer_pelvic_problems" */
export type Customer_Answer_Pelvic_Problems_Aggregate = {
  __typename?: 'customer_answer_pelvic_problems_aggregate';
  aggregate?: Maybe<Customer_Answer_Pelvic_Problems_Aggregate_Fields>;
  nodes: Array<Customer_Answer_Pelvic_Problems>;
};

export type Customer_Answer_Pelvic_Problems_Aggregate_Bool_Exp = {
  count?: InputMaybe<Customer_Answer_Pelvic_Problems_Aggregate_Bool_Exp_Count>;
};

export type Customer_Answer_Pelvic_Problems_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Customer_Answer_Pelvic_Problems_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Answer_Pelvic_Problems_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customer.answer_pelvic_problems" */
export type Customer_Answer_Pelvic_Problems_Aggregate_Fields = {
  __typename?: 'customer_answer_pelvic_problems_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Customer_Answer_Pelvic_Problems_Max_Fields>;
  min?: Maybe<Customer_Answer_Pelvic_Problems_Min_Fields>;
};


/** aggregate fields of "customer.answer_pelvic_problems" */
export type Customer_Answer_Pelvic_Problems_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Answer_Pelvic_Problems_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customer.answer_pelvic_problems" */
export type Customer_Answer_Pelvic_Problems_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Customer_Answer_Pelvic_Problems_Max_Order_By>;
  min?: InputMaybe<Customer_Answer_Pelvic_Problems_Min_Order_By>;
};

/** input type for inserting array relation for remote table "customer.answer_pelvic_problems" */
export type Customer_Answer_Pelvic_Problems_Arr_Rel_Insert_Input = {
  data: Array<Customer_Answer_Pelvic_Problems_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_Answer_Pelvic_Problems_On_Conflict>;
};

/** Boolean expression to filter rows from the table "customer.answer_pelvic_problems". All fields are combined with a logical 'AND'. */
export type Customer_Answer_Pelvic_Problems_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Answer_Pelvic_Problems_Bool_Exp>>;
  _not?: InputMaybe<Customer_Answer_Pelvic_Problems_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Answer_Pelvic_Problems_Bool_Exp>>;
  answer?: InputMaybe<Customer_Answer_Bool_Exp>;
  answer_id?: InputMaybe<String_Comparison_Exp>;
  pelvic_problem?: InputMaybe<Answer_Enums_Pelvic_Problems_Bool_Exp>;
  pelvic_problem_id?: InputMaybe<Answer_Enums_Pelvic_Problems_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer.answer_pelvic_problems" */
export enum Customer_Answer_Pelvic_Problems_Constraint {
  /** unique or primary key constraint on columns "answer_id", "pelvic_problem_id" */
  UserAnswerPelvicProblemsPkey = 'user_answer_pelvic_problems_pkey'
}

/** input type for inserting data into table "customer.answer_pelvic_problems" */
export type Customer_Answer_Pelvic_Problems_Insert_Input = {
  answer?: InputMaybe<Customer_Answer_Obj_Rel_Insert_Input>;
  answer_id?: InputMaybe<Scalars['String']>;
  pelvic_problem?: InputMaybe<Answer_Enums_Pelvic_Problems_Obj_Rel_Insert_Input>;
  pelvic_problem_id?: InputMaybe<Answer_Enums_Pelvic_Problems_Enum>;
};

/** aggregate max on columns */
export type Customer_Answer_Pelvic_Problems_Max_Fields = {
  __typename?: 'customer_answer_pelvic_problems_max_fields';
  answer_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "customer.answer_pelvic_problems" */
export type Customer_Answer_Pelvic_Problems_Max_Order_By = {
  answer_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Customer_Answer_Pelvic_Problems_Min_Fields = {
  __typename?: 'customer_answer_pelvic_problems_min_fields';
  answer_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "customer.answer_pelvic_problems" */
export type Customer_Answer_Pelvic_Problems_Min_Order_By = {
  answer_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "customer.answer_pelvic_problems" */
export type Customer_Answer_Pelvic_Problems_Mutation_Response = {
  __typename?: 'customer_answer_pelvic_problems_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Answer_Pelvic_Problems>;
};

/** on_conflict condition type for table "customer.answer_pelvic_problems" */
export type Customer_Answer_Pelvic_Problems_On_Conflict = {
  constraint: Customer_Answer_Pelvic_Problems_Constraint;
  update_columns?: Array<Customer_Answer_Pelvic_Problems_Update_Column>;
  where?: InputMaybe<Customer_Answer_Pelvic_Problems_Bool_Exp>;
};

/** Ordering options when selecting data from "customer.answer_pelvic_problems". */
export type Customer_Answer_Pelvic_Problems_Order_By = {
  answer?: InputMaybe<Customer_Answer_Order_By>;
  answer_id?: InputMaybe<Order_By>;
  pelvic_problem?: InputMaybe<Answer_Enums_Pelvic_Problems_Order_By>;
  pelvic_problem_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customer.answer_pelvic_problems */
export type Customer_Answer_Pelvic_Problems_Pk_Columns_Input = {
  answer_id: Scalars['String'];
  pelvic_problem_id: Answer_Enums_Pelvic_Problems_Enum;
};

/** select columns of table "customer.answer_pelvic_problems" */
export enum Customer_Answer_Pelvic_Problems_Select_Column {
  /** column name */
  AnswerId = 'answer_id',
  /** column name */
  PelvicProblemId = 'pelvic_problem_id'
}

/** input type for updating data in table "customer.answer_pelvic_problems" */
export type Customer_Answer_Pelvic_Problems_Set_Input = {
  answer_id?: InputMaybe<Scalars['String']>;
  pelvic_problem_id?: InputMaybe<Answer_Enums_Pelvic_Problems_Enum>;
};

/** Streaming cursor of the table "customer_answer_pelvic_problems" */
export type Customer_Answer_Pelvic_Problems_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Answer_Pelvic_Problems_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Answer_Pelvic_Problems_Stream_Cursor_Value_Input = {
  answer_id?: InputMaybe<Scalars['String']>;
  pelvic_problem_id?: InputMaybe<Answer_Enums_Pelvic_Problems_Enum>;
};

/** update columns of table "customer.answer_pelvic_problems" */
export enum Customer_Answer_Pelvic_Problems_Update_Column {
  /** column name */
  AnswerId = 'answer_id',
  /** column name */
  PelvicProblemId = 'pelvic_problem_id'
}

export type Customer_Answer_Pelvic_Problems_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Answer_Pelvic_Problems_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Answer_Pelvic_Problems_Bool_Exp;
};

/** primary key columns input for table: customer.answer */
export type Customer_Answer_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Customer_Answer_Prepend_Input = {
  /** Article groups used in this answer (see: public.article_groups) */
  article_groups?: InputMaybe<Scalars['jsonb']>;
};

/** In this table stores the results of a customer answer (i.e. calculated values from the static answers) */
export type Customer_Answer_Results = {
  __typename?: 'customer_answer_results';
  answer_id: Scalars['String'];
  bike_body_height?: Maybe<Scalars['String']>;
  body_height?: Maybe<Scalars['String']>;
  clothing_fitting?: Maybe<Scalars['String']>;
  glove_size?: Maybe<Scalars['String']>;
  grip_size?: Maybe<Scalars['String']>;
  hip_width?: Maybe<Scalars['String']>;
  insole_type?: Maybe<Scalars['String']>;
  jersey_size?: Maybe<Scalars['String']>;
  pedal_size?: Maybe<Scalars['String']>;
  reach?: Maybe<Scalars['float8']>;
  saddle_size?: Maybe<Scalars['String']>;
  shorts_size?: Maybe<Scalars['String']>;
  stack?: Maybe<Scalars['float8']>;
  str_ratio?: Maybe<Scalars['float8']>;
};

/** aggregated selection of "customer.answer_results" */
export type Customer_Answer_Results_Aggregate = {
  __typename?: 'customer_answer_results_aggregate';
  aggregate?: Maybe<Customer_Answer_Results_Aggregate_Fields>;
  nodes: Array<Customer_Answer_Results>;
};

/** aggregate fields of "customer.answer_results" */
export type Customer_Answer_Results_Aggregate_Fields = {
  __typename?: 'customer_answer_results_aggregate_fields';
  avg?: Maybe<Customer_Answer_Results_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Answer_Results_Max_Fields>;
  min?: Maybe<Customer_Answer_Results_Min_Fields>;
  stddev?: Maybe<Customer_Answer_Results_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Answer_Results_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Answer_Results_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Answer_Results_Sum_Fields>;
  var_pop?: Maybe<Customer_Answer_Results_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Answer_Results_Var_Samp_Fields>;
  variance?: Maybe<Customer_Answer_Results_Variance_Fields>;
};


/** aggregate fields of "customer.answer_results" */
export type Customer_Answer_Results_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Answer_Results_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Customer_Answer_Results_Avg_Fields = {
  __typename?: 'customer_answer_results_avg_fields';
  reach?: Maybe<Scalars['Float']>;
  stack?: Maybe<Scalars['Float']>;
  str_ratio?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "customer.answer_results". All fields are combined with a logical 'AND'. */
export type Customer_Answer_Results_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Answer_Results_Bool_Exp>>;
  _not?: InputMaybe<Customer_Answer_Results_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Answer_Results_Bool_Exp>>;
  answer_id?: InputMaybe<String_Comparison_Exp>;
  bike_body_height?: InputMaybe<String_Comparison_Exp>;
  body_height?: InputMaybe<String_Comparison_Exp>;
  clothing_fitting?: InputMaybe<String_Comparison_Exp>;
  glove_size?: InputMaybe<String_Comparison_Exp>;
  grip_size?: InputMaybe<String_Comparison_Exp>;
  hip_width?: InputMaybe<String_Comparison_Exp>;
  insole_type?: InputMaybe<String_Comparison_Exp>;
  jersey_size?: InputMaybe<String_Comparison_Exp>;
  pedal_size?: InputMaybe<String_Comparison_Exp>;
  reach?: InputMaybe<Float8_Comparison_Exp>;
  saddle_size?: InputMaybe<String_Comparison_Exp>;
  shorts_size?: InputMaybe<String_Comparison_Exp>;
  stack?: InputMaybe<Float8_Comparison_Exp>;
  str_ratio?: InputMaybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer.answer_results" */
export enum Customer_Answer_Results_Constraint {
  /** unique or primary key constraint on columns "answer_id" */
  AnswerResultsPkey = 'answer_results_pkey'
}

/** input type for incrementing numeric columns in table "customer.answer_results" */
export type Customer_Answer_Results_Inc_Input = {
  reach?: InputMaybe<Scalars['float8']>;
  stack?: InputMaybe<Scalars['float8']>;
  str_ratio?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "customer.answer_results" */
export type Customer_Answer_Results_Insert_Input = {
  answer_id?: InputMaybe<Scalars['String']>;
  bike_body_height?: InputMaybe<Scalars['String']>;
  body_height?: InputMaybe<Scalars['String']>;
  clothing_fitting?: InputMaybe<Scalars['String']>;
  glove_size?: InputMaybe<Scalars['String']>;
  grip_size?: InputMaybe<Scalars['String']>;
  hip_width?: InputMaybe<Scalars['String']>;
  insole_type?: InputMaybe<Scalars['String']>;
  jersey_size?: InputMaybe<Scalars['String']>;
  pedal_size?: InputMaybe<Scalars['String']>;
  reach?: InputMaybe<Scalars['float8']>;
  saddle_size?: InputMaybe<Scalars['String']>;
  shorts_size?: InputMaybe<Scalars['String']>;
  stack?: InputMaybe<Scalars['float8']>;
  str_ratio?: InputMaybe<Scalars['float8']>;
};

/** aggregate max on columns */
export type Customer_Answer_Results_Max_Fields = {
  __typename?: 'customer_answer_results_max_fields';
  answer_id?: Maybe<Scalars['String']>;
  bike_body_height?: Maybe<Scalars['String']>;
  body_height?: Maybe<Scalars['String']>;
  clothing_fitting?: Maybe<Scalars['String']>;
  glove_size?: Maybe<Scalars['String']>;
  grip_size?: Maybe<Scalars['String']>;
  hip_width?: Maybe<Scalars['String']>;
  insole_type?: Maybe<Scalars['String']>;
  jersey_size?: Maybe<Scalars['String']>;
  pedal_size?: Maybe<Scalars['String']>;
  reach?: Maybe<Scalars['float8']>;
  saddle_size?: Maybe<Scalars['String']>;
  shorts_size?: Maybe<Scalars['String']>;
  stack?: Maybe<Scalars['float8']>;
  str_ratio?: Maybe<Scalars['float8']>;
};

/** aggregate min on columns */
export type Customer_Answer_Results_Min_Fields = {
  __typename?: 'customer_answer_results_min_fields';
  answer_id?: Maybe<Scalars['String']>;
  bike_body_height?: Maybe<Scalars['String']>;
  body_height?: Maybe<Scalars['String']>;
  clothing_fitting?: Maybe<Scalars['String']>;
  glove_size?: Maybe<Scalars['String']>;
  grip_size?: Maybe<Scalars['String']>;
  hip_width?: Maybe<Scalars['String']>;
  insole_type?: Maybe<Scalars['String']>;
  jersey_size?: Maybe<Scalars['String']>;
  pedal_size?: Maybe<Scalars['String']>;
  reach?: Maybe<Scalars['float8']>;
  saddle_size?: Maybe<Scalars['String']>;
  shorts_size?: Maybe<Scalars['String']>;
  stack?: Maybe<Scalars['float8']>;
  str_ratio?: Maybe<Scalars['float8']>;
};

/** response of any mutation on the table "customer.answer_results" */
export type Customer_Answer_Results_Mutation_Response = {
  __typename?: 'customer_answer_results_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Answer_Results>;
};

/** on_conflict condition type for table "customer.answer_results" */
export type Customer_Answer_Results_On_Conflict = {
  constraint: Customer_Answer_Results_Constraint;
  update_columns?: Array<Customer_Answer_Results_Update_Column>;
  where?: InputMaybe<Customer_Answer_Results_Bool_Exp>;
};

/** Ordering options when selecting data from "customer.answer_results". */
export type Customer_Answer_Results_Order_By = {
  answer_id?: InputMaybe<Order_By>;
  bike_body_height?: InputMaybe<Order_By>;
  body_height?: InputMaybe<Order_By>;
  clothing_fitting?: InputMaybe<Order_By>;
  glove_size?: InputMaybe<Order_By>;
  grip_size?: InputMaybe<Order_By>;
  hip_width?: InputMaybe<Order_By>;
  insole_type?: InputMaybe<Order_By>;
  jersey_size?: InputMaybe<Order_By>;
  pedal_size?: InputMaybe<Order_By>;
  reach?: InputMaybe<Order_By>;
  saddle_size?: InputMaybe<Order_By>;
  shorts_size?: InputMaybe<Order_By>;
  stack?: InputMaybe<Order_By>;
  str_ratio?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customer.answer_results */
export type Customer_Answer_Results_Pk_Columns_Input = {
  answer_id: Scalars['String'];
};

/** select columns of table "customer.answer_results" */
export enum Customer_Answer_Results_Select_Column {
  /** column name */
  AnswerId = 'answer_id',
  /** column name */
  BikeBodyHeight = 'bike_body_height',
  /** column name */
  BodyHeight = 'body_height',
  /** column name */
  ClothingFitting = 'clothing_fitting',
  /** column name */
  GloveSize = 'glove_size',
  /** column name */
  GripSize = 'grip_size',
  /** column name */
  HipWidth = 'hip_width',
  /** column name */
  InsoleType = 'insole_type',
  /** column name */
  JerseySize = 'jersey_size',
  /** column name */
  PedalSize = 'pedal_size',
  /** column name */
  Reach = 'reach',
  /** column name */
  SaddleSize = 'saddle_size',
  /** column name */
  ShortsSize = 'shorts_size',
  /** column name */
  Stack = 'stack',
  /** column name */
  StrRatio = 'str_ratio'
}

/** input type for updating data in table "customer.answer_results" */
export type Customer_Answer_Results_Set_Input = {
  answer_id?: InputMaybe<Scalars['String']>;
  bike_body_height?: InputMaybe<Scalars['String']>;
  body_height?: InputMaybe<Scalars['String']>;
  clothing_fitting?: InputMaybe<Scalars['String']>;
  glove_size?: InputMaybe<Scalars['String']>;
  grip_size?: InputMaybe<Scalars['String']>;
  hip_width?: InputMaybe<Scalars['String']>;
  insole_type?: InputMaybe<Scalars['String']>;
  jersey_size?: InputMaybe<Scalars['String']>;
  pedal_size?: InputMaybe<Scalars['String']>;
  reach?: InputMaybe<Scalars['float8']>;
  saddle_size?: InputMaybe<Scalars['String']>;
  shorts_size?: InputMaybe<Scalars['String']>;
  stack?: InputMaybe<Scalars['float8']>;
  str_ratio?: InputMaybe<Scalars['float8']>;
};

/** aggregate stddev on columns */
export type Customer_Answer_Results_Stddev_Fields = {
  __typename?: 'customer_answer_results_stddev_fields';
  reach?: Maybe<Scalars['Float']>;
  stack?: Maybe<Scalars['Float']>;
  str_ratio?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Customer_Answer_Results_Stddev_Pop_Fields = {
  __typename?: 'customer_answer_results_stddev_pop_fields';
  reach?: Maybe<Scalars['Float']>;
  stack?: Maybe<Scalars['Float']>;
  str_ratio?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Customer_Answer_Results_Stddev_Samp_Fields = {
  __typename?: 'customer_answer_results_stddev_samp_fields';
  reach?: Maybe<Scalars['Float']>;
  stack?: Maybe<Scalars['Float']>;
  str_ratio?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "customer_answer_results" */
export type Customer_Answer_Results_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Answer_Results_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Answer_Results_Stream_Cursor_Value_Input = {
  answer_id?: InputMaybe<Scalars['String']>;
  bike_body_height?: InputMaybe<Scalars['String']>;
  body_height?: InputMaybe<Scalars['String']>;
  clothing_fitting?: InputMaybe<Scalars['String']>;
  glove_size?: InputMaybe<Scalars['String']>;
  grip_size?: InputMaybe<Scalars['String']>;
  hip_width?: InputMaybe<Scalars['String']>;
  insole_type?: InputMaybe<Scalars['String']>;
  jersey_size?: InputMaybe<Scalars['String']>;
  pedal_size?: InputMaybe<Scalars['String']>;
  reach?: InputMaybe<Scalars['float8']>;
  saddle_size?: InputMaybe<Scalars['String']>;
  shorts_size?: InputMaybe<Scalars['String']>;
  stack?: InputMaybe<Scalars['float8']>;
  str_ratio?: InputMaybe<Scalars['float8']>;
};

/** aggregate sum on columns */
export type Customer_Answer_Results_Sum_Fields = {
  __typename?: 'customer_answer_results_sum_fields';
  reach?: Maybe<Scalars['float8']>;
  stack?: Maybe<Scalars['float8']>;
  str_ratio?: Maybe<Scalars['float8']>;
};

/** update columns of table "customer.answer_results" */
export enum Customer_Answer_Results_Update_Column {
  /** column name */
  AnswerId = 'answer_id',
  /** column name */
  BikeBodyHeight = 'bike_body_height',
  /** column name */
  BodyHeight = 'body_height',
  /** column name */
  ClothingFitting = 'clothing_fitting',
  /** column name */
  GloveSize = 'glove_size',
  /** column name */
  GripSize = 'grip_size',
  /** column name */
  HipWidth = 'hip_width',
  /** column name */
  InsoleType = 'insole_type',
  /** column name */
  JerseySize = 'jersey_size',
  /** column name */
  PedalSize = 'pedal_size',
  /** column name */
  Reach = 'reach',
  /** column name */
  SaddleSize = 'saddle_size',
  /** column name */
  ShortsSize = 'shorts_size',
  /** column name */
  Stack = 'stack',
  /** column name */
  StrRatio = 'str_ratio'
}

export type Customer_Answer_Results_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Customer_Answer_Results_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Answer_Results_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Answer_Results_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Customer_Answer_Results_Var_Pop_Fields = {
  __typename?: 'customer_answer_results_var_pop_fields';
  reach?: Maybe<Scalars['Float']>;
  stack?: Maybe<Scalars['Float']>;
  str_ratio?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Customer_Answer_Results_Var_Samp_Fields = {
  __typename?: 'customer_answer_results_var_samp_fields';
  reach?: Maybe<Scalars['Float']>;
  stack?: Maybe<Scalars['Float']>;
  str_ratio?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Customer_Answer_Results_Variance_Fields = {
  __typename?: 'customer_answer_results_variance_fields';
  reach?: Maybe<Scalars['Float']>;
  stack?: Maybe<Scalars['Float']>;
  str_ratio?: Maybe<Scalars['Float']>;
};

/** select columns of table "customer.answer" */
export enum Customer_Answer_Select_Column {
  /** column name */
  AdditionalDataProcessingConsentGivenAt = 'additional_data_processing_consent_given_at',
  /** column name */
  Age = 'age',
  /** column name */
  ArmLengthCm = 'arm_length_cm',
  /** column name */
  ArticleGroups = 'article_groups',
  /** column name */
  BicycleTypeId = 'bicycle_type_id',
  /** column name */
  BodyHeight = 'body_height',
  /** column name */
  Comment = 'comment',
  /** column name */
  Commission = 'commission',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerName = 'customer_name',
  /** column name */
  DrivingDurationId = 'driving_duration_id',
  /** column name */
  DrivingFrequencyId = 'driving_frequency_id',
  /** column name */
  EBikeId = 'e_bike_id',
  /** column name */
  Email = 'email',
  /** column name */
  FootPositionId = 'foot_position_id',
  /** column name */
  FootTypeId = 'foot_type_id',
  /** column name */
  GripWidthCm = 'grip_width_cm',
  /** column name */
  GrippingDistance = 'gripping_distance',
  /** column name */
  HandWidthCm = 'hand_width_cm',
  /** column name */
  HipCircumference = 'hip_circumference',
  /** column name */
  HipWidth = 'hip_width',
  /** column name */
  Id = 'id',
  /** column name */
  KneeHeightCm = 'knee_height_cm',
  /** column name */
  LegAxisId = 'leg_axis_id',
  /** column name */
  SeatBoneDistanceCm = 'seat_bone_distance_cm',
  /** column name */
  SeatPositionId = 'seat_position_id',
  /** column name */
  SexId = 'sex_id',
  /** column name */
  ShoeSize = 'shoe_size',
  /** column name */
  ShortId = 'short_id',
  /** column name */
  ShoulderWidthCm = 'shoulder_width_cm',
  /** column name */
  StepLength = 'step_length',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VendorId = 'vendor_id',
  /** column name */
  Weight = 'weight'
}

/** input type for updating data in table "customer.answer" */
export type Customer_Answer_Set_Input = {
  additional_data_processing_consent_given_at?: InputMaybe<Scalars['timestamptz']>;
  age?: InputMaybe<Scalars['Int']>;
  arm_length_cm?: InputMaybe<Scalars['float8']>;
  /** Article groups used in this answer (see: public.article_groups) */
  article_groups?: InputMaybe<Scalars['jsonb']>;
  bicycle_type_id?: InputMaybe<Answer_Enums_Bicycle_Type_Enum>;
  /** Body height in centimetres */
  body_height?: InputMaybe<Scalars['float8']>;
  comment?: InputMaybe<Scalars['String']>;
  commission?: InputMaybe<Scalars['float8']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_name?: InputMaybe<Scalars['String']>;
  driving_duration_id?: InputMaybe<Answer_Enums_Driving_Duration_Enum>;
  driving_frequency_id?: InputMaybe<Answer_Enums_Driving_Frequency_Enum>;
  e_bike_id?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  foot_position_id?: InputMaybe<Answer_Enums_Foot_Position_Enum>;
  foot_type_id?: InputMaybe<Answer_Enums_Foot_Type_Enum>;
  /** distance from the tip of the middle finger to the middle of the hand (corresponds to hand_height in the cv table) */
  grip_width_cm?: InputMaybe<Scalars['float8']>;
  /** Size of the grip (S, M, L) */
  gripping_distance?: InputMaybe<Answer_Enums_Gripping_Distance_Enum>;
  /** distance across the hand over the knuckles */
  hand_width_cm?: InputMaybe<Scalars['float8']>;
  hip_circumference?: InputMaybe<Scalars['float8']>;
  hip_width?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['String']>;
  knee_height_cm?: InputMaybe<Scalars['float8']>;
  leg_axis_id?: InputMaybe<Answer_Enums_Leg_Axis_Enum>;
  /** corrensponds to "sitbone_distance" in cv table "sitbones" */
  seat_bone_distance_cm?: InputMaybe<Scalars['Int']>;
  seat_position_id?: InputMaybe<Answer_Enums_Seat_Position_Enum>;
  sex_id?: InputMaybe<Answer_Enums_Sex_Enum>;
  shoe_size?: InputMaybe<Scalars['Int']>;
  short_id?: InputMaybe<Scalars['String']>;
  shoulder_width_cm?: InputMaybe<Scalars['float8']>;
  /** crotch length in centimetres (distance from hip to the ground) */
  step_length?: InputMaybe<Scalars['float8']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  vendor_id?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Customer_Answer_Stddev_Fields = {
  __typename?: 'customer_answer_stddev_fields';
  age?: Maybe<Scalars['Float']>;
  arm_length_cm?: Maybe<Scalars['Float']>;
  /** Body height in centimetres */
  body_height?: Maybe<Scalars['Float']>;
  commission?: Maybe<Scalars['Float']>;
  /** distance from the tip of the middle finger to the middle of the hand (corresponds to hand_height in the cv table) */
  grip_width_cm?: Maybe<Scalars['Float']>;
  /** distance across the hand over the knuckles */
  hand_width_cm?: Maybe<Scalars['Float']>;
  hip_circumference?: Maybe<Scalars['Float']>;
  hip_width?: Maybe<Scalars['Float']>;
  knee_height_cm?: Maybe<Scalars['Float']>;
  /** corrensponds to "sitbone_distance" in cv table "sitbones" */
  seat_bone_distance_cm?: Maybe<Scalars['Float']>;
  shoe_size?: Maybe<Scalars['Float']>;
  shoulder_width_cm?: Maybe<Scalars['Float']>;
  /** crotch length in centimetres (distance from hip to the ground) */
  step_length?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Customer_Answer_Stddev_Pop_Fields = {
  __typename?: 'customer_answer_stddev_pop_fields';
  age?: Maybe<Scalars['Float']>;
  arm_length_cm?: Maybe<Scalars['Float']>;
  /** Body height in centimetres */
  body_height?: Maybe<Scalars['Float']>;
  commission?: Maybe<Scalars['Float']>;
  /** distance from the tip of the middle finger to the middle of the hand (corresponds to hand_height in the cv table) */
  grip_width_cm?: Maybe<Scalars['Float']>;
  /** distance across the hand over the knuckles */
  hand_width_cm?: Maybe<Scalars['Float']>;
  hip_circumference?: Maybe<Scalars['Float']>;
  hip_width?: Maybe<Scalars['Float']>;
  knee_height_cm?: Maybe<Scalars['Float']>;
  /** corrensponds to "sitbone_distance" in cv table "sitbones" */
  seat_bone_distance_cm?: Maybe<Scalars['Float']>;
  shoe_size?: Maybe<Scalars['Float']>;
  shoulder_width_cm?: Maybe<Scalars['Float']>;
  /** crotch length in centimetres (distance from hip to the ground) */
  step_length?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Customer_Answer_Stddev_Samp_Fields = {
  __typename?: 'customer_answer_stddev_samp_fields';
  age?: Maybe<Scalars['Float']>;
  arm_length_cm?: Maybe<Scalars['Float']>;
  /** Body height in centimetres */
  body_height?: Maybe<Scalars['Float']>;
  commission?: Maybe<Scalars['Float']>;
  /** distance from the tip of the middle finger to the middle of the hand (corresponds to hand_height in the cv table) */
  grip_width_cm?: Maybe<Scalars['Float']>;
  /** distance across the hand over the knuckles */
  hand_width_cm?: Maybe<Scalars['Float']>;
  hip_circumference?: Maybe<Scalars['Float']>;
  hip_width?: Maybe<Scalars['Float']>;
  knee_height_cm?: Maybe<Scalars['Float']>;
  /** corrensponds to "sitbone_distance" in cv table "sitbones" */
  seat_bone_distance_cm?: Maybe<Scalars['Float']>;
  shoe_size?: Maybe<Scalars['Float']>;
  shoulder_width_cm?: Maybe<Scalars['Float']>;
  /** crotch length in centimetres (distance from hip to the ground) */
  step_length?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "customer_answer" */
export type Customer_Answer_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Answer_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Answer_Stream_Cursor_Value_Input = {
  additional_data_processing_consent_given_at?: InputMaybe<Scalars['timestamptz']>;
  age?: InputMaybe<Scalars['Int']>;
  arm_length_cm?: InputMaybe<Scalars['float8']>;
  /** Article groups used in this answer (see: public.article_groups) */
  article_groups?: InputMaybe<Scalars['jsonb']>;
  bicycle_type_id?: InputMaybe<Answer_Enums_Bicycle_Type_Enum>;
  /** Body height in centimetres */
  body_height?: InputMaybe<Scalars['float8']>;
  comment?: InputMaybe<Scalars['String']>;
  commission?: InputMaybe<Scalars['float8']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_name?: InputMaybe<Scalars['String']>;
  driving_duration_id?: InputMaybe<Answer_Enums_Driving_Duration_Enum>;
  driving_frequency_id?: InputMaybe<Answer_Enums_Driving_Frequency_Enum>;
  e_bike_id?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  foot_position_id?: InputMaybe<Answer_Enums_Foot_Position_Enum>;
  foot_type_id?: InputMaybe<Answer_Enums_Foot_Type_Enum>;
  /** distance from the tip of the middle finger to the middle of the hand (corresponds to hand_height in the cv table) */
  grip_width_cm?: InputMaybe<Scalars['float8']>;
  /** Size of the grip (S, M, L) */
  gripping_distance?: InputMaybe<Answer_Enums_Gripping_Distance_Enum>;
  /** distance across the hand over the knuckles */
  hand_width_cm?: InputMaybe<Scalars['float8']>;
  hip_circumference?: InputMaybe<Scalars['float8']>;
  hip_width?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['String']>;
  knee_height_cm?: InputMaybe<Scalars['float8']>;
  leg_axis_id?: InputMaybe<Answer_Enums_Leg_Axis_Enum>;
  /** corrensponds to "sitbone_distance" in cv table "sitbones" */
  seat_bone_distance_cm?: InputMaybe<Scalars['Int']>;
  seat_position_id?: InputMaybe<Answer_Enums_Seat_Position_Enum>;
  sex_id?: InputMaybe<Answer_Enums_Sex_Enum>;
  shoe_size?: InputMaybe<Scalars['Int']>;
  short_id?: InputMaybe<Scalars['String']>;
  shoulder_width_cm?: InputMaybe<Scalars['float8']>;
  /** crotch length in centimetres (distance from hip to the ground) */
  step_length?: InputMaybe<Scalars['float8']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  vendor_id?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Customer_Answer_Sum_Fields = {
  __typename?: 'customer_answer_sum_fields';
  age?: Maybe<Scalars['Int']>;
  arm_length_cm?: Maybe<Scalars['float8']>;
  /** Body height in centimetres */
  body_height?: Maybe<Scalars['float8']>;
  commission?: Maybe<Scalars['float8']>;
  /** distance from the tip of the middle finger to the middle of the hand (corresponds to hand_height in the cv table) */
  grip_width_cm?: Maybe<Scalars['float8']>;
  /** distance across the hand over the knuckles */
  hand_width_cm?: Maybe<Scalars['float8']>;
  hip_circumference?: Maybe<Scalars['float8']>;
  hip_width?: Maybe<Scalars['float8']>;
  knee_height_cm?: Maybe<Scalars['float8']>;
  /** corrensponds to "sitbone_distance" in cv table "sitbones" */
  seat_bone_distance_cm?: Maybe<Scalars['Int']>;
  shoe_size?: Maybe<Scalars['Int']>;
  shoulder_width_cm?: Maybe<Scalars['float8']>;
  /** crotch length in centimetres (distance from hip to the ground) */
  step_length?: Maybe<Scalars['float8']>;
  weight?: Maybe<Scalars['Int']>;
};

/** update columns of table "customer.answer" */
export enum Customer_Answer_Update_Column {
  /** column name */
  AdditionalDataProcessingConsentGivenAt = 'additional_data_processing_consent_given_at',
  /** column name */
  Age = 'age',
  /** column name */
  ArmLengthCm = 'arm_length_cm',
  /** column name */
  ArticleGroups = 'article_groups',
  /** column name */
  BicycleTypeId = 'bicycle_type_id',
  /** column name */
  BodyHeight = 'body_height',
  /** column name */
  Comment = 'comment',
  /** column name */
  Commission = 'commission',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerName = 'customer_name',
  /** column name */
  DrivingDurationId = 'driving_duration_id',
  /** column name */
  DrivingFrequencyId = 'driving_frequency_id',
  /** column name */
  EBikeId = 'e_bike_id',
  /** column name */
  Email = 'email',
  /** column name */
  FootPositionId = 'foot_position_id',
  /** column name */
  FootTypeId = 'foot_type_id',
  /** column name */
  GripWidthCm = 'grip_width_cm',
  /** column name */
  GrippingDistance = 'gripping_distance',
  /** column name */
  HandWidthCm = 'hand_width_cm',
  /** column name */
  HipCircumference = 'hip_circumference',
  /** column name */
  HipWidth = 'hip_width',
  /** column name */
  Id = 'id',
  /** column name */
  KneeHeightCm = 'knee_height_cm',
  /** column name */
  LegAxisId = 'leg_axis_id',
  /** column name */
  SeatBoneDistanceCm = 'seat_bone_distance_cm',
  /** column name */
  SeatPositionId = 'seat_position_id',
  /** column name */
  SexId = 'sex_id',
  /** column name */
  ShoeSize = 'shoe_size',
  /** column name */
  ShortId = 'short_id',
  /** column name */
  ShoulderWidthCm = 'shoulder_width_cm',
  /** column name */
  StepLength = 'step_length',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VendorId = 'vendor_id',
  /** column name */
  Weight = 'weight'
}

export type Customer_Answer_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Customer_Answer_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Customer_Answer_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Customer_Answer_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Customer_Answer_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Customer_Answer_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Customer_Answer_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Answer_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Answer_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Customer_Answer_Var_Pop_Fields = {
  __typename?: 'customer_answer_var_pop_fields';
  age?: Maybe<Scalars['Float']>;
  arm_length_cm?: Maybe<Scalars['Float']>;
  /** Body height in centimetres */
  body_height?: Maybe<Scalars['Float']>;
  commission?: Maybe<Scalars['Float']>;
  /** distance from the tip of the middle finger to the middle of the hand (corresponds to hand_height in the cv table) */
  grip_width_cm?: Maybe<Scalars['Float']>;
  /** distance across the hand over the knuckles */
  hand_width_cm?: Maybe<Scalars['Float']>;
  hip_circumference?: Maybe<Scalars['Float']>;
  hip_width?: Maybe<Scalars['Float']>;
  knee_height_cm?: Maybe<Scalars['Float']>;
  /** corrensponds to "sitbone_distance" in cv table "sitbones" */
  seat_bone_distance_cm?: Maybe<Scalars['Float']>;
  shoe_size?: Maybe<Scalars['Float']>;
  shoulder_width_cm?: Maybe<Scalars['Float']>;
  /** crotch length in centimetres (distance from hip to the ground) */
  step_length?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Customer_Answer_Var_Samp_Fields = {
  __typename?: 'customer_answer_var_samp_fields';
  age?: Maybe<Scalars['Float']>;
  arm_length_cm?: Maybe<Scalars['Float']>;
  /** Body height in centimetres */
  body_height?: Maybe<Scalars['Float']>;
  commission?: Maybe<Scalars['Float']>;
  /** distance from the tip of the middle finger to the middle of the hand (corresponds to hand_height in the cv table) */
  grip_width_cm?: Maybe<Scalars['Float']>;
  /** distance across the hand over the knuckles */
  hand_width_cm?: Maybe<Scalars['Float']>;
  hip_circumference?: Maybe<Scalars['Float']>;
  hip_width?: Maybe<Scalars['Float']>;
  knee_height_cm?: Maybe<Scalars['Float']>;
  /** corrensponds to "sitbone_distance" in cv table "sitbones" */
  seat_bone_distance_cm?: Maybe<Scalars['Float']>;
  shoe_size?: Maybe<Scalars['Float']>;
  shoulder_width_cm?: Maybe<Scalars['Float']>;
  /** crotch length in centimetres (distance from hip to the ground) */
  step_length?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Customer_Answer_Variance_Fields = {
  __typename?: 'customer_answer_variance_fields';
  age?: Maybe<Scalars['Float']>;
  arm_length_cm?: Maybe<Scalars['Float']>;
  /** Body height in centimetres */
  body_height?: Maybe<Scalars['Float']>;
  commission?: Maybe<Scalars['Float']>;
  /** distance from the tip of the middle finger to the middle of the hand (corresponds to hand_height in the cv table) */
  grip_width_cm?: Maybe<Scalars['Float']>;
  /** distance across the hand over the knuckles */
  hand_width_cm?: Maybe<Scalars['Float']>;
  hip_circumference?: Maybe<Scalars['Float']>;
  hip_width?: Maybe<Scalars['Float']>;
  knee_height_cm?: Maybe<Scalars['Float']>;
  /** corrensponds to "sitbone_distance" in cv table "sitbones" */
  seat_bone_distance_cm?: Maybe<Scalars['Float']>;
  shoe_size?: Maybe<Scalars['Float']>;
  shoulder_width_cm?: Maybe<Scalars['Float']>;
  /** crotch length in centimetres (distance from hip to the ground) */
  step_length?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "customer_dashboard.event" */
export type Customer_Dashboard_Event = {
  __typename?: 'customer_dashboard_event';
  answer_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  /** Serialized data for the event */
  data?: Maybe<Scalars['jsonb']>;
  kind: Customer_Dashboard_Event_Kind_Enum;
  /** For auditing and tracing support. Contains things like the user agent, screen size, device, ... */
  meta: Scalars['jsonb'];
};


/** columns and relationships of "customer_dashboard.event" */
export type Customer_Dashboard_EventDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "customer_dashboard.event" */
export type Customer_Dashboard_EventMetaArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "customer_dashboard.event" */
export type Customer_Dashboard_Event_Aggregate = {
  __typename?: 'customer_dashboard_event_aggregate';
  aggregate?: Maybe<Customer_Dashboard_Event_Aggregate_Fields>;
  nodes: Array<Customer_Dashboard_Event>;
};

/** aggregate fields of "customer_dashboard.event" */
export type Customer_Dashboard_Event_Aggregate_Fields = {
  __typename?: 'customer_dashboard_event_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Customer_Dashboard_Event_Max_Fields>;
  min?: Maybe<Customer_Dashboard_Event_Min_Fields>;
};


/** aggregate fields of "customer_dashboard.event" */
export type Customer_Dashboard_Event_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Dashboard_Event_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Customer_Dashboard_Event_Append_Input = {
  /** Serialized data for the event */
  data?: InputMaybe<Scalars['jsonb']>;
  /** For auditing and tracing support. Contains things like the user agent, screen size, device, ... */
  meta?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "customer_dashboard.event". All fields are combined with a logical 'AND'. */
export type Customer_Dashboard_Event_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Dashboard_Event_Bool_Exp>>;
  _not?: InputMaybe<Customer_Dashboard_Event_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Dashboard_Event_Bool_Exp>>;
  answer_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  kind?: InputMaybe<Customer_Dashboard_Event_Kind_Enum_Comparison_Exp>;
  meta?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer_dashboard.event" */
export enum Customer_Dashboard_Event_Constraint {
  /** unique or primary key constraint on columns "answer_id", "created_at" */
  EventPkey = 'event_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Customer_Dashboard_Event_Delete_At_Path_Input = {
  /** Serialized data for the event */
  data?: InputMaybe<Array<Scalars['String']>>;
  /** For auditing and tracing support. Contains things like the user agent, screen size, device, ... */
  meta?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Customer_Dashboard_Event_Delete_Elem_Input = {
  /** Serialized data for the event */
  data?: InputMaybe<Scalars['Int']>;
  /** For auditing and tracing support. Contains things like the user agent, screen size, device, ... */
  meta?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Customer_Dashboard_Event_Delete_Key_Input = {
  /** Serialized data for the event */
  data?: InputMaybe<Scalars['String']>;
  /** For auditing and tracing support. Contains things like the user agent, screen size, device, ... */
  meta?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "customer_dashboard.event" */
export type Customer_Dashboard_Event_Insert_Input = {
  answer_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** Serialized data for the event */
  data?: InputMaybe<Scalars['jsonb']>;
  kind?: InputMaybe<Customer_Dashboard_Event_Kind_Enum>;
  /** For auditing and tracing support. Contains things like the user agent, screen size, device, ... */
  meta?: InputMaybe<Scalars['jsonb']>;
};

/** columns and relationships of "customer_dashboard.event_kind" */
export type Customer_Dashboard_Event_Kind = {
  __typename?: 'customer_dashboard_event_kind';
  value: Scalars['String'];
};

/** aggregated selection of "customer_dashboard.event_kind" */
export type Customer_Dashboard_Event_Kind_Aggregate = {
  __typename?: 'customer_dashboard_event_kind_aggregate';
  aggregate?: Maybe<Customer_Dashboard_Event_Kind_Aggregate_Fields>;
  nodes: Array<Customer_Dashboard_Event_Kind>;
};

/** aggregate fields of "customer_dashboard.event_kind" */
export type Customer_Dashboard_Event_Kind_Aggregate_Fields = {
  __typename?: 'customer_dashboard_event_kind_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Customer_Dashboard_Event_Kind_Max_Fields>;
  min?: Maybe<Customer_Dashboard_Event_Kind_Min_Fields>;
};


/** aggregate fields of "customer_dashboard.event_kind" */
export type Customer_Dashboard_Event_Kind_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Dashboard_Event_Kind_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "customer_dashboard.event_kind". All fields are combined with a logical 'AND'. */
export type Customer_Dashboard_Event_Kind_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Dashboard_Event_Kind_Bool_Exp>>;
  _not?: InputMaybe<Customer_Dashboard_Event_Kind_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Dashboard_Event_Kind_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer_dashboard.event_kind" */
export enum Customer_Dashboard_Event_Kind_Constraint {
  /** unique or primary key constraint on columns "value" */
  EventKindPkey = 'event_kind_pkey'
}

export enum Customer_Dashboard_Event_Kind_Enum {
  Leave = 'LEAVE',
  ViewInOnlineShop = 'VIEW_IN_ONLINE_SHOP',
  Visit = 'VISIT'
}

/** Boolean expression to compare columns of type "customer_dashboard_event_kind_enum". All fields are combined with logical 'AND'. */
export type Customer_Dashboard_Event_Kind_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Customer_Dashboard_Event_Kind_Enum>;
  _in?: InputMaybe<Array<Customer_Dashboard_Event_Kind_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Customer_Dashboard_Event_Kind_Enum>;
  _nin?: InputMaybe<Array<Customer_Dashboard_Event_Kind_Enum>>;
};

/** input type for inserting data into table "customer_dashboard.event_kind" */
export type Customer_Dashboard_Event_Kind_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Customer_Dashboard_Event_Kind_Max_Fields = {
  __typename?: 'customer_dashboard_event_kind_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Customer_Dashboard_Event_Kind_Min_Fields = {
  __typename?: 'customer_dashboard_event_kind_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "customer_dashboard.event_kind" */
export type Customer_Dashboard_Event_Kind_Mutation_Response = {
  __typename?: 'customer_dashboard_event_kind_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Dashboard_Event_Kind>;
};

/** on_conflict condition type for table "customer_dashboard.event_kind" */
export type Customer_Dashboard_Event_Kind_On_Conflict = {
  constraint: Customer_Dashboard_Event_Kind_Constraint;
  update_columns?: Array<Customer_Dashboard_Event_Kind_Update_Column>;
  where?: InputMaybe<Customer_Dashboard_Event_Kind_Bool_Exp>;
};

/** Ordering options when selecting data from "customer_dashboard.event_kind". */
export type Customer_Dashboard_Event_Kind_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customer_dashboard.event_kind */
export type Customer_Dashboard_Event_Kind_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "customer_dashboard.event_kind" */
export enum Customer_Dashboard_Event_Kind_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "customer_dashboard.event_kind" */
export type Customer_Dashboard_Event_Kind_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "customer_dashboard_event_kind" */
export type Customer_Dashboard_Event_Kind_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Dashboard_Event_Kind_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Dashboard_Event_Kind_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "customer_dashboard.event_kind" */
export enum Customer_Dashboard_Event_Kind_Update_Column {
  /** column name */
  Value = 'value'
}

export type Customer_Dashboard_Event_Kind_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Dashboard_Event_Kind_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Dashboard_Event_Kind_Bool_Exp;
};

/** aggregate max on columns */
export type Customer_Dashboard_Event_Max_Fields = {
  __typename?: 'customer_dashboard_event_max_fields';
  answer_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Customer_Dashboard_Event_Min_Fields = {
  __typename?: 'customer_dashboard_event_min_fields';
  answer_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "customer_dashboard.event" */
export type Customer_Dashboard_Event_Mutation_Response = {
  __typename?: 'customer_dashboard_event_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Dashboard_Event>;
};

/** on_conflict condition type for table "customer_dashboard.event" */
export type Customer_Dashboard_Event_On_Conflict = {
  constraint: Customer_Dashboard_Event_Constraint;
  update_columns?: Array<Customer_Dashboard_Event_Update_Column>;
  where?: InputMaybe<Customer_Dashboard_Event_Bool_Exp>;
};

/** Ordering options when selecting data from "customer_dashboard.event". */
export type Customer_Dashboard_Event_Order_By = {
  answer_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  meta?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customer_dashboard.event */
export type Customer_Dashboard_Event_Pk_Columns_Input = {
  answer_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Customer_Dashboard_Event_Prepend_Input = {
  /** Serialized data for the event */
  data?: InputMaybe<Scalars['jsonb']>;
  /** For auditing and tracing support. Contains things like the user agent, screen size, device, ... */
  meta?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "customer_dashboard.event" */
export enum Customer_Dashboard_Event_Select_Column {
  /** column name */
  AnswerId = 'answer_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Kind = 'kind',
  /** column name */
  Meta = 'meta'
}

/** input type for updating data in table "customer_dashboard.event" */
export type Customer_Dashboard_Event_Set_Input = {
  answer_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** Serialized data for the event */
  data?: InputMaybe<Scalars['jsonb']>;
  kind?: InputMaybe<Customer_Dashboard_Event_Kind_Enum>;
  /** For auditing and tracing support. Contains things like the user agent, screen size, device, ... */
  meta?: InputMaybe<Scalars['jsonb']>;
};

/** Streaming cursor of the table "customer_dashboard_event" */
export type Customer_Dashboard_Event_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Dashboard_Event_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Dashboard_Event_Stream_Cursor_Value_Input = {
  answer_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** Serialized data for the event */
  data?: InputMaybe<Scalars['jsonb']>;
  kind?: InputMaybe<Customer_Dashboard_Event_Kind_Enum>;
  /** For auditing and tracing support. Contains things like the user agent, screen size, device, ... */
  meta?: InputMaybe<Scalars['jsonb']>;
};

/** update columns of table "customer_dashboard.event" */
export enum Customer_Dashboard_Event_Update_Column {
  /** column name */
  AnswerId = 'answer_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Kind = 'kind',
  /** column name */
  Meta = 'meta'
}

export type Customer_Dashboard_Event_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Customer_Dashboard_Event_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Customer_Dashboard_Event_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Customer_Dashboard_Event_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Customer_Dashboard_Event_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Customer_Dashboard_Event_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Dashboard_Event_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Dashboard_Event_Bool_Exp;
};

/** columns and relationships of "customer_dashboard.page_visit" */
export type Customer_Dashboard_Page_Visit = {
  __typename?: 'customer_dashboard_page_visit';
  answer_id?: Maybe<Scalars['String']>;
  leave_time?: Maybe<Scalars['timestamptz']>;
  page?: Maybe<Scalars['jsonb']>;
  seconds_spent?: Maybe<Scalars['numeric']>;
  visit_time?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "customer_dashboard.page_visit" */
export type Customer_Dashboard_Page_VisitPageArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "customer_dashboard.page_visit" */
export type Customer_Dashboard_Page_Visit_Aggregate = {
  __typename?: 'customer_dashboard_page_visit_aggregate';
  aggregate?: Maybe<Customer_Dashboard_Page_Visit_Aggregate_Fields>;
  nodes: Array<Customer_Dashboard_Page_Visit>;
};

/** aggregate fields of "customer_dashboard.page_visit" */
export type Customer_Dashboard_Page_Visit_Aggregate_Fields = {
  __typename?: 'customer_dashboard_page_visit_aggregate_fields';
  avg?: Maybe<Customer_Dashboard_Page_Visit_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Dashboard_Page_Visit_Max_Fields>;
  min?: Maybe<Customer_Dashboard_Page_Visit_Min_Fields>;
  stddev?: Maybe<Customer_Dashboard_Page_Visit_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Dashboard_Page_Visit_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Dashboard_Page_Visit_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Dashboard_Page_Visit_Sum_Fields>;
  var_pop?: Maybe<Customer_Dashboard_Page_Visit_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Dashboard_Page_Visit_Var_Samp_Fields>;
  variance?: Maybe<Customer_Dashboard_Page_Visit_Variance_Fields>;
};


/** aggregate fields of "customer_dashboard.page_visit" */
export type Customer_Dashboard_Page_Visit_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Dashboard_Page_Visit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Customer_Dashboard_Page_Visit_Avg_Fields = {
  __typename?: 'customer_dashboard_page_visit_avg_fields';
  seconds_spent?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "customer_dashboard.page_visit". All fields are combined with a logical 'AND'. */
export type Customer_Dashboard_Page_Visit_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Dashboard_Page_Visit_Bool_Exp>>;
  _not?: InputMaybe<Customer_Dashboard_Page_Visit_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Dashboard_Page_Visit_Bool_Exp>>;
  answer_id?: InputMaybe<String_Comparison_Exp>;
  leave_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  page?: InputMaybe<Jsonb_Comparison_Exp>;
  seconds_spent?: InputMaybe<Numeric_Comparison_Exp>;
  visit_time?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Customer_Dashboard_Page_Visit_Max_Fields = {
  __typename?: 'customer_dashboard_page_visit_max_fields';
  answer_id?: Maybe<Scalars['String']>;
  leave_time?: Maybe<Scalars['timestamptz']>;
  seconds_spent?: Maybe<Scalars['numeric']>;
  visit_time?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Customer_Dashboard_Page_Visit_Min_Fields = {
  __typename?: 'customer_dashboard_page_visit_min_fields';
  answer_id?: Maybe<Scalars['String']>;
  leave_time?: Maybe<Scalars['timestamptz']>;
  seconds_spent?: Maybe<Scalars['numeric']>;
  visit_time?: Maybe<Scalars['timestamptz']>;
};

/** Ordering options when selecting data from "customer_dashboard.page_visit". */
export type Customer_Dashboard_Page_Visit_Order_By = {
  answer_id?: InputMaybe<Order_By>;
  leave_time?: InputMaybe<Order_By>;
  page?: InputMaybe<Order_By>;
  seconds_spent?: InputMaybe<Order_By>;
  visit_time?: InputMaybe<Order_By>;
};

/** select columns of table "customer_dashboard.page_visit" */
export enum Customer_Dashboard_Page_Visit_Select_Column {
  /** column name */
  AnswerId = 'answer_id',
  /** column name */
  LeaveTime = 'leave_time',
  /** column name */
  Page = 'page',
  /** column name */
  SecondsSpent = 'seconds_spent',
  /** column name */
  VisitTime = 'visit_time'
}

/** aggregate stddev on columns */
export type Customer_Dashboard_Page_Visit_Stddev_Fields = {
  __typename?: 'customer_dashboard_page_visit_stddev_fields';
  seconds_spent?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Customer_Dashboard_Page_Visit_Stddev_Pop_Fields = {
  __typename?: 'customer_dashboard_page_visit_stddev_pop_fields';
  seconds_spent?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Customer_Dashboard_Page_Visit_Stddev_Samp_Fields = {
  __typename?: 'customer_dashboard_page_visit_stddev_samp_fields';
  seconds_spent?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "customer_dashboard_page_visit" */
export type Customer_Dashboard_Page_Visit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Dashboard_Page_Visit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Dashboard_Page_Visit_Stream_Cursor_Value_Input = {
  answer_id?: InputMaybe<Scalars['String']>;
  leave_time?: InputMaybe<Scalars['timestamptz']>;
  page?: InputMaybe<Scalars['jsonb']>;
  seconds_spent?: InputMaybe<Scalars['numeric']>;
  visit_time?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Customer_Dashboard_Page_Visit_Sum_Fields = {
  __typename?: 'customer_dashboard_page_visit_sum_fields';
  seconds_spent?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type Customer_Dashboard_Page_Visit_Var_Pop_Fields = {
  __typename?: 'customer_dashboard_page_visit_var_pop_fields';
  seconds_spent?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Customer_Dashboard_Page_Visit_Var_Samp_Fields = {
  __typename?: 'customer_dashboard_page_visit_var_samp_fields';
  seconds_spent?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Customer_Dashboard_Page_Visit_Variance_Fields = {
  __typename?: 'customer_dashboard_page_visit_variance_fields';
  seconds_spent?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "customer.deleted_answers" */
export type Customer_Deleted_Answers = {
  __typename?: 'customer_deleted_answers';
  answer_id: Scalars['String'];
  deleted_at: Scalars['timestamptz'];
  origin: Scalars['String'];
  /** An object relationship */
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "customer.deleted_answers" */
export type Customer_Deleted_Answers_Aggregate = {
  __typename?: 'customer_deleted_answers_aggregate';
  aggregate?: Maybe<Customer_Deleted_Answers_Aggregate_Fields>;
  nodes: Array<Customer_Deleted_Answers>;
};

/** aggregate fields of "customer.deleted_answers" */
export type Customer_Deleted_Answers_Aggregate_Fields = {
  __typename?: 'customer_deleted_answers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Customer_Deleted_Answers_Max_Fields>;
  min?: Maybe<Customer_Deleted_Answers_Min_Fields>;
};


/** aggregate fields of "customer.deleted_answers" */
export type Customer_Deleted_Answers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Deleted_Answers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "customer.deleted_answers". All fields are combined with a logical 'AND'. */
export type Customer_Deleted_Answers_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Deleted_Answers_Bool_Exp>>;
  _not?: InputMaybe<Customer_Deleted_Answers_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Deleted_Answers_Bool_Exp>>;
  answer_id?: InputMaybe<String_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  origin?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer.deleted_answers" */
export enum Customer_Deleted_Answers_Constraint {
  /** unique or primary key constraint on columns "answer_id" */
  DeletedAnswersPkey = 'deleted_answers_pkey'
}

/** input type for inserting data into table "customer.deleted_answers" */
export type Customer_Deleted_Answers_Insert_Input = {
  answer_id?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  origin?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Customer_Deleted_Answers_Max_Fields = {
  __typename?: 'customer_deleted_answers_max_fields';
  answer_id?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  origin?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Customer_Deleted_Answers_Min_Fields = {
  __typename?: 'customer_deleted_answers_min_fields';
  answer_id?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  origin?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "customer.deleted_answers" */
export type Customer_Deleted_Answers_Mutation_Response = {
  __typename?: 'customer_deleted_answers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Deleted_Answers>;
};

/** on_conflict condition type for table "customer.deleted_answers" */
export type Customer_Deleted_Answers_On_Conflict = {
  constraint: Customer_Deleted_Answers_Constraint;
  update_columns?: Array<Customer_Deleted_Answers_Update_Column>;
  where?: InputMaybe<Customer_Deleted_Answers_Bool_Exp>;
};

/** Ordering options when selecting data from "customer.deleted_answers". */
export type Customer_Deleted_Answers_Order_By = {
  answer_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  origin?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customer.deleted_answers */
export type Customer_Deleted_Answers_Pk_Columns_Input = {
  answer_id: Scalars['String'];
};

/** select columns of table "customer.deleted_answers" */
export enum Customer_Deleted_Answers_Select_Column {
  /** column name */
  AnswerId = 'answer_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Origin = 'origin',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "customer.deleted_answers" */
export type Customer_Deleted_Answers_Set_Input = {
  answer_id?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  origin?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "customer_deleted_answers" */
export type Customer_Deleted_Answers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Deleted_Answers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Deleted_Answers_Stream_Cursor_Value_Input = {
  answer_id?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  origin?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "customer.deleted_answers" */
export enum Customer_Deleted_Answers_Update_Column {
  /** column name */
  AnswerId = 'answer_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Origin = 'origin',
  /** column name */
  UserId = 'user_id'
}

export type Customer_Deleted_Answers_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Deleted_Answers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Deleted_Answers_Bool_Exp;
};

/** columns and relationships of "customer.emails" */
export type Customer_Emails = {
  __typename?: 'customer_emails';
  answer_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  language: Scalars['String'];
  scheduled_at: Scalars['timestamptz'];
  sent: Scalars['Boolean'];
  type: Scalars['String'];
};

/** aggregated selection of "customer.emails" */
export type Customer_Emails_Aggregate = {
  __typename?: 'customer_emails_aggregate';
  aggregate?: Maybe<Customer_Emails_Aggregate_Fields>;
  nodes: Array<Customer_Emails>;
};

/** aggregate fields of "customer.emails" */
export type Customer_Emails_Aggregate_Fields = {
  __typename?: 'customer_emails_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Customer_Emails_Max_Fields>;
  min?: Maybe<Customer_Emails_Min_Fields>;
};


/** aggregate fields of "customer.emails" */
export type Customer_Emails_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Emails_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "customer.emails". All fields are combined with a logical 'AND'. */
export type Customer_Emails_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Emails_Bool_Exp>>;
  _not?: InputMaybe<Customer_Emails_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Emails_Bool_Exp>>;
  answer_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  language?: InputMaybe<String_Comparison_Exp>;
  scheduled_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  sent?: InputMaybe<Boolean_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer.emails" */
export enum Customer_Emails_Constraint {
  /** unique or primary key constraint on columns "answer_id", "type" */
  EmailsPkey = 'emails_pkey'
}

/** input type for inserting data into table "customer.emails" */
export type Customer_Emails_Insert_Input = {
  answer_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  language?: InputMaybe<Scalars['String']>;
  scheduled_at?: InputMaybe<Scalars['timestamptz']>;
  sent?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Customer_Emails_Max_Fields = {
  __typename?: 'customer_emails_max_fields';
  answer_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  language?: Maybe<Scalars['String']>;
  scheduled_at?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Customer_Emails_Min_Fields = {
  __typename?: 'customer_emails_min_fields';
  answer_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  language?: Maybe<Scalars['String']>;
  scheduled_at?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "customer.emails" */
export type Customer_Emails_Mutation_Response = {
  __typename?: 'customer_emails_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Emails>;
};

/** on_conflict condition type for table "customer.emails" */
export type Customer_Emails_On_Conflict = {
  constraint: Customer_Emails_Constraint;
  update_columns?: Array<Customer_Emails_Update_Column>;
  where?: InputMaybe<Customer_Emails_Bool_Exp>;
};

/** Ordering options when selecting data from "customer.emails". */
export type Customer_Emails_Order_By = {
  answer_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  scheduled_at?: InputMaybe<Order_By>;
  sent?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customer.emails */
export type Customer_Emails_Pk_Columns_Input = {
  answer_id: Scalars['String'];
  type: Scalars['String'];
};

/** select columns of table "customer.emails" */
export enum Customer_Emails_Select_Column {
  /** column name */
  AnswerId = 'answer_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Language = 'language',
  /** column name */
  ScheduledAt = 'scheduled_at',
  /** column name */
  Sent = 'sent',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "customer.emails" */
export type Customer_Emails_Set_Input = {
  answer_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  language?: InputMaybe<Scalars['String']>;
  scheduled_at?: InputMaybe<Scalars['timestamptz']>;
  sent?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "customer_emails" */
export type Customer_Emails_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Emails_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Emails_Stream_Cursor_Value_Input = {
  answer_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  language?: InputMaybe<Scalars['String']>;
  scheduled_at?: InputMaybe<Scalars['timestamptz']>;
  sent?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "customer.emails" */
export enum Customer_Emails_Update_Column {
  /** column name */
  AnswerId = 'answer_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Language = 'language',
  /** column name */
  ScheduledAt = 'scheduled_at',
  /** column name */
  Sent = 'sent',
  /** column name */
  Type = 'type'
}

export type Customer_Emails_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Emails_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Emails_Bool_Exp;
};

/** Table for managing the data for the vendor registrations to the Profiler app. */
export type Customer_Registration = {
  __typename?: 'customer_registration';
  additional_email_addresses?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  deny_reason?: Maybe<Scalars['String']>;
  email_address: Scalars['String'];
  first_name: Scalars['String'];
  id: Scalars['Int'];
  ipad_available: Scalars['Boolean'];
  language: Scalars['String'];
  registration_email_sent_at?: Maybe<Scalars['timestamptz']>;
  status: Scalars['String'];
  surname: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  vendor?: Maybe<Organisation>;
  vendor_id?: Maybe<Scalars['String']>;
  vendor_name?: Maybe<Scalars['String']>;
  /** An array relationship */
  vendor_registrations: Array<Customer_Registration>;
  /** An aggregate relationship */
  vendor_registrations_aggregate: Customer_Registration_Aggregate;
  vendor_size?: Maybe<Scalars['String']>;
};


/** Table for managing the data for the vendor registrations to the Profiler app. */
export type Customer_RegistrationVendor_RegistrationsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Registration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Registration_Order_By>>;
  where?: InputMaybe<Customer_Registration_Bool_Exp>;
};


/** Table for managing the data for the vendor registrations to the Profiler app. */
export type Customer_RegistrationVendor_Registrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Registration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Registration_Order_By>>;
  where?: InputMaybe<Customer_Registration_Bool_Exp>;
};

/** aggregated selection of "customer.registration" */
export type Customer_Registration_Aggregate = {
  __typename?: 'customer_registration_aggregate';
  aggregate?: Maybe<Customer_Registration_Aggregate_Fields>;
  nodes: Array<Customer_Registration>;
};

export type Customer_Registration_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Customer_Registration_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Customer_Registration_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Customer_Registration_Aggregate_Bool_Exp_Count>;
};

export type Customer_Registration_Aggregate_Bool_Exp_Bool_And = {
  arguments: Customer_Registration_Select_Column_Customer_Registration_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Registration_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Customer_Registration_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Customer_Registration_Select_Column_Customer_Registration_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Registration_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Customer_Registration_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Customer_Registration_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Registration_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customer.registration" */
export type Customer_Registration_Aggregate_Fields = {
  __typename?: 'customer_registration_aggregate_fields';
  avg?: Maybe<Customer_Registration_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Registration_Max_Fields>;
  min?: Maybe<Customer_Registration_Min_Fields>;
  stddev?: Maybe<Customer_Registration_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Registration_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Registration_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Registration_Sum_Fields>;
  var_pop?: Maybe<Customer_Registration_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Registration_Var_Samp_Fields>;
  variance?: Maybe<Customer_Registration_Variance_Fields>;
};


/** aggregate fields of "customer.registration" */
export type Customer_Registration_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Registration_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customer.registration" */
export type Customer_Registration_Aggregate_Order_By = {
  avg?: InputMaybe<Customer_Registration_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Customer_Registration_Max_Order_By>;
  min?: InputMaybe<Customer_Registration_Min_Order_By>;
  stddev?: InputMaybe<Customer_Registration_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Customer_Registration_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Customer_Registration_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Customer_Registration_Sum_Order_By>;
  var_pop?: InputMaybe<Customer_Registration_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Customer_Registration_Var_Samp_Order_By>;
  variance?: InputMaybe<Customer_Registration_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "customer.registration" */
export type Customer_Registration_Arr_Rel_Insert_Input = {
  data: Array<Customer_Registration_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_Registration_On_Conflict>;
};

/** aggregate avg on columns */
export type Customer_Registration_Avg_Fields = {
  __typename?: 'customer_registration_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "customer.registration" */
export type Customer_Registration_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customer.registration". All fields are combined with a logical 'AND'. */
export type Customer_Registration_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Registration_Bool_Exp>>;
  _not?: InputMaybe<Customer_Registration_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Registration_Bool_Exp>>;
  additional_email_addresses?: InputMaybe<String_Comparison_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deny_reason?: InputMaybe<String_Comparison_Exp>;
  email_address?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  ipad_available?: InputMaybe<Boolean_Comparison_Exp>;
  language?: InputMaybe<String_Comparison_Exp>;
  registration_email_sent_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  surname?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vendor?: InputMaybe<Organisation_Bool_Exp>;
  vendor_id?: InputMaybe<String_Comparison_Exp>;
  vendor_name?: InputMaybe<String_Comparison_Exp>;
  vendor_registrations?: InputMaybe<Customer_Registration_Bool_Exp>;
  vendor_registrations_aggregate?: InputMaybe<Customer_Registration_Aggregate_Bool_Exp>;
  vendor_size?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer.registration" */
export enum Customer_Registration_Constraint {
  /** unique or primary key constraint on columns "id" */
  RegistrationPkey = 'registration_pkey'
}

/** input type for incrementing numeric columns in table "customer.registration" */
export type Customer_Registration_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "customer.registration" */
export type Customer_Registration_Insert_Input = {
  additional_email_addresses?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deny_reason?: InputMaybe<Scalars['String']>;
  email_address?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  ipad_available?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  registration_email_sent_at?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['String']>;
  surname?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vendor?: InputMaybe<Organisation_Obj_Rel_Insert_Input>;
  vendor_id?: InputMaybe<Scalars['String']>;
  vendor_name?: InputMaybe<Scalars['String']>;
  vendor_registrations?: InputMaybe<Customer_Registration_Arr_Rel_Insert_Input>;
  vendor_size?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Customer_Registration_Max_Fields = {
  __typename?: 'customer_registration_max_fields';
  additional_email_addresses?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deny_reason?: Maybe<Scalars['String']>;
  email_address?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['String']>;
  registration_email_sent_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vendor_id?: Maybe<Scalars['String']>;
  vendor_name?: Maybe<Scalars['String']>;
  vendor_size?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "customer.registration" */
export type Customer_Registration_Max_Order_By = {
  additional_email_addresses?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deny_reason?: InputMaybe<Order_By>;
  email_address?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  registration_email_sent_at?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  surname?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
  vendor_name?: InputMaybe<Order_By>;
  vendor_size?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Customer_Registration_Min_Fields = {
  __typename?: 'customer_registration_min_fields';
  additional_email_addresses?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deny_reason?: Maybe<Scalars['String']>;
  email_address?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['String']>;
  registration_email_sent_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vendor_id?: Maybe<Scalars['String']>;
  vendor_name?: Maybe<Scalars['String']>;
  vendor_size?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "customer.registration" */
export type Customer_Registration_Min_Order_By = {
  additional_email_addresses?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deny_reason?: InputMaybe<Order_By>;
  email_address?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  registration_email_sent_at?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  surname?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
  vendor_name?: InputMaybe<Order_By>;
  vendor_size?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "customer.registration" */
export type Customer_Registration_Mutation_Response = {
  __typename?: 'customer_registration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Registration>;
};

/** on_conflict condition type for table "customer.registration" */
export type Customer_Registration_On_Conflict = {
  constraint: Customer_Registration_Constraint;
  update_columns?: Array<Customer_Registration_Update_Column>;
  where?: InputMaybe<Customer_Registration_Bool_Exp>;
};

/** Ordering options when selecting data from "customer.registration". */
export type Customer_Registration_Order_By = {
  additional_email_addresses?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deny_reason?: InputMaybe<Order_By>;
  email_address?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ipad_available?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  registration_email_sent_at?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  surname?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor?: InputMaybe<Organisation_Order_By>;
  vendor_id?: InputMaybe<Order_By>;
  vendor_name?: InputMaybe<Order_By>;
  vendor_registrations_aggregate?: InputMaybe<Customer_Registration_Aggregate_Order_By>;
  vendor_size?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customer.registration */
export type Customer_Registration_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "customer.registration" */
export enum Customer_Registration_Select_Column {
  /** column name */
  AdditionalEmailAddresses = 'additional_email_addresses',
  /** column name */
  Comment = 'comment',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DenyReason = 'deny_reason',
  /** column name */
  EmailAddress = 'email_address',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IpadAvailable = 'ipad_available',
  /** column name */
  Language = 'language',
  /** column name */
  RegistrationEmailSentAt = 'registration_email_sent_at',
  /** column name */
  Status = 'status',
  /** column name */
  Surname = 'surname',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VendorId = 'vendor_id',
  /** column name */
  VendorName = 'vendor_name',
  /** column name */
  VendorSize = 'vendor_size'
}

/** select "customer_registration_aggregate_bool_exp_bool_and_arguments_columns" columns of table "customer.registration" */
export enum Customer_Registration_Select_Column_Customer_Registration_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IpadAvailable = 'ipad_available'
}

/** select "customer_registration_aggregate_bool_exp_bool_or_arguments_columns" columns of table "customer.registration" */
export enum Customer_Registration_Select_Column_Customer_Registration_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IpadAvailable = 'ipad_available'
}

/** input type for updating data in table "customer.registration" */
export type Customer_Registration_Set_Input = {
  additional_email_addresses?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deny_reason?: InputMaybe<Scalars['String']>;
  email_address?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  ipad_available?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  registration_email_sent_at?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['String']>;
  surname?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vendor_id?: InputMaybe<Scalars['String']>;
  vendor_name?: InputMaybe<Scalars['String']>;
  vendor_size?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Customer_Registration_Stddev_Fields = {
  __typename?: 'customer_registration_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "customer.registration" */
export type Customer_Registration_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Customer_Registration_Stddev_Pop_Fields = {
  __typename?: 'customer_registration_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "customer.registration" */
export type Customer_Registration_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Customer_Registration_Stddev_Samp_Fields = {
  __typename?: 'customer_registration_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "customer.registration" */
export type Customer_Registration_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "customer_registration" */
export type Customer_Registration_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Registration_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Registration_Stream_Cursor_Value_Input = {
  additional_email_addresses?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deny_reason?: InputMaybe<Scalars['String']>;
  email_address?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  ipad_available?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  registration_email_sent_at?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['String']>;
  surname?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vendor_id?: InputMaybe<Scalars['String']>;
  vendor_name?: InputMaybe<Scalars['String']>;
  vendor_size?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Customer_Registration_Sum_Fields = {
  __typename?: 'customer_registration_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "customer.registration" */
export type Customer_Registration_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "customer.registration" */
export enum Customer_Registration_Update_Column {
  /** column name */
  AdditionalEmailAddresses = 'additional_email_addresses',
  /** column name */
  Comment = 'comment',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DenyReason = 'deny_reason',
  /** column name */
  EmailAddress = 'email_address',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IpadAvailable = 'ipad_available',
  /** column name */
  Language = 'language',
  /** column name */
  RegistrationEmailSentAt = 'registration_email_sent_at',
  /** column name */
  Status = 'status',
  /** column name */
  Surname = 'surname',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VendorId = 'vendor_id',
  /** column name */
  VendorName = 'vendor_name',
  /** column name */
  VendorSize = 'vendor_size'
}

export type Customer_Registration_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Customer_Registration_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Registration_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Registration_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Customer_Registration_Var_Pop_Fields = {
  __typename?: 'customer_registration_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "customer.registration" */
export type Customer_Registration_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Customer_Registration_Var_Samp_Fields = {
  __typename?: 'customer_registration_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "customer.registration" */
export type Customer_Registration_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Customer_Registration_Variance_Fields = {
  __typename?: 'customer_registration_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "customer.registration" */
export type Customer_Registration_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** stores the signatures of the data privacy page until 2022, after that we started to store screenshots of the signature in a S3 bucket. */
export type Customer_Signature = {
  __typename?: 'customer_signature';
  /** An object relationship */
  answer?: Maybe<Customer_Answer>;
  answer_id?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  customer_name?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  location?: Maybe<Scalars['String']>;
  signature_data_uri: Scalars['String'];
  signature_points: Scalars['jsonb'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['String'];
  vendor_id: Scalars['String'];
};


/** stores the signatures of the data privacy page until 2022, after that we started to store screenshots of the signature in a S3 bucket. */
export type Customer_SignatureSignature_PointsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "customer.signature" */
export type Customer_Signature_Aggregate = {
  __typename?: 'customer_signature_aggregate';
  aggregate?: Maybe<Customer_Signature_Aggregate_Fields>;
  nodes: Array<Customer_Signature>;
};

export type Customer_Signature_Aggregate_Bool_Exp = {
  count?: InputMaybe<Customer_Signature_Aggregate_Bool_Exp_Count>;
};

export type Customer_Signature_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Customer_Signature_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Signature_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customer.signature" */
export type Customer_Signature_Aggregate_Fields = {
  __typename?: 'customer_signature_aggregate_fields';
  avg?: Maybe<Customer_Signature_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Signature_Max_Fields>;
  min?: Maybe<Customer_Signature_Min_Fields>;
  stddev?: Maybe<Customer_Signature_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Signature_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Signature_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Signature_Sum_Fields>;
  var_pop?: Maybe<Customer_Signature_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Signature_Var_Samp_Fields>;
  variance?: Maybe<Customer_Signature_Variance_Fields>;
};


/** aggregate fields of "customer.signature" */
export type Customer_Signature_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Signature_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customer.signature" */
export type Customer_Signature_Aggregate_Order_By = {
  avg?: InputMaybe<Customer_Signature_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Customer_Signature_Max_Order_By>;
  min?: InputMaybe<Customer_Signature_Min_Order_By>;
  stddev?: InputMaybe<Customer_Signature_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Customer_Signature_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Customer_Signature_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Customer_Signature_Sum_Order_By>;
  var_pop?: InputMaybe<Customer_Signature_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Customer_Signature_Var_Samp_Order_By>;
  variance?: InputMaybe<Customer_Signature_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Customer_Signature_Append_Input = {
  signature_points?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "customer.signature" */
export type Customer_Signature_Arr_Rel_Insert_Input = {
  data: Array<Customer_Signature_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_Signature_On_Conflict>;
};

/** aggregate avg on columns */
export type Customer_Signature_Avg_Fields = {
  __typename?: 'customer_signature_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "customer.signature" */
export type Customer_Signature_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customer.signature". All fields are combined with a logical 'AND'. */
export type Customer_Signature_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Signature_Bool_Exp>>;
  _not?: InputMaybe<Customer_Signature_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Signature_Bool_Exp>>;
  answer?: InputMaybe<Customer_Answer_Bool_Exp>;
  answer_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  customer_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  location?: InputMaybe<String_Comparison_Exp>;
  signature_data_uri?: InputMaybe<String_Comparison_Exp>;
  signature_points?: InputMaybe<Jsonb_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
  vendor_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer.signature" */
export enum Customer_Signature_Constraint {
  /** unique or primary key constraint on columns "id" */
  SignaturePkey = 'signature_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Customer_Signature_Delete_At_Path_Input = {
  signature_points?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Customer_Signature_Delete_Elem_Input = {
  signature_points?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Customer_Signature_Delete_Key_Input = {
  signature_points?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "customer.signature" */
export type Customer_Signature_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "customer.signature" */
export type Customer_Signature_Insert_Input = {
  answer?: InputMaybe<Customer_Answer_Obj_Rel_Insert_Input>;
  answer_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['String']>;
  signature_data_uri?: InputMaybe<Scalars['String']>;
  signature_points?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Customer_Signature_Max_Fields = {
  __typename?: 'customer_signature_max_fields';
  answer_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['String']>;
  signature_data_uri?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "customer.signature" */
export type Customer_Signature_Max_Order_By = {
  answer_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  signature_data_uri?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Customer_Signature_Min_Fields = {
  __typename?: 'customer_signature_min_fields';
  answer_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['String']>;
  signature_data_uri?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "customer.signature" */
export type Customer_Signature_Min_Order_By = {
  answer_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  signature_data_uri?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "customer.signature" */
export type Customer_Signature_Mutation_Response = {
  __typename?: 'customer_signature_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Signature>;
};

/** on_conflict condition type for table "customer.signature" */
export type Customer_Signature_On_Conflict = {
  constraint: Customer_Signature_Constraint;
  update_columns?: Array<Customer_Signature_Update_Column>;
  where?: InputMaybe<Customer_Signature_Bool_Exp>;
};

/** Ordering options when selecting data from "customer.signature". */
export type Customer_Signature_Order_By = {
  answer?: InputMaybe<Customer_Answer_Order_By>;
  answer_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  signature_data_uri?: InputMaybe<Order_By>;
  signature_points?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customer.signature */
export type Customer_Signature_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Customer_Signature_Prepend_Input = {
  signature_points?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "customer.signature" */
export enum Customer_Signature_Select_Column {
  /** column name */
  AnswerId = 'answer_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerName = 'customer_name',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  SignatureDataUri = 'signature_data_uri',
  /** column name */
  SignaturePoints = 'signature_points',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VendorId = 'vendor_id'
}

/** input type for updating data in table "customer.signature" */
export type Customer_Signature_Set_Input = {
  answer_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['String']>;
  signature_data_uri?: InputMaybe<Scalars['String']>;
  signature_points?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Customer_Signature_Stddev_Fields = {
  __typename?: 'customer_signature_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "customer.signature" */
export type Customer_Signature_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Customer_Signature_Stddev_Pop_Fields = {
  __typename?: 'customer_signature_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "customer.signature" */
export type Customer_Signature_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Customer_Signature_Stddev_Samp_Fields = {
  __typename?: 'customer_signature_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "customer.signature" */
export type Customer_Signature_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "customer_signature" */
export type Customer_Signature_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Signature_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Signature_Stream_Cursor_Value_Input = {
  answer_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['String']>;
  signature_data_uri?: InputMaybe<Scalars['String']>;
  signature_points?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Customer_Signature_Sum_Fields = {
  __typename?: 'customer_signature_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "customer.signature" */
export type Customer_Signature_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "customer.signature" */
export enum Customer_Signature_Update_Column {
  /** column name */
  AnswerId = 'answer_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerName = 'customer_name',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  SignatureDataUri = 'signature_data_uri',
  /** column name */
  SignaturePoints = 'signature_points',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VendorId = 'vendor_id'
}

export type Customer_Signature_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Customer_Signature_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Customer_Signature_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Customer_Signature_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Customer_Signature_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Customer_Signature_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Customer_Signature_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Signature_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Signature_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Customer_Signature_Var_Pop_Fields = {
  __typename?: 'customer_signature_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "customer.signature" */
export type Customer_Signature_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Customer_Signature_Var_Samp_Fields = {
  __typename?: 'customer_signature_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "customer.signature" */
export type Customer_Signature_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Customer_Signature_Variance_Fields = {
  __typename?: 'customer_signature_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "customer.signature" */
export type Customer_Signature_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Stores voucher codes that are setup for specific users and vendors for a certain duration. If two codes are active at the same time for the same user it is undefined which one is chosen. Voucher codes are displayed in the questionnaire initial recommendation mail. */
export type Customer_Voucher = {
  __typename?: 'customer_voucher';
  comment: Scalars['String'];
  created_at: Scalars['timestamptz'];
  display_text: Scalars['String'];
  id: Scalars['Int'];
  public_from: Scalars['timestamptz'];
  public_to: Scalars['timestamptz'];
  updated_at: Scalars['timestamptz'];
  valid_for_user?: Maybe<Scalars['uuid']>;
  valid_for_vendor?: Maybe<Scalars['String']>;
  voucher_code: Scalars['String'];
};

/** aggregated selection of "customer.voucher" */
export type Customer_Voucher_Aggregate = {
  __typename?: 'customer_voucher_aggregate';
  aggregate?: Maybe<Customer_Voucher_Aggregate_Fields>;
  nodes: Array<Customer_Voucher>;
};

/** aggregate fields of "customer.voucher" */
export type Customer_Voucher_Aggregate_Fields = {
  __typename?: 'customer_voucher_aggregate_fields';
  avg?: Maybe<Customer_Voucher_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Voucher_Max_Fields>;
  min?: Maybe<Customer_Voucher_Min_Fields>;
  stddev?: Maybe<Customer_Voucher_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Voucher_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Voucher_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Voucher_Sum_Fields>;
  var_pop?: Maybe<Customer_Voucher_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Voucher_Var_Samp_Fields>;
  variance?: Maybe<Customer_Voucher_Variance_Fields>;
};


/** aggregate fields of "customer.voucher" */
export type Customer_Voucher_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Voucher_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Customer_Voucher_Avg_Fields = {
  __typename?: 'customer_voucher_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "customer.voucher". All fields are combined with a logical 'AND'. */
export type Customer_Voucher_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Voucher_Bool_Exp>>;
  _not?: InputMaybe<Customer_Voucher_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Voucher_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  display_text?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  public_from?: InputMaybe<Timestamptz_Comparison_Exp>;
  public_to?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_for_user?: InputMaybe<Uuid_Comparison_Exp>;
  valid_for_vendor?: InputMaybe<String_Comparison_Exp>;
  voucher_code?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer.voucher" */
export enum Customer_Voucher_Constraint {
  /** unique or primary key constraint on columns "id" */
  VoucherPkey = 'voucher_pkey'
}

/** input type for incrementing numeric columns in table "customer.voucher" */
export type Customer_Voucher_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "customer.voucher" */
export type Customer_Voucher_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  display_text?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  public_from?: InputMaybe<Scalars['timestamptz']>;
  public_to?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  valid_for_user?: InputMaybe<Scalars['uuid']>;
  valid_for_vendor?: InputMaybe<Scalars['String']>;
  voucher_code?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Customer_Voucher_Max_Fields = {
  __typename?: 'customer_voucher_max_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  display_text?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  public_from?: Maybe<Scalars['timestamptz']>;
  public_to?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  valid_for_user?: Maybe<Scalars['uuid']>;
  valid_for_vendor?: Maybe<Scalars['String']>;
  voucher_code?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Customer_Voucher_Min_Fields = {
  __typename?: 'customer_voucher_min_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  display_text?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  public_from?: Maybe<Scalars['timestamptz']>;
  public_to?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  valid_for_user?: Maybe<Scalars['uuid']>;
  valid_for_vendor?: Maybe<Scalars['String']>;
  voucher_code?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "customer.voucher" */
export type Customer_Voucher_Mutation_Response = {
  __typename?: 'customer_voucher_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Voucher>;
};

/** on_conflict condition type for table "customer.voucher" */
export type Customer_Voucher_On_Conflict = {
  constraint: Customer_Voucher_Constraint;
  update_columns?: Array<Customer_Voucher_Update_Column>;
  where?: InputMaybe<Customer_Voucher_Bool_Exp>;
};

/** Ordering options when selecting data from "customer.voucher". */
export type Customer_Voucher_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  display_text?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  public_from?: InputMaybe<Order_By>;
  public_to?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  valid_for_user?: InputMaybe<Order_By>;
  valid_for_vendor?: InputMaybe<Order_By>;
  voucher_code?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customer.voucher */
export type Customer_Voucher_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "customer.voucher" */
export enum Customer_Voucher_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayText = 'display_text',
  /** column name */
  Id = 'id',
  /** column name */
  PublicFrom = 'public_from',
  /** column name */
  PublicTo = 'public_to',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ValidForUser = 'valid_for_user',
  /** column name */
  ValidForVendor = 'valid_for_vendor',
  /** column name */
  VoucherCode = 'voucher_code'
}

/** input type for updating data in table "customer.voucher" */
export type Customer_Voucher_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  display_text?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  public_from?: InputMaybe<Scalars['timestamptz']>;
  public_to?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  valid_for_user?: InputMaybe<Scalars['uuid']>;
  valid_for_vendor?: InputMaybe<Scalars['String']>;
  voucher_code?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Customer_Voucher_Stddev_Fields = {
  __typename?: 'customer_voucher_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Customer_Voucher_Stddev_Pop_Fields = {
  __typename?: 'customer_voucher_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Customer_Voucher_Stddev_Samp_Fields = {
  __typename?: 'customer_voucher_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "customer_voucher" */
export type Customer_Voucher_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Voucher_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Voucher_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  display_text?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  public_from?: InputMaybe<Scalars['timestamptz']>;
  public_to?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  valid_for_user?: InputMaybe<Scalars['uuid']>;
  valid_for_vendor?: InputMaybe<Scalars['String']>;
  voucher_code?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Customer_Voucher_Sum_Fields = {
  __typename?: 'customer_voucher_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "customer.voucher" */
export enum Customer_Voucher_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayText = 'display_text',
  /** column name */
  Id = 'id',
  /** column name */
  PublicFrom = 'public_from',
  /** column name */
  PublicTo = 'public_to',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ValidForUser = 'valid_for_user',
  /** column name */
  ValidForVendor = 'valid_for_vendor',
  /** column name */
  VoucherCode = 'voucher_code'
}

export type Customer_Voucher_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Customer_Voucher_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Voucher_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Voucher_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Customer_Voucher_Var_Pop_Fields = {
  __typename?: 'customer_voucher_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Customer_Voucher_Var_Samp_Fields = {
  __typename?: 'customer_voucher_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Customer_Voucher_Variance_Fields = {
  __typename?: 'customer_voucher_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** If an answer_id is present in this table it means that it has been completed on a handheld device. Used to notify the web profiler that it can return to main menu. */
export type Customer_Web_Questionnaire_Completion = {
  __typename?: 'customer_web_questionnaire_completion';
  answer_id: Scalars['String'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "customer.web_questionnaire_completion" */
export type Customer_Web_Questionnaire_Completion_Aggregate = {
  __typename?: 'customer_web_questionnaire_completion_aggregate';
  aggregate?: Maybe<Customer_Web_Questionnaire_Completion_Aggregate_Fields>;
  nodes: Array<Customer_Web_Questionnaire_Completion>;
};

/** aggregate fields of "customer.web_questionnaire_completion" */
export type Customer_Web_Questionnaire_Completion_Aggregate_Fields = {
  __typename?: 'customer_web_questionnaire_completion_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Customer_Web_Questionnaire_Completion_Max_Fields>;
  min?: Maybe<Customer_Web_Questionnaire_Completion_Min_Fields>;
};


/** aggregate fields of "customer.web_questionnaire_completion" */
export type Customer_Web_Questionnaire_Completion_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Web_Questionnaire_Completion_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "customer.web_questionnaire_completion". All fields are combined with a logical 'AND'. */
export type Customer_Web_Questionnaire_Completion_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Web_Questionnaire_Completion_Bool_Exp>>;
  _not?: InputMaybe<Customer_Web_Questionnaire_Completion_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Web_Questionnaire_Completion_Bool_Exp>>;
  answer_id?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer.web_questionnaire_completion" */
export enum Customer_Web_Questionnaire_Completion_Constraint {
  /** unique or primary key constraint on columns "answer_id" */
  WebQuestionnaireCompletionPkey = 'web_questionnaire_completion_pkey'
}

/** input type for inserting data into table "customer.web_questionnaire_completion" */
export type Customer_Web_Questionnaire_Completion_Insert_Input = {
  answer_id?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Customer_Web_Questionnaire_Completion_Max_Fields = {
  __typename?: 'customer_web_questionnaire_completion_max_fields';
  answer_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Customer_Web_Questionnaire_Completion_Min_Fields = {
  __typename?: 'customer_web_questionnaire_completion_min_fields';
  answer_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "customer.web_questionnaire_completion" */
export type Customer_Web_Questionnaire_Completion_Mutation_Response = {
  __typename?: 'customer_web_questionnaire_completion_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Web_Questionnaire_Completion>;
};

/** on_conflict condition type for table "customer.web_questionnaire_completion" */
export type Customer_Web_Questionnaire_Completion_On_Conflict = {
  constraint: Customer_Web_Questionnaire_Completion_Constraint;
  update_columns?: Array<Customer_Web_Questionnaire_Completion_Update_Column>;
  where?: InputMaybe<Customer_Web_Questionnaire_Completion_Bool_Exp>;
};

/** Ordering options when selecting data from "customer.web_questionnaire_completion". */
export type Customer_Web_Questionnaire_Completion_Order_By = {
  answer_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customer.web_questionnaire_completion */
export type Customer_Web_Questionnaire_Completion_Pk_Columns_Input = {
  answer_id: Scalars['String'];
};

/** select columns of table "customer.web_questionnaire_completion" */
export enum Customer_Web_Questionnaire_Completion_Select_Column {
  /** column name */
  AnswerId = 'answer_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "customer.web_questionnaire_completion" */
export type Customer_Web_Questionnaire_Completion_Set_Input = {
  answer_id?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "customer_web_questionnaire_completion" */
export type Customer_Web_Questionnaire_Completion_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Web_Questionnaire_Completion_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Web_Questionnaire_Completion_Stream_Cursor_Value_Input = {
  answer_id?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "customer.web_questionnaire_completion" */
export enum Customer_Web_Questionnaire_Completion_Update_Column {
  /** column name */
  AnswerId = 'answer_id',
  /** column name */
  UserId = 'user_id'
}

export type Customer_Web_Questionnaire_Completion_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Web_Questionnaire_Completion_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Web_Questionnaire_Completion_Bool_Exp;
};

/** stores computervision data for the full body detection */
export type Cv_Body = {
  __typename?: 'cv_body';
  /** An object relationship */
  answer: Customer_Answer;
  answer_id: Scalars['String'];
  arm_length_cm: Scalars['float8'];
  before_img_url: Scalars['String'];
  biceps_width_in_cm: Scalars['float8'];
  body_height_cm: Scalars['float8'];
  body_landmarks: Scalars['jsonb'];
  chest_width_in_cm: Scalars['float8'];
  created_at: Scalars['timestamptz'];
  crotch_landmark: Scalars['jsonb'];
  /** corresponds to "step_length" in the customer answer */
  crotch_length_cm: Scalars['float8'];
  detection_duration: Scalars['float8'];
  error_id: Scalars['Int'];
  eyes_to_hip_height_in_cm: Scalars['float8'];
  face_landmarks: Scalars['jsonb'];
  final_image_url: Scalars['String'];
  head_width_in_cm: Scalars['float8'];
  heel_landmark: Scalars['jsonb'];
  knee_height_cm: Scalars['float8'];
  outer_hip_width_in_cm?: Maybe<Scalars['float8']>;
  parting_to_hip_height_in_cm: Scalars['float8'];
  shoulder_width_cm: Scalars['float8'];
  thigh_width_in_cm: Scalars['float8'];
  waist_width_in_cm: Scalars['float8'];
  warnings_id_list: Scalars['jsonb'];
};


/** stores computervision data for the full body detection */
export type Cv_BodyBody_LandmarksArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** stores computervision data for the full body detection */
export type Cv_BodyCrotch_LandmarkArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** stores computervision data for the full body detection */
export type Cv_BodyFace_LandmarksArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** stores computervision data for the full body detection */
export type Cv_BodyHeel_LandmarkArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** stores computervision data for the full body detection */
export type Cv_BodyWarnings_Id_ListArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "cv.body" */
export type Cv_Body_Aggregate = {
  __typename?: 'cv_body_aggregate';
  aggregate?: Maybe<Cv_Body_Aggregate_Fields>;
  nodes: Array<Cv_Body>;
};

export type Cv_Body_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Cv_Body_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Cv_Body_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Cv_Body_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Cv_Body_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Cv_Body_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Cv_Body_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Cv_Body_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Cv_Body_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Cv_Body_Aggregate_Bool_Exp_Var_Samp>;
};

export type Cv_Body_Aggregate_Bool_Exp_Avg = {
  arguments: Cv_Body_Select_Column_Cv_Body_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cv_Body_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Cv_Body_Aggregate_Bool_Exp_Corr = {
  arguments: Cv_Body_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cv_Body_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Cv_Body_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Cv_Body_Select_Column_Cv_Body_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Cv_Body_Select_Column_Cv_Body_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Cv_Body_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Cv_Body_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cv_Body_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Cv_Body_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Cv_Body_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cv_Body_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Cv_Body_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Cv_Body_Select_Column_Cv_Body_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Cv_Body_Select_Column_Cv_Body_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Cv_Body_Aggregate_Bool_Exp_Max = {
  arguments: Cv_Body_Select_Column_Cv_Body_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cv_Body_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Cv_Body_Aggregate_Bool_Exp_Min = {
  arguments: Cv_Body_Select_Column_Cv_Body_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cv_Body_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Cv_Body_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Cv_Body_Select_Column_Cv_Body_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cv_Body_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Cv_Body_Aggregate_Bool_Exp_Sum = {
  arguments: Cv_Body_Select_Column_Cv_Body_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cv_Body_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Cv_Body_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Cv_Body_Select_Column_Cv_Body_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cv_Body_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "cv.body" */
export type Cv_Body_Aggregate_Fields = {
  __typename?: 'cv_body_aggregate_fields';
  avg?: Maybe<Cv_Body_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Cv_Body_Max_Fields>;
  min?: Maybe<Cv_Body_Min_Fields>;
  stddev?: Maybe<Cv_Body_Stddev_Fields>;
  stddev_pop?: Maybe<Cv_Body_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Cv_Body_Stddev_Samp_Fields>;
  sum?: Maybe<Cv_Body_Sum_Fields>;
  var_pop?: Maybe<Cv_Body_Var_Pop_Fields>;
  var_samp?: Maybe<Cv_Body_Var_Samp_Fields>;
  variance?: Maybe<Cv_Body_Variance_Fields>;
};


/** aggregate fields of "cv.body" */
export type Cv_Body_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Cv_Body_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "cv.body" */
export type Cv_Body_Aggregate_Order_By = {
  avg?: InputMaybe<Cv_Body_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Cv_Body_Max_Order_By>;
  min?: InputMaybe<Cv_Body_Min_Order_By>;
  stddev?: InputMaybe<Cv_Body_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Cv_Body_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Cv_Body_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Cv_Body_Sum_Order_By>;
  var_pop?: InputMaybe<Cv_Body_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Cv_Body_Var_Samp_Order_By>;
  variance?: InputMaybe<Cv_Body_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Cv_Body_Append_Input = {
  body_landmarks?: InputMaybe<Scalars['jsonb']>;
  crotch_landmark?: InputMaybe<Scalars['jsonb']>;
  face_landmarks?: InputMaybe<Scalars['jsonb']>;
  heel_landmark?: InputMaybe<Scalars['jsonb']>;
  warnings_id_list?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "cv.body" */
export type Cv_Body_Arr_Rel_Insert_Input = {
  data: Array<Cv_Body_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Cv_Body_On_Conflict>;
};

/** aggregate avg on columns */
export type Cv_Body_Avg_Fields = {
  __typename?: 'cv_body_avg_fields';
  arm_length_cm?: Maybe<Scalars['Float']>;
  biceps_width_in_cm?: Maybe<Scalars['Float']>;
  body_height_cm?: Maybe<Scalars['Float']>;
  chest_width_in_cm?: Maybe<Scalars['Float']>;
  /** corresponds to "step_length" in the customer answer */
  crotch_length_cm?: Maybe<Scalars['Float']>;
  detection_duration?: Maybe<Scalars['Float']>;
  error_id?: Maybe<Scalars['Float']>;
  eyes_to_hip_height_in_cm?: Maybe<Scalars['Float']>;
  head_width_in_cm?: Maybe<Scalars['Float']>;
  knee_height_cm?: Maybe<Scalars['Float']>;
  outer_hip_width_in_cm?: Maybe<Scalars['Float']>;
  parting_to_hip_height_in_cm?: Maybe<Scalars['Float']>;
  shoulder_width_cm?: Maybe<Scalars['Float']>;
  thigh_width_in_cm?: Maybe<Scalars['Float']>;
  waist_width_in_cm?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "cv.body" */
export type Cv_Body_Avg_Order_By = {
  arm_length_cm?: InputMaybe<Order_By>;
  biceps_width_in_cm?: InputMaybe<Order_By>;
  body_height_cm?: InputMaybe<Order_By>;
  chest_width_in_cm?: InputMaybe<Order_By>;
  /** corresponds to "step_length" in the customer answer */
  crotch_length_cm?: InputMaybe<Order_By>;
  detection_duration?: InputMaybe<Order_By>;
  error_id?: InputMaybe<Order_By>;
  eyes_to_hip_height_in_cm?: InputMaybe<Order_By>;
  head_width_in_cm?: InputMaybe<Order_By>;
  knee_height_cm?: InputMaybe<Order_By>;
  outer_hip_width_in_cm?: InputMaybe<Order_By>;
  parting_to_hip_height_in_cm?: InputMaybe<Order_By>;
  shoulder_width_cm?: InputMaybe<Order_By>;
  thigh_width_in_cm?: InputMaybe<Order_By>;
  waist_width_in_cm?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "cv.body". All fields are combined with a logical 'AND'. */
export type Cv_Body_Bool_Exp = {
  _and?: InputMaybe<Array<Cv_Body_Bool_Exp>>;
  _not?: InputMaybe<Cv_Body_Bool_Exp>;
  _or?: InputMaybe<Array<Cv_Body_Bool_Exp>>;
  answer?: InputMaybe<Customer_Answer_Bool_Exp>;
  answer_id?: InputMaybe<String_Comparison_Exp>;
  arm_length_cm?: InputMaybe<Float8_Comparison_Exp>;
  before_img_url?: InputMaybe<String_Comparison_Exp>;
  biceps_width_in_cm?: InputMaybe<Float8_Comparison_Exp>;
  body_height_cm?: InputMaybe<Float8_Comparison_Exp>;
  body_landmarks?: InputMaybe<Jsonb_Comparison_Exp>;
  chest_width_in_cm?: InputMaybe<Float8_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  crotch_landmark?: InputMaybe<Jsonb_Comparison_Exp>;
  crotch_length_cm?: InputMaybe<Float8_Comparison_Exp>;
  detection_duration?: InputMaybe<Float8_Comparison_Exp>;
  error_id?: InputMaybe<Int_Comparison_Exp>;
  eyes_to_hip_height_in_cm?: InputMaybe<Float8_Comparison_Exp>;
  face_landmarks?: InputMaybe<Jsonb_Comparison_Exp>;
  final_image_url?: InputMaybe<String_Comparison_Exp>;
  head_width_in_cm?: InputMaybe<Float8_Comparison_Exp>;
  heel_landmark?: InputMaybe<Jsonb_Comparison_Exp>;
  knee_height_cm?: InputMaybe<Float8_Comparison_Exp>;
  outer_hip_width_in_cm?: InputMaybe<Float8_Comparison_Exp>;
  parting_to_hip_height_in_cm?: InputMaybe<Float8_Comparison_Exp>;
  shoulder_width_cm?: InputMaybe<Float8_Comparison_Exp>;
  thigh_width_in_cm?: InputMaybe<Float8_Comparison_Exp>;
  waist_width_in_cm?: InputMaybe<Float8_Comparison_Exp>;
  warnings_id_list?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "cv.body" */
export enum Cv_Body_Constraint {
  /** unique or primary key constraint on columns "answer_id", "created_at" */
  BodyPkey = 'body_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Cv_Body_Delete_At_Path_Input = {
  body_landmarks?: InputMaybe<Array<Scalars['String']>>;
  crotch_landmark?: InputMaybe<Array<Scalars['String']>>;
  face_landmarks?: InputMaybe<Array<Scalars['String']>>;
  heel_landmark?: InputMaybe<Array<Scalars['String']>>;
  warnings_id_list?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Cv_Body_Delete_Elem_Input = {
  body_landmarks?: InputMaybe<Scalars['Int']>;
  crotch_landmark?: InputMaybe<Scalars['Int']>;
  face_landmarks?: InputMaybe<Scalars['Int']>;
  heel_landmark?: InputMaybe<Scalars['Int']>;
  warnings_id_list?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Cv_Body_Delete_Key_Input = {
  body_landmarks?: InputMaybe<Scalars['String']>;
  crotch_landmark?: InputMaybe<Scalars['String']>;
  face_landmarks?: InputMaybe<Scalars['String']>;
  heel_landmark?: InputMaybe<Scalars['String']>;
  warnings_id_list?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "cv.body" */
export type Cv_Body_Inc_Input = {
  arm_length_cm?: InputMaybe<Scalars['float8']>;
  biceps_width_in_cm?: InputMaybe<Scalars['float8']>;
  body_height_cm?: InputMaybe<Scalars['float8']>;
  chest_width_in_cm?: InputMaybe<Scalars['float8']>;
  /** corresponds to "step_length" in the customer answer */
  crotch_length_cm?: InputMaybe<Scalars['float8']>;
  detection_duration?: InputMaybe<Scalars['float8']>;
  error_id?: InputMaybe<Scalars['Int']>;
  eyes_to_hip_height_in_cm?: InputMaybe<Scalars['float8']>;
  head_width_in_cm?: InputMaybe<Scalars['float8']>;
  knee_height_cm?: InputMaybe<Scalars['float8']>;
  outer_hip_width_in_cm?: InputMaybe<Scalars['float8']>;
  parting_to_hip_height_in_cm?: InputMaybe<Scalars['float8']>;
  shoulder_width_cm?: InputMaybe<Scalars['float8']>;
  thigh_width_in_cm?: InputMaybe<Scalars['float8']>;
  waist_width_in_cm?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "cv.body" */
export type Cv_Body_Insert_Input = {
  answer?: InputMaybe<Customer_Answer_Obj_Rel_Insert_Input>;
  answer_id?: InputMaybe<Scalars['String']>;
  arm_length_cm?: InputMaybe<Scalars['float8']>;
  before_img_url?: InputMaybe<Scalars['String']>;
  biceps_width_in_cm?: InputMaybe<Scalars['float8']>;
  body_height_cm?: InputMaybe<Scalars['float8']>;
  body_landmarks?: InputMaybe<Scalars['jsonb']>;
  chest_width_in_cm?: InputMaybe<Scalars['float8']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  crotch_landmark?: InputMaybe<Scalars['jsonb']>;
  /** corresponds to "step_length" in the customer answer */
  crotch_length_cm?: InputMaybe<Scalars['float8']>;
  detection_duration?: InputMaybe<Scalars['float8']>;
  error_id?: InputMaybe<Scalars['Int']>;
  eyes_to_hip_height_in_cm?: InputMaybe<Scalars['float8']>;
  face_landmarks?: InputMaybe<Scalars['jsonb']>;
  final_image_url?: InputMaybe<Scalars['String']>;
  head_width_in_cm?: InputMaybe<Scalars['float8']>;
  heel_landmark?: InputMaybe<Scalars['jsonb']>;
  knee_height_cm?: InputMaybe<Scalars['float8']>;
  outer_hip_width_in_cm?: InputMaybe<Scalars['float8']>;
  parting_to_hip_height_in_cm?: InputMaybe<Scalars['float8']>;
  shoulder_width_cm?: InputMaybe<Scalars['float8']>;
  thigh_width_in_cm?: InputMaybe<Scalars['float8']>;
  waist_width_in_cm?: InputMaybe<Scalars['float8']>;
  warnings_id_list?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type Cv_Body_Max_Fields = {
  __typename?: 'cv_body_max_fields';
  answer_id?: Maybe<Scalars['String']>;
  arm_length_cm?: Maybe<Scalars['float8']>;
  before_img_url?: Maybe<Scalars['String']>;
  biceps_width_in_cm?: Maybe<Scalars['float8']>;
  body_height_cm?: Maybe<Scalars['float8']>;
  chest_width_in_cm?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** corresponds to "step_length" in the customer answer */
  crotch_length_cm?: Maybe<Scalars['float8']>;
  detection_duration?: Maybe<Scalars['float8']>;
  error_id?: Maybe<Scalars['Int']>;
  eyes_to_hip_height_in_cm?: Maybe<Scalars['float8']>;
  final_image_url?: Maybe<Scalars['String']>;
  head_width_in_cm?: Maybe<Scalars['float8']>;
  knee_height_cm?: Maybe<Scalars['float8']>;
  outer_hip_width_in_cm?: Maybe<Scalars['float8']>;
  parting_to_hip_height_in_cm?: Maybe<Scalars['float8']>;
  shoulder_width_cm?: Maybe<Scalars['float8']>;
  thigh_width_in_cm?: Maybe<Scalars['float8']>;
  waist_width_in_cm?: Maybe<Scalars['float8']>;
};

/** order by max() on columns of table "cv.body" */
export type Cv_Body_Max_Order_By = {
  answer_id?: InputMaybe<Order_By>;
  arm_length_cm?: InputMaybe<Order_By>;
  before_img_url?: InputMaybe<Order_By>;
  biceps_width_in_cm?: InputMaybe<Order_By>;
  body_height_cm?: InputMaybe<Order_By>;
  chest_width_in_cm?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  /** corresponds to "step_length" in the customer answer */
  crotch_length_cm?: InputMaybe<Order_By>;
  detection_duration?: InputMaybe<Order_By>;
  error_id?: InputMaybe<Order_By>;
  eyes_to_hip_height_in_cm?: InputMaybe<Order_By>;
  final_image_url?: InputMaybe<Order_By>;
  head_width_in_cm?: InputMaybe<Order_By>;
  knee_height_cm?: InputMaybe<Order_By>;
  outer_hip_width_in_cm?: InputMaybe<Order_By>;
  parting_to_hip_height_in_cm?: InputMaybe<Order_By>;
  shoulder_width_cm?: InputMaybe<Order_By>;
  thigh_width_in_cm?: InputMaybe<Order_By>;
  waist_width_in_cm?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Cv_Body_Min_Fields = {
  __typename?: 'cv_body_min_fields';
  answer_id?: Maybe<Scalars['String']>;
  arm_length_cm?: Maybe<Scalars['float8']>;
  before_img_url?: Maybe<Scalars['String']>;
  biceps_width_in_cm?: Maybe<Scalars['float8']>;
  body_height_cm?: Maybe<Scalars['float8']>;
  chest_width_in_cm?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** corresponds to "step_length" in the customer answer */
  crotch_length_cm?: Maybe<Scalars['float8']>;
  detection_duration?: Maybe<Scalars['float8']>;
  error_id?: Maybe<Scalars['Int']>;
  eyes_to_hip_height_in_cm?: Maybe<Scalars['float8']>;
  final_image_url?: Maybe<Scalars['String']>;
  head_width_in_cm?: Maybe<Scalars['float8']>;
  knee_height_cm?: Maybe<Scalars['float8']>;
  outer_hip_width_in_cm?: Maybe<Scalars['float8']>;
  parting_to_hip_height_in_cm?: Maybe<Scalars['float8']>;
  shoulder_width_cm?: Maybe<Scalars['float8']>;
  thigh_width_in_cm?: Maybe<Scalars['float8']>;
  waist_width_in_cm?: Maybe<Scalars['float8']>;
};

/** order by min() on columns of table "cv.body" */
export type Cv_Body_Min_Order_By = {
  answer_id?: InputMaybe<Order_By>;
  arm_length_cm?: InputMaybe<Order_By>;
  before_img_url?: InputMaybe<Order_By>;
  biceps_width_in_cm?: InputMaybe<Order_By>;
  body_height_cm?: InputMaybe<Order_By>;
  chest_width_in_cm?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  /** corresponds to "step_length" in the customer answer */
  crotch_length_cm?: InputMaybe<Order_By>;
  detection_duration?: InputMaybe<Order_By>;
  error_id?: InputMaybe<Order_By>;
  eyes_to_hip_height_in_cm?: InputMaybe<Order_By>;
  final_image_url?: InputMaybe<Order_By>;
  head_width_in_cm?: InputMaybe<Order_By>;
  knee_height_cm?: InputMaybe<Order_By>;
  outer_hip_width_in_cm?: InputMaybe<Order_By>;
  parting_to_hip_height_in_cm?: InputMaybe<Order_By>;
  shoulder_width_cm?: InputMaybe<Order_By>;
  thigh_width_in_cm?: InputMaybe<Order_By>;
  waist_width_in_cm?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "cv.body" */
export type Cv_Body_Mutation_Response = {
  __typename?: 'cv_body_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Cv_Body>;
};

/** on_conflict condition type for table "cv.body" */
export type Cv_Body_On_Conflict = {
  constraint: Cv_Body_Constraint;
  update_columns?: Array<Cv_Body_Update_Column>;
  where?: InputMaybe<Cv_Body_Bool_Exp>;
};

/** Ordering options when selecting data from "cv.body". */
export type Cv_Body_Order_By = {
  answer?: InputMaybe<Customer_Answer_Order_By>;
  answer_id?: InputMaybe<Order_By>;
  arm_length_cm?: InputMaybe<Order_By>;
  before_img_url?: InputMaybe<Order_By>;
  biceps_width_in_cm?: InputMaybe<Order_By>;
  body_height_cm?: InputMaybe<Order_By>;
  body_landmarks?: InputMaybe<Order_By>;
  chest_width_in_cm?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  crotch_landmark?: InputMaybe<Order_By>;
  crotch_length_cm?: InputMaybe<Order_By>;
  detection_duration?: InputMaybe<Order_By>;
  error_id?: InputMaybe<Order_By>;
  eyes_to_hip_height_in_cm?: InputMaybe<Order_By>;
  face_landmarks?: InputMaybe<Order_By>;
  final_image_url?: InputMaybe<Order_By>;
  head_width_in_cm?: InputMaybe<Order_By>;
  heel_landmark?: InputMaybe<Order_By>;
  knee_height_cm?: InputMaybe<Order_By>;
  outer_hip_width_in_cm?: InputMaybe<Order_By>;
  parting_to_hip_height_in_cm?: InputMaybe<Order_By>;
  shoulder_width_cm?: InputMaybe<Order_By>;
  thigh_width_in_cm?: InputMaybe<Order_By>;
  waist_width_in_cm?: InputMaybe<Order_By>;
  warnings_id_list?: InputMaybe<Order_By>;
};

/** primary key columns input for table: cv.body */
export type Cv_Body_Pk_Columns_Input = {
  answer_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Cv_Body_Prepend_Input = {
  body_landmarks?: InputMaybe<Scalars['jsonb']>;
  crotch_landmark?: InputMaybe<Scalars['jsonb']>;
  face_landmarks?: InputMaybe<Scalars['jsonb']>;
  heel_landmark?: InputMaybe<Scalars['jsonb']>;
  warnings_id_list?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "cv.body" */
export enum Cv_Body_Select_Column {
  /** column name */
  AnswerId = 'answer_id',
  /** column name */
  ArmLengthCm = 'arm_length_cm',
  /** column name */
  BeforeImgUrl = 'before_img_url',
  /** column name */
  BicepsWidthInCm = 'biceps_width_in_cm',
  /** column name */
  BodyHeightCm = 'body_height_cm',
  /** column name */
  BodyLandmarks = 'body_landmarks',
  /** column name */
  ChestWidthInCm = 'chest_width_in_cm',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CrotchLandmark = 'crotch_landmark',
  /** column name */
  CrotchLengthCm = 'crotch_length_cm',
  /** column name */
  DetectionDuration = 'detection_duration',
  /** column name */
  ErrorId = 'error_id',
  /** column name */
  EyesToHipHeightInCm = 'eyes_to_hip_height_in_cm',
  /** column name */
  FaceLandmarks = 'face_landmarks',
  /** column name */
  FinalImageUrl = 'final_image_url',
  /** column name */
  HeadWidthInCm = 'head_width_in_cm',
  /** column name */
  HeelLandmark = 'heel_landmark',
  /** column name */
  KneeHeightCm = 'knee_height_cm',
  /** column name */
  OuterHipWidthInCm = 'outer_hip_width_in_cm',
  /** column name */
  PartingToHipHeightInCm = 'parting_to_hip_height_in_cm',
  /** column name */
  ShoulderWidthCm = 'shoulder_width_cm',
  /** column name */
  ThighWidthInCm = 'thigh_width_in_cm',
  /** column name */
  WaistWidthInCm = 'waist_width_in_cm',
  /** column name */
  WarningsIdList = 'warnings_id_list'
}

/** select "cv_body_aggregate_bool_exp_avg_arguments_columns" columns of table "cv.body" */
export enum Cv_Body_Select_Column_Cv_Body_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  ArmLengthCm = 'arm_length_cm',
  /** column name */
  BicepsWidthInCm = 'biceps_width_in_cm',
  /** column name */
  BodyHeightCm = 'body_height_cm',
  /** column name */
  ChestWidthInCm = 'chest_width_in_cm',
  /** column name */
  CrotchLengthCm = 'crotch_length_cm',
  /** column name */
  DetectionDuration = 'detection_duration',
  /** column name */
  EyesToHipHeightInCm = 'eyes_to_hip_height_in_cm',
  /** column name */
  HeadWidthInCm = 'head_width_in_cm',
  /** column name */
  KneeHeightCm = 'knee_height_cm',
  /** column name */
  OuterHipWidthInCm = 'outer_hip_width_in_cm',
  /** column name */
  PartingToHipHeightInCm = 'parting_to_hip_height_in_cm',
  /** column name */
  ShoulderWidthCm = 'shoulder_width_cm',
  /** column name */
  ThighWidthInCm = 'thigh_width_in_cm',
  /** column name */
  WaistWidthInCm = 'waist_width_in_cm'
}

/** select "cv_body_aggregate_bool_exp_corr_arguments_columns" columns of table "cv.body" */
export enum Cv_Body_Select_Column_Cv_Body_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  ArmLengthCm = 'arm_length_cm',
  /** column name */
  BicepsWidthInCm = 'biceps_width_in_cm',
  /** column name */
  BodyHeightCm = 'body_height_cm',
  /** column name */
  ChestWidthInCm = 'chest_width_in_cm',
  /** column name */
  CrotchLengthCm = 'crotch_length_cm',
  /** column name */
  DetectionDuration = 'detection_duration',
  /** column name */
  EyesToHipHeightInCm = 'eyes_to_hip_height_in_cm',
  /** column name */
  HeadWidthInCm = 'head_width_in_cm',
  /** column name */
  KneeHeightCm = 'knee_height_cm',
  /** column name */
  OuterHipWidthInCm = 'outer_hip_width_in_cm',
  /** column name */
  PartingToHipHeightInCm = 'parting_to_hip_height_in_cm',
  /** column name */
  ShoulderWidthCm = 'shoulder_width_cm',
  /** column name */
  ThighWidthInCm = 'thigh_width_in_cm',
  /** column name */
  WaistWidthInCm = 'waist_width_in_cm'
}

/** select "cv_body_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "cv.body" */
export enum Cv_Body_Select_Column_Cv_Body_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  ArmLengthCm = 'arm_length_cm',
  /** column name */
  BicepsWidthInCm = 'biceps_width_in_cm',
  /** column name */
  BodyHeightCm = 'body_height_cm',
  /** column name */
  ChestWidthInCm = 'chest_width_in_cm',
  /** column name */
  CrotchLengthCm = 'crotch_length_cm',
  /** column name */
  DetectionDuration = 'detection_duration',
  /** column name */
  EyesToHipHeightInCm = 'eyes_to_hip_height_in_cm',
  /** column name */
  HeadWidthInCm = 'head_width_in_cm',
  /** column name */
  KneeHeightCm = 'knee_height_cm',
  /** column name */
  OuterHipWidthInCm = 'outer_hip_width_in_cm',
  /** column name */
  PartingToHipHeightInCm = 'parting_to_hip_height_in_cm',
  /** column name */
  ShoulderWidthCm = 'shoulder_width_cm',
  /** column name */
  ThighWidthInCm = 'thigh_width_in_cm',
  /** column name */
  WaistWidthInCm = 'waist_width_in_cm'
}

/** select "cv_body_aggregate_bool_exp_max_arguments_columns" columns of table "cv.body" */
export enum Cv_Body_Select_Column_Cv_Body_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  ArmLengthCm = 'arm_length_cm',
  /** column name */
  BicepsWidthInCm = 'biceps_width_in_cm',
  /** column name */
  BodyHeightCm = 'body_height_cm',
  /** column name */
  ChestWidthInCm = 'chest_width_in_cm',
  /** column name */
  CrotchLengthCm = 'crotch_length_cm',
  /** column name */
  DetectionDuration = 'detection_duration',
  /** column name */
  EyesToHipHeightInCm = 'eyes_to_hip_height_in_cm',
  /** column name */
  HeadWidthInCm = 'head_width_in_cm',
  /** column name */
  KneeHeightCm = 'knee_height_cm',
  /** column name */
  OuterHipWidthInCm = 'outer_hip_width_in_cm',
  /** column name */
  PartingToHipHeightInCm = 'parting_to_hip_height_in_cm',
  /** column name */
  ShoulderWidthCm = 'shoulder_width_cm',
  /** column name */
  ThighWidthInCm = 'thigh_width_in_cm',
  /** column name */
  WaistWidthInCm = 'waist_width_in_cm'
}

/** select "cv_body_aggregate_bool_exp_min_arguments_columns" columns of table "cv.body" */
export enum Cv_Body_Select_Column_Cv_Body_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  ArmLengthCm = 'arm_length_cm',
  /** column name */
  BicepsWidthInCm = 'biceps_width_in_cm',
  /** column name */
  BodyHeightCm = 'body_height_cm',
  /** column name */
  ChestWidthInCm = 'chest_width_in_cm',
  /** column name */
  CrotchLengthCm = 'crotch_length_cm',
  /** column name */
  DetectionDuration = 'detection_duration',
  /** column name */
  EyesToHipHeightInCm = 'eyes_to_hip_height_in_cm',
  /** column name */
  HeadWidthInCm = 'head_width_in_cm',
  /** column name */
  KneeHeightCm = 'knee_height_cm',
  /** column name */
  OuterHipWidthInCm = 'outer_hip_width_in_cm',
  /** column name */
  PartingToHipHeightInCm = 'parting_to_hip_height_in_cm',
  /** column name */
  ShoulderWidthCm = 'shoulder_width_cm',
  /** column name */
  ThighWidthInCm = 'thigh_width_in_cm',
  /** column name */
  WaistWidthInCm = 'waist_width_in_cm'
}

/** select "cv_body_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "cv.body" */
export enum Cv_Body_Select_Column_Cv_Body_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  ArmLengthCm = 'arm_length_cm',
  /** column name */
  BicepsWidthInCm = 'biceps_width_in_cm',
  /** column name */
  BodyHeightCm = 'body_height_cm',
  /** column name */
  ChestWidthInCm = 'chest_width_in_cm',
  /** column name */
  CrotchLengthCm = 'crotch_length_cm',
  /** column name */
  DetectionDuration = 'detection_duration',
  /** column name */
  EyesToHipHeightInCm = 'eyes_to_hip_height_in_cm',
  /** column name */
  HeadWidthInCm = 'head_width_in_cm',
  /** column name */
  KneeHeightCm = 'knee_height_cm',
  /** column name */
  OuterHipWidthInCm = 'outer_hip_width_in_cm',
  /** column name */
  PartingToHipHeightInCm = 'parting_to_hip_height_in_cm',
  /** column name */
  ShoulderWidthCm = 'shoulder_width_cm',
  /** column name */
  ThighWidthInCm = 'thigh_width_in_cm',
  /** column name */
  WaistWidthInCm = 'waist_width_in_cm'
}

/** select "cv_body_aggregate_bool_exp_sum_arguments_columns" columns of table "cv.body" */
export enum Cv_Body_Select_Column_Cv_Body_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  ArmLengthCm = 'arm_length_cm',
  /** column name */
  BicepsWidthInCm = 'biceps_width_in_cm',
  /** column name */
  BodyHeightCm = 'body_height_cm',
  /** column name */
  ChestWidthInCm = 'chest_width_in_cm',
  /** column name */
  CrotchLengthCm = 'crotch_length_cm',
  /** column name */
  DetectionDuration = 'detection_duration',
  /** column name */
  EyesToHipHeightInCm = 'eyes_to_hip_height_in_cm',
  /** column name */
  HeadWidthInCm = 'head_width_in_cm',
  /** column name */
  KneeHeightCm = 'knee_height_cm',
  /** column name */
  OuterHipWidthInCm = 'outer_hip_width_in_cm',
  /** column name */
  PartingToHipHeightInCm = 'parting_to_hip_height_in_cm',
  /** column name */
  ShoulderWidthCm = 'shoulder_width_cm',
  /** column name */
  ThighWidthInCm = 'thigh_width_in_cm',
  /** column name */
  WaistWidthInCm = 'waist_width_in_cm'
}

/** select "cv_body_aggregate_bool_exp_var_samp_arguments_columns" columns of table "cv.body" */
export enum Cv_Body_Select_Column_Cv_Body_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  ArmLengthCm = 'arm_length_cm',
  /** column name */
  BicepsWidthInCm = 'biceps_width_in_cm',
  /** column name */
  BodyHeightCm = 'body_height_cm',
  /** column name */
  ChestWidthInCm = 'chest_width_in_cm',
  /** column name */
  CrotchLengthCm = 'crotch_length_cm',
  /** column name */
  DetectionDuration = 'detection_duration',
  /** column name */
  EyesToHipHeightInCm = 'eyes_to_hip_height_in_cm',
  /** column name */
  HeadWidthInCm = 'head_width_in_cm',
  /** column name */
  KneeHeightCm = 'knee_height_cm',
  /** column name */
  OuterHipWidthInCm = 'outer_hip_width_in_cm',
  /** column name */
  PartingToHipHeightInCm = 'parting_to_hip_height_in_cm',
  /** column name */
  ShoulderWidthCm = 'shoulder_width_cm',
  /** column name */
  ThighWidthInCm = 'thigh_width_in_cm',
  /** column name */
  WaistWidthInCm = 'waist_width_in_cm'
}

/** input type for updating data in table "cv.body" */
export type Cv_Body_Set_Input = {
  answer_id?: InputMaybe<Scalars['String']>;
  arm_length_cm?: InputMaybe<Scalars['float8']>;
  before_img_url?: InputMaybe<Scalars['String']>;
  biceps_width_in_cm?: InputMaybe<Scalars['float8']>;
  body_height_cm?: InputMaybe<Scalars['float8']>;
  body_landmarks?: InputMaybe<Scalars['jsonb']>;
  chest_width_in_cm?: InputMaybe<Scalars['float8']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  crotch_landmark?: InputMaybe<Scalars['jsonb']>;
  /** corresponds to "step_length" in the customer answer */
  crotch_length_cm?: InputMaybe<Scalars['float8']>;
  detection_duration?: InputMaybe<Scalars['float8']>;
  error_id?: InputMaybe<Scalars['Int']>;
  eyes_to_hip_height_in_cm?: InputMaybe<Scalars['float8']>;
  face_landmarks?: InputMaybe<Scalars['jsonb']>;
  final_image_url?: InputMaybe<Scalars['String']>;
  head_width_in_cm?: InputMaybe<Scalars['float8']>;
  heel_landmark?: InputMaybe<Scalars['jsonb']>;
  knee_height_cm?: InputMaybe<Scalars['float8']>;
  outer_hip_width_in_cm?: InputMaybe<Scalars['float8']>;
  parting_to_hip_height_in_cm?: InputMaybe<Scalars['float8']>;
  shoulder_width_cm?: InputMaybe<Scalars['float8']>;
  thigh_width_in_cm?: InputMaybe<Scalars['float8']>;
  waist_width_in_cm?: InputMaybe<Scalars['float8']>;
  warnings_id_list?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate stddev on columns */
export type Cv_Body_Stddev_Fields = {
  __typename?: 'cv_body_stddev_fields';
  arm_length_cm?: Maybe<Scalars['Float']>;
  biceps_width_in_cm?: Maybe<Scalars['Float']>;
  body_height_cm?: Maybe<Scalars['Float']>;
  chest_width_in_cm?: Maybe<Scalars['Float']>;
  /** corresponds to "step_length" in the customer answer */
  crotch_length_cm?: Maybe<Scalars['Float']>;
  detection_duration?: Maybe<Scalars['Float']>;
  error_id?: Maybe<Scalars['Float']>;
  eyes_to_hip_height_in_cm?: Maybe<Scalars['Float']>;
  head_width_in_cm?: Maybe<Scalars['Float']>;
  knee_height_cm?: Maybe<Scalars['Float']>;
  outer_hip_width_in_cm?: Maybe<Scalars['Float']>;
  parting_to_hip_height_in_cm?: Maybe<Scalars['Float']>;
  shoulder_width_cm?: Maybe<Scalars['Float']>;
  thigh_width_in_cm?: Maybe<Scalars['Float']>;
  waist_width_in_cm?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "cv.body" */
export type Cv_Body_Stddev_Order_By = {
  arm_length_cm?: InputMaybe<Order_By>;
  biceps_width_in_cm?: InputMaybe<Order_By>;
  body_height_cm?: InputMaybe<Order_By>;
  chest_width_in_cm?: InputMaybe<Order_By>;
  /** corresponds to "step_length" in the customer answer */
  crotch_length_cm?: InputMaybe<Order_By>;
  detection_duration?: InputMaybe<Order_By>;
  error_id?: InputMaybe<Order_By>;
  eyes_to_hip_height_in_cm?: InputMaybe<Order_By>;
  head_width_in_cm?: InputMaybe<Order_By>;
  knee_height_cm?: InputMaybe<Order_By>;
  outer_hip_width_in_cm?: InputMaybe<Order_By>;
  parting_to_hip_height_in_cm?: InputMaybe<Order_By>;
  shoulder_width_cm?: InputMaybe<Order_By>;
  thigh_width_in_cm?: InputMaybe<Order_By>;
  waist_width_in_cm?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Cv_Body_Stddev_Pop_Fields = {
  __typename?: 'cv_body_stddev_pop_fields';
  arm_length_cm?: Maybe<Scalars['Float']>;
  biceps_width_in_cm?: Maybe<Scalars['Float']>;
  body_height_cm?: Maybe<Scalars['Float']>;
  chest_width_in_cm?: Maybe<Scalars['Float']>;
  /** corresponds to "step_length" in the customer answer */
  crotch_length_cm?: Maybe<Scalars['Float']>;
  detection_duration?: Maybe<Scalars['Float']>;
  error_id?: Maybe<Scalars['Float']>;
  eyes_to_hip_height_in_cm?: Maybe<Scalars['Float']>;
  head_width_in_cm?: Maybe<Scalars['Float']>;
  knee_height_cm?: Maybe<Scalars['Float']>;
  outer_hip_width_in_cm?: Maybe<Scalars['Float']>;
  parting_to_hip_height_in_cm?: Maybe<Scalars['Float']>;
  shoulder_width_cm?: Maybe<Scalars['Float']>;
  thigh_width_in_cm?: Maybe<Scalars['Float']>;
  waist_width_in_cm?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "cv.body" */
export type Cv_Body_Stddev_Pop_Order_By = {
  arm_length_cm?: InputMaybe<Order_By>;
  biceps_width_in_cm?: InputMaybe<Order_By>;
  body_height_cm?: InputMaybe<Order_By>;
  chest_width_in_cm?: InputMaybe<Order_By>;
  /** corresponds to "step_length" in the customer answer */
  crotch_length_cm?: InputMaybe<Order_By>;
  detection_duration?: InputMaybe<Order_By>;
  error_id?: InputMaybe<Order_By>;
  eyes_to_hip_height_in_cm?: InputMaybe<Order_By>;
  head_width_in_cm?: InputMaybe<Order_By>;
  knee_height_cm?: InputMaybe<Order_By>;
  outer_hip_width_in_cm?: InputMaybe<Order_By>;
  parting_to_hip_height_in_cm?: InputMaybe<Order_By>;
  shoulder_width_cm?: InputMaybe<Order_By>;
  thigh_width_in_cm?: InputMaybe<Order_By>;
  waist_width_in_cm?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Cv_Body_Stddev_Samp_Fields = {
  __typename?: 'cv_body_stddev_samp_fields';
  arm_length_cm?: Maybe<Scalars['Float']>;
  biceps_width_in_cm?: Maybe<Scalars['Float']>;
  body_height_cm?: Maybe<Scalars['Float']>;
  chest_width_in_cm?: Maybe<Scalars['Float']>;
  /** corresponds to "step_length" in the customer answer */
  crotch_length_cm?: Maybe<Scalars['Float']>;
  detection_duration?: Maybe<Scalars['Float']>;
  error_id?: Maybe<Scalars['Float']>;
  eyes_to_hip_height_in_cm?: Maybe<Scalars['Float']>;
  head_width_in_cm?: Maybe<Scalars['Float']>;
  knee_height_cm?: Maybe<Scalars['Float']>;
  outer_hip_width_in_cm?: Maybe<Scalars['Float']>;
  parting_to_hip_height_in_cm?: Maybe<Scalars['Float']>;
  shoulder_width_cm?: Maybe<Scalars['Float']>;
  thigh_width_in_cm?: Maybe<Scalars['Float']>;
  waist_width_in_cm?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "cv.body" */
export type Cv_Body_Stddev_Samp_Order_By = {
  arm_length_cm?: InputMaybe<Order_By>;
  biceps_width_in_cm?: InputMaybe<Order_By>;
  body_height_cm?: InputMaybe<Order_By>;
  chest_width_in_cm?: InputMaybe<Order_By>;
  /** corresponds to "step_length" in the customer answer */
  crotch_length_cm?: InputMaybe<Order_By>;
  detection_duration?: InputMaybe<Order_By>;
  error_id?: InputMaybe<Order_By>;
  eyes_to_hip_height_in_cm?: InputMaybe<Order_By>;
  head_width_in_cm?: InputMaybe<Order_By>;
  knee_height_cm?: InputMaybe<Order_By>;
  outer_hip_width_in_cm?: InputMaybe<Order_By>;
  parting_to_hip_height_in_cm?: InputMaybe<Order_By>;
  shoulder_width_cm?: InputMaybe<Order_By>;
  thigh_width_in_cm?: InputMaybe<Order_By>;
  waist_width_in_cm?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "cv_body" */
export type Cv_Body_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cv_Body_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Cv_Body_Stream_Cursor_Value_Input = {
  answer_id?: InputMaybe<Scalars['String']>;
  arm_length_cm?: InputMaybe<Scalars['float8']>;
  before_img_url?: InputMaybe<Scalars['String']>;
  biceps_width_in_cm?: InputMaybe<Scalars['float8']>;
  body_height_cm?: InputMaybe<Scalars['float8']>;
  body_landmarks?: InputMaybe<Scalars['jsonb']>;
  chest_width_in_cm?: InputMaybe<Scalars['float8']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  crotch_landmark?: InputMaybe<Scalars['jsonb']>;
  /** corresponds to "step_length" in the customer answer */
  crotch_length_cm?: InputMaybe<Scalars['float8']>;
  detection_duration?: InputMaybe<Scalars['float8']>;
  error_id?: InputMaybe<Scalars['Int']>;
  eyes_to_hip_height_in_cm?: InputMaybe<Scalars['float8']>;
  face_landmarks?: InputMaybe<Scalars['jsonb']>;
  final_image_url?: InputMaybe<Scalars['String']>;
  head_width_in_cm?: InputMaybe<Scalars['float8']>;
  heel_landmark?: InputMaybe<Scalars['jsonb']>;
  knee_height_cm?: InputMaybe<Scalars['float8']>;
  outer_hip_width_in_cm?: InputMaybe<Scalars['float8']>;
  parting_to_hip_height_in_cm?: InputMaybe<Scalars['float8']>;
  shoulder_width_cm?: InputMaybe<Scalars['float8']>;
  thigh_width_in_cm?: InputMaybe<Scalars['float8']>;
  waist_width_in_cm?: InputMaybe<Scalars['float8']>;
  warnings_id_list?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate sum on columns */
export type Cv_Body_Sum_Fields = {
  __typename?: 'cv_body_sum_fields';
  arm_length_cm?: Maybe<Scalars['float8']>;
  biceps_width_in_cm?: Maybe<Scalars['float8']>;
  body_height_cm?: Maybe<Scalars['float8']>;
  chest_width_in_cm?: Maybe<Scalars['float8']>;
  /** corresponds to "step_length" in the customer answer */
  crotch_length_cm?: Maybe<Scalars['float8']>;
  detection_duration?: Maybe<Scalars['float8']>;
  error_id?: Maybe<Scalars['Int']>;
  eyes_to_hip_height_in_cm?: Maybe<Scalars['float8']>;
  head_width_in_cm?: Maybe<Scalars['float8']>;
  knee_height_cm?: Maybe<Scalars['float8']>;
  outer_hip_width_in_cm?: Maybe<Scalars['float8']>;
  parting_to_hip_height_in_cm?: Maybe<Scalars['float8']>;
  shoulder_width_cm?: Maybe<Scalars['float8']>;
  thigh_width_in_cm?: Maybe<Scalars['float8']>;
  waist_width_in_cm?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "cv.body" */
export type Cv_Body_Sum_Order_By = {
  arm_length_cm?: InputMaybe<Order_By>;
  biceps_width_in_cm?: InputMaybe<Order_By>;
  body_height_cm?: InputMaybe<Order_By>;
  chest_width_in_cm?: InputMaybe<Order_By>;
  /** corresponds to "step_length" in the customer answer */
  crotch_length_cm?: InputMaybe<Order_By>;
  detection_duration?: InputMaybe<Order_By>;
  error_id?: InputMaybe<Order_By>;
  eyes_to_hip_height_in_cm?: InputMaybe<Order_By>;
  head_width_in_cm?: InputMaybe<Order_By>;
  knee_height_cm?: InputMaybe<Order_By>;
  outer_hip_width_in_cm?: InputMaybe<Order_By>;
  parting_to_hip_height_in_cm?: InputMaybe<Order_By>;
  shoulder_width_cm?: InputMaybe<Order_By>;
  thigh_width_in_cm?: InputMaybe<Order_By>;
  waist_width_in_cm?: InputMaybe<Order_By>;
};

/** update columns of table "cv.body" */
export enum Cv_Body_Update_Column {
  /** column name */
  AnswerId = 'answer_id',
  /** column name */
  ArmLengthCm = 'arm_length_cm',
  /** column name */
  BeforeImgUrl = 'before_img_url',
  /** column name */
  BicepsWidthInCm = 'biceps_width_in_cm',
  /** column name */
  BodyHeightCm = 'body_height_cm',
  /** column name */
  BodyLandmarks = 'body_landmarks',
  /** column name */
  ChestWidthInCm = 'chest_width_in_cm',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CrotchLandmark = 'crotch_landmark',
  /** column name */
  CrotchLengthCm = 'crotch_length_cm',
  /** column name */
  DetectionDuration = 'detection_duration',
  /** column name */
  ErrorId = 'error_id',
  /** column name */
  EyesToHipHeightInCm = 'eyes_to_hip_height_in_cm',
  /** column name */
  FaceLandmarks = 'face_landmarks',
  /** column name */
  FinalImageUrl = 'final_image_url',
  /** column name */
  HeadWidthInCm = 'head_width_in_cm',
  /** column name */
  HeelLandmark = 'heel_landmark',
  /** column name */
  KneeHeightCm = 'knee_height_cm',
  /** column name */
  OuterHipWidthInCm = 'outer_hip_width_in_cm',
  /** column name */
  PartingToHipHeightInCm = 'parting_to_hip_height_in_cm',
  /** column name */
  ShoulderWidthCm = 'shoulder_width_cm',
  /** column name */
  ThighWidthInCm = 'thigh_width_in_cm',
  /** column name */
  WaistWidthInCm = 'waist_width_in_cm',
  /** column name */
  WarningsIdList = 'warnings_id_list'
}

export type Cv_Body_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Cv_Body_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Cv_Body_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Cv_Body_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Cv_Body_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Cv_Body_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Cv_Body_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Cv_Body_Set_Input>;
  /** filter the rows which have to be updated */
  where: Cv_Body_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Cv_Body_Var_Pop_Fields = {
  __typename?: 'cv_body_var_pop_fields';
  arm_length_cm?: Maybe<Scalars['Float']>;
  biceps_width_in_cm?: Maybe<Scalars['Float']>;
  body_height_cm?: Maybe<Scalars['Float']>;
  chest_width_in_cm?: Maybe<Scalars['Float']>;
  /** corresponds to "step_length" in the customer answer */
  crotch_length_cm?: Maybe<Scalars['Float']>;
  detection_duration?: Maybe<Scalars['Float']>;
  error_id?: Maybe<Scalars['Float']>;
  eyes_to_hip_height_in_cm?: Maybe<Scalars['Float']>;
  head_width_in_cm?: Maybe<Scalars['Float']>;
  knee_height_cm?: Maybe<Scalars['Float']>;
  outer_hip_width_in_cm?: Maybe<Scalars['Float']>;
  parting_to_hip_height_in_cm?: Maybe<Scalars['Float']>;
  shoulder_width_cm?: Maybe<Scalars['Float']>;
  thigh_width_in_cm?: Maybe<Scalars['Float']>;
  waist_width_in_cm?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "cv.body" */
export type Cv_Body_Var_Pop_Order_By = {
  arm_length_cm?: InputMaybe<Order_By>;
  biceps_width_in_cm?: InputMaybe<Order_By>;
  body_height_cm?: InputMaybe<Order_By>;
  chest_width_in_cm?: InputMaybe<Order_By>;
  /** corresponds to "step_length" in the customer answer */
  crotch_length_cm?: InputMaybe<Order_By>;
  detection_duration?: InputMaybe<Order_By>;
  error_id?: InputMaybe<Order_By>;
  eyes_to_hip_height_in_cm?: InputMaybe<Order_By>;
  head_width_in_cm?: InputMaybe<Order_By>;
  knee_height_cm?: InputMaybe<Order_By>;
  outer_hip_width_in_cm?: InputMaybe<Order_By>;
  parting_to_hip_height_in_cm?: InputMaybe<Order_By>;
  shoulder_width_cm?: InputMaybe<Order_By>;
  thigh_width_in_cm?: InputMaybe<Order_By>;
  waist_width_in_cm?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Cv_Body_Var_Samp_Fields = {
  __typename?: 'cv_body_var_samp_fields';
  arm_length_cm?: Maybe<Scalars['Float']>;
  biceps_width_in_cm?: Maybe<Scalars['Float']>;
  body_height_cm?: Maybe<Scalars['Float']>;
  chest_width_in_cm?: Maybe<Scalars['Float']>;
  /** corresponds to "step_length" in the customer answer */
  crotch_length_cm?: Maybe<Scalars['Float']>;
  detection_duration?: Maybe<Scalars['Float']>;
  error_id?: Maybe<Scalars['Float']>;
  eyes_to_hip_height_in_cm?: Maybe<Scalars['Float']>;
  head_width_in_cm?: Maybe<Scalars['Float']>;
  knee_height_cm?: Maybe<Scalars['Float']>;
  outer_hip_width_in_cm?: Maybe<Scalars['Float']>;
  parting_to_hip_height_in_cm?: Maybe<Scalars['Float']>;
  shoulder_width_cm?: Maybe<Scalars['Float']>;
  thigh_width_in_cm?: Maybe<Scalars['Float']>;
  waist_width_in_cm?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "cv.body" */
export type Cv_Body_Var_Samp_Order_By = {
  arm_length_cm?: InputMaybe<Order_By>;
  biceps_width_in_cm?: InputMaybe<Order_By>;
  body_height_cm?: InputMaybe<Order_By>;
  chest_width_in_cm?: InputMaybe<Order_By>;
  /** corresponds to "step_length" in the customer answer */
  crotch_length_cm?: InputMaybe<Order_By>;
  detection_duration?: InputMaybe<Order_By>;
  error_id?: InputMaybe<Order_By>;
  eyes_to_hip_height_in_cm?: InputMaybe<Order_By>;
  head_width_in_cm?: InputMaybe<Order_By>;
  knee_height_cm?: InputMaybe<Order_By>;
  outer_hip_width_in_cm?: InputMaybe<Order_By>;
  parting_to_hip_height_in_cm?: InputMaybe<Order_By>;
  shoulder_width_cm?: InputMaybe<Order_By>;
  thigh_width_in_cm?: InputMaybe<Order_By>;
  waist_width_in_cm?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Cv_Body_Variance_Fields = {
  __typename?: 'cv_body_variance_fields';
  arm_length_cm?: Maybe<Scalars['Float']>;
  biceps_width_in_cm?: Maybe<Scalars['Float']>;
  body_height_cm?: Maybe<Scalars['Float']>;
  chest_width_in_cm?: Maybe<Scalars['Float']>;
  /** corresponds to "step_length" in the customer answer */
  crotch_length_cm?: Maybe<Scalars['Float']>;
  detection_duration?: Maybe<Scalars['Float']>;
  error_id?: Maybe<Scalars['Float']>;
  eyes_to_hip_height_in_cm?: Maybe<Scalars['Float']>;
  head_width_in_cm?: Maybe<Scalars['Float']>;
  knee_height_cm?: Maybe<Scalars['Float']>;
  outer_hip_width_in_cm?: Maybe<Scalars['Float']>;
  parting_to_hip_height_in_cm?: Maybe<Scalars['Float']>;
  shoulder_width_cm?: Maybe<Scalars['Float']>;
  thigh_width_in_cm?: Maybe<Scalars['Float']>;
  waist_width_in_cm?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "cv.body" */
export type Cv_Body_Variance_Order_By = {
  arm_length_cm?: InputMaybe<Order_By>;
  biceps_width_in_cm?: InputMaybe<Order_By>;
  body_height_cm?: InputMaybe<Order_By>;
  chest_width_in_cm?: InputMaybe<Order_By>;
  /** corresponds to "step_length" in the customer answer */
  crotch_length_cm?: InputMaybe<Order_By>;
  detection_duration?: InputMaybe<Order_By>;
  error_id?: InputMaybe<Order_By>;
  eyes_to_hip_height_in_cm?: InputMaybe<Order_By>;
  head_width_in_cm?: InputMaybe<Order_By>;
  knee_height_cm?: InputMaybe<Order_By>;
  outer_hip_width_in_cm?: InputMaybe<Order_By>;
  parting_to_hip_height_in_cm?: InputMaybe<Order_By>;
  shoulder_width_cm?: InputMaybe<Order_By>;
  thigh_width_in_cm?: InputMaybe<Order_By>;
  waist_width_in_cm?: InputMaybe<Order_By>;
};

/** columns and relationships of "cv.handmarks" */
export type Cv_Handmarks = {
  __typename?: 'cv_handmarks';
  /** An object relationship */
  answer: Customer_Answer;
  answer_id: Scalars['String'];
  before_img_url: Scalars['String'];
  created_at: Scalars['timestamptz'];
  detected_key_points: Scalars['jsonb'];
  detection_duration: Scalars['float8'];
  error_id?: Maybe<Scalars['Int']>;
  grip_size_label?: Maybe<Scalars['String']>;
  hand_height: Scalars['float8'];
  hand_width: Scalars['float8'];
  handmark_list: Scalars['jsonb'];
  is_3d: Scalars['Boolean'];
  result_img_url: Scalars['String'];
  warning_id_list: Scalars['jsonb'];
};


/** columns and relationships of "cv.handmarks" */
export type Cv_HandmarksDetected_Key_PointsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "cv.handmarks" */
export type Cv_HandmarksHandmark_ListArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "cv.handmarks" */
export type Cv_HandmarksWarning_Id_ListArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "cv.handmarks" */
export type Cv_Handmarks_Aggregate = {
  __typename?: 'cv_handmarks_aggregate';
  aggregate?: Maybe<Cv_Handmarks_Aggregate_Fields>;
  nodes: Array<Cv_Handmarks>;
};

export type Cv_Handmarks_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Cv_Handmarks_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<Cv_Handmarks_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Cv_Handmarks_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<Cv_Handmarks_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Cv_Handmarks_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Cv_Handmarks_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Cv_Handmarks_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Cv_Handmarks_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Cv_Handmarks_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Cv_Handmarks_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Cv_Handmarks_Aggregate_Bool_Exp_Var_Samp>;
};

export type Cv_Handmarks_Aggregate_Bool_Exp_Avg = {
  arguments: Cv_Handmarks_Select_Column_Cv_Handmarks_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cv_Handmarks_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Cv_Handmarks_Aggregate_Bool_Exp_Bool_And = {
  arguments: Cv_Handmarks_Select_Column_Cv_Handmarks_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cv_Handmarks_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Cv_Handmarks_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Cv_Handmarks_Select_Column_Cv_Handmarks_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cv_Handmarks_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Cv_Handmarks_Aggregate_Bool_Exp_Corr = {
  arguments: Cv_Handmarks_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cv_Handmarks_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Cv_Handmarks_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Cv_Handmarks_Select_Column_Cv_Handmarks_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Cv_Handmarks_Select_Column_Cv_Handmarks_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Cv_Handmarks_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Cv_Handmarks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cv_Handmarks_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Cv_Handmarks_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Cv_Handmarks_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cv_Handmarks_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Cv_Handmarks_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Cv_Handmarks_Select_Column_Cv_Handmarks_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Cv_Handmarks_Select_Column_Cv_Handmarks_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Cv_Handmarks_Aggregate_Bool_Exp_Max = {
  arguments: Cv_Handmarks_Select_Column_Cv_Handmarks_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cv_Handmarks_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Cv_Handmarks_Aggregate_Bool_Exp_Min = {
  arguments: Cv_Handmarks_Select_Column_Cv_Handmarks_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cv_Handmarks_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Cv_Handmarks_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Cv_Handmarks_Select_Column_Cv_Handmarks_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cv_Handmarks_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Cv_Handmarks_Aggregate_Bool_Exp_Sum = {
  arguments: Cv_Handmarks_Select_Column_Cv_Handmarks_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cv_Handmarks_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Cv_Handmarks_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Cv_Handmarks_Select_Column_Cv_Handmarks_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cv_Handmarks_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "cv.handmarks" */
export type Cv_Handmarks_Aggregate_Fields = {
  __typename?: 'cv_handmarks_aggregate_fields';
  avg?: Maybe<Cv_Handmarks_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Cv_Handmarks_Max_Fields>;
  min?: Maybe<Cv_Handmarks_Min_Fields>;
  stddev?: Maybe<Cv_Handmarks_Stddev_Fields>;
  stddev_pop?: Maybe<Cv_Handmarks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Cv_Handmarks_Stddev_Samp_Fields>;
  sum?: Maybe<Cv_Handmarks_Sum_Fields>;
  var_pop?: Maybe<Cv_Handmarks_Var_Pop_Fields>;
  var_samp?: Maybe<Cv_Handmarks_Var_Samp_Fields>;
  variance?: Maybe<Cv_Handmarks_Variance_Fields>;
};


/** aggregate fields of "cv.handmarks" */
export type Cv_Handmarks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Cv_Handmarks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "cv.handmarks" */
export type Cv_Handmarks_Aggregate_Order_By = {
  avg?: InputMaybe<Cv_Handmarks_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Cv_Handmarks_Max_Order_By>;
  min?: InputMaybe<Cv_Handmarks_Min_Order_By>;
  stddev?: InputMaybe<Cv_Handmarks_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Cv_Handmarks_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Cv_Handmarks_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Cv_Handmarks_Sum_Order_By>;
  var_pop?: InputMaybe<Cv_Handmarks_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Cv_Handmarks_Var_Samp_Order_By>;
  variance?: InputMaybe<Cv_Handmarks_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Cv_Handmarks_Append_Input = {
  detected_key_points?: InputMaybe<Scalars['jsonb']>;
  handmark_list?: InputMaybe<Scalars['jsonb']>;
  warning_id_list?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "cv.handmarks" */
export type Cv_Handmarks_Arr_Rel_Insert_Input = {
  data: Array<Cv_Handmarks_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Cv_Handmarks_On_Conflict>;
};

/** aggregate avg on columns */
export type Cv_Handmarks_Avg_Fields = {
  __typename?: 'cv_handmarks_avg_fields';
  detection_duration?: Maybe<Scalars['Float']>;
  error_id?: Maybe<Scalars['Float']>;
  hand_height?: Maybe<Scalars['Float']>;
  hand_width?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "cv.handmarks" */
export type Cv_Handmarks_Avg_Order_By = {
  detection_duration?: InputMaybe<Order_By>;
  error_id?: InputMaybe<Order_By>;
  hand_height?: InputMaybe<Order_By>;
  hand_width?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "cv.handmarks". All fields are combined with a logical 'AND'. */
export type Cv_Handmarks_Bool_Exp = {
  _and?: InputMaybe<Array<Cv_Handmarks_Bool_Exp>>;
  _not?: InputMaybe<Cv_Handmarks_Bool_Exp>;
  _or?: InputMaybe<Array<Cv_Handmarks_Bool_Exp>>;
  answer?: InputMaybe<Customer_Answer_Bool_Exp>;
  answer_id?: InputMaybe<String_Comparison_Exp>;
  before_img_url?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  detected_key_points?: InputMaybe<Jsonb_Comparison_Exp>;
  detection_duration?: InputMaybe<Float8_Comparison_Exp>;
  error_id?: InputMaybe<Int_Comparison_Exp>;
  grip_size_label?: InputMaybe<String_Comparison_Exp>;
  hand_height?: InputMaybe<Float8_Comparison_Exp>;
  hand_width?: InputMaybe<Float8_Comparison_Exp>;
  handmark_list?: InputMaybe<Jsonb_Comparison_Exp>;
  is_3d?: InputMaybe<Boolean_Comparison_Exp>;
  result_img_url?: InputMaybe<String_Comparison_Exp>;
  warning_id_list?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "cv.handmarks" */
export enum Cv_Handmarks_Constraint {
  /** unique or primary key constraint on columns "answer_id", "created_at" */
  HandmarksPkey = 'handmarks_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Cv_Handmarks_Delete_At_Path_Input = {
  detected_key_points?: InputMaybe<Array<Scalars['String']>>;
  handmark_list?: InputMaybe<Array<Scalars['String']>>;
  warning_id_list?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Cv_Handmarks_Delete_Elem_Input = {
  detected_key_points?: InputMaybe<Scalars['Int']>;
  handmark_list?: InputMaybe<Scalars['Int']>;
  warning_id_list?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Cv_Handmarks_Delete_Key_Input = {
  detected_key_points?: InputMaybe<Scalars['String']>;
  handmark_list?: InputMaybe<Scalars['String']>;
  warning_id_list?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "cv.handmarks" */
export type Cv_Handmarks_Inc_Input = {
  detection_duration?: InputMaybe<Scalars['float8']>;
  error_id?: InputMaybe<Scalars['Int']>;
  hand_height?: InputMaybe<Scalars['float8']>;
  hand_width?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "cv.handmarks" */
export type Cv_Handmarks_Insert_Input = {
  answer?: InputMaybe<Customer_Answer_Obj_Rel_Insert_Input>;
  answer_id?: InputMaybe<Scalars['String']>;
  before_img_url?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  detected_key_points?: InputMaybe<Scalars['jsonb']>;
  detection_duration?: InputMaybe<Scalars['float8']>;
  error_id?: InputMaybe<Scalars['Int']>;
  grip_size_label?: InputMaybe<Scalars['String']>;
  hand_height?: InputMaybe<Scalars['float8']>;
  hand_width?: InputMaybe<Scalars['float8']>;
  handmark_list?: InputMaybe<Scalars['jsonb']>;
  is_3d?: InputMaybe<Scalars['Boolean']>;
  result_img_url?: InputMaybe<Scalars['String']>;
  warning_id_list?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type Cv_Handmarks_Max_Fields = {
  __typename?: 'cv_handmarks_max_fields';
  answer_id?: Maybe<Scalars['String']>;
  before_img_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  detection_duration?: Maybe<Scalars['float8']>;
  error_id?: Maybe<Scalars['Int']>;
  grip_size_label?: Maybe<Scalars['String']>;
  hand_height?: Maybe<Scalars['float8']>;
  hand_width?: Maybe<Scalars['float8']>;
  result_img_url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "cv.handmarks" */
export type Cv_Handmarks_Max_Order_By = {
  answer_id?: InputMaybe<Order_By>;
  before_img_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  detection_duration?: InputMaybe<Order_By>;
  error_id?: InputMaybe<Order_By>;
  grip_size_label?: InputMaybe<Order_By>;
  hand_height?: InputMaybe<Order_By>;
  hand_width?: InputMaybe<Order_By>;
  result_img_url?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Cv_Handmarks_Min_Fields = {
  __typename?: 'cv_handmarks_min_fields';
  answer_id?: Maybe<Scalars['String']>;
  before_img_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  detection_duration?: Maybe<Scalars['float8']>;
  error_id?: Maybe<Scalars['Int']>;
  grip_size_label?: Maybe<Scalars['String']>;
  hand_height?: Maybe<Scalars['float8']>;
  hand_width?: Maybe<Scalars['float8']>;
  result_img_url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "cv.handmarks" */
export type Cv_Handmarks_Min_Order_By = {
  answer_id?: InputMaybe<Order_By>;
  before_img_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  detection_duration?: InputMaybe<Order_By>;
  error_id?: InputMaybe<Order_By>;
  grip_size_label?: InputMaybe<Order_By>;
  hand_height?: InputMaybe<Order_By>;
  hand_width?: InputMaybe<Order_By>;
  result_img_url?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "cv.handmarks" */
export type Cv_Handmarks_Mutation_Response = {
  __typename?: 'cv_handmarks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Cv_Handmarks>;
};

/** on_conflict condition type for table "cv.handmarks" */
export type Cv_Handmarks_On_Conflict = {
  constraint: Cv_Handmarks_Constraint;
  update_columns?: Array<Cv_Handmarks_Update_Column>;
  where?: InputMaybe<Cv_Handmarks_Bool_Exp>;
};

/** Ordering options when selecting data from "cv.handmarks". */
export type Cv_Handmarks_Order_By = {
  answer?: InputMaybe<Customer_Answer_Order_By>;
  answer_id?: InputMaybe<Order_By>;
  before_img_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  detected_key_points?: InputMaybe<Order_By>;
  detection_duration?: InputMaybe<Order_By>;
  error_id?: InputMaybe<Order_By>;
  grip_size_label?: InputMaybe<Order_By>;
  hand_height?: InputMaybe<Order_By>;
  hand_width?: InputMaybe<Order_By>;
  handmark_list?: InputMaybe<Order_By>;
  is_3d?: InputMaybe<Order_By>;
  result_img_url?: InputMaybe<Order_By>;
  warning_id_list?: InputMaybe<Order_By>;
};

/** primary key columns input for table: cv.handmarks */
export type Cv_Handmarks_Pk_Columns_Input = {
  answer_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Cv_Handmarks_Prepend_Input = {
  detected_key_points?: InputMaybe<Scalars['jsonb']>;
  handmark_list?: InputMaybe<Scalars['jsonb']>;
  warning_id_list?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "cv.handmarks" */
export enum Cv_Handmarks_Select_Column {
  /** column name */
  AnswerId = 'answer_id',
  /** column name */
  BeforeImgUrl = 'before_img_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DetectedKeyPoints = 'detected_key_points',
  /** column name */
  DetectionDuration = 'detection_duration',
  /** column name */
  ErrorId = 'error_id',
  /** column name */
  GripSizeLabel = 'grip_size_label',
  /** column name */
  HandHeight = 'hand_height',
  /** column name */
  HandWidth = 'hand_width',
  /** column name */
  HandmarkList = 'handmark_list',
  /** column name */
  Is_3d = 'is_3d',
  /** column name */
  ResultImgUrl = 'result_img_url',
  /** column name */
  WarningIdList = 'warning_id_list'
}

/** select "cv_handmarks_aggregate_bool_exp_avg_arguments_columns" columns of table "cv.handmarks" */
export enum Cv_Handmarks_Select_Column_Cv_Handmarks_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  DetectionDuration = 'detection_duration',
  /** column name */
  HandHeight = 'hand_height',
  /** column name */
  HandWidth = 'hand_width'
}

/** select "cv_handmarks_aggregate_bool_exp_bool_and_arguments_columns" columns of table "cv.handmarks" */
export enum Cv_Handmarks_Select_Column_Cv_Handmarks_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Is_3d = 'is_3d'
}

/** select "cv_handmarks_aggregate_bool_exp_bool_or_arguments_columns" columns of table "cv.handmarks" */
export enum Cv_Handmarks_Select_Column_Cv_Handmarks_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Is_3d = 'is_3d'
}

/** select "cv_handmarks_aggregate_bool_exp_corr_arguments_columns" columns of table "cv.handmarks" */
export enum Cv_Handmarks_Select_Column_Cv_Handmarks_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  DetectionDuration = 'detection_duration',
  /** column name */
  HandHeight = 'hand_height',
  /** column name */
  HandWidth = 'hand_width'
}

/** select "cv_handmarks_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "cv.handmarks" */
export enum Cv_Handmarks_Select_Column_Cv_Handmarks_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  DetectionDuration = 'detection_duration',
  /** column name */
  HandHeight = 'hand_height',
  /** column name */
  HandWidth = 'hand_width'
}

/** select "cv_handmarks_aggregate_bool_exp_max_arguments_columns" columns of table "cv.handmarks" */
export enum Cv_Handmarks_Select_Column_Cv_Handmarks_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  DetectionDuration = 'detection_duration',
  /** column name */
  HandHeight = 'hand_height',
  /** column name */
  HandWidth = 'hand_width'
}

/** select "cv_handmarks_aggregate_bool_exp_min_arguments_columns" columns of table "cv.handmarks" */
export enum Cv_Handmarks_Select_Column_Cv_Handmarks_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  DetectionDuration = 'detection_duration',
  /** column name */
  HandHeight = 'hand_height',
  /** column name */
  HandWidth = 'hand_width'
}

/** select "cv_handmarks_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "cv.handmarks" */
export enum Cv_Handmarks_Select_Column_Cv_Handmarks_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  DetectionDuration = 'detection_duration',
  /** column name */
  HandHeight = 'hand_height',
  /** column name */
  HandWidth = 'hand_width'
}

/** select "cv_handmarks_aggregate_bool_exp_sum_arguments_columns" columns of table "cv.handmarks" */
export enum Cv_Handmarks_Select_Column_Cv_Handmarks_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  DetectionDuration = 'detection_duration',
  /** column name */
  HandHeight = 'hand_height',
  /** column name */
  HandWidth = 'hand_width'
}

/** select "cv_handmarks_aggregate_bool_exp_var_samp_arguments_columns" columns of table "cv.handmarks" */
export enum Cv_Handmarks_Select_Column_Cv_Handmarks_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  DetectionDuration = 'detection_duration',
  /** column name */
  HandHeight = 'hand_height',
  /** column name */
  HandWidth = 'hand_width'
}

/** input type for updating data in table "cv.handmarks" */
export type Cv_Handmarks_Set_Input = {
  answer_id?: InputMaybe<Scalars['String']>;
  before_img_url?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  detected_key_points?: InputMaybe<Scalars['jsonb']>;
  detection_duration?: InputMaybe<Scalars['float8']>;
  error_id?: InputMaybe<Scalars['Int']>;
  grip_size_label?: InputMaybe<Scalars['String']>;
  hand_height?: InputMaybe<Scalars['float8']>;
  hand_width?: InputMaybe<Scalars['float8']>;
  handmark_list?: InputMaybe<Scalars['jsonb']>;
  is_3d?: InputMaybe<Scalars['Boolean']>;
  result_img_url?: InputMaybe<Scalars['String']>;
  warning_id_list?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate stddev on columns */
export type Cv_Handmarks_Stddev_Fields = {
  __typename?: 'cv_handmarks_stddev_fields';
  detection_duration?: Maybe<Scalars['Float']>;
  error_id?: Maybe<Scalars['Float']>;
  hand_height?: Maybe<Scalars['Float']>;
  hand_width?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "cv.handmarks" */
export type Cv_Handmarks_Stddev_Order_By = {
  detection_duration?: InputMaybe<Order_By>;
  error_id?: InputMaybe<Order_By>;
  hand_height?: InputMaybe<Order_By>;
  hand_width?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Cv_Handmarks_Stddev_Pop_Fields = {
  __typename?: 'cv_handmarks_stddev_pop_fields';
  detection_duration?: Maybe<Scalars['Float']>;
  error_id?: Maybe<Scalars['Float']>;
  hand_height?: Maybe<Scalars['Float']>;
  hand_width?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "cv.handmarks" */
export type Cv_Handmarks_Stddev_Pop_Order_By = {
  detection_duration?: InputMaybe<Order_By>;
  error_id?: InputMaybe<Order_By>;
  hand_height?: InputMaybe<Order_By>;
  hand_width?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Cv_Handmarks_Stddev_Samp_Fields = {
  __typename?: 'cv_handmarks_stddev_samp_fields';
  detection_duration?: Maybe<Scalars['Float']>;
  error_id?: Maybe<Scalars['Float']>;
  hand_height?: Maybe<Scalars['Float']>;
  hand_width?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "cv.handmarks" */
export type Cv_Handmarks_Stddev_Samp_Order_By = {
  detection_duration?: InputMaybe<Order_By>;
  error_id?: InputMaybe<Order_By>;
  hand_height?: InputMaybe<Order_By>;
  hand_width?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "cv_handmarks" */
export type Cv_Handmarks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cv_Handmarks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Cv_Handmarks_Stream_Cursor_Value_Input = {
  answer_id?: InputMaybe<Scalars['String']>;
  before_img_url?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  detected_key_points?: InputMaybe<Scalars['jsonb']>;
  detection_duration?: InputMaybe<Scalars['float8']>;
  error_id?: InputMaybe<Scalars['Int']>;
  grip_size_label?: InputMaybe<Scalars['String']>;
  hand_height?: InputMaybe<Scalars['float8']>;
  hand_width?: InputMaybe<Scalars['float8']>;
  handmark_list?: InputMaybe<Scalars['jsonb']>;
  is_3d?: InputMaybe<Scalars['Boolean']>;
  result_img_url?: InputMaybe<Scalars['String']>;
  warning_id_list?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate sum on columns */
export type Cv_Handmarks_Sum_Fields = {
  __typename?: 'cv_handmarks_sum_fields';
  detection_duration?: Maybe<Scalars['float8']>;
  error_id?: Maybe<Scalars['Int']>;
  hand_height?: Maybe<Scalars['float8']>;
  hand_width?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "cv.handmarks" */
export type Cv_Handmarks_Sum_Order_By = {
  detection_duration?: InputMaybe<Order_By>;
  error_id?: InputMaybe<Order_By>;
  hand_height?: InputMaybe<Order_By>;
  hand_width?: InputMaybe<Order_By>;
};

/** update columns of table "cv.handmarks" */
export enum Cv_Handmarks_Update_Column {
  /** column name */
  AnswerId = 'answer_id',
  /** column name */
  BeforeImgUrl = 'before_img_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DetectedKeyPoints = 'detected_key_points',
  /** column name */
  DetectionDuration = 'detection_duration',
  /** column name */
  ErrorId = 'error_id',
  /** column name */
  GripSizeLabel = 'grip_size_label',
  /** column name */
  HandHeight = 'hand_height',
  /** column name */
  HandWidth = 'hand_width',
  /** column name */
  HandmarkList = 'handmark_list',
  /** column name */
  Is_3d = 'is_3d',
  /** column name */
  ResultImgUrl = 'result_img_url',
  /** column name */
  WarningIdList = 'warning_id_list'
}

export type Cv_Handmarks_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Cv_Handmarks_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Cv_Handmarks_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Cv_Handmarks_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Cv_Handmarks_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Cv_Handmarks_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Cv_Handmarks_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Cv_Handmarks_Set_Input>;
  /** filter the rows which have to be updated */
  where: Cv_Handmarks_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Cv_Handmarks_Var_Pop_Fields = {
  __typename?: 'cv_handmarks_var_pop_fields';
  detection_duration?: Maybe<Scalars['Float']>;
  error_id?: Maybe<Scalars['Float']>;
  hand_height?: Maybe<Scalars['Float']>;
  hand_width?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "cv.handmarks" */
export type Cv_Handmarks_Var_Pop_Order_By = {
  detection_duration?: InputMaybe<Order_By>;
  error_id?: InputMaybe<Order_By>;
  hand_height?: InputMaybe<Order_By>;
  hand_width?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Cv_Handmarks_Var_Samp_Fields = {
  __typename?: 'cv_handmarks_var_samp_fields';
  detection_duration?: Maybe<Scalars['Float']>;
  error_id?: Maybe<Scalars['Float']>;
  hand_height?: Maybe<Scalars['Float']>;
  hand_width?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "cv.handmarks" */
export type Cv_Handmarks_Var_Samp_Order_By = {
  detection_duration?: InputMaybe<Order_By>;
  error_id?: InputMaybe<Order_By>;
  hand_height?: InputMaybe<Order_By>;
  hand_width?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Cv_Handmarks_Variance_Fields = {
  __typename?: 'cv_handmarks_variance_fields';
  detection_duration?: Maybe<Scalars['Float']>;
  error_id?: Maybe<Scalars['Float']>;
  hand_height?: Maybe<Scalars['Float']>;
  hand_width?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "cv.handmarks" */
export type Cv_Handmarks_Variance_Order_By = {
  detection_duration?: InputMaybe<Order_By>;
  error_id?: InputMaybe<Order_By>;
  hand_height?: InputMaybe<Order_By>;
  hand_width?: InputMaybe<Order_By>;
};

/** corresponds to "seat_bones" in the customer answer */
export type Cv_Sitbones = {
  __typename?: 'cv_sitbones';
  /** An object relationship */
  answer: Customer_Answer;
  answer_id: Scalars['String'];
  before_img_url: Scalars['String'];
  blob_list: Scalars['jsonb'];
  coordinates_cluster1: Scalars['jsonb'];
  coordinates_cluster2: Scalars['jsonb'];
  created_at: Scalars['timestamptz'];
  detected_corner: Scalars['Boolean'];
  detection_duration: Scalars['float8'];
  error_id?: Maybe<Scalars['Int']>;
  indicator_asymmetry?: Maybe<Scalars['float8']>;
  number_blobs_cluster1: Scalars['Int'];
  number_blobs_cluster2: Scalars['Int'];
  reference_size: Scalars['float8'];
  result_img_url: Scalars['String'];
  /** corresponds to "seat_bone_distance" in the customer answer */
  sitbone_distance: Scalars['float8'];
  std_deviation_cluster1: Scalars['float8'];
  std_deviation_cluster2: Scalars['float8'];
  warning_id_list: Scalars['jsonb'];
};


/** corresponds to "seat_bones" in the customer answer */
export type Cv_SitbonesBlob_ListArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** corresponds to "seat_bones" in the customer answer */
export type Cv_SitbonesCoordinates_Cluster1Args = {
  path?: InputMaybe<Scalars['String']>;
};


/** corresponds to "seat_bones" in the customer answer */
export type Cv_SitbonesCoordinates_Cluster2Args = {
  path?: InputMaybe<Scalars['String']>;
};


/** corresponds to "seat_bones" in the customer answer */
export type Cv_SitbonesWarning_Id_ListArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "cv.sitbones" */
export type Cv_Sitbones_Aggregate = {
  __typename?: 'cv_sitbones_aggregate';
  aggregate?: Maybe<Cv_Sitbones_Aggregate_Fields>;
  nodes: Array<Cv_Sitbones>;
};

export type Cv_Sitbones_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Cv_Sitbones_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<Cv_Sitbones_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Cv_Sitbones_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<Cv_Sitbones_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Cv_Sitbones_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Cv_Sitbones_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Cv_Sitbones_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Cv_Sitbones_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Cv_Sitbones_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Cv_Sitbones_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Cv_Sitbones_Aggregate_Bool_Exp_Var_Samp>;
};

export type Cv_Sitbones_Aggregate_Bool_Exp_Avg = {
  arguments: Cv_Sitbones_Select_Column_Cv_Sitbones_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cv_Sitbones_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Cv_Sitbones_Aggregate_Bool_Exp_Bool_And = {
  arguments: Cv_Sitbones_Select_Column_Cv_Sitbones_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cv_Sitbones_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Cv_Sitbones_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Cv_Sitbones_Select_Column_Cv_Sitbones_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cv_Sitbones_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Cv_Sitbones_Aggregate_Bool_Exp_Corr = {
  arguments: Cv_Sitbones_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cv_Sitbones_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Cv_Sitbones_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Cv_Sitbones_Select_Column_Cv_Sitbones_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Cv_Sitbones_Select_Column_Cv_Sitbones_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Cv_Sitbones_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Cv_Sitbones_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cv_Sitbones_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Cv_Sitbones_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Cv_Sitbones_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cv_Sitbones_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Cv_Sitbones_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Cv_Sitbones_Select_Column_Cv_Sitbones_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Cv_Sitbones_Select_Column_Cv_Sitbones_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Cv_Sitbones_Aggregate_Bool_Exp_Max = {
  arguments: Cv_Sitbones_Select_Column_Cv_Sitbones_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cv_Sitbones_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Cv_Sitbones_Aggregate_Bool_Exp_Min = {
  arguments: Cv_Sitbones_Select_Column_Cv_Sitbones_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cv_Sitbones_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Cv_Sitbones_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Cv_Sitbones_Select_Column_Cv_Sitbones_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cv_Sitbones_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Cv_Sitbones_Aggregate_Bool_Exp_Sum = {
  arguments: Cv_Sitbones_Select_Column_Cv_Sitbones_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cv_Sitbones_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Cv_Sitbones_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Cv_Sitbones_Select_Column_Cv_Sitbones_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cv_Sitbones_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "cv.sitbones" */
export type Cv_Sitbones_Aggregate_Fields = {
  __typename?: 'cv_sitbones_aggregate_fields';
  avg?: Maybe<Cv_Sitbones_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Cv_Sitbones_Max_Fields>;
  min?: Maybe<Cv_Sitbones_Min_Fields>;
  stddev?: Maybe<Cv_Sitbones_Stddev_Fields>;
  stddev_pop?: Maybe<Cv_Sitbones_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Cv_Sitbones_Stddev_Samp_Fields>;
  sum?: Maybe<Cv_Sitbones_Sum_Fields>;
  var_pop?: Maybe<Cv_Sitbones_Var_Pop_Fields>;
  var_samp?: Maybe<Cv_Sitbones_Var_Samp_Fields>;
  variance?: Maybe<Cv_Sitbones_Variance_Fields>;
};


/** aggregate fields of "cv.sitbones" */
export type Cv_Sitbones_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Cv_Sitbones_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "cv.sitbones" */
export type Cv_Sitbones_Aggregate_Order_By = {
  avg?: InputMaybe<Cv_Sitbones_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Cv_Sitbones_Max_Order_By>;
  min?: InputMaybe<Cv_Sitbones_Min_Order_By>;
  stddev?: InputMaybe<Cv_Sitbones_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Cv_Sitbones_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Cv_Sitbones_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Cv_Sitbones_Sum_Order_By>;
  var_pop?: InputMaybe<Cv_Sitbones_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Cv_Sitbones_Var_Samp_Order_By>;
  variance?: InputMaybe<Cv_Sitbones_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Cv_Sitbones_Append_Input = {
  blob_list?: InputMaybe<Scalars['jsonb']>;
  coordinates_cluster1?: InputMaybe<Scalars['jsonb']>;
  coordinates_cluster2?: InputMaybe<Scalars['jsonb']>;
  warning_id_list?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "cv.sitbones" */
export type Cv_Sitbones_Arr_Rel_Insert_Input = {
  data: Array<Cv_Sitbones_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Cv_Sitbones_On_Conflict>;
};

/** aggregate avg on columns */
export type Cv_Sitbones_Avg_Fields = {
  __typename?: 'cv_sitbones_avg_fields';
  detection_duration?: Maybe<Scalars['Float']>;
  error_id?: Maybe<Scalars['Float']>;
  indicator_asymmetry?: Maybe<Scalars['Float']>;
  number_blobs_cluster1?: Maybe<Scalars['Float']>;
  number_blobs_cluster2?: Maybe<Scalars['Float']>;
  reference_size?: Maybe<Scalars['Float']>;
  /** corresponds to "seat_bone_distance" in the customer answer */
  sitbone_distance?: Maybe<Scalars['Float']>;
  std_deviation_cluster1?: Maybe<Scalars['Float']>;
  std_deviation_cluster2?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "cv.sitbones" */
export type Cv_Sitbones_Avg_Order_By = {
  detection_duration?: InputMaybe<Order_By>;
  error_id?: InputMaybe<Order_By>;
  indicator_asymmetry?: InputMaybe<Order_By>;
  number_blobs_cluster1?: InputMaybe<Order_By>;
  number_blobs_cluster2?: InputMaybe<Order_By>;
  reference_size?: InputMaybe<Order_By>;
  /** corresponds to "seat_bone_distance" in the customer answer */
  sitbone_distance?: InputMaybe<Order_By>;
  std_deviation_cluster1?: InputMaybe<Order_By>;
  std_deviation_cluster2?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "cv.sitbones". All fields are combined with a logical 'AND'. */
export type Cv_Sitbones_Bool_Exp = {
  _and?: InputMaybe<Array<Cv_Sitbones_Bool_Exp>>;
  _not?: InputMaybe<Cv_Sitbones_Bool_Exp>;
  _or?: InputMaybe<Array<Cv_Sitbones_Bool_Exp>>;
  answer?: InputMaybe<Customer_Answer_Bool_Exp>;
  answer_id?: InputMaybe<String_Comparison_Exp>;
  before_img_url?: InputMaybe<String_Comparison_Exp>;
  blob_list?: InputMaybe<Jsonb_Comparison_Exp>;
  coordinates_cluster1?: InputMaybe<Jsonb_Comparison_Exp>;
  coordinates_cluster2?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  detected_corner?: InputMaybe<Boolean_Comparison_Exp>;
  detection_duration?: InputMaybe<Float8_Comparison_Exp>;
  error_id?: InputMaybe<Int_Comparison_Exp>;
  indicator_asymmetry?: InputMaybe<Float8_Comparison_Exp>;
  number_blobs_cluster1?: InputMaybe<Int_Comparison_Exp>;
  number_blobs_cluster2?: InputMaybe<Int_Comparison_Exp>;
  reference_size?: InputMaybe<Float8_Comparison_Exp>;
  result_img_url?: InputMaybe<String_Comparison_Exp>;
  sitbone_distance?: InputMaybe<Float8_Comparison_Exp>;
  std_deviation_cluster1?: InputMaybe<Float8_Comparison_Exp>;
  std_deviation_cluster2?: InputMaybe<Float8_Comparison_Exp>;
  warning_id_list?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "cv.sitbones" */
export enum Cv_Sitbones_Constraint {
  /** unique or primary key constraint on columns "answer_id", "created_at" */
  SitbonesPkey = 'sitbones_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Cv_Sitbones_Delete_At_Path_Input = {
  blob_list?: InputMaybe<Array<Scalars['String']>>;
  coordinates_cluster1?: InputMaybe<Array<Scalars['String']>>;
  coordinates_cluster2?: InputMaybe<Array<Scalars['String']>>;
  warning_id_list?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Cv_Sitbones_Delete_Elem_Input = {
  blob_list?: InputMaybe<Scalars['Int']>;
  coordinates_cluster1?: InputMaybe<Scalars['Int']>;
  coordinates_cluster2?: InputMaybe<Scalars['Int']>;
  warning_id_list?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Cv_Sitbones_Delete_Key_Input = {
  blob_list?: InputMaybe<Scalars['String']>;
  coordinates_cluster1?: InputMaybe<Scalars['String']>;
  coordinates_cluster2?: InputMaybe<Scalars['String']>;
  warning_id_list?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "cv.sitbones" */
export type Cv_Sitbones_Inc_Input = {
  detection_duration?: InputMaybe<Scalars['float8']>;
  error_id?: InputMaybe<Scalars['Int']>;
  indicator_asymmetry?: InputMaybe<Scalars['float8']>;
  number_blobs_cluster1?: InputMaybe<Scalars['Int']>;
  number_blobs_cluster2?: InputMaybe<Scalars['Int']>;
  reference_size?: InputMaybe<Scalars['float8']>;
  /** corresponds to "seat_bone_distance" in the customer answer */
  sitbone_distance?: InputMaybe<Scalars['float8']>;
  std_deviation_cluster1?: InputMaybe<Scalars['float8']>;
  std_deviation_cluster2?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "cv.sitbones" */
export type Cv_Sitbones_Insert_Input = {
  answer?: InputMaybe<Customer_Answer_Obj_Rel_Insert_Input>;
  answer_id?: InputMaybe<Scalars['String']>;
  before_img_url?: InputMaybe<Scalars['String']>;
  blob_list?: InputMaybe<Scalars['jsonb']>;
  coordinates_cluster1?: InputMaybe<Scalars['jsonb']>;
  coordinates_cluster2?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  detected_corner?: InputMaybe<Scalars['Boolean']>;
  detection_duration?: InputMaybe<Scalars['float8']>;
  error_id?: InputMaybe<Scalars['Int']>;
  indicator_asymmetry?: InputMaybe<Scalars['float8']>;
  number_blobs_cluster1?: InputMaybe<Scalars['Int']>;
  number_blobs_cluster2?: InputMaybe<Scalars['Int']>;
  reference_size?: InputMaybe<Scalars['float8']>;
  result_img_url?: InputMaybe<Scalars['String']>;
  /** corresponds to "seat_bone_distance" in the customer answer */
  sitbone_distance?: InputMaybe<Scalars['float8']>;
  std_deviation_cluster1?: InputMaybe<Scalars['float8']>;
  std_deviation_cluster2?: InputMaybe<Scalars['float8']>;
  warning_id_list?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type Cv_Sitbones_Max_Fields = {
  __typename?: 'cv_sitbones_max_fields';
  answer_id?: Maybe<Scalars['String']>;
  before_img_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  detection_duration?: Maybe<Scalars['float8']>;
  error_id?: Maybe<Scalars['Int']>;
  indicator_asymmetry?: Maybe<Scalars['float8']>;
  number_blobs_cluster1?: Maybe<Scalars['Int']>;
  number_blobs_cluster2?: Maybe<Scalars['Int']>;
  reference_size?: Maybe<Scalars['float8']>;
  result_img_url?: Maybe<Scalars['String']>;
  /** corresponds to "seat_bone_distance" in the customer answer */
  sitbone_distance?: Maybe<Scalars['float8']>;
  std_deviation_cluster1?: Maybe<Scalars['float8']>;
  std_deviation_cluster2?: Maybe<Scalars['float8']>;
};

/** order by max() on columns of table "cv.sitbones" */
export type Cv_Sitbones_Max_Order_By = {
  answer_id?: InputMaybe<Order_By>;
  before_img_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  detection_duration?: InputMaybe<Order_By>;
  error_id?: InputMaybe<Order_By>;
  indicator_asymmetry?: InputMaybe<Order_By>;
  number_blobs_cluster1?: InputMaybe<Order_By>;
  number_blobs_cluster2?: InputMaybe<Order_By>;
  reference_size?: InputMaybe<Order_By>;
  result_img_url?: InputMaybe<Order_By>;
  /** corresponds to "seat_bone_distance" in the customer answer */
  sitbone_distance?: InputMaybe<Order_By>;
  std_deviation_cluster1?: InputMaybe<Order_By>;
  std_deviation_cluster2?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Cv_Sitbones_Min_Fields = {
  __typename?: 'cv_sitbones_min_fields';
  answer_id?: Maybe<Scalars['String']>;
  before_img_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  detection_duration?: Maybe<Scalars['float8']>;
  error_id?: Maybe<Scalars['Int']>;
  indicator_asymmetry?: Maybe<Scalars['float8']>;
  number_blobs_cluster1?: Maybe<Scalars['Int']>;
  number_blobs_cluster2?: Maybe<Scalars['Int']>;
  reference_size?: Maybe<Scalars['float8']>;
  result_img_url?: Maybe<Scalars['String']>;
  /** corresponds to "seat_bone_distance" in the customer answer */
  sitbone_distance?: Maybe<Scalars['float8']>;
  std_deviation_cluster1?: Maybe<Scalars['float8']>;
  std_deviation_cluster2?: Maybe<Scalars['float8']>;
};

/** order by min() on columns of table "cv.sitbones" */
export type Cv_Sitbones_Min_Order_By = {
  answer_id?: InputMaybe<Order_By>;
  before_img_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  detection_duration?: InputMaybe<Order_By>;
  error_id?: InputMaybe<Order_By>;
  indicator_asymmetry?: InputMaybe<Order_By>;
  number_blobs_cluster1?: InputMaybe<Order_By>;
  number_blobs_cluster2?: InputMaybe<Order_By>;
  reference_size?: InputMaybe<Order_By>;
  result_img_url?: InputMaybe<Order_By>;
  /** corresponds to "seat_bone_distance" in the customer answer */
  sitbone_distance?: InputMaybe<Order_By>;
  std_deviation_cluster1?: InputMaybe<Order_By>;
  std_deviation_cluster2?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "cv.sitbones" */
export type Cv_Sitbones_Mutation_Response = {
  __typename?: 'cv_sitbones_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Cv_Sitbones>;
};

/** on_conflict condition type for table "cv.sitbones" */
export type Cv_Sitbones_On_Conflict = {
  constraint: Cv_Sitbones_Constraint;
  update_columns?: Array<Cv_Sitbones_Update_Column>;
  where?: InputMaybe<Cv_Sitbones_Bool_Exp>;
};

/** Ordering options when selecting data from "cv.sitbones". */
export type Cv_Sitbones_Order_By = {
  answer?: InputMaybe<Customer_Answer_Order_By>;
  answer_id?: InputMaybe<Order_By>;
  before_img_url?: InputMaybe<Order_By>;
  blob_list?: InputMaybe<Order_By>;
  coordinates_cluster1?: InputMaybe<Order_By>;
  coordinates_cluster2?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  detected_corner?: InputMaybe<Order_By>;
  detection_duration?: InputMaybe<Order_By>;
  error_id?: InputMaybe<Order_By>;
  indicator_asymmetry?: InputMaybe<Order_By>;
  number_blobs_cluster1?: InputMaybe<Order_By>;
  number_blobs_cluster2?: InputMaybe<Order_By>;
  reference_size?: InputMaybe<Order_By>;
  result_img_url?: InputMaybe<Order_By>;
  sitbone_distance?: InputMaybe<Order_By>;
  std_deviation_cluster1?: InputMaybe<Order_By>;
  std_deviation_cluster2?: InputMaybe<Order_By>;
  warning_id_list?: InputMaybe<Order_By>;
};

/** primary key columns input for table: cv.sitbones */
export type Cv_Sitbones_Pk_Columns_Input = {
  answer_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Cv_Sitbones_Prepend_Input = {
  blob_list?: InputMaybe<Scalars['jsonb']>;
  coordinates_cluster1?: InputMaybe<Scalars['jsonb']>;
  coordinates_cluster2?: InputMaybe<Scalars['jsonb']>;
  warning_id_list?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "cv.sitbones" */
export enum Cv_Sitbones_Select_Column {
  /** column name */
  AnswerId = 'answer_id',
  /** column name */
  BeforeImgUrl = 'before_img_url',
  /** column name */
  BlobList = 'blob_list',
  /** column name */
  CoordinatesCluster1 = 'coordinates_cluster1',
  /** column name */
  CoordinatesCluster2 = 'coordinates_cluster2',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DetectedCorner = 'detected_corner',
  /** column name */
  DetectionDuration = 'detection_duration',
  /** column name */
  ErrorId = 'error_id',
  /** column name */
  IndicatorAsymmetry = 'indicator_asymmetry',
  /** column name */
  NumberBlobsCluster1 = 'number_blobs_cluster1',
  /** column name */
  NumberBlobsCluster2 = 'number_blobs_cluster2',
  /** column name */
  ReferenceSize = 'reference_size',
  /** column name */
  ResultImgUrl = 'result_img_url',
  /** column name */
  SitboneDistance = 'sitbone_distance',
  /** column name */
  StdDeviationCluster1 = 'std_deviation_cluster1',
  /** column name */
  StdDeviationCluster2 = 'std_deviation_cluster2',
  /** column name */
  WarningIdList = 'warning_id_list'
}

/** select "cv_sitbones_aggregate_bool_exp_avg_arguments_columns" columns of table "cv.sitbones" */
export enum Cv_Sitbones_Select_Column_Cv_Sitbones_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  DetectionDuration = 'detection_duration',
  /** column name */
  IndicatorAsymmetry = 'indicator_asymmetry',
  /** column name */
  ReferenceSize = 'reference_size',
  /** column name */
  SitboneDistance = 'sitbone_distance',
  /** column name */
  StdDeviationCluster1 = 'std_deviation_cluster1',
  /** column name */
  StdDeviationCluster2 = 'std_deviation_cluster2'
}

/** select "cv_sitbones_aggregate_bool_exp_bool_and_arguments_columns" columns of table "cv.sitbones" */
export enum Cv_Sitbones_Select_Column_Cv_Sitbones_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  DetectedCorner = 'detected_corner'
}

/** select "cv_sitbones_aggregate_bool_exp_bool_or_arguments_columns" columns of table "cv.sitbones" */
export enum Cv_Sitbones_Select_Column_Cv_Sitbones_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  DetectedCorner = 'detected_corner'
}

/** select "cv_sitbones_aggregate_bool_exp_corr_arguments_columns" columns of table "cv.sitbones" */
export enum Cv_Sitbones_Select_Column_Cv_Sitbones_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  DetectionDuration = 'detection_duration',
  /** column name */
  IndicatorAsymmetry = 'indicator_asymmetry',
  /** column name */
  ReferenceSize = 'reference_size',
  /** column name */
  SitboneDistance = 'sitbone_distance',
  /** column name */
  StdDeviationCluster1 = 'std_deviation_cluster1',
  /** column name */
  StdDeviationCluster2 = 'std_deviation_cluster2'
}

/** select "cv_sitbones_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "cv.sitbones" */
export enum Cv_Sitbones_Select_Column_Cv_Sitbones_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  DetectionDuration = 'detection_duration',
  /** column name */
  IndicatorAsymmetry = 'indicator_asymmetry',
  /** column name */
  ReferenceSize = 'reference_size',
  /** column name */
  SitboneDistance = 'sitbone_distance',
  /** column name */
  StdDeviationCluster1 = 'std_deviation_cluster1',
  /** column name */
  StdDeviationCluster2 = 'std_deviation_cluster2'
}

/** select "cv_sitbones_aggregate_bool_exp_max_arguments_columns" columns of table "cv.sitbones" */
export enum Cv_Sitbones_Select_Column_Cv_Sitbones_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  DetectionDuration = 'detection_duration',
  /** column name */
  IndicatorAsymmetry = 'indicator_asymmetry',
  /** column name */
  ReferenceSize = 'reference_size',
  /** column name */
  SitboneDistance = 'sitbone_distance',
  /** column name */
  StdDeviationCluster1 = 'std_deviation_cluster1',
  /** column name */
  StdDeviationCluster2 = 'std_deviation_cluster2'
}

/** select "cv_sitbones_aggregate_bool_exp_min_arguments_columns" columns of table "cv.sitbones" */
export enum Cv_Sitbones_Select_Column_Cv_Sitbones_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  DetectionDuration = 'detection_duration',
  /** column name */
  IndicatorAsymmetry = 'indicator_asymmetry',
  /** column name */
  ReferenceSize = 'reference_size',
  /** column name */
  SitboneDistance = 'sitbone_distance',
  /** column name */
  StdDeviationCluster1 = 'std_deviation_cluster1',
  /** column name */
  StdDeviationCluster2 = 'std_deviation_cluster2'
}

/** select "cv_sitbones_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "cv.sitbones" */
export enum Cv_Sitbones_Select_Column_Cv_Sitbones_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  DetectionDuration = 'detection_duration',
  /** column name */
  IndicatorAsymmetry = 'indicator_asymmetry',
  /** column name */
  ReferenceSize = 'reference_size',
  /** column name */
  SitboneDistance = 'sitbone_distance',
  /** column name */
  StdDeviationCluster1 = 'std_deviation_cluster1',
  /** column name */
  StdDeviationCluster2 = 'std_deviation_cluster2'
}

/** select "cv_sitbones_aggregate_bool_exp_sum_arguments_columns" columns of table "cv.sitbones" */
export enum Cv_Sitbones_Select_Column_Cv_Sitbones_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  DetectionDuration = 'detection_duration',
  /** column name */
  IndicatorAsymmetry = 'indicator_asymmetry',
  /** column name */
  ReferenceSize = 'reference_size',
  /** column name */
  SitboneDistance = 'sitbone_distance',
  /** column name */
  StdDeviationCluster1 = 'std_deviation_cluster1',
  /** column name */
  StdDeviationCluster2 = 'std_deviation_cluster2'
}

/** select "cv_sitbones_aggregate_bool_exp_var_samp_arguments_columns" columns of table "cv.sitbones" */
export enum Cv_Sitbones_Select_Column_Cv_Sitbones_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  DetectionDuration = 'detection_duration',
  /** column name */
  IndicatorAsymmetry = 'indicator_asymmetry',
  /** column name */
  ReferenceSize = 'reference_size',
  /** column name */
  SitboneDistance = 'sitbone_distance',
  /** column name */
  StdDeviationCluster1 = 'std_deviation_cluster1',
  /** column name */
  StdDeviationCluster2 = 'std_deviation_cluster2'
}

/** input type for updating data in table "cv.sitbones" */
export type Cv_Sitbones_Set_Input = {
  answer_id?: InputMaybe<Scalars['String']>;
  before_img_url?: InputMaybe<Scalars['String']>;
  blob_list?: InputMaybe<Scalars['jsonb']>;
  coordinates_cluster1?: InputMaybe<Scalars['jsonb']>;
  coordinates_cluster2?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  detected_corner?: InputMaybe<Scalars['Boolean']>;
  detection_duration?: InputMaybe<Scalars['float8']>;
  error_id?: InputMaybe<Scalars['Int']>;
  indicator_asymmetry?: InputMaybe<Scalars['float8']>;
  number_blobs_cluster1?: InputMaybe<Scalars['Int']>;
  number_blobs_cluster2?: InputMaybe<Scalars['Int']>;
  reference_size?: InputMaybe<Scalars['float8']>;
  result_img_url?: InputMaybe<Scalars['String']>;
  /** corresponds to "seat_bone_distance" in the customer answer */
  sitbone_distance?: InputMaybe<Scalars['float8']>;
  std_deviation_cluster1?: InputMaybe<Scalars['float8']>;
  std_deviation_cluster2?: InputMaybe<Scalars['float8']>;
  warning_id_list?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate stddev on columns */
export type Cv_Sitbones_Stddev_Fields = {
  __typename?: 'cv_sitbones_stddev_fields';
  detection_duration?: Maybe<Scalars['Float']>;
  error_id?: Maybe<Scalars['Float']>;
  indicator_asymmetry?: Maybe<Scalars['Float']>;
  number_blobs_cluster1?: Maybe<Scalars['Float']>;
  number_blobs_cluster2?: Maybe<Scalars['Float']>;
  reference_size?: Maybe<Scalars['Float']>;
  /** corresponds to "seat_bone_distance" in the customer answer */
  sitbone_distance?: Maybe<Scalars['Float']>;
  std_deviation_cluster1?: Maybe<Scalars['Float']>;
  std_deviation_cluster2?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "cv.sitbones" */
export type Cv_Sitbones_Stddev_Order_By = {
  detection_duration?: InputMaybe<Order_By>;
  error_id?: InputMaybe<Order_By>;
  indicator_asymmetry?: InputMaybe<Order_By>;
  number_blobs_cluster1?: InputMaybe<Order_By>;
  number_blobs_cluster2?: InputMaybe<Order_By>;
  reference_size?: InputMaybe<Order_By>;
  /** corresponds to "seat_bone_distance" in the customer answer */
  sitbone_distance?: InputMaybe<Order_By>;
  std_deviation_cluster1?: InputMaybe<Order_By>;
  std_deviation_cluster2?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Cv_Sitbones_Stddev_Pop_Fields = {
  __typename?: 'cv_sitbones_stddev_pop_fields';
  detection_duration?: Maybe<Scalars['Float']>;
  error_id?: Maybe<Scalars['Float']>;
  indicator_asymmetry?: Maybe<Scalars['Float']>;
  number_blobs_cluster1?: Maybe<Scalars['Float']>;
  number_blobs_cluster2?: Maybe<Scalars['Float']>;
  reference_size?: Maybe<Scalars['Float']>;
  /** corresponds to "seat_bone_distance" in the customer answer */
  sitbone_distance?: Maybe<Scalars['Float']>;
  std_deviation_cluster1?: Maybe<Scalars['Float']>;
  std_deviation_cluster2?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "cv.sitbones" */
export type Cv_Sitbones_Stddev_Pop_Order_By = {
  detection_duration?: InputMaybe<Order_By>;
  error_id?: InputMaybe<Order_By>;
  indicator_asymmetry?: InputMaybe<Order_By>;
  number_blobs_cluster1?: InputMaybe<Order_By>;
  number_blobs_cluster2?: InputMaybe<Order_By>;
  reference_size?: InputMaybe<Order_By>;
  /** corresponds to "seat_bone_distance" in the customer answer */
  sitbone_distance?: InputMaybe<Order_By>;
  std_deviation_cluster1?: InputMaybe<Order_By>;
  std_deviation_cluster2?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Cv_Sitbones_Stddev_Samp_Fields = {
  __typename?: 'cv_sitbones_stddev_samp_fields';
  detection_duration?: Maybe<Scalars['Float']>;
  error_id?: Maybe<Scalars['Float']>;
  indicator_asymmetry?: Maybe<Scalars['Float']>;
  number_blobs_cluster1?: Maybe<Scalars['Float']>;
  number_blobs_cluster2?: Maybe<Scalars['Float']>;
  reference_size?: Maybe<Scalars['Float']>;
  /** corresponds to "seat_bone_distance" in the customer answer */
  sitbone_distance?: Maybe<Scalars['Float']>;
  std_deviation_cluster1?: Maybe<Scalars['Float']>;
  std_deviation_cluster2?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "cv.sitbones" */
export type Cv_Sitbones_Stddev_Samp_Order_By = {
  detection_duration?: InputMaybe<Order_By>;
  error_id?: InputMaybe<Order_By>;
  indicator_asymmetry?: InputMaybe<Order_By>;
  number_blobs_cluster1?: InputMaybe<Order_By>;
  number_blobs_cluster2?: InputMaybe<Order_By>;
  reference_size?: InputMaybe<Order_By>;
  /** corresponds to "seat_bone_distance" in the customer answer */
  sitbone_distance?: InputMaybe<Order_By>;
  std_deviation_cluster1?: InputMaybe<Order_By>;
  std_deviation_cluster2?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "cv_sitbones" */
export type Cv_Sitbones_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cv_Sitbones_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Cv_Sitbones_Stream_Cursor_Value_Input = {
  answer_id?: InputMaybe<Scalars['String']>;
  before_img_url?: InputMaybe<Scalars['String']>;
  blob_list?: InputMaybe<Scalars['jsonb']>;
  coordinates_cluster1?: InputMaybe<Scalars['jsonb']>;
  coordinates_cluster2?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  detected_corner?: InputMaybe<Scalars['Boolean']>;
  detection_duration?: InputMaybe<Scalars['float8']>;
  error_id?: InputMaybe<Scalars['Int']>;
  indicator_asymmetry?: InputMaybe<Scalars['float8']>;
  number_blobs_cluster1?: InputMaybe<Scalars['Int']>;
  number_blobs_cluster2?: InputMaybe<Scalars['Int']>;
  reference_size?: InputMaybe<Scalars['float8']>;
  result_img_url?: InputMaybe<Scalars['String']>;
  /** corresponds to "seat_bone_distance" in the customer answer */
  sitbone_distance?: InputMaybe<Scalars['float8']>;
  std_deviation_cluster1?: InputMaybe<Scalars['float8']>;
  std_deviation_cluster2?: InputMaybe<Scalars['float8']>;
  warning_id_list?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate sum on columns */
export type Cv_Sitbones_Sum_Fields = {
  __typename?: 'cv_sitbones_sum_fields';
  detection_duration?: Maybe<Scalars['float8']>;
  error_id?: Maybe<Scalars['Int']>;
  indicator_asymmetry?: Maybe<Scalars['float8']>;
  number_blobs_cluster1?: Maybe<Scalars['Int']>;
  number_blobs_cluster2?: Maybe<Scalars['Int']>;
  reference_size?: Maybe<Scalars['float8']>;
  /** corresponds to "seat_bone_distance" in the customer answer */
  sitbone_distance?: Maybe<Scalars['float8']>;
  std_deviation_cluster1?: Maybe<Scalars['float8']>;
  std_deviation_cluster2?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "cv.sitbones" */
export type Cv_Sitbones_Sum_Order_By = {
  detection_duration?: InputMaybe<Order_By>;
  error_id?: InputMaybe<Order_By>;
  indicator_asymmetry?: InputMaybe<Order_By>;
  number_blobs_cluster1?: InputMaybe<Order_By>;
  number_blobs_cluster2?: InputMaybe<Order_By>;
  reference_size?: InputMaybe<Order_By>;
  /** corresponds to "seat_bone_distance" in the customer answer */
  sitbone_distance?: InputMaybe<Order_By>;
  std_deviation_cluster1?: InputMaybe<Order_By>;
  std_deviation_cluster2?: InputMaybe<Order_By>;
};

/** update columns of table "cv.sitbones" */
export enum Cv_Sitbones_Update_Column {
  /** column name */
  AnswerId = 'answer_id',
  /** column name */
  BeforeImgUrl = 'before_img_url',
  /** column name */
  BlobList = 'blob_list',
  /** column name */
  CoordinatesCluster1 = 'coordinates_cluster1',
  /** column name */
  CoordinatesCluster2 = 'coordinates_cluster2',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DetectedCorner = 'detected_corner',
  /** column name */
  DetectionDuration = 'detection_duration',
  /** column name */
  ErrorId = 'error_id',
  /** column name */
  IndicatorAsymmetry = 'indicator_asymmetry',
  /** column name */
  NumberBlobsCluster1 = 'number_blobs_cluster1',
  /** column name */
  NumberBlobsCluster2 = 'number_blobs_cluster2',
  /** column name */
  ReferenceSize = 'reference_size',
  /** column name */
  ResultImgUrl = 'result_img_url',
  /** column name */
  SitboneDistance = 'sitbone_distance',
  /** column name */
  StdDeviationCluster1 = 'std_deviation_cluster1',
  /** column name */
  StdDeviationCluster2 = 'std_deviation_cluster2',
  /** column name */
  WarningIdList = 'warning_id_list'
}

export type Cv_Sitbones_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Cv_Sitbones_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Cv_Sitbones_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Cv_Sitbones_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Cv_Sitbones_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Cv_Sitbones_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Cv_Sitbones_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Cv_Sitbones_Set_Input>;
  /** filter the rows which have to be updated */
  where: Cv_Sitbones_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Cv_Sitbones_Var_Pop_Fields = {
  __typename?: 'cv_sitbones_var_pop_fields';
  detection_duration?: Maybe<Scalars['Float']>;
  error_id?: Maybe<Scalars['Float']>;
  indicator_asymmetry?: Maybe<Scalars['Float']>;
  number_blobs_cluster1?: Maybe<Scalars['Float']>;
  number_blobs_cluster2?: Maybe<Scalars['Float']>;
  reference_size?: Maybe<Scalars['Float']>;
  /** corresponds to "seat_bone_distance" in the customer answer */
  sitbone_distance?: Maybe<Scalars['Float']>;
  std_deviation_cluster1?: Maybe<Scalars['Float']>;
  std_deviation_cluster2?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "cv.sitbones" */
export type Cv_Sitbones_Var_Pop_Order_By = {
  detection_duration?: InputMaybe<Order_By>;
  error_id?: InputMaybe<Order_By>;
  indicator_asymmetry?: InputMaybe<Order_By>;
  number_blobs_cluster1?: InputMaybe<Order_By>;
  number_blobs_cluster2?: InputMaybe<Order_By>;
  reference_size?: InputMaybe<Order_By>;
  /** corresponds to "seat_bone_distance" in the customer answer */
  sitbone_distance?: InputMaybe<Order_By>;
  std_deviation_cluster1?: InputMaybe<Order_By>;
  std_deviation_cluster2?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Cv_Sitbones_Var_Samp_Fields = {
  __typename?: 'cv_sitbones_var_samp_fields';
  detection_duration?: Maybe<Scalars['Float']>;
  error_id?: Maybe<Scalars['Float']>;
  indicator_asymmetry?: Maybe<Scalars['Float']>;
  number_blobs_cluster1?: Maybe<Scalars['Float']>;
  number_blobs_cluster2?: Maybe<Scalars['Float']>;
  reference_size?: Maybe<Scalars['Float']>;
  /** corresponds to "seat_bone_distance" in the customer answer */
  sitbone_distance?: Maybe<Scalars['Float']>;
  std_deviation_cluster1?: Maybe<Scalars['Float']>;
  std_deviation_cluster2?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "cv.sitbones" */
export type Cv_Sitbones_Var_Samp_Order_By = {
  detection_duration?: InputMaybe<Order_By>;
  error_id?: InputMaybe<Order_By>;
  indicator_asymmetry?: InputMaybe<Order_By>;
  number_blobs_cluster1?: InputMaybe<Order_By>;
  number_blobs_cluster2?: InputMaybe<Order_By>;
  reference_size?: InputMaybe<Order_By>;
  /** corresponds to "seat_bone_distance" in the customer answer */
  sitbone_distance?: InputMaybe<Order_By>;
  std_deviation_cluster1?: InputMaybe<Order_By>;
  std_deviation_cluster2?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Cv_Sitbones_Variance_Fields = {
  __typename?: 'cv_sitbones_variance_fields';
  detection_duration?: Maybe<Scalars['Float']>;
  error_id?: Maybe<Scalars['Float']>;
  indicator_asymmetry?: Maybe<Scalars['Float']>;
  number_blobs_cluster1?: Maybe<Scalars['Float']>;
  number_blobs_cluster2?: Maybe<Scalars['Float']>;
  reference_size?: Maybe<Scalars['Float']>;
  /** corresponds to "seat_bone_distance" in the customer answer */
  sitbone_distance?: Maybe<Scalars['Float']>;
  std_deviation_cluster1?: Maybe<Scalars['Float']>;
  std_deviation_cluster2?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "cv.sitbones" */
export type Cv_Sitbones_Variance_Order_By = {
  detection_duration?: InputMaybe<Order_By>;
  error_id?: InputMaybe<Order_By>;
  indicator_asymmetry?: InputMaybe<Order_By>;
  number_blobs_cluster1?: InputMaybe<Order_By>;
  number_blobs_cluster2?: InputMaybe<Order_By>;
  reference_size?: InputMaybe<Order_By>;
  /** corresponds to "seat_bone_distance" in the customer answer */
  sitbone_distance?: InputMaybe<Order_By>;
  std_deviation_cluster1?: InputMaybe<Order_By>;
  std_deviation_cluster2?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

export type DeleteDataByEmailResponse = {
  __typename?: 'deleteDataByEmailResponse';
  affectedRows: Scalars['Int'];
};

export type DeleteDataByIdResponse = {
  __typename?: 'deleteDataByIdResponse';
  affectedRows: Scalars['Int'];
};

/** columns and relationships of "distributor" */
export type Distributor = {
  __typename?: 'distributor';
  created_at: Scalars['timestamptz'];
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "distributor" */
export type Distributor_Aggregate = {
  __typename?: 'distributor_aggregate';
  aggregate?: Maybe<Distributor_Aggregate_Fields>;
  nodes: Array<Distributor>;
};

/** aggregate fields of "distributor" */
export type Distributor_Aggregate_Fields = {
  __typename?: 'distributor_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Distributor_Max_Fields>;
  min?: Maybe<Distributor_Min_Fields>;
};


/** aggregate fields of "distributor" */
export type Distributor_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Distributor_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "distributor". All fields are combined with a logical 'AND'. */
export type Distributor_Bool_Exp = {
  _and?: InputMaybe<Array<Distributor_Bool_Exp>>;
  _not?: InputMaybe<Distributor_Bool_Exp>;
  _or?: InputMaybe<Array<Distributor_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "distributor" */
export enum Distributor_Constraint {
  /** unique or primary key constraint on columns "name" */
  DistributorPkey = 'distributor_pkey'
}

/** input type for inserting data into table "distributor" */
export type Distributor_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Distributor_Max_Fields = {
  __typename?: 'distributor_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Distributor_Min_Fields = {
  __typename?: 'distributor_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "distributor" */
export type Distributor_Mutation_Response = {
  __typename?: 'distributor_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Distributor>;
};

/** on_conflict condition type for table "distributor" */
export type Distributor_On_Conflict = {
  constraint: Distributor_Constraint;
  update_columns?: Array<Distributor_Update_Column>;
  where?: InputMaybe<Distributor_Bool_Exp>;
};

/** Ordering options when selecting data from "distributor". */
export type Distributor_Order_By = {
  created_at?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: distributor */
export type Distributor_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "distributor" */
export enum Distributor_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "distributor" */
export type Distributor_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "distributor" */
export type Distributor_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Distributor_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Distributor_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "distributor" */
export enum Distributor_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Distributor_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Distributor_Set_Input>;
  /** filter the rows which have to be updated */
  where: Distributor_Bool_Exp;
};

/** columns and relationships of "distributors" */
export type Distributors = {
  __typename?: 'distributors';
  country_code: Scalars['String'];
  distributor_id: Scalars['String'];
};

/** aggregated selection of "distributors" */
export type Distributors_Aggregate = {
  __typename?: 'distributors_aggregate';
  aggregate?: Maybe<Distributors_Aggregate_Fields>;
  nodes: Array<Distributors>;
};

/** aggregate fields of "distributors" */
export type Distributors_Aggregate_Fields = {
  __typename?: 'distributors_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Distributors_Max_Fields>;
  min?: Maybe<Distributors_Min_Fields>;
};


/** aggregate fields of "distributors" */
export type Distributors_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Distributors_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "distributors". All fields are combined with a logical 'AND'. */
export type Distributors_Bool_Exp = {
  _and?: InputMaybe<Array<Distributors_Bool_Exp>>;
  _not?: InputMaybe<Distributors_Bool_Exp>;
  _or?: InputMaybe<Array<Distributors_Bool_Exp>>;
  country_code?: InputMaybe<String_Comparison_Exp>;
  distributor_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "distributors" */
export enum Distributors_Constraint {
  /** unique or primary key constraint on columns "country_code" */
  DistributorsPkey = 'distributors_pkey'
}

/** input type for inserting data into table "distributors" */
export type Distributors_Insert_Input = {
  country_code?: InputMaybe<Scalars['String']>;
  distributor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Distributors_Max_Fields = {
  __typename?: 'distributors_max_fields';
  country_code?: Maybe<Scalars['String']>;
  distributor_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Distributors_Min_Fields = {
  __typename?: 'distributors_min_fields';
  country_code?: Maybe<Scalars['String']>;
  distributor_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "distributors" */
export type Distributors_Mutation_Response = {
  __typename?: 'distributors_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Distributors>;
};

/** on_conflict condition type for table "distributors" */
export type Distributors_On_Conflict = {
  constraint: Distributors_Constraint;
  update_columns?: Array<Distributors_Update_Column>;
  where?: InputMaybe<Distributors_Bool_Exp>;
};

/** Ordering options when selecting data from "distributors". */
export type Distributors_Order_By = {
  country_code?: InputMaybe<Order_By>;
  distributor_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: distributors */
export type Distributors_Pk_Columns_Input = {
  country_code: Scalars['String'];
};

/** select columns of table "distributors" */
export enum Distributors_Select_Column {
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  DistributorId = 'distributor_id'
}

/** input type for updating data in table "distributors" */
export type Distributors_Set_Input = {
  country_code?: InputMaybe<Scalars['String']>;
  distributor_id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "distributors" */
export type Distributors_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Distributors_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Distributors_Stream_Cursor_Value_Input = {
  country_code?: InputMaybe<Scalars['String']>;
  distributor_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "distributors" */
export enum Distributors_Update_Column {
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  DistributorId = 'distributor_id'
}

export type Distributors_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Distributors_Set_Input>;
  /** filter the rows which have to be updated */
  where: Distributors_Bool_Exp;
};

/** In this table the tracked article events are defined */
export type Event_Enums_Article_Event = {
  __typename?: 'event_enums_article_event';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "event_enums.article_event" */
export type Event_Enums_Article_Event_Aggregate = {
  __typename?: 'event_enums_article_event_aggregate';
  aggregate?: Maybe<Event_Enums_Article_Event_Aggregate_Fields>;
  nodes: Array<Event_Enums_Article_Event>;
};

/** aggregate fields of "event_enums.article_event" */
export type Event_Enums_Article_Event_Aggregate_Fields = {
  __typename?: 'event_enums_article_event_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Event_Enums_Article_Event_Max_Fields>;
  min?: Maybe<Event_Enums_Article_Event_Min_Fields>;
};


/** aggregate fields of "event_enums.article_event" */
export type Event_Enums_Article_Event_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Event_Enums_Article_Event_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "event_enums.article_event". All fields are combined with a logical 'AND'. */
export type Event_Enums_Article_Event_Bool_Exp = {
  _and?: InputMaybe<Array<Event_Enums_Article_Event_Bool_Exp>>;
  _not?: InputMaybe<Event_Enums_Article_Event_Bool_Exp>;
  _or?: InputMaybe<Array<Event_Enums_Article_Event_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_enums.article_event" */
export enum Event_Enums_Article_Event_Constraint {
  /** unique or primary key constraint on columns "description" */
  ArticleEventDescriptionKey = 'article_event_description_key',
  /** unique or primary key constraint on columns "value" */
  ArticleEventPkey = 'article_event_pkey'
}

export enum Event_Enums_Article_Event_Enum {
  /** Produkt zum Warenkorb hinzugefügt */
  AddToBasket = 'ADD_TO_BASKET',
  /** Detail-Ansicht geöffnet */
  DetailViewOpened = 'DETAIL_VIEW_OPENED',
  /** Produkt verkauft */
  ProductSold = 'PRODUCT_SOLD',
  /** Onlineshop Link geklickt */
  ShopLinkClicked = 'SHOP_LINK_CLICKED'
}

/** Boolean expression to compare columns of type "event_enums_article_event_enum". All fields are combined with logical 'AND'. */
export type Event_Enums_Article_Event_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Event_Enums_Article_Event_Enum>;
  _in?: InputMaybe<Array<Event_Enums_Article_Event_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Event_Enums_Article_Event_Enum>;
  _nin?: InputMaybe<Array<Event_Enums_Article_Event_Enum>>;
};

/** input type for inserting data into table "event_enums.article_event" */
export type Event_Enums_Article_Event_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Event_Enums_Article_Event_Max_Fields = {
  __typename?: 'event_enums_article_event_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Event_Enums_Article_Event_Min_Fields = {
  __typename?: 'event_enums_article_event_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "event_enums.article_event" */
export type Event_Enums_Article_Event_Mutation_Response = {
  __typename?: 'event_enums_article_event_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Event_Enums_Article_Event>;
};

/** on_conflict condition type for table "event_enums.article_event" */
export type Event_Enums_Article_Event_On_Conflict = {
  constraint: Event_Enums_Article_Event_Constraint;
  update_columns?: Array<Event_Enums_Article_Event_Update_Column>;
  where?: InputMaybe<Event_Enums_Article_Event_Bool_Exp>;
};

/** Ordering options when selecting data from "event_enums.article_event". */
export type Event_Enums_Article_Event_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: event_enums.article_event */
export type Event_Enums_Article_Event_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "event_enums.article_event" */
export enum Event_Enums_Article_Event_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "event_enums.article_event" */
export type Event_Enums_Article_Event_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "event_enums_article_event" */
export type Event_Enums_Article_Event_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Event_Enums_Article_Event_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Event_Enums_Article_Event_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "event_enums.article_event" */
export enum Event_Enums_Article_Event_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type Event_Enums_Article_Event_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Event_Enums_Article_Event_Set_Input>;
  /** filter the rows which have to be updated */
  where: Event_Enums_Article_Event_Bool_Exp;
};

/** In this table the sources for the events are specified. */
export type Event_Enums_Event_Source = {
  __typename?: 'event_enums_event_source';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "event_enums.event_source" */
export type Event_Enums_Event_Source_Aggregate = {
  __typename?: 'event_enums_event_source_aggregate';
  aggregate?: Maybe<Event_Enums_Event_Source_Aggregate_Fields>;
  nodes: Array<Event_Enums_Event_Source>;
};

/** aggregate fields of "event_enums.event_source" */
export type Event_Enums_Event_Source_Aggregate_Fields = {
  __typename?: 'event_enums_event_source_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Event_Enums_Event_Source_Max_Fields>;
  min?: Maybe<Event_Enums_Event_Source_Min_Fields>;
};


/** aggregate fields of "event_enums.event_source" */
export type Event_Enums_Event_Source_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Event_Enums_Event_Source_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "event_enums.event_source". All fields are combined with a logical 'AND'. */
export type Event_Enums_Event_Source_Bool_Exp = {
  _and?: InputMaybe<Array<Event_Enums_Event_Source_Bool_Exp>>;
  _not?: InputMaybe<Event_Enums_Event_Source_Bool_Exp>;
  _or?: InputMaybe<Array<Event_Enums_Event_Source_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_enums.event_source" */
export enum Event_Enums_Event_Source_Constraint {
  /** unique or primary key constraint on columns "description" */
  EventSourceDescriptionKey = 'event_source_description_key',
  /** unique or primary key constraint on columns "value" */
  EventSourcePkey = 'event_source_pkey'
}

export enum Event_Enums_Event_Source_Enum {
  /** SQlab Profiler App */
  App = 'APP',
  /** SQlab Onlineshop */
  Shop = 'SHOP'
}

/** Boolean expression to compare columns of type "event_enums_event_source_enum". All fields are combined with logical 'AND'. */
export type Event_Enums_Event_Source_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Event_Enums_Event_Source_Enum>;
  _in?: InputMaybe<Array<Event_Enums_Event_Source_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Event_Enums_Event_Source_Enum>;
  _nin?: InputMaybe<Array<Event_Enums_Event_Source_Enum>>;
};

/** input type for inserting data into table "event_enums.event_source" */
export type Event_Enums_Event_Source_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Event_Enums_Event_Source_Max_Fields = {
  __typename?: 'event_enums_event_source_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Event_Enums_Event_Source_Min_Fields = {
  __typename?: 'event_enums_event_source_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "event_enums.event_source" */
export type Event_Enums_Event_Source_Mutation_Response = {
  __typename?: 'event_enums_event_source_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Event_Enums_Event_Source>;
};

/** on_conflict condition type for table "event_enums.event_source" */
export type Event_Enums_Event_Source_On_Conflict = {
  constraint: Event_Enums_Event_Source_Constraint;
  update_columns?: Array<Event_Enums_Event_Source_Update_Column>;
  where?: InputMaybe<Event_Enums_Event_Source_Bool_Exp>;
};

/** Ordering options when selecting data from "event_enums.event_source". */
export type Event_Enums_Event_Source_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: event_enums.event_source */
export type Event_Enums_Event_Source_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "event_enums.event_source" */
export enum Event_Enums_Event_Source_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "event_enums.event_source" */
export type Event_Enums_Event_Source_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "event_enums_event_source" */
export type Event_Enums_Event_Source_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Event_Enums_Event_Source_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Event_Enums_Event_Source_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "event_enums.event_source" */
export enum Event_Enums_Event_Source_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type Event_Enums_Event_Source_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Event_Enums_Event_Source_Set_Input>;
  /** filter the rows which have to be updated */
  where: Event_Enums_Event_Source_Bool_Exp;
};

/** columns and relationships of "event_enums.log_level" */
export type Event_Enums_Log_Level = {
  __typename?: 'event_enums_log_level';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "event_enums.log_level" */
export type Event_Enums_Log_Level_Aggregate = {
  __typename?: 'event_enums_log_level_aggregate';
  aggregate?: Maybe<Event_Enums_Log_Level_Aggregate_Fields>;
  nodes: Array<Event_Enums_Log_Level>;
};

/** aggregate fields of "event_enums.log_level" */
export type Event_Enums_Log_Level_Aggregate_Fields = {
  __typename?: 'event_enums_log_level_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Event_Enums_Log_Level_Max_Fields>;
  min?: Maybe<Event_Enums_Log_Level_Min_Fields>;
};


/** aggregate fields of "event_enums.log_level" */
export type Event_Enums_Log_Level_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Event_Enums_Log_Level_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "event_enums.log_level". All fields are combined with a logical 'AND'. */
export type Event_Enums_Log_Level_Bool_Exp = {
  _and?: InputMaybe<Array<Event_Enums_Log_Level_Bool_Exp>>;
  _not?: InputMaybe<Event_Enums_Log_Level_Bool_Exp>;
  _or?: InputMaybe<Array<Event_Enums_Log_Level_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_enums.log_level" */
export enum Event_Enums_Log_Level_Constraint {
  /** unique or primary key constraint on columns "description" */
  LogLevelDescriptionKey = 'log_level_description_key',
  /** unique or primary key constraint on columns "value" */
  LogLevelPkey = 'log_level_pkey'
}

export enum Event_Enums_Log_Level_Enum {
  /** Debugging */
  Debug = 'DEBUG',
  /** Fehler */
  Error = 'ERROR',
  /** Information */
  Info = 'INFO',
  /** Warnung */
  Warn = 'WARN'
}

/** Boolean expression to compare columns of type "event_enums_log_level_enum". All fields are combined with logical 'AND'. */
export type Event_Enums_Log_Level_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Event_Enums_Log_Level_Enum>;
  _in?: InputMaybe<Array<Event_Enums_Log_Level_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Event_Enums_Log_Level_Enum>;
  _nin?: InputMaybe<Array<Event_Enums_Log_Level_Enum>>;
};

/** input type for inserting data into table "event_enums.log_level" */
export type Event_Enums_Log_Level_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Event_Enums_Log_Level_Max_Fields = {
  __typename?: 'event_enums_log_level_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Event_Enums_Log_Level_Min_Fields = {
  __typename?: 'event_enums_log_level_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "event_enums.log_level" */
export type Event_Enums_Log_Level_Mutation_Response = {
  __typename?: 'event_enums_log_level_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Event_Enums_Log_Level>;
};

/** on_conflict condition type for table "event_enums.log_level" */
export type Event_Enums_Log_Level_On_Conflict = {
  constraint: Event_Enums_Log_Level_Constraint;
  update_columns?: Array<Event_Enums_Log_Level_Update_Column>;
  where?: InputMaybe<Event_Enums_Log_Level_Bool_Exp>;
};

/** Ordering options when selecting data from "event_enums.log_level". */
export type Event_Enums_Log_Level_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: event_enums.log_level */
export type Event_Enums_Log_Level_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "event_enums.log_level" */
export enum Event_Enums_Log_Level_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "event_enums.log_level" */
export type Event_Enums_Log_Level_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "event_enums_log_level" */
export type Event_Enums_Log_Level_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Event_Enums_Log_Level_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Event_Enums_Log_Level_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "event_enums.log_level" */
export enum Event_Enums_Log_Level_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type Event_Enums_Log_Level_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Event_Enums_Log_Level_Set_Input>;
  /** filter the rows which have to be updated */
  where: Event_Enums_Log_Level_Bool_Exp;
};

/** columns and relationships of "event_log.event_types" */
export type Event_Log_Event_Types = {
  __typename?: 'event_log_event_types';
  id: Scalars['String'];
};

/** aggregated selection of "event_log.event_types" */
export type Event_Log_Event_Types_Aggregate = {
  __typename?: 'event_log_event_types_aggregate';
  aggregate?: Maybe<Event_Log_Event_Types_Aggregate_Fields>;
  nodes: Array<Event_Log_Event_Types>;
};

/** aggregate fields of "event_log.event_types" */
export type Event_Log_Event_Types_Aggregate_Fields = {
  __typename?: 'event_log_event_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Event_Log_Event_Types_Max_Fields>;
  min?: Maybe<Event_Log_Event_Types_Min_Fields>;
};


/** aggregate fields of "event_log.event_types" */
export type Event_Log_Event_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Event_Log_Event_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "event_log.event_types". All fields are combined with a logical 'AND'. */
export type Event_Log_Event_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Event_Log_Event_Types_Bool_Exp>>;
  _not?: InputMaybe<Event_Log_Event_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Event_Log_Event_Types_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_log.event_types" */
export enum Event_Log_Event_Types_Constraint {
  /** unique or primary key constraint on columns "id" */
  EventTypesPkey = 'event_types_pkey'
}

/** input type for inserting data into table "event_log.event_types" */
export type Event_Log_Event_Types_Insert_Input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Event_Log_Event_Types_Max_Fields = {
  __typename?: 'event_log_event_types_max_fields';
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Event_Log_Event_Types_Min_Fields = {
  __typename?: 'event_log_event_types_min_fields';
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "event_log.event_types" */
export type Event_Log_Event_Types_Mutation_Response = {
  __typename?: 'event_log_event_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Event_Log_Event_Types>;
};

/** on_conflict condition type for table "event_log.event_types" */
export type Event_Log_Event_Types_On_Conflict = {
  constraint: Event_Log_Event_Types_Constraint;
  update_columns?: Array<Event_Log_Event_Types_Update_Column>;
  where?: InputMaybe<Event_Log_Event_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "event_log.event_types". */
export type Event_Log_Event_Types_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: event_log.event_types */
export type Event_Log_Event_Types_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "event_log.event_types" */
export enum Event_Log_Event_Types_Select_Column {
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "event_log.event_types" */
export type Event_Log_Event_Types_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "event_log_event_types" */
export type Event_Log_Event_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Event_Log_Event_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Event_Log_Event_Types_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "event_log.event_types" */
export enum Event_Log_Event_Types_Update_Column {
  /** column name */
  Id = 'id'
}

export type Event_Log_Event_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Event_Log_Event_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Event_Log_Event_Types_Bool_Exp;
};

/** columns and relationships of "event_log.events" */
export type Event_Log_Events = {
  __typename?: 'event_log_events';
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  parameters: Scalars['jsonb'];
  type: Scalars['String'];
  user_id: Scalars['uuid'];
  vendor_id: Scalars['String'];
};


/** columns and relationships of "event_log.events" */
export type Event_Log_EventsParametersArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "event_log.events" */
export type Event_Log_Events_Aggregate = {
  __typename?: 'event_log_events_aggregate';
  aggregate?: Maybe<Event_Log_Events_Aggregate_Fields>;
  nodes: Array<Event_Log_Events>;
};

/** aggregate fields of "event_log.events" */
export type Event_Log_Events_Aggregate_Fields = {
  __typename?: 'event_log_events_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Event_Log_Events_Max_Fields>;
  min?: Maybe<Event_Log_Events_Min_Fields>;
};


/** aggregate fields of "event_log.events" */
export type Event_Log_Events_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Event_Log_Events_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Event_Log_Events_Append_Input = {
  parameters?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "event_log.events". All fields are combined with a logical 'AND'. */
export type Event_Log_Events_Bool_Exp = {
  _and?: InputMaybe<Array<Event_Log_Events_Bool_Exp>>;
  _not?: InputMaybe<Event_Log_Events_Bool_Exp>;
  _or?: InputMaybe<Array<Event_Log_Events_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  parameters?: InputMaybe<Jsonb_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  vendor_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_log.events" */
export enum Event_Log_Events_Constraint {
  /** unique or primary key constraint on columns "user_id", "created_at", "type" */
  EventsPkey = 'events_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Event_Log_Events_Delete_At_Path_Input = {
  parameters?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Event_Log_Events_Delete_Elem_Input = {
  parameters?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Event_Log_Events_Delete_Key_Input = {
  parameters?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "event_log.events" */
export type Event_Log_Events_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  parameters?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Event_Log_Events_Max_Fields = {
  __typename?: 'event_log_events_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Event_Log_Events_Min_Fields = {
  __typename?: 'event_log_events_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "event_log.events" */
export type Event_Log_Events_Mutation_Response = {
  __typename?: 'event_log_events_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Event_Log_Events>;
};

/** on_conflict condition type for table "event_log.events" */
export type Event_Log_Events_On_Conflict = {
  constraint: Event_Log_Events_Constraint;
  update_columns?: Array<Event_Log_Events_Update_Column>;
  where?: InputMaybe<Event_Log_Events_Bool_Exp>;
};

/** Ordering options when selecting data from "event_log.events". */
export type Event_Log_Events_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  parameters?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: event_log.events */
export type Event_Log_Events_Pk_Columns_Input = {
  created_at: Scalars['timestamptz'];
  type: Scalars['String'];
  user_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Event_Log_Events_Prepend_Input = {
  parameters?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "event_log.events" */
export enum Event_Log_Events_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Parameters = 'parameters',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VendorId = 'vendor_id'
}

/** input type for updating data in table "event_log.events" */
export type Event_Log_Events_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  parameters?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "event_log_events" */
export type Event_Log_Events_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Event_Log_Events_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Event_Log_Events_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  parameters?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "event_log.events" */
export enum Event_Log_Events_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Parameters = 'parameters',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VendorId = 'vendor_id'
}

export type Event_Log_Events_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Event_Log_Events_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Event_Log_Events_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Event_Log_Events_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Event_Log_Events_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Event_Log_Events_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Event_Log_Events_Set_Input>;
  /** filter the rows which have to be updated */
  where: Event_Log_Events_Bool_Exp;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']>;
  _gt?: InputMaybe<Scalars['float8']>;
  _gte?: InputMaybe<Scalars['float8']>;
  _in?: InputMaybe<Array<Scalars['float8']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['float8']>;
  _lte?: InputMaybe<Scalars['float8']>;
  _neq?: InputMaybe<Scalars['float8']>;
  _nin?: InputMaybe<Array<Scalars['float8']>>;
};

/** In this table the support requests of vendors struggling with the CV measurements */
export type Frontend_Vendor_Support = {
  __typename?: 'frontend_vendor_support';
  /** An object relationship */
  answer?: Maybe<Customer_Answer>;
  answer_id?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  question_id: Scalars['String'];
  solved_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user?: Maybe<User>;
  user_id: Scalars['uuid'];
  /** An object relationship */
  vendor?: Maybe<Organisation>;
  vendor_id: Scalars['String'];
};

/** aggregated selection of "frontend.vendor_support" */
export type Frontend_Vendor_Support_Aggregate = {
  __typename?: 'frontend_vendor_support_aggregate';
  aggregate?: Maybe<Frontend_Vendor_Support_Aggregate_Fields>;
  nodes: Array<Frontend_Vendor_Support>;
};

/** aggregate fields of "frontend.vendor_support" */
export type Frontend_Vendor_Support_Aggregate_Fields = {
  __typename?: 'frontend_vendor_support_aggregate_fields';
  avg?: Maybe<Frontend_Vendor_Support_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Frontend_Vendor_Support_Max_Fields>;
  min?: Maybe<Frontend_Vendor_Support_Min_Fields>;
  stddev?: Maybe<Frontend_Vendor_Support_Stddev_Fields>;
  stddev_pop?: Maybe<Frontend_Vendor_Support_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Frontend_Vendor_Support_Stddev_Samp_Fields>;
  sum?: Maybe<Frontend_Vendor_Support_Sum_Fields>;
  var_pop?: Maybe<Frontend_Vendor_Support_Var_Pop_Fields>;
  var_samp?: Maybe<Frontend_Vendor_Support_Var_Samp_Fields>;
  variance?: Maybe<Frontend_Vendor_Support_Variance_Fields>;
};


/** aggregate fields of "frontend.vendor_support" */
export type Frontend_Vendor_Support_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Frontend_Vendor_Support_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Frontend_Vendor_Support_Avg_Fields = {
  __typename?: 'frontend_vendor_support_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "frontend.vendor_support". All fields are combined with a logical 'AND'. */
export type Frontend_Vendor_Support_Bool_Exp = {
  _and?: InputMaybe<Array<Frontend_Vendor_Support_Bool_Exp>>;
  _not?: InputMaybe<Frontend_Vendor_Support_Bool_Exp>;
  _or?: InputMaybe<Array<Frontend_Vendor_Support_Bool_Exp>>;
  answer?: InputMaybe<Customer_Answer_Bool_Exp>;
  answer_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  question_id?: InputMaybe<String_Comparison_Exp>;
  solved_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  vendor?: InputMaybe<Organisation_Bool_Exp>;
  vendor_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "frontend.vendor_support" */
export enum Frontend_Vendor_Support_Constraint {
  /** unique or primary key constraint on columns "id" */
  VendorSupportPkey = 'vendor_support_pkey'
}

/** input type for incrementing numeric columns in table "frontend.vendor_support" */
export type Frontend_Vendor_Support_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "frontend.vendor_support" */
export type Frontend_Vendor_Support_Insert_Input = {
  answer?: InputMaybe<Customer_Answer_Obj_Rel_Insert_Input>;
  answer_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  question_id?: InputMaybe<Scalars['String']>;
  solved_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
  vendor?: InputMaybe<Organisation_Obj_Rel_Insert_Input>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Frontend_Vendor_Support_Max_Fields = {
  __typename?: 'frontend_vendor_support_max_fields';
  answer_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  question_id?: Maybe<Scalars['String']>;
  solved_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Frontend_Vendor_Support_Min_Fields = {
  __typename?: 'frontend_vendor_support_min_fields';
  answer_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  question_id?: Maybe<Scalars['String']>;
  solved_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "frontend.vendor_support" */
export type Frontend_Vendor_Support_Mutation_Response = {
  __typename?: 'frontend_vendor_support_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Frontend_Vendor_Support>;
};

/** on_conflict condition type for table "frontend.vendor_support" */
export type Frontend_Vendor_Support_On_Conflict = {
  constraint: Frontend_Vendor_Support_Constraint;
  update_columns?: Array<Frontend_Vendor_Support_Update_Column>;
  where?: InputMaybe<Frontend_Vendor_Support_Bool_Exp>;
};

/** Ordering options when selecting data from "frontend.vendor_support". */
export type Frontend_Vendor_Support_Order_By = {
  answer?: InputMaybe<Customer_Answer_Order_By>;
  answer_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
  solved_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
  vendor?: InputMaybe<Organisation_Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: frontend.vendor_support */
export type Frontend_Vendor_Support_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "frontend.vendor_support" */
export enum Frontend_Vendor_Support_Select_Column {
  /** column name */
  AnswerId = 'answer_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  SolvedAt = 'solved_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VendorId = 'vendor_id'
}

/** input type for updating data in table "frontend.vendor_support" */
export type Frontend_Vendor_Support_Set_Input = {
  answer_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  question_id?: InputMaybe<Scalars['String']>;
  solved_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Frontend_Vendor_Support_Stddev_Fields = {
  __typename?: 'frontend_vendor_support_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Frontend_Vendor_Support_Stddev_Pop_Fields = {
  __typename?: 'frontend_vendor_support_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Frontend_Vendor_Support_Stddev_Samp_Fields = {
  __typename?: 'frontend_vendor_support_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "frontend_vendor_support" */
export type Frontend_Vendor_Support_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Frontend_Vendor_Support_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Frontend_Vendor_Support_Stream_Cursor_Value_Input = {
  answer_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  question_id?: InputMaybe<Scalars['String']>;
  solved_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Frontend_Vendor_Support_Sum_Fields = {
  __typename?: 'frontend_vendor_support_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "frontend.vendor_support" */
export enum Frontend_Vendor_Support_Update_Column {
  /** column name */
  AnswerId = 'answer_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  SolvedAt = 'solved_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VendorId = 'vendor_id'
}

export type Frontend_Vendor_Support_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Frontend_Vendor_Support_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Frontend_Vendor_Support_Set_Input>;
  /** filter the rows which have to be updated */
  where: Frontend_Vendor_Support_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Frontend_Vendor_Support_Var_Pop_Fields = {
  __typename?: 'frontend_vendor_support_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Frontend_Vendor_Support_Var_Samp_Fields = {
  __typename?: 'frontend_vendor_support_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Frontend_Vendor_Support_Variance_Fields = {
  __typename?: 'frontend_vendor_support_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "health" */
export type Health = {
  __typename?: 'health';
  now?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "health" */
export type Health_Aggregate = {
  __typename?: 'health_aggregate';
  aggregate?: Maybe<Health_Aggregate_Fields>;
  nodes: Array<Health>;
};

/** aggregate fields of "health" */
export type Health_Aggregate_Fields = {
  __typename?: 'health_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Health_Max_Fields>;
  min?: Maybe<Health_Min_Fields>;
};


/** aggregate fields of "health" */
export type Health_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Health_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "health". All fields are combined with a logical 'AND'. */
export type Health_Bool_Exp = {
  _and?: InputMaybe<Array<Health_Bool_Exp>>;
  _not?: InputMaybe<Health_Bool_Exp>;
  _or?: InputMaybe<Array<Health_Bool_Exp>>;
  now?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Health_Max_Fields = {
  __typename?: 'health_max_fields';
  now?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Health_Min_Fields = {
  __typename?: 'health_min_fields';
  now?: Maybe<Scalars['timestamptz']>;
};

/** Ordering options when selecting data from "health". */
export type Health_Order_By = {
  now?: InputMaybe<Order_By>;
};

/** select columns of table "health" */
export enum Health_Select_Column {
  /** column name */
  Now = 'now'
}

/** Streaming cursor of the table "health" */
export type Health_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Health_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Health_Stream_Cursor_Value_Input = {
  now?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "iframe.feedback" */
export type Iframe_Feedback = {
  __typename?: 'iframe_feedback';
  comment?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  questionnaire_id: Scalars['String'];
  rating: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "iframe.feedback" */
export type Iframe_Feedback_Aggregate = {
  __typename?: 'iframe_feedback_aggregate';
  aggregate?: Maybe<Iframe_Feedback_Aggregate_Fields>;
  nodes: Array<Iframe_Feedback>;
};

/** aggregate fields of "iframe.feedback" */
export type Iframe_Feedback_Aggregate_Fields = {
  __typename?: 'iframe_feedback_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Iframe_Feedback_Max_Fields>;
  min?: Maybe<Iframe_Feedback_Min_Fields>;
};


/** aggregate fields of "iframe.feedback" */
export type Iframe_Feedback_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Iframe_Feedback_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "iframe.feedback". All fields are combined with a logical 'AND'. */
export type Iframe_Feedback_Bool_Exp = {
  _and?: InputMaybe<Array<Iframe_Feedback_Bool_Exp>>;
  _not?: InputMaybe<Iframe_Feedback_Bool_Exp>;
  _or?: InputMaybe<Array<Iframe_Feedback_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  questionnaire_id?: InputMaybe<String_Comparison_Exp>;
  rating?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "iframe.feedback" */
export enum Iframe_Feedback_Constraint {
  /** unique or primary key constraint on columns "id" */
  FeedbackPkey = 'feedback_pkey'
}

/** input type for inserting data into table "iframe.feedback" */
export type Iframe_Feedback_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  questionnaire_id?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Iframe_Feedback_Max_Fields = {
  __typename?: 'iframe_feedback_max_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  questionnaire_id?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Iframe_Feedback_Min_Fields = {
  __typename?: 'iframe_feedback_min_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  questionnaire_id?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "iframe.feedback" */
export type Iframe_Feedback_Mutation_Response = {
  __typename?: 'iframe_feedback_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Iframe_Feedback>;
};

/** on_conflict condition type for table "iframe.feedback" */
export type Iframe_Feedback_On_Conflict = {
  constraint: Iframe_Feedback_Constraint;
  update_columns?: Array<Iframe_Feedback_Update_Column>;
  where?: InputMaybe<Iframe_Feedback_Bool_Exp>;
};

/** Ordering options when selecting data from "iframe.feedback". */
export type Iframe_Feedback_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: iframe.feedback */
export type Iframe_Feedback_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "iframe.feedback" */
export enum Iframe_Feedback_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  QuestionnaireId = 'questionnaire_id',
  /** column name */
  Rating = 'rating',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "iframe.feedback" */
export type Iframe_Feedback_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  questionnaire_id?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "iframe_feedback" */
export type Iframe_Feedback_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Iframe_Feedback_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Iframe_Feedback_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  questionnaire_id?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "iframe.feedback" */
export enum Iframe_Feedback_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  QuestionnaireId = 'questionnaire_id',
  /** column name */
  Rating = 'rating',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Iframe_Feedback_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Iframe_Feedback_Set_Input>;
  /** filter the rows which have to be updated */
  where: Iframe_Feedback_Bool_Exp;
};

/** Stores newsletter subscription information with email and region */
export type Iframe_Newsletter_Subscriptions = {
  __typename?: 'iframe_newsletter_subscriptions';
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  id: Scalars['uuid'];
  region: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "iframe.newsletter_subscriptions" */
export type Iframe_Newsletter_Subscriptions_Aggregate = {
  __typename?: 'iframe_newsletter_subscriptions_aggregate';
  aggregate?: Maybe<Iframe_Newsletter_Subscriptions_Aggregate_Fields>;
  nodes: Array<Iframe_Newsletter_Subscriptions>;
};

/** aggregate fields of "iframe.newsletter_subscriptions" */
export type Iframe_Newsletter_Subscriptions_Aggregate_Fields = {
  __typename?: 'iframe_newsletter_subscriptions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Iframe_Newsletter_Subscriptions_Max_Fields>;
  min?: Maybe<Iframe_Newsletter_Subscriptions_Min_Fields>;
};


/** aggregate fields of "iframe.newsletter_subscriptions" */
export type Iframe_Newsletter_Subscriptions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Iframe_Newsletter_Subscriptions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "iframe.newsletter_subscriptions". All fields are combined with a logical 'AND'. */
export type Iframe_Newsletter_Subscriptions_Bool_Exp = {
  _and?: InputMaybe<Array<Iframe_Newsletter_Subscriptions_Bool_Exp>>;
  _not?: InputMaybe<Iframe_Newsletter_Subscriptions_Bool_Exp>;
  _or?: InputMaybe<Array<Iframe_Newsletter_Subscriptions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  region?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "iframe.newsletter_subscriptions" */
export enum Iframe_Newsletter_Subscriptions_Constraint {
  /** unique or primary key constraint on columns "email" */
  NewsletterSubscriptionsEmailIdx = 'newsletter_subscriptions_email_idx',
  /** unique or primary key constraint on columns "id" */
  NewsletterSubscriptionsPkey = 'newsletter_subscriptions_pkey'
}

/** input type for inserting data into table "iframe.newsletter_subscriptions" */
export type Iframe_Newsletter_Subscriptions_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  region?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Iframe_Newsletter_Subscriptions_Max_Fields = {
  __typename?: 'iframe_newsletter_subscriptions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  region?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Iframe_Newsletter_Subscriptions_Min_Fields = {
  __typename?: 'iframe_newsletter_subscriptions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  region?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "iframe.newsletter_subscriptions" */
export type Iframe_Newsletter_Subscriptions_Mutation_Response = {
  __typename?: 'iframe_newsletter_subscriptions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Iframe_Newsletter_Subscriptions>;
};

/** on_conflict condition type for table "iframe.newsletter_subscriptions" */
export type Iframe_Newsletter_Subscriptions_On_Conflict = {
  constraint: Iframe_Newsletter_Subscriptions_Constraint;
  update_columns?: Array<Iframe_Newsletter_Subscriptions_Update_Column>;
  where?: InputMaybe<Iframe_Newsletter_Subscriptions_Bool_Exp>;
};

/** Ordering options when selecting data from "iframe.newsletter_subscriptions". */
export type Iframe_Newsletter_Subscriptions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: iframe.newsletter_subscriptions */
export type Iframe_Newsletter_Subscriptions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "iframe.newsletter_subscriptions" */
export enum Iframe_Newsletter_Subscriptions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Region = 'region',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "iframe.newsletter_subscriptions" */
export type Iframe_Newsletter_Subscriptions_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  region?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "iframe_newsletter_subscriptions" */
export type Iframe_Newsletter_Subscriptions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Iframe_Newsletter_Subscriptions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Iframe_Newsletter_Subscriptions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  region?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "iframe.newsletter_subscriptions" */
export enum Iframe_Newsletter_Subscriptions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Region = 'region',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Iframe_Newsletter_Subscriptions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Iframe_Newsletter_Subscriptions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Iframe_Newsletter_Subscriptions_Bool_Exp;
};

/** columns and relationships of "iframe.rating" */
export type Iframe_Rating = {
  __typename?: 'iframe_rating';
  value: Scalars['String'];
};

/** aggregated selection of "iframe.rating" */
export type Iframe_Rating_Aggregate = {
  __typename?: 'iframe_rating_aggregate';
  aggregate?: Maybe<Iframe_Rating_Aggregate_Fields>;
  nodes: Array<Iframe_Rating>;
};

/** aggregate fields of "iframe.rating" */
export type Iframe_Rating_Aggregate_Fields = {
  __typename?: 'iframe_rating_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Iframe_Rating_Max_Fields>;
  min?: Maybe<Iframe_Rating_Min_Fields>;
};


/** aggregate fields of "iframe.rating" */
export type Iframe_Rating_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Iframe_Rating_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "iframe.rating". All fields are combined with a logical 'AND'. */
export type Iframe_Rating_Bool_Exp = {
  _and?: InputMaybe<Array<Iframe_Rating_Bool_Exp>>;
  _not?: InputMaybe<Iframe_Rating_Bool_Exp>;
  _or?: InputMaybe<Array<Iframe_Rating_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "iframe.rating" */
export enum Iframe_Rating_Constraint {
  /** unique or primary key constraint on columns "value" */
  RatingPkey = 'rating_pkey'
}

/** input type for inserting data into table "iframe.rating" */
export type Iframe_Rating_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Iframe_Rating_Max_Fields = {
  __typename?: 'iframe_rating_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Iframe_Rating_Min_Fields = {
  __typename?: 'iframe_rating_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "iframe.rating" */
export type Iframe_Rating_Mutation_Response = {
  __typename?: 'iframe_rating_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Iframe_Rating>;
};

/** on_conflict condition type for table "iframe.rating" */
export type Iframe_Rating_On_Conflict = {
  constraint: Iframe_Rating_Constraint;
  update_columns?: Array<Iframe_Rating_Update_Column>;
  where?: InputMaybe<Iframe_Rating_Bool_Exp>;
};

/** Ordering options when selecting data from "iframe.rating". */
export type Iframe_Rating_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: iframe.rating */
export type Iframe_Rating_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "iframe.rating" */
export enum Iframe_Rating_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "iframe.rating" */
export type Iframe_Rating_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "iframe_rating" */
export type Iframe_Rating_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Iframe_Rating_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Iframe_Rating_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "iframe.rating" */
export enum Iframe_Rating_Update_Column {
  /** column name */
  Value = 'value'
}

export type Iframe_Rating_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Iframe_Rating_Set_Input>;
  /** filter the rows which have to be updated */
  where: Iframe_Rating_Bool_Exp;
};

/** Boolean expression to compare columns of type "interval". All fields are combined with logical 'AND'. */
export type Interval_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['interval']>;
  _gt?: InputMaybe<Scalars['interval']>;
  _gte?: InputMaybe<Scalars['interval']>;
  _in?: InputMaybe<Array<Scalars['interval']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['interval']>;
  _lte?: InputMaybe<Scalars['interval']>;
  _neq?: InputMaybe<Scalars['interval']>;
  _nin?: InputMaybe<Array<Scalars['interval']>>;
};

/** This table describes the types of voucher codes used. */
export type Iqlab_Rewards_Types = {
  __typename?: 'iqlab_rewards_types';
  description: Scalars['String'];
  type: Scalars['String'];
  /** An array relationship */
  vouchers: Array<Iqlab_Rewards_Vouchers>;
  /** An aggregate relationship */
  vouchers_aggregate: Iqlab_Rewards_Vouchers_Aggregate;
};


/** This table describes the types of voucher codes used. */
export type Iqlab_Rewards_TypesVouchersArgs = {
  distinct_on?: InputMaybe<Array<Iqlab_Rewards_Vouchers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Iqlab_Rewards_Vouchers_Order_By>>;
  where?: InputMaybe<Iqlab_Rewards_Vouchers_Bool_Exp>;
};


/** This table describes the types of voucher codes used. */
export type Iqlab_Rewards_TypesVouchers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Iqlab_Rewards_Vouchers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Iqlab_Rewards_Vouchers_Order_By>>;
  where?: InputMaybe<Iqlab_Rewards_Vouchers_Bool_Exp>;
};

/** aggregated selection of "iqlab_rewards.types" */
export type Iqlab_Rewards_Types_Aggregate = {
  __typename?: 'iqlab_rewards_types_aggregate';
  aggregate?: Maybe<Iqlab_Rewards_Types_Aggregate_Fields>;
  nodes: Array<Iqlab_Rewards_Types>;
};

/** aggregate fields of "iqlab_rewards.types" */
export type Iqlab_Rewards_Types_Aggregate_Fields = {
  __typename?: 'iqlab_rewards_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Iqlab_Rewards_Types_Max_Fields>;
  min?: Maybe<Iqlab_Rewards_Types_Min_Fields>;
};


/** aggregate fields of "iqlab_rewards.types" */
export type Iqlab_Rewards_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Iqlab_Rewards_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "iqlab_rewards.types". All fields are combined with a logical 'AND'. */
export type Iqlab_Rewards_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Iqlab_Rewards_Types_Bool_Exp>>;
  _not?: InputMaybe<Iqlab_Rewards_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Iqlab_Rewards_Types_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  vouchers?: InputMaybe<Iqlab_Rewards_Vouchers_Bool_Exp>;
  vouchers_aggregate?: InputMaybe<Iqlab_Rewards_Vouchers_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "iqlab_rewards.types" */
export enum Iqlab_Rewards_Types_Constraint {
  /** unique or primary key constraint on columns "type" */
  TypesPkey = 'types_pkey'
}

/** input type for inserting data into table "iqlab_rewards.types" */
export type Iqlab_Rewards_Types_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  vouchers?: InputMaybe<Iqlab_Rewards_Vouchers_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Iqlab_Rewards_Types_Max_Fields = {
  __typename?: 'iqlab_rewards_types_max_fields';
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Iqlab_Rewards_Types_Min_Fields = {
  __typename?: 'iqlab_rewards_types_min_fields';
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "iqlab_rewards.types" */
export type Iqlab_Rewards_Types_Mutation_Response = {
  __typename?: 'iqlab_rewards_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Iqlab_Rewards_Types>;
};

/** input type for inserting object relation for remote table "iqlab_rewards.types" */
export type Iqlab_Rewards_Types_Obj_Rel_Insert_Input = {
  data: Iqlab_Rewards_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Iqlab_Rewards_Types_On_Conflict>;
};

/** on_conflict condition type for table "iqlab_rewards.types" */
export type Iqlab_Rewards_Types_On_Conflict = {
  constraint: Iqlab_Rewards_Types_Constraint;
  update_columns?: Array<Iqlab_Rewards_Types_Update_Column>;
  where?: InputMaybe<Iqlab_Rewards_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "iqlab_rewards.types". */
export type Iqlab_Rewards_Types_Order_By = {
  description?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  vouchers_aggregate?: InputMaybe<Iqlab_Rewards_Vouchers_Aggregate_Order_By>;
};

/** primary key columns input for table: iqlab_rewards.types */
export type Iqlab_Rewards_Types_Pk_Columns_Input = {
  type: Scalars['String'];
};

/** select columns of table "iqlab_rewards.types" */
export enum Iqlab_Rewards_Types_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "iqlab_rewards.types" */
export type Iqlab_Rewards_Types_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "iqlab_rewards_types" */
export type Iqlab_Rewards_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Iqlab_Rewards_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Iqlab_Rewards_Types_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "iqlab_rewards.types" */
export enum Iqlab_Rewards_Types_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Type = 'type'
}

export type Iqlab_Rewards_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Iqlab_Rewards_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Iqlab_Rewards_Types_Bool_Exp;
};

/** columns and relationships of "iqlab_rewards.vouchers" */
export type Iqlab_Rewards_Vouchers = {
  __typename?: 'iqlab_rewards_vouchers';
  code: Scalars['String'];
  created_at: Scalars['timestamptz'];
  expires_at: Scalars['timestamptz'];
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<User_Details>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "iqlab_rewards.vouchers" */
export type Iqlab_Rewards_Vouchers_Aggregate = {
  __typename?: 'iqlab_rewards_vouchers_aggregate';
  aggregate?: Maybe<Iqlab_Rewards_Vouchers_Aggregate_Fields>;
  nodes: Array<Iqlab_Rewards_Vouchers>;
};

export type Iqlab_Rewards_Vouchers_Aggregate_Bool_Exp = {
  count?: InputMaybe<Iqlab_Rewards_Vouchers_Aggregate_Bool_Exp_Count>;
};

export type Iqlab_Rewards_Vouchers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Iqlab_Rewards_Vouchers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Iqlab_Rewards_Vouchers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "iqlab_rewards.vouchers" */
export type Iqlab_Rewards_Vouchers_Aggregate_Fields = {
  __typename?: 'iqlab_rewards_vouchers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Iqlab_Rewards_Vouchers_Max_Fields>;
  min?: Maybe<Iqlab_Rewards_Vouchers_Min_Fields>;
};


/** aggregate fields of "iqlab_rewards.vouchers" */
export type Iqlab_Rewards_Vouchers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Iqlab_Rewards_Vouchers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "iqlab_rewards.vouchers" */
export type Iqlab_Rewards_Vouchers_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Iqlab_Rewards_Vouchers_Max_Order_By>;
  min?: InputMaybe<Iqlab_Rewards_Vouchers_Min_Order_By>;
};

/** input type for inserting array relation for remote table "iqlab_rewards.vouchers" */
export type Iqlab_Rewards_Vouchers_Arr_Rel_Insert_Input = {
  data: Array<Iqlab_Rewards_Vouchers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Iqlab_Rewards_Vouchers_On_Conflict>;
};

/** Boolean expression to filter rows from the table "iqlab_rewards.vouchers". All fields are combined with a logical 'AND'. */
export type Iqlab_Rewards_Vouchers_Bool_Exp = {
  _and?: InputMaybe<Array<Iqlab_Rewards_Vouchers_Bool_Exp>>;
  _not?: InputMaybe<Iqlab_Rewards_Vouchers_Bool_Exp>;
  _or?: InputMaybe<Array<Iqlab_Rewards_Vouchers_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  expires_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Details_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "iqlab_rewards.vouchers" */
export enum Iqlab_Rewards_Vouchers_Constraint {
  /** unique or primary key constraint on columns "code" */
  VouchersCodeKey = 'vouchers_code_key',
  /** unique or primary key constraint on columns "created_at", "code" */
  VouchersPkey = 'vouchers_pkey'
}

/** input type for inserting data into table "iqlab_rewards.vouchers" */
export type Iqlab_Rewards_Vouchers_Insert_Input = {
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  expires_at?: InputMaybe<Scalars['timestamptz']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Details_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Iqlab_Rewards_Vouchers_Max_Fields = {
  __typename?: 'iqlab_rewards_vouchers_max_fields';
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "iqlab_rewards.vouchers" */
export type Iqlab_Rewards_Vouchers_Max_Order_By = {
  code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Iqlab_Rewards_Vouchers_Min_Fields = {
  __typename?: 'iqlab_rewards_vouchers_min_fields';
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "iqlab_rewards.vouchers" */
export type Iqlab_Rewards_Vouchers_Min_Order_By = {
  code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "iqlab_rewards.vouchers" */
export type Iqlab_Rewards_Vouchers_Mutation_Response = {
  __typename?: 'iqlab_rewards_vouchers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Iqlab_Rewards_Vouchers>;
};

/** on_conflict condition type for table "iqlab_rewards.vouchers" */
export type Iqlab_Rewards_Vouchers_On_Conflict = {
  constraint: Iqlab_Rewards_Vouchers_Constraint;
  update_columns?: Array<Iqlab_Rewards_Vouchers_Update_Column>;
  where?: InputMaybe<Iqlab_Rewards_Vouchers_Bool_Exp>;
};

/** Ordering options when selecting data from "iqlab_rewards.vouchers". */
export type Iqlab_Rewards_Vouchers_Order_By = {
  code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Details_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: iqlab_rewards.vouchers */
export type Iqlab_Rewards_Vouchers_Pk_Columns_Input = {
  code: Scalars['String'];
  created_at: Scalars['timestamptz'];
};

/** select columns of table "iqlab_rewards.vouchers" */
export enum Iqlab_Rewards_Vouchers_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "iqlab_rewards.vouchers" */
export type Iqlab_Rewards_Vouchers_Set_Input = {
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  expires_at?: InputMaybe<Scalars['timestamptz']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "iqlab_rewards_vouchers" */
export type Iqlab_Rewards_Vouchers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Iqlab_Rewards_Vouchers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Iqlab_Rewards_Vouchers_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  expires_at?: InputMaybe<Scalars['timestamptz']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "iqlab_rewards.vouchers" */
export enum Iqlab_Rewards_Vouchers_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Iqlab_Rewards_Vouchers_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Iqlab_Rewards_Vouchers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Iqlab_Rewards_Vouchers_Bool_Exp;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** stores a single measure in a given session */
export type Measurement_Measure = {
  __typename?: 'measurement_measure';
  /** An object relationship */
  session: Measurement_Sessions;
  session_id: Scalars['Int'];
  type: Scalars['String'];
  value: Scalars['numeric'];
};

/** aggregated selection of "measurement.measure" */
export type Measurement_Measure_Aggregate = {
  __typename?: 'measurement_measure_aggregate';
  aggregate?: Maybe<Measurement_Measure_Aggregate_Fields>;
  nodes: Array<Measurement_Measure>;
};

export type Measurement_Measure_Aggregate_Bool_Exp = {
  count?: InputMaybe<Measurement_Measure_Aggregate_Bool_Exp_Count>;
};

export type Measurement_Measure_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Measurement_Measure_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Measurement_Measure_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "measurement.measure" */
export type Measurement_Measure_Aggregate_Fields = {
  __typename?: 'measurement_measure_aggregate_fields';
  avg?: Maybe<Measurement_Measure_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Measurement_Measure_Max_Fields>;
  min?: Maybe<Measurement_Measure_Min_Fields>;
  stddev?: Maybe<Measurement_Measure_Stddev_Fields>;
  stddev_pop?: Maybe<Measurement_Measure_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Measurement_Measure_Stddev_Samp_Fields>;
  sum?: Maybe<Measurement_Measure_Sum_Fields>;
  var_pop?: Maybe<Measurement_Measure_Var_Pop_Fields>;
  var_samp?: Maybe<Measurement_Measure_Var_Samp_Fields>;
  variance?: Maybe<Measurement_Measure_Variance_Fields>;
};


/** aggregate fields of "measurement.measure" */
export type Measurement_Measure_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Measurement_Measure_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "measurement.measure" */
export type Measurement_Measure_Aggregate_Order_By = {
  avg?: InputMaybe<Measurement_Measure_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Measurement_Measure_Max_Order_By>;
  min?: InputMaybe<Measurement_Measure_Min_Order_By>;
  stddev?: InputMaybe<Measurement_Measure_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Measurement_Measure_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Measurement_Measure_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Measurement_Measure_Sum_Order_By>;
  var_pop?: InputMaybe<Measurement_Measure_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Measurement_Measure_Var_Samp_Order_By>;
  variance?: InputMaybe<Measurement_Measure_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "measurement.measure" */
export type Measurement_Measure_Arr_Rel_Insert_Input = {
  data: Array<Measurement_Measure_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Measurement_Measure_On_Conflict>;
};

/** aggregate avg on columns */
export type Measurement_Measure_Avg_Fields = {
  __typename?: 'measurement_measure_avg_fields';
  session_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "measurement.measure" */
export type Measurement_Measure_Avg_Order_By = {
  session_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "measurement.measure". All fields are combined with a logical 'AND'. */
export type Measurement_Measure_Bool_Exp = {
  _and?: InputMaybe<Array<Measurement_Measure_Bool_Exp>>;
  _not?: InputMaybe<Measurement_Measure_Bool_Exp>;
  _or?: InputMaybe<Array<Measurement_Measure_Bool_Exp>>;
  session?: InputMaybe<Measurement_Sessions_Bool_Exp>;
  session_id?: InputMaybe<Int_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "measurement.measure" */
export enum Measurement_Measure_Constraint {
  /** unique or primary key constraint on columns "session_id", "type" */
  MeasurePkey = 'measure_pkey'
}

/** input type for incrementing numeric columns in table "measurement.measure" */
export type Measurement_Measure_Inc_Input = {
  session_id?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "measurement.measure" */
export type Measurement_Measure_Insert_Input = {
  session?: InputMaybe<Measurement_Sessions_Obj_Rel_Insert_Input>;
  session_id?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Measurement_Measure_Max_Fields = {
  __typename?: 'measurement_measure_max_fields';
  session_id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "measurement.measure" */
export type Measurement_Measure_Max_Order_By = {
  session_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Measurement_Measure_Min_Fields = {
  __typename?: 'measurement_measure_min_fields';
  session_id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "measurement.measure" */
export type Measurement_Measure_Min_Order_By = {
  session_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "measurement.measure" */
export type Measurement_Measure_Mutation_Response = {
  __typename?: 'measurement_measure_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Measurement_Measure>;
};

/** on_conflict condition type for table "measurement.measure" */
export type Measurement_Measure_On_Conflict = {
  constraint: Measurement_Measure_Constraint;
  update_columns?: Array<Measurement_Measure_Update_Column>;
  where?: InputMaybe<Measurement_Measure_Bool_Exp>;
};

/** Ordering options when selecting data from "measurement.measure". */
export type Measurement_Measure_Order_By = {
  session?: InputMaybe<Measurement_Sessions_Order_By>;
  session_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: measurement.measure */
export type Measurement_Measure_Pk_Columns_Input = {
  session_id: Scalars['Int'];
  type: Scalars['String'];
};

/** select columns of table "measurement.measure" */
export enum Measurement_Measure_Select_Column {
  /** column name */
  SessionId = 'session_id',
  /** column name */
  Type = 'type',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "measurement.measure" */
export type Measurement_Measure_Set_Input = {
  session_id?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Measurement_Measure_Stddev_Fields = {
  __typename?: 'measurement_measure_stddev_fields';
  session_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "measurement.measure" */
export type Measurement_Measure_Stddev_Order_By = {
  session_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Measurement_Measure_Stddev_Pop_Fields = {
  __typename?: 'measurement_measure_stddev_pop_fields';
  session_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "measurement.measure" */
export type Measurement_Measure_Stddev_Pop_Order_By = {
  session_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Measurement_Measure_Stddev_Samp_Fields = {
  __typename?: 'measurement_measure_stddev_samp_fields';
  session_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "measurement.measure" */
export type Measurement_Measure_Stddev_Samp_Order_By = {
  session_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "measurement_measure" */
export type Measurement_Measure_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Measurement_Measure_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Measurement_Measure_Stream_Cursor_Value_Input = {
  session_id?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Measurement_Measure_Sum_Fields = {
  __typename?: 'measurement_measure_sum_fields';
  session_id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "measurement.measure" */
export type Measurement_Measure_Sum_Order_By = {
  session_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** different types of measurements and explanations */
export type Measurement_Measure_Type = {
  __typename?: 'measurement_measure_type';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "measurement.measure_type" */
export type Measurement_Measure_Type_Aggregate = {
  __typename?: 'measurement_measure_type_aggregate';
  aggregate?: Maybe<Measurement_Measure_Type_Aggregate_Fields>;
  nodes: Array<Measurement_Measure_Type>;
};

/** aggregate fields of "measurement.measure_type" */
export type Measurement_Measure_Type_Aggregate_Fields = {
  __typename?: 'measurement_measure_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Measurement_Measure_Type_Max_Fields>;
  min?: Maybe<Measurement_Measure_Type_Min_Fields>;
};


/** aggregate fields of "measurement.measure_type" */
export type Measurement_Measure_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Measurement_Measure_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "measurement.measure_type". All fields are combined with a logical 'AND'. */
export type Measurement_Measure_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Measurement_Measure_Type_Bool_Exp>>;
  _not?: InputMaybe<Measurement_Measure_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Measurement_Measure_Type_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "measurement.measure_type" */
export enum Measurement_Measure_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  MeasureTypesPkey = 'measure_types_pkey'
}

/** input type for inserting data into table "measurement.measure_type" */
export type Measurement_Measure_Type_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Measurement_Measure_Type_Max_Fields = {
  __typename?: 'measurement_measure_type_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Measurement_Measure_Type_Min_Fields = {
  __typename?: 'measurement_measure_type_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "measurement.measure_type" */
export type Measurement_Measure_Type_Mutation_Response = {
  __typename?: 'measurement_measure_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Measurement_Measure_Type>;
};

/** on_conflict condition type for table "measurement.measure_type" */
export type Measurement_Measure_Type_On_Conflict = {
  constraint: Measurement_Measure_Type_Constraint;
  update_columns?: Array<Measurement_Measure_Type_Update_Column>;
  where?: InputMaybe<Measurement_Measure_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "measurement.measure_type". */
export type Measurement_Measure_Type_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: measurement.measure_type */
export type Measurement_Measure_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "measurement.measure_type" */
export enum Measurement_Measure_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "measurement.measure_type" */
export type Measurement_Measure_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "measurement_measure_type" */
export type Measurement_Measure_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Measurement_Measure_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Measurement_Measure_Type_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "measurement.measure_type" */
export enum Measurement_Measure_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type Measurement_Measure_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Measurement_Measure_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Measurement_Measure_Type_Bool_Exp;
};

/** update columns of table "measurement.measure" */
export enum Measurement_Measure_Update_Column {
  /** column name */
  SessionId = 'session_id',
  /** column name */
  Type = 'type',
  /** column name */
  Value = 'value'
}

export type Measurement_Measure_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Measurement_Measure_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Measurement_Measure_Set_Input>;
  /** filter the rows which have to be updated */
  where: Measurement_Measure_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Measurement_Measure_Var_Pop_Fields = {
  __typename?: 'measurement_measure_var_pop_fields';
  session_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "measurement.measure" */
export type Measurement_Measure_Var_Pop_Order_By = {
  session_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Measurement_Measure_Var_Samp_Fields = {
  __typename?: 'measurement_measure_var_samp_fields';
  session_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "measurement.measure" */
export type Measurement_Measure_Var_Samp_Order_By = {
  session_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Measurement_Measure_Variance_Fields = {
  __typename?: 'measurement_measure_variance_fields';
  session_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "measurement.measure" */
export type Measurement_Measure_Variance_Order_By = {
  session_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** stores the type of session e.g. manual or CV */
export type Measurement_Session_Source = {
  __typename?: 'measurement_session_source';
  value: Scalars['String'];
};

/** aggregated selection of "measurement.session_source" */
export type Measurement_Session_Source_Aggregate = {
  __typename?: 'measurement_session_source_aggregate';
  aggregate?: Maybe<Measurement_Session_Source_Aggregate_Fields>;
  nodes: Array<Measurement_Session_Source>;
};

/** aggregate fields of "measurement.session_source" */
export type Measurement_Session_Source_Aggregate_Fields = {
  __typename?: 'measurement_session_source_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Measurement_Session_Source_Max_Fields>;
  min?: Maybe<Measurement_Session_Source_Min_Fields>;
};


/** aggregate fields of "measurement.session_source" */
export type Measurement_Session_Source_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Measurement_Session_Source_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "measurement.session_source". All fields are combined with a logical 'AND'. */
export type Measurement_Session_Source_Bool_Exp = {
  _and?: InputMaybe<Array<Measurement_Session_Source_Bool_Exp>>;
  _not?: InputMaybe<Measurement_Session_Source_Bool_Exp>;
  _or?: InputMaybe<Array<Measurement_Session_Source_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "measurement.session_source" */
export enum Measurement_Session_Source_Constraint {
  /** unique or primary key constraint on columns "value" */
  SessionTypePkey = 'session_type_pkey'
}

export enum Measurement_Session_Source_Enum {
  AppManual = 'APP_MANUAL',
  CvBackend = 'CV_BACKEND'
}

/** Boolean expression to compare columns of type "measurement_session_source_enum". All fields are combined with logical 'AND'. */
export type Measurement_Session_Source_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Measurement_Session_Source_Enum>;
  _in?: InputMaybe<Array<Measurement_Session_Source_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Measurement_Session_Source_Enum>;
  _nin?: InputMaybe<Array<Measurement_Session_Source_Enum>>;
};

/** input type for inserting data into table "measurement.session_source" */
export type Measurement_Session_Source_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Measurement_Session_Source_Max_Fields = {
  __typename?: 'measurement_session_source_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Measurement_Session_Source_Min_Fields = {
  __typename?: 'measurement_session_source_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "measurement.session_source" */
export type Measurement_Session_Source_Mutation_Response = {
  __typename?: 'measurement_session_source_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Measurement_Session_Source>;
};

/** on_conflict condition type for table "measurement.session_source" */
export type Measurement_Session_Source_On_Conflict = {
  constraint: Measurement_Session_Source_Constraint;
  update_columns?: Array<Measurement_Session_Source_Update_Column>;
  where?: InputMaybe<Measurement_Session_Source_Bool_Exp>;
};

/** Ordering options when selecting data from "measurement.session_source". */
export type Measurement_Session_Source_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: measurement.session_source */
export type Measurement_Session_Source_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "measurement.session_source" */
export enum Measurement_Session_Source_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "measurement.session_source" */
export type Measurement_Session_Source_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "measurement_session_source" */
export type Measurement_Session_Source_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Measurement_Session_Source_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Measurement_Session_Source_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "measurement.session_source" */
export enum Measurement_Session_Source_Update_Column {
  /** column name */
  Value = 'value'
}

export type Measurement_Session_Source_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Measurement_Session_Source_Set_Input>;
  /** filter the rows which have to be updated */
  where: Measurement_Session_Source_Bool_Exp;
};

/** defines what kind of measurement session was done */
export type Measurement_Session_Type = {
  __typename?: 'measurement_session_type';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "measurement.session_type" */
export type Measurement_Session_Type_Aggregate = {
  __typename?: 'measurement_session_type_aggregate';
  aggregate?: Maybe<Measurement_Session_Type_Aggregate_Fields>;
  nodes: Array<Measurement_Session_Type>;
};

/** aggregate fields of "measurement.session_type" */
export type Measurement_Session_Type_Aggregate_Fields = {
  __typename?: 'measurement_session_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Measurement_Session_Type_Max_Fields>;
  min?: Maybe<Measurement_Session_Type_Min_Fields>;
};


/** aggregate fields of "measurement.session_type" */
export type Measurement_Session_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Measurement_Session_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "measurement.session_type". All fields are combined with a logical 'AND'. */
export type Measurement_Session_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Measurement_Session_Type_Bool_Exp>>;
  _not?: InputMaybe<Measurement_Session_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Measurement_Session_Type_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "measurement.session_type" */
export enum Measurement_Session_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  SessionTypePkey1 = 'session_type_pkey1'
}

export enum Measurement_Session_Type_Enum {
  /** stores measurement of the whole body */
  FullBody = 'FULL_BODY',
  /** includes hand with and grip width */
  Hand = 'HAND',
  /** typically done with the sitbone paper */
  Sitbone = 'SITBONE'
}

/** Boolean expression to compare columns of type "measurement_session_type_enum". All fields are combined with logical 'AND'. */
export type Measurement_Session_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Measurement_Session_Type_Enum>;
  _in?: InputMaybe<Array<Measurement_Session_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Measurement_Session_Type_Enum>;
  _nin?: InputMaybe<Array<Measurement_Session_Type_Enum>>;
};

/** input type for inserting data into table "measurement.session_type" */
export type Measurement_Session_Type_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Measurement_Session_Type_Max_Fields = {
  __typename?: 'measurement_session_type_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Measurement_Session_Type_Min_Fields = {
  __typename?: 'measurement_session_type_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "measurement.session_type" */
export type Measurement_Session_Type_Mutation_Response = {
  __typename?: 'measurement_session_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Measurement_Session_Type>;
};

/** on_conflict condition type for table "measurement.session_type" */
export type Measurement_Session_Type_On_Conflict = {
  constraint: Measurement_Session_Type_Constraint;
  update_columns?: Array<Measurement_Session_Type_Update_Column>;
  where?: InputMaybe<Measurement_Session_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "measurement.session_type". */
export type Measurement_Session_Type_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: measurement.session_type */
export type Measurement_Session_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "measurement.session_type" */
export enum Measurement_Session_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "measurement.session_type" */
export type Measurement_Session_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "measurement_session_type" */
export type Measurement_Session_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Measurement_Session_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Measurement_Session_Type_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "measurement.session_type" */
export enum Measurement_Session_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type Measurement_Session_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Measurement_Session_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Measurement_Session_Type_Bool_Exp;
};

/** stores multiple measurement that are made at the same time */
export type Measurement_Sessions = {
  __typename?: 'measurement_sessions';
  /** An object relationship */
  answer: Customer_Answer;
  /** the corresponding answer id */
  answer_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  /** stores an error code or message, if any */
  error?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An array relationship */
  measures: Array<Measurement_Measure>;
  /** An aggregate relationship */
  measures_aggregate: Measurement_Measure_Aggregate;
  /** Stores an image or video url */
  media_url?: Maybe<Scalars['String']>;
  /** stores additional metadata like durations, landmarks */
  metadata?: Maybe<Scalars['jsonb']>;
  /** the source like CV backend or app */
  source: Measurement_Session_Source_Enum;
  /** the type of the session like hand, sitbone or fullbody */
  type: Measurement_Session_Type_Enum;
};


/** stores multiple measurement that are made at the same time */
export type Measurement_SessionsMeasuresArgs = {
  distinct_on?: InputMaybe<Array<Measurement_Measure_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Measurement_Measure_Order_By>>;
  where?: InputMaybe<Measurement_Measure_Bool_Exp>;
};


/** stores multiple measurement that are made at the same time */
export type Measurement_SessionsMeasures_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Measurement_Measure_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Measurement_Measure_Order_By>>;
  where?: InputMaybe<Measurement_Measure_Bool_Exp>;
};


/** stores multiple measurement that are made at the same time */
export type Measurement_SessionsMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "measurement.sessions" */
export type Measurement_Sessions_Aggregate = {
  __typename?: 'measurement_sessions_aggregate';
  aggregate?: Maybe<Measurement_Sessions_Aggregate_Fields>;
  nodes: Array<Measurement_Sessions>;
};

/** aggregate fields of "measurement.sessions" */
export type Measurement_Sessions_Aggregate_Fields = {
  __typename?: 'measurement_sessions_aggregate_fields';
  avg?: Maybe<Measurement_Sessions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Measurement_Sessions_Max_Fields>;
  min?: Maybe<Measurement_Sessions_Min_Fields>;
  stddev?: Maybe<Measurement_Sessions_Stddev_Fields>;
  stddev_pop?: Maybe<Measurement_Sessions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Measurement_Sessions_Stddev_Samp_Fields>;
  sum?: Maybe<Measurement_Sessions_Sum_Fields>;
  var_pop?: Maybe<Measurement_Sessions_Var_Pop_Fields>;
  var_samp?: Maybe<Measurement_Sessions_Var_Samp_Fields>;
  variance?: Maybe<Measurement_Sessions_Variance_Fields>;
};


/** aggregate fields of "measurement.sessions" */
export type Measurement_Sessions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Measurement_Sessions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Measurement_Sessions_Append_Input = {
  /** stores additional metadata like durations, landmarks */
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Measurement_Sessions_Avg_Fields = {
  __typename?: 'measurement_sessions_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "measurement.sessions". All fields are combined with a logical 'AND'. */
export type Measurement_Sessions_Bool_Exp = {
  _and?: InputMaybe<Array<Measurement_Sessions_Bool_Exp>>;
  _not?: InputMaybe<Measurement_Sessions_Bool_Exp>;
  _or?: InputMaybe<Array<Measurement_Sessions_Bool_Exp>>;
  answer?: InputMaybe<Customer_Answer_Bool_Exp>;
  answer_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  error?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  measures?: InputMaybe<Measurement_Measure_Bool_Exp>;
  measures_aggregate?: InputMaybe<Measurement_Measure_Aggregate_Bool_Exp>;
  media_url?: InputMaybe<String_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  source?: InputMaybe<Measurement_Session_Source_Enum_Comparison_Exp>;
  type?: InputMaybe<Measurement_Session_Type_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "measurement.sessions" */
export enum Measurement_Sessions_Constraint {
  /** unique or primary key constraint on columns "id" */
  SessionPkey = 'session_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Measurement_Sessions_Delete_At_Path_Input = {
  /** stores additional metadata like durations, landmarks */
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Measurement_Sessions_Delete_Elem_Input = {
  /** stores additional metadata like durations, landmarks */
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Measurement_Sessions_Delete_Key_Input = {
  /** stores additional metadata like durations, landmarks */
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "measurement.sessions" */
export type Measurement_Sessions_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "measurement.sessions" */
export type Measurement_Sessions_Insert_Input = {
  answer?: InputMaybe<Customer_Answer_Obj_Rel_Insert_Input>;
  /** the corresponding answer id */
  answer_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** stores an error code or message, if any */
  error?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  measures?: InputMaybe<Measurement_Measure_Arr_Rel_Insert_Input>;
  /** Stores an image or video url */
  media_url?: InputMaybe<Scalars['String']>;
  /** stores additional metadata like durations, landmarks */
  metadata?: InputMaybe<Scalars['jsonb']>;
  /** the source like CV backend or app */
  source?: InputMaybe<Measurement_Session_Source_Enum>;
  /** the type of the session like hand, sitbone or fullbody */
  type?: InputMaybe<Measurement_Session_Type_Enum>;
};

/** aggregate max on columns */
export type Measurement_Sessions_Max_Fields = {
  __typename?: 'measurement_sessions_max_fields';
  /** the corresponding answer id */
  answer_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** stores an error code or message, if any */
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** Stores an image or video url */
  media_url?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Measurement_Sessions_Min_Fields = {
  __typename?: 'measurement_sessions_min_fields';
  /** the corresponding answer id */
  answer_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** stores an error code or message, if any */
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** Stores an image or video url */
  media_url?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "measurement.sessions" */
export type Measurement_Sessions_Mutation_Response = {
  __typename?: 'measurement_sessions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Measurement_Sessions>;
};

/** input type for inserting object relation for remote table "measurement.sessions" */
export type Measurement_Sessions_Obj_Rel_Insert_Input = {
  data: Measurement_Sessions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Measurement_Sessions_On_Conflict>;
};

/** on_conflict condition type for table "measurement.sessions" */
export type Measurement_Sessions_On_Conflict = {
  constraint: Measurement_Sessions_Constraint;
  update_columns?: Array<Measurement_Sessions_Update_Column>;
  where?: InputMaybe<Measurement_Sessions_Bool_Exp>;
};

/** Ordering options when selecting data from "measurement.sessions". */
export type Measurement_Sessions_Order_By = {
  answer?: InputMaybe<Customer_Answer_Order_By>;
  answer_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  error?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  measures_aggregate?: InputMaybe<Measurement_Measure_Aggregate_Order_By>;
  media_url?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: measurement.sessions */
export type Measurement_Sessions_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Measurement_Sessions_Prepend_Input = {
  /** stores additional metadata like durations, landmarks */
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "measurement.sessions" */
export enum Measurement_Sessions_Select_Column {
  /** column name */
  AnswerId = 'answer_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Error = 'error',
  /** column name */
  Id = 'id',
  /** column name */
  MediaUrl = 'media_url',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Source = 'source',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "measurement.sessions" */
export type Measurement_Sessions_Set_Input = {
  /** the corresponding answer id */
  answer_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** stores an error code or message, if any */
  error?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  /** Stores an image or video url */
  media_url?: InputMaybe<Scalars['String']>;
  /** stores additional metadata like durations, landmarks */
  metadata?: InputMaybe<Scalars['jsonb']>;
  /** the source like CV backend or app */
  source?: InputMaybe<Measurement_Session_Source_Enum>;
  /** the type of the session like hand, sitbone or fullbody */
  type?: InputMaybe<Measurement_Session_Type_Enum>;
};

/** aggregate stddev on columns */
export type Measurement_Sessions_Stddev_Fields = {
  __typename?: 'measurement_sessions_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Measurement_Sessions_Stddev_Pop_Fields = {
  __typename?: 'measurement_sessions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Measurement_Sessions_Stddev_Samp_Fields = {
  __typename?: 'measurement_sessions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "measurement_sessions" */
export type Measurement_Sessions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Measurement_Sessions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Measurement_Sessions_Stream_Cursor_Value_Input = {
  /** the corresponding answer id */
  answer_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** stores an error code or message, if any */
  error?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  /** Stores an image or video url */
  media_url?: InputMaybe<Scalars['String']>;
  /** stores additional metadata like durations, landmarks */
  metadata?: InputMaybe<Scalars['jsonb']>;
  /** the source like CV backend or app */
  source?: InputMaybe<Measurement_Session_Source_Enum>;
  /** the type of the session like hand, sitbone or fullbody */
  type?: InputMaybe<Measurement_Session_Type_Enum>;
};

/** aggregate sum on columns */
export type Measurement_Sessions_Sum_Fields = {
  __typename?: 'measurement_sessions_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "measurement.sessions" */
export enum Measurement_Sessions_Update_Column {
  /** column name */
  AnswerId = 'answer_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Error = 'error',
  /** column name */
  Id = 'id',
  /** column name */
  MediaUrl = 'media_url',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Source = 'source',
  /** column name */
  Type = 'type'
}

export type Measurement_Sessions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Measurement_Sessions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Measurement_Sessions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Measurement_Sessions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Measurement_Sessions_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Measurement_Sessions_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Measurement_Sessions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Measurement_Sessions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Measurement_Sessions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Measurement_Sessions_Var_Pop_Fields = {
  __typename?: 'measurement_sessions_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Measurement_Sessions_Var_Samp_Fields = {
  __typename?: 'measurement_sessions_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Measurement_Sessions_Variance_Fields = {
  __typename?: 'measurement_sessions_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** In this table the country in which a consolidation has been done by SQlab will be stored. */
export type Metric_Answer_Location = {
  __typename?: 'metric_answer_location';
  /** An object relationship */
  answer: Customer_Answer;
  answer_id: Scalars['String'];
  country: Scalars['String'];
};

/** aggregated selection of "metric.answer_location" */
export type Metric_Answer_Location_Aggregate = {
  __typename?: 'metric_answer_location_aggregate';
  aggregate?: Maybe<Metric_Answer_Location_Aggregate_Fields>;
  nodes: Array<Metric_Answer_Location>;
};

/** aggregate fields of "metric.answer_location" */
export type Metric_Answer_Location_Aggregate_Fields = {
  __typename?: 'metric_answer_location_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Metric_Answer_Location_Max_Fields>;
  min?: Maybe<Metric_Answer_Location_Min_Fields>;
};


/** aggregate fields of "metric.answer_location" */
export type Metric_Answer_Location_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Metric_Answer_Location_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "metric.answer_location". All fields are combined with a logical 'AND'. */
export type Metric_Answer_Location_Bool_Exp = {
  _and?: InputMaybe<Array<Metric_Answer_Location_Bool_Exp>>;
  _not?: InputMaybe<Metric_Answer_Location_Bool_Exp>;
  _or?: InputMaybe<Array<Metric_Answer_Location_Bool_Exp>>;
  answer?: InputMaybe<Customer_Answer_Bool_Exp>;
  answer_id?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "metric.answer_location" */
export enum Metric_Answer_Location_Constraint {
  /** unique or primary key constraint on columns "answer_id" */
  AnswerLocationPkey = 'answer_location_pkey'
}

/** input type for inserting data into table "metric.answer_location" */
export type Metric_Answer_Location_Insert_Input = {
  answer?: InputMaybe<Customer_Answer_Obj_Rel_Insert_Input>;
  answer_id?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Metric_Answer_Location_Max_Fields = {
  __typename?: 'metric_answer_location_max_fields';
  answer_id?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Metric_Answer_Location_Min_Fields = {
  __typename?: 'metric_answer_location_min_fields';
  answer_id?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "metric.answer_location" */
export type Metric_Answer_Location_Mutation_Response = {
  __typename?: 'metric_answer_location_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Metric_Answer_Location>;
};

/** on_conflict condition type for table "metric.answer_location" */
export type Metric_Answer_Location_On_Conflict = {
  constraint: Metric_Answer_Location_Constraint;
  update_columns?: Array<Metric_Answer_Location_Update_Column>;
  where?: InputMaybe<Metric_Answer_Location_Bool_Exp>;
};

/** Ordering options when selecting data from "metric.answer_location". */
export type Metric_Answer_Location_Order_By = {
  answer?: InputMaybe<Customer_Answer_Order_By>;
  answer_id?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
};

/** primary key columns input for table: metric.answer_location */
export type Metric_Answer_Location_Pk_Columns_Input = {
  answer_id: Scalars['String'];
};

/** select columns of table "metric.answer_location" */
export enum Metric_Answer_Location_Select_Column {
  /** column name */
  AnswerId = 'answer_id',
  /** column name */
  Country = 'country'
}

/** input type for updating data in table "metric.answer_location" */
export type Metric_Answer_Location_Set_Input = {
  answer_id?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "metric_answer_location" */
export type Metric_Answer_Location_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Metric_Answer_Location_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Metric_Answer_Location_Stream_Cursor_Value_Input = {
  answer_id?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
};

/** update columns of table "metric.answer_location" */
export enum Metric_Answer_Location_Update_Column {
  /** column name */
  AnswerId = 'answer_id',
  /** column name */
  Country = 'country'
}

export type Metric_Answer_Location_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Metric_Answer_Location_Set_Input>;
  /** filter the rows which have to be updated */
  where: Metric_Answer_Location_Bool_Exp;
};

/** In this table all kinds of events of the profiler app are being logged */
export type Metric_App_Events = {
  __typename?: 'metric_app_events';
  answer_id?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  /** naming convention: CAPS_WITH_UNDERSCORES */
  event_name: Scalars['String'];
  level: Event_Enums_Log_Level_Enum;
  payload: Scalars['jsonb'];
  user_id: Scalars['uuid'];
  vendor_id?: Maybe<Scalars['String']>;
};


/** In this table all kinds of events of the profiler app are being logged */
export type Metric_App_EventsPayloadArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "metric.app_events" */
export type Metric_App_Events_Aggregate = {
  __typename?: 'metric_app_events_aggregate';
  aggregate?: Maybe<Metric_App_Events_Aggregate_Fields>;
  nodes: Array<Metric_App_Events>;
};

export type Metric_App_Events_Aggregate_Bool_Exp = {
  count?: InputMaybe<Metric_App_Events_Aggregate_Bool_Exp_Count>;
};

export type Metric_App_Events_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Metric_App_Events_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Metric_App_Events_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "metric.app_events" */
export type Metric_App_Events_Aggregate_Fields = {
  __typename?: 'metric_app_events_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Metric_App_Events_Max_Fields>;
  min?: Maybe<Metric_App_Events_Min_Fields>;
};


/** aggregate fields of "metric.app_events" */
export type Metric_App_Events_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Metric_App_Events_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "metric.app_events" */
export type Metric_App_Events_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Metric_App_Events_Max_Order_By>;
  min?: InputMaybe<Metric_App_Events_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Metric_App_Events_Append_Input = {
  payload?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "metric.app_events" */
export type Metric_App_Events_Arr_Rel_Insert_Input = {
  data: Array<Metric_App_Events_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Metric_App_Events_On_Conflict>;
};

/** Boolean expression to filter rows from the table "metric.app_events". All fields are combined with a logical 'AND'. */
export type Metric_App_Events_Bool_Exp = {
  _and?: InputMaybe<Array<Metric_App_Events_Bool_Exp>>;
  _not?: InputMaybe<Metric_App_Events_Bool_Exp>;
  _or?: InputMaybe<Array<Metric_App_Events_Bool_Exp>>;
  answer_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  event_name?: InputMaybe<String_Comparison_Exp>;
  level?: InputMaybe<Event_Enums_Log_Level_Enum_Comparison_Exp>;
  payload?: InputMaybe<Jsonb_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  vendor_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "metric.app_events" */
export enum Metric_App_Events_Constraint {
  /** unique or primary key constraint on columns "user_id", "created_at" */
  AppEventsPkey = 'app_events_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Metric_App_Events_Delete_At_Path_Input = {
  payload?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Metric_App_Events_Delete_Elem_Input = {
  payload?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Metric_App_Events_Delete_Key_Input = {
  payload?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "metric.app_events" */
export type Metric_App_Events_Insert_Input = {
  answer_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  /** naming convention: CAPS_WITH_UNDERSCORES */
  event_name?: InputMaybe<Scalars['String']>;
  level?: InputMaybe<Event_Enums_Log_Level_Enum>;
  payload?: InputMaybe<Scalars['jsonb']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Metric_App_Events_Max_Fields = {
  __typename?: 'metric_app_events_max_fields';
  answer_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  /** naming convention: CAPS_WITH_UNDERSCORES */
  event_name?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "metric.app_events" */
export type Metric_App_Events_Max_Order_By = {
  answer_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  /** naming convention: CAPS_WITH_UNDERSCORES */
  event_name?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Metric_App_Events_Min_Fields = {
  __typename?: 'metric_app_events_min_fields';
  answer_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  /** naming convention: CAPS_WITH_UNDERSCORES */
  event_name?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "metric.app_events" */
export type Metric_App_Events_Min_Order_By = {
  answer_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  /** naming convention: CAPS_WITH_UNDERSCORES */
  event_name?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "metric.app_events" */
export type Metric_App_Events_Mutation_Response = {
  __typename?: 'metric_app_events_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Metric_App_Events>;
};

/** on_conflict condition type for table "metric.app_events" */
export type Metric_App_Events_On_Conflict = {
  constraint: Metric_App_Events_Constraint;
  update_columns?: Array<Metric_App_Events_Update_Column>;
  where?: InputMaybe<Metric_App_Events_Bool_Exp>;
};

/** Ordering options when selecting data from "metric.app_events". */
export type Metric_App_Events_Order_By = {
  answer_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  event_name?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  payload?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: metric.app_events */
export type Metric_App_Events_Pk_Columns_Input = {
  created_at: Scalars['timestamptz'];
  user_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Metric_App_Events_Prepend_Input = {
  payload?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "metric.app_events" */
export enum Metric_App_Events_Select_Column {
  /** column name */
  AnswerId = 'answer_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  EventName = 'event_name',
  /** column name */
  Level = 'level',
  /** column name */
  Payload = 'payload',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VendorId = 'vendor_id'
}

/** input type for updating data in table "metric.app_events" */
export type Metric_App_Events_Set_Input = {
  answer_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  /** naming convention: CAPS_WITH_UNDERSCORES */
  event_name?: InputMaybe<Scalars['String']>;
  level?: InputMaybe<Event_Enums_Log_Level_Enum>;
  payload?: InputMaybe<Scalars['jsonb']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "metric_app_events" */
export type Metric_App_Events_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Metric_App_Events_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Metric_App_Events_Stream_Cursor_Value_Input = {
  answer_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  /** naming convention: CAPS_WITH_UNDERSCORES */
  event_name?: InputMaybe<Scalars['String']>;
  level?: InputMaybe<Event_Enums_Log_Level_Enum>;
  payload?: InputMaybe<Scalars['jsonb']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "metric.app_events" */
export enum Metric_App_Events_Update_Column {
  /** column name */
  AnswerId = 'answer_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  EventName = 'event_name',
  /** column name */
  Level = 'level',
  /** column name */
  Payload = 'payload',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VendorId = 'vendor_id'
}

export type Metric_App_Events_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Metric_App_Events_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Metric_App_Events_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Metric_App_Events_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Metric_App_Events_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Metric_App_Events_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Metric_App_Events_Set_Input>;
  /** filter the rows which have to be updated */
  where: Metric_App_Events_Bool_Exp;
};

/** columns and relationships of "metric.avg_questionnaire_count_30_days" */
export type Metric_Avg_Questionnaire_Count_30_Days = {
  __typename?: 'metric_avg_questionnaire_count_30_days';
  avg_questionnaire_count?: Maybe<Scalars['numeric']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "metric.avg_questionnaire_count_30_days" */
export type Metric_Avg_Questionnaire_Count_30_Days_Aggregate = {
  __typename?: 'metric_avg_questionnaire_count_30_days_aggregate';
  aggregate?: Maybe<Metric_Avg_Questionnaire_Count_30_Days_Aggregate_Fields>;
  nodes: Array<Metric_Avg_Questionnaire_Count_30_Days>;
};

/** aggregate fields of "metric.avg_questionnaire_count_30_days" */
export type Metric_Avg_Questionnaire_Count_30_Days_Aggregate_Fields = {
  __typename?: 'metric_avg_questionnaire_count_30_days_aggregate_fields';
  avg?: Maybe<Metric_Avg_Questionnaire_Count_30_Days_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Metric_Avg_Questionnaire_Count_30_Days_Max_Fields>;
  min?: Maybe<Metric_Avg_Questionnaire_Count_30_Days_Min_Fields>;
  stddev?: Maybe<Metric_Avg_Questionnaire_Count_30_Days_Stddev_Fields>;
  stddev_pop?: Maybe<Metric_Avg_Questionnaire_Count_30_Days_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Metric_Avg_Questionnaire_Count_30_Days_Stddev_Samp_Fields>;
  sum?: Maybe<Metric_Avg_Questionnaire_Count_30_Days_Sum_Fields>;
  var_pop?: Maybe<Metric_Avg_Questionnaire_Count_30_Days_Var_Pop_Fields>;
  var_samp?: Maybe<Metric_Avg_Questionnaire_Count_30_Days_Var_Samp_Fields>;
  variance?: Maybe<Metric_Avg_Questionnaire_Count_30_Days_Variance_Fields>;
};


/** aggregate fields of "metric.avg_questionnaire_count_30_days" */
export type Metric_Avg_Questionnaire_Count_30_Days_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Metric_Avg_Questionnaire_Count_30_Days_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Metric_Avg_Questionnaire_Count_30_Days_Avg_Fields = {
  __typename?: 'metric_avg_questionnaire_count_30_days_avg_fields';
  avg_questionnaire_count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "metric.avg_questionnaire_count_30_days". All fields are combined with a logical 'AND'. */
export type Metric_Avg_Questionnaire_Count_30_Days_Bool_Exp = {
  _and?: InputMaybe<Array<Metric_Avg_Questionnaire_Count_30_Days_Bool_Exp>>;
  _not?: InputMaybe<Metric_Avg_Questionnaire_Count_30_Days_Bool_Exp>;
  _or?: InputMaybe<Array<Metric_Avg_Questionnaire_Count_30_Days_Bool_Exp>>;
  avg_questionnaire_count?: InputMaybe<Numeric_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type Metric_Avg_Questionnaire_Count_30_Days_Max_Fields = {
  __typename?: 'metric_avg_questionnaire_count_30_days_max_fields';
  avg_questionnaire_count?: Maybe<Scalars['numeric']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Metric_Avg_Questionnaire_Count_30_Days_Min_Fields = {
  __typename?: 'metric_avg_questionnaire_count_30_days_min_fields';
  avg_questionnaire_count?: Maybe<Scalars['numeric']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "metric.avg_questionnaire_count_30_days". */
export type Metric_Avg_Questionnaire_Count_30_Days_Order_By = {
  avg_questionnaire_count?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "metric.avg_questionnaire_count_30_days" */
export enum Metric_Avg_Questionnaire_Count_30_Days_Select_Column {
  /** column name */
  AvgQuestionnaireCount = 'avg_questionnaire_count',
  /** column name */
  UserId = 'user_id'
}

/** aggregate stddev on columns */
export type Metric_Avg_Questionnaire_Count_30_Days_Stddev_Fields = {
  __typename?: 'metric_avg_questionnaire_count_30_days_stddev_fields';
  avg_questionnaire_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Metric_Avg_Questionnaire_Count_30_Days_Stddev_Pop_Fields = {
  __typename?: 'metric_avg_questionnaire_count_30_days_stddev_pop_fields';
  avg_questionnaire_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Metric_Avg_Questionnaire_Count_30_Days_Stddev_Samp_Fields = {
  __typename?: 'metric_avg_questionnaire_count_30_days_stddev_samp_fields';
  avg_questionnaire_count?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "metric_avg_questionnaire_count_30_days" */
export type Metric_Avg_Questionnaire_Count_30_Days_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Metric_Avg_Questionnaire_Count_30_Days_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Metric_Avg_Questionnaire_Count_30_Days_Stream_Cursor_Value_Input = {
  avg_questionnaire_count?: InputMaybe<Scalars['numeric']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Metric_Avg_Questionnaire_Count_30_Days_Sum_Fields = {
  __typename?: 'metric_avg_questionnaire_count_30_days_sum_fields';
  avg_questionnaire_count?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type Metric_Avg_Questionnaire_Count_30_Days_Var_Pop_Fields = {
  __typename?: 'metric_avg_questionnaire_count_30_days_var_pop_fields';
  avg_questionnaire_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Metric_Avg_Questionnaire_Count_30_Days_Var_Samp_Fields = {
  __typename?: 'metric_avg_questionnaire_count_30_days_var_samp_fields';
  avg_questionnaire_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Metric_Avg_Questionnaire_Count_30_Days_Variance_Fields = {
  __typename?: 'metric_avg_questionnaire_count_30_days_variance_fields';
  avg_questionnaire_count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "metric.max_questionnaires_with_day" */
export type Metric_Max_Questionnaires_With_Day = {
  __typename?: 'metric_max_questionnaires_with_day';
  date?: Maybe<Scalars['date']>;
  questionnaire_count?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "metric.max_questionnaires_with_day" */
export type Metric_Max_Questionnaires_With_Day_Aggregate = {
  __typename?: 'metric_max_questionnaires_with_day_aggregate';
  aggregate?: Maybe<Metric_Max_Questionnaires_With_Day_Aggregate_Fields>;
  nodes: Array<Metric_Max_Questionnaires_With_Day>;
};

/** aggregate fields of "metric.max_questionnaires_with_day" */
export type Metric_Max_Questionnaires_With_Day_Aggregate_Fields = {
  __typename?: 'metric_max_questionnaires_with_day_aggregate_fields';
  avg?: Maybe<Metric_Max_Questionnaires_With_Day_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Metric_Max_Questionnaires_With_Day_Max_Fields>;
  min?: Maybe<Metric_Max_Questionnaires_With_Day_Min_Fields>;
  stddev?: Maybe<Metric_Max_Questionnaires_With_Day_Stddev_Fields>;
  stddev_pop?: Maybe<Metric_Max_Questionnaires_With_Day_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Metric_Max_Questionnaires_With_Day_Stddev_Samp_Fields>;
  sum?: Maybe<Metric_Max_Questionnaires_With_Day_Sum_Fields>;
  var_pop?: Maybe<Metric_Max_Questionnaires_With_Day_Var_Pop_Fields>;
  var_samp?: Maybe<Metric_Max_Questionnaires_With_Day_Var_Samp_Fields>;
  variance?: Maybe<Metric_Max_Questionnaires_With_Day_Variance_Fields>;
};


/** aggregate fields of "metric.max_questionnaires_with_day" */
export type Metric_Max_Questionnaires_With_Day_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Metric_Max_Questionnaires_With_Day_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Metric_Max_Questionnaires_With_Day_Avg_Fields = {
  __typename?: 'metric_max_questionnaires_with_day_avg_fields';
  questionnaire_count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "metric.max_questionnaires_with_day". All fields are combined with a logical 'AND'. */
export type Metric_Max_Questionnaires_With_Day_Bool_Exp = {
  _and?: InputMaybe<Array<Metric_Max_Questionnaires_With_Day_Bool_Exp>>;
  _not?: InputMaybe<Metric_Max_Questionnaires_With_Day_Bool_Exp>;
  _or?: InputMaybe<Array<Metric_Max_Questionnaires_With_Day_Bool_Exp>>;
  date?: InputMaybe<Date_Comparison_Exp>;
  questionnaire_count?: InputMaybe<Bigint_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type Metric_Max_Questionnaires_With_Day_Max_Fields = {
  __typename?: 'metric_max_questionnaires_with_day_max_fields';
  date?: Maybe<Scalars['date']>;
  questionnaire_count?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Metric_Max_Questionnaires_With_Day_Min_Fields = {
  __typename?: 'metric_max_questionnaires_with_day_min_fields';
  date?: Maybe<Scalars['date']>;
  questionnaire_count?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "metric.max_questionnaires_with_day". */
export type Metric_Max_Questionnaires_With_Day_Order_By = {
  date?: InputMaybe<Order_By>;
  questionnaire_count?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "metric.max_questionnaires_with_day" */
export enum Metric_Max_Questionnaires_With_Day_Select_Column {
  /** column name */
  Date = 'date',
  /** column name */
  QuestionnaireCount = 'questionnaire_count',
  /** column name */
  UserId = 'user_id'
}

/** aggregate stddev on columns */
export type Metric_Max_Questionnaires_With_Day_Stddev_Fields = {
  __typename?: 'metric_max_questionnaires_with_day_stddev_fields';
  questionnaire_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Metric_Max_Questionnaires_With_Day_Stddev_Pop_Fields = {
  __typename?: 'metric_max_questionnaires_with_day_stddev_pop_fields';
  questionnaire_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Metric_Max_Questionnaires_With_Day_Stddev_Samp_Fields = {
  __typename?: 'metric_max_questionnaires_with_day_stddev_samp_fields';
  questionnaire_count?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "metric_max_questionnaires_with_day" */
export type Metric_Max_Questionnaires_With_Day_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Metric_Max_Questionnaires_With_Day_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Metric_Max_Questionnaires_With_Day_Stream_Cursor_Value_Input = {
  date?: InputMaybe<Scalars['date']>;
  questionnaire_count?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Metric_Max_Questionnaires_With_Day_Sum_Fields = {
  __typename?: 'metric_max_questionnaires_with_day_sum_fields';
  questionnaire_count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Metric_Max_Questionnaires_With_Day_Var_Pop_Fields = {
  __typename?: 'metric_max_questionnaires_with_day_var_pop_fields';
  questionnaire_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Metric_Max_Questionnaires_With_Day_Var_Samp_Fields = {
  __typename?: 'metric_max_questionnaires_with_day_var_samp_fields';
  questionnaire_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Metric_Max_Questionnaires_With_Day_Variance_Fields = {
  __typename?: 'metric_max_questionnaires_with_day_variance_fields';
  questionnaire_count?: Maybe<Scalars['Float']>;
};

/** collects the duration for the different questions */
export type Metric_Question_Duration = {
  __typename?: 'metric_question_duration';
  /** An object relationship */
  answer: Customer_Answer;
  created_at: Scalars['timestamptz'];
  customer_answer_id: Scalars['String'];
  duration_in_ms: Scalars['Int'];
  question_id: Scalars['String'];
};

/** aggregated selection of "metric.question_duration" */
export type Metric_Question_Duration_Aggregate = {
  __typename?: 'metric_question_duration_aggregate';
  aggregate?: Maybe<Metric_Question_Duration_Aggregate_Fields>;
  nodes: Array<Metric_Question_Duration>;
};

export type Metric_Question_Duration_Aggregate_Bool_Exp = {
  count?: InputMaybe<Metric_Question_Duration_Aggregate_Bool_Exp_Count>;
};

export type Metric_Question_Duration_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Metric_Question_Duration_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Metric_Question_Duration_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "metric.question_duration" */
export type Metric_Question_Duration_Aggregate_Fields = {
  __typename?: 'metric_question_duration_aggregate_fields';
  avg?: Maybe<Metric_Question_Duration_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Metric_Question_Duration_Max_Fields>;
  min?: Maybe<Metric_Question_Duration_Min_Fields>;
  stddev?: Maybe<Metric_Question_Duration_Stddev_Fields>;
  stddev_pop?: Maybe<Metric_Question_Duration_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Metric_Question_Duration_Stddev_Samp_Fields>;
  sum?: Maybe<Metric_Question_Duration_Sum_Fields>;
  var_pop?: Maybe<Metric_Question_Duration_Var_Pop_Fields>;
  var_samp?: Maybe<Metric_Question_Duration_Var_Samp_Fields>;
  variance?: Maybe<Metric_Question_Duration_Variance_Fields>;
};


/** aggregate fields of "metric.question_duration" */
export type Metric_Question_Duration_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Metric_Question_Duration_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "metric.question_duration" */
export type Metric_Question_Duration_Aggregate_Order_By = {
  avg?: InputMaybe<Metric_Question_Duration_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Metric_Question_Duration_Max_Order_By>;
  min?: InputMaybe<Metric_Question_Duration_Min_Order_By>;
  stddev?: InputMaybe<Metric_Question_Duration_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Metric_Question_Duration_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Metric_Question_Duration_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Metric_Question_Duration_Sum_Order_By>;
  var_pop?: InputMaybe<Metric_Question_Duration_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Metric_Question_Duration_Var_Samp_Order_By>;
  variance?: InputMaybe<Metric_Question_Duration_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "metric.question_duration" */
export type Metric_Question_Duration_Arr_Rel_Insert_Input = {
  data: Array<Metric_Question_Duration_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Metric_Question_Duration_On_Conflict>;
};

/** aggregate avg on columns */
export type Metric_Question_Duration_Avg_Fields = {
  __typename?: 'metric_question_duration_avg_fields';
  duration_in_ms?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "metric.question_duration" */
export type Metric_Question_Duration_Avg_Order_By = {
  duration_in_ms?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "metric.question_duration". All fields are combined with a logical 'AND'. */
export type Metric_Question_Duration_Bool_Exp = {
  _and?: InputMaybe<Array<Metric_Question_Duration_Bool_Exp>>;
  _not?: InputMaybe<Metric_Question_Duration_Bool_Exp>;
  _or?: InputMaybe<Array<Metric_Question_Duration_Bool_Exp>>;
  answer?: InputMaybe<Customer_Answer_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  customer_answer_id?: InputMaybe<String_Comparison_Exp>;
  duration_in_ms?: InputMaybe<Int_Comparison_Exp>;
  question_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "metric.question_duration" */
export enum Metric_Question_Duration_Constraint {
  /** unique or primary key constraint on columns "customer_answer_id", "question_id", "created_at" */
  QuestionDurationPkey = 'question_duration_pkey'
}

/** input type for incrementing numeric columns in table "metric.question_duration" */
export type Metric_Question_Duration_Inc_Input = {
  duration_in_ms?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "metric.question_duration" */
export type Metric_Question_Duration_Insert_Input = {
  answer?: InputMaybe<Customer_Answer_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_answer_id?: InputMaybe<Scalars['String']>;
  duration_in_ms?: InputMaybe<Scalars['Int']>;
  question_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Metric_Question_Duration_Max_Fields = {
  __typename?: 'metric_question_duration_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_answer_id?: Maybe<Scalars['String']>;
  duration_in_ms?: Maybe<Scalars['Int']>;
  question_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "metric.question_duration" */
export type Metric_Question_Duration_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  customer_answer_id?: InputMaybe<Order_By>;
  duration_in_ms?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Metric_Question_Duration_Min_Fields = {
  __typename?: 'metric_question_duration_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_answer_id?: Maybe<Scalars['String']>;
  duration_in_ms?: Maybe<Scalars['Int']>;
  question_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "metric.question_duration" */
export type Metric_Question_Duration_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  customer_answer_id?: InputMaybe<Order_By>;
  duration_in_ms?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "metric.question_duration" */
export type Metric_Question_Duration_Mutation_Response = {
  __typename?: 'metric_question_duration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Metric_Question_Duration>;
};

/** on_conflict condition type for table "metric.question_duration" */
export type Metric_Question_Duration_On_Conflict = {
  constraint: Metric_Question_Duration_Constraint;
  update_columns?: Array<Metric_Question_Duration_Update_Column>;
  where?: InputMaybe<Metric_Question_Duration_Bool_Exp>;
};

/** Ordering options when selecting data from "metric.question_duration". */
export type Metric_Question_Duration_Order_By = {
  answer?: InputMaybe<Customer_Answer_Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer_answer_id?: InputMaybe<Order_By>;
  duration_in_ms?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: metric.question_duration */
export type Metric_Question_Duration_Pk_Columns_Input = {
  created_at: Scalars['timestamptz'];
  customer_answer_id: Scalars['String'];
  question_id: Scalars['String'];
};

/** select columns of table "metric.question_duration" */
export enum Metric_Question_Duration_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerAnswerId = 'customer_answer_id',
  /** column name */
  DurationInMs = 'duration_in_ms',
  /** column name */
  QuestionId = 'question_id'
}

/** input type for updating data in table "metric.question_duration" */
export type Metric_Question_Duration_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_answer_id?: InputMaybe<Scalars['String']>;
  duration_in_ms?: InputMaybe<Scalars['Int']>;
  question_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Metric_Question_Duration_Stddev_Fields = {
  __typename?: 'metric_question_duration_stddev_fields';
  duration_in_ms?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "metric.question_duration" */
export type Metric_Question_Duration_Stddev_Order_By = {
  duration_in_ms?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Metric_Question_Duration_Stddev_Pop_Fields = {
  __typename?: 'metric_question_duration_stddev_pop_fields';
  duration_in_ms?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "metric.question_duration" */
export type Metric_Question_Duration_Stddev_Pop_Order_By = {
  duration_in_ms?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Metric_Question_Duration_Stddev_Samp_Fields = {
  __typename?: 'metric_question_duration_stddev_samp_fields';
  duration_in_ms?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "metric.question_duration" */
export type Metric_Question_Duration_Stddev_Samp_Order_By = {
  duration_in_ms?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "metric_question_duration" */
export type Metric_Question_Duration_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Metric_Question_Duration_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Metric_Question_Duration_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_answer_id?: InputMaybe<Scalars['String']>;
  duration_in_ms?: InputMaybe<Scalars['Int']>;
  question_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Metric_Question_Duration_Sum_Fields = {
  __typename?: 'metric_question_duration_sum_fields';
  duration_in_ms?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "metric.question_duration" */
export type Metric_Question_Duration_Sum_Order_By = {
  duration_in_ms?: InputMaybe<Order_By>;
};

/** update columns of table "metric.question_duration" */
export enum Metric_Question_Duration_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerAnswerId = 'customer_answer_id',
  /** column name */
  DurationInMs = 'duration_in_ms',
  /** column name */
  QuestionId = 'question_id'
}

export type Metric_Question_Duration_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Metric_Question_Duration_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Metric_Question_Duration_Set_Input>;
  /** filter the rows which have to be updated */
  where: Metric_Question_Duration_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Metric_Question_Duration_Var_Pop_Fields = {
  __typename?: 'metric_question_duration_var_pop_fields';
  duration_in_ms?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "metric.question_duration" */
export type Metric_Question_Duration_Var_Pop_Order_By = {
  duration_in_ms?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Metric_Question_Duration_Var_Samp_Fields = {
  __typename?: 'metric_question_duration_var_samp_fields';
  duration_in_ms?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "metric.question_duration" */
export type Metric_Question_Duration_Var_Samp_Order_By = {
  duration_in_ms?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Metric_Question_Duration_Variance_Fields = {
  __typename?: 'metric_question_duration_variance_fields';
  duration_in_ms?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "metric.question_duration" */
export type Metric_Question_Duration_Variance_Order_By = {
  duration_in_ms?: InputMaybe<Order_By>;
};

/** columns and relationships of "metric.questionnaires_per_vendor_and_year" */
export type Metric_Questionnaires_Per_Vendor_And_Year = {
  __typename?: 'metric_questionnaires_per_vendor_and_year';
  answer_year?: Maybe<Scalars['float8']>;
  questionnaire_count?: Maybe<Scalars['bigint']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "metric.questionnaires_per_vendor_and_year" */
export type Metric_Questionnaires_Per_Vendor_And_Year_Aggregate = {
  __typename?: 'metric_questionnaires_per_vendor_and_year_aggregate';
  aggregate?: Maybe<Metric_Questionnaires_Per_Vendor_And_Year_Aggregate_Fields>;
  nodes: Array<Metric_Questionnaires_Per_Vendor_And_Year>;
};

/** aggregate fields of "metric.questionnaires_per_vendor_and_year" */
export type Metric_Questionnaires_Per_Vendor_And_Year_Aggregate_Fields = {
  __typename?: 'metric_questionnaires_per_vendor_and_year_aggregate_fields';
  avg?: Maybe<Metric_Questionnaires_Per_Vendor_And_Year_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Metric_Questionnaires_Per_Vendor_And_Year_Max_Fields>;
  min?: Maybe<Metric_Questionnaires_Per_Vendor_And_Year_Min_Fields>;
  stddev?: Maybe<Metric_Questionnaires_Per_Vendor_And_Year_Stddev_Fields>;
  stddev_pop?: Maybe<Metric_Questionnaires_Per_Vendor_And_Year_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Metric_Questionnaires_Per_Vendor_And_Year_Stddev_Samp_Fields>;
  sum?: Maybe<Metric_Questionnaires_Per_Vendor_And_Year_Sum_Fields>;
  var_pop?: Maybe<Metric_Questionnaires_Per_Vendor_And_Year_Var_Pop_Fields>;
  var_samp?: Maybe<Metric_Questionnaires_Per_Vendor_And_Year_Var_Samp_Fields>;
  variance?: Maybe<Metric_Questionnaires_Per_Vendor_And_Year_Variance_Fields>;
};


/** aggregate fields of "metric.questionnaires_per_vendor_and_year" */
export type Metric_Questionnaires_Per_Vendor_And_Year_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Metric_Questionnaires_Per_Vendor_And_Year_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Metric_Questionnaires_Per_Vendor_And_Year_Avg_Fields = {
  __typename?: 'metric_questionnaires_per_vendor_and_year_avg_fields';
  answer_year?: Maybe<Scalars['Float']>;
  questionnaire_count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "metric.questionnaires_per_vendor_and_year". All fields are combined with a logical 'AND'. */
export type Metric_Questionnaires_Per_Vendor_And_Year_Bool_Exp = {
  _and?: InputMaybe<Array<Metric_Questionnaires_Per_Vendor_And_Year_Bool_Exp>>;
  _not?: InputMaybe<Metric_Questionnaires_Per_Vendor_And_Year_Bool_Exp>;
  _or?: InputMaybe<Array<Metric_Questionnaires_Per_Vendor_And_Year_Bool_Exp>>;
  answer_year?: InputMaybe<Float8_Comparison_Exp>;
  questionnaire_count?: InputMaybe<Bigint_Comparison_Exp>;
  vendor_id?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Metric_Questionnaires_Per_Vendor_And_Year_Max_Fields = {
  __typename?: 'metric_questionnaires_per_vendor_and_year_max_fields';
  answer_year?: Maybe<Scalars['float8']>;
  questionnaire_count?: Maybe<Scalars['bigint']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Metric_Questionnaires_Per_Vendor_And_Year_Min_Fields = {
  __typename?: 'metric_questionnaires_per_vendor_and_year_min_fields';
  answer_year?: Maybe<Scalars['float8']>;
  questionnaire_count?: Maybe<Scalars['bigint']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "metric.questionnaires_per_vendor_and_year". */
export type Metric_Questionnaires_Per_Vendor_And_Year_Order_By = {
  answer_year?: InputMaybe<Order_By>;
  questionnaire_count?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** select columns of table "metric.questionnaires_per_vendor_and_year" */
export enum Metric_Questionnaires_Per_Vendor_And_Year_Select_Column {
  /** column name */
  AnswerYear = 'answer_year',
  /** column name */
  QuestionnaireCount = 'questionnaire_count',
  /** column name */
  VendorId = 'vendor_id'
}

/** aggregate stddev on columns */
export type Metric_Questionnaires_Per_Vendor_And_Year_Stddev_Fields = {
  __typename?: 'metric_questionnaires_per_vendor_and_year_stddev_fields';
  answer_year?: Maybe<Scalars['Float']>;
  questionnaire_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Metric_Questionnaires_Per_Vendor_And_Year_Stddev_Pop_Fields = {
  __typename?: 'metric_questionnaires_per_vendor_and_year_stddev_pop_fields';
  answer_year?: Maybe<Scalars['Float']>;
  questionnaire_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Metric_Questionnaires_Per_Vendor_And_Year_Stddev_Samp_Fields = {
  __typename?: 'metric_questionnaires_per_vendor_and_year_stddev_samp_fields';
  answer_year?: Maybe<Scalars['Float']>;
  questionnaire_count?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "metric_questionnaires_per_vendor_and_year" */
export type Metric_Questionnaires_Per_Vendor_And_Year_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Metric_Questionnaires_Per_Vendor_And_Year_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Metric_Questionnaires_Per_Vendor_And_Year_Stream_Cursor_Value_Input = {
  answer_year?: InputMaybe<Scalars['float8']>;
  questionnaire_count?: InputMaybe<Scalars['bigint']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Metric_Questionnaires_Per_Vendor_And_Year_Sum_Fields = {
  __typename?: 'metric_questionnaires_per_vendor_and_year_sum_fields';
  answer_year?: Maybe<Scalars['float8']>;
  questionnaire_count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Metric_Questionnaires_Per_Vendor_And_Year_Var_Pop_Fields = {
  __typename?: 'metric_questionnaires_per_vendor_and_year_var_pop_fields';
  answer_year?: Maybe<Scalars['Float']>;
  questionnaire_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Metric_Questionnaires_Per_Vendor_And_Year_Var_Samp_Fields = {
  __typename?: 'metric_questionnaires_per_vendor_and_year_var_samp_fields';
  answer_year?: Maybe<Scalars['Float']>;
  questionnaire_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Metric_Questionnaires_Per_Vendor_And_Year_Variance_Fields = {
  __typename?: 'metric_questionnaires_per_vendor_and_year_variance_fields';
  answer_year?: Maybe<Scalars['Float']>;
  questionnaire_count?: Maybe<Scalars['Float']>;
};

/** In this table the device information as well as the usage information for the users are stored */
export type Metric_Usage_Information = {
  __typename?: 'metric_usage_information';
  app_version: Scalars['String'];
  connection_type?: Maybe<Scalars['String']>;
  device_id: Scalars['String'];
  device_model: Scalars['String'];
  device_name: Scalars['String'];
  first_seen: Scalars['timestamptz'];
  last_seen: Scalars['timestamptz'];
  machine?: Maybe<Scalars['String']>;
  node_name?: Maybe<Scalars['String']>;
  operating_system?: Maybe<Scalars['String']>;
  sysname?: Maybe<Scalars['String']>;
  system_release: Scalars['String'];
  system_version: Scalars['String'];
  /** An object relationship */
  user?: Maybe<User>;
  user_id: Scalars['uuid'];
  vendor_id: Scalars['String'];
};

/** aggregated selection of "metric.usage_information" */
export type Metric_Usage_Information_Aggregate = {
  __typename?: 'metric_usage_information_aggregate';
  aggregate?: Maybe<Metric_Usage_Information_Aggregate_Fields>;
  nodes: Array<Metric_Usage_Information>;
};

/** aggregate fields of "metric.usage_information" */
export type Metric_Usage_Information_Aggregate_Fields = {
  __typename?: 'metric_usage_information_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Metric_Usage_Information_Max_Fields>;
  min?: Maybe<Metric_Usage_Information_Min_Fields>;
};


/** aggregate fields of "metric.usage_information" */
export type Metric_Usage_Information_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Metric_Usage_Information_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "metric.usage_information". All fields are combined with a logical 'AND'. */
export type Metric_Usage_Information_Bool_Exp = {
  _and?: InputMaybe<Array<Metric_Usage_Information_Bool_Exp>>;
  _not?: InputMaybe<Metric_Usage_Information_Bool_Exp>;
  _or?: InputMaybe<Array<Metric_Usage_Information_Bool_Exp>>;
  app_version?: InputMaybe<String_Comparison_Exp>;
  connection_type?: InputMaybe<String_Comparison_Exp>;
  device_id?: InputMaybe<String_Comparison_Exp>;
  device_model?: InputMaybe<String_Comparison_Exp>;
  device_name?: InputMaybe<String_Comparison_Exp>;
  first_seen?: InputMaybe<Timestamptz_Comparison_Exp>;
  last_seen?: InputMaybe<Timestamptz_Comparison_Exp>;
  machine?: InputMaybe<String_Comparison_Exp>;
  node_name?: InputMaybe<String_Comparison_Exp>;
  operating_system?: InputMaybe<String_Comparison_Exp>;
  sysname?: InputMaybe<String_Comparison_Exp>;
  system_release?: InputMaybe<String_Comparison_Exp>;
  system_version?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  vendor_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "metric.usage_information" */
export enum Metric_Usage_Information_Constraint {
  /** unique or primary key constraint on columns "vendor_id", "user_id", "device_id" */
  UsageInformationPkey = 'usage_information_pkey'
}

/** input type for inserting data into table "metric.usage_information" */
export type Metric_Usage_Information_Insert_Input = {
  app_version?: InputMaybe<Scalars['String']>;
  connection_type?: InputMaybe<Scalars['String']>;
  device_id?: InputMaybe<Scalars['String']>;
  device_model?: InputMaybe<Scalars['String']>;
  device_name?: InputMaybe<Scalars['String']>;
  first_seen?: InputMaybe<Scalars['timestamptz']>;
  last_seen?: InputMaybe<Scalars['timestamptz']>;
  machine?: InputMaybe<Scalars['String']>;
  node_name?: InputMaybe<Scalars['String']>;
  operating_system?: InputMaybe<Scalars['String']>;
  sysname?: InputMaybe<Scalars['String']>;
  system_release?: InputMaybe<Scalars['String']>;
  system_version?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Metric_Usage_Information_Max_Fields = {
  __typename?: 'metric_usage_information_max_fields';
  app_version?: Maybe<Scalars['String']>;
  connection_type?: Maybe<Scalars['String']>;
  device_id?: Maybe<Scalars['String']>;
  device_model?: Maybe<Scalars['String']>;
  device_name?: Maybe<Scalars['String']>;
  first_seen?: Maybe<Scalars['timestamptz']>;
  last_seen?: Maybe<Scalars['timestamptz']>;
  machine?: Maybe<Scalars['String']>;
  node_name?: Maybe<Scalars['String']>;
  operating_system?: Maybe<Scalars['String']>;
  sysname?: Maybe<Scalars['String']>;
  system_release?: Maybe<Scalars['String']>;
  system_version?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Metric_Usage_Information_Min_Fields = {
  __typename?: 'metric_usage_information_min_fields';
  app_version?: Maybe<Scalars['String']>;
  connection_type?: Maybe<Scalars['String']>;
  device_id?: Maybe<Scalars['String']>;
  device_model?: Maybe<Scalars['String']>;
  device_name?: Maybe<Scalars['String']>;
  first_seen?: Maybe<Scalars['timestamptz']>;
  last_seen?: Maybe<Scalars['timestamptz']>;
  machine?: Maybe<Scalars['String']>;
  node_name?: Maybe<Scalars['String']>;
  operating_system?: Maybe<Scalars['String']>;
  sysname?: Maybe<Scalars['String']>;
  system_release?: Maybe<Scalars['String']>;
  system_version?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "metric.usage_information" */
export type Metric_Usage_Information_Mutation_Response = {
  __typename?: 'metric_usage_information_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Metric_Usage_Information>;
};

/** on_conflict condition type for table "metric.usage_information" */
export type Metric_Usage_Information_On_Conflict = {
  constraint: Metric_Usage_Information_Constraint;
  update_columns?: Array<Metric_Usage_Information_Update_Column>;
  where?: InputMaybe<Metric_Usage_Information_Bool_Exp>;
};

/** Ordering options when selecting data from "metric.usage_information". */
export type Metric_Usage_Information_Order_By = {
  app_version?: InputMaybe<Order_By>;
  connection_type?: InputMaybe<Order_By>;
  device_id?: InputMaybe<Order_By>;
  device_model?: InputMaybe<Order_By>;
  device_name?: InputMaybe<Order_By>;
  first_seen?: InputMaybe<Order_By>;
  last_seen?: InputMaybe<Order_By>;
  machine?: InputMaybe<Order_By>;
  node_name?: InputMaybe<Order_By>;
  operating_system?: InputMaybe<Order_By>;
  sysname?: InputMaybe<Order_By>;
  system_release?: InputMaybe<Order_By>;
  system_version?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: metric.usage_information */
export type Metric_Usage_Information_Pk_Columns_Input = {
  device_id: Scalars['String'];
  user_id: Scalars['uuid'];
  vendor_id: Scalars['String'];
};

/** select columns of table "metric.usage_information" */
export enum Metric_Usage_Information_Select_Column {
  /** column name */
  AppVersion = 'app_version',
  /** column name */
  ConnectionType = 'connection_type',
  /** column name */
  DeviceId = 'device_id',
  /** column name */
  DeviceModel = 'device_model',
  /** column name */
  DeviceName = 'device_name',
  /** column name */
  FirstSeen = 'first_seen',
  /** column name */
  LastSeen = 'last_seen',
  /** column name */
  Machine = 'machine',
  /** column name */
  NodeName = 'node_name',
  /** column name */
  OperatingSystem = 'operating_system',
  /** column name */
  Sysname = 'sysname',
  /** column name */
  SystemRelease = 'system_release',
  /** column name */
  SystemVersion = 'system_version',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VendorId = 'vendor_id'
}

/** input type for updating data in table "metric.usage_information" */
export type Metric_Usage_Information_Set_Input = {
  app_version?: InputMaybe<Scalars['String']>;
  connection_type?: InputMaybe<Scalars['String']>;
  device_id?: InputMaybe<Scalars['String']>;
  device_model?: InputMaybe<Scalars['String']>;
  device_name?: InputMaybe<Scalars['String']>;
  first_seen?: InputMaybe<Scalars['timestamptz']>;
  last_seen?: InputMaybe<Scalars['timestamptz']>;
  machine?: InputMaybe<Scalars['String']>;
  node_name?: InputMaybe<Scalars['String']>;
  operating_system?: InputMaybe<Scalars['String']>;
  sysname?: InputMaybe<Scalars['String']>;
  system_release?: InputMaybe<Scalars['String']>;
  system_version?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "metric_usage_information" */
export type Metric_Usage_Information_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Metric_Usage_Information_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Metric_Usage_Information_Stream_Cursor_Value_Input = {
  app_version?: InputMaybe<Scalars['String']>;
  connection_type?: InputMaybe<Scalars['String']>;
  device_id?: InputMaybe<Scalars['String']>;
  device_model?: InputMaybe<Scalars['String']>;
  device_name?: InputMaybe<Scalars['String']>;
  first_seen?: InputMaybe<Scalars['timestamptz']>;
  last_seen?: InputMaybe<Scalars['timestamptz']>;
  machine?: InputMaybe<Scalars['String']>;
  node_name?: InputMaybe<Scalars['String']>;
  operating_system?: InputMaybe<Scalars['String']>;
  sysname?: InputMaybe<Scalars['String']>;
  system_release?: InputMaybe<Scalars['String']>;
  system_version?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "metric.usage_information" */
export enum Metric_Usage_Information_Update_Column {
  /** column name */
  AppVersion = 'app_version',
  /** column name */
  ConnectionType = 'connection_type',
  /** column name */
  DeviceId = 'device_id',
  /** column name */
  DeviceModel = 'device_model',
  /** column name */
  DeviceName = 'device_name',
  /** column name */
  FirstSeen = 'first_seen',
  /** column name */
  LastSeen = 'last_seen',
  /** column name */
  Machine = 'machine',
  /** column name */
  NodeName = 'node_name',
  /** column name */
  OperatingSystem = 'operating_system',
  /** column name */
  Sysname = 'sysname',
  /** column name */
  SystemRelease = 'system_release',
  /** column name */
  SystemVersion = 'system_version',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VendorId = 'vendor_id'
}

export type Metric_Usage_Information_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Metric_Usage_Information_Set_Input>;
  /** filter the rows which have to be updated */
  where: Metric_Usage_Information_Bool_Exp;
};

/** columns and relationships of "metric.user_ranking" */
export type Metric_User_Ranking = {
  __typename?: 'metric_user_ranking';
  points?: Maybe<Scalars['float8']>;
  /** An object relationship */
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['uuid']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "metric.user_ranking" */
export type Metric_User_Ranking_Aggregate = {
  __typename?: 'metric_user_ranking_aggregate';
  aggregate?: Maybe<Metric_User_Ranking_Aggregate_Fields>;
  nodes: Array<Metric_User_Ranking>;
};

/** aggregate fields of "metric.user_ranking" */
export type Metric_User_Ranking_Aggregate_Fields = {
  __typename?: 'metric_user_ranking_aggregate_fields';
  avg?: Maybe<Metric_User_Ranking_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Metric_User_Ranking_Max_Fields>;
  min?: Maybe<Metric_User_Ranking_Min_Fields>;
  stddev?: Maybe<Metric_User_Ranking_Stddev_Fields>;
  stddev_pop?: Maybe<Metric_User_Ranking_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Metric_User_Ranking_Stddev_Samp_Fields>;
  sum?: Maybe<Metric_User_Ranking_Sum_Fields>;
  var_pop?: Maybe<Metric_User_Ranking_Var_Pop_Fields>;
  var_samp?: Maybe<Metric_User_Ranking_Var_Samp_Fields>;
  variance?: Maybe<Metric_User_Ranking_Variance_Fields>;
};


/** aggregate fields of "metric.user_ranking" */
export type Metric_User_Ranking_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Metric_User_Ranking_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Metric_User_Ranking_Avg_Fields = {
  __typename?: 'metric_user_ranking_avg_fields';
  points?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "metric.user_ranking". All fields are combined with a logical 'AND'. */
export type Metric_User_Ranking_Bool_Exp = {
  _and?: InputMaybe<Array<Metric_User_Ranking_Bool_Exp>>;
  _not?: InputMaybe<Metric_User_Ranking_Bool_Exp>;
  _or?: InputMaybe<Array<Metric_User_Ranking_Bool_Exp>>;
  points?: InputMaybe<Float8_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  vendor_id?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Metric_User_Ranking_Max_Fields = {
  __typename?: 'metric_user_ranking_max_fields';
  points?: Maybe<Scalars['float8']>;
  user_id?: Maybe<Scalars['uuid']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Metric_User_Ranking_Min_Fields = {
  __typename?: 'metric_user_ranking_min_fields';
  points?: Maybe<Scalars['float8']>;
  user_id?: Maybe<Scalars['uuid']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "metric.user_ranking". */
export type Metric_User_Ranking_Order_By = {
  points?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** select columns of table "metric.user_ranking" */
export enum Metric_User_Ranking_Select_Column {
  /** column name */
  Points = 'points',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VendorId = 'vendor_id'
}

/** aggregate stddev on columns */
export type Metric_User_Ranking_Stddev_Fields = {
  __typename?: 'metric_user_ranking_stddev_fields';
  points?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Metric_User_Ranking_Stddev_Pop_Fields = {
  __typename?: 'metric_user_ranking_stddev_pop_fields';
  points?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Metric_User_Ranking_Stddev_Samp_Fields = {
  __typename?: 'metric_user_ranking_stddev_samp_fields';
  points?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "metric_user_ranking" */
export type Metric_User_Ranking_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Metric_User_Ranking_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Metric_User_Ranking_Stream_Cursor_Value_Input = {
  points?: InputMaybe<Scalars['float8']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Metric_User_Ranking_Sum_Fields = {
  __typename?: 'metric_user_ranking_sum_fields';
  points?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type Metric_User_Ranking_Var_Pop_Fields = {
  __typename?: 'metric_user_ranking_var_pop_fields';
  points?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Metric_User_Ranking_Var_Samp_Fields = {
  __typename?: 'metric_user_ranking_var_samp_fields';
  points?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Metric_User_Ranking_Variance_Fields = {
  __typename?: 'metric_user_ranking_variance_fields';
  points?: Maybe<Scalars['Float']>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** Insert a new transaction for ordering a benefit */
  addBenefitTransaction?: Maybe<TransactionOutput>;
  /** This action creates a new customer answer and returns the answerId. */
  createAnswer?: Maybe<AnswerResponse>;
  createAnswerPublicUser?: Maybe<AnswerResponse>;
  deleteDataByEmailAddress?: Maybe<DeleteDataByEmailResponse>;
  deleteDataById?: Maybe<DeleteDataByIdResponse>;
  /** delete data from the table: "answer_enums.bicycle_type" */
  delete_answer_enums_bicycle_type?: Maybe<Answer_Enums_Bicycle_Type_Mutation_Response>;
  /** delete single row from the table: "answer_enums.bicycle_type" */
  delete_answer_enums_bicycle_type_by_pk?: Maybe<Answer_Enums_Bicycle_Type>;
  /** delete data from the table: "answer_enums.driving_duration" */
  delete_answer_enums_driving_duration?: Maybe<Answer_Enums_Driving_Duration_Mutation_Response>;
  /** delete single row from the table: "answer_enums.driving_duration" */
  delete_answer_enums_driving_duration_by_pk?: Maybe<Answer_Enums_Driving_Duration>;
  /** delete data from the table: "answer_enums.driving_frequency" */
  delete_answer_enums_driving_frequency?: Maybe<Answer_Enums_Driving_Frequency_Mutation_Response>;
  /** delete single row from the table: "answer_enums.driving_frequency" */
  delete_answer_enums_driving_frequency_by_pk?: Maybe<Answer_Enums_Driving_Frequency>;
  /** delete data from the table: "answer_enums.e_bike" */
  delete_answer_enums_e_bike?: Maybe<Answer_Enums_E_Bike_Mutation_Response>;
  /** delete single row from the table: "answer_enums.e_bike" */
  delete_answer_enums_e_bike_by_pk?: Maybe<Answer_Enums_E_Bike>;
  /** delete data from the table: "answer_enums.foot_position" */
  delete_answer_enums_foot_position?: Maybe<Answer_Enums_Foot_Position_Mutation_Response>;
  /** delete single row from the table: "answer_enums.foot_position" */
  delete_answer_enums_foot_position_by_pk?: Maybe<Answer_Enums_Foot_Position>;
  /** delete data from the table: "answer_enums.foot_problems" */
  delete_answer_enums_foot_problems?: Maybe<Answer_Enums_Foot_Problems_Mutation_Response>;
  /** delete single row from the table: "answer_enums.foot_problems" */
  delete_answer_enums_foot_problems_by_pk?: Maybe<Answer_Enums_Foot_Problems>;
  /** delete data from the table: "answer_enums.foot_type" */
  delete_answer_enums_foot_type?: Maybe<Answer_Enums_Foot_Type_Mutation_Response>;
  /** delete single row from the table: "answer_enums.foot_type" */
  delete_answer_enums_foot_type_by_pk?: Maybe<Answer_Enums_Foot_Type>;
  /** delete data from the table: "answer_enums.gripping_distance" */
  delete_answer_enums_gripping_distance?: Maybe<Answer_Enums_Gripping_Distance_Mutation_Response>;
  /** delete single row from the table: "answer_enums.gripping_distance" */
  delete_answer_enums_gripping_distance_by_pk?: Maybe<Answer_Enums_Gripping_Distance>;
  /** delete data from the table: "answer_enums.hand_problems" */
  delete_answer_enums_hand_problems?: Maybe<Answer_Enums_Hand_Problems_Mutation_Response>;
  /** delete single row from the table: "answer_enums.hand_problems" */
  delete_answer_enums_hand_problems_by_pk?: Maybe<Answer_Enums_Hand_Problems>;
  /** delete data from the table: "answer_enums.leg_axis" */
  delete_answer_enums_leg_axis?: Maybe<Answer_Enums_Leg_Axis_Mutation_Response>;
  /** delete single row from the table: "answer_enums.leg_axis" */
  delete_answer_enums_leg_axis_by_pk?: Maybe<Answer_Enums_Leg_Axis>;
  /** delete data from the table: "answer_enums.pelvic_problems" */
  delete_answer_enums_pelvic_problems?: Maybe<Answer_Enums_Pelvic_Problems_Mutation_Response>;
  /** delete single row from the table: "answer_enums.pelvic_problems" */
  delete_answer_enums_pelvic_problems_by_pk?: Maybe<Answer_Enums_Pelvic_Problems>;
  /** delete data from the table: "answer_enums.seat_position" */
  delete_answer_enums_seat_position?: Maybe<Answer_Enums_Seat_Position_Mutation_Response>;
  /** delete single row from the table: "answer_enums.seat_position" */
  delete_answer_enums_seat_position_by_pk?: Maybe<Answer_Enums_Seat_Position>;
  /** delete data from the table: "answer_enums.sex" */
  delete_answer_enums_sex?: Maybe<Answer_Enums_Sex_Mutation_Response>;
  /** delete single row from the table: "answer_enums.sex" */
  delete_answer_enums_sex_by_pk?: Maybe<Answer_Enums_Sex>;
  /** delete data from the table: "answers" */
  delete_answers?: Maybe<Answers_Mutation_Response>;
  /** delete single row from the table: "answers" */
  delete_answers_by_pk?: Maybe<Answers>;
  /** delete data from the table: "article_group" */
  delete_article_group?: Maybe<Article_Group_Mutation_Response>;
  /** delete single row from the table: "article_group" */
  delete_article_group_by_pk?: Maybe<Article_Group>;
  /** delete data from the table: "article_information_i18n" */
  delete_article_information_i18n?: Maybe<Article_Information_I18n_Mutation_Response>;
  /** delete single row from the table: "article_information_i18n" */
  delete_article_information_i18n_by_pk?: Maybe<Article_Information_I18n>;
  /** delete data from the table: "article_ranking" */
  delete_article_ranking?: Maybe<Article_Ranking_Mutation_Response>;
  /** delete single row from the table: "article_ranking" */
  delete_article_ranking_by_pk?: Maybe<Article_Ranking>;
  /** delete data from the table: "article_type" */
  delete_article_type?: Maybe<Article_Type_Mutation_Response>;
  /** delete single row from the table: "article_type" */
  delete_article_type_by_pk?: Maybe<Article_Type>;
  /** delete data from the table: "auth.one_time_token" */
  delete_auth_one_time_token?: Maybe<Auth_One_Time_Token_Mutation_Response>;
  /** delete single row from the table: "auth.one_time_token" */
  delete_auth_one_time_token_by_pk?: Maybe<Auth_One_Time_Token>;
  /** delete data from the table: "bikefitting.bikefitting_session" */
  delete_bikefitting_bikefitting_session?: Maybe<Bikefitting_Bikefitting_Session_Mutation_Response>;
  /** delete single row from the table: "bikefitting.bikefitting_session" */
  delete_bikefitting_bikefitting_session_by_pk?: Maybe<Bikefitting_Bikefitting_Session>;
  /** delete data from the table: "commission.account" */
  delete_commission_account?: Maybe<Commission_Account_Mutation_Response>;
  /** delete single row from the table: "commission.account" */
  delete_commission_account_by_pk?: Maybe<Commission_Account>;
  /** delete data from the table: "commission.boost" */
  delete_commission_boost?: Maybe<Commission_Boost_Mutation_Response>;
  /** delete single row from the table: "commission.boost" */
  delete_commission_boost_by_pk?: Maybe<Commission_Boost>;
  /** delete data from the table: "commission.payout_log" */
  delete_commission_payout_log?: Maybe<Commission_Payout_Log_Mutation_Response>;
  /** delete single row from the table: "commission.payout_log" */
  delete_commission_payout_log_by_pk?: Maybe<Commission_Payout_Log>;
  /** delete data from the table: "commission.type" */
  delete_commission_type?: Maybe<Commission_Type_Mutation_Response>;
  /** delete single row from the table: "commission.type" */
  delete_commission_type_by_pk?: Maybe<Commission_Type>;
  /** delete data from the table: "commission.webshop_data" */
  delete_commission_webshop_data?: Maybe<Commission_Webshop_Data_Mutation_Response>;
  /** delete single row from the table: "commission.webshop_data" */
  delete_commission_webshop_data_by_pk?: Maybe<Commission_Webshop_Data>;
  /** delete data from the table: "content.chapters" */
  delete_content_chapters?: Maybe<Content_Chapters_Mutation_Response>;
  /** delete single row from the table: "content.chapters" */
  delete_content_chapters_by_pk?: Maybe<Content_Chapters>;
  /** delete data from the table: "content.choices" */
  delete_content_choices?: Maybe<Content_Choices_Mutation_Response>;
  /** delete single row from the table: "content.choices" */
  delete_content_choices_by_pk?: Maybe<Content_Choices>;
  /** delete data from the table: "content.contactpoints" */
  delete_content_contactpoints?: Maybe<Content_Contactpoints_Mutation_Response>;
  /** delete single row from the table: "content.contactpoints" */
  delete_content_contactpoints_by_pk?: Maybe<Content_Contactpoints>;
  /** delete data from the table: "content.lection_requirements" */
  delete_content_lection_requirements?: Maybe<Content_Lection_Requirements_Mutation_Response>;
  /** delete single row from the table: "content.lection_requirements" */
  delete_content_lection_requirements_by_pk?: Maybe<Content_Lection_Requirements>;
  /** delete data from the table: "content.lections" */
  delete_content_lections?: Maybe<Content_Lections_Mutation_Response>;
  /** delete single row from the table: "content.lections" */
  delete_content_lections_by_pk?: Maybe<Content_Lections>;
  /** delete data from the table: "content.levels" */
  delete_content_levels?: Maybe<Content_Levels_Mutation_Response>;
  /** delete single row from the table: "content.levels" */
  delete_content_levels_by_pk?: Maybe<Content_Levels>;
  /** delete data from the table: "content.questions" */
  delete_content_questions?: Maybe<Content_Questions_Mutation_Response>;
  /** delete single row from the table: "content.questions" */
  delete_content_questions_by_pk?: Maybe<Content_Questions>;
  /** delete data from the table: "content.quiz_type" */
  delete_content_quiz_type?: Maybe<Content_Quiz_Type_Mutation_Response>;
  /** delete single row from the table: "content.quiz_type" */
  delete_content_quiz_type_by_pk?: Maybe<Content_Quiz_Type>;
  /** delete data from the table: "content.quizzes" */
  delete_content_quizzes?: Maybe<Content_Quizzes_Mutation_Response>;
  /** delete single row from the table: "content.quizzes" */
  delete_content_quizzes_by_pk?: Maybe<Content_Quizzes>;
  /** delete data from the table: "country_code" */
  delete_country_code?: Maybe<Country_Code_Mutation_Response>;
  /** delete single row from the table: "country_code" */
  delete_country_code_by_pk?: Maybe<Country_Code>;
  /** delete data from the table: "currency" */
  delete_currency?: Maybe<Currency_Mutation_Response>;
  /** delete single row from the table: "currency" */
  delete_currency_by_pk?: Maybe<Currency>;
  /** delete data from the table: "current_user" */
  delete_current_user?: Maybe<Current_User_Mutation_Response>;
  /** delete data from the table: "customer.answer" */
  delete_customer_answer?: Maybe<Customer_Answer_Mutation_Response>;
  /** delete data from the table: "customer.answer_article_event" */
  delete_customer_answer_article_event?: Maybe<Customer_Answer_Article_Event_Mutation_Response>;
  /** delete single row from the table: "customer.answer_article_event" */
  delete_customer_answer_article_event_by_pk?: Maybe<Customer_Answer_Article_Event>;
  /** delete single row from the table: "customer.answer" */
  delete_customer_answer_by_pk?: Maybe<Customer_Answer>;
  /** delete data from the table: "customer.answer_foot_problems" */
  delete_customer_answer_foot_problems?: Maybe<Customer_Answer_Foot_Problems_Mutation_Response>;
  /** delete single row from the table: "customer.answer_foot_problems" */
  delete_customer_answer_foot_problems_by_pk?: Maybe<Customer_Answer_Foot_Problems>;
  /** delete data from the table: "customer.answer_hand_problems" */
  delete_customer_answer_hand_problems?: Maybe<Customer_Answer_Hand_Problems_Mutation_Response>;
  /** delete single row from the table: "customer.answer_hand_problems" */
  delete_customer_answer_hand_problems_by_pk?: Maybe<Customer_Answer_Hand_Problems>;
  /** delete data from the table: "customer.answer_pelvic_problems" */
  delete_customer_answer_pelvic_problems?: Maybe<Customer_Answer_Pelvic_Problems_Mutation_Response>;
  /** delete single row from the table: "customer.answer_pelvic_problems" */
  delete_customer_answer_pelvic_problems_by_pk?: Maybe<Customer_Answer_Pelvic_Problems>;
  /** delete data from the table: "customer.answer_results" */
  delete_customer_answer_results?: Maybe<Customer_Answer_Results_Mutation_Response>;
  /** delete single row from the table: "customer.answer_results" */
  delete_customer_answer_results_by_pk?: Maybe<Customer_Answer_Results>;
  /** delete data from the table: "customer_dashboard.event" */
  delete_customer_dashboard_event?: Maybe<Customer_Dashboard_Event_Mutation_Response>;
  /** delete single row from the table: "customer_dashboard.event" */
  delete_customer_dashboard_event_by_pk?: Maybe<Customer_Dashboard_Event>;
  /** delete data from the table: "customer_dashboard.event_kind" */
  delete_customer_dashboard_event_kind?: Maybe<Customer_Dashboard_Event_Kind_Mutation_Response>;
  /** delete single row from the table: "customer_dashboard.event_kind" */
  delete_customer_dashboard_event_kind_by_pk?: Maybe<Customer_Dashboard_Event_Kind>;
  /** delete data from the table: "customer.deleted_answers" */
  delete_customer_deleted_answers?: Maybe<Customer_Deleted_Answers_Mutation_Response>;
  /** delete single row from the table: "customer.deleted_answers" */
  delete_customer_deleted_answers_by_pk?: Maybe<Customer_Deleted_Answers>;
  /** delete data from the table: "customer.emails" */
  delete_customer_emails?: Maybe<Customer_Emails_Mutation_Response>;
  /** delete single row from the table: "customer.emails" */
  delete_customer_emails_by_pk?: Maybe<Customer_Emails>;
  /** delete data from the table: "customer.registration" */
  delete_customer_registration?: Maybe<Customer_Registration_Mutation_Response>;
  /** delete single row from the table: "customer.registration" */
  delete_customer_registration_by_pk?: Maybe<Customer_Registration>;
  /** delete data from the table: "customer.signature" */
  delete_customer_signature?: Maybe<Customer_Signature_Mutation_Response>;
  /** delete single row from the table: "customer.signature" */
  delete_customer_signature_by_pk?: Maybe<Customer_Signature>;
  /** delete data from the table: "customer.voucher" */
  delete_customer_voucher?: Maybe<Customer_Voucher_Mutation_Response>;
  /** delete single row from the table: "customer.voucher" */
  delete_customer_voucher_by_pk?: Maybe<Customer_Voucher>;
  /** delete data from the table: "customer.web_questionnaire_completion" */
  delete_customer_web_questionnaire_completion?: Maybe<Customer_Web_Questionnaire_Completion_Mutation_Response>;
  /** delete single row from the table: "customer.web_questionnaire_completion" */
  delete_customer_web_questionnaire_completion_by_pk?: Maybe<Customer_Web_Questionnaire_Completion>;
  /** delete data from the table: "cv.body" */
  delete_cv_body?: Maybe<Cv_Body_Mutation_Response>;
  /** delete single row from the table: "cv.body" */
  delete_cv_body_by_pk?: Maybe<Cv_Body>;
  /** delete data from the table: "cv.handmarks" */
  delete_cv_handmarks?: Maybe<Cv_Handmarks_Mutation_Response>;
  /** delete single row from the table: "cv.handmarks" */
  delete_cv_handmarks_by_pk?: Maybe<Cv_Handmarks>;
  /** delete data from the table: "cv.sitbones" */
  delete_cv_sitbones?: Maybe<Cv_Sitbones_Mutation_Response>;
  /** delete single row from the table: "cv.sitbones" */
  delete_cv_sitbones_by_pk?: Maybe<Cv_Sitbones>;
  /** delete data from the table: "distributor" */
  delete_distributor?: Maybe<Distributor_Mutation_Response>;
  /** delete single row from the table: "distributor" */
  delete_distributor_by_pk?: Maybe<Distributor>;
  /** delete data from the table: "distributors" */
  delete_distributors?: Maybe<Distributors_Mutation_Response>;
  /** delete single row from the table: "distributors" */
  delete_distributors_by_pk?: Maybe<Distributors>;
  /** delete data from the table: "event_enums.article_event" */
  delete_event_enums_article_event?: Maybe<Event_Enums_Article_Event_Mutation_Response>;
  /** delete single row from the table: "event_enums.article_event" */
  delete_event_enums_article_event_by_pk?: Maybe<Event_Enums_Article_Event>;
  /** delete data from the table: "event_enums.event_source" */
  delete_event_enums_event_source?: Maybe<Event_Enums_Event_Source_Mutation_Response>;
  /** delete single row from the table: "event_enums.event_source" */
  delete_event_enums_event_source_by_pk?: Maybe<Event_Enums_Event_Source>;
  /** delete data from the table: "event_enums.log_level" */
  delete_event_enums_log_level?: Maybe<Event_Enums_Log_Level_Mutation_Response>;
  /** delete single row from the table: "event_enums.log_level" */
  delete_event_enums_log_level_by_pk?: Maybe<Event_Enums_Log_Level>;
  /** delete data from the table: "event_log.event_types" */
  delete_event_log_event_types?: Maybe<Event_Log_Event_Types_Mutation_Response>;
  /** delete single row from the table: "event_log.event_types" */
  delete_event_log_event_types_by_pk?: Maybe<Event_Log_Event_Types>;
  /** delete data from the table: "event_log.events" */
  delete_event_log_events?: Maybe<Event_Log_Events_Mutation_Response>;
  /** delete single row from the table: "event_log.events" */
  delete_event_log_events_by_pk?: Maybe<Event_Log_Events>;
  /** delete data from the table: "frontend.vendor_support" */
  delete_frontend_vendor_support?: Maybe<Frontend_Vendor_Support_Mutation_Response>;
  /** delete single row from the table: "frontend.vendor_support" */
  delete_frontend_vendor_support_by_pk?: Maybe<Frontend_Vendor_Support>;
  /** delete data from the table: "iframe.feedback" */
  delete_iframe_feedback?: Maybe<Iframe_Feedback_Mutation_Response>;
  /** delete single row from the table: "iframe.feedback" */
  delete_iframe_feedback_by_pk?: Maybe<Iframe_Feedback>;
  /** delete data from the table: "iframe.newsletter_subscriptions" */
  delete_iframe_newsletter_subscriptions?: Maybe<Iframe_Newsletter_Subscriptions_Mutation_Response>;
  /** delete single row from the table: "iframe.newsletter_subscriptions" */
  delete_iframe_newsletter_subscriptions_by_pk?: Maybe<Iframe_Newsletter_Subscriptions>;
  /** delete data from the table: "iframe.rating" */
  delete_iframe_rating?: Maybe<Iframe_Rating_Mutation_Response>;
  /** delete single row from the table: "iframe.rating" */
  delete_iframe_rating_by_pk?: Maybe<Iframe_Rating>;
  /** delete data from the table: "iqlab_rewards.types" */
  delete_iqlab_rewards_types?: Maybe<Iqlab_Rewards_Types_Mutation_Response>;
  /** delete single row from the table: "iqlab_rewards.types" */
  delete_iqlab_rewards_types_by_pk?: Maybe<Iqlab_Rewards_Types>;
  /** delete data from the table: "iqlab_rewards.vouchers" */
  delete_iqlab_rewards_vouchers?: Maybe<Iqlab_Rewards_Vouchers_Mutation_Response>;
  /** delete single row from the table: "iqlab_rewards.vouchers" */
  delete_iqlab_rewards_vouchers_by_pk?: Maybe<Iqlab_Rewards_Vouchers>;
  /** delete data from the table: "measurement.measure" */
  delete_measurement_measure?: Maybe<Measurement_Measure_Mutation_Response>;
  /** delete single row from the table: "measurement.measure" */
  delete_measurement_measure_by_pk?: Maybe<Measurement_Measure>;
  /** delete data from the table: "measurement.measure_type" */
  delete_measurement_measure_type?: Maybe<Measurement_Measure_Type_Mutation_Response>;
  /** delete single row from the table: "measurement.measure_type" */
  delete_measurement_measure_type_by_pk?: Maybe<Measurement_Measure_Type>;
  /** delete data from the table: "measurement.session_source" */
  delete_measurement_session_source?: Maybe<Measurement_Session_Source_Mutation_Response>;
  /** delete single row from the table: "measurement.session_source" */
  delete_measurement_session_source_by_pk?: Maybe<Measurement_Session_Source>;
  /** delete data from the table: "measurement.session_type" */
  delete_measurement_session_type?: Maybe<Measurement_Session_Type_Mutation_Response>;
  /** delete single row from the table: "measurement.session_type" */
  delete_measurement_session_type_by_pk?: Maybe<Measurement_Session_Type>;
  /** delete data from the table: "measurement.sessions" */
  delete_measurement_sessions?: Maybe<Measurement_Sessions_Mutation_Response>;
  /** delete single row from the table: "measurement.sessions" */
  delete_measurement_sessions_by_pk?: Maybe<Measurement_Sessions>;
  /** delete data from the table: "metric.answer_location" */
  delete_metric_answer_location?: Maybe<Metric_Answer_Location_Mutation_Response>;
  /** delete single row from the table: "metric.answer_location" */
  delete_metric_answer_location_by_pk?: Maybe<Metric_Answer_Location>;
  /** delete data from the table: "metric.app_events" */
  delete_metric_app_events?: Maybe<Metric_App_Events_Mutation_Response>;
  /** delete single row from the table: "metric.app_events" */
  delete_metric_app_events_by_pk?: Maybe<Metric_App_Events>;
  /** delete data from the table: "metric.question_duration" */
  delete_metric_question_duration?: Maybe<Metric_Question_Duration_Mutation_Response>;
  /** delete single row from the table: "metric.question_duration" */
  delete_metric_question_duration_by_pk?: Maybe<Metric_Question_Duration>;
  /** delete data from the table: "metric.usage_information" */
  delete_metric_usage_information?: Maybe<Metric_Usage_Information_Mutation_Response>;
  /** delete single row from the table: "metric.usage_information" */
  delete_metric_usage_information_by_pk?: Maybe<Metric_Usage_Information>;
  /** delete data from the table: "onboarding.emails" */
  delete_onboarding_emails?: Maybe<Onboarding_Emails_Mutation_Response>;
  /** delete single row from the table: "onboarding.emails" */
  delete_onboarding_emails_by_pk?: Maybe<Onboarding_Emails>;
  /** delete data from the table: "organisation" */
  delete_organisation?: Maybe<Organisation_Mutation_Response>;
  /** delete single row from the table: "organisation" */
  delete_organisation_by_pk?: Maybe<Organisation>;
  /** delete data from the table: "preferences.user_preferences" */
  delete_preferences_user_preferences?: Maybe<Preferences_User_Preferences_Mutation_Response>;
  /** delete single row from the table: "preferences.user_preferences" */
  delete_preferences_user_preferences_by_pk?: Maybe<Preferences_User_Preferences>;
  /** delete data from the table: "preferences.vendor_preferences" */
  delete_preferences_vendor_preferences?: Maybe<Preferences_Vendor_Preferences_Mutation_Response>;
  /** delete single row from the table: "preferences.vendor_preferences" */
  delete_preferences_vendor_preferences_by_pk?: Maybe<Preferences_Vendor_Preferences>;
  /** delete data from the table: "progress.completed_chapters" */
  delete_progress_completed_chapters?: Maybe<Progress_Completed_Chapters_Mutation_Response>;
  /** delete single row from the table: "progress.completed_chapters" */
  delete_progress_completed_chapters_by_pk?: Maybe<Progress_Completed_Chapters>;
  /** delete data from the table: "progress.completed_contactpoints" */
  delete_progress_completed_contactpoints?: Maybe<Progress_Completed_Contactpoints_Mutation_Response>;
  /** delete single row from the table: "progress.completed_contactpoints" */
  delete_progress_completed_contactpoints_by_pk?: Maybe<Progress_Completed_Contactpoints>;
  /** delete data from the table: "progress.completed_lections" */
  delete_progress_completed_lections?: Maybe<Progress_Completed_Lections_Mutation_Response>;
  /** delete single row from the table: "progress.completed_lections" */
  delete_progress_completed_lections_by_pk?: Maybe<Progress_Completed_Lections>;
  /** delete data from the table: "recommendation.own_articles" */
  delete_recommendation_own_articles?: Maybe<Recommendation_Own_Articles_Mutation_Response>;
  /** delete single row from the table: "recommendation.own_articles" */
  delete_recommendation_own_articles_by_pk?: Maybe<Recommendation_Own_Articles>;
  /** delete data from the table: "recommendation.return_search_article" */
  delete_recommendation_return_search_article?: Maybe<Recommendation_Return_Search_Article_Mutation_Response>;
  /** delete single row from the table: "recommendation.return_search_article" */
  delete_recommendation_return_search_article_by_pk?: Maybe<Recommendation_Return_Search_Article>;
  /** delete data from the table: "rewards.benefit_category" */
  delete_rewards_benefit_category?: Maybe<Rewards_Benefit_Category_Mutation_Response>;
  /** delete single row from the table: "rewards.benefit_category" */
  delete_rewards_benefit_category_by_pk?: Maybe<Rewards_Benefit_Category>;
  /** delete data from the table: "rewards.benefits" */
  delete_rewards_benefits?: Maybe<Rewards_Benefits_Mutation_Response>;
  /** delete single row from the table: "rewards.benefits" */
  delete_rewards_benefits_by_pk?: Maybe<Rewards_Benefits>;
  /** delete data from the table: "rewards.boosts" */
  delete_rewards_boosts?: Maybe<Rewards_Boosts_Mutation_Response>;
  /** delete single row from the table: "rewards.boosts" */
  delete_rewards_boosts_by_pk?: Maybe<Rewards_Boosts>;
  /** delete data from the table: "rewards.transactions" */
  delete_rewards_transactions?: Maybe<Rewards_Transactions_Mutation_Response>;
  /** delete single row from the table: "rewards.transactions" */
  delete_rewards_transactions_by_pk?: Maybe<Rewards_Transactions>;
  /** delete data from the table: "sessions" */
  delete_sessions?: Maybe<Sessions_Mutation_Response>;
  /** delete single row from the table: "sessions" */
  delete_sessions_by_pk?: Maybe<Sessions>;
  /** delete data from the table: "sqbase_event_log.event_types" */
  delete_sqbase_event_log_event_types?: Maybe<Sqbase_Event_Log_Event_Types_Mutation_Response>;
  /** delete single row from the table: "sqbase_event_log.event_types" */
  delete_sqbase_event_log_event_types_by_pk?: Maybe<Sqbase_Event_Log_Event_Types>;
  /** delete data from the table: "sqbase_event_log.events" */
  delete_sqbase_event_log_events?: Maybe<Sqbase_Event_Log_Events_Mutation_Response>;
  /** delete single row from the table: "sqbase_event_log.events" */
  delete_sqbase_event_log_events_by_pk?: Maybe<Sqbase_Event_Log_Events>;
  /** delete data from the table: "sqlab_contacts" */
  delete_sqlab_contacts?: Maybe<Sqlab_Contacts_Mutation_Response>;
  /** delete single row from the table: "sqlab_contacts" */
  delete_sqlab_contacts_by_pk?: Maybe<Sqlab_Contacts>;
  /** delete data from the table: "support.interactions" */
  delete_support_interactions?: Maybe<Support_Interactions_Mutation_Response>;
  /** delete single row from the table: "support.interactions" */
  delete_support_interactions_by_pk?: Maybe<Support_Interactions>;
  /** delete data from the table: "support.registrations_without_answers" */
  delete_support_registrations_without_answers?: Maybe<Support_Registrations_Without_Answers_Mutation_Response>;
  /** delete data from the table: "support.vendor_support" */
  delete_support_vendor_support?: Maybe<Support_Vendor_Support_Mutation_Response>;
  /** delete single row from the table: "support.vendor_support" */
  delete_support_vendor_support_by_pk?: Maybe<Support_Vendor_Support>;
  /** delete data from the table: "support.vendor_support_type" */
  delete_support_vendor_support_type?: Maybe<Support_Vendor_Support_Type_Mutation_Response>;
  /** delete single row from the table: "support.vendor_support_type" */
  delete_support_vendor_support_type_by_pk?: Maybe<Support_Vendor_Support_Type>;
  /** delete data from the table: "tracker.article_prices" */
  delete_tracker_article_prices?: Maybe<Tracker_Article_Prices_Mutation_Response>;
  /** delete single row from the table: "tracker.article_prices" */
  delete_tracker_article_prices_by_pk?: Maybe<Tracker_Article_Prices>;
  /** delete data from the table: "user" */
  delete_user?: Maybe<User_Mutation_Response>;
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<User>;
  /** delete data from the table: "user_details" */
  delete_user_details?: Maybe<User_Details_Mutation_Response>;
  /** delete single row from the table: "user_details" */
  delete_user_details_by_pk?: Maybe<User_Details>;
  evaluateQuiz?: Maybe<QuizResult>;
  evaluateResponse?: Maybe<EvaluateResponse>;
  generateOneTimePassword?: Maybe<OneTimePasswordResponse>;
  /** Inserts new vendor registration */
  insertRegistration?: Maybe<InsertRegistrationOutput>;
  /** insert data into the table: "answer_enums.bicycle_type" */
  insert_answer_enums_bicycle_type?: Maybe<Answer_Enums_Bicycle_Type_Mutation_Response>;
  /** insert a single row into the table: "answer_enums.bicycle_type" */
  insert_answer_enums_bicycle_type_one?: Maybe<Answer_Enums_Bicycle_Type>;
  /** insert data into the table: "answer_enums.driving_duration" */
  insert_answer_enums_driving_duration?: Maybe<Answer_Enums_Driving_Duration_Mutation_Response>;
  /** insert a single row into the table: "answer_enums.driving_duration" */
  insert_answer_enums_driving_duration_one?: Maybe<Answer_Enums_Driving_Duration>;
  /** insert data into the table: "answer_enums.driving_frequency" */
  insert_answer_enums_driving_frequency?: Maybe<Answer_Enums_Driving_Frequency_Mutation_Response>;
  /** insert a single row into the table: "answer_enums.driving_frequency" */
  insert_answer_enums_driving_frequency_one?: Maybe<Answer_Enums_Driving_Frequency>;
  /** insert data into the table: "answer_enums.e_bike" */
  insert_answer_enums_e_bike?: Maybe<Answer_Enums_E_Bike_Mutation_Response>;
  /** insert a single row into the table: "answer_enums.e_bike" */
  insert_answer_enums_e_bike_one?: Maybe<Answer_Enums_E_Bike>;
  /** insert data into the table: "answer_enums.foot_position" */
  insert_answer_enums_foot_position?: Maybe<Answer_Enums_Foot_Position_Mutation_Response>;
  /** insert a single row into the table: "answer_enums.foot_position" */
  insert_answer_enums_foot_position_one?: Maybe<Answer_Enums_Foot_Position>;
  /** insert data into the table: "answer_enums.foot_problems" */
  insert_answer_enums_foot_problems?: Maybe<Answer_Enums_Foot_Problems_Mutation_Response>;
  /** insert a single row into the table: "answer_enums.foot_problems" */
  insert_answer_enums_foot_problems_one?: Maybe<Answer_Enums_Foot_Problems>;
  /** insert data into the table: "answer_enums.foot_type" */
  insert_answer_enums_foot_type?: Maybe<Answer_Enums_Foot_Type_Mutation_Response>;
  /** insert a single row into the table: "answer_enums.foot_type" */
  insert_answer_enums_foot_type_one?: Maybe<Answer_Enums_Foot_Type>;
  /** insert data into the table: "answer_enums.gripping_distance" */
  insert_answer_enums_gripping_distance?: Maybe<Answer_Enums_Gripping_Distance_Mutation_Response>;
  /** insert a single row into the table: "answer_enums.gripping_distance" */
  insert_answer_enums_gripping_distance_one?: Maybe<Answer_Enums_Gripping_Distance>;
  /** insert data into the table: "answer_enums.hand_problems" */
  insert_answer_enums_hand_problems?: Maybe<Answer_Enums_Hand_Problems_Mutation_Response>;
  /** insert a single row into the table: "answer_enums.hand_problems" */
  insert_answer_enums_hand_problems_one?: Maybe<Answer_Enums_Hand_Problems>;
  /** insert data into the table: "answer_enums.leg_axis" */
  insert_answer_enums_leg_axis?: Maybe<Answer_Enums_Leg_Axis_Mutation_Response>;
  /** insert a single row into the table: "answer_enums.leg_axis" */
  insert_answer_enums_leg_axis_one?: Maybe<Answer_Enums_Leg_Axis>;
  /** insert data into the table: "answer_enums.pelvic_problems" */
  insert_answer_enums_pelvic_problems?: Maybe<Answer_Enums_Pelvic_Problems_Mutation_Response>;
  /** insert a single row into the table: "answer_enums.pelvic_problems" */
  insert_answer_enums_pelvic_problems_one?: Maybe<Answer_Enums_Pelvic_Problems>;
  /** insert data into the table: "answer_enums.seat_position" */
  insert_answer_enums_seat_position?: Maybe<Answer_Enums_Seat_Position_Mutation_Response>;
  /** insert a single row into the table: "answer_enums.seat_position" */
  insert_answer_enums_seat_position_one?: Maybe<Answer_Enums_Seat_Position>;
  /** insert data into the table: "answer_enums.sex" */
  insert_answer_enums_sex?: Maybe<Answer_Enums_Sex_Mutation_Response>;
  /** insert a single row into the table: "answer_enums.sex" */
  insert_answer_enums_sex_one?: Maybe<Answer_Enums_Sex>;
  /** insert data into the table: "answers" */
  insert_answers?: Maybe<Answers_Mutation_Response>;
  /** insert a single row into the table: "answers" */
  insert_answers_one?: Maybe<Answers>;
  /** insert data into the table: "article_group" */
  insert_article_group?: Maybe<Article_Group_Mutation_Response>;
  /** insert a single row into the table: "article_group" */
  insert_article_group_one?: Maybe<Article_Group>;
  /** insert data into the table: "article_information_i18n" */
  insert_article_information_i18n?: Maybe<Article_Information_I18n_Mutation_Response>;
  /** insert a single row into the table: "article_information_i18n" */
  insert_article_information_i18n_one?: Maybe<Article_Information_I18n>;
  /** insert data into the table: "article_ranking" */
  insert_article_ranking?: Maybe<Article_Ranking_Mutation_Response>;
  /** insert a single row into the table: "article_ranking" */
  insert_article_ranking_one?: Maybe<Article_Ranking>;
  /** insert data into the table: "article_type" */
  insert_article_type?: Maybe<Article_Type_Mutation_Response>;
  /** insert a single row into the table: "article_type" */
  insert_article_type_one?: Maybe<Article_Type>;
  /** insert data into the table: "auth.one_time_token" */
  insert_auth_one_time_token?: Maybe<Auth_One_Time_Token_Mutation_Response>;
  /** insert a single row into the table: "auth.one_time_token" */
  insert_auth_one_time_token_one?: Maybe<Auth_One_Time_Token>;
  /** insert data into the table: "bikefitting.bikefitting_session" */
  insert_bikefitting_bikefitting_session?: Maybe<Bikefitting_Bikefitting_Session_Mutation_Response>;
  /** insert a single row into the table: "bikefitting.bikefitting_session" */
  insert_bikefitting_bikefitting_session_one?: Maybe<Bikefitting_Bikefitting_Session>;
  /** insert data into the table: "commission.account" */
  insert_commission_account?: Maybe<Commission_Account_Mutation_Response>;
  /** insert a single row into the table: "commission.account" */
  insert_commission_account_one?: Maybe<Commission_Account>;
  /** insert data into the table: "commission.boost" */
  insert_commission_boost?: Maybe<Commission_Boost_Mutation_Response>;
  /** insert a single row into the table: "commission.boost" */
  insert_commission_boost_one?: Maybe<Commission_Boost>;
  /** insert data into the table: "commission.payout_log" */
  insert_commission_payout_log?: Maybe<Commission_Payout_Log_Mutation_Response>;
  /** insert a single row into the table: "commission.payout_log" */
  insert_commission_payout_log_one?: Maybe<Commission_Payout_Log>;
  /** insert data into the table: "commission.type" */
  insert_commission_type?: Maybe<Commission_Type_Mutation_Response>;
  /** insert a single row into the table: "commission.type" */
  insert_commission_type_one?: Maybe<Commission_Type>;
  /** insert data into the table: "commission.webshop_data" */
  insert_commission_webshop_data?: Maybe<Commission_Webshop_Data_Mutation_Response>;
  /** insert a single row into the table: "commission.webshop_data" */
  insert_commission_webshop_data_one?: Maybe<Commission_Webshop_Data>;
  /** insert data into the table: "content.chapters" */
  insert_content_chapters?: Maybe<Content_Chapters_Mutation_Response>;
  /** insert a single row into the table: "content.chapters" */
  insert_content_chapters_one?: Maybe<Content_Chapters>;
  /** insert data into the table: "content.choices" */
  insert_content_choices?: Maybe<Content_Choices_Mutation_Response>;
  /** insert a single row into the table: "content.choices" */
  insert_content_choices_one?: Maybe<Content_Choices>;
  /** insert data into the table: "content.contactpoints" */
  insert_content_contactpoints?: Maybe<Content_Contactpoints_Mutation_Response>;
  /** insert a single row into the table: "content.contactpoints" */
  insert_content_contactpoints_one?: Maybe<Content_Contactpoints>;
  /** insert data into the table: "content.lection_requirements" */
  insert_content_lection_requirements?: Maybe<Content_Lection_Requirements_Mutation_Response>;
  /** insert a single row into the table: "content.lection_requirements" */
  insert_content_lection_requirements_one?: Maybe<Content_Lection_Requirements>;
  /** insert data into the table: "content.lections" */
  insert_content_lections?: Maybe<Content_Lections_Mutation_Response>;
  /** insert a single row into the table: "content.lections" */
  insert_content_lections_one?: Maybe<Content_Lections>;
  /** insert data into the table: "content.levels" */
  insert_content_levels?: Maybe<Content_Levels_Mutation_Response>;
  /** insert a single row into the table: "content.levels" */
  insert_content_levels_one?: Maybe<Content_Levels>;
  /** insert data into the table: "content.questions" */
  insert_content_questions?: Maybe<Content_Questions_Mutation_Response>;
  /** insert a single row into the table: "content.questions" */
  insert_content_questions_one?: Maybe<Content_Questions>;
  /** insert data into the table: "content.quiz_type" */
  insert_content_quiz_type?: Maybe<Content_Quiz_Type_Mutation_Response>;
  /** insert a single row into the table: "content.quiz_type" */
  insert_content_quiz_type_one?: Maybe<Content_Quiz_Type>;
  /** insert data into the table: "content.quizzes" */
  insert_content_quizzes?: Maybe<Content_Quizzes_Mutation_Response>;
  /** insert a single row into the table: "content.quizzes" */
  insert_content_quizzes_one?: Maybe<Content_Quizzes>;
  /** insert data into the table: "country_code" */
  insert_country_code?: Maybe<Country_Code_Mutation_Response>;
  /** insert a single row into the table: "country_code" */
  insert_country_code_one?: Maybe<Country_Code>;
  /** insert data into the table: "currency" */
  insert_currency?: Maybe<Currency_Mutation_Response>;
  /** insert a single row into the table: "currency" */
  insert_currency_one?: Maybe<Currency>;
  /** insert data into the table: "current_user" */
  insert_current_user?: Maybe<Current_User_Mutation_Response>;
  /** insert a single row into the table: "current_user" */
  insert_current_user_one?: Maybe<Current_User>;
  /** insert data into the table: "customer.answer" */
  insert_customer_answer?: Maybe<Customer_Answer_Mutation_Response>;
  /** insert data into the table: "customer.answer_article_event" */
  insert_customer_answer_article_event?: Maybe<Customer_Answer_Article_Event_Mutation_Response>;
  /** insert a single row into the table: "customer.answer_article_event" */
  insert_customer_answer_article_event_one?: Maybe<Customer_Answer_Article_Event>;
  /** insert data into the table: "customer.answer_foot_problems" */
  insert_customer_answer_foot_problems?: Maybe<Customer_Answer_Foot_Problems_Mutation_Response>;
  /** insert a single row into the table: "customer.answer_foot_problems" */
  insert_customer_answer_foot_problems_one?: Maybe<Customer_Answer_Foot_Problems>;
  /** insert data into the table: "customer.answer_hand_problems" */
  insert_customer_answer_hand_problems?: Maybe<Customer_Answer_Hand_Problems_Mutation_Response>;
  /** insert a single row into the table: "customer.answer_hand_problems" */
  insert_customer_answer_hand_problems_one?: Maybe<Customer_Answer_Hand_Problems>;
  /** insert a single row into the table: "customer.answer" */
  insert_customer_answer_one?: Maybe<Customer_Answer>;
  /** insert data into the table: "customer.answer_pelvic_problems" */
  insert_customer_answer_pelvic_problems?: Maybe<Customer_Answer_Pelvic_Problems_Mutation_Response>;
  /** insert a single row into the table: "customer.answer_pelvic_problems" */
  insert_customer_answer_pelvic_problems_one?: Maybe<Customer_Answer_Pelvic_Problems>;
  /** insert data into the table: "customer.answer_results" */
  insert_customer_answer_results?: Maybe<Customer_Answer_Results_Mutation_Response>;
  /** insert a single row into the table: "customer.answer_results" */
  insert_customer_answer_results_one?: Maybe<Customer_Answer_Results>;
  /** insert data into the table: "customer_dashboard.event" */
  insert_customer_dashboard_event?: Maybe<Customer_Dashboard_Event_Mutation_Response>;
  /** insert data into the table: "customer_dashboard.event_kind" */
  insert_customer_dashboard_event_kind?: Maybe<Customer_Dashboard_Event_Kind_Mutation_Response>;
  /** insert a single row into the table: "customer_dashboard.event_kind" */
  insert_customer_dashboard_event_kind_one?: Maybe<Customer_Dashboard_Event_Kind>;
  /** insert a single row into the table: "customer_dashboard.event" */
  insert_customer_dashboard_event_one?: Maybe<Customer_Dashboard_Event>;
  /** insert data into the table: "customer.deleted_answers" */
  insert_customer_deleted_answers?: Maybe<Customer_Deleted_Answers_Mutation_Response>;
  /** insert a single row into the table: "customer.deleted_answers" */
  insert_customer_deleted_answers_one?: Maybe<Customer_Deleted_Answers>;
  /** insert data into the table: "customer.emails" */
  insert_customer_emails?: Maybe<Customer_Emails_Mutation_Response>;
  /** insert a single row into the table: "customer.emails" */
  insert_customer_emails_one?: Maybe<Customer_Emails>;
  /** insert data into the table: "customer.registration" */
  insert_customer_registration?: Maybe<Customer_Registration_Mutation_Response>;
  /** insert a single row into the table: "customer.registration" */
  insert_customer_registration_one?: Maybe<Customer_Registration>;
  /** insert data into the table: "customer.signature" */
  insert_customer_signature?: Maybe<Customer_Signature_Mutation_Response>;
  /** insert a single row into the table: "customer.signature" */
  insert_customer_signature_one?: Maybe<Customer_Signature>;
  /** insert data into the table: "customer.voucher" */
  insert_customer_voucher?: Maybe<Customer_Voucher_Mutation_Response>;
  /** insert a single row into the table: "customer.voucher" */
  insert_customer_voucher_one?: Maybe<Customer_Voucher>;
  /** insert data into the table: "customer.web_questionnaire_completion" */
  insert_customer_web_questionnaire_completion?: Maybe<Customer_Web_Questionnaire_Completion_Mutation_Response>;
  /** insert a single row into the table: "customer.web_questionnaire_completion" */
  insert_customer_web_questionnaire_completion_one?: Maybe<Customer_Web_Questionnaire_Completion>;
  /** insert data into the table: "cv.body" */
  insert_cv_body?: Maybe<Cv_Body_Mutation_Response>;
  /** insert a single row into the table: "cv.body" */
  insert_cv_body_one?: Maybe<Cv_Body>;
  /** insert data into the table: "cv.handmarks" */
  insert_cv_handmarks?: Maybe<Cv_Handmarks_Mutation_Response>;
  /** insert a single row into the table: "cv.handmarks" */
  insert_cv_handmarks_one?: Maybe<Cv_Handmarks>;
  /** insert data into the table: "cv.sitbones" */
  insert_cv_sitbones?: Maybe<Cv_Sitbones_Mutation_Response>;
  /** insert a single row into the table: "cv.sitbones" */
  insert_cv_sitbones_one?: Maybe<Cv_Sitbones>;
  /** insert data into the table: "distributor" */
  insert_distributor?: Maybe<Distributor_Mutation_Response>;
  /** insert a single row into the table: "distributor" */
  insert_distributor_one?: Maybe<Distributor>;
  /** insert data into the table: "distributors" */
  insert_distributors?: Maybe<Distributors_Mutation_Response>;
  /** insert a single row into the table: "distributors" */
  insert_distributors_one?: Maybe<Distributors>;
  /** insert data into the table: "event_enums.article_event" */
  insert_event_enums_article_event?: Maybe<Event_Enums_Article_Event_Mutation_Response>;
  /** insert a single row into the table: "event_enums.article_event" */
  insert_event_enums_article_event_one?: Maybe<Event_Enums_Article_Event>;
  /** insert data into the table: "event_enums.event_source" */
  insert_event_enums_event_source?: Maybe<Event_Enums_Event_Source_Mutation_Response>;
  /** insert a single row into the table: "event_enums.event_source" */
  insert_event_enums_event_source_one?: Maybe<Event_Enums_Event_Source>;
  /** insert data into the table: "event_enums.log_level" */
  insert_event_enums_log_level?: Maybe<Event_Enums_Log_Level_Mutation_Response>;
  /** insert a single row into the table: "event_enums.log_level" */
  insert_event_enums_log_level_one?: Maybe<Event_Enums_Log_Level>;
  /** insert data into the table: "event_log.event_types" */
  insert_event_log_event_types?: Maybe<Event_Log_Event_Types_Mutation_Response>;
  /** insert a single row into the table: "event_log.event_types" */
  insert_event_log_event_types_one?: Maybe<Event_Log_Event_Types>;
  /** insert data into the table: "event_log.events" */
  insert_event_log_events?: Maybe<Event_Log_Events_Mutation_Response>;
  /** insert a single row into the table: "event_log.events" */
  insert_event_log_events_one?: Maybe<Event_Log_Events>;
  /** insert data into the table: "frontend.vendor_support" */
  insert_frontend_vendor_support?: Maybe<Frontend_Vendor_Support_Mutation_Response>;
  /** insert a single row into the table: "frontend.vendor_support" */
  insert_frontend_vendor_support_one?: Maybe<Frontend_Vendor_Support>;
  /** insert data into the table: "iframe.feedback" */
  insert_iframe_feedback?: Maybe<Iframe_Feedback_Mutation_Response>;
  /** insert a single row into the table: "iframe.feedback" */
  insert_iframe_feedback_one?: Maybe<Iframe_Feedback>;
  /** insert data into the table: "iframe.newsletter_subscriptions" */
  insert_iframe_newsletter_subscriptions?: Maybe<Iframe_Newsletter_Subscriptions_Mutation_Response>;
  /** insert a single row into the table: "iframe.newsletter_subscriptions" */
  insert_iframe_newsletter_subscriptions_one?: Maybe<Iframe_Newsletter_Subscriptions>;
  /** insert data into the table: "iframe.rating" */
  insert_iframe_rating?: Maybe<Iframe_Rating_Mutation_Response>;
  /** insert a single row into the table: "iframe.rating" */
  insert_iframe_rating_one?: Maybe<Iframe_Rating>;
  /** insert data into the table: "iqlab_rewards.types" */
  insert_iqlab_rewards_types?: Maybe<Iqlab_Rewards_Types_Mutation_Response>;
  /** insert a single row into the table: "iqlab_rewards.types" */
  insert_iqlab_rewards_types_one?: Maybe<Iqlab_Rewards_Types>;
  /** insert data into the table: "iqlab_rewards.vouchers" */
  insert_iqlab_rewards_vouchers?: Maybe<Iqlab_Rewards_Vouchers_Mutation_Response>;
  /** insert a single row into the table: "iqlab_rewards.vouchers" */
  insert_iqlab_rewards_vouchers_one?: Maybe<Iqlab_Rewards_Vouchers>;
  /** insert data into the table: "measurement.measure" */
  insert_measurement_measure?: Maybe<Measurement_Measure_Mutation_Response>;
  /** insert a single row into the table: "measurement.measure" */
  insert_measurement_measure_one?: Maybe<Measurement_Measure>;
  /** insert data into the table: "measurement.measure_type" */
  insert_measurement_measure_type?: Maybe<Measurement_Measure_Type_Mutation_Response>;
  /** insert a single row into the table: "measurement.measure_type" */
  insert_measurement_measure_type_one?: Maybe<Measurement_Measure_Type>;
  /** insert data into the table: "measurement.session_source" */
  insert_measurement_session_source?: Maybe<Measurement_Session_Source_Mutation_Response>;
  /** insert a single row into the table: "measurement.session_source" */
  insert_measurement_session_source_one?: Maybe<Measurement_Session_Source>;
  /** insert data into the table: "measurement.session_type" */
  insert_measurement_session_type?: Maybe<Measurement_Session_Type_Mutation_Response>;
  /** insert a single row into the table: "measurement.session_type" */
  insert_measurement_session_type_one?: Maybe<Measurement_Session_Type>;
  /** insert data into the table: "measurement.sessions" */
  insert_measurement_sessions?: Maybe<Measurement_Sessions_Mutation_Response>;
  /** insert a single row into the table: "measurement.sessions" */
  insert_measurement_sessions_one?: Maybe<Measurement_Sessions>;
  /** insert data into the table: "metric.answer_location" */
  insert_metric_answer_location?: Maybe<Metric_Answer_Location_Mutation_Response>;
  /** insert a single row into the table: "metric.answer_location" */
  insert_metric_answer_location_one?: Maybe<Metric_Answer_Location>;
  /** insert data into the table: "metric.app_events" */
  insert_metric_app_events?: Maybe<Metric_App_Events_Mutation_Response>;
  /** insert a single row into the table: "metric.app_events" */
  insert_metric_app_events_one?: Maybe<Metric_App_Events>;
  /** insert data into the table: "metric.question_duration" */
  insert_metric_question_duration?: Maybe<Metric_Question_Duration_Mutation_Response>;
  /** insert a single row into the table: "metric.question_duration" */
  insert_metric_question_duration_one?: Maybe<Metric_Question_Duration>;
  /** insert data into the table: "metric.usage_information" */
  insert_metric_usage_information?: Maybe<Metric_Usage_Information_Mutation_Response>;
  /** insert a single row into the table: "metric.usage_information" */
  insert_metric_usage_information_one?: Maybe<Metric_Usage_Information>;
  /** insert data into the table: "onboarding.emails" */
  insert_onboarding_emails?: Maybe<Onboarding_Emails_Mutation_Response>;
  /** insert a single row into the table: "onboarding.emails" */
  insert_onboarding_emails_one?: Maybe<Onboarding_Emails>;
  /** insert data into the table: "organisation" */
  insert_organisation?: Maybe<Organisation_Mutation_Response>;
  /** insert a single row into the table: "organisation" */
  insert_organisation_one?: Maybe<Organisation>;
  /** insert data into the table: "preferences.user_preferences" */
  insert_preferences_user_preferences?: Maybe<Preferences_User_Preferences_Mutation_Response>;
  /** insert a single row into the table: "preferences.user_preferences" */
  insert_preferences_user_preferences_one?: Maybe<Preferences_User_Preferences>;
  /** insert data into the table: "preferences.vendor_preferences" */
  insert_preferences_vendor_preferences?: Maybe<Preferences_Vendor_Preferences_Mutation_Response>;
  /** insert a single row into the table: "preferences.vendor_preferences" */
  insert_preferences_vendor_preferences_one?: Maybe<Preferences_Vendor_Preferences>;
  /** insert data into the table: "progress.completed_chapters" */
  insert_progress_completed_chapters?: Maybe<Progress_Completed_Chapters_Mutation_Response>;
  /** insert a single row into the table: "progress.completed_chapters" */
  insert_progress_completed_chapters_one?: Maybe<Progress_Completed_Chapters>;
  /** insert data into the table: "progress.completed_contactpoints" */
  insert_progress_completed_contactpoints?: Maybe<Progress_Completed_Contactpoints_Mutation_Response>;
  /** insert a single row into the table: "progress.completed_contactpoints" */
  insert_progress_completed_contactpoints_one?: Maybe<Progress_Completed_Contactpoints>;
  /** insert data into the table: "progress.completed_lections" */
  insert_progress_completed_lections?: Maybe<Progress_Completed_Lections_Mutation_Response>;
  /** insert a single row into the table: "progress.completed_lections" */
  insert_progress_completed_lections_one?: Maybe<Progress_Completed_Lections>;
  /** insert data into the table: "recommendation.own_articles" */
  insert_recommendation_own_articles?: Maybe<Recommendation_Own_Articles_Mutation_Response>;
  /** insert a single row into the table: "recommendation.own_articles" */
  insert_recommendation_own_articles_one?: Maybe<Recommendation_Own_Articles>;
  /** insert data into the table: "recommendation.return_search_article" */
  insert_recommendation_return_search_article?: Maybe<Recommendation_Return_Search_Article_Mutation_Response>;
  /** insert a single row into the table: "recommendation.return_search_article" */
  insert_recommendation_return_search_article_one?: Maybe<Recommendation_Return_Search_Article>;
  /** insert data into the table: "rewards.benefit_category" */
  insert_rewards_benefit_category?: Maybe<Rewards_Benefit_Category_Mutation_Response>;
  /** insert a single row into the table: "rewards.benefit_category" */
  insert_rewards_benefit_category_one?: Maybe<Rewards_Benefit_Category>;
  /** insert data into the table: "rewards.benefits" */
  insert_rewards_benefits?: Maybe<Rewards_Benefits_Mutation_Response>;
  /** insert a single row into the table: "rewards.benefits" */
  insert_rewards_benefits_one?: Maybe<Rewards_Benefits>;
  /** insert data into the table: "rewards.boosts" */
  insert_rewards_boosts?: Maybe<Rewards_Boosts_Mutation_Response>;
  /** insert a single row into the table: "rewards.boosts" */
  insert_rewards_boosts_one?: Maybe<Rewards_Boosts>;
  /** insert data into the table: "rewards.transactions" */
  insert_rewards_transactions?: Maybe<Rewards_Transactions_Mutation_Response>;
  /** insert a single row into the table: "rewards.transactions" */
  insert_rewards_transactions_one?: Maybe<Rewards_Transactions>;
  /** insert data into the table: "sessions" */
  insert_sessions?: Maybe<Sessions_Mutation_Response>;
  /** insert a single row into the table: "sessions" */
  insert_sessions_one?: Maybe<Sessions>;
  /** insert data into the table: "sqbase_event_log.event_types" */
  insert_sqbase_event_log_event_types?: Maybe<Sqbase_Event_Log_Event_Types_Mutation_Response>;
  /** insert a single row into the table: "sqbase_event_log.event_types" */
  insert_sqbase_event_log_event_types_one?: Maybe<Sqbase_Event_Log_Event_Types>;
  /** insert data into the table: "sqbase_event_log.events" */
  insert_sqbase_event_log_events?: Maybe<Sqbase_Event_Log_Events_Mutation_Response>;
  /** insert a single row into the table: "sqbase_event_log.events" */
  insert_sqbase_event_log_events_one?: Maybe<Sqbase_Event_Log_Events>;
  /** insert data into the table: "sqlab_contacts" */
  insert_sqlab_contacts?: Maybe<Sqlab_Contacts_Mutation_Response>;
  /** insert a single row into the table: "sqlab_contacts" */
  insert_sqlab_contacts_one?: Maybe<Sqlab_Contacts>;
  /** insert data into the table: "support.interactions" */
  insert_support_interactions?: Maybe<Support_Interactions_Mutation_Response>;
  /** insert a single row into the table: "support.interactions" */
  insert_support_interactions_one?: Maybe<Support_Interactions>;
  /** insert data into the table: "support.registrations_without_answers" */
  insert_support_registrations_without_answers?: Maybe<Support_Registrations_Without_Answers_Mutation_Response>;
  /** insert a single row into the table: "support.registrations_without_answers" */
  insert_support_registrations_without_answers_one?: Maybe<Support_Registrations_Without_Answers>;
  /** insert data into the table: "support.vendor_support" */
  insert_support_vendor_support?: Maybe<Support_Vendor_Support_Mutation_Response>;
  /** insert a single row into the table: "support.vendor_support" */
  insert_support_vendor_support_one?: Maybe<Support_Vendor_Support>;
  /** insert data into the table: "support.vendor_support_type" */
  insert_support_vendor_support_type?: Maybe<Support_Vendor_Support_Type_Mutation_Response>;
  /** insert a single row into the table: "support.vendor_support_type" */
  insert_support_vendor_support_type_one?: Maybe<Support_Vendor_Support_Type>;
  /** insert data into the table: "tracker.article_prices" */
  insert_tracker_article_prices?: Maybe<Tracker_Article_Prices_Mutation_Response>;
  /** insert a single row into the table: "tracker.article_prices" */
  insert_tracker_article_prices_one?: Maybe<Tracker_Article_Prices>;
  /** insert data into the table: "user" */
  insert_user?: Maybe<User_Mutation_Response>;
  /** insert data into the table: "user_details" */
  insert_user_details?: Maybe<User_Details_Mutation_Response>;
  /** insert a single row into the table: "user_details" */
  insert_user_details_one?: Maybe<User_Details>;
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<User>;
  registerNewUser?: Maybe<UserInfo>;
  registerUser?: Maybe<UserDetails>;
  resetPassword?: Maybe<PasswordResetResult>;
  sendEmail?: Maybe<EmailResult>;
  /** Sends the QR code for the quick start of a customer consultation via email. */
  sendErgonomicsTicket?: Maybe<SendErgonomicsTicketResponse>;
  sendLocalizedRecommendationEmail?: Maybe<RecommendationEmailResponse>;
  sendLocalizedRecommendationEmailUnauthenticated?: Maybe<RecommendationEmailResponse>;
  updateCommission?: Maybe<CommissionResponse>;
  /** update data of the table: "answer_enums.bicycle_type" */
  update_answer_enums_bicycle_type?: Maybe<Answer_Enums_Bicycle_Type_Mutation_Response>;
  /** update single row of the table: "answer_enums.bicycle_type" */
  update_answer_enums_bicycle_type_by_pk?: Maybe<Answer_Enums_Bicycle_Type>;
  /** update multiples rows of table: "answer_enums.bicycle_type" */
  update_answer_enums_bicycle_type_many?: Maybe<Array<Maybe<Answer_Enums_Bicycle_Type_Mutation_Response>>>;
  /** update data of the table: "answer_enums.driving_duration" */
  update_answer_enums_driving_duration?: Maybe<Answer_Enums_Driving_Duration_Mutation_Response>;
  /** update single row of the table: "answer_enums.driving_duration" */
  update_answer_enums_driving_duration_by_pk?: Maybe<Answer_Enums_Driving_Duration>;
  /** update multiples rows of table: "answer_enums.driving_duration" */
  update_answer_enums_driving_duration_many?: Maybe<Array<Maybe<Answer_Enums_Driving_Duration_Mutation_Response>>>;
  /** update data of the table: "answer_enums.driving_frequency" */
  update_answer_enums_driving_frequency?: Maybe<Answer_Enums_Driving_Frequency_Mutation_Response>;
  /** update single row of the table: "answer_enums.driving_frequency" */
  update_answer_enums_driving_frequency_by_pk?: Maybe<Answer_Enums_Driving_Frequency>;
  /** update multiples rows of table: "answer_enums.driving_frequency" */
  update_answer_enums_driving_frequency_many?: Maybe<Array<Maybe<Answer_Enums_Driving_Frequency_Mutation_Response>>>;
  /** update data of the table: "answer_enums.e_bike" */
  update_answer_enums_e_bike?: Maybe<Answer_Enums_E_Bike_Mutation_Response>;
  /** update single row of the table: "answer_enums.e_bike" */
  update_answer_enums_e_bike_by_pk?: Maybe<Answer_Enums_E_Bike>;
  /** update multiples rows of table: "answer_enums.e_bike" */
  update_answer_enums_e_bike_many?: Maybe<Array<Maybe<Answer_Enums_E_Bike_Mutation_Response>>>;
  /** update data of the table: "answer_enums.foot_position" */
  update_answer_enums_foot_position?: Maybe<Answer_Enums_Foot_Position_Mutation_Response>;
  /** update single row of the table: "answer_enums.foot_position" */
  update_answer_enums_foot_position_by_pk?: Maybe<Answer_Enums_Foot_Position>;
  /** update multiples rows of table: "answer_enums.foot_position" */
  update_answer_enums_foot_position_many?: Maybe<Array<Maybe<Answer_Enums_Foot_Position_Mutation_Response>>>;
  /** update data of the table: "answer_enums.foot_problems" */
  update_answer_enums_foot_problems?: Maybe<Answer_Enums_Foot_Problems_Mutation_Response>;
  /** update single row of the table: "answer_enums.foot_problems" */
  update_answer_enums_foot_problems_by_pk?: Maybe<Answer_Enums_Foot_Problems>;
  /** update multiples rows of table: "answer_enums.foot_problems" */
  update_answer_enums_foot_problems_many?: Maybe<Array<Maybe<Answer_Enums_Foot_Problems_Mutation_Response>>>;
  /** update data of the table: "answer_enums.foot_type" */
  update_answer_enums_foot_type?: Maybe<Answer_Enums_Foot_Type_Mutation_Response>;
  /** update single row of the table: "answer_enums.foot_type" */
  update_answer_enums_foot_type_by_pk?: Maybe<Answer_Enums_Foot_Type>;
  /** update multiples rows of table: "answer_enums.foot_type" */
  update_answer_enums_foot_type_many?: Maybe<Array<Maybe<Answer_Enums_Foot_Type_Mutation_Response>>>;
  /** update data of the table: "answer_enums.gripping_distance" */
  update_answer_enums_gripping_distance?: Maybe<Answer_Enums_Gripping_Distance_Mutation_Response>;
  /** update single row of the table: "answer_enums.gripping_distance" */
  update_answer_enums_gripping_distance_by_pk?: Maybe<Answer_Enums_Gripping_Distance>;
  /** update multiples rows of table: "answer_enums.gripping_distance" */
  update_answer_enums_gripping_distance_many?: Maybe<Array<Maybe<Answer_Enums_Gripping_Distance_Mutation_Response>>>;
  /** update data of the table: "answer_enums.hand_problems" */
  update_answer_enums_hand_problems?: Maybe<Answer_Enums_Hand_Problems_Mutation_Response>;
  /** update single row of the table: "answer_enums.hand_problems" */
  update_answer_enums_hand_problems_by_pk?: Maybe<Answer_Enums_Hand_Problems>;
  /** update multiples rows of table: "answer_enums.hand_problems" */
  update_answer_enums_hand_problems_many?: Maybe<Array<Maybe<Answer_Enums_Hand_Problems_Mutation_Response>>>;
  /** update data of the table: "answer_enums.leg_axis" */
  update_answer_enums_leg_axis?: Maybe<Answer_Enums_Leg_Axis_Mutation_Response>;
  /** update single row of the table: "answer_enums.leg_axis" */
  update_answer_enums_leg_axis_by_pk?: Maybe<Answer_Enums_Leg_Axis>;
  /** update multiples rows of table: "answer_enums.leg_axis" */
  update_answer_enums_leg_axis_many?: Maybe<Array<Maybe<Answer_Enums_Leg_Axis_Mutation_Response>>>;
  /** update data of the table: "answer_enums.pelvic_problems" */
  update_answer_enums_pelvic_problems?: Maybe<Answer_Enums_Pelvic_Problems_Mutation_Response>;
  /** update single row of the table: "answer_enums.pelvic_problems" */
  update_answer_enums_pelvic_problems_by_pk?: Maybe<Answer_Enums_Pelvic_Problems>;
  /** update multiples rows of table: "answer_enums.pelvic_problems" */
  update_answer_enums_pelvic_problems_many?: Maybe<Array<Maybe<Answer_Enums_Pelvic_Problems_Mutation_Response>>>;
  /** update data of the table: "answer_enums.seat_position" */
  update_answer_enums_seat_position?: Maybe<Answer_Enums_Seat_Position_Mutation_Response>;
  /** update single row of the table: "answer_enums.seat_position" */
  update_answer_enums_seat_position_by_pk?: Maybe<Answer_Enums_Seat_Position>;
  /** update multiples rows of table: "answer_enums.seat_position" */
  update_answer_enums_seat_position_many?: Maybe<Array<Maybe<Answer_Enums_Seat_Position_Mutation_Response>>>;
  /** update data of the table: "answer_enums.sex" */
  update_answer_enums_sex?: Maybe<Answer_Enums_Sex_Mutation_Response>;
  /** update single row of the table: "answer_enums.sex" */
  update_answer_enums_sex_by_pk?: Maybe<Answer_Enums_Sex>;
  /** update multiples rows of table: "answer_enums.sex" */
  update_answer_enums_sex_many?: Maybe<Array<Maybe<Answer_Enums_Sex_Mutation_Response>>>;
  /** update data of the table: "answers" */
  update_answers?: Maybe<Answers_Mutation_Response>;
  /** update single row of the table: "answers" */
  update_answers_by_pk?: Maybe<Answers>;
  /** update multiples rows of table: "answers" */
  update_answers_many?: Maybe<Array<Maybe<Answers_Mutation_Response>>>;
  /** update data of the table: "article_group" */
  update_article_group?: Maybe<Article_Group_Mutation_Response>;
  /** update single row of the table: "article_group" */
  update_article_group_by_pk?: Maybe<Article_Group>;
  /** update multiples rows of table: "article_group" */
  update_article_group_many?: Maybe<Array<Maybe<Article_Group_Mutation_Response>>>;
  /** update data of the table: "article_information_i18n" */
  update_article_information_i18n?: Maybe<Article_Information_I18n_Mutation_Response>;
  /** update single row of the table: "article_information_i18n" */
  update_article_information_i18n_by_pk?: Maybe<Article_Information_I18n>;
  /** update multiples rows of table: "article_information_i18n" */
  update_article_information_i18n_many?: Maybe<Array<Maybe<Article_Information_I18n_Mutation_Response>>>;
  /** update data of the table: "article_ranking" */
  update_article_ranking?: Maybe<Article_Ranking_Mutation_Response>;
  /** update single row of the table: "article_ranking" */
  update_article_ranking_by_pk?: Maybe<Article_Ranking>;
  /** update multiples rows of table: "article_ranking" */
  update_article_ranking_many?: Maybe<Array<Maybe<Article_Ranking_Mutation_Response>>>;
  /** update data of the table: "article_type" */
  update_article_type?: Maybe<Article_Type_Mutation_Response>;
  /** update single row of the table: "article_type" */
  update_article_type_by_pk?: Maybe<Article_Type>;
  /** update multiples rows of table: "article_type" */
  update_article_type_many?: Maybe<Array<Maybe<Article_Type_Mutation_Response>>>;
  /** update data of the table: "auth.one_time_token" */
  update_auth_one_time_token?: Maybe<Auth_One_Time_Token_Mutation_Response>;
  /** update single row of the table: "auth.one_time_token" */
  update_auth_one_time_token_by_pk?: Maybe<Auth_One_Time_Token>;
  /** update multiples rows of table: "auth.one_time_token" */
  update_auth_one_time_token_many?: Maybe<Array<Maybe<Auth_One_Time_Token_Mutation_Response>>>;
  /** update data of the table: "bikefitting.bikefitting_session" */
  update_bikefitting_bikefitting_session?: Maybe<Bikefitting_Bikefitting_Session_Mutation_Response>;
  /** update single row of the table: "bikefitting.bikefitting_session" */
  update_bikefitting_bikefitting_session_by_pk?: Maybe<Bikefitting_Bikefitting_Session>;
  /** update multiples rows of table: "bikefitting.bikefitting_session" */
  update_bikefitting_bikefitting_session_many?: Maybe<Array<Maybe<Bikefitting_Bikefitting_Session_Mutation_Response>>>;
  /** update data of the table: "commission.account" */
  update_commission_account?: Maybe<Commission_Account_Mutation_Response>;
  /** update single row of the table: "commission.account" */
  update_commission_account_by_pk?: Maybe<Commission_Account>;
  /** update multiples rows of table: "commission.account" */
  update_commission_account_many?: Maybe<Array<Maybe<Commission_Account_Mutation_Response>>>;
  /** update data of the table: "commission.boost" */
  update_commission_boost?: Maybe<Commission_Boost_Mutation_Response>;
  /** update single row of the table: "commission.boost" */
  update_commission_boost_by_pk?: Maybe<Commission_Boost>;
  /** update multiples rows of table: "commission.boost" */
  update_commission_boost_many?: Maybe<Array<Maybe<Commission_Boost_Mutation_Response>>>;
  /** update data of the table: "commission.payout_log" */
  update_commission_payout_log?: Maybe<Commission_Payout_Log_Mutation_Response>;
  /** update single row of the table: "commission.payout_log" */
  update_commission_payout_log_by_pk?: Maybe<Commission_Payout_Log>;
  /** update multiples rows of table: "commission.payout_log" */
  update_commission_payout_log_many?: Maybe<Array<Maybe<Commission_Payout_Log_Mutation_Response>>>;
  /** update data of the table: "commission.type" */
  update_commission_type?: Maybe<Commission_Type_Mutation_Response>;
  /** update single row of the table: "commission.type" */
  update_commission_type_by_pk?: Maybe<Commission_Type>;
  /** update multiples rows of table: "commission.type" */
  update_commission_type_many?: Maybe<Array<Maybe<Commission_Type_Mutation_Response>>>;
  /** update data of the table: "commission.webshop_data" */
  update_commission_webshop_data?: Maybe<Commission_Webshop_Data_Mutation_Response>;
  /** update single row of the table: "commission.webshop_data" */
  update_commission_webshop_data_by_pk?: Maybe<Commission_Webshop_Data>;
  /** update multiples rows of table: "commission.webshop_data" */
  update_commission_webshop_data_many?: Maybe<Array<Maybe<Commission_Webshop_Data_Mutation_Response>>>;
  /** update data of the table: "content.chapters" */
  update_content_chapters?: Maybe<Content_Chapters_Mutation_Response>;
  /** update single row of the table: "content.chapters" */
  update_content_chapters_by_pk?: Maybe<Content_Chapters>;
  /** update multiples rows of table: "content.chapters" */
  update_content_chapters_many?: Maybe<Array<Maybe<Content_Chapters_Mutation_Response>>>;
  /** update data of the table: "content.choices" */
  update_content_choices?: Maybe<Content_Choices_Mutation_Response>;
  /** update single row of the table: "content.choices" */
  update_content_choices_by_pk?: Maybe<Content_Choices>;
  /** update multiples rows of table: "content.choices" */
  update_content_choices_many?: Maybe<Array<Maybe<Content_Choices_Mutation_Response>>>;
  /** update data of the table: "content.contactpoints" */
  update_content_contactpoints?: Maybe<Content_Contactpoints_Mutation_Response>;
  /** update single row of the table: "content.contactpoints" */
  update_content_contactpoints_by_pk?: Maybe<Content_Contactpoints>;
  /** update multiples rows of table: "content.contactpoints" */
  update_content_contactpoints_many?: Maybe<Array<Maybe<Content_Contactpoints_Mutation_Response>>>;
  /** update data of the table: "content.lection_requirements" */
  update_content_lection_requirements?: Maybe<Content_Lection_Requirements_Mutation_Response>;
  /** update single row of the table: "content.lection_requirements" */
  update_content_lection_requirements_by_pk?: Maybe<Content_Lection_Requirements>;
  /** update multiples rows of table: "content.lection_requirements" */
  update_content_lection_requirements_many?: Maybe<Array<Maybe<Content_Lection_Requirements_Mutation_Response>>>;
  /** update data of the table: "content.lections" */
  update_content_lections?: Maybe<Content_Lections_Mutation_Response>;
  /** update single row of the table: "content.lections" */
  update_content_lections_by_pk?: Maybe<Content_Lections>;
  /** update multiples rows of table: "content.lections" */
  update_content_lections_many?: Maybe<Array<Maybe<Content_Lections_Mutation_Response>>>;
  /** update data of the table: "content.levels" */
  update_content_levels?: Maybe<Content_Levels_Mutation_Response>;
  /** update single row of the table: "content.levels" */
  update_content_levels_by_pk?: Maybe<Content_Levels>;
  /** update multiples rows of table: "content.levels" */
  update_content_levels_many?: Maybe<Array<Maybe<Content_Levels_Mutation_Response>>>;
  /** update data of the table: "content.questions" */
  update_content_questions?: Maybe<Content_Questions_Mutation_Response>;
  /** update single row of the table: "content.questions" */
  update_content_questions_by_pk?: Maybe<Content_Questions>;
  /** update multiples rows of table: "content.questions" */
  update_content_questions_many?: Maybe<Array<Maybe<Content_Questions_Mutation_Response>>>;
  /** update data of the table: "content.quiz_type" */
  update_content_quiz_type?: Maybe<Content_Quiz_Type_Mutation_Response>;
  /** update single row of the table: "content.quiz_type" */
  update_content_quiz_type_by_pk?: Maybe<Content_Quiz_Type>;
  /** update multiples rows of table: "content.quiz_type" */
  update_content_quiz_type_many?: Maybe<Array<Maybe<Content_Quiz_Type_Mutation_Response>>>;
  /** update data of the table: "content.quizzes" */
  update_content_quizzes?: Maybe<Content_Quizzes_Mutation_Response>;
  /** update single row of the table: "content.quizzes" */
  update_content_quizzes_by_pk?: Maybe<Content_Quizzes>;
  /** update multiples rows of table: "content.quizzes" */
  update_content_quizzes_many?: Maybe<Array<Maybe<Content_Quizzes_Mutation_Response>>>;
  /** update data of the table: "country_code" */
  update_country_code?: Maybe<Country_Code_Mutation_Response>;
  /** update single row of the table: "country_code" */
  update_country_code_by_pk?: Maybe<Country_Code>;
  /** update multiples rows of table: "country_code" */
  update_country_code_many?: Maybe<Array<Maybe<Country_Code_Mutation_Response>>>;
  /** update data of the table: "currency" */
  update_currency?: Maybe<Currency_Mutation_Response>;
  /** update single row of the table: "currency" */
  update_currency_by_pk?: Maybe<Currency>;
  /** update multiples rows of table: "currency" */
  update_currency_many?: Maybe<Array<Maybe<Currency_Mutation_Response>>>;
  /** update data of the table: "current_user" */
  update_current_user?: Maybe<Current_User_Mutation_Response>;
  /** update multiples rows of table: "current_user" */
  update_current_user_many?: Maybe<Array<Maybe<Current_User_Mutation_Response>>>;
  /** update data of the table: "customer.answer" */
  update_customer_answer?: Maybe<Customer_Answer_Mutation_Response>;
  /** update data of the table: "customer.answer_article_event" */
  update_customer_answer_article_event?: Maybe<Customer_Answer_Article_Event_Mutation_Response>;
  /** update single row of the table: "customer.answer_article_event" */
  update_customer_answer_article_event_by_pk?: Maybe<Customer_Answer_Article_Event>;
  /** update multiples rows of table: "customer.answer_article_event" */
  update_customer_answer_article_event_many?: Maybe<Array<Maybe<Customer_Answer_Article_Event_Mutation_Response>>>;
  /** update single row of the table: "customer.answer" */
  update_customer_answer_by_pk?: Maybe<Customer_Answer>;
  /** update data of the table: "customer.answer_foot_problems" */
  update_customer_answer_foot_problems?: Maybe<Customer_Answer_Foot_Problems_Mutation_Response>;
  /** update single row of the table: "customer.answer_foot_problems" */
  update_customer_answer_foot_problems_by_pk?: Maybe<Customer_Answer_Foot_Problems>;
  /** update multiples rows of table: "customer.answer_foot_problems" */
  update_customer_answer_foot_problems_many?: Maybe<Array<Maybe<Customer_Answer_Foot_Problems_Mutation_Response>>>;
  /** update data of the table: "customer.answer_hand_problems" */
  update_customer_answer_hand_problems?: Maybe<Customer_Answer_Hand_Problems_Mutation_Response>;
  /** update single row of the table: "customer.answer_hand_problems" */
  update_customer_answer_hand_problems_by_pk?: Maybe<Customer_Answer_Hand_Problems>;
  /** update multiples rows of table: "customer.answer_hand_problems" */
  update_customer_answer_hand_problems_many?: Maybe<Array<Maybe<Customer_Answer_Hand_Problems_Mutation_Response>>>;
  /** update multiples rows of table: "customer.answer" */
  update_customer_answer_many?: Maybe<Array<Maybe<Customer_Answer_Mutation_Response>>>;
  /** update data of the table: "customer.answer_pelvic_problems" */
  update_customer_answer_pelvic_problems?: Maybe<Customer_Answer_Pelvic_Problems_Mutation_Response>;
  /** update single row of the table: "customer.answer_pelvic_problems" */
  update_customer_answer_pelvic_problems_by_pk?: Maybe<Customer_Answer_Pelvic_Problems>;
  /** update multiples rows of table: "customer.answer_pelvic_problems" */
  update_customer_answer_pelvic_problems_many?: Maybe<Array<Maybe<Customer_Answer_Pelvic_Problems_Mutation_Response>>>;
  /** update data of the table: "customer.answer_results" */
  update_customer_answer_results?: Maybe<Customer_Answer_Results_Mutation_Response>;
  /** update single row of the table: "customer.answer_results" */
  update_customer_answer_results_by_pk?: Maybe<Customer_Answer_Results>;
  /** update multiples rows of table: "customer.answer_results" */
  update_customer_answer_results_many?: Maybe<Array<Maybe<Customer_Answer_Results_Mutation_Response>>>;
  /** update data of the table: "customer_dashboard.event" */
  update_customer_dashboard_event?: Maybe<Customer_Dashboard_Event_Mutation_Response>;
  /** update single row of the table: "customer_dashboard.event" */
  update_customer_dashboard_event_by_pk?: Maybe<Customer_Dashboard_Event>;
  /** update data of the table: "customer_dashboard.event_kind" */
  update_customer_dashboard_event_kind?: Maybe<Customer_Dashboard_Event_Kind_Mutation_Response>;
  /** update single row of the table: "customer_dashboard.event_kind" */
  update_customer_dashboard_event_kind_by_pk?: Maybe<Customer_Dashboard_Event_Kind>;
  /** update multiples rows of table: "customer_dashboard.event_kind" */
  update_customer_dashboard_event_kind_many?: Maybe<Array<Maybe<Customer_Dashboard_Event_Kind_Mutation_Response>>>;
  /** update multiples rows of table: "customer_dashboard.event" */
  update_customer_dashboard_event_many?: Maybe<Array<Maybe<Customer_Dashboard_Event_Mutation_Response>>>;
  /** update data of the table: "customer.deleted_answers" */
  update_customer_deleted_answers?: Maybe<Customer_Deleted_Answers_Mutation_Response>;
  /** update single row of the table: "customer.deleted_answers" */
  update_customer_deleted_answers_by_pk?: Maybe<Customer_Deleted_Answers>;
  /** update multiples rows of table: "customer.deleted_answers" */
  update_customer_deleted_answers_many?: Maybe<Array<Maybe<Customer_Deleted_Answers_Mutation_Response>>>;
  /** update data of the table: "customer.emails" */
  update_customer_emails?: Maybe<Customer_Emails_Mutation_Response>;
  /** update single row of the table: "customer.emails" */
  update_customer_emails_by_pk?: Maybe<Customer_Emails>;
  /** update multiples rows of table: "customer.emails" */
  update_customer_emails_many?: Maybe<Array<Maybe<Customer_Emails_Mutation_Response>>>;
  /** update data of the table: "customer.registration" */
  update_customer_registration?: Maybe<Customer_Registration_Mutation_Response>;
  /** update single row of the table: "customer.registration" */
  update_customer_registration_by_pk?: Maybe<Customer_Registration>;
  /** update multiples rows of table: "customer.registration" */
  update_customer_registration_many?: Maybe<Array<Maybe<Customer_Registration_Mutation_Response>>>;
  /** update data of the table: "customer.signature" */
  update_customer_signature?: Maybe<Customer_Signature_Mutation_Response>;
  /** update single row of the table: "customer.signature" */
  update_customer_signature_by_pk?: Maybe<Customer_Signature>;
  /** update multiples rows of table: "customer.signature" */
  update_customer_signature_many?: Maybe<Array<Maybe<Customer_Signature_Mutation_Response>>>;
  /** update data of the table: "customer.voucher" */
  update_customer_voucher?: Maybe<Customer_Voucher_Mutation_Response>;
  /** update single row of the table: "customer.voucher" */
  update_customer_voucher_by_pk?: Maybe<Customer_Voucher>;
  /** update multiples rows of table: "customer.voucher" */
  update_customer_voucher_many?: Maybe<Array<Maybe<Customer_Voucher_Mutation_Response>>>;
  /** update data of the table: "customer.web_questionnaire_completion" */
  update_customer_web_questionnaire_completion?: Maybe<Customer_Web_Questionnaire_Completion_Mutation_Response>;
  /** update single row of the table: "customer.web_questionnaire_completion" */
  update_customer_web_questionnaire_completion_by_pk?: Maybe<Customer_Web_Questionnaire_Completion>;
  /** update multiples rows of table: "customer.web_questionnaire_completion" */
  update_customer_web_questionnaire_completion_many?: Maybe<Array<Maybe<Customer_Web_Questionnaire_Completion_Mutation_Response>>>;
  /** update data of the table: "cv.body" */
  update_cv_body?: Maybe<Cv_Body_Mutation_Response>;
  /** update single row of the table: "cv.body" */
  update_cv_body_by_pk?: Maybe<Cv_Body>;
  /** update multiples rows of table: "cv.body" */
  update_cv_body_many?: Maybe<Array<Maybe<Cv_Body_Mutation_Response>>>;
  /** update data of the table: "cv.handmarks" */
  update_cv_handmarks?: Maybe<Cv_Handmarks_Mutation_Response>;
  /** update single row of the table: "cv.handmarks" */
  update_cv_handmarks_by_pk?: Maybe<Cv_Handmarks>;
  /** update multiples rows of table: "cv.handmarks" */
  update_cv_handmarks_many?: Maybe<Array<Maybe<Cv_Handmarks_Mutation_Response>>>;
  /** update data of the table: "cv.sitbones" */
  update_cv_sitbones?: Maybe<Cv_Sitbones_Mutation_Response>;
  /** update single row of the table: "cv.sitbones" */
  update_cv_sitbones_by_pk?: Maybe<Cv_Sitbones>;
  /** update multiples rows of table: "cv.sitbones" */
  update_cv_sitbones_many?: Maybe<Array<Maybe<Cv_Sitbones_Mutation_Response>>>;
  /** update data of the table: "distributor" */
  update_distributor?: Maybe<Distributor_Mutation_Response>;
  /** update single row of the table: "distributor" */
  update_distributor_by_pk?: Maybe<Distributor>;
  /** update multiples rows of table: "distributor" */
  update_distributor_many?: Maybe<Array<Maybe<Distributor_Mutation_Response>>>;
  /** update data of the table: "distributors" */
  update_distributors?: Maybe<Distributors_Mutation_Response>;
  /** update single row of the table: "distributors" */
  update_distributors_by_pk?: Maybe<Distributors>;
  /** update multiples rows of table: "distributors" */
  update_distributors_many?: Maybe<Array<Maybe<Distributors_Mutation_Response>>>;
  /** update data of the table: "event_enums.article_event" */
  update_event_enums_article_event?: Maybe<Event_Enums_Article_Event_Mutation_Response>;
  /** update single row of the table: "event_enums.article_event" */
  update_event_enums_article_event_by_pk?: Maybe<Event_Enums_Article_Event>;
  /** update multiples rows of table: "event_enums.article_event" */
  update_event_enums_article_event_many?: Maybe<Array<Maybe<Event_Enums_Article_Event_Mutation_Response>>>;
  /** update data of the table: "event_enums.event_source" */
  update_event_enums_event_source?: Maybe<Event_Enums_Event_Source_Mutation_Response>;
  /** update single row of the table: "event_enums.event_source" */
  update_event_enums_event_source_by_pk?: Maybe<Event_Enums_Event_Source>;
  /** update multiples rows of table: "event_enums.event_source" */
  update_event_enums_event_source_many?: Maybe<Array<Maybe<Event_Enums_Event_Source_Mutation_Response>>>;
  /** update data of the table: "event_enums.log_level" */
  update_event_enums_log_level?: Maybe<Event_Enums_Log_Level_Mutation_Response>;
  /** update single row of the table: "event_enums.log_level" */
  update_event_enums_log_level_by_pk?: Maybe<Event_Enums_Log_Level>;
  /** update multiples rows of table: "event_enums.log_level" */
  update_event_enums_log_level_many?: Maybe<Array<Maybe<Event_Enums_Log_Level_Mutation_Response>>>;
  /** update data of the table: "event_log.event_types" */
  update_event_log_event_types?: Maybe<Event_Log_Event_Types_Mutation_Response>;
  /** update single row of the table: "event_log.event_types" */
  update_event_log_event_types_by_pk?: Maybe<Event_Log_Event_Types>;
  /** update multiples rows of table: "event_log.event_types" */
  update_event_log_event_types_many?: Maybe<Array<Maybe<Event_Log_Event_Types_Mutation_Response>>>;
  /** update data of the table: "event_log.events" */
  update_event_log_events?: Maybe<Event_Log_Events_Mutation_Response>;
  /** update single row of the table: "event_log.events" */
  update_event_log_events_by_pk?: Maybe<Event_Log_Events>;
  /** update multiples rows of table: "event_log.events" */
  update_event_log_events_many?: Maybe<Array<Maybe<Event_Log_Events_Mutation_Response>>>;
  /** update data of the table: "frontend.vendor_support" */
  update_frontend_vendor_support?: Maybe<Frontend_Vendor_Support_Mutation_Response>;
  /** update single row of the table: "frontend.vendor_support" */
  update_frontend_vendor_support_by_pk?: Maybe<Frontend_Vendor_Support>;
  /** update multiples rows of table: "frontend.vendor_support" */
  update_frontend_vendor_support_many?: Maybe<Array<Maybe<Frontend_Vendor_Support_Mutation_Response>>>;
  /** update data of the table: "iframe.feedback" */
  update_iframe_feedback?: Maybe<Iframe_Feedback_Mutation_Response>;
  /** update single row of the table: "iframe.feedback" */
  update_iframe_feedback_by_pk?: Maybe<Iframe_Feedback>;
  /** update multiples rows of table: "iframe.feedback" */
  update_iframe_feedback_many?: Maybe<Array<Maybe<Iframe_Feedback_Mutation_Response>>>;
  /** update data of the table: "iframe.newsletter_subscriptions" */
  update_iframe_newsletter_subscriptions?: Maybe<Iframe_Newsletter_Subscriptions_Mutation_Response>;
  /** update single row of the table: "iframe.newsletter_subscriptions" */
  update_iframe_newsletter_subscriptions_by_pk?: Maybe<Iframe_Newsletter_Subscriptions>;
  /** update multiples rows of table: "iframe.newsletter_subscriptions" */
  update_iframe_newsletter_subscriptions_many?: Maybe<Array<Maybe<Iframe_Newsletter_Subscriptions_Mutation_Response>>>;
  /** update data of the table: "iframe.rating" */
  update_iframe_rating?: Maybe<Iframe_Rating_Mutation_Response>;
  /** update single row of the table: "iframe.rating" */
  update_iframe_rating_by_pk?: Maybe<Iframe_Rating>;
  /** update multiples rows of table: "iframe.rating" */
  update_iframe_rating_many?: Maybe<Array<Maybe<Iframe_Rating_Mutation_Response>>>;
  /** update data of the table: "iqlab_rewards.types" */
  update_iqlab_rewards_types?: Maybe<Iqlab_Rewards_Types_Mutation_Response>;
  /** update single row of the table: "iqlab_rewards.types" */
  update_iqlab_rewards_types_by_pk?: Maybe<Iqlab_Rewards_Types>;
  /** update multiples rows of table: "iqlab_rewards.types" */
  update_iqlab_rewards_types_many?: Maybe<Array<Maybe<Iqlab_Rewards_Types_Mutation_Response>>>;
  /** update data of the table: "iqlab_rewards.vouchers" */
  update_iqlab_rewards_vouchers?: Maybe<Iqlab_Rewards_Vouchers_Mutation_Response>;
  /** update single row of the table: "iqlab_rewards.vouchers" */
  update_iqlab_rewards_vouchers_by_pk?: Maybe<Iqlab_Rewards_Vouchers>;
  /** update multiples rows of table: "iqlab_rewards.vouchers" */
  update_iqlab_rewards_vouchers_many?: Maybe<Array<Maybe<Iqlab_Rewards_Vouchers_Mutation_Response>>>;
  /** update data of the table: "measurement.measure" */
  update_measurement_measure?: Maybe<Measurement_Measure_Mutation_Response>;
  /** update single row of the table: "measurement.measure" */
  update_measurement_measure_by_pk?: Maybe<Measurement_Measure>;
  /** update multiples rows of table: "measurement.measure" */
  update_measurement_measure_many?: Maybe<Array<Maybe<Measurement_Measure_Mutation_Response>>>;
  /** update data of the table: "measurement.measure_type" */
  update_measurement_measure_type?: Maybe<Measurement_Measure_Type_Mutation_Response>;
  /** update single row of the table: "measurement.measure_type" */
  update_measurement_measure_type_by_pk?: Maybe<Measurement_Measure_Type>;
  /** update multiples rows of table: "measurement.measure_type" */
  update_measurement_measure_type_many?: Maybe<Array<Maybe<Measurement_Measure_Type_Mutation_Response>>>;
  /** update data of the table: "measurement.session_source" */
  update_measurement_session_source?: Maybe<Measurement_Session_Source_Mutation_Response>;
  /** update single row of the table: "measurement.session_source" */
  update_measurement_session_source_by_pk?: Maybe<Measurement_Session_Source>;
  /** update multiples rows of table: "measurement.session_source" */
  update_measurement_session_source_many?: Maybe<Array<Maybe<Measurement_Session_Source_Mutation_Response>>>;
  /** update data of the table: "measurement.session_type" */
  update_measurement_session_type?: Maybe<Measurement_Session_Type_Mutation_Response>;
  /** update single row of the table: "measurement.session_type" */
  update_measurement_session_type_by_pk?: Maybe<Measurement_Session_Type>;
  /** update multiples rows of table: "measurement.session_type" */
  update_measurement_session_type_many?: Maybe<Array<Maybe<Measurement_Session_Type_Mutation_Response>>>;
  /** update data of the table: "measurement.sessions" */
  update_measurement_sessions?: Maybe<Measurement_Sessions_Mutation_Response>;
  /** update single row of the table: "measurement.sessions" */
  update_measurement_sessions_by_pk?: Maybe<Measurement_Sessions>;
  /** update multiples rows of table: "measurement.sessions" */
  update_measurement_sessions_many?: Maybe<Array<Maybe<Measurement_Sessions_Mutation_Response>>>;
  /** update data of the table: "metric.answer_location" */
  update_metric_answer_location?: Maybe<Metric_Answer_Location_Mutation_Response>;
  /** update single row of the table: "metric.answer_location" */
  update_metric_answer_location_by_pk?: Maybe<Metric_Answer_Location>;
  /** update multiples rows of table: "metric.answer_location" */
  update_metric_answer_location_many?: Maybe<Array<Maybe<Metric_Answer_Location_Mutation_Response>>>;
  /** update data of the table: "metric.app_events" */
  update_metric_app_events?: Maybe<Metric_App_Events_Mutation_Response>;
  /** update single row of the table: "metric.app_events" */
  update_metric_app_events_by_pk?: Maybe<Metric_App_Events>;
  /** update multiples rows of table: "metric.app_events" */
  update_metric_app_events_many?: Maybe<Array<Maybe<Metric_App_Events_Mutation_Response>>>;
  /** update data of the table: "metric.question_duration" */
  update_metric_question_duration?: Maybe<Metric_Question_Duration_Mutation_Response>;
  /** update single row of the table: "metric.question_duration" */
  update_metric_question_duration_by_pk?: Maybe<Metric_Question_Duration>;
  /** update multiples rows of table: "metric.question_duration" */
  update_metric_question_duration_many?: Maybe<Array<Maybe<Metric_Question_Duration_Mutation_Response>>>;
  /** update data of the table: "metric.usage_information" */
  update_metric_usage_information?: Maybe<Metric_Usage_Information_Mutation_Response>;
  /** update single row of the table: "metric.usage_information" */
  update_metric_usage_information_by_pk?: Maybe<Metric_Usage_Information>;
  /** update multiples rows of table: "metric.usage_information" */
  update_metric_usage_information_many?: Maybe<Array<Maybe<Metric_Usage_Information_Mutation_Response>>>;
  /** update data of the table: "onboarding.emails" */
  update_onboarding_emails?: Maybe<Onboarding_Emails_Mutation_Response>;
  /** update single row of the table: "onboarding.emails" */
  update_onboarding_emails_by_pk?: Maybe<Onboarding_Emails>;
  /** update multiples rows of table: "onboarding.emails" */
  update_onboarding_emails_many?: Maybe<Array<Maybe<Onboarding_Emails_Mutation_Response>>>;
  /** update data of the table: "organisation" */
  update_organisation?: Maybe<Organisation_Mutation_Response>;
  /** update single row of the table: "organisation" */
  update_organisation_by_pk?: Maybe<Organisation>;
  /** update multiples rows of table: "organisation" */
  update_organisation_many?: Maybe<Array<Maybe<Organisation_Mutation_Response>>>;
  /** update data of the table: "preferences.user_preferences" */
  update_preferences_user_preferences?: Maybe<Preferences_User_Preferences_Mutation_Response>;
  /** update single row of the table: "preferences.user_preferences" */
  update_preferences_user_preferences_by_pk?: Maybe<Preferences_User_Preferences>;
  /** update multiples rows of table: "preferences.user_preferences" */
  update_preferences_user_preferences_many?: Maybe<Array<Maybe<Preferences_User_Preferences_Mutation_Response>>>;
  /** update data of the table: "preferences.vendor_preferences" */
  update_preferences_vendor_preferences?: Maybe<Preferences_Vendor_Preferences_Mutation_Response>;
  /** update single row of the table: "preferences.vendor_preferences" */
  update_preferences_vendor_preferences_by_pk?: Maybe<Preferences_Vendor_Preferences>;
  /** update multiples rows of table: "preferences.vendor_preferences" */
  update_preferences_vendor_preferences_many?: Maybe<Array<Maybe<Preferences_Vendor_Preferences_Mutation_Response>>>;
  /** update data of the table: "progress.completed_chapters" */
  update_progress_completed_chapters?: Maybe<Progress_Completed_Chapters_Mutation_Response>;
  /** update single row of the table: "progress.completed_chapters" */
  update_progress_completed_chapters_by_pk?: Maybe<Progress_Completed_Chapters>;
  /** update multiples rows of table: "progress.completed_chapters" */
  update_progress_completed_chapters_many?: Maybe<Array<Maybe<Progress_Completed_Chapters_Mutation_Response>>>;
  /** update data of the table: "progress.completed_contactpoints" */
  update_progress_completed_contactpoints?: Maybe<Progress_Completed_Contactpoints_Mutation_Response>;
  /** update single row of the table: "progress.completed_contactpoints" */
  update_progress_completed_contactpoints_by_pk?: Maybe<Progress_Completed_Contactpoints>;
  /** update multiples rows of table: "progress.completed_contactpoints" */
  update_progress_completed_contactpoints_many?: Maybe<Array<Maybe<Progress_Completed_Contactpoints_Mutation_Response>>>;
  /** update data of the table: "progress.completed_lections" */
  update_progress_completed_lections?: Maybe<Progress_Completed_Lections_Mutation_Response>;
  /** update single row of the table: "progress.completed_lections" */
  update_progress_completed_lections_by_pk?: Maybe<Progress_Completed_Lections>;
  /** update multiples rows of table: "progress.completed_lections" */
  update_progress_completed_lections_many?: Maybe<Array<Maybe<Progress_Completed_Lections_Mutation_Response>>>;
  /** update data of the table: "recommendation.own_articles" */
  update_recommendation_own_articles?: Maybe<Recommendation_Own_Articles_Mutation_Response>;
  /** update single row of the table: "recommendation.own_articles" */
  update_recommendation_own_articles_by_pk?: Maybe<Recommendation_Own_Articles>;
  /** update multiples rows of table: "recommendation.own_articles" */
  update_recommendation_own_articles_many?: Maybe<Array<Maybe<Recommendation_Own_Articles_Mutation_Response>>>;
  /** update data of the table: "recommendation.return_search_article" */
  update_recommendation_return_search_article?: Maybe<Recommendation_Return_Search_Article_Mutation_Response>;
  /** update single row of the table: "recommendation.return_search_article" */
  update_recommendation_return_search_article_by_pk?: Maybe<Recommendation_Return_Search_Article>;
  /** update multiples rows of table: "recommendation.return_search_article" */
  update_recommendation_return_search_article_many?: Maybe<Array<Maybe<Recommendation_Return_Search_Article_Mutation_Response>>>;
  /** update data of the table: "rewards.benefit_category" */
  update_rewards_benefit_category?: Maybe<Rewards_Benefit_Category_Mutation_Response>;
  /** update single row of the table: "rewards.benefit_category" */
  update_rewards_benefit_category_by_pk?: Maybe<Rewards_Benefit_Category>;
  /** update multiples rows of table: "rewards.benefit_category" */
  update_rewards_benefit_category_many?: Maybe<Array<Maybe<Rewards_Benefit_Category_Mutation_Response>>>;
  /** update data of the table: "rewards.benefits" */
  update_rewards_benefits?: Maybe<Rewards_Benefits_Mutation_Response>;
  /** update single row of the table: "rewards.benefits" */
  update_rewards_benefits_by_pk?: Maybe<Rewards_Benefits>;
  /** update multiples rows of table: "rewards.benefits" */
  update_rewards_benefits_many?: Maybe<Array<Maybe<Rewards_Benefits_Mutation_Response>>>;
  /** update data of the table: "rewards.boosts" */
  update_rewards_boosts?: Maybe<Rewards_Boosts_Mutation_Response>;
  /** update single row of the table: "rewards.boosts" */
  update_rewards_boosts_by_pk?: Maybe<Rewards_Boosts>;
  /** update multiples rows of table: "rewards.boosts" */
  update_rewards_boosts_many?: Maybe<Array<Maybe<Rewards_Boosts_Mutation_Response>>>;
  /** update data of the table: "rewards.transactions" */
  update_rewards_transactions?: Maybe<Rewards_Transactions_Mutation_Response>;
  /** update single row of the table: "rewards.transactions" */
  update_rewards_transactions_by_pk?: Maybe<Rewards_Transactions>;
  /** update multiples rows of table: "rewards.transactions" */
  update_rewards_transactions_many?: Maybe<Array<Maybe<Rewards_Transactions_Mutation_Response>>>;
  /** update data of the table: "sessions" */
  update_sessions?: Maybe<Sessions_Mutation_Response>;
  /** update single row of the table: "sessions" */
  update_sessions_by_pk?: Maybe<Sessions>;
  /** update multiples rows of table: "sessions" */
  update_sessions_many?: Maybe<Array<Maybe<Sessions_Mutation_Response>>>;
  /** update data of the table: "sqbase_event_log.event_types" */
  update_sqbase_event_log_event_types?: Maybe<Sqbase_Event_Log_Event_Types_Mutation_Response>;
  /** update single row of the table: "sqbase_event_log.event_types" */
  update_sqbase_event_log_event_types_by_pk?: Maybe<Sqbase_Event_Log_Event_Types>;
  /** update multiples rows of table: "sqbase_event_log.event_types" */
  update_sqbase_event_log_event_types_many?: Maybe<Array<Maybe<Sqbase_Event_Log_Event_Types_Mutation_Response>>>;
  /** update data of the table: "sqbase_event_log.events" */
  update_sqbase_event_log_events?: Maybe<Sqbase_Event_Log_Events_Mutation_Response>;
  /** update single row of the table: "sqbase_event_log.events" */
  update_sqbase_event_log_events_by_pk?: Maybe<Sqbase_Event_Log_Events>;
  /** update multiples rows of table: "sqbase_event_log.events" */
  update_sqbase_event_log_events_many?: Maybe<Array<Maybe<Sqbase_Event_Log_Events_Mutation_Response>>>;
  /** update data of the table: "sqlab_contacts" */
  update_sqlab_contacts?: Maybe<Sqlab_Contacts_Mutation_Response>;
  /** update single row of the table: "sqlab_contacts" */
  update_sqlab_contacts_by_pk?: Maybe<Sqlab_Contacts>;
  /** update multiples rows of table: "sqlab_contacts" */
  update_sqlab_contacts_many?: Maybe<Array<Maybe<Sqlab_Contacts_Mutation_Response>>>;
  /** update data of the table: "support.interactions" */
  update_support_interactions?: Maybe<Support_Interactions_Mutation_Response>;
  /** update single row of the table: "support.interactions" */
  update_support_interactions_by_pk?: Maybe<Support_Interactions>;
  /** update multiples rows of table: "support.interactions" */
  update_support_interactions_many?: Maybe<Array<Maybe<Support_Interactions_Mutation_Response>>>;
  /** update data of the table: "support.registrations_without_answers" */
  update_support_registrations_without_answers?: Maybe<Support_Registrations_Without_Answers_Mutation_Response>;
  /** update multiples rows of table: "support.registrations_without_answers" */
  update_support_registrations_without_answers_many?: Maybe<Array<Maybe<Support_Registrations_Without_Answers_Mutation_Response>>>;
  /** update data of the table: "support.vendor_support" */
  update_support_vendor_support?: Maybe<Support_Vendor_Support_Mutation_Response>;
  /** update single row of the table: "support.vendor_support" */
  update_support_vendor_support_by_pk?: Maybe<Support_Vendor_Support>;
  /** update multiples rows of table: "support.vendor_support" */
  update_support_vendor_support_many?: Maybe<Array<Maybe<Support_Vendor_Support_Mutation_Response>>>;
  /** update data of the table: "support.vendor_support_type" */
  update_support_vendor_support_type?: Maybe<Support_Vendor_Support_Type_Mutation_Response>;
  /** update single row of the table: "support.vendor_support_type" */
  update_support_vendor_support_type_by_pk?: Maybe<Support_Vendor_Support_Type>;
  /** update multiples rows of table: "support.vendor_support_type" */
  update_support_vendor_support_type_many?: Maybe<Array<Maybe<Support_Vendor_Support_Type_Mutation_Response>>>;
  /** update data of the table: "tracker.article_prices" */
  update_tracker_article_prices?: Maybe<Tracker_Article_Prices_Mutation_Response>;
  /** update single row of the table: "tracker.article_prices" */
  update_tracker_article_prices_by_pk?: Maybe<Tracker_Article_Prices>;
  /** update multiples rows of table: "tracker.article_prices" */
  update_tracker_article_prices_many?: Maybe<Array<Maybe<Tracker_Article_Prices_Mutation_Response>>>;
  /** update data of the table: "user" */
  update_user?: Maybe<User_Mutation_Response>;
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>;
  /** update data of the table: "user_details" */
  update_user_details?: Maybe<User_Details_Mutation_Response>;
  /** update single row of the table: "user_details" */
  update_user_details_by_pk?: Maybe<User_Details>;
  /** update multiples rows of table: "user_details" */
  update_user_details_many?: Maybe<Array<Maybe<User_Details_Mutation_Response>>>;
  /** update multiples rows of table: "user" */
  update_user_many?: Maybe<Array<Maybe<User_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootAddBenefitTransactionArgs = {
  benefitId: Scalars['Int'];
  variant: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDeleteDataByEmailAddressArgs = {
  emailAddress: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDeleteDataByIdArgs = {
  customerAnswerId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Answer_Enums_Bicycle_TypeArgs = {
  where: Answer_Enums_Bicycle_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Answer_Enums_Bicycle_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Answer_Enums_Driving_DurationArgs = {
  where: Answer_Enums_Driving_Duration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Answer_Enums_Driving_Duration_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Answer_Enums_Driving_FrequencyArgs = {
  where: Answer_Enums_Driving_Frequency_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Answer_Enums_Driving_Frequency_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Answer_Enums_E_BikeArgs = {
  where: Answer_Enums_E_Bike_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Answer_Enums_E_Bike_By_PkArgs = {
  value: Scalars['Boolean'];
};


/** mutation root */
export type Mutation_RootDelete_Answer_Enums_Foot_PositionArgs = {
  where: Answer_Enums_Foot_Position_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Answer_Enums_Foot_Position_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Answer_Enums_Foot_ProblemsArgs = {
  where: Answer_Enums_Foot_Problems_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Answer_Enums_Foot_Problems_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Answer_Enums_Foot_TypeArgs = {
  where: Answer_Enums_Foot_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Answer_Enums_Foot_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Answer_Enums_Gripping_DistanceArgs = {
  where: Answer_Enums_Gripping_Distance_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Answer_Enums_Gripping_Distance_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Answer_Enums_Hand_ProblemsArgs = {
  where: Answer_Enums_Hand_Problems_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Answer_Enums_Hand_Problems_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Answer_Enums_Leg_AxisArgs = {
  where: Answer_Enums_Leg_Axis_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Answer_Enums_Leg_Axis_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Answer_Enums_Pelvic_ProblemsArgs = {
  where: Answer_Enums_Pelvic_Problems_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Answer_Enums_Pelvic_Problems_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Answer_Enums_Seat_PositionArgs = {
  where: Answer_Enums_Seat_Position_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Answer_Enums_Seat_Position_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Answer_Enums_SexArgs = {
  where: Answer_Enums_Sex_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Answer_Enums_Sex_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_AnswersArgs = {
  where: Answers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Answers_By_PkArgs = {
  choice_id: Scalars['Int'];
  session_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Article_GroupArgs = {
  where: Article_Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Article_Group_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Article_Information_I18nArgs = {
  where: Article_Information_I18n_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Article_Information_I18n_By_PkArgs = {
  article_id: Scalars['String'];
  language: Country_Code_Enum;
};


/** mutation root */
export type Mutation_RootDelete_Article_RankingArgs = {
  where: Article_Ranking_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Article_Ranking_By_PkArgs = {
  answer: Scalars['String'];
  article_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Article_TypeArgs = {
  where: Article_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Article_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_One_Time_TokenArgs = {
  where: Auth_One_Time_Token_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_One_Time_Token_By_PkArgs = {
  value: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Bikefitting_Bikefitting_SessionArgs = {
  where: Bikefitting_Bikefitting_Session_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bikefitting_Bikefitting_Session_By_PkArgs = {
  bikefitting_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Commission_AccountArgs = {
  where: Commission_Account_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Commission_Account_By_PkArgs = {
  answer_id: Scalars['String'];
  booking_time: Scalars['timestamptz'];
  type: Commission_Type_Enum;
  vendor_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Commission_BoostArgs = {
  where: Commission_Boost_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Commission_Boost_By_PkArgs = {
  start_date: Scalars['date'];
  vendor_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Commission_Payout_LogArgs = {
  where: Commission_Payout_Log_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Commission_Payout_Log_By_PkArgs = {
  quarter: Scalars['Int'];
  vendor_id: Scalars['String'];
  year: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Commission_TypeArgs = {
  where: Commission_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Commission_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Commission_Webshop_DataArgs = {
  where: Commission_Webshop_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Commission_Webshop_Data_By_PkArgs = {
  ox_order_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Content_ChaptersArgs = {
  where: Content_Chapters_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Content_Chapters_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Content_ChoicesArgs = {
  where: Content_Choices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Content_Choices_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Content_ContactpointsArgs = {
  where: Content_Contactpoints_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Content_Contactpoints_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Content_Lection_RequirementsArgs = {
  where: Content_Lection_Requirements_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Content_Lection_Requirements_By_PkArgs = {
  lection_id: Scalars['Int'];
  required_lection_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Content_LectionsArgs = {
  where: Content_Lections_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Content_Lections_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Content_LevelsArgs = {
  where: Content_Levels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Content_Levels_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Content_QuestionsArgs = {
  where: Content_Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Content_Questions_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Content_Quiz_TypeArgs = {
  where: Content_Quiz_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Content_Quiz_Type_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Content_QuizzesArgs = {
  where: Content_Quizzes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Content_Quizzes_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Country_CodeArgs = {
  where: Country_Code_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Country_Code_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_CurrencyArgs = {
  where: Currency_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Currency_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Current_UserArgs = {
  where: Current_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Customer_AnswerArgs = {
  where: Customer_Answer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Customer_Answer_Article_EventArgs = {
  where: Customer_Answer_Article_Event_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Customer_Answer_Article_Event_By_PkArgs = {
  answer_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
};


/** mutation root */
export type Mutation_RootDelete_Customer_Answer_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Customer_Answer_Foot_ProblemsArgs = {
  where: Customer_Answer_Foot_Problems_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Customer_Answer_Foot_Problems_By_PkArgs = {
  answer_id: Scalars['String'];
  foot_problem_id: Answer_Enums_Foot_Problems_Enum;
};


/** mutation root */
export type Mutation_RootDelete_Customer_Answer_Hand_ProblemsArgs = {
  where: Customer_Answer_Hand_Problems_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Customer_Answer_Hand_Problems_By_PkArgs = {
  answer_id: Scalars['String'];
  hand_problem_id: Answer_Enums_Hand_Problems_Enum;
};


/** mutation root */
export type Mutation_RootDelete_Customer_Answer_Pelvic_ProblemsArgs = {
  where: Customer_Answer_Pelvic_Problems_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Customer_Answer_Pelvic_Problems_By_PkArgs = {
  answer_id: Scalars['String'];
  pelvic_problem_id: Answer_Enums_Pelvic_Problems_Enum;
};


/** mutation root */
export type Mutation_RootDelete_Customer_Answer_ResultsArgs = {
  where: Customer_Answer_Results_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Customer_Answer_Results_By_PkArgs = {
  answer_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Customer_Dashboard_EventArgs = {
  where: Customer_Dashboard_Event_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Customer_Dashboard_Event_By_PkArgs = {
  answer_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
};


/** mutation root */
export type Mutation_RootDelete_Customer_Dashboard_Event_KindArgs = {
  where: Customer_Dashboard_Event_Kind_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Customer_Dashboard_Event_Kind_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Customer_Deleted_AnswersArgs = {
  where: Customer_Deleted_Answers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Customer_Deleted_Answers_By_PkArgs = {
  answer_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Customer_EmailsArgs = {
  where: Customer_Emails_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Customer_Emails_By_PkArgs = {
  answer_id: Scalars['String'];
  type: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Customer_RegistrationArgs = {
  where: Customer_Registration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Customer_Registration_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Customer_SignatureArgs = {
  where: Customer_Signature_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Customer_Signature_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Customer_VoucherArgs = {
  where: Customer_Voucher_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Customer_Voucher_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Customer_Web_Questionnaire_CompletionArgs = {
  where: Customer_Web_Questionnaire_Completion_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Customer_Web_Questionnaire_Completion_By_PkArgs = {
  answer_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Cv_BodyArgs = {
  where: Cv_Body_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Cv_Body_By_PkArgs = {
  answer_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
};


/** mutation root */
export type Mutation_RootDelete_Cv_HandmarksArgs = {
  where: Cv_Handmarks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Cv_Handmarks_By_PkArgs = {
  answer_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
};


/** mutation root */
export type Mutation_RootDelete_Cv_SitbonesArgs = {
  where: Cv_Sitbones_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Cv_Sitbones_By_PkArgs = {
  answer_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
};


/** mutation root */
export type Mutation_RootDelete_DistributorArgs = {
  where: Distributor_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Distributor_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_DistributorsArgs = {
  where: Distributors_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Distributors_By_PkArgs = {
  country_code: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Event_Enums_Article_EventArgs = {
  where: Event_Enums_Article_Event_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Enums_Article_Event_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Event_Enums_Event_SourceArgs = {
  where: Event_Enums_Event_Source_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Enums_Event_Source_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Event_Enums_Log_LevelArgs = {
  where: Event_Enums_Log_Level_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Enums_Log_Level_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Event_Log_Event_TypesArgs = {
  where: Event_Log_Event_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Log_Event_Types_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Event_Log_EventsArgs = {
  where: Event_Log_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Log_Events_By_PkArgs = {
  created_at: Scalars['timestamptz'];
  type: Scalars['String'];
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Frontend_Vendor_SupportArgs = {
  where: Frontend_Vendor_Support_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Frontend_Vendor_Support_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Iframe_FeedbackArgs = {
  where: Iframe_Feedback_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Iframe_Feedback_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Iframe_Newsletter_SubscriptionsArgs = {
  where: Iframe_Newsletter_Subscriptions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Iframe_Newsletter_Subscriptions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Iframe_RatingArgs = {
  where: Iframe_Rating_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Iframe_Rating_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Iqlab_Rewards_TypesArgs = {
  where: Iqlab_Rewards_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Iqlab_Rewards_Types_By_PkArgs = {
  type: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Iqlab_Rewards_VouchersArgs = {
  where: Iqlab_Rewards_Vouchers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Iqlab_Rewards_Vouchers_By_PkArgs = {
  code: Scalars['String'];
  created_at: Scalars['timestamptz'];
};


/** mutation root */
export type Mutation_RootDelete_Measurement_MeasureArgs = {
  where: Measurement_Measure_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Measurement_Measure_By_PkArgs = {
  session_id: Scalars['Int'];
  type: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Measurement_Measure_TypeArgs = {
  where: Measurement_Measure_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Measurement_Measure_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Measurement_Session_SourceArgs = {
  where: Measurement_Session_Source_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Measurement_Session_Source_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Measurement_Session_TypeArgs = {
  where: Measurement_Session_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Measurement_Session_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Measurement_SessionsArgs = {
  where: Measurement_Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Measurement_Sessions_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Metric_Answer_LocationArgs = {
  where: Metric_Answer_Location_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Metric_Answer_Location_By_PkArgs = {
  answer_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Metric_App_EventsArgs = {
  where: Metric_App_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Metric_App_Events_By_PkArgs = {
  created_at: Scalars['timestamptz'];
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Metric_Question_DurationArgs = {
  where: Metric_Question_Duration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Metric_Question_Duration_By_PkArgs = {
  created_at: Scalars['timestamptz'];
  customer_answer_id: Scalars['String'];
  question_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Metric_Usage_InformationArgs = {
  where: Metric_Usage_Information_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Metric_Usage_Information_By_PkArgs = {
  device_id: Scalars['String'];
  user_id: Scalars['uuid'];
  vendor_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Onboarding_EmailsArgs = {
  where: Onboarding_Emails_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Onboarding_Emails_By_PkArgs = {
  registration_id: Scalars['Int'];
  type: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_OrganisationArgs = {
  where: Organisation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Organisation_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Preferences_User_PreferencesArgs = {
  where: Preferences_User_Preferences_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Preferences_User_Preferences_By_PkArgs = {
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Preferences_Vendor_PreferencesArgs = {
  where: Preferences_Vendor_Preferences_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Preferences_Vendor_Preferences_By_PkArgs = {
  vendor_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Progress_Completed_ChaptersArgs = {
  where: Progress_Completed_Chapters_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Progress_Completed_Chapters_By_PkArgs = {
  chapter_id: Scalars['Int'];
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Progress_Completed_ContactpointsArgs = {
  where: Progress_Completed_Contactpoints_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Progress_Completed_Contactpoints_By_PkArgs = {
  contactpoint_id: Scalars['Int'];
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Progress_Completed_LectionsArgs = {
  where: Progress_Completed_Lections_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Progress_Completed_Lections_By_PkArgs = {
  lection_id: Scalars['Int'];
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Recommendation_Own_ArticlesArgs = {
  where: Recommendation_Own_Articles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Recommendation_Own_Articles_By_PkArgs = {
  answer_id: Scalars['String'];
  article_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Recommendation_Return_Search_ArticleArgs = {
  where: Recommendation_Return_Search_Article_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Recommendation_Return_Search_Article_By_PkArgs = {
  article_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Rewards_Benefit_CategoryArgs = {
  where: Rewards_Benefit_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rewards_Benefit_Category_By_PkArgs = {
  category: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Rewards_BenefitsArgs = {
  where: Rewards_Benefits_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rewards_Benefits_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Rewards_BoostsArgs = {
  where: Rewards_Boosts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rewards_Boosts_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Rewards_TransactionsArgs = {
  where: Rewards_Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rewards_Transactions_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_SessionsArgs = {
  where: Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sessions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Sqbase_Event_Log_Event_TypesArgs = {
  where: Sqbase_Event_Log_Event_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sqbase_Event_Log_Event_Types_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Sqbase_Event_Log_EventsArgs = {
  where: Sqbase_Event_Log_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sqbase_Event_Log_Events_By_PkArgs = {
  created_at: Scalars['timestamptz'];
  type: Scalars['String'];
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Sqlab_ContactsArgs = {
  where: Sqlab_Contacts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sqlab_Contacts_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Support_InteractionsArgs = {
  where: Support_Interactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Support_Interactions_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Support_Registrations_Without_AnswersArgs = {
  where: Support_Registrations_Without_Answers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Support_Vendor_SupportArgs = {
  where: Support_Vendor_Support_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Support_Vendor_Support_By_PkArgs = {
  request_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Support_Vendor_Support_TypeArgs = {
  where: Support_Vendor_Support_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Support_Vendor_Support_Type_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Tracker_Article_PricesArgs = {
  where: Tracker_Article_Prices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tracker_Article_Prices_By_PkArgs = {
  created_at: Scalars['timestamptz'];
  ean: Scalars['String'];
  shop_domain: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_DetailsArgs = {
  where: User_Details_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Details_By_PkArgs = {
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootEvaluateQuizArgs = {
  session_id: Scalars['ID'];
};


/** mutation root */
export type Mutation_RootEvaluateResponseArgs = {
  customerAnswerId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootGenerateOneTimePasswordArgs = {
  emailAddress: Scalars['String'];
  platformName: Scalars['String'];
};


/** mutation root */
export type Mutation_RootInsertRegistrationArgs = {
  additional_email_addresses: Scalars['String'];
  country: Scalars['String'];
  email: Scalars['String'];
  first_name: Scalars['String'];
  ipad_available: Scalars['String'];
  last_name: Scalars['String'];
  vendor_id: Scalars['String'];
  vendor_name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootInsert_Answer_Enums_Bicycle_TypeArgs = {
  objects: Array<Answer_Enums_Bicycle_Type_Insert_Input>;
  on_conflict?: InputMaybe<Answer_Enums_Bicycle_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Answer_Enums_Bicycle_Type_OneArgs = {
  object: Answer_Enums_Bicycle_Type_Insert_Input;
  on_conflict?: InputMaybe<Answer_Enums_Bicycle_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Answer_Enums_Driving_DurationArgs = {
  objects: Array<Answer_Enums_Driving_Duration_Insert_Input>;
  on_conflict?: InputMaybe<Answer_Enums_Driving_Duration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Answer_Enums_Driving_Duration_OneArgs = {
  object: Answer_Enums_Driving_Duration_Insert_Input;
  on_conflict?: InputMaybe<Answer_Enums_Driving_Duration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Answer_Enums_Driving_FrequencyArgs = {
  objects: Array<Answer_Enums_Driving_Frequency_Insert_Input>;
  on_conflict?: InputMaybe<Answer_Enums_Driving_Frequency_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Answer_Enums_Driving_Frequency_OneArgs = {
  object: Answer_Enums_Driving_Frequency_Insert_Input;
  on_conflict?: InputMaybe<Answer_Enums_Driving_Frequency_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Answer_Enums_E_BikeArgs = {
  objects: Array<Answer_Enums_E_Bike_Insert_Input>;
  on_conflict?: InputMaybe<Answer_Enums_E_Bike_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Answer_Enums_E_Bike_OneArgs = {
  object: Answer_Enums_E_Bike_Insert_Input;
  on_conflict?: InputMaybe<Answer_Enums_E_Bike_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Answer_Enums_Foot_PositionArgs = {
  objects: Array<Answer_Enums_Foot_Position_Insert_Input>;
  on_conflict?: InputMaybe<Answer_Enums_Foot_Position_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Answer_Enums_Foot_Position_OneArgs = {
  object: Answer_Enums_Foot_Position_Insert_Input;
  on_conflict?: InputMaybe<Answer_Enums_Foot_Position_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Answer_Enums_Foot_ProblemsArgs = {
  objects: Array<Answer_Enums_Foot_Problems_Insert_Input>;
  on_conflict?: InputMaybe<Answer_Enums_Foot_Problems_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Answer_Enums_Foot_Problems_OneArgs = {
  object: Answer_Enums_Foot_Problems_Insert_Input;
  on_conflict?: InputMaybe<Answer_Enums_Foot_Problems_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Answer_Enums_Foot_TypeArgs = {
  objects: Array<Answer_Enums_Foot_Type_Insert_Input>;
  on_conflict?: InputMaybe<Answer_Enums_Foot_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Answer_Enums_Foot_Type_OneArgs = {
  object: Answer_Enums_Foot_Type_Insert_Input;
  on_conflict?: InputMaybe<Answer_Enums_Foot_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Answer_Enums_Gripping_DistanceArgs = {
  objects: Array<Answer_Enums_Gripping_Distance_Insert_Input>;
  on_conflict?: InputMaybe<Answer_Enums_Gripping_Distance_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Answer_Enums_Gripping_Distance_OneArgs = {
  object: Answer_Enums_Gripping_Distance_Insert_Input;
  on_conflict?: InputMaybe<Answer_Enums_Gripping_Distance_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Answer_Enums_Hand_ProblemsArgs = {
  objects: Array<Answer_Enums_Hand_Problems_Insert_Input>;
  on_conflict?: InputMaybe<Answer_Enums_Hand_Problems_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Answer_Enums_Hand_Problems_OneArgs = {
  object: Answer_Enums_Hand_Problems_Insert_Input;
  on_conflict?: InputMaybe<Answer_Enums_Hand_Problems_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Answer_Enums_Leg_AxisArgs = {
  objects: Array<Answer_Enums_Leg_Axis_Insert_Input>;
  on_conflict?: InputMaybe<Answer_Enums_Leg_Axis_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Answer_Enums_Leg_Axis_OneArgs = {
  object: Answer_Enums_Leg_Axis_Insert_Input;
  on_conflict?: InputMaybe<Answer_Enums_Leg_Axis_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Answer_Enums_Pelvic_ProblemsArgs = {
  objects: Array<Answer_Enums_Pelvic_Problems_Insert_Input>;
  on_conflict?: InputMaybe<Answer_Enums_Pelvic_Problems_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Answer_Enums_Pelvic_Problems_OneArgs = {
  object: Answer_Enums_Pelvic_Problems_Insert_Input;
  on_conflict?: InputMaybe<Answer_Enums_Pelvic_Problems_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Answer_Enums_Seat_PositionArgs = {
  objects: Array<Answer_Enums_Seat_Position_Insert_Input>;
  on_conflict?: InputMaybe<Answer_Enums_Seat_Position_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Answer_Enums_Seat_Position_OneArgs = {
  object: Answer_Enums_Seat_Position_Insert_Input;
  on_conflict?: InputMaybe<Answer_Enums_Seat_Position_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Answer_Enums_SexArgs = {
  objects: Array<Answer_Enums_Sex_Insert_Input>;
  on_conflict?: InputMaybe<Answer_Enums_Sex_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Answer_Enums_Sex_OneArgs = {
  object: Answer_Enums_Sex_Insert_Input;
  on_conflict?: InputMaybe<Answer_Enums_Sex_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AnswersArgs = {
  objects: Array<Answers_Insert_Input>;
  on_conflict?: InputMaybe<Answers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Answers_OneArgs = {
  object: Answers_Insert_Input;
  on_conflict?: InputMaybe<Answers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Article_GroupArgs = {
  objects: Array<Article_Group_Insert_Input>;
  on_conflict?: InputMaybe<Article_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Article_Group_OneArgs = {
  object: Article_Group_Insert_Input;
  on_conflict?: InputMaybe<Article_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Article_Information_I18nArgs = {
  objects: Array<Article_Information_I18n_Insert_Input>;
  on_conflict?: InputMaybe<Article_Information_I18n_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Article_Information_I18n_OneArgs = {
  object: Article_Information_I18n_Insert_Input;
  on_conflict?: InputMaybe<Article_Information_I18n_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Article_RankingArgs = {
  objects: Array<Article_Ranking_Insert_Input>;
  on_conflict?: InputMaybe<Article_Ranking_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Article_Ranking_OneArgs = {
  object: Article_Ranking_Insert_Input;
  on_conflict?: InputMaybe<Article_Ranking_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Article_TypeArgs = {
  objects: Array<Article_Type_Insert_Input>;
  on_conflict?: InputMaybe<Article_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Article_Type_OneArgs = {
  object: Article_Type_Insert_Input;
  on_conflict?: InputMaybe<Article_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_One_Time_TokenArgs = {
  objects: Array<Auth_One_Time_Token_Insert_Input>;
  on_conflict?: InputMaybe<Auth_One_Time_Token_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_One_Time_Token_OneArgs = {
  object: Auth_One_Time_Token_Insert_Input;
  on_conflict?: InputMaybe<Auth_One_Time_Token_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bikefitting_Bikefitting_SessionArgs = {
  objects: Array<Bikefitting_Bikefitting_Session_Insert_Input>;
  on_conflict?: InputMaybe<Bikefitting_Bikefitting_Session_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bikefitting_Bikefitting_Session_OneArgs = {
  object: Bikefitting_Bikefitting_Session_Insert_Input;
  on_conflict?: InputMaybe<Bikefitting_Bikefitting_Session_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Commission_AccountArgs = {
  objects: Array<Commission_Account_Insert_Input>;
  on_conflict?: InputMaybe<Commission_Account_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Commission_Account_OneArgs = {
  object: Commission_Account_Insert_Input;
  on_conflict?: InputMaybe<Commission_Account_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Commission_BoostArgs = {
  objects: Array<Commission_Boost_Insert_Input>;
  on_conflict?: InputMaybe<Commission_Boost_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Commission_Boost_OneArgs = {
  object: Commission_Boost_Insert_Input;
  on_conflict?: InputMaybe<Commission_Boost_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Commission_Payout_LogArgs = {
  objects: Array<Commission_Payout_Log_Insert_Input>;
  on_conflict?: InputMaybe<Commission_Payout_Log_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Commission_Payout_Log_OneArgs = {
  object: Commission_Payout_Log_Insert_Input;
  on_conflict?: InputMaybe<Commission_Payout_Log_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Commission_TypeArgs = {
  objects: Array<Commission_Type_Insert_Input>;
  on_conflict?: InputMaybe<Commission_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Commission_Type_OneArgs = {
  object: Commission_Type_Insert_Input;
  on_conflict?: InputMaybe<Commission_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Commission_Webshop_DataArgs = {
  objects: Array<Commission_Webshop_Data_Insert_Input>;
  on_conflict?: InputMaybe<Commission_Webshop_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Commission_Webshop_Data_OneArgs = {
  object: Commission_Webshop_Data_Insert_Input;
  on_conflict?: InputMaybe<Commission_Webshop_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Content_ChaptersArgs = {
  objects: Array<Content_Chapters_Insert_Input>;
  on_conflict?: InputMaybe<Content_Chapters_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Content_Chapters_OneArgs = {
  object: Content_Chapters_Insert_Input;
  on_conflict?: InputMaybe<Content_Chapters_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Content_ChoicesArgs = {
  objects: Array<Content_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Content_Choices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Content_Choices_OneArgs = {
  object: Content_Choices_Insert_Input;
  on_conflict?: InputMaybe<Content_Choices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Content_ContactpointsArgs = {
  objects: Array<Content_Contactpoints_Insert_Input>;
  on_conflict?: InputMaybe<Content_Contactpoints_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Content_Contactpoints_OneArgs = {
  object: Content_Contactpoints_Insert_Input;
  on_conflict?: InputMaybe<Content_Contactpoints_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Content_Lection_RequirementsArgs = {
  objects: Array<Content_Lection_Requirements_Insert_Input>;
  on_conflict?: InputMaybe<Content_Lection_Requirements_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Content_Lection_Requirements_OneArgs = {
  object: Content_Lection_Requirements_Insert_Input;
  on_conflict?: InputMaybe<Content_Lection_Requirements_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Content_LectionsArgs = {
  objects: Array<Content_Lections_Insert_Input>;
  on_conflict?: InputMaybe<Content_Lections_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Content_Lections_OneArgs = {
  object: Content_Lections_Insert_Input;
  on_conflict?: InputMaybe<Content_Lections_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Content_LevelsArgs = {
  objects: Array<Content_Levels_Insert_Input>;
  on_conflict?: InputMaybe<Content_Levels_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Content_Levels_OneArgs = {
  object: Content_Levels_Insert_Input;
  on_conflict?: InputMaybe<Content_Levels_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Content_QuestionsArgs = {
  objects: Array<Content_Questions_Insert_Input>;
  on_conflict?: InputMaybe<Content_Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Content_Questions_OneArgs = {
  object: Content_Questions_Insert_Input;
  on_conflict?: InputMaybe<Content_Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Content_Quiz_TypeArgs = {
  objects: Array<Content_Quiz_Type_Insert_Input>;
  on_conflict?: InputMaybe<Content_Quiz_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Content_Quiz_Type_OneArgs = {
  object: Content_Quiz_Type_Insert_Input;
  on_conflict?: InputMaybe<Content_Quiz_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Content_QuizzesArgs = {
  objects: Array<Content_Quizzes_Insert_Input>;
  on_conflict?: InputMaybe<Content_Quizzes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Content_Quizzes_OneArgs = {
  object: Content_Quizzes_Insert_Input;
  on_conflict?: InputMaybe<Content_Quizzes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Country_CodeArgs = {
  objects: Array<Country_Code_Insert_Input>;
  on_conflict?: InputMaybe<Country_Code_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Country_Code_OneArgs = {
  object: Country_Code_Insert_Input;
  on_conflict?: InputMaybe<Country_Code_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CurrencyArgs = {
  objects: Array<Currency_Insert_Input>;
  on_conflict?: InputMaybe<Currency_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Currency_OneArgs = {
  object: Currency_Insert_Input;
  on_conflict?: InputMaybe<Currency_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Current_UserArgs = {
  objects: Array<Current_User_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Current_User_OneArgs = {
  object: Current_User_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Customer_AnswerArgs = {
  objects: Array<Customer_Answer_Insert_Input>;
  on_conflict?: InputMaybe<Customer_Answer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customer_Answer_Article_EventArgs = {
  objects: Array<Customer_Answer_Article_Event_Insert_Input>;
  on_conflict?: InputMaybe<Customer_Answer_Article_Event_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customer_Answer_Article_Event_OneArgs = {
  object: Customer_Answer_Article_Event_Insert_Input;
  on_conflict?: InputMaybe<Customer_Answer_Article_Event_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customer_Answer_Foot_ProblemsArgs = {
  objects: Array<Customer_Answer_Foot_Problems_Insert_Input>;
  on_conflict?: InputMaybe<Customer_Answer_Foot_Problems_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customer_Answer_Foot_Problems_OneArgs = {
  object: Customer_Answer_Foot_Problems_Insert_Input;
  on_conflict?: InputMaybe<Customer_Answer_Foot_Problems_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customer_Answer_Hand_ProblemsArgs = {
  objects: Array<Customer_Answer_Hand_Problems_Insert_Input>;
  on_conflict?: InputMaybe<Customer_Answer_Hand_Problems_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customer_Answer_Hand_Problems_OneArgs = {
  object: Customer_Answer_Hand_Problems_Insert_Input;
  on_conflict?: InputMaybe<Customer_Answer_Hand_Problems_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customer_Answer_OneArgs = {
  object: Customer_Answer_Insert_Input;
  on_conflict?: InputMaybe<Customer_Answer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customer_Answer_Pelvic_ProblemsArgs = {
  objects: Array<Customer_Answer_Pelvic_Problems_Insert_Input>;
  on_conflict?: InputMaybe<Customer_Answer_Pelvic_Problems_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customer_Answer_Pelvic_Problems_OneArgs = {
  object: Customer_Answer_Pelvic_Problems_Insert_Input;
  on_conflict?: InputMaybe<Customer_Answer_Pelvic_Problems_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customer_Answer_ResultsArgs = {
  objects: Array<Customer_Answer_Results_Insert_Input>;
  on_conflict?: InputMaybe<Customer_Answer_Results_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customer_Answer_Results_OneArgs = {
  object: Customer_Answer_Results_Insert_Input;
  on_conflict?: InputMaybe<Customer_Answer_Results_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customer_Dashboard_EventArgs = {
  objects: Array<Customer_Dashboard_Event_Insert_Input>;
  on_conflict?: InputMaybe<Customer_Dashboard_Event_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customer_Dashboard_Event_KindArgs = {
  objects: Array<Customer_Dashboard_Event_Kind_Insert_Input>;
  on_conflict?: InputMaybe<Customer_Dashboard_Event_Kind_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customer_Dashboard_Event_Kind_OneArgs = {
  object: Customer_Dashboard_Event_Kind_Insert_Input;
  on_conflict?: InputMaybe<Customer_Dashboard_Event_Kind_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customer_Dashboard_Event_OneArgs = {
  object: Customer_Dashboard_Event_Insert_Input;
  on_conflict?: InputMaybe<Customer_Dashboard_Event_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customer_Deleted_AnswersArgs = {
  objects: Array<Customer_Deleted_Answers_Insert_Input>;
  on_conflict?: InputMaybe<Customer_Deleted_Answers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customer_Deleted_Answers_OneArgs = {
  object: Customer_Deleted_Answers_Insert_Input;
  on_conflict?: InputMaybe<Customer_Deleted_Answers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customer_EmailsArgs = {
  objects: Array<Customer_Emails_Insert_Input>;
  on_conflict?: InputMaybe<Customer_Emails_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customer_Emails_OneArgs = {
  object: Customer_Emails_Insert_Input;
  on_conflict?: InputMaybe<Customer_Emails_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customer_RegistrationArgs = {
  objects: Array<Customer_Registration_Insert_Input>;
  on_conflict?: InputMaybe<Customer_Registration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customer_Registration_OneArgs = {
  object: Customer_Registration_Insert_Input;
  on_conflict?: InputMaybe<Customer_Registration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customer_SignatureArgs = {
  objects: Array<Customer_Signature_Insert_Input>;
  on_conflict?: InputMaybe<Customer_Signature_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customer_Signature_OneArgs = {
  object: Customer_Signature_Insert_Input;
  on_conflict?: InputMaybe<Customer_Signature_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customer_VoucherArgs = {
  objects: Array<Customer_Voucher_Insert_Input>;
  on_conflict?: InputMaybe<Customer_Voucher_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customer_Voucher_OneArgs = {
  object: Customer_Voucher_Insert_Input;
  on_conflict?: InputMaybe<Customer_Voucher_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customer_Web_Questionnaire_CompletionArgs = {
  objects: Array<Customer_Web_Questionnaire_Completion_Insert_Input>;
  on_conflict?: InputMaybe<Customer_Web_Questionnaire_Completion_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customer_Web_Questionnaire_Completion_OneArgs = {
  object: Customer_Web_Questionnaire_Completion_Insert_Input;
  on_conflict?: InputMaybe<Customer_Web_Questionnaire_Completion_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Cv_BodyArgs = {
  objects: Array<Cv_Body_Insert_Input>;
  on_conflict?: InputMaybe<Cv_Body_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Cv_Body_OneArgs = {
  object: Cv_Body_Insert_Input;
  on_conflict?: InputMaybe<Cv_Body_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Cv_HandmarksArgs = {
  objects: Array<Cv_Handmarks_Insert_Input>;
  on_conflict?: InputMaybe<Cv_Handmarks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Cv_Handmarks_OneArgs = {
  object: Cv_Handmarks_Insert_Input;
  on_conflict?: InputMaybe<Cv_Handmarks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Cv_SitbonesArgs = {
  objects: Array<Cv_Sitbones_Insert_Input>;
  on_conflict?: InputMaybe<Cv_Sitbones_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Cv_Sitbones_OneArgs = {
  object: Cv_Sitbones_Insert_Input;
  on_conflict?: InputMaybe<Cv_Sitbones_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DistributorArgs = {
  objects: Array<Distributor_Insert_Input>;
  on_conflict?: InputMaybe<Distributor_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Distributor_OneArgs = {
  object: Distributor_Insert_Input;
  on_conflict?: InputMaybe<Distributor_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DistributorsArgs = {
  objects: Array<Distributors_Insert_Input>;
  on_conflict?: InputMaybe<Distributors_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Distributors_OneArgs = {
  object: Distributors_Insert_Input;
  on_conflict?: InputMaybe<Distributors_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Enums_Article_EventArgs = {
  objects: Array<Event_Enums_Article_Event_Insert_Input>;
  on_conflict?: InputMaybe<Event_Enums_Article_Event_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Enums_Article_Event_OneArgs = {
  object: Event_Enums_Article_Event_Insert_Input;
  on_conflict?: InputMaybe<Event_Enums_Article_Event_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Enums_Event_SourceArgs = {
  objects: Array<Event_Enums_Event_Source_Insert_Input>;
  on_conflict?: InputMaybe<Event_Enums_Event_Source_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Enums_Event_Source_OneArgs = {
  object: Event_Enums_Event_Source_Insert_Input;
  on_conflict?: InputMaybe<Event_Enums_Event_Source_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Enums_Log_LevelArgs = {
  objects: Array<Event_Enums_Log_Level_Insert_Input>;
  on_conflict?: InputMaybe<Event_Enums_Log_Level_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Enums_Log_Level_OneArgs = {
  object: Event_Enums_Log_Level_Insert_Input;
  on_conflict?: InputMaybe<Event_Enums_Log_Level_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Log_Event_TypesArgs = {
  objects: Array<Event_Log_Event_Types_Insert_Input>;
  on_conflict?: InputMaybe<Event_Log_Event_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Log_Event_Types_OneArgs = {
  object: Event_Log_Event_Types_Insert_Input;
  on_conflict?: InputMaybe<Event_Log_Event_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Log_EventsArgs = {
  objects: Array<Event_Log_Events_Insert_Input>;
  on_conflict?: InputMaybe<Event_Log_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Log_Events_OneArgs = {
  object: Event_Log_Events_Insert_Input;
  on_conflict?: InputMaybe<Event_Log_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Frontend_Vendor_SupportArgs = {
  objects: Array<Frontend_Vendor_Support_Insert_Input>;
  on_conflict?: InputMaybe<Frontend_Vendor_Support_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Frontend_Vendor_Support_OneArgs = {
  object: Frontend_Vendor_Support_Insert_Input;
  on_conflict?: InputMaybe<Frontend_Vendor_Support_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Iframe_FeedbackArgs = {
  objects: Array<Iframe_Feedback_Insert_Input>;
  on_conflict?: InputMaybe<Iframe_Feedback_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Iframe_Feedback_OneArgs = {
  object: Iframe_Feedback_Insert_Input;
  on_conflict?: InputMaybe<Iframe_Feedback_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Iframe_Newsletter_SubscriptionsArgs = {
  objects: Array<Iframe_Newsletter_Subscriptions_Insert_Input>;
  on_conflict?: InputMaybe<Iframe_Newsletter_Subscriptions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Iframe_Newsletter_Subscriptions_OneArgs = {
  object: Iframe_Newsletter_Subscriptions_Insert_Input;
  on_conflict?: InputMaybe<Iframe_Newsletter_Subscriptions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Iframe_RatingArgs = {
  objects: Array<Iframe_Rating_Insert_Input>;
  on_conflict?: InputMaybe<Iframe_Rating_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Iframe_Rating_OneArgs = {
  object: Iframe_Rating_Insert_Input;
  on_conflict?: InputMaybe<Iframe_Rating_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Iqlab_Rewards_TypesArgs = {
  objects: Array<Iqlab_Rewards_Types_Insert_Input>;
  on_conflict?: InputMaybe<Iqlab_Rewards_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Iqlab_Rewards_Types_OneArgs = {
  object: Iqlab_Rewards_Types_Insert_Input;
  on_conflict?: InputMaybe<Iqlab_Rewards_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Iqlab_Rewards_VouchersArgs = {
  objects: Array<Iqlab_Rewards_Vouchers_Insert_Input>;
  on_conflict?: InputMaybe<Iqlab_Rewards_Vouchers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Iqlab_Rewards_Vouchers_OneArgs = {
  object: Iqlab_Rewards_Vouchers_Insert_Input;
  on_conflict?: InputMaybe<Iqlab_Rewards_Vouchers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Measurement_MeasureArgs = {
  objects: Array<Measurement_Measure_Insert_Input>;
  on_conflict?: InputMaybe<Measurement_Measure_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Measurement_Measure_OneArgs = {
  object: Measurement_Measure_Insert_Input;
  on_conflict?: InputMaybe<Measurement_Measure_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Measurement_Measure_TypeArgs = {
  objects: Array<Measurement_Measure_Type_Insert_Input>;
  on_conflict?: InputMaybe<Measurement_Measure_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Measurement_Measure_Type_OneArgs = {
  object: Measurement_Measure_Type_Insert_Input;
  on_conflict?: InputMaybe<Measurement_Measure_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Measurement_Session_SourceArgs = {
  objects: Array<Measurement_Session_Source_Insert_Input>;
  on_conflict?: InputMaybe<Measurement_Session_Source_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Measurement_Session_Source_OneArgs = {
  object: Measurement_Session_Source_Insert_Input;
  on_conflict?: InputMaybe<Measurement_Session_Source_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Measurement_Session_TypeArgs = {
  objects: Array<Measurement_Session_Type_Insert_Input>;
  on_conflict?: InputMaybe<Measurement_Session_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Measurement_Session_Type_OneArgs = {
  object: Measurement_Session_Type_Insert_Input;
  on_conflict?: InputMaybe<Measurement_Session_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Measurement_SessionsArgs = {
  objects: Array<Measurement_Sessions_Insert_Input>;
  on_conflict?: InputMaybe<Measurement_Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Measurement_Sessions_OneArgs = {
  object: Measurement_Sessions_Insert_Input;
  on_conflict?: InputMaybe<Measurement_Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Metric_Answer_LocationArgs = {
  objects: Array<Metric_Answer_Location_Insert_Input>;
  on_conflict?: InputMaybe<Metric_Answer_Location_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Metric_Answer_Location_OneArgs = {
  object: Metric_Answer_Location_Insert_Input;
  on_conflict?: InputMaybe<Metric_Answer_Location_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Metric_App_EventsArgs = {
  objects: Array<Metric_App_Events_Insert_Input>;
  on_conflict?: InputMaybe<Metric_App_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Metric_App_Events_OneArgs = {
  object: Metric_App_Events_Insert_Input;
  on_conflict?: InputMaybe<Metric_App_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Metric_Question_DurationArgs = {
  objects: Array<Metric_Question_Duration_Insert_Input>;
  on_conflict?: InputMaybe<Metric_Question_Duration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Metric_Question_Duration_OneArgs = {
  object: Metric_Question_Duration_Insert_Input;
  on_conflict?: InputMaybe<Metric_Question_Duration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Metric_Usage_InformationArgs = {
  objects: Array<Metric_Usage_Information_Insert_Input>;
  on_conflict?: InputMaybe<Metric_Usage_Information_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Metric_Usage_Information_OneArgs = {
  object: Metric_Usage_Information_Insert_Input;
  on_conflict?: InputMaybe<Metric_Usage_Information_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Onboarding_EmailsArgs = {
  objects: Array<Onboarding_Emails_Insert_Input>;
  on_conflict?: InputMaybe<Onboarding_Emails_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Onboarding_Emails_OneArgs = {
  object: Onboarding_Emails_Insert_Input;
  on_conflict?: InputMaybe<Onboarding_Emails_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OrganisationArgs = {
  objects: Array<Organisation_Insert_Input>;
  on_conflict?: InputMaybe<Organisation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Organisation_OneArgs = {
  object: Organisation_Insert_Input;
  on_conflict?: InputMaybe<Organisation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Preferences_User_PreferencesArgs = {
  objects: Array<Preferences_User_Preferences_Insert_Input>;
  on_conflict?: InputMaybe<Preferences_User_Preferences_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Preferences_User_Preferences_OneArgs = {
  object: Preferences_User_Preferences_Insert_Input;
  on_conflict?: InputMaybe<Preferences_User_Preferences_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Preferences_Vendor_PreferencesArgs = {
  objects: Array<Preferences_Vendor_Preferences_Insert_Input>;
  on_conflict?: InputMaybe<Preferences_Vendor_Preferences_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Preferences_Vendor_Preferences_OneArgs = {
  object: Preferences_Vendor_Preferences_Insert_Input;
  on_conflict?: InputMaybe<Preferences_Vendor_Preferences_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Progress_Completed_ChaptersArgs = {
  objects: Array<Progress_Completed_Chapters_Insert_Input>;
  on_conflict?: InputMaybe<Progress_Completed_Chapters_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Progress_Completed_Chapters_OneArgs = {
  object: Progress_Completed_Chapters_Insert_Input;
  on_conflict?: InputMaybe<Progress_Completed_Chapters_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Progress_Completed_ContactpointsArgs = {
  objects: Array<Progress_Completed_Contactpoints_Insert_Input>;
  on_conflict?: InputMaybe<Progress_Completed_Contactpoints_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Progress_Completed_Contactpoints_OneArgs = {
  object: Progress_Completed_Contactpoints_Insert_Input;
  on_conflict?: InputMaybe<Progress_Completed_Contactpoints_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Progress_Completed_LectionsArgs = {
  objects: Array<Progress_Completed_Lections_Insert_Input>;
  on_conflict?: InputMaybe<Progress_Completed_Lections_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Progress_Completed_Lections_OneArgs = {
  object: Progress_Completed_Lections_Insert_Input;
  on_conflict?: InputMaybe<Progress_Completed_Lections_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recommendation_Own_ArticlesArgs = {
  objects: Array<Recommendation_Own_Articles_Insert_Input>;
  on_conflict?: InputMaybe<Recommendation_Own_Articles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recommendation_Own_Articles_OneArgs = {
  object: Recommendation_Own_Articles_Insert_Input;
  on_conflict?: InputMaybe<Recommendation_Own_Articles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recommendation_Return_Search_ArticleArgs = {
  objects: Array<Recommendation_Return_Search_Article_Insert_Input>;
  on_conflict?: InputMaybe<Recommendation_Return_Search_Article_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recommendation_Return_Search_Article_OneArgs = {
  object: Recommendation_Return_Search_Article_Insert_Input;
  on_conflict?: InputMaybe<Recommendation_Return_Search_Article_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rewards_Benefit_CategoryArgs = {
  objects: Array<Rewards_Benefit_Category_Insert_Input>;
  on_conflict?: InputMaybe<Rewards_Benefit_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rewards_Benefit_Category_OneArgs = {
  object: Rewards_Benefit_Category_Insert_Input;
  on_conflict?: InputMaybe<Rewards_Benefit_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rewards_BenefitsArgs = {
  objects: Array<Rewards_Benefits_Insert_Input>;
  on_conflict?: InputMaybe<Rewards_Benefits_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rewards_Benefits_OneArgs = {
  object: Rewards_Benefits_Insert_Input;
  on_conflict?: InputMaybe<Rewards_Benefits_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rewards_BoostsArgs = {
  objects: Array<Rewards_Boosts_Insert_Input>;
  on_conflict?: InputMaybe<Rewards_Boosts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rewards_Boosts_OneArgs = {
  object: Rewards_Boosts_Insert_Input;
  on_conflict?: InputMaybe<Rewards_Boosts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rewards_TransactionsArgs = {
  objects: Array<Rewards_Transactions_Insert_Input>;
  on_conflict?: InputMaybe<Rewards_Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rewards_Transactions_OneArgs = {
  object: Rewards_Transactions_Insert_Input;
  on_conflict?: InputMaybe<Rewards_Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SessionsArgs = {
  objects: Array<Sessions_Insert_Input>;
  on_conflict?: InputMaybe<Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sessions_OneArgs = {
  object: Sessions_Insert_Input;
  on_conflict?: InputMaybe<Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sqbase_Event_Log_Event_TypesArgs = {
  objects: Array<Sqbase_Event_Log_Event_Types_Insert_Input>;
  on_conflict?: InputMaybe<Sqbase_Event_Log_Event_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sqbase_Event_Log_Event_Types_OneArgs = {
  object: Sqbase_Event_Log_Event_Types_Insert_Input;
  on_conflict?: InputMaybe<Sqbase_Event_Log_Event_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sqbase_Event_Log_EventsArgs = {
  objects: Array<Sqbase_Event_Log_Events_Insert_Input>;
  on_conflict?: InputMaybe<Sqbase_Event_Log_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sqbase_Event_Log_Events_OneArgs = {
  object: Sqbase_Event_Log_Events_Insert_Input;
  on_conflict?: InputMaybe<Sqbase_Event_Log_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sqlab_ContactsArgs = {
  objects: Array<Sqlab_Contacts_Insert_Input>;
  on_conflict?: InputMaybe<Sqlab_Contacts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sqlab_Contacts_OneArgs = {
  object: Sqlab_Contacts_Insert_Input;
  on_conflict?: InputMaybe<Sqlab_Contacts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Support_InteractionsArgs = {
  objects: Array<Support_Interactions_Insert_Input>;
  on_conflict?: InputMaybe<Support_Interactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Support_Interactions_OneArgs = {
  object: Support_Interactions_Insert_Input;
  on_conflict?: InputMaybe<Support_Interactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Support_Registrations_Without_AnswersArgs = {
  objects: Array<Support_Registrations_Without_Answers_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Support_Registrations_Without_Answers_OneArgs = {
  object: Support_Registrations_Without_Answers_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Support_Vendor_SupportArgs = {
  objects: Array<Support_Vendor_Support_Insert_Input>;
  on_conflict?: InputMaybe<Support_Vendor_Support_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Support_Vendor_Support_OneArgs = {
  object: Support_Vendor_Support_Insert_Input;
  on_conflict?: InputMaybe<Support_Vendor_Support_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Support_Vendor_Support_TypeArgs = {
  objects: Array<Support_Vendor_Support_Type_Insert_Input>;
  on_conflict?: InputMaybe<Support_Vendor_Support_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Support_Vendor_Support_Type_OneArgs = {
  object: Support_Vendor_Support_Type_Insert_Input;
  on_conflict?: InputMaybe<Support_Vendor_Support_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tracker_Article_PricesArgs = {
  objects: Array<Tracker_Article_Prices_Insert_Input>;
  on_conflict?: InputMaybe<Tracker_Article_Prices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tracker_Article_Prices_OneArgs = {
  object: Tracker_Article_Prices_Insert_Input;
  on_conflict?: InputMaybe<Tracker_Article_Prices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_DetailsArgs = {
  objects: Array<User_Details_Insert_Input>;
  on_conflict?: InputMaybe<User_Details_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Details_OneArgs = {
  object: User_Details_Insert_Input;
  on_conflict?: InputMaybe<User_Details_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootRegisterNewUserArgs = {
  emailAddress?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  userName: Scalars['String'];
  vendorId?: InputMaybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootResetPasswordArgs = {
  newPassword: Scalars['String'];
};


/** mutation root */
export type Mutation_RootSendEmailArgs = {
  attachmentUrls?: InputMaybe<Array<Scalars['String']>>;
  bcc?: InputMaybe<Array<EmailRecipient>>;
  parameters?: InputMaybe<Scalars['jsonb']>;
  recipient: EmailRecipient;
  templateId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootSendErgonomicsTicketArgs = {
  emailAddress: Scalars['String'];
  language: Scalars['String'];
  name: Scalars['String'];
  qrCode: Scalars['String'];
};


/** mutation root */
export type Mutation_RootSendLocalizedRecommendationEmailArgs = {
  customerAnswerId: Scalars['String'];
  language: Scalars['String'];
};


/** mutation root */
export type Mutation_RootSendLocalizedRecommendationEmailUnauthenticatedArgs = {
  customerAnswerId: Scalars['String'];
  language?: InputMaybe<Scalars['String']>;
  userId: Scalars['uuid'];
  vendorId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUpdate_Answer_Enums_Bicycle_TypeArgs = {
  _set?: InputMaybe<Answer_Enums_Bicycle_Type_Set_Input>;
  where: Answer_Enums_Bicycle_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Answer_Enums_Bicycle_Type_By_PkArgs = {
  _set?: InputMaybe<Answer_Enums_Bicycle_Type_Set_Input>;
  pk_columns: Answer_Enums_Bicycle_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Answer_Enums_Bicycle_Type_ManyArgs = {
  updates: Array<Answer_Enums_Bicycle_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Answer_Enums_Driving_DurationArgs = {
  _set?: InputMaybe<Answer_Enums_Driving_Duration_Set_Input>;
  where: Answer_Enums_Driving_Duration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Answer_Enums_Driving_Duration_By_PkArgs = {
  _set?: InputMaybe<Answer_Enums_Driving_Duration_Set_Input>;
  pk_columns: Answer_Enums_Driving_Duration_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Answer_Enums_Driving_Duration_ManyArgs = {
  updates: Array<Answer_Enums_Driving_Duration_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Answer_Enums_Driving_FrequencyArgs = {
  _set?: InputMaybe<Answer_Enums_Driving_Frequency_Set_Input>;
  where: Answer_Enums_Driving_Frequency_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Answer_Enums_Driving_Frequency_By_PkArgs = {
  _set?: InputMaybe<Answer_Enums_Driving_Frequency_Set_Input>;
  pk_columns: Answer_Enums_Driving_Frequency_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Answer_Enums_Driving_Frequency_ManyArgs = {
  updates: Array<Answer_Enums_Driving_Frequency_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Answer_Enums_E_BikeArgs = {
  _set?: InputMaybe<Answer_Enums_E_Bike_Set_Input>;
  where: Answer_Enums_E_Bike_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Answer_Enums_E_Bike_By_PkArgs = {
  _set?: InputMaybe<Answer_Enums_E_Bike_Set_Input>;
  pk_columns: Answer_Enums_E_Bike_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Answer_Enums_E_Bike_ManyArgs = {
  updates: Array<Answer_Enums_E_Bike_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Answer_Enums_Foot_PositionArgs = {
  _set?: InputMaybe<Answer_Enums_Foot_Position_Set_Input>;
  where: Answer_Enums_Foot_Position_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Answer_Enums_Foot_Position_By_PkArgs = {
  _set?: InputMaybe<Answer_Enums_Foot_Position_Set_Input>;
  pk_columns: Answer_Enums_Foot_Position_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Answer_Enums_Foot_Position_ManyArgs = {
  updates: Array<Answer_Enums_Foot_Position_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Answer_Enums_Foot_ProblemsArgs = {
  _set?: InputMaybe<Answer_Enums_Foot_Problems_Set_Input>;
  where: Answer_Enums_Foot_Problems_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Answer_Enums_Foot_Problems_By_PkArgs = {
  _set?: InputMaybe<Answer_Enums_Foot_Problems_Set_Input>;
  pk_columns: Answer_Enums_Foot_Problems_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Answer_Enums_Foot_Problems_ManyArgs = {
  updates: Array<Answer_Enums_Foot_Problems_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Answer_Enums_Foot_TypeArgs = {
  _set?: InputMaybe<Answer_Enums_Foot_Type_Set_Input>;
  where: Answer_Enums_Foot_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Answer_Enums_Foot_Type_By_PkArgs = {
  _set?: InputMaybe<Answer_Enums_Foot_Type_Set_Input>;
  pk_columns: Answer_Enums_Foot_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Answer_Enums_Foot_Type_ManyArgs = {
  updates: Array<Answer_Enums_Foot_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Answer_Enums_Gripping_DistanceArgs = {
  _set?: InputMaybe<Answer_Enums_Gripping_Distance_Set_Input>;
  where: Answer_Enums_Gripping_Distance_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Answer_Enums_Gripping_Distance_By_PkArgs = {
  _set?: InputMaybe<Answer_Enums_Gripping_Distance_Set_Input>;
  pk_columns: Answer_Enums_Gripping_Distance_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Answer_Enums_Gripping_Distance_ManyArgs = {
  updates: Array<Answer_Enums_Gripping_Distance_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Answer_Enums_Hand_ProblemsArgs = {
  _set?: InputMaybe<Answer_Enums_Hand_Problems_Set_Input>;
  where: Answer_Enums_Hand_Problems_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Answer_Enums_Hand_Problems_By_PkArgs = {
  _set?: InputMaybe<Answer_Enums_Hand_Problems_Set_Input>;
  pk_columns: Answer_Enums_Hand_Problems_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Answer_Enums_Hand_Problems_ManyArgs = {
  updates: Array<Answer_Enums_Hand_Problems_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Answer_Enums_Leg_AxisArgs = {
  _set?: InputMaybe<Answer_Enums_Leg_Axis_Set_Input>;
  where: Answer_Enums_Leg_Axis_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Answer_Enums_Leg_Axis_By_PkArgs = {
  _set?: InputMaybe<Answer_Enums_Leg_Axis_Set_Input>;
  pk_columns: Answer_Enums_Leg_Axis_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Answer_Enums_Leg_Axis_ManyArgs = {
  updates: Array<Answer_Enums_Leg_Axis_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Answer_Enums_Pelvic_ProblemsArgs = {
  _set?: InputMaybe<Answer_Enums_Pelvic_Problems_Set_Input>;
  where: Answer_Enums_Pelvic_Problems_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Answer_Enums_Pelvic_Problems_By_PkArgs = {
  _set?: InputMaybe<Answer_Enums_Pelvic_Problems_Set_Input>;
  pk_columns: Answer_Enums_Pelvic_Problems_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Answer_Enums_Pelvic_Problems_ManyArgs = {
  updates: Array<Answer_Enums_Pelvic_Problems_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Answer_Enums_Seat_PositionArgs = {
  _set?: InputMaybe<Answer_Enums_Seat_Position_Set_Input>;
  where: Answer_Enums_Seat_Position_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Answer_Enums_Seat_Position_By_PkArgs = {
  _set?: InputMaybe<Answer_Enums_Seat_Position_Set_Input>;
  pk_columns: Answer_Enums_Seat_Position_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Answer_Enums_Seat_Position_ManyArgs = {
  updates: Array<Answer_Enums_Seat_Position_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Answer_Enums_SexArgs = {
  _set?: InputMaybe<Answer_Enums_Sex_Set_Input>;
  where: Answer_Enums_Sex_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Answer_Enums_Sex_By_PkArgs = {
  _set?: InputMaybe<Answer_Enums_Sex_Set_Input>;
  pk_columns: Answer_Enums_Sex_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Answer_Enums_Sex_ManyArgs = {
  updates: Array<Answer_Enums_Sex_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AnswersArgs = {
  _inc?: InputMaybe<Answers_Inc_Input>;
  _set?: InputMaybe<Answers_Set_Input>;
  where: Answers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Answers_By_PkArgs = {
  _inc?: InputMaybe<Answers_Inc_Input>;
  _set?: InputMaybe<Answers_Set_Input>;
  pk_columns: Answers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Answers_ManyArgs = {
  updates: Array<Answers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Article_GroupArgs = {
  _set?: InputMaybe<Article_Group_Set_Input>;
  where: Article_Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Article_Group_By_PkArgs = {
  _set?: InputMaybe<Article_Group_Set_Input>;
  pk_columns: Article_Group_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Article_Group_ManyArgs = {
  updates: Array<Article_Group_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Article_Information_I18nArgs = {
  _append?: InputMaybe<Article_Information_I18n_Append_Input>;
  _delete_at_path?: InputMaybe<Article_Information_I18n_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Article_Information_I18n_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Article_Information_I18n_Delete_Key_Input>;
  _inc?: InputMaybe<Article_Information_I18n_Inc_Input>;
  _prepend?: InputMaybe<Article_Information_I18n_Prepend_Input>;
  _set?: InputMaybe<Article_Information_I18n_Set_Input>;
  where: Article_Information_I18n_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Article_Information_I18n_By_PkArgs = {
  _append?: InputMaybe<Article_Information_I18n_Append_Input>;
  _delete_at_path?: InputMaybe<Article_Information_I18n_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Article_Information_I18n_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Article_Information_I18n_Delete_Key_Input>;
  _inc?: InputMaybe<Article_Information_I18n_Inc_Input>;
  _prepend?: InputMaybe<Article_Information_I18n_Prepend_Input>;
  _set?: InputMaybe<Article_Information_I18n_Set_Input>;
  pk_columns: Article_Information_I18n_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Article_Information_I18n_ManyArgs = {
  updates: Array<Article_Information_I18n_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Article_RankingArgs = {
  _inc?: InputMaybe<Article_Ranking_Inc_Input>;
  _set?: InputMaybe<Article_Ranking_Set_Input>;
  where: Article_Ranking_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Article_Ranking_By_PkArgs = {
  _inc?: InputMaybe<Article_Ranking_Inc_Input>;
  _set?: InputMaybe<Article_Ranking_Set_Input>;
  pk_columns: Article_Ranking_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Article_Ranking_ManyArgs = {
  updates: Array<Article_Ranking_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Article_TypeArgs = {
  _set?: InputMaybe<Article_Type_Set_Input>;
  where: Article_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Article_Type_By_PkArgs = {
  _set?: InputMaybe<Article_Type_Set_Input>;
  pk_columns: Article_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Article_Type_ManyArgs = {
  updates: Array<Article_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_One_Time_TokenArgs = {
  _set?: InputMaybe<Auth_One_Time_Token_Set_Input>;
  where: Auth_One_Time_Token_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_One_Time_Token_By_PkArgs = {
  _set?: InputMaybe<Auth_One_Time_Token_Set_Input>;
  pk_columns: Auth_One_Time_Token_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_One_Time_Token_ManyArgs = {
  updates: Array<Auth_One_Time_Token_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Bikefitting_Bikefitting_SessionArgs = {
  _inc?: InputMaybe<Bikefitting_Bikefitting_Session_Inc_Input>;
  _set?: InputMaybe<Bikefitting_Bikefitting_Session_Set_Input>;
  where: Bikefitting_Bikefitting_Session_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bikefitting_Bikefitting_Session_By_PkArgs = {
  _inc?: InputMaybe<Bikefitting_Bikefitting_Session_Inc_Input>;
  _set?: InputMaybe<Bikefitting_Bikefitting_Session_Set_Input>;
  pk_columns: Bikefitting_Bikefitting_Session_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bikefitting_Bikefitting_Session_ManyArgs = {
  updates: Array<Bikefitting_Bikefitting_Session_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Commission_AccountArgs = {
  _append?: InputMaybe<Commission_Account_Append_Input>;
  _delete_at_path?: InputMaybe<Commission_Account_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Commission_Account_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Commission_Account_Delete_Key_Input>;
  _inc?: InputMaybe<Commission_Account_Inc_Input>;
  _prepend?: InputMaybe<Commission_Account_Prepend_Input>;
  _set?: InputMaybe<Commission_Account_Set_Input>;
  where: Commission_Account_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Commission_Account_By_PkArgs = {
  _append?: InputMaybe<Commission_Account_Append_Input>;
  _delete_at_path?: InputMaybe<Commission_Account_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Commission_Account_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Commission_Account_Delete_Key_Input>;
  _inc?: InputMaybe<Commission_Account_Inc_Input>;
  _prepend?: InputMaybe<Commission_Account_Prepend_Input>;
  _set?: InputMaybe<Commission_Account_Set_Input>;
  pk_columns: Commission_Account_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Commission_Account_ManyArgs = {
  updates: Array<Commission_Account_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Commission_BoostArgs = {
  _inc?: InputMaybe<Commission_Boost_Inc_Input>;
  _set?: InputMaybe<Commission_Boost_Set_Input>;
  where: Commission_Boost_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Commission_Boost_By_PkArgs = {
  _inc?: InputMaybe<Commission_Boost_Inc_Input>;
  _set?: InputMaybe<Commission_Boost_Set_Input>;
  pk_columns: Commission_Boost_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Commission_Boost_ManyArgs = {
  updates: Array<Commission_Boost_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Commission_Payout_LogArgs = {
  _append?: InputMaybe<Commission_Payout_Log_Append_Input>;
  _delete_at_path?: InputMaybe<Commission_Payout_Log_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Commission_Payout_Log_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Commission_Payout_Log_Delete_Key_Input>;
  _inc?: InputMaybe<Commission_Payout_Log_Inc_Input>;
  _prepend?: InputMaybe<Commission_Payout_Log_Prepend_Input>;
  _set?: InputMaybe<Commission_Payout_Log_Set_Input>;
  where: Commission_Payout_Log_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Commission_Payout_Log_By_PkArgs = {
  _append?: InputMaybe<Commission_Payout_Log_Append_Input>;
  _delete_at_path?: InputMaybe<Commission_Payout_Log_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Commission_Payout_Log_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Commission_Payout_Log_Delete_Key_Input>;
  _inc?: InputMaybe<Commission_Payout_Log_Inc_Input>;
  _prepend?: InputMaybe<Commission_Payout_Log_Prepend_Input>;
  _set?: InputMaybe<Commission_Payout_Log_Set_Input>;
  pk_columns: Commission_Payout_Log_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Commission_Payout_Log_ManyArgs = {
  updates: Array<Commission_Payout_Log_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Commission_TypeArgs = {
  _set?: InputMaybe<Commission_Type_Set_Input>;
  where: Commission_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Commission_Type_By_PkArgs = {
  _set?: InputMaybe<Commission_Type_Set_Input>;
  pk_columns: Commission_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Commission_Type_ManyArgs = {
  updates: Array<Commission_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Commission_Webshop_DataArgs = {
  _inc?: InputMaybe<Commission_Webshop_Data_Inc_Input>;
  _set?: InputMaybe<Commission_Webshop_Data_Set_Input>;
  where: Commission_Webshop_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Commission_Webshop_Data_By_PkArgs = {
  _inc?: InputMaybe<Commission_Webshop_Data_Inc_Input>;
  _set?: InputMaybe<Commission_Webshop_Data_Set_Input>;
  pk_columns: Commission_Webshop_Data_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Commission_Webshop_Data_ManyArgs = {
  updates: Array<Commission_Webshop_Data_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Content_ChaptersArgs = {
  _append?: InputMaybe<Content_Chapters_Append_Input>;
  _delete_at_path?: InputMaybe<Content_Chapters_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Content_Chapters_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Content_Chapters_Delete_Key_Input>;
  _inc?: InputMaybe<Content_Chapters_Inc_Input>;
  _prepend?: InputMaybe<Content_Chapters_Prepend_Input>;
  _set?: InputMaybe<Content_Chapters_Set_Input>;
  where: Content_Chapters_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Content_Chapters_By_PkArgs = {
  _append?: InputMaybe<Content_Chapters_Append_Input>;
  _delete_at_path?: InputMaybe<Content_Chapters_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Content_Chapters_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Content_Chapters_Delete_Key_Input>;
  _inc?: InputMaybe<Content_Chapters_Inc_Input>;
  _prepend?: InputMaybe<Content_Chapters_Prepend_Input>;
  _set?: InputMaybe<Content_Chapters_Set_Input>;
  pk_columns: Content_Chapters_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Content_Chapters_ManyArgs = {
  updates: Array<Content_Chapters_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Content_ChoicesArgs = {
  _append?: InputMaybe<Content_Choices_Append_Input>;
  _delete_at_path?: InputMaybe<Content_Choices_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Content_Choices_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Content_Choices_Delete_Key_Input>;
  _inc?: InputMaybe<Content_Choices_Inc_Input>;
  _prepend?: InputMaybe<Content_Choices_Prepend_Input>;
  _set?: InputMaybe<Content_Choices_Set_Input>;
  where: Content_Choices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Content_Choices_By_PkArgs = {
  _append?: InputMaybe<Content_Choices_Append_Input>;
  _delete_at_path?: InputMaybe<Content_Choices_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Content_Choices_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Content_Choices_Delete_Key_Input>;
  _inc?: InputMaybe<Content_Choices_Inc_Input>;
  _prepend?: InputMaybe<Content_Choices_Prepend_Input>;
  _set?: InputMaybe<Content_Choices_Set_Input>;
  pk_columns: Content_Choices_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Content_Choices_ManyArgs = {
  updates: Array<Content_Choices_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Content_ContactpointsArgs = {
  _append?: InputMaybe<Content_Contactpoints_Append_Input>;
  _delete_at_path?: InputMaybe<Content_Contactpoints_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Content_Contactpoints_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Content_Contactpoints_Delete_Key_Input>;
  _inc?: InputMaybe<Content_Contactpoints_Inc_Input>;
  _prepend?: InputMaybe<Content_Contactpoints_Prepend_Input>;
  _set?: InputMaybe<Content_Contactpoints_Set_Input>;
  where: Content_Contactpoints_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Content_Contactpoints_By_PkArgs = {
  _append?: InputMaybe<Content_Contactpoints_Append_Input>;
  _delete_at_path?: InputMaybe<Content_Contactpoints_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Content_Contactpoints_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Content_Contactpoints_Delete_Key_Input>;
  _inc?: InputMaybe<Content_Contactpoints_Inc_Input>;
  _prepend?: InputMaybe<Content_Contactpoints_Prepend_Input>;
  _set?: InputMaybe<Content_Contactpoints_Set_Input>;
  pk_columns: Content_Contactpoints_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Content_Contactpoints_ManyArgs = {
  updates: Array<Content_Contactpoints_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Content_Lection_RequirementsArgs = {
  _inc?: InputMaybe<Content_Lection_Requirements_Inc_Input>;
  _set?: InputMaybe<Content_Lection_Requirements_Set_Input>;
  where: Content_Lection_Requirements_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Content_Lection_Requirements_By_PkArgs = {
  _inc?: InputMaybe<Content_Lection_Requirements_Inc_Input>;
  _set?: InputMaybe<Content_Lection_Requirements_Set_Input>;
  pk_columns: Content_Lection_Requirements_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Content_Lection_Requirements_ManyArgs = {
  updates: Array<Content_Lection_Requirements_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Content_LectionsArgs = {
  _append?: InputMaybe<Content_Lections_Append_Input>;
  _delete_at_path?: InputMaybe<Content_Lections_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Content_Lections_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Content_Lections_Delete_Key_Input>;
  _inc?: InputMaybe<Content_Lections_Inc_Input>;
  _prepend?: InputMaybe<Content_Lections_Prepend_Input>;
  _set?: InputMaybe<Content_Lections_Set_Input>;
  where: Content_Lections_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Content_Lections_By_PkArgs = {
  _append?: InputMaybe<Content_Lections_Append_Input>;
  _delete_at_path?: InputMaybe<Content_Lections_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Content_Lections_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Content_Lections_Delete_Key_Input>;
  _inc?: InputMaybe<Content_Lections_Inc_Input>;
  _prepend?: InputMaybe<Content_Lections_Prepend_Input>;
  _set?: InputMaybe<Content_Lections_Set_Input>;
  pk_columns: Content_Lections_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Content_Lections_ManyArgs = {
  updates: Array<Content_Lections_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Content_LevelsArgs = {
  _append?: InputMaybe<Content_Levels_Append_Input>;
  _delete_at_path?: InputMaybe<Content_Levels_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Content_Levels_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Content_Levels_Delete_Key_Input>;
  _inc?: InputMaybe<Content_Levels_Inc_Input>;
  _prepend?: InputMaybe<Content_Levels_Prepend_Input>;
  _set?: InputMaybe<Content_Levels_Set_Input>;
  where: Content_Levels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Content_Levels_By_PkArgs = {
  _append?: InputMaybe<Content_Levels_Append_Input>;
  _delete_at_path?: InputMaybe<Content_Levels_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Content_Levels_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Content_Levels_Delete_Key_Input>;
  _inc?: InputMaybe<Content_Levels_Inc_Input>;
  _prepend?: InputMaybe<Content_Levels_Prepend_Input>;
  _set?: InputMaybe<Content_Levels_Set_Input>;
  pk_columns: Content_Levels_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Content_Levels_ManyArgs = {
  updates: Array<Content_Levels_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Content_QuestionsArgs = {
  _append?: InputMaybe<Content_Questions_Append_Input>;
  _delete_at_path?: InputMaybe<Content_Questions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Content_Questions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Content_Questions_Delete_Key_Input>;
  _inc?: InputMaybe<Content_Questions_Inc_Input>;
  _prepend?: InputMaybe<Content_Questions_Prepend_Input>;
  _set?: InputMaybe<Content_Questions_Set_Input>;
  where: Content_Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Content_Questions_By_PkArgs = {
  _append?: InputMaybe<Content_Questions_Append_Input>;
  _delete_at_path?: InputMaybe<Content_Questions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Content_Questions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Content_Questions_Delete_Key_Input>;
  _inc?: InputMaybe<Content_Questions_Inc_Input>;
  _prepend?: InputMaybe<Content_Questions_Prepend_Input>;
  _set?: InputMaybe<Content_Questions_Set_Input>;
  pk_columns: Content_Questions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Content_Questions_ManyArgs = {
  updates: Array<Content_Questions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Content_Quiz_TypeArgs = {
  _set?: InputMaybe<Content_Quiz_Type_Set_Input>;
  where: Content_Quiz_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Content_Quiz_Type_By_PkArgs = {
  _set?: InputMaybe<Content_Quiz_Type_Set_Input>;
  pk_columns: Content_Quiz_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Content_Quiz_Type_ManyArgs = {
  updates: Array<Content_Quiz_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Content_QuizzesArgs = {
  _append?: InputMaybe<Content_Quizzes_Append_Input>;
  _delete_at_path?: InputMaybe<Content_Quizzes_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Content_Quizzes_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Content_Quizzes_Delete_Key_Input>;
  _inc?: InputMaybe<Content_Quizzes_Inc_Input>;
  _prepend?: InputMaybe<Content_Quizzes_Prepend_Input>;
  _set?: InputMaybe<Content_Quizzes_Set_Input>;
  where: Content_Quizzes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Content_Quizzes_By_PkArgs = {
  _append?: InputMaybe<Content_Quizzes_Append_Input>;
  _delete_at_path?: InputMaybe<Content_Quizzes_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Content_Quizzes_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Content_Quizzes_Delete_Key_Input>;
  _inc?: InputMaybe<Content_Quizzes_Inc_Input>;
  _prepend?: InputMaybe<Content_Quizzes_Prepend_Input>;
  _set?: InputMaybe<Content_Quizzes_Set_Input>;
  pk_columns: Content_Quizzes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Content_Quizzes_ManyArgs = {
  updates: Array<Content_Quizzes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Country_CodeArgs = {
  _set?: InputMaybe<Country_Code_Set_Input>;
  where: Country_Code_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Country_Code_By_PkArgs = {
  _set?: InputMaybe<Country_Code_Set_Input>;
  pk_columns: Country_Code_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Country_Code_ManyArgs = {
  updates: Array<Country_Code_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CurrencyArgs = {
  _set?: InputMaybe<Currency_Set_Input>;
  where: Currency_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Currency_By_PkArgs = {
  _set?: InputMaybe<Currency_Set_Input>;
  pk_columns: Currency_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Currency_ManyArgs = {
  updates: Array<Currency_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Current_UserArgs = {
  _set?: InputMaybe<Current_User_Set_Input>;
  where: Current_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Current_User_ManyArgs = {
  updates: Array<Current_User_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_AnswerArgs = {
  _append?: InputMaybe<Customer_Answer_Append_Input>;
  _delete_at_path?: InputMaybe<Customer_Answer_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Customer_Answer_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Customer_Answer_Delete_Key_Input>;
  _inc?: InputMaybe<Customer_Answer_Inc_Input>;
  _prepend?: InputMaybe<Customer_Answer_Prepend_Input>;
  _set?: InputMaybe<Customer_Answer_Set_Input>;
  where: Customer_Answer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Answer_Article_EventArgs = {
  _set?: InputMaybe<Customer_Answer_Article_Event_Set_Input>;
  where: Customer_Answer_Article_Event_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Answer_Article_Event_By_PkArgs = {
  _set?: InputMaybe<Customer_Answer_Article_Event_Set_Input>;
  pk_columns: Customer_Answer_Article_Event_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Answer_Article_Event_ManyArgs = {
  updates: Array<Customer_Answer_Article_Event_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Answer_By_PkArgs = {
  _append?: InputMaybe<Customer_Answer_Append_Input>;
  _delete_at_path?: InputMaybe<Customer_Answer_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Customer_Answer_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Customer_Answer_Delete_Key_Input>;
  _inc?: InputMaybe<Customer_Answer_Inc_Input>;
  _prepend?: InputMaybe<Customer_Answer_Prepend_Input>;
  _set?: InputMaybe<Customer_Answer_Set_Input>;
  pk_columns: Customer_Answer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Answer_Foot_ProblemsArgs = {
  _set?: InputMaybe<Customer_Answer_Foot_Problems_Set_Input>;
  where: Customer_Answer_Foot_Problems_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Answer_Foot_Problems_By_PkArgs = {
  _set?: InputMaybe<Customer_Answer_Foot_Problems_Set_Input>;
  pk_columns: Customer_Answer_Foot_Problems_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Answer_Foot_Problems_ManyArgs = {
  updates: Array<Customer_Answer_Foot_Problems_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Answer_Hand_ProblemsArgs = {
  _set?: InputMaybe<Customer_Answer_Hand_Problems_Set_Input>;
  where: Customer_Answer_Hand_Problems_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Answer_Hand_Problems_By_PkArgs = {
  _set?: InputMaybe<Customer_Answer_Hand_Problems_Set_Input>;
  pk_columns: Customer_Answer_Hand_Problems_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Answer_Hand_Problems_ManyArgs = {
  updates: Array<Customer_Answer_Hand_Problems_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Answer_ManyArgs = {
  updates: Array<Customer_Answer_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Answer_Pelvic_ProblemsArgs = {
  _set?: InputMaybe<Customer_Answer_Pelvic_Problems_Set_Input>;
  where: Customer_Answer_Pelvic_Problems_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Answer_Pelvic_Problems_By_PkArgs = {
  _set?: InputMaybe<Customer_Answer_Pelvic_Problems_Set_Input>;
  pk_columns: Customer_Answer_Pelvic_Problems_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Answer_Pelvic_Problems_ManyArgs = {
  updates: Array<Customer_Answer_Pelvic_Problems_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Answer_ResultsArgs = {
  _inc?: InputMaybe<Customer_Answer_Results_Inc_Input>;
  _set?: InputMaybe<Customer_Answer_Results_Set_Input>;
  where: Customer_Answer_Results_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Answer_Results_By_PkArgs = {
  _inc?: InputMaybe<Customer_Answer_Results_Inc_Input>;
  _set?: InputMaybe<Customer_Answer_Results_Set_Input>;
  pk_columns: Customer_Answer_Results_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Answer_Results_ManyArgs = {
  updates: Array<Customer_Answer_Results_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Dashboard_EventArgs = {
  _append?: InputMaybe<Customer_Dashboard_Event_Append_Input>;
  _delete_at_path?: InputMaybe<Customer_Dashboard_Event_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Customer_Dashboard_Event_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Customer_Dashboard_Event_Delete_Key_Input>;
  _prepend?: InputMaybe<Customer_Dashboard_Event_Prepend_Input>;
  _set?: InputMaybe<Customer_Dashboard_Event_Set_Input>;
  where: Customer_Dashboard_Event_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Dashboard_Event_By_PkArgs = {
  _append?: InputMaybe<Customer_Dashboard_Event_Append_Input>;
  _delete_at_path?: InputMaybe<Customer_Dashboard_Event_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Customer_Dashboard_Event_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Customer_Dashboard_Event_Delete_Key_Input>;
  _prepend?: InputMaybe<Customer_Dashboard_Event_Prepend_Input>;
  _set?: InputMaybe<Customer_Dashboard_Event_Set_Input>;
  pk_columns: Customer_Dashboard_Event_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Dashboard_Event_KindArgs = {
  _set?: InputMaybe<Customer_Dashboard_Event_Kind_Set_Input>;
  where: Customer_Dashboard_Event_Kind_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Dashboard_Event_Kind_By_PkArgs = {
  _set?: InputMaybe<Customer_Dashboard_Event_Kind_Set_Input>;
  pk_columns: Customer_Dashboard_Event_Kind_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Dashboard_Event_Kind_ManyArgs = {
  updates: Array<Customer_Dashboard_Event_Kind_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Dashboard_Event_ManyArgs = {
  updates: Array<Customer_Dashboard_Event_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Deleted_AnswersArgs = {
  _set?: InputMaybe<Customer_Deleted_Answers_Set_Input>;
  where: Customer_Deleted_Answers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Deleted_Answers_By_PkArgs = {
  _set?: InputMaybe<Customer_Deleted_Answers_Set_Input>;
  pk_columns: Customer_Deleted_Answers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Deleted_Answers_ManyArgs = {
  updates: Array<Customer_Deleted_Answers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_EmailsArgs = {
  _set?: InputMaybe<Customer_Emails_Set_Input>;
  where: Customer_Emails_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Emails_By_PkArgs = {
  _set?: InputMaybe<Customer_Emails_Set_Input>;
  pk_columns: Customer_Emails_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Emails_ManyArgs = {
  updates: Array<Customer_Emails_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_RegistrationArgs = {
  _inc?: InputMaybe<Customer_Registration_Inc_Input>;
  _set?: InputMaybe<Customer_Registration_Set_Input>;
  where: Customer_Registration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Registration_By_PkArgs = {
  _inc?: InputMaybe<Customer_Registration_Inc_Input>;
  _set?: InputMaybe<Customer_Registration_Set_Input>;
  pk_columns: Customer_Registration_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Registration_ManyArgs = {
  updates: Array<Customer_Registration_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_SignatureArgs = {
  _append?: InputMaybe<Customer_Signature_Append_Input>;
  _delete_at_path?: InputMaybe<Customer_Signature_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Customer_Signature_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Customer_Signature_Delete_Key_Input>;
  _inc?: InputMaybe<Customer_Signature_Inc_Input>;
  _prepend?: InputMaybe<Customer_Signature_Prepend_Input>;
  _set?: InputMaybe<Customer_Signature_Set_Input>;
  where: Customer_Signature_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Signature_By_PkArgs = {
  _append?: InputMaybe<Customer_Signature_Append_Input>;
  _delete_at_path?: InputMaybe<Customer_Signature_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Customer_Signature_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Customer_Signature_Delete_Key_Input>;
  _inc?: InputMaybe<Customer_Signature_Inc_Input>;
  _prepend?: InputMaybe<Customer_Signature_Prepend_Input>;
  _set?: InputMaybe<Customer_Signature_Set_Input>;
  pk_columns: Customer_Signature_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Signature_ManyArgs = {
  updates: Array<Customer_Signature_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_VoucherArgs = {
  _inc?: InputMaybe<Customer_Voucher_Inc_Input>;
  _set?: InputMaybe<Customer_Voucher_Set_Input>;
  where: Customer_Voucher_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Voucher_By_PkArgs = {
  _inc?: InputMaybe<Customer_Voucher_Inc_Input>;
  _set?: InputMaybe<Customer_Voucher_Set_Input>;
  pk_columns: Customer_Voucher_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Voucher_ManyArgs = {
  updates: Array<Customer_Voucher_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Web_Questionnaire_CompletionArgs = {
  _set?: InputMaybe<Customer_Web_Questionnaire_Completion_Set_Input>;
  where: Customer_Web_Questionnaire_Completion_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Web_Questionnaire_Completion_By_PkArgs = {
  _set?: InputMaybe<Customer_Web_Questionnaire_Completion_Set_Input>;
  pk_columns: Customer_Web_Questionnaire_Completion_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Web_Questionnaire_Completion_ManyArgs = {
  updates: Array<Customer_Web_Questionnaire_Completion_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Cv_BodyArgs = {
  _append?: InputMaybe<Cv_Body_Append_Input>;
  _delete_at_path?: InputMaybe<Cv_Body_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Cv_Body_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Cv_Body_Delete_Key_Input>;
  _inc?: InputMaybe<Cv_Body_Inc_Input>;
  _prepend?: InputMaybe<Cv_Body_Prepend_Input>;
  _set?: InputMaybe<Cv_Body_Set_Input>;
  where: Cv_Body_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Cv_Body_By_PkArgs = {
  _append?: InputMaybe<Cv_Body_Append_Input>;
  _delete_at_path?: InputMaybe<Cv_Body_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Cv_Body_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Cv_Body_Delete_Key_Input>;
  _inc?: InputMaybe<Cv_Body_Inc_Input>;
  _prepend?: InputMaybe<Cv_Body_Prepend_Input>;
  _set?: InputMaybe<Cv_Body_Set_Input>;
  pk_columns: Cv_Body_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Cv_Body_ManyArgs = {
  updates: Array<Cv_Body_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Cv_HandmarksArgs = {
  _append?: InputMaybe<Cv_Handmarks_Append_Input>;
  _delete_at_path?: InputMaybe<Cv_Handmarks_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Cv_Handmarks_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Cv_Handmarks_Delete_Key_Input>;
  _inc?: InputMaybe<Cv_Handmarks_Inc_Input>;
  _prepend?: InputMaybe<Cv_Handmarks_Prepend_Input>;
  _set?: InputMaybe<Cv_Handmarks_Set_Input>;
  where: Cv_Handmarks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Cv_Handmarks_By_PkArgs = {
  _append?: InputMaybe<Cv_Handmarks_Append_Input>;
  _delete_at_path?: InputMaybe<Cv_Handmarks_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Cv_Handmarks_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Cv_Handmarks_Delete_Key_Input>;
  _inc?: InputMaybe<Cv_Handmarks_Inc_Input>;
  _prepend?: InputMaybe<Cv_Handmarks_Prepend_Input>;
  _set?: InputMaybe<Cv_Handmarks_Set_Input>;
  pk_columns: Cv_Handmarks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Cv_Handmarks_ManyArgs = {
  updates: Array<Cv_Handmarks_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Cv_SitbonesArgs = {
  _append?: InputMaybe<Cv_Sitbones_Append_Input>;
  _delete_at_path?: InputMaybe<Cv_Sitbones_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Cv_Sitbones_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Cv_Sitbones_Delete_Key_Input>;
  _inc?: InputMaybe<Cv_Sitbones_Inc_Input>;
  _prepend?: InputMaybe<Cv_Sitbones_Prepend_Input>;
  _set?: InputMaybe<Cv_Sitbones_Set_Input>;
  where: Cv_Sitbones_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Cv_Sitbones_By_PkArgs = {
  _append?: InputMaybe<Cv_Sitbones_Append_Input>;
  _delete_at_path?: InputMaybe<Cv_Sitbones_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Cv_Sitbones_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Cv_Sitbones_Delete_Key_Input>;
  _inc?: InputMaybe<Cv_Sitbones_Inc_Input>;
  _prepend?: InputMaybe<Cv_Sitbones_Prepend_Input>;
  _set?: InputMaybe<Cv_Sitbones_Set_Input>;
  pk_columns: Cv_Sitbones_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Cv_Sitbones_ManyArgs = {
  updates: Array<Cv_Sitbones_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_DistributorArgs = {
  _set?: InputMaybe<Distributor_Set_Input>;
  where: Distributor_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Distributor_By_PkArgs = {
  _set?: InputMaybe<Distributor_Set_Input>;
  pk_columns: Distributor_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Distributor_ManyArgs = {
  updates: Array<Distributor_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_DistributorsArgs = {
  _set?: InputMaybe<Distributors_Set_Input>;
  where: Distributors_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Distributors_By_PkArgs = {
  _set?: InputMaybe<Distributors_Set_Input>;
  pk_columns: Distributors_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Distributors_ManyArgs = {
  updates: Array<Distributors_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Enums_Article_EventArgs = {
  _set?: InputMaybe<Event_Enums_Article_Event_Set_Input>;
  where: Event_Enums_Article_Event_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Enums_Article_Event_By_PkArgs = {
  _set?: InputMaybe<Event_Enums_Article_Event_Set_Input>;
  pk_columns: Event_Enums_Article_Event_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Enums_Article_Event_ManyArgs = {
  updates: Array<Event_Enums_Article_Event_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Enums_Event_SourceArgs = {
  _set?: InputMaybe<Event_Enums_Event_Source_Set_Input>;
  where: Event_Enums_Event_Source_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Enums_Event_Source_By_PkArgs = {
  _set?: InputMaybe<Event_Enums_Event_Source_Set_Input>;
  pk_columns: Event_Enums_Event_Source_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Enums_Event_Source_ManyArgs = {
  updates: Array<Event_Enums_Event_Source_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Enums_Log_LevelArgs = {
  _set?: InputMaybe<Event_Enums_Log_Level_Set_Input>;
  where: Event_Enums_Log_Level_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Enums_Log_Level_By_PkArgs = {
  _set?: InputMaybe<Event_Enums_Log_Level_Set_Input>;
  pk_columns: Event_Enums_Log_Level_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Enums_Log_Level_ManyArgs = {
  updates: Array<Event_Enums_Log_Level_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Log_Event_TypesArgs = {
  _set?: InputMaybe<Event_Log_Event_Types_Set_Input>;
  where: Event_Log_Event_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Log_Event_Types_By_PkArgs = {
  _set?: InputMaybe<Event_Log_Event_Types_Set_Input>;
  pk_columns: Event_Log_Event_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Log_Event_Types_ManyArgs = {
  updates: Array<Event_Log_Event_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Log_EventsArgs = {
  _append?: InputMaybe<Event_Log_Events_Append_Input>;
  _delete_at_path?: InputMaybe<Event_Log_Events_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Event_Log_Events_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Event_Log_Events_Delete_Key_Input>;
  _prepend?: InputMaybe<Event_Log_Events_Prepend_Input>;
  _set?: InputMaybe<Event_Log_Events_Set_Input>;
  where: Event_Log_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Log_Events_By_PkArgs = {
  _append?: InputMaybe<Event_Log_Events_Append_Input>;
  _delete_at_path?: InputMaybe<Event_Log_Events_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Event_Log_Events_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Event_Log_Events_Delete_Key_Input>;
  _prepend?: InputMaybe<Event_Log_Events_Prepend_Input>;
  _set?: InputMaybe<Event_Log_Events_Set_Input>;
  pk_columns: Event_Log_Events_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Log_Events_ManyArgs = {
  updates: Array<Event_Log_Events_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Frontend_Vendor_SupportArgs = {
  _inc?: InputMaybe<Frontend_Vendor_Support_Inc_Input>;
  _set?: InputMaybe<Frontend_Vendor_Support_Set_Input>;
  where: Frontend_Vendor_Support_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Frontend_Vendor_Support_By_PkArgs = {
  _inc?: InputMaybe<Frontend_Vendor_Support_Inc_Input>;
  _set?: InputMaybe<Frontend_Vendor_Support_Set_Input>;
  pk_columns: Frontend_Vendor_Support_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Frontend_Vendor_Support_ManyArgs = {
  updates: Array<Frontend_Vendor_Support_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Iframe_FeedbackArgs = {
  _set?: InputMaybe<Iframe_Feedback_Set_Input>;
  where: Iframe_Feedback_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Iframe_Feedback_By_PkArgs = {
  _set?: InputMaybe<Iframe_Feedback_Set_Input>;
  pk_columns: Iframe_Feedback_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Iframe_Feedback_ManyArgs = {
  updates: Array<Iframe_Feedback_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Iframe_Newsletter_SubscriptionsArgs = {
  _set?: InputMaybe<Iframe_Newsletter_Subscriptions_Set_Input>;
  where: Iframe_Newsletter_Subscriptions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Iframe_Newsletter_Subscriptions_By_PkArgs = {
  _set?: InputMaybe<Iframe_Newsletter_Subscriptions_Set_Input>;
  pk_columns: Iframe_Newsletter_Subscriptions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Iframe_Newsletter_Subscriptions_ManyArgs = {
  updates: Array<Iframe_Newsletter_Subscriptions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Iframe_RatingArgs = {
  _set?: InputMaybe<Iframe_Rating_Set_Input>;
  where: Iframe_Rating_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Iframe_Rating_By_PkArgs = {
  _set?: InputMaybe<Iframe_Rating_Set_Input>;
  pk_columns: Iframe_Rating_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Iframe_Rating_ManyArgs = {
  updates: Array<Iframe_Rating_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Iqlab_Rewards_TypesArgs = {
  _set?: InputMaybe<Iqlab_Rewards_Types_Set_Input>;
  where: Iqlab_Rewards_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Iqlab_Rewards_Types_By_PkArgs = {
  _set?: InputMaybe<Iqlab_Rewards_Types_Set_Input>;
  pk_columns: Iqlab_Rewards_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Iqlab_Rewards_Types_ManyArgs = {
  updates: Array<Iqlab_Rewards_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Iqlab_Rewards_VouchersArgs = {
  _set?: InputMaybe<Iqlab_Rewards_Vouchers_Set_Input>;
  where: Iqlab_Rewards_Vouchers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Iqlab_Rewards_Vouchers_By_PkArgs = {
  _set?: InputMaybe<Iqlab_Rewards_Vouchers_Set_Input>;
  pk_columns: Iqlab_Rewards_Vouchers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Iqlab_Rewards_Vouchers_ManyArgs = {
  updates: Array<Iqlab_Rewards_Vouchers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Measurement_MeasureArgs = {
  _inc?: InputMaybe<Measurement_Measure_Inc_Input>;
  _set?: InputMaybe<Measurement_Measure_Set_Input>;
  where: Measurement_Measure_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Measurement_Measure_By_PkArgs = {
  _inc?: InputMaybe<Measurement_Measure_Inc_Input>;
  _set?: InputMaybe<Measurement_Measure_Set_Input>;
  pk_columns: Measurement_Measure_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Measurement_Measure_ManyArgs = {
  updates: Array<Measurement_Measure_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Measurement_Measure_TypeArgs = {
  _set?: InputMaybe<Measurement_Measure_Type_Set_Input>;
  where: Measurement_Measure_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Measurement_Measure_Type_By_PkArgs = {
  _set?: InputMaybe<Measurement_Measure_Type_Set_Input>;
  pk_columns: Measurement_Measure_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Measurement_Measure_Type_ManyArgs = {
  updates: Array<Measurement_Measure_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Measurement_Session_SourceArgs = {
  _set?: InputMaybe<Measurement_Session_Source_Set_Input>;
  where: Measurement_Session_Source_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Measurement_Session_Source_By_PkArgs = {
  _set?: InputMaybe<Measurement_Session_Source_Set_Input>;
  pk_columns: Measurement_Session_Source_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Measurement_Session_Source_ManyArgs = {
  updates: Array<Measurement_Session_Source_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Measurement_Session_TypeArgs = {
  _set?: InputMaybe<Measurement_Session_Type_Set_Input>;
  where: Measurement_Session_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Measurement_Session_Type_By_PkArgs = {
  _set?: InputMaybe<Measurement_Session_Type_Set_Input>;
  pk_columns: Measurement_Session_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Measurement_Session_Type_ManyArgs = {
  updates: Array<Measurement_Session_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Measurement_SessionsArgs = {
  _append?: InputMaybe<Measurement_Sessions_Append_Input>;
  _delete_at_path?: InputMaybe<Measurement_Sessions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Measurement_Sessions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Measurement_Sessions_Delete_Key_Input>;
  _inc?: InputMaybe<Measurement_Sessions_Inc_Input>;
  _prepend?: InputMaybe<Measurement_Sessions_Prepend_Input>;
  _set?: InputMaybe<Measurement_Sessions_Set_Input>;
  where: Measurement_Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Measurement_Sessions_By_PkArgs = {
  _append?: InputMaybe<Measurement_Sessions_Append_Input>;
  _delete_at_path?: InputMaybe<Measurement_Sessions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Measurement_Sessions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Measurement_Sessions_Delete_Key_Input>;
  _inc?: InputMaybe<Measurement_Sessions_Inc_Input>;
  _prepend?: InputMaybe<Measurement_Sessions_Prepend_Input>;
  _set?: InputMaybe<Measurement_Sessions_Set_Input>;
  pk_columns: Measurement_Sessions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Measurement_Sessions_ManyArgs = {
  updates: Array<Measurement_Sessions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Metric_Answer_LocationArgs = {
  _set?: InputMaybe<Metric_Answer_Location_Set_Input>;
  where: Metric_Answer_Location_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Metric_Answer_Location_By_PkArgs = {
  _set?: InputMaybe<Metric_Answer_Location_Set_Input>;
  pk_columns: Metric_Answer_Location_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Metric_Answer_Location_ManyArgs = {
  updates: Array<Metric_Answer_Location_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Metric_App_EventsArgs = {
  _append?: InputMaybe<Metric_App_Events_Append_Input>;
  _delete_at_path?: InputMaybe<Metric_App_Events_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Metric_App_Events_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Metric_App_Events_Delete_Key_Input>;
  _prepend?: InputMaybe<Metric_App_Events_Prepend_Input>;
  _set?: InputMaybe<Metric_App_Events_Set_Input>;
  where: Metric_App_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Metric_App_Events_By_PkArgs = {
  _append?: InputMaybe<Metric_App_Events_Append_Input>;
  _delete_at_path?: InputMaybe<Metric_App_Events_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Metric_App_Events_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Metric_App_Events_Delete_Key_Input>;
  _prepend?: InputMaybe<Metric_App_Events_Prepend_Input>;
  _set?: InputMaybe<Metric_App_Events_Set_Input>;
  pk_columns: Metric_App_Events_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Metric_App_Events_ManyArgs = {
  updates: Array<Metric_App_Events_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Metric_Question_DurationArgs = {
  _inc?: InputMaybe<Metric_Question_Duration_Inc_Input>;
  _set?: InputMaybe<Metric_Question_Duration_Set_Input>;
  where: Metric_Question_Duration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Metric_Question_Duration_By_PkArgs = {
  _inc?: InputMaybe<Metric_Question_Duration_Inc_Input>;
  _set?: InputMaybe<Metric_Question_Duration_Set_Input>;
  pk_columns: Metric_Question_Duration_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Metric_Question_Duration_ManyArgs = {
  updates: Array<Metric_Question_Duration_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Metric_Usage_InformationArgs = {
  _set?: InputMaybe<Metric_Usage_Information_Set_Input>;
  where: Metric_Usage_Information_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Metric_Usage_Information_By_PkArgs = {
  _set?: InputMaybe<Metric_Usage_Information_Set_Input>;
  pk_columns: Metric_Usage_Information_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Metric_Usage_Information_ManyArgs = {
  updates: Array<Metric_Usage_Information_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Onboarding_EmailsArgs = {
  _inc?: InputMaybe<Onboarding_Emails_Inc_Input>;
  _set?: InputMaybe<Onboarding_Emails_Set_Input>;
  where: Onboarding_Emails_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Onboarding_Emails_By_PkArgs = {
  _inc?: InputMaybe<Onboarding_Emails_Inc_Input>;
  _set?: InputMaybe<Onboarding_Emails_Set_Input>;
  pk_columns: Onboarding_Emails_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Onboarding_Emails_ManyArgs = {
  updates: Array<Onboarding_Emails_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_OrganisationArgs = {
  _append?: InputMaybe<Organisation_Append_Input>;
  _delete_at_path?: InputMaybe<Organisation_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Organisation_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Organisation_Delete_Key_Input>;
  _inc?: InputMaybe<Organisation_Inc_Input>;
  _prepend?: InputMaybe<Organisation_Prepend_Input>;
  _set?: InputMaybe<Organisation_Set_Input>;
  where: Organisation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Organisation_By_PkArgs = {
  _append?: InputMaybe<Organisation_Append_Input>;
  _delete_at_path?: InputMaybe<Organisation_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Organisation_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Organisation_Delete_Key_Input>;
  _inc?: InputMaybe<Organisation_Inc_Input>;
  _prepend?: InputMaybe<Organisation_Prepend_Input>;
  _set?: InputMaybe<Organisation_Set_Input>;
  pk_columns: Organisation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Organisation_ManyArgs = {
  updates: Array<Organisation_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Preferences_User_PreferencesArgs = {
  _append?: InputMaybe<Preferences_User_Preferences_Append_Input>;
  _delete_at_path?: InputMaybe<Preferences_User_Preferences_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Preferences_User_Preferences_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Preferences_User_Preferences_Delete_Key_Input>;
  _prepend?: InputMaybe<Preferences_User_Preferences_Prepend_Input>;
  _set?: InputMaybe<Preferences_User_Preferences_Set_Input>;
  where: Preferences_User_Preferences_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Preferences_User_Preferences_By_PkArgs = {
  _append?: InputMaybe<Preferences_User_Preferences_Append_Input>;
  _delete_at_path?: InputMaybe<Preferences_User_Preferences_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Preferences_User_Preferences_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Preferences_User_Preferences_Delete_Key_Input>;
  _prepend?: InputMaybe<Preferences_User_Preferences_Prepend_Input>;
  _set?: InputMaybe<Preferences_User_Preferences_Set_Input>;
  pk_columns: Preferences_User_Preferences_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Preferences_User_Preferences_ManyArgs = {
  updates: Array<Preferences_User_Preferences_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Preferences_Vendor_PreferencesArgs = {
  _append?: InputMaybe<Preferences_Vendor_Preferences_Append_Input>;
  _delete_at_path?: InputMaybe<Preferences_Vendor_Preferences_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Preferences_Vendor_Preferences_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Preferences_Vendor_Preferences_Delete_Key_Input>;
  _prepend?: InputMaybe<Preferences_Vendor_Preferences_Prepend_Input>;
  _set?: InputMaybe<Preferences_Vendor_Preferences_Set_Input>;
  where: Preferences_Vendor_Preferences_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Preferences_Vendor_Preferences_By_PkArgs = {
  _append?: InputMaybe<Preferences_Vendor_Preferences_Append_Input>;
  _delete_at_path?: InputMaybe<Preferences_Vendor_Preferences_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Preferences_Vendor_Preferences_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Preferences_Vendor_Preferences_Delete_Key_Input>;
  _prepend?: InputMaybe<Preferences_Vendor_Preferences_Prepend_Input>;
  _set?: InputMaybe<Preferences_Vendor_Preferences_Set_Input>;
  pk_columns: Preferences_Vendor_Preferences_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Preferences_Vendor_Preferences_ManyArgs = {
  updates: Array<Preferences_Vendor_Preferences_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Progress_Completed_ChaptersArgs = {
  _inc?: InputMaybe<Progress_Completed_Chapters_Inc_Input>;
  _set?: InputMaybe<Progress_Completed_Chapters_Set_Input>;
  where: Progress_Completed_Chapters_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Progress_Completed_Chapters_By_PkArgs = {
  _inc?: InputMaybe<Progress_Completed_Chapters_Inc_Input>;
  _set?: InputMaybe<Progress_Completed_Chapters_Set_Input>;
  pk_columns: Progress_Completed_Chapters_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Progress_Completed_Chapters_ManyArgs = {
  updates: Array<Progress_Completed_Chapters_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Progress_Completed_ContactpointsArgs = {
  _inc?: InputMaybe<Progress_Completed_Contactpoints_Inc_Input>;
  _set?: InputMaybe<Progress_Completed_Contactpoints_Set_Input>;
  where: Progress_Completed_Contactpoints_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Progress_Completed_Contactpoints_By_PkArgs = {
  _inc?: InputMaybe<Progress_Completed_Contactpoints_Inc_Input>;
  _set?: InputMaybe<Progress_Completed_Contactpoints_Set_Input>;
  pk_columns: Progress_Completed_Contactpoints_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Progress_Completed_Contactpoints_ManyArgs = {
  updates: Array<Progress_Completed_Contactpoints_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Progress_Completed_LectionsArgs = {
  _inc?: InputMaybe<Progress_Completed_Lections_Inc_Input>;
  _set?: InputMaybe<Progress_Completed_Lections_Set_Input>;
  where: Progress_Completed_Lections_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Progress_Completed_Lections_By_PkArgs = {
  _inc?: InputMaybe<Progress_Completed_Lections_Inc_Input>;
  _set?: InputMaybe<Progress_Completed_Lections_Set_Input>;
  pk_columns: Progress_Completed_Lections_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Progress_Completed_Lections_ManyArgs = {
  updates: Array<Progress_Completed_Lections_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Recommendation_Own_ArticlesArgs = {
  _append?: InputMaybe<Recommendation_Own_Articles_Append_Input>;
  _delete_at_path?: InputMaybe<Recommendation_Own_Articles_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Recommendation_Own_Articles_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Recommendation_Own_Articles_Delete_Key_Input>;
  _inc?: InputMaybe<Recommendation_Own_Articles_Inc_Input>;
  _prepend?: InputMaybe<Recommendation_Own_Articles_Prepend_Input>;
  _set?: InputMaybe<Recommendation_Own_Articles_Set_Input>;
  where: Recommendation_Own_Articles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Recommendation_Own_Articles_By_PkArgs = {
  _append?: InputMaybe<Recommendation_Own_Articles_Append_Input>;
  _delete_at_path?: InputMaybe<Recommendation_Own_Articles_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Recommendation_Own_Articles_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Recommendation_Own_Articles_Delete_Key_Input>;
  _inc?: InputMaybe<Recommendation_Own_Articles_Inc_Input>;
  _prepend?: InputMaybe<Recommendation_Own_Articles_Prepend_Input>;
  _set?: InputMaybe<Recommendation_Own_Articles_Set_Input>;
  pk_columns: Recommendation_Own_Articles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Recommendation_Own_Articles_ManyArgs = {
  updates: Array<Recommendation_Own_Articles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Recommendation_Return_Search_ArticleArgs = {
  _append?: InputMaybe<Recommendation_Return_Search_Article_Append_Input>;
  _delete_at_path?: InputMaybe<Recommendation_Return_Search_Article_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Recommendation_Return_Search_Article_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Recommendation_Return_Search_Article_Delete_Key_Input>;
  _inc?: InputMaybe<Recommendation_Return_Search_Article_Inc_Input>;
  _prepend?: InputMaybe<Recommendation_Return_Search_Article_Prepend_Input>;
  _set?: InputMaybe<Recommendation_Return_Search_Article_Set_Input>;
  where: Recommendation_Return_Search_Article_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Recommendation_Return_Search_Article_By_PkArgs = {
  _append?: InputMaybe<Recommendation_Return_Search_Article_Append_Input>;
  _delete_at_path?: InputMaybe<Recommendation_Return_Search_Article_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Recommendation_Return_Search_Article_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Recommendation_Return_Search_Article_Delete_Key_Input>;
  _inc?: InputMaybe<Recommendation_Return_Search_Article_Inc_Input>;
  _prepend?: InputMaybe<Recommendation_Return_Search_Article_Prepend_Input>;
  _set?: InputMaybe<Recommendation_Return_Search_Article_Set_Input>;
  pk_columns: Recommendation_Return_Search_Article_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Recommendation_Return_Search_Article_ManyArgs = {
  updates: Array<Recommendation_Return_Search_Article_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Rewards_Benefit_CategoryArgs = {
  _set?: InputMaybe<Rewards_Benefit_Category_Set_Input>;
  where: Rewards_Benefit_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rewards_Benefit_Category_By_PkArgs = {
  _set?: InputMaybe<Rewards_Benefit_Category_Set_Input>;
  pk_columns: Rewards_Benefit_Category_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Rewards_Benefit_Category_ManyArgs = {
  updates: Array<Rewards_Benefit_Category_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Rewards_BenefitsArgs = {
  _append?: InputMaybe<Rewards_Benefits_Append_Input>;
  _delete_at_path?: InputMaybe<Rewards_Benefits_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Rewards_Benefits_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Rewards_Benefits_Delete_Key_Input>;
  _inc?: InputMaybe<Rewards_Benefits_Inc_Input>;
  _prepend?: InputMaybe<Rewards_Benefits_Prepend_Input>;
  _set?: InputMaybe<Rewards_Benefits_Set_Input>;
  where: Rewards_Benefits_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rewards_Benefits_By_PkArgs = {
  _append?: InputMaybe<Rewards_Benefits_Append_Input>;
  _delete_at_path?: InputMaybe<Rewards_Benefits_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Rewards_Benefits_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Rewards_Benefits_Delete_Key_Input>;
  _inc?: InputMaybe<Rewards_Benefits_Inc_Input>;
  _prepend?: InputMaybe<Rewards_Benefits_Prepend_Input>;
  _set?: InputMaybe<Rewards_Benefits_Set_Input>;
  pk_columns: Rewards_Benefits_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Rewards_Benefits_ManyArgs = {
  updates: Array<Rewards_Benefits_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Rewards_BoostsArgs = {
  _append?: InputMaybe<Rewards_Boosts_Append_Input>;
  _delete_at_path?: InputMaybe<Rewards_Boosts_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Rewards_Boosts_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Rewards_Boosts_Delete_Key_Input>;
  _inc?: InputMaybe<Rewards_Boosts_Inc_Input>;
  _prepend?: InputMaybe<Rewards_Boosts_Prepend_Input>;
  _set?: InputMaybe<Rewards_Boosts_Set_Input>;
  where: Rewards_Boosts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rewards_Boosts_By_PkArgs = {
  _append?: InputMaybe<Rewards_Boosts_Append_Input>;
  _delete_at_path?: InputMaybe<Rewards_Boosts_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Rewards_Boosts_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Rewards_Boosts_Delete_Key_Input>;
  _inc?: InputMaybe<Rewards_Boosts_Inc_Input>;
  _prepend?: InputMaybe<Rewards_Boosts_Prepend_Input>;
  _set?: InputMaybe<Rewards_Boosts_Set_Input>;
  pk_columns: Rewards_Boosts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Rewards_Boosts_ManyArgs = {
  updates: Array<Rewards_Boosts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Rewards_TransactionsArgs = {
  _append?: InputMaybe<Rewards_Transactions_Append_Input>;
  _delete_at_path?: InputMaybe<Rewards_Transactions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Rewards_Transactions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Rewards_Transactions_Delete_Key_Input>;
  _inc?: InputMaybe<Rewards_Transactions_Inc_Input>;
  _prepend?: InputMaybe<Rewards_Transactions_Prepend_Input>;
  _set?: InputMaybe<Rewards_Transactions_Set_Input>;
  where: Rewards_Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rewards_Transactions_By_PkArgs = {
  _append?: InputMaybe<Rewards_Transactions_Append_Input>;
  _delete_at_path?: InputMaybe<Rewards_Transactions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Rewards_Transactions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Rewards_Transactions_Delete_Key_Input>;
  _inc?: InputMaybe<Rewards_Transactions_Inc_Input>;
  _prepend?: InputMaybe<Rewards_Transactions_Prepend_Input>;
  _set?: InputMaybe<Rewards_Transactions_Set_Input>;
  pk_columns: Rewards_Transactions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Rewards_Transactions_ManyArgs = {
  updates: Array<Rewards_Transactions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SessionsArgs = {
  _inc?: InputMaybe<Sessions_Inc_Input>;
  _set?: InputMaybe<Sessions_Set_Input>;
  where: Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sessions_By_PkArgs = {
  _inc?: InputMaybe<Sessions_Inc_Input>;
  _set?: InputMaybe<Sessions_Set_Input>;
  pk_columns: Sessions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Sessions_ManyArgs = {
  updates: Array<Sessions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Sqbase_Event_Log_Event_TypesArgs = {
  _set?: InputMaybe<Sqbase_Event_Log_Event_Types_Set_Input>;
  where: Sqbase_Event_Log_Event_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sqbase_Event_Log_Event_Types_By_PkArgs = {
  _set?: InputMaybe<Sqbase_Event_Log_Event_Types_Set_Input>;
  pk_columns: Sqbase_Event_Log_Event_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Sqbase_Event_Log_Event_Types_ManyArgs = {
  updates: Array<Sqbase_Event_Log_Event_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Sqbase_Event_Log_EventsArgs = {
  _append?: InputMaybe<Sqbase_Event_Log_Events_Append_Input>;
  _delete_at_path?: InputMaybe<Sqbase_Event_Log_Events_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Sqbase_Event_Log_Events_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Sqbase_Event_Log_Events_Delete_Key_Input>;
  _prepend?: InputMaybe<Sqbase_Event_Log_Events_Prepend_Input>;
  _set?: InputMaybe<Sqbase_Event_Log_Events_Set_Input>;
  where: Sqbase_Event_Log_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sqbase_Event_Log_Events_By_PkArgs = {
  _append?: InputMaybe<Sqbase_Event_Log_Events_Append_Input>;
  _delete_at_path?: InputMaybe<Sqbase_Event_Log_Events_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Sqbase_Event_Log_Events_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Sqbase_Event_Log_Events_Delete_Key_Input>;
  _prepend?: InputMaybe<Sqbase_Event_Log_Events_Prepend_Input>;
  _set?: InputMaybe<Sqbase_Event_Log_Events_Set_Input>;
  pk_columns: Sqbase_Event_Log_Events_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Sqbase_Event_Log_Events_ManyArgs = {
  updates: Array<Sqbase_Event_Log_Events_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Sqlab_ContactsArgs = {
  _set?: InputMaybe<Sqlab_Contacts_Set_Input>;
  where: Sqlab_Contacts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sqlab_Contacts_By_PkArgs = {
  _set?: InputMaybe<Sqlab_Contacts_Set_Input>;
  pk_columns: Sqlab_Contacts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Sqlab_Contacts_ManyArgs = {
  updates: Array<Sqlab_Contacts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Support_InteractionsArgs = {
  _inc?: InputMaybe<Support_Interactions_Inc_Input>;
  _set?: InputMaybe<Support_Interactions_Set_Input>;
  where: Support_Interactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Support_Interactions_By_PkArgs = {
  _inc?: InputMaybe<Support_Interactions_Inc_Input>;
  _set?: InputMaybe<Support_Interactions_Set_Input>;
  pk_columns: Support_Interactions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Support_Interactions_ManyArgs = {
  updates: Array<Support_Interactions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Support_Registrations_Without_AnswersArgs = {
  _inc?: InputMaybe<Support_Registrations_Without_Answers_Inc_Input>;
  _set?: InputMaybe<Support_Registrations_Without_Answers_Set_Input>;
  where: Support_Registrations_Without_Answers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Support_Registrations_Without_Answers_ManyArgs = {
  updates: Array<Support_Registrations_Without_Answers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Support_Vendor_SupportArgs = {
  _set?: InputMaybe<Support_Vendor_Support_Set_Input>;
  where: Support_Vendor_Support_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Support_Vendor_Support_By_PkArgs = {
  _set?: InputMaybe<Support_Vendor_Support_Set_Input>;
  pk_columns: Support_Vendor_Support_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Support_Vendor_Support_ManyArgs = {
  updates: Array<Support_Vendor_Support_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Support_Vendor_Support_TypeArgs = {
  _set?: InputMaybe<Support_Vendor_Support_Type_Set_Input>;
  where: Support_Vendor_Support_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Support_Vendor_Support_Type_By_PkArgs = {
  _set?: InputMaybe<Support_Vendor_Support_Type_Set_Input>;
  pk_columns: Support_Vendor_Support_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Support_Vendor_Support_Type_ManyArgs = {
  updates: Array<Support_Vendor_Support_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tracker_Article_PricesArgs = {
  _inc?: InputMaybe<Tracker_Article_Prices_Inc_Input>;
  _set?: InputMaybe<Tracker_Article_Prices_Set_Input>;
  where: Tracker_Article_Prices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tracker_Article_Prices_By_PkArgs = {
  _inc?: InputMaybe<Tracker_Article_Prices_Inc_Input>;
  _set?: InputMaybe<Tracker_Article_Prices_Set_Input>;
  pk_columns: Tracker_Article_Prices_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tracker_Article_Prices_ManyArgs = {
  updates: Array<Tracker_Article_Prices_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _set?: InputMaybe<User_Set_Input>;
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _set?: InputMaybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_DetailsArgs = {
  _inc?: InputMaybe<User_Details_Inc_Input>;
  _set?: InputMaybe<User_Details_Set_Input>;
  where: User_Details_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Details_By_PkArgs = {
  _inc?: InputMaybe<User_Details_Inc_Input>;
  _set?: InputMaybe<User_Details_Set_Input>;
  pk_columns: User_Details_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Details_ManyArgs = {
  updates: Array<User_Details_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_ManyArgs = {
  updates: Array<User_Updates>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** columns and relationships of "onboarding.emails" */
export type Onboarding_Emails = {
  __typename?: 'onboarding_emails';
  created_at: Scalars['timestamptz'];
  language: Scalars['String'];
  registration_id: Scalars['Int'];
  scheduled_at: Scalars['timestamptz'];
  sent_at?: Maybe<Scalars['timestamptz']>;
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "onboarding.emails" */
export type Onboarding_Emails_Aggregate = {
  __typename?: 'onboarding_emails_aggregate';
  aggregate?: Maybe<Onboarding_Emails_Aggregate_Fields>;
  nodes: Array<Onboarding_Emails>;
};

/** aggregate fields of "onboarding.emails" */
export type Onboarding_Emails_Aggregate_Fields = {
  __typename?: 'onboarding_emails_aggregate_fields';
  avg?: Maybe<Onboarding_Emails_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Onboarding_Emails_Max_Fields>;
  min?: Maybe<Onboarding_Emails_Min_Fields>;
  stddev?: Maybe<Onboarding_Emails_Stddev_Fields>;
  stddev_pop?: Maybe<Onboarding_Emails_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Onboarding_Emails_Stddev_Samp_Fields>;
  sum?: Maybe<Onboarding_Emails_Sum_Fields>;
  var_pop?: Maybe<Onboarding_Emails_Var_Pop_Fields>;
  var_samp?: Maybe<Onboarding_Emails_Var_Samp_Fields>;
  variance?: Maybe<Onboarding_Emails_Variance_Fields>;
};


/** aggregate fields of "onboarding.emails" */
export type Onboarding_Emails_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Onboarding_Emails_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Onboarding_Emails_Avg_Fields = {
  __typename?: 'onboarding_emails_avg_fields';
  registration_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "onboarding.emails". All fields are combined with a logical 'AND'. */
export type Onboarding_Emails_Bool_Exp = {
  _and?: InputMaybe<Array<Onboarding_Emails_Bool_Exp>>;
  _not?: InputMaybe<Onboarding_Emails_Bool_Exp>;
  _or?: InputMaybe<Array<Onboarding_Emails_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  language?: InputMaybe<String_Comparison_Exp>;
  registration_id?: InputMaybe<Int_Comparison_Exp>;
  scheduled_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  sent_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "onboarding.emails" */
export enum Onboarding_Emails_Constraint {
  /** unique or primary key constraint on columns "registration_id", "type" */
  EmailsPkey = 'emails_pkey'
}

/** input type for incrementing numeric columns in table "onboarding.emails" */
export type Onboarding_Emails_Inc_Input = {
  registration_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "onboarding.emails" */
export type Onboarding_Emails_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  language?: InputMaybe<Scalars['String']>;
  registration_id?: InputMaybe<Scalars['Int']>;
  scheduled_at?: InputMaybe<Scalars['timestamptz']>;
  sent_at?: InputMaybe<Scalars['timestamptz']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Onboarding_Emails_Max_Fields = {
  __typename?: 'onboarding_emails_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  language?: Maybe<Scalars['String']>;
  registration_id?: Maybe<Scalars['Int']>;
  scheduled_at?: Maybe<Scalars['timestamptz']>;
  sent_at?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Onboarding_Emails_Min_Fields = {
  __typename?: 'onboarding_emails_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  language?: Maybe<Scalars['String']>;
  registration_id?: Maybe<Scalars['Int']>;
  scheduled_at?: Maybe<Scalars['timestamptz']>;
  sent_at?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "onboarding.emails" */
export type Onboarding_Emails_Mutation_Response = {
  __typename?: 'onboarding_emails_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Onboarding_Emails>;
};

/** on_conflict condition type for table "onboarding.emails" */
export type Onboarding_Emails_On_Conflict = {
  constraint: Onboarding_Emails_Constraint;
  update_columns?: Array<Onboarding_Emails_Update_Column>;
  where?: InputMaybe<Onboarding_Emails_Bool_Exp>;
};

/** Ordering options when selecting data from "onboarding.emails". */
export type Onboarding_Emails_Order_By = {
  created_at?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  registration_id?: InputMaybe<Order_By>;
  scheduled_at?: InputMaybe<Order_By>;
  sent_at?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: onboarding.emails */
export type Onboarding_Emails_Pk_Columns_Input = {
  registration_id: Scalars['Int'];
  type: Scalars['String'];
};

/** select columns of table "onboarding.emails" */
export enum Onboarding_Emails_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Language = 'language',
  /** column name */
  RegistrationId = 'registration_id',
  /** column name */
  ScheduledAt = 'scheduled_at',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "onboarding.emails" */
export type Onboarding_Emails_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  language?: InputMaybe<Scalars['String']>;
  registration_id?: InputMaybe<Scalars['Int']>;
  scheduled_at?: InputMaybe<Scalars['timestamptz']>;
  sent_at?: InputMaybe<Scalars['timestamptz']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Onboarding_Emails_Stddev_Fields = {
  __typename?: 'onboarding_emails_stddev_fields';
  registration_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Onboarding_Emails_Stddev_Pop_Fields = {
  __typename?: 'onboarding_emails_stddev_pop_fields';
  registration_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Onboarding_Emails_Stddev_Samp_Fields = {
  __typename?: 'onboarding_emails_stddev_samp_fields';
  registration_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "onboarding_emails" */
export type Onboarding_Emails_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Onboarding_Emails_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Onboarding_Emails_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  language?: InputMaybe<Scalars['String']>;
  registration_id?: InputMaybe<Scalars['Int']>;
  scheduled_at?: InputMaybe<Scalars['timestamptz']>;
  sent_at?: InputMaybe<Scalars['timestamptz']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Onboarding_Emails_Sum_Fields = {
  __typename?: 'onboarding_emails_sum_fields';
  registration_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "onboarding.emails" */
export enum Onboarding_Emails_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Language = 'language',
  /** column name */
  RegistrationId = 'registration_id',
  /** column name */
  ScheduledAt = 'scheduled_at',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Onboarding_Emails_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Onboarding_Emails_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Onboarding_Emails_Set_Input>;
  /** filter the rows which have to be updated */
  where: Onboarding_Emails_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Onboarding_Emails_Var_Pop_Fields = {
  __typename?: 'onboarding_emails_var_pop_fields';
  registration_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Onboarding_Emails_Var_Samp_Fields = {
  __typename?: 'onboarding_emails_var_samp_fields';
  registration_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Onboarding_Emails_Variance_Fields = {
  __typename?: 'onboarding_emails_variance_fields';
  registration_id?: Maybe<Scalars['Float']>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** represents an organisation */
export type Organisation = {
  __typename?: 'organisation';
  bic?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  distributor?: Maybe<Scalars['String']>;
  email_address?: Maybe<Scalars['String']>;
  erp_system_id?: Maybe<Scalars['Int']>;
  group_description?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  /** The customer number from the SQlab ERP system. */
  id: Scalars['String'];
  iqlab_access_enabled: Scalars['Boolean'];
  is_locked: Scalars['Boolean'];
  is_shop?: Maybe<Scalars['Boolean']>;
  last_name?: Maybe<Scalars['String']>;
  logo_img_url?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['jsonb']>;
  password: Scalars['String'];
  password_hash?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  profiler_access_enabled: Scalars['Boolean'];
  profiler_commission_enabled: Scalars['Boolean'];
  /** A readable vendor name. */
  shop_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  sqlab_contact?: Maybe<Sqlab_Contacts>;
  street?: Maybe<Scalars['String']>;
  supervisor?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  users: Array<User>;
  /** An aggregate relationship */
  users_aggregate: User_Aggregate;
};


/** represents an organisation */
export type OrganisationMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** represents an organisation */
export type OrganisationUsersArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


/** represents an organisation */
export type OrganisationUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** aggregated selection of "organisation" */
export type Organisation_Aggregate = {
  __typename?: 'organisation_aggregate';
  aggregate?: Maybe<Organisation_Aggregate_Fields>;
  nodes: Array<Organisation>;
};

/** aggregate fields of "organisation" */
export type Organisation_Aggregate_Fields = {
  __typename?: 'organisation_aggregate_fields';
  avg?: Maybe<Organisation_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Organisation_Max_Fields>;
  min?: Maybe<Organisation_Min_Fields>;
  stddev?: Maybe<Organisation_Stddev_Fields>;
  stddev_pop?: Maybe<Organisation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Organisation_Stddev_Samp_Fields>;
  sum?: Maybe<Organisation_Sum_Fields>;
  var_pop?: Maybe<Organisation_Var_Pop_Fields>;
  var_samp?: Maybe<Organisation_Var_Samp_Fields>;
  variance?: Maybe<Organisation_Variance_Fields>;
};


/** aggregate fields of "organisation" */
export type Organisation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organisation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Organisation_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Organisation_Avg_Fields = {
  __typename?: 'organisation_avg_fields';
  erp_system_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "organisation". All fields are combined with a logical 'AND'. */
export type Organisation_Bool_Exp = {
  _and?: InputMaybe<Array<Organisation_Bool_Exp>>;
  _not?: InputMaybe<Organisation_Bool_Exp>;
  _or?: InputMaybe<Array<Organisation_Bool_Exp>>;
  bic?: InputMaybe<String_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  country_code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  distributor?: InputMaybe<String_Comparison_Exp>;
  email_address?: InputMaybe<String_Comparison_Exp>;
  erp_system_id?: InputMaybe<Int_Comparison_Exp>;
  group_description?: InputMaybe<String_Comparison_Exp>;
  group_id?: InputMaybe<String_Comparison_Exp>;
  iban?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  iqlab_access_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  is_locked?: InputMaybe<Boolean_Comparison_Exp>;
  is_shop?: InputMaybe<Boolean_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  logo_img_url?: InputMaybe<String_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  password?: InputMaybe<String_Comparison_Exp>;
  password_hash?: InputMaybe<String_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  postal_code?: InputMaybe<String_Comparison_Exp>;
  profiler_access_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  profiler_commission_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  shop_name?: InputMaybe<String_Comparison_Exp>;
  sqlab_contact?: InputMaybe<Sqlab_Contacts_Bool_Exp>;
  street?: InputMaybe<String_Comparison_Exp>;
  supervisor?: InputMaybe<String_Comparison_Exp>;
  surname?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  users?: InputMaybe<User_Bool_Exp>;
  users_aggregate?: InputMaybe<User_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "organisation" */
export enum Organisation_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrganisationPkey = 'organisation_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Organisation_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Organisation_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Organisation_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "organisation" */
export type Organisation_Inc_Input = {
  erp_system_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "organisation" */
export type Organisation_Insert_Input = {
  bic?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  distributor?: InputMaybe<Scalars['String']>;
  email_address?: InputMaybe<Scalars['String']>;
  erp_system_id?: InputMaybe<Scalars['Int']>;
  group_description?: InputMaybe<Scalars['String']>;
  group_id?: InputMaybe<Scalars['String']>;
  iban?: InputMaybe<Scalars['String']>;
  /** The customer number from the SQlab ERP system. */
  id?: InputMaybe<Scalars['String']>;
  iqlab_access_enabled?: InputMaybe<Scalars['Boolean']>;
  is_locked?: InputMaybe<Scalars['Boolean']>;
  is_shop?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  logo_img_url?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  password?: InputMaybe<Scalars['String']>;
  password_hash?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  postal_code?: InputMaybe<Scalars['String']>;
  profiler_access_enabled?: InputMaybe<Scalars['Boolean']>;
  profiler_commission_enabled?: InputMaybe<Scalars['Boolean']>;
  /** A readable vendor name. */
  shop_name?: InputMaybe<Scalars['String']>;
  sqlab_contact?: InputMaybe<Sqlab_Contacts_Obj_Rel_Insert_Input>;
  street?: InputMaybe<Scalars['String']>;
  supervisor?: InputMaybe<Scalars['String']>;
  surname?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  users?: InputMaybe<User_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Organisation_Max_Fields = {
  __typename?: 'organisation_max_fields';
  bic?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  distributor?: Maybe<Scalars['String']>;
  email_address?: Maybe<Scalars['String']>;
  erp_system_id?: Maybe<Scalars['Int']>;
  group_description?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  /** The customer number from the SQlab ERP system. */
  id?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  logo_img_url?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  /** A readable vendor name. */
  shop_name?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  supervisor?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Organisation_Min_Fields = {
  __typename?: 'organisation_min_fields';
  bic?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  distributor?: Maybe<Scalars['String']>;
  email_address?: Maybe<Scalars['String']>;
  erp_system_id?: Maybe<Scalars['Int']>;
  group_description?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  /** The customer number from the SQlab ERP system. */
  id?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  logo_img_url?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  /** A readable vendor name. */
  shop_name?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  supervisor?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "organisation" */
export type Organisation_Mutation_Response = {
  __typename?: 'organisation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organisation>;
};

/** input type for inserting object relation for remote table "organisation" */
export type Organisation_Obj_Rel_Insert_Input = {
  data: Organisation_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Organisation_On_Conflict>;
};

/** on_conflict condition type for table "organisation" */
export type Organisation_On_Conflict = {
  constraint: Organisation_Constraint;
  update_columns?: Array<Organisation_Update_Column>;
  where?: InputMaybe<Organisation_Bool_Exp>;
};

/** Ordering options when selecting data from "organisation". */
export type Organisation_Order_By = {
  bic?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  country_code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  distributor?: InputMaybe<Order_By>;
  email_address?: InputMaybe<Order_By>;
  erp_system_id?: InputMaybe<Order_By>;
  group_description?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  iban?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  iqlab_access_enabled?: InputMaybe<Order_By>;
  is_locked?: InputMaybe<Order_By>;
  is_shop?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  logo_img_url?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  password?: InputMaybe<Order_By>;
  password_hash?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  postal_code?: InputMaybe<Order_By>;
  profiler_access_enabled?: InputMaybe<Order_By>;
  profiler_commission_enabled?: InputMaybe<Order_By>;
  shop_name?: InputMaybe<Order_By>;
  sqlab_contact?: InputMaybe<Sqlab_Contacts_Order_By>;
  street?: InputMaybe<Order_By>;
  supervisor?: InputMaybe<Order_By>;
  surname?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  users_aggregate?: InputMaybe<User_Aggregate_Order_By>;
};

/** primary key columns input for table: organisation */
export type Organisation_Pk_Columns_Input = {
  /** The customer number from the SQlab ERP system. */
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Organisation_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "organisation" */
export enum Organisation_Select_Column {
  /** column name */
  Bic = 'bic',
  /** column name */
  City = 'city',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Distributor = 'distributor',
  /** column name */
  EmailAddress = 'email_address',
  /** column name */
  ErpSystemId = 'erp_system_id',
  /** column name */
  GroupDescription = 'group_description',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Iban = 'iban',
  /** column name */
  Id = 'id',
  /** column name */
  IqlabAccessEnabled = 'iqlab_access_enabled',
  /** column name */
  IsLocked = 'is_locked',
  /** column name */
  IsShop = 'is_shop',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LogoImgUrl = 'logo_img_url',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Password = 'password',
  /** column name */
  PasswordHash = 'password_hash',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  PostalCode = 'postal_code',
  /** column name */
  ProfilerAccessEnabled = 'profiler_access_enabled',
  /** column name */
  ProfilerCommissionEnabled = 'profiler_commission_enabled',
  /** column name */
  ShopName = 'shop_name',
  /** column name */
  Street = 'street',
  /** column name */
  Supervisor = 'supervisor',
  /** column name */
  Surname = 'surname',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "organisation" */
export type Organisation_Set_Input = {
  bic?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  distributor?: InputMaybe<Scalars['String']>;
  email_address?: InputMaybe<Scalars['String']>;
  erp_system_id?: InputMaybe<Scalars['Int']>;
  group_description?: InputMaybe<Scalars['String']>;
  group_id?: InputMaybe<Scalars['String']>;
  iban?: InputMaybe<Scalars['String']>;
  /** The customer number from the SQlab ERP system. */
  id?: InputMaybe<Scalars['String']>;
  iqlab_access_enabled?: InputMaybe<Scalars['Boolean']>;
  is_locked?: InputMaybe<Scalars['Boolean']>;
  is_shop?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  logo_img_url?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  password?: InputMaybe<Scalars['String']>;
  password_hash?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  postal_code?: InputMaybe<Scalars['String']>;
  profiler_access_enabled?: InputMaybe<Scalars['Boolean']>;
  profiler_commission_enabled?: InputMaybe<Scalars['Boolean']>;
  /** A readable vendor name. */
  shop_name?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  supervisor?: InputMaybe<Scalars['String']>;
  surname?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Organisation_Stddev_Fields = {
  __typename?: 'organisation_stddev_fields';
  erp_system_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Organisation_Stddev_Pop_Fields = {
  __typename?: 'organisation_stddev_pop_fields';
  erp_system_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Organisation_Stddev_Samp_Fields = {
  __typename?: 'organisation_stddev_samp_fields';
  erp_system_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "organisation" */
export type Organisation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organisation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organisation_Stream_Cursor_Value_Input = {
  bic?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  distributor?: InputMaybe<Scalars['String']>;
  email_address?: InputMaybe<Scalars['String']>;
  erp_system_id?: InputMaybe<Scalars['Int']>;
  group_description?: InputMaybe<Scalars['String']>;
  group_id?: InputMaybe<Scalars['String']>;
  iban?: InputMaybe<Scalars['String']>;
  /** The customer number from the SQlab ERP system. */
  id?: InputMaybe<Scalars['String']>;
  iqlab_access_enabled?: InputMaybe<Scalars['Boolean']>;
  is_locked?: InputMaybe<Scalars['Boolean']>;
  is_shop?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  logo_img_url?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  password?: InputMaybe<Scalars['String']>;
  password_hash?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  postal_code?: InputMaybe<Scalars['String']>;
  profiler_access_enabled?: InputMaybe<Scalars['Boolean']>;
  profiler_commission_enabled?: InputMaybe<Scalars['Boolean']>;
  /** A readable vendor name. */
  shop_name?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  supervisor?: InputMaybe<Scalars['String']>;
  surname?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Organisation_Sum_Fields = {
  __typename?: 'organisation_sum_fields';
  erp_system_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "organisation" */
export enum Organisation_Update_Column {
  /** column name */
  Bic = 'bic',
  /** column name */
  City = 'city',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Distributor = 'distributor',
  /** column name */
  EmailAddress = 'email_address',
  /** column name */
  ErpSystemId = 'erp_system_id',
  /** column name */
  GroupDescription = 'group_description',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Iban = 'iban',
  /** column name */
  Id = 'id',
  /** column name */
  IqlabAccessEnabled = 'iqlab_access_enabled',
  /** column name */
  IsLocked = 'is_locked',
  /** column name */
  IsShop = 'is_shop',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LogoImgUrl = 'logo_img_url',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Password = 'password',
  /** column name */
  PasswordHash = 'password_hash',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  PostalCode = 'postal_code',
  /** column name */
  ProfilerAccessEnabled = 'profiler_access_enabled',
  /** column name */
  ProfilerCommissionEnabled = 'profiler_commission_enabled',
  /** column name */
  ShopName = 'shop_name',
  /** column name */
  Street = 'street',
  /** column name */
  Supervisor = 'supervisor',
  /** column name */
  Surname = 'surname',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Organisation_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Organisation_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Organisation_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Organisation_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Organisation_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Organisation_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Organisation_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organisation_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organisation_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Organisation_Var_Pop_Fields = {
  __typename?: 'organisation_var_pop_fields';
  erp_system_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Organisation_Var_Samp_Fields = {
  __typename?: 'organisation_var_samp_fields';
  erp_system_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Organisation_Variance_Fields = {
  __typename?: 'organisation_variance_fields';
  erp_system_id?: Maybe<Scalars['Float']>;
};

/** The user preferences can be set within the Profiler App and contain the article groups which should be recommended for the next consultation. The selection will be reset after the consultation has been finished. */
export type Preferences_User_Preferences = {
  __typename?: 'preferences_user_preferences';
  article_groups: Scalars['jsonb'];
  user_id: Scalars['uuid'];
};


/** The user preferences can be set within the Profiler App and contain the article groups which should be recommended for the next consultation. The selection will be reset after the consultation has been finished. */
export type Preferences_User_PreferencesArticle_GroupsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "preferences.user_preferences" */
export type Preferences_User_Preferences_Aggregate = {
  __typename?: 'preferences_user_preferences_aggregate';
  aggregate?: Maybe<Preferences_User_Preferences_Aggregate_Fields>;
  nodes: Array<Preferences_User_Preferences>;
};

/** aggregate fields of "preferences.user_preferences" */
export type Preferences_User_Preferences_Aggregate_Fields = {
  __typename?: 'preferences_user_preferences_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Preferences_User_Preferences_Max_Fields>;
  min?: Maybe<Preferences_User_Preferences_Min_Fields>;
};


/** aggregate fields of "preferences.user_preferences" */
export type Preferences_User_Preferences_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Preferences_User_Preferences_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Preferences_User_Preferences_Append_Input = {
  article_groups?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "preferences.user_preferences". All fields are combined with a logical 'AND'. */
export type Preferences_User_Preferences_Bool_Exp = {
  _and?: InputMaybe<Array<Preferences_User_Preferences_Bool_Exp>>;
  _not?: InputMaybe<Preferences_User_Preferences_Bool_Exp>;
  _or?: InputMaybe<Array<Preferences_User_Preferences_Bool_Exp>>;
  article_groups?: InputMaybe<Jsonb_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "preferences.user_preferences" */
export enum Preferences_User_Preferences_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  UserPreferencesPkey = 'user_preferences_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Preferences_User_Preferences_Delete_At_Path_Input = {
  article_groups?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Preferences_User_Preferences_Delete_Elem_Input = {
  article_groups?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Preferences_User_Preferences_Delete_Key_Input = {
  article_groups?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "preferences.user_preferences" */
export type Preferences_User_Preferences_Insert_Input = {
  article_groups?: InputMaybe<Scalars['jsonb']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Preferences_User_Preferences_Max_Fields = {
  __typename?: 'preferences_user_preferences_max_fields';
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Preferences_User_Preferences_Min_Fields = {
  __typename?: 'preferences_user_preferences_min_fields';
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "preferences.user_preferences" */
export type Preferences_User_Preferences_Mutation_Response = {
  __typename?: 'preferences_user_preferences_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Preferences_User_Preferences>;
};

/** input type for inserting object relation for remote table "preferences.user_preferences" */
export type Preferences_User_Preferences_Obj_Rel_Insert_Input = {
  data: Preferences_User_Preferences_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Preferences_User_Preferences_On_Conflict>;
};

/** on_conflict condition type for table "preferences.user_preferences" */
export type Preferences_User_Preferences_On_Conflict = {
  constraint: Preferences_User_Preferences_Constraint;
  update_columns?: Array<Preferences_User_Preferences_Update_Column>;
  where?: InputMaybe<Preferences_User_Preferences_Bool_Exp>;
};

/** Ordering options when selecting data from "preferences.user_preferences". */
export type Preferences_User_Preferences_Order_By = {
  article_groups?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: preferences.user_preferences */
export type Preferences_User_Preferences_Pk_Columns_Input = {
  user_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Preferences_User_Preferences_Prepend_Input = {
  article_groups?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "preferences.user_preferences" */
export enum Preferences_User_Preferences_Select_Column {
  /** column name */
  ArticleGroups = 'article_groups',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "preferences.user_preferences" */
export type Preferences_User_Preferences_Set_Input = {
  article_groups?: InputMaybe<Scalars['jsonb']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "preferences_user_preferences" */
export type Preferences_User_Preferences_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Preferences_User_Preferences_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Preferences_User_Preferences_Stream_Cursor_Value_Input = {
  article_groups?: InputMaybe<Scalars['jsonb']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "preferences.user_preferences" */
export enum Preferences_User_Preferences_Update_Column {
  /** column name */
  ArticleGroups = 'article_groups',
  /** column name */
  UserId = 'user_id'
}

export type Preferences_User_Preferences_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Preferences_User_Preferences_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Preferences_User_Preferences_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Preferences_User_Preferences_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Preferences_User_Preferences_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Preferences_User_Preferences_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Preferences_User_Preferences_Set_Input>;
  /** filter the rows which have to be updated */
  where: Preferences_User_Preferences_Bool_Exp;
};

/** columns and relationships of "preferences.vendor_preferences" */
export type Preferences_Vendor_Preferences = {
  __typename?: 'preferences_vendor_preferences';
  excluded_manufacturers: Scalars['jsonb'];
  vendor_id: Scalars['String'];
};


/** columns and relationships of "preferences.vendor_preferences" */
export type Preferences_Vendor_PreferencesExcluded_ManufacturersArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "preferences.vendor_preferences" */
export type Preferences_Vendor_Preferences_Aggregate = {
  __typename?: 'preferences_vendor_preferences_aggregate';
  aggregate?: Maybe<Preferences_Vendor_Preferences_Aggregate_Fields>;
  nodes: Array<Preferences_Vendor_Preferences>;
};

/** aggregate fields of "preferences.vendor_preferences" */
export type Preferences_Vendor_Preferences_Aggregate_Fields = {
  __typename?: 'preferences_vendor_preferences_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Preferences_Vendor_Preferences_Max_Fields>;
  min?: Maybe<Preferences_Vendor_Preferences_Min_Fields>;
};


/** aggregate fields of "preferences.vendor_preferences" */
export type Preferences_Vendor_Preferences_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Preferences_Vendor_Preferences_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Preferences_Vendor_Preferences_Append_Input = {
  excluded_manufacturers?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "preferences.vendor_preferences". All fields are combined with a logical 'AND'. */
export type Preferences_Vendor_Preferences_Bool_Exp = {
  _and?: InputMaybe<Array<Preferences_Vendor_Preferences_Bool_Exp>>;
  _not?: InputMaybe<Preferences_Vendor_Preferences_Bool_Exp>;
  _or?: InputMaybe<Array<Preferences_Vendor_Preferences_Bool_Exp>>;
  excluded_manufacturers?: InputMaybe<Jsonb_Comparison_Exp>;
  vendor_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "preferences.vendor_preferences" */
export enum Preferences_Vendor_Preferences_Constraint {
  /** unique or primary key constraint on columns "vendor_id" */
  VendorPreferencesPkey = 'vendor_preferences_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Preferences_Vendor_Preferences_Delete_At_Path_Input = {
  excluded_manufacturers?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Preferences_Vendor_Preferences_Delete_Elem_Input = {
  excluded_manufacturers?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Preferences_Vendor_Preferences_Delete_Key_Input = {
  excluded_manufacturers?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "preferences.vendor_preferences" */
export type Preferences_Vendor_Preferences_Insert_Input = {
  excluded_manufacturers?: InputMaybe<Scalars['jsonb']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Preferences_Vendor_Preferences_Max_Fields = {
  __typename?: 'preferences_vendor_preferences_max_fields';
  vendor_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Preferences_Vendor_Preferences_Min_Fields = {
  __typename?: 'preferences_vendor_preferences_min_fields';
  vendor_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "preferences.vendor_preferences" */
export type Preferences_Vendor_Preferences_Mutation_Response = {
  __typename?: 'preferences_vendor_preferences_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Preferences_Vendor_Preferences>;
};

/** input type for inserting object relation for remote table "preferences.vendor_preferences" */
export type Preferences_Vendor_Preferences_Obj_Rel_Insert_Input = {
  data: Preferences_Vendor_Preferences_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Preferences_Vendor_Preferences_On_Conflict>;
};

/** on_conflict condition type for table "preferences.vendor_preferences" */
export type Preferences_Vendor_Preferences_On_Conflict = {
  constraint: Preferences_Vendor_Preferences_Constraint;
  update_columns?: Array<Preferences_Vendor_Preferences_Update_Column>;
  where?: InputMaybe<Preferences_Vendor_Preferences_Bool_Exp>;
};

/** Ordering options when selecting data from "preferences.vendor_preferences". */
export type Preferences_Vendor_Preferences_Order_By = {
  excluded_manufacturers?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: preferences.vendor_preferences */
export type Preferences_Vendor_Preferences_Pk_Columns_Input = {
  vendor_id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Preferences_Vendor_Preferences_Prepend_Input = {
  excluded_manufacturers?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "preferences.vendor_preferences" */
export enum Preferences_Vendor_Preferences_Select_Column {
  /** column name */
  ExcludedManufacturers = 'excluded_manufacturers',
  /** column name */
  VendorId = 'vendor_id'
}

/** input type for updating data in table "preferences.vendor_preferences" */
export type Preferences_Vendor_Preferences_Set_Input = {
  excluded_manufacturers?: InputMaybe<Scalars['jsonb']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "preferences_vendor_preferences" */
export type Preferences_Vendor_Preferences_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Preferences_Vendor_Preferences_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Preferences_Vendor_Preferences_Stream_Cursor_Value_Input = {
  excluded_manufacturers?: InputMaybe<Scalars['jsonb']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "preferences.vendor_preferences" */
export enum Preferences_Vendor_Preferences_Update_Column {
  /** column name */
  ExcludedManufacturers = 'excluded_manufacturers',
  /** column name */
  VendorId = 'vendor_id'
}

export type Preferences_Vendor_Preferences_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Preferences_Vendor_Preferences_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Preferences_Vendor_Preferences_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Preferences_Vendor_Preferences_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Preferences_Vendor_Preferences_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Preferences_Vendor_Preferences_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Preferences_Vendor_Preferences_Set_Input>;
  /** filter the rows which have to be updated */
  where: Preferences_Vendor_Preferences_Bool_Exp;
};

/** columns and relationships of "progress.chapter_overview" */
export type Progress_Chapter_Overview = {
  __typename?: 'progress_chapter_overview';
  /** An object relationship */
  chapter?: Maybe<Content_Chapters>;
  chapter_id?: Maybe<Scalars['Int']>;
  contact_point_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  contactpoint?: Maybe<Content_Contactpoints>;
  is_completed?: Maybe<Scalars['Boolean']>;
  last_completed_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  lection?: Maybe<Content_Lections>;
  lection_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  user?: Maybe<User_Details>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "progress.chapter_overview" */
export type Progress_Chapter_Overview_Aggregate = {
  __typename?: 'progress_chapter_overview_aggregate';
  aggregate?: Maybe<Progress_Chapter_Overview_Aggregate_Fields>;
  nodes: Array<Progress_Chapter_Overview>;
};

/** aggregate fields of "progress.chapter_overview" */
export type Progress_Chapter_Overview_Aggregate_Fields = {
  __typename?: 'progress_chapter_overview_aggregate_fields';
  avg?: Maybe<Progress_Chapter_Overview_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Progress_Chapter_Overview_Max_Fields>;
  min?: Maybe<Progress_Chapter_Overview_Min_Fields>;
  stddev?: Maybe<Progress_Chapter_Overview_Stddev_Fields>;
  stddev_pop?: Maybe<Progress_Chapter_Overview_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Progress_Chapter_Overview_Stddev_Samp_Fields>;
  sum?: Maybe<Progress_Chapter_Overview_Sum_Fields>;
  var_pop?: Maybe<Progress_Chapter_Overview_Var_Pop_Fields>;
  var_samp?: Maybe<Progress_Chapter_Overview_Var_Samp_Fields>;
  variance?: Maybe<Progress_Chapter_Overview_Variance_Fields>;
};


/** aggregate fields of "progress.chapter_overview" */
export type Progress_Chapter_Overview_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Progress_Chapter_Overview_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Progress_Chapter_Overview_Avg_Fields = {
  __typename?: 'progress_chapter_overview_avg_fields';
  chapter_id?: Maybe<Scalars['Float']>;
  contact_point_id?: Maybe<Scalars['Float']>;
  lection_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "progress.chapter_overview". All fields are combined with a logical 'AND'. */
export type Progress_Chapter_Overview_Bool_Exp = {
  _and?: InputMaybe<Array<Progress_Chapter_Overview_Bool_Exp>>;
  _not?: InputMaybe<Progress_Chapter_Overview_Bool_Exp>;
  _or?: InputMaybe<Array<Progress_Chapter_Overview_Bool_Exp>>;
  chapter?: InputMaybe<Content_Chapters_Bool_Exp>;
  chapter_id?: InputMaybe<Int_Comparison_Exp>;
  contact_point_id?: InputMaybe<Int_Comparison_Exp>;
  contactpoint?: InputMaybe<Content_Contactpoints_Bool_Exp>;
  is_completed?: InputMaybe<Boolean_Comparison_Exp>;
  last_completed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  lection?: InputMaybe<Content_Lections_Bool_Exp>;
  lection_id?: InputMaybe<Int_Comparison_Exp>;
  user?: InputMaybe<User_Details_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type Progress_Chapter_Overview_Max_Fields = {
  __typename?: 'progress_chapter_overview_max_fields';
  chapter_id?: Maybe<Scalars['Int']>;
  contact_point_id?: Maybe<Scalars['Int']>;
  last_completed_at?: Maybe<Scalars['timestamptz']>;
  lection_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Progress_Chapter_Overview_Min_Fields = {
  __typename?: 'progress_chapter_overview_min_fields';
  chapter_id?: Maybe<Scalars['Int']>;
  contact_point_id?: Maybe<Scalars['Int']>;
  last_completed_at?: Maybe<Scalars['timestamptz']>;
  lection_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "progress.chapter_overview". */
export type Progress_Chapter_Overview_Order_By = {
  chapter?: InputMaybe<Content_Chapters_Order_By>;
  chapter_id?: InputMaybe<Order_By>;
  contact_point_id?: InputMaybe<Order_By>;
  contactpoint?: InputMaybe<Content_Contactpoints_Order_By>;
  is_completed?: InputMaybe<Order_By>;
  last_completed_at?: InputMaybe<Order_By>;
  lection?: InputMaybe<Content_Lections_Order_By>;
  lection_id?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Details_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "progress.chapter_overview" */
export enum Progress_Chapter_Overview_Select_Column {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  ContactPointId = 'contact_point_id',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  LastCompletedAt = 'last_completed_at',
  /** column name */
  LectionId = 'lection_id',
  /** column name */
  UserId = 'user_id'
}

/** aggregate stddev on columns */
export type Progress_Chapter_Overview_Stddev_Fields = {
  __typename?: 'progress_chapter_overview_stddev_fields';
  chapter_id?: Maybe<Scalars['Float']>;
  contact_point_id?: Maybe<Scalars['Float']>;
  lection_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Progress_Chapter_Overview_Stddev_Pop_Fields = {
  __typename?: 'progress_chapter_overview_stddev_pop_fields';
  chapter_id?: Maybe<Scalars['Float']>;
  contact_point_id?: Maybe<Scalars['Float']>;
  lection_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Progress_Chapter_Overview_Stddev_Samp_Fields = {
  __typename?: 'progress_chapter_overview_stddev_samp_fields';
  chapter_id?: Maybe<Scalars['Float']>;
  contact_point_id?: Maybe<Scalars['Float']>;
  lection_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "progress_chapter_overview" */
export type Progress_Chapter_Overview_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Progress_Chapter_Overview_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Progress_Chapter_Overview_Stream_Cursor_Value_Input = {
  chapter_id?: InputMaybe<Scalars['Int']>;
  contact_point_id?: InputMaybe<Scalars['Int']>;
  is_completed?: InputMaybe<Scalars['Boolean']>;
  last_completed_at?: InputMaybe<Scalars['timestamptz']>;
  lection_id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Progress_Chapter_Overview_Sum_Fields = {
  __typename?: 'progress_chapter_overview_sum_fields';
  chapter_id?: Maybe<Scalars['Int']>;
  contact_point_id?: Maybe<Scalars['Int']>;
  lection_id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Progress_Chapter_Overview_Var_Pop_Fields = {
  __typename?: 'progress_chapter_overview_var_pop_fields';
  chapter_id?: Maybe<Scalars['Float']>;
  contact_point_id?: Maybe<Scalars['Float']>;
  lection_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Progress_Chapter_Overview_Var_Samp_Fields = {
  __typename?: 'progress_chapter_overview_var_samp_fields';
  chapter_id?: Maybe<Scalars['Float']>;
  contact_point_id?: Maybe<Scalars['Float']>;
  lection_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Progress_Chapter_Overview_Variance_Fields = {
  __typename?: 'progress_chapter_overview_variance_fields';
  chapter_id?: Maybe<Scalars['Float']>;
  contact_point_id?: Maybe<Scalars['Float']>;
  lection_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "progress.completed_chapters" */
export type Progress_Completed_Chapters = {
  __typename?: 'progress_completed_chapters';
  /** An object relationship */
  chapter: Content_Chapters;
  chapter_id: Scalars['Int'];
  last_completed_at: Scalars['timestamptz'];
  /** An object relationship */
  user_details: User_Details;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "progress.completed_chapters" */
export type Progress_Completed_Chapters_Aggregate = {
  __typename?: 'progress_completed_chapters_aggregate';
  aggregate?: Maybe<Progress_Completed_Chapters_Aggregate_Fields>;
  nodes: Array<Progress_Completed_Chapters>;
};

export type Progress_Completed_Chapters_Aggregate_Bool_Exp = {
  count?: InputMaybe<Progress_Completed_Chapters_Aggregate_Bool_Exp_Count>;
};

export type Progress_Completed_Chapters_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Progress_Completed_Chapters_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Progress_Completed_Chapters_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "progress.completed_chapters" */
export type Progress_Completed_Chapters_Aggregate_Fields = {
  __typename?: 'progress_completed_chapters_aggregate_fields';
  avg?: Maybe<Progress_Completed_Chapters_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Progress_Completed_Chapters_Max_Fields>;
  min?: Maybe<Progress_Completed_Chapters_Min_Fields>;
  stddev?: Maybe<Progress_Completed_Chapters_Stddev_Fields>;
  stddev_pop?: Maybe<Progress_Completed_Chapters_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Progress_Completed_Chapters_Stddev_Samp_Fields>;
  sum?: Maybe<Progress_Completed_Chapters_Sum_Fields>;
  var_pop?: Maybe<Progress_Completed_Chapters_Var_Pop_Fields>;
  var_samp?: Maybe<Progress_Completed_Chapters_Var_Samp_Fields>;
  variance?: Maybe<Progress_Completed_Chapters_Variance_Fields>;
};


/** aggregate fields of "progress.completed_chapters" */
export type Progress_Completed_Chapters_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Progress_Completed_Chapters_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "progress.completed_chapters" */
export type Progress_Completed_Chapters_Aggregate_Order_By = {
  avg?: InputMaybe<Progress_Completed_Chapters_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Progress_Completed_Chapters_Max_Order_By>;
  min?: InputMaybe<Progress_Completed_Chapters_Min_Order_By>;
  stddev?: InputMaybe<Progress_Completed_Chapters_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Progress_Completed_Chapters_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Progress_Completed_Chapters_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Progress_Completed_Chapters_Sum_Order_By>;
  var_pop?: InputMaybe<Progress_Completed_Chapters_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Progress_Completed_Chapters_Var_Samp_Order_By>;
  variance?: InputMaybe<Progress_Completed_Chapters_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "progress.completed_chapters" */
export type Progress_Completed_Chapters_Arr_Rel_Insert_Input = {
  data: Array<Progress_Completed_Chapters_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Progress_Completed_Chapters_On_Conflict>;
};

/** aggregate avg on columns */
export type Progress_Completed_Chapters_Avg_Fields = {
  __typename?: 'progress_completed_chapters_avg_fields';
  chapter_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "progress.completed_chapters" */
export type Progress_Completed_Chapters_Avg_Order_By = {
  chapter_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "progress.completed_chapters". All fields are combined with a logical 'AND'. */
export type Progress_Completed_Chapters_Bool_Exp = {
  _and?: InputMaybe<Array<Progress_Completed_Chapters_Bool_Exp>>;
  _not?: InputMaybe<Progress_Completed_Chapters_Bool_Exp>;
  _or?: InputMaybe<Array<Progress_Completed_Chapters_Bool_Exp>>;
  chapter?: InputMaybe<Content_Chapters_Bool_Exp>;
  chapter_id?: InputMaybe<Int_Comparison_Exp>;
  last_completed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_details?: InputMaybe<User_Details_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "progress.completed_chapters" */
export enum Progress_Completed_Chapters_Constraint {
  /** unique or primary key constraint on columns "user_id", "chapter_id" */
  CompletedChaptersPkey = 'completed_chapters_pkey'
}

/** input type for incrementing numeric columns in table "progress.completed_chapters" */
export type Progress_Completed_Chapters_Inc_Input = {
  chapter_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "progress.completed_chapters" */
export type Progress_Completed_Chapters_Insert_Input = {
  chapter?: InputMaybe<Content_Chapters_Obj_Rel_Insert_Input>;
  chapter_id?: InputMaybe<Scalars['Int']>;
  last_completed_at?: InputMaybe<Scalars['timestamptz']>;
  user_details?: InputMaybe<User_Details_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Progress_Completed_Chapters_Max_Fields = {
  __typename?: 'progress_completed_chapters_max_fields';
  chapter_id?: Maybe<Scalars['Int']>;
  last_completed_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "progress.completed_chapters" */
export type Progress_Completed_Chapters_Max_Order_By = {
  chapter_id?: InputMaybe<Order_By>;
  last_completed_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Progress_Completed_Chapters_Min_Fields = {
  __typename?: 'progress_completed_chapters_min_fields';
  chapter_id?: Maybe<Scalars['Int']>;
  last_completed_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "progress.completed_chapters" */
export type Progress_Completed_Chapters_Min_Order_By = {
  chapter_id?: InputMaybe<Order_By>;
  last_completed_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "progress.completed_chapters" */
export type Progress_Completed_Chapters_Mutation_Response = {
  __typename?: 'progress_completed_chapters_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Progress_Completed_Chapters>;
};

/** input type for inserting object relation for remote table "progress.completed_chapters" */
export type Progress_Completed_Chapters_Obj_Rel_Insert_Input = {
  data: Progress_Completed_Chapters_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Progress_Completed_Chapters_On_Conflict>;
};

/** on_conflict condition type for table "progress.completed_chapters" */
export type Progress_Completed_Chapters_On_Conflict = {
  constraint: Progress_Completed_Chapters_Constraint;
  update_columns?: Array<Progress_Completed_Chapters_Update_Column>;
  where?: InputMaybe<Progress_Completed_Chapters_Bool_Exp>;
};

/** Ordering options when selecting data from "progress.completed_chapters". */
export type Progress_Completed_Chapters_Order_By = {
  chapter?: InputMaybe<Content_Chapters_Order_By>;
  chapter_id?: InputMaybe<Order_By>;
  last_completed_at?: InputMaybe<Order_By>;
  user_details?: InputMaybe<User_Details_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: progress.completed_chapters */
export type Progress_Completed_Chapters_Pk_Columns_Input = {
  chapter_id: Scalars['Int'];
  user_id: Scalars['uuid'];
};

/** select columns of table "progress.completed_chapters" */
export enum Progress_Completed_Chapters_Select_Column {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  LastCompletedAt = 'last_completed_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "progress.completed_chapters" */
export type Progress_Completed_Chapters_Set_Input = {
  chapter_id?: InputMaybe<Scalars['Int']>;
  last_completed_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Progress_Completed_Chapters_Stddev_Fields = {
  __typename?: 'progress_completed_chapters_stddev_fields';
  chapter_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "progress.completed_chapters" */
export type Progress_Completed_Chapters_Stddev_Order_By = {
  chapter_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Progress_Completed_Chapters_Stddev_Pop_Fields = {
  __typename?: 'progress_completed_chapters_stddev_pop_fields';
  chapter_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "progress.completed_chapters" */
export type Progress_Completed_Chapters_Stddev_Pop_Order_By = {
  chapter_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Progress_Completed_Chapters_Stddev_Samp_Fields = {
  __typename?: 'progress_completed_chapters_stddev_samp_fields';
  chapter_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "progress.completed_chapters" */
export type Progress_Completed_Chapters_Stddev_Samp_Order_By = {
  chapter_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "progress_completed_chapters" */
export type Progress_Completed_Chapters_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Progress_Completed_Chapters_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Progress_Completed_Chapters_Stream_Cursor_Value_Input = {
  chapter_id?: InputMaybe<Scalars['Int']>;
  last_completed_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Progress_Completed_Chapters_Sum_Fields = {
  __typename?: 'progress_completed_chapters_sum_fields';
  chapter_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "progress.completed_chapters" */
export type Progress_Completed_Chapters_Sum_Order_By = {
  chapter_id?: InputMaybe<Order_By>;
};

/** update columns of table "progress.completed_chapters" */
export enum Progress_Completed_Chapters_Update_Column {
  /** column name */
  ChapterId = 'chapter_id',
  /** column name */
  LastCompletedAt = 'last_completed_at',
  /** column name */
  UserId = 'user_id'
}

export type Progress_Completed_Chapters_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Progress_Completed_Chapters_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Progress_Completed_Chapters_Set_Input>;
  /** filter the rows which have to be updated */
  where: Progress_Completed_Chapters_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Progress_Completed_Chapters_Var_Pop_Fields = {
  __typename?: 'progress_completed_chapters_var_pop_fields';
  chapter_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "progress.completed_chapters" */
export type Progress_Completed_Chapters_Var_Pop_Order_By = {
  chapter_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Progress_Completed_Chapters_Var_Samp_Fields = {
  __typename?: 'progress_completed_chapters_var_samp_fields';
  chapter_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "progress.completed_chapters" */
export type Progress_Completed_Chapters_Var_Samp_Order_By = {
  chapter_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Progress_Completed_Chapters_Variance_Fields = {
  __typename?: 'progress_completed_chapters_variance_fields';
  chapter_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "progress.completed_chapters" */
export type Progress_Completed_Chapters_Variance_Order_By = {
  chapter_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "progress.completed_contactpoints" */
export type Progress_Completed_Contactpoints = {
  __typename?: 'progress_completed_contactpoints';
  /** An object relationship */
  contactpoint: Content_Contactpoints;
  contactpoint_id: Scalars['Int'];
  last_completed_at: Scalars['timestamptz'];
  /** An object relationship */
  user_details: User_Details;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "progress.completed_contactpoints" */
export type Progress_Completed_Contactpoints_Aggregate = {
  __typename?: 'progress_completed_contactpoints_aggregate';
  aggregate?: Maybe<Progress_Completed_Contactpoints_Aggregate_Fields>;
  nodes: Array<Progress_Completed_Contactpoints>;
};

export type Progress_Completed_Contactpoints_Aggregate_Bool_Exp = {
  count?: InputMaybe<Progress_Completed_Contactpoints_Aggregate_Bool_Exp_Count>;
};

export type Progress_Completed_Contactpoints_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Progress_Completed_Contactpoints_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Progress_Completed_Contactpoints_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "progress.completed_contactpoints" */
export type Progress_Completed_Contactpoints_Aggregate_Fields = {
  __typename?: 'progress_completed_contactpoints_aggregate_fields';
  avg?: Maybe<Progress_Completed_Contactpoints_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Progress_Completed_Contactpoints_Max_Fields>;
  min?: Maybe<Progress_Completed_Contactpoints_Min_Fields>;
  stddev?: Maybe<Progress_Completed_Contactpoints_Stddev_Fields>;
  stddev_pop?: Maybe<Progress_Completed_Contactpoints_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Progress_Completed_Contactpoints_Stddev_Samp_Fields>;
  sum?: Maybe<Progress_Completed_Contactpoints_Sum_Fields>;
  var_pop?: Maybe<Progress_Completed_Contactpoints_Var_Pop_Fields>;
  var_samp?: Maybe<Progress_Completed_Contactpoints_Var_Samp_Fields>;
  variance?: Maybe<Progress_Completed_Contactpoints_Variance_Fields>;
};


/** aggregate fields of "progress.completed_contactpoints" */
export type Progress_Completed_Contactpoints_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Progress_Completed_Contactpoints_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "progress.completed_contactpoints" */
export type Progress_Completed_Contactpoints_Aggregate_Order_By = {
  avg?: InputMaybe<Progress_Completed_Contactpoints_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Progress_Completed_Contactpoints_Max_Order_By>;
  min?: InputMaybe<Progress_Completed_Contactpoints_Min_Order_By>;
  stddev?: InputMaybe<Progress_Completed_Contactpoints_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Progress_Completed_Contactpoints_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Progress_Completed_Contactpoints_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Progress_Completed_Contactpoints_Sum_Order_By>;
  var_pop?: InputMaybe<Progress_Completed_Contactpoints_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Progress_Completed_Contactpoints_Var_Samp_Order_By>;
  variance?: InputMaybe<Progress_Completed_Contactpoints_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "progress.completed_contactpoints" */
export type Progress_Completed_Contactpoints_Arr_Rel_Insert_Input = {
  data: Array<Progress_Completed_Contactpoints_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Progress_Completed_Contactpoints_On_Conflict>;
};

/** aggregate avg on columns */
export type Progress_Completed_Contactpoints_Avg_Fields = {
  __typename?: 'progress_completed_contactpoints_avg_fields';
  contactpoint_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "progress.completed_contactpoints" */
export type Progress_Completed_Contactpoints_Avg_Order_By = {
  contactpoint_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "progress.completed_contactpoints". All fields are combined with a logical 'AND'. */
export type Progress_Completed_Contactpoints_Bool_Exp = {
  _and?: InputMaybe<Array<Progress_Completed_Contactpoints_Bool_Exp>>;
  _not?: InputMaybe<Progress_Completed_Contactpoints_Bool_Exp>;
  _or?: InputMaybe<Array<Progress_Completed_Contactpoints_Bool_Exp>>;
  contactpoint?: InputMaybe<Content_Contactpoints_Bool_Exp>;
  contactpoint_id?: InputMaybe<Int_Comparison_Exp>;
  last_completed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_details?: InputMaybe<User_Details_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "progress.completed_contactpoints" */
export enum Progress_Completed_Contactpoints_Constraint {
  /** unique or primary key constraint on columns "user_id", "contactpoint_id" */
  CompletedContactpointsPkey = 'completed_contactpoints_pkey'
}

/** input type for incrementing numeric columns in table "progress.completed_contactpoints" */
export type Progress_Completed_Contactpoints_Inc_Input = {
  contactpoint_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "progress.completed_contactpoints" */
export type Progress_Completed_Contactpoints_Insert_Input = {
  contactpoint?: InputMaybe<Content_Contactpoints_Obj_Rel_Insert_Input>;
  contactpoint_id?: InputMaybe<Scalars['Int']>;
  last_completed_at?: InputMaybe<Scalars['timestamptz']>;
  user_details?: InputMaybe<User_Details_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Progress_Completed_Contactpoints_Max_Fields = {
  __typename?: 'progress_completed_contactpoints_max_fields';
  contactpoint_id?: Maybe<Scalars['Int']>;
  last_completed_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "progress.completed_contactpoints" */
export type Progress_Completed_Contactpoints_Max_Order_By = {
  contactpoint_id?: InputMaybe<Order_By>;
  last_completed_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Progress_Completed_Contactpoints_Min_Fields = {
  __typename?: 'progress_completed_contactpoints_min_fields';
  contactpoint_id?: Maybe<Scalars['Int']>;
  last_completed_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "progress.completed_contactpoints" */
export type Progress_Completed_Contactpoints_Min_Order_By = {
  contactpoint_id?: InputMaybe<Order_By>;
  last_completed_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "progress.completed_contactpoints" */
export type Progress_Completed_Contactpoints_Mutation_Response = {
  __typename?: 'progress_completed_contactpoints_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Progress_Completed_Contactpoints>;
};

/** input type for inserting object relation for remote table "progress.completed_contactpoints" */
export type Progress_Completed_Contactpoints_Obj_Rel_Insert_Input = {
  data: Progress_Completed_Contactpoints_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Progress_Completed_Contactpoints_On_Conflict>;
};

/** on_conflict condition type for table "progress.completed_contactpoints" */
export type Progress_Completed_Contactpoints_On_Conflict = {
  constraint: Progress_Completed_Contactpoints_Constraint;
  update_columns?: Array<Progress_Completed_Contactpoints_Update_Column>;
  where?: InputMaybe<Progress_Completed_Contactpoints_Bool_Exp>;
};

/** Ordering options when selecting data from "progress.completed_contactpoints". */
export type Progress_Completed_Contactpoints_Order_By = {
  contactpoint?: InputMaybe<Content_Contactpoints_Order_By>;
  contactpoint_id?: InputMaybe<Order_By>;
  last_completed_at?: InputMaybe<Order_By>;
  user_details?: InputMaybe<User_Details_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: progress.completed_contactpoints */
export type Progress_Completed_Contactpoints_Pk_Columns_Input = {
  contactpoint_id: Scalars['Int'];
  user_id: Scalars['uuid'];
};

/** select columns of table "progress.completed_contactpoints" */
export enum Progress_Completed_Contactpoints_Select_Column {
  /** column name */
  ContactpointId = 'contactpoint_id',
  /** column name */
  LastCompletedAt = 'last_completed_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "progress.completed_contactpoints" */
export type Progress_Completed_Contactpoints_Set_Input = {
  contactpoint_id?: InputMaybe<Scalars['Int']>;
  last_completed_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Progress_Completed_Contactpoints_Stddev_Fields = {
  __typename?: 'progress_completed_contactpoints_stddev_fields';
  contactpoint_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "progress.completed_contactpoints" */
export type Progress_Completed_Contactpoints_Stddev_Order_By = {
  contactpoint_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Progress_Completed_Contactpoints_Stddev_Pop_Fields = {
  __typename?: 'progress_completed_contactpoints_stddev_pop_fields';
  contactpoint_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "progress.completed_contactpoints" */
export type Progress_Completed_Contactpoints_Stddev_Pop_Order_By = {
  contactpoint_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Progress_Completed_Contactpoints_Stddev_Samp_Fields = {
  __typename?: 'progress_completed_contactpoints_stddev_samp_fields';
  contactpoint_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "progress.completed_contactpoints" */
export type Progress_Completed_Contactpoints_Stddev_Samp_Order_By = {
  contactpoint_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "progress_completed_contactpoints" */
export type Progress_Completed_Contactpoints_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Progress_Completed_Contactpoints_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Progress_Completed_Contactpoints_Stream_Cursor_Value_Input = {
  contactpoint_id?: InputMaybe<Scalars['Int']>;
  last_completed_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Progress_Completed_Contactpoints_Sum_Fields = {
  __typename?: 'progress_completed_contactpoints_sum_fields';
  contactpoint_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "progress.completed_contactpoints" */
export type Progress_Completed_Contactpoints_Sum_Order_By = {
  contactpoint_id?: InputMaybe<Order_By>;
};

/** update columns of table "progress.completed_contactpoints" */
export enum Progress_Completed_Contactpoints_Update_Column {
  /** column name */
  ContactpointId = 'contactpoint_id',
  /** column name */
  LastCompletedAt = 'last_completed_at',
  /** column name */
  UserId = 'user_id'
}

export type Progress_Completed_Contactpoints_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Progress_Completed_Contactpoints_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Progress_Completed_Contactpoints_Set_Input>;
  /** filter the rows which have to be updated */
  where: Progress_Completed_Contactpoints_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Progress_Completed_Contactpoints_Var_Pop_Fields = {
  __typename?: 'progress_completed_contactpoints_var_pop_fields';
  contactpoint_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "progress.completed_contactpoints" */
export type Progress_Completed_Contactpoints_Var_Pop_Order_By = {
  contactpoint_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Progress_Completed_Contactpoints_Var_Samp_Fields = {
  __typename?: 'progress_completed_contactpoints_var_samp_fields';
  contactpoint_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "progress.completed_contactpoints" */
export type Progress_Completed_Contactpoints_Var_Samp_Order_By = {
  contactpoint_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Progress_Completed_Contactpoints_Variance_Fields = {
  __typename?: 'progress_completed_contactpoints_variance_fields';
  contactpoint_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "progress.completed_contactpoints" */
export type Progress_Completed_Contactpoints_Variance_Order_By = {
  contactpoint_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "progress.completed_lections" */
export type Progress_Completed_Lections = {
  __typename?: 'progress_completed_lections';
  last_completed_at: Scalars['timestamptz'];
  /** An object relationship */
  lection: Content_Lections;
  lection_id: Scalars['Int'];
  /** An object relationship */
  user_details: User_Details;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "progress.completed_lections" */
export type Progress_Completed_Lections_Aggregate = {
  __typename?: 'progress_completed_lections_aggregate';
  aggregate?: Maybe<Progress_Completed_Lections_Aggregate_Fields>;
  nodes: Array<Progress_Completed_Lections>;
};

export type Progress_Completed_Lections_Aggregate_Bool_Exp = {
  count?: InputMaybe<Progress_Completed_Lections_Aggregate_Bool_Exp_Count>;
};

export type Progress_Completed_Lections_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Progress_Completed_Lections_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Progress_Completed_Lections_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "progress.completed_lections" */
export type Progress_Completed_Lections_Aggregate_Fields = {
  __typename?: 'progress_completed_lections_aggregate_fields';
  avg?: Maybe<Progress_Completed_Lections_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Progress_Completed_Lections_Max_Fields>;
  min?: Maybe<Progress_Completed_Lections_Min_Fields>;
  stddev?: Maybe<Progress_Completed_Lections_Stddev_Fields>;
  stddev_pop?: Maybe<Progress_Completed_Lections_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Progress_Completed_Lections_Stddev_Samp_Fields>;
  sum?: Maybe<Progress_Completed_Lections_Sum_Fields>;
  var_pop?: Maybe<Progress_Completed_Lections_Var_Pop_Fields>;
  var_samp?: Maybe<Progress_Completed_Lections_Var_Samp_Fields>;
  variance?: Maybe<Progress_Completed_Lections_Variance_Fields>;
};


/** aggregate fields of "progress.completed_lections" */
export type Progress_Completed_Lections_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Progress_Completed_Lections_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "progress.completed_lections" */
export type Progress_Completed_Lections_Aggregate_Order_By = {
  avg?: InputMaybe<Progress_Completed_Lections_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Progress_Completed_Lections_Max_Order_By>;
  min?: InputMaybe<Progress_Completed_Lections_Min_Order_By>;
  stddev?: InputMaybe<Progress_Completed_Lections_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Progress_Completed_Lections_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Progress_Completed_Lections_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Progress_Completed_Lections_Sum_Order_By>;
  var_pop?: InputMaybe<Progress_Completed_Lections_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Progress_Completed_Lections_Var_Samp_Order_By>;
  variance?: InputMaybe<Progress_Completed_Lections_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "progress.completed_lections" */
export type Progress_Completed_Lections_Arr_Rel_Insert_Input = {
  data: Array<Progress_Completed_Lections_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Progress_Completed_Lections_On_Conflict>;
};

/** aggregate avg on columns */
export type Progress_Completed_Lections_Avg_Fields = {
  __typename?: 'progress_completed_lections_avg_fields';
  lection_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "progress.completed_lections" */
export type Progress_Completed_Lections_Avg_Order_By = {
  lection_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "progress.completed_lections". All fields are combined with a logical 'AND'. */
export type Progress_Completed_Lections_Bool_Exp = {
  _and?: InputMaybe<Array<Progress_Completed_Lections_Bool_Exp>>;
  _not?: InputMaybe<Progress_Completed_Lections_Bool_Exp>;
  _or?: InputMaybe<Array<Progress_Completed_Lections_Bool_Exp>>;
  last_completed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  lection?: InputMaybe<Content_Lections_Bool_Exp>;
  lection_id?: InputMaybe<Int_Comparison_Exp>;
  user_details?: InputMaybe<User_Details_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "progress.completed_lections" */
export enum Progress_Completed_Lections_Constraint {
  /** unique or primary key constraint on columns "user_id", "lection_id" */
  CompletedLectionsPkey = 'completed_lections_pkey'
}

/** input type for incrementing numeric columns in table "progress.completed_lections" */
export type Progress_Completed_Lections_Inc_Input = {
  lection_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "progress.completed_lections" */
export type Progress_Completed_Lections_Insert_Input = {
  last_completed_at?: InputMaybe<Scalars['timestamptz']>;
  lection?: InputMaybe<Content_Lections_Obj_Rel_Insert_Input>;
  lection_id?: InputMaybe<Scalars['Int']>;
  user_details?: InputMaybe<User_Details_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Progress_Completed_Lections_Max_Fields = {
  __typename?: 'progress_completed_lections_max_fields';
  last_completed_at?: Maybe<Scalars['timestamptz']>;
  lection_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "progress.completed_lections" */
export type Progress_Completed_Lections_Max_Order_By = {
  last_completed_at?: InputMaybe<Order_By>;
  lection_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Progress_Completed_Lections_Min_Fields = {
  __typename?: 'progress_completed_lections_min_fields';
  last_completed_at?: Maybe<Scalars['timestamptz']>;
  lection_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "progress.completed_lections" */
export type Progress_Completed_Lections_Min_Order_By = {
  last_completed_at?: InputMaybe<Order_By>;
  lection_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "progress.completed_lections" */
export type Progress_Completed_Lections_Mutation_Response = {
  __typename?: 'progress_completed_lections_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Progress_Completed_Lections>;
};

/** input type for inserting object relation for remote table "progress.completed_lections" */
export type Progress_Completed_Lections_Obj_Rel_Insert_Input = {
  data: Progress_Completed_Lections_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Progress_Completed_Lections_On_Conflict>;
};

/** on_conflict condition type for table "progress.completed_lections" */
export type Progress_Completed_Lections_On_Conflict = {
  constraint: Progress_Completed_Lections_Constraint;
  update_columns?: Array<Progress_Completed_Lections_Update_Column>;
  where?: InputMaybe<Progress_Completed_Lections_Bool_Exp>;
};

/** Ordering options when selecting data from "progress.completed_lections". */
export type Progress_Completed_Lections_Order_By = {
  last_completed_at?: InputMaybe<Order_By>;
  lection?: InputMaybe<Content_Lections_Order_By>;
  lection_id?: InputMaybe<Order_By>;
  user_details?: InputMaybe<User_Details_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: progress.completed_lections */
export type Progress_Completed_Lections_Pk_Columns_Input = {
  lection_id: Scalars['Int'];
  user_id: Scalars['uuid'];
};

/** select columns of table "progress.completed_lections" */
export enum Progress_Completed_Lections_Select_Column {
  /** column name */
  LastCompletedAt = 'last_completed_at',
  /** column name */
  LectionId = 'lection_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "progress.completed_lections" */
export type Progress_Completed_Lections_Set_Input = {
  last_completed_at?: InputMaybe<Scalars['timestamptz']>;
  lection_id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Progress_Completed_Lections_Stddev_Fields = {
  __typename?: 'progress_completed_lections_stddev_fields';
  lection_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "progress.completed_lections" */
export type Progress_Completed_Lections_Stddev_Order_By = {
  lection_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Progress_Completed_Lections_Stddev_Pop_Fields = {
  __typename?: 'progress_completed_lections_stddev_pop_fields';
  lection_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "progress.completed_lections" */
export type Progress_Completed_Lections_Stddev_Pop_Order_By = {
  lection_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Progress_Completed_Lections_Stddev_Samp_Fields = {
  __typename?: 'progress_completed_lections_stddev_samp_fields';
  lection_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "progress.completed_lections" */
export type Progress_Completed_Lections_Stddev_Samp_Order_By = {
  lection_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "progress_completed_lections" */
export type Progress_Completed_Lections_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Progress_Completed_Lections_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Progress_Completed_Lections_Stream_Cursor_Value_Input = {
  last_completed_at?: InputMaybe<Scalars['timestamptz']>;
  lection_id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Progress_Completed_Lections_Sum_Fields = {
  __typename?: 'progress_completed_lections_sum_fields';
  lection_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "progress.completed_lections" */
export type Progress_Completed_Lections_Sum_Order_By = {
  lection_id?: InputMaybe<Order_By>;
};

/** update columns of table "progress.completed_lections" */
export enum Progress_Completed_Lections_Update_Column {
  /** column name */
  LastCompletedAt = 'last_completed_at',
  /** column name */
  LectionId = 'lection_id',
  /** column name */
  UserId = 'user_id'
}

export type Progress_Completed_Lections_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Progress_Completed_Lections_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Progress_Completed_Lections_Set_Input>;
  /** filter the rows which have to be updated */
  where: Progress_Completed_Lections_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Progress_Completed_Lections_Var_Pop_Fields = {
  __typename?: 'progress_completed_lections_var_pop_fields';
  lection_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "progress.completed_lections" */
export type Progress_Completed_Lections_Var_Pop_Order_By = {
  lection_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Progress_Completed_Lections_Var_Samp_Fields = {
  __typename?: 'progress_completed_lections_var_samp_fields';
  lection_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "progress.completed_lections" */
export type Progress_Completed_Lections_Var_Samp_Order_By = {
  lection_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Progress_Completed_Lections_Variance_Fields = {
  __typename?: 'progress_completed_lections_variance_fields';
  lection_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "progress.completed_lections" */
export type Progress_Completed_Lections_Variance_Order_By = {
  lection_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "progress.contactpoint_overview" */
export type Progress_Contactpoint_Overview = {
  __typename?: 'progress_contactpoint_overview';
  completed_count?: Maybe<Scalars['bigint']>;
  contact_point_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  contactpoint?: Maybe<Content_Contactpoints>;
  /** An object relationship */
  final_quiz?: Maybe<Content_Quizzes>;
  final_quiz_id?: Maybe<Scalars['Int']>;
  is_completed?: Maybe<Scalars['Boolean']>;
  is_quiz_locked?: Maybe<Scalars['Boolean']>;
  last_completed_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  lections_overview: Array<Progress_Lection_Overview>;
  /** An aggregate relationship */
  lections_overview_aggregate: Progress_Lection_Overview_Aggregate;
  total_count?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  user?: Maybe<User_Details>;
  user_id?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "progress.contactpoint_overview" */
export type Progress_Contactpoint_OverviewLections_OverviewArgs = {
  distinct_on?: InputMaybe<Array<Progress_Lection_Overview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Progress_Lection_Overview_Order_By>>;
  where?: InputMaybe<Progress_Lection_Overview_Bool_Exp>;
};


/** columns and relationships of "progress.contactpoint_overview" */
export type Progress_Contactpoint_OverviewLections_Overview_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Progress_Lection_Overview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Progress_Lection_Overview_Order_By>>;
  where?: InputMaybe<Progress_Lection_Overview_Bool_Exp>;
};

/** aggregated selection of "progress.contactpoint_overview" */
export type Progress_Contactpoint_Overview_Aggregate = {
  __typename?: 'progress_contactpoint_overview_aggregate';
  aggregate?: Maybe<Progress_Contactpoint_Overview_Aggregate_Fields>;
  nodes: Array<Progress_Contactpoint_Overview>;
};

/** aggregate fields of "progress.contactpoint_overview" */
export type Progress_Contactpoint_Overview_Aggregate_Fields = {
  __typename?: 'progress_contactpoint_overview_aggregate_fields';
  avg?: Maybe<Progress_Contactpoint_Overview_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Progress_Contactpoint_Overview_Max_Fields>;
  min?: Maybe<Progress_Contactpoint_Overview_Min_Fields>;
  stddev?: Maybe<Progress_Contactpoint_Overview_Stddev_Fields>;
  stddev_pop?: Maybe<Progress_Contactpoint_Overview_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Progress_Contactpoint_Overview_Stddev_Samp_Fields>;
  sum?: Maybe<Progress_Contactpoint_Overview_Sum_Fields>;
  var_pop?: Maybe<Progress_Contactpoint_Overview_Var_Pop_Fields>;
  var_samp?: Maybe<Progress_Contactpoint_Overview_Var_Samp_Fields>;
  variance?: Maybe<Progress_Contactpoint_Overview_Variance_Fields>;
};


/** aggregate fields of "progress.contactpoint_overview" */
export type Progress_Contactpoint_Overview_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Progress_Contactpoint_Overview_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Progress_Contactpoint_Overview_Avg_Fields = {
  __typename?: 'progress_contactpoint_overview_avg_fields';
  completed_count?: Maybe<Scalars['Float']>;
  contact_point_id?: Maybe<Scalars['Float']>;
  final_quiz_id?: Maybe<Scalars['Float']>;
  total_count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "progress.contactpoint_overview". All fields are combined with a logical 'AND'. */
export type Progress_Contactpoint_Overview_Bool_Exp = {
  _and?: InputMaybe<Array<Progress_Contactpoint_Overview_Bool_Exp>>;
  _not?: InputMaybe<Progress_Contactpoint_Overview_Bool_Exp>;
  _or?: InputMaybe<Array<Progress_Contactpoint_Overview_Bool_Exp>>;
  completed_count?: InputMaybe<Bigint_Comparison_Exp>;
  contact_point_id?: InputMaybe<Int_Comparison_Exp>;
  contactpoint?: InputMaybe<Content_Contactpoints_Bool_Exp>;
  final_quiz?: InputMaybe<Content_Quizzes_Bool_Exp>;
  final_quiz_id?: InputMaybe<Int_Comparison_Exp>;
  is_completed?: InputMaybe<Boolean_Comparison_Exp>;
  is_quiz_locked?: InputMaybe<Boolean_Comparison_Exp>;
  last_completed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  lections_overview?: InputMaybe<Progress_Lection_Overview_Bool_Exp>;
  lections_overview_aggregate?: InputMaybe<Progress_Lection_Overview_Aggregate_Bool_Exp>;
  total_count?: InputMaybe<Bigint_Comparison_Exp>;
  user?: InputMaybe<User_Details_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type Progress_Contactpoint_Overview_Max_Fields = {
  __typename?: 'progress_contactpoint_overview_max_fields';
  completed_count?: Maybe<Scalars['bigint']>;
  contact_point_id?: Maybe<Scalars['Int']>;
  final_quiz_id?: Maybe<Scalars['Int']>;
  last_completed_at?: Maybe<Scalars['timestamptz']>;
  total_count?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Progress_Contactpoint_Overview_Min_Fields = {
  __typename?: 'progress_contactpoint_overview_min_fields';
  completed_count?: Maybe<Scalars['bigint']>;
  contact_point_id?: Maybe<Scalars['Int']>;
  final_quiz_id?: Maybe<Scalars['Int']>;
  last_completed_at?: Maybe<Scalars['timestamptz']>;
  total_count?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "progress.contactpoint_overview". */
export type Progress_Contactpoint_Overview_Order_By = {
  completed_count?: InputMaybe<Order_By>;
  contact_point_id?: InputMaybe<Order_By>;
  contactpoint?: InputMaybe<Content_Contactpoints_Order_By>;
  final_quiz?: InputMaybe<Content_Quizzes_Order_By>;
  final_quiz_id?: InputMaybe<Order_By>;
  is_completed?: InputMaybe<Order_By>;
  is_quiz_locked?: InputMaybe<Order_By>;
  last_completed_at?: InputMaybe<Order_By>;
  lections_overview_aggregate?: InputMaybe<Progress_Lection_Overview_Aggregate_Order_By>;
  total_count?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Details_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "progress.contactpoint_overview" */
export enum Progress_Contactpoint_Overview_Select_Column {
  /** column name */
  CompletedCount = 'completed_count',
  /** column name */
  ContactPointId = 'contact_point_id',
  /** column name */
  FinalQuizId = 'final_quiz_id',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  IsQuizLocked = 'is_quiz_locked',
  /** column name */
  LastCompletedAt = 'last_completed_at',
  /** column name */
  TotalCount = 'total_count',
  /** column name */
  UserId = 'user_id'
}

/** aggregate stddev on columns */
export type Progress_Contactpoint_Overview_Stddev_Fields = {
  __typename?: 'progress_contactpoint_overview_stddev_fields';
  completed_count?: Maybe<Scalars['Float']>;
  contact_point_id?: Maybe<Scalars['Float']>;
  final_quiz_id?: Maybe<Scalars['Float']>;
  total_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Progress_Contactpoint_Overview_Stddev_Pop_Fields = {
  __typename?: 'progress_contactpoint_overview_stddev_pop_fields';
  completed_count?: Maybe<Scalars['Float']>;
  contact_point_id?: Maybe<Scalars['Float']>;
  final_quiz_id?: Maybe<Scalars['Float']>;
  total_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Progress_Contactpoint_Overview_Stddev_Samp_Fields = {
  __typename?: 'progress_contactpoint_overview_stddev_samp_fields';
  completed_count?: Maybe<Scalars['Float']>;
  contact_point_id?: Maybe<Scalars['Float']>;
  final_quiz_id?: Maybe<Scalars['Float']>;
  total_count?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "progress_contactpoint_overview" */
export type Progress_Contactpoint_Overview_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Progress_Contactpoint_Overview_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Progress_Contactpoint_Overview_Stream_Cursor_Value_Input = {
  completed_count?: InputMaybe<Scalars['bigint']>;
  contact_point_id?: InputMaybe<Scalars['Int']>;
  final_quiz_id?: InputMaybe<Scalars['Int']>;
  is_completed?: InputMaybe<Scalars['Boolean']>;
  is_quiz_locked?: InputMaybe<Scalars['Boolean']>;
  last_completed_at?: InputMaybe<Scalars['timestamptz']>;
  total_count?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Progress_Contactpoint_Overview_Sum_Fields = {
  __typename?: 'progress_contactpoint_overview_sum_fields';
  completed_count?: Maybe<Scalars['bigint']>;
  contact_point_id?: Maybe<Scalars['Int']>;
  final_quiz_id?: Maybe<Scalars['Int']>;
  total_count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Progress_Contactpoint_Overview_Var_Pop_Fields = {
  __typename?: 'progress_contactpoint_overview_var_pop_fields';
  completed_count?: Maybe<Scalars['Float']>;
  contact_point_id?: Maybe<Scalars['Float']>;
  final_quiz_id?: Maybe<Scalars['Float']>;
  total_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Progress_Contactpoint_Overview_Var_Samp_Fields = {
  __typename?: 'progress_contactpoint_overview_var_samp_fields';
  completed_count?: Maybe<Scalars['Float']>;
  contact_point_id?: Maybe<Scalars['Float']>;
  final_quiz_id?: Maybe<Scalars['Float']>;
  total_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Progress_Contactpoint_Overview_Variance_Fields = {
  __typename?: 'progress_contactpoint_overview_variance_fields';
  completed_count?: Maybe<Scalars['Float']>;
  contact_point_id?: Maybe<Scalars['Float']>;
  final_quiz_id?: Maybe<Scalars['Float']>;
  total_count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "progress.lection_overview" */
export type Progress_Lection_Overview = {
  __typename?: 'progress_lection_overview';
  completed_count?: Maybe<Scalars['bigint']>;
  contact_point_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  contactpoint?: Maybe<Content_Contactpoints>;
  is_completed?: Maybe<Scalars['Boolean']>;
  is_locked?: Maybe<Scalars['Boolean']>;
  last_completed_at?: Maybe<Scalars['timestamptz']>;
  last_viewed?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  lection?: Maybe<Content_Lections>;
  lection_id?: Maybe<Scalars['Int']>;
  lection_viewed?: Maybe<Scalars['Boolean']>;
  total_count?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  user?: Maybe<User_Details>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "progress.lection_overview" */
export type Progress_Lection_Overview_Aggregate = {
  __typename?: 'progress_lection_overview_aggregate';
  aggregate?: Maybe<Progress_Lection_Overview_Aggregate_Fields>;
  nodes: Array<Progress_Lection_Overview>;
};

export type Progress_Lection_Overview_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Progress_Lection_Overview_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Progress_Lection_Overview_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Progress_Lection_Overview_Aggregate_Bool_Exp_Count>;
};

export type Progress_Lection_Overview_Aggregate_Bool_Exp_Bool_And = {
  arguments: Progress_Lection_Overview_Select_Column_Progress_Lection_Overview_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Progress_Lection_Overview_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Progress_Lection_Overview_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Progress_Lection_Overview_Select_Column_Progress_Lection_Overview_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Progress_Lection_Overview_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Progress_Lection_Overview_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Progress_Lection_Overview_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Progress_Lection_Overview_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "progress.lection_overview" */
export type Progress_Lection_Overview_Aggregate_Fields = {
  __typename?: 'progress_lection_overview_aggregate_fields';
  avg?: Maybe<Progress_Lection_Overview_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Progress_Lection_Overview_Max_Fields>;
  min?: Maybe<Progress_Lection_Overview_Min_Fields>;
  stddev?: Maybe<Progress_Lection_Overview_Stddev_Fields>;
  stddev_pop?: Maybe<Progress_Lection_Overview_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Progress_Lection_Overview_Stddev_Samp_Fields>;
  sum?: Maybe<Progress_Lection_Overview_Sum_Fields>;
  var_pop?: Maybe<Progress_Lection_Overview_Var_Pop_Fields>;
  var_samp?: Maybe<Progress_Lection_Overview_Var_Samp_Fields>;
  variance?: Maybe<Progress_Lection_Overview_Variance_Fields>;
};


/** aggregate fields of "progress.lection_overview" */
export type Progress_Lection_Overview_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Progress_Lection_Overview_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "progress.lection_overview" */
export type Progress_Lection_Overview_Aggregate_Order_By = {
  avg?: InputMaybe<Progress_Lection_Overview_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Progress_Lection_Overview_Max_Order_By>;
  min?: InputMaybe<Progress_Lection_Overview_Min_Order_By>;
  stddev?: InputMaybe<Progress_Lection_Overview_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Progress_Lection_Overview_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Progress_Lection_Overview_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Progress_Lection_Overview_Sum_Order_By>;
  var_pop?: InputMaybe<Progress_Lection_Overview_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Progress_Lection_Overview_Var_Samp_Order_By>;
  variance?: InputMaybe<Progress_Lection_Overview_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Progress_Lection_Overview_Avg_Fields = {
  __typename?: 'progress_lection_overview_avg_fields';
  completed_count?: Maybe<Scalars['Float']>;
  contact_point_id?: Maybe<Scalars['Float']>;
  lection_id?: Maybe<Scalars['Float']>;
  total_count?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "progress.lection_overview" */
export type Progress_Lection_Overview_Avg_Order_By = {
  completed_count?: InputMaybe<Order_By>;
  contact_point_id?: InputMaybe<Order_By>;
  lection_id?: InputMaybe<Order_By>;
  total_count?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "progress.lection_overview". All fields are combined with a logical 'AND'. */
export type Progress_Lection_Overview_Bool_Exp = {
  _and?: InputMaybe<Array<Progress_Lection_Overview_Bool_Exp>>;
  _not?: InputMaybe<Progress_Lection_Overview_Bool_Exp>;
  _or?: InputMaybe<Array<Progress_Lection_Overview_Bool_Exp>>;
  completed_count?: InputMaybe<Bigint_Comparison_Exp>;
  contact_point_id?: InputMaybe<Int_Comparison_Exp>;
  contactpoint?: InputMaybe<Content_Contactpoints_Bool_Exp>;
  is_completed?: InputMaybe<Boolean_Comparison_Exp>;
  is_locked?: InputMaybe<Boolean_Comparison_Exp>;
  last_completed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  last_viewed?: InputMaybe<Timestamptz_Comparison_Exp>;
  lection?: InputMaybe<Content_Lections_Bool_Exp>;
  lection_id?: InputMaybe<Int_Comparison_Exp>;
  lection_viewed?: InputMaybe<Boolean_Comparison_Exp>;
  total_count?: InputMaybe<Bigint_Comparison_Exp>;
  user?: InputMaybe<User_Details_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type Progress_Lection_Overview_Max_Fields = {
  __typename?: 'progress_lection_overview_max_fields';
  completed_count?: Maybe<Scalars['bigint']>;
  contact_point_id?: Maybe<Scalars['Int']>;
  last_completed_at?: Maybe<Scalars['timestamptz']>;
  last_viewed?: Maybe<Scalars['timestamptz']>;
  lection_id?: Maybe<Scalars['Int']>;
  total_count?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "progress.lection_overview" */
export type Progress_Lection_Overview_Max_Order_By = {
  completed_count?: InputMaybe<Order_By>;
  contact_point_id?: InputMaybe<Order_By>;
  last_completed_at?: InputMaybe<Order_By>;
  last_viewed?: InputMaybe<Order_By>;
  lection_id?: InputMaybe<Order_By>;
  total_count?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Progress_Lection_Overview_Min_Fields = {
  __typename?: 'progress_lection_overview_min_fields';
  completed_count?: Maybe<Scalars['bigint']>;
  contact_point_id?: Maybe<Scalars['Int']>;
  last_completed_at?: Maybe<Scalars['timestamptz']>;
  last_viewed?: Maybe<Scalars['timestamptz']>;
  lection_id?: Maybe<Scalars['Int']>;
  total_count?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "progress.lection_overview" */
export type Progress_Lection_Overview_Min_Order_By = {
  completed_count?: InputMaybe<Order_By>;
  contact_point_id?: InputMaybe<Order_By>;
  last_completed_at?: InputMaybe<Order_By>;
  last_viewed?: InputMaybe<Order_By>;
  lection_id?: InputMaybe<Order_By>;
  total_count?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "progress.lection_overview". */
export type Progress_Lection_Overview_Order_By = {
  completed_count?: InputMaybe<Order_By>;
  contact_point_id?: InputMaybe<Order_By>;
  contactpoint?: InputMaybe<Content_Contactpoints_Order_By>;
  is_completed?: InputMaybe<Order_By>;
  is_locked?: InputMaybe<Order_By>;
  last_completed_at?: InputMaybe<Order_By>;
  last_viewed?: InputMaybe<Order_By>;
  lection?: InputMaybe<Content_Lections_Order_By>;
  lection_id?: InputMaybe<Order_By>;
  lection_viewed?: InputMaybe<Order_By>;
  total_count?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Details_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "progress.lection_overview" */
export enum Progress_Lection_Overview_Select_Column {
  /** column name */
  CompletedCount = 'completed_count',
  /** column name */
  ContactPointId = 'contact_point_id',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  IsLocked = 'is_locked',
  /** column name */
  LastCompletedAt = 'last_completed_at',
  /** column name */
  LastViewed = 'last_viewed',
  /** column name */
  LectionId = 'lection_id',
  /** column name */
  LectionViewed = 'lection_viewed',
  /** column name */
  TotalCount = 'total_count',
  /** column name */
  UserId = 'user_id'
}

/** select "progress_lection_overview_aggregate_bool_exp_bool_and_arguments_columns" columns of table "progress.lection_overview" */
export enum Progress_Lection_Overview_Select_Column_Progress_Lection_Overview_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  IsLocked = 'is_locked',
  /** column name */
  LectionViewed = 'lection_viewed'
}

/** select "progress_lection_overview_aggregate_bool_exp_bool_or_arguments_columns" columns of table "progress.lection_overview" */
export enum Progress_Lection_Overview_Select_Column_Progress_Lection_Overview_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  IsLocked = 'is_locked',
  /** column name */
  LectionViewed = 'lection_viewed'
}

/** aggregate stddev on columns */
export type Progress_Lection_Overview_Stddev_Fields = {
  __typename?: 'progress_lection_overview_stddev_fields';
  completed_count?: Maybe<Scalars['Float']>;
  contact_point_id?: Maybe<Scalars['Float']>;
  lection_id?: Maybe<Scalars['Float']>;
  total_count?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "progress.lection_overview" */
export type Progress_Lection_Overview_Stddev_Order_By = {
  completed_count?: InputMaybe<Order_By>;
  contact_point_id?: InputMaybe<Order_By>;
  lection_id?: InputMaybe<Order_By>;
  total_count?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Progress_Lection_Overview_Stddev_Pop_Fields = {
  __typename?: 'progress_lection_overview_stddev_pop_fields';
  completed_count?: Maybe<Scalars['Float']>;
  contact_point_id?: Maybe<Scalars['Float']>;
  lection_id?: Maybe<Scalars['Float']>;
  total_count?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "progress.lection_overview" */
export type Progress_Lection_Overview_Stddev_Pop_Order_By = {
  completed_count?: InputMaybe<Order_By>;
  contact_point_id?: InputMaybe<Order_By>;
  lection_id?: InputMaybe<Order_By>;
  total_count?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Progress_Lection_Overview_Stddev_Samp_Fields = {
  __typename?: 'progress_lection_overview_stddev_samp_fields';
  completed_count?: Maybe<Scalars['Float']>;
  contact_point_id?: Maybe<Scalars['Float']>;
  lection_id?: Maybe<Scalars['Float']>;
  total_count?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "progress.lection_overview" */
export type Progress_Lection_Overview_Stddev_Samp_Order_By = {
  completed_count?: InputMaybe<Order_By>;
  contact_point_id?: InputMaybe<Order_By>;
  lection_id?: InputMaybe<Order_By>;
  total_count?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "progress_lection_overview" */
export type Progress_Lection_Overview_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Progress_Lection_Overview_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Progress_Lection_Overview_Stream_Cursor_Value_Input = {
  completed_count?: InputMaybe<Scalars['bigint']>;
  contact_point_id?: InputMaybe<Scalars['Int']>;
  is_completed?: InputMaybe<Scalars['Boolean']>;
  is_locked?: InputMaybe<Scalars['Boolean']>;
  last_completed_at?: InputMaybe<Scalars['timestamptz']>;
  last_viewed?: InputMaybe<Scalars['timestamptz']>;
  lection_id?: InputMaybe<Scalars['Int']>;
  lection_viewed?: InputMaybe<Scalars['Boolean']>;
  total_count?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Progress_Lection_Overview_Sum_Fields = {
  __typename?: 'progress_lection_overview_sum_fields';
  completed_count?: Maybe<Scalars['bigint']>;
  contact_point_id?: Maybe<Scalars['Int']>;
  lection_id?: Maybe<Scalars['Int']>;
  total_count?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "progress.lection_overview" */
export type Progress_Lection_Overview_Sum_Order_By = {
  completed_count?: InputMaybe<Order_By>;
  contact_point_id?: InputMaybe<Order_By>;
  lection_id?: InputMaybe<Order_By>;
  total_count?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Progress_Lection_Overview_Var_Pop_Fields = {
  __typename?: 'progress_lection_overview_var_pop_fields';
  completed_count?: Maybe<Scalars['Float']>;
  contact_point_id?: Maybe<Scalars['Float']>;
  lection_id?: Maybe<Scalars['Float']>;
  total_count?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "progress.lection_overview" */
export type Progress_Lection_Overview_Var_Pop_Order_By = {
  completed_count?: InputMaybe<Order_By>;
  contact_point_id?: InputMaybe<Order_By>;
  lection_id?: InputMaybe<Order_By>;
  total_count?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Progress_Lection_Overview_Var_Samp_Fields = {
  __typename?: 'progress_lection_overview_var_samp_fields';
  completed_count?: Maybe<Scalars['Float']>;
  contact_point_id?: Maybe<Scalars['Float']>;
  lection_id?: Maybe<Scalars['Float']>;
  total_count?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "progress.lection_overview" */
export type Progress_Lection_Overview_Var_Samp_Order_By = {
  completed_count?: InputMaybe<Order_By>;
  contact_point_id?: InputMaybe<Order_By>;
  lection_id?: InputMaybe<Order_By>;
  total_count?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Progress_Lection_Overview_Variance_Fields = {
  __typename?: 'progress_lection_overview_variance_fields';
  completed_count?: Maybe<Scalars['Float']>;
  contact_point_id?: Maybe<Scalars['Float']>;
  lection_id?: Maybe<Scalars['Float']>;
  total_count?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "progress.lection_overview" */
export type Progress_Lection_Overview_Variance_Order_By = {
  completed_count?: InputMaybe<Order_By>;
  contact_point_id?: InputMaybe<Order_By>;
  lection_id?: InputMaybe<Order_By>;
  total_count?: InputMaybe<Order_By>;
};

export type Query_Root = {
  __typename?: 'query_root';
  ContactPoint?: Maybe<ContactPoint>;
  Document?: Maybe<Document>;
  Lection?: Maybe<Lection>;
  Level?: Maybe<Level>;
  MediaTag?: Maybe<MediaTag>;
  Quiz?: Maybe<Quiz>;
  SanityFileAsset?: Maybe<SanityFileAsset>;
  SanityImageAsset?: Maybe<SanityImageAsset>;
  /** fetch data from the table: "aggregation.lectures_completed_by_vendor_per_year" */
  aggregation_lectures_completed_by_vendor_per_year: Array<Aggregation_Lectures_Completed_By_Vendor_Per_Year>;
  /** fetch aggregated fields from the table: "aggregation.lectures_completed_by_vendor_per_year" */
  aggregation_lectures_completed_by_vendor_per_year_aggregate: Aggregation_Lectures_Completed_By_Vendor_Per_Year_Aggregate;
  /** fetch data from the table: "aggregation.required_lections_count" */
  aggregation_required_lections_count: Array<Aggregation_Required_Lections_Count>;
  /** fetch aggregated fields from the table: "aggregation.required_lections_count" */
  aggregation_required_lections_count_aggregate: Aggregation_Required_Lections_Count_Aggregate;
  /** fetch data from the table: "aggregation.requirements_overview" */
  aggregation_requirements_overview: Array<Aggregation_Requirements_Overview>;
  /** fetch aggregated fields from the table: "aggregation.requirements_overview" */
  aggregation_requirements_overview_aggregate: Aggregation_Requirements_Overview_Aggregate;
  /** fetch data from the table: "aggregation.users_chapters" */
  aggregation_users_chapters: Array<Aggregation_Users_Chapters>;
  /** fetch aggregated fields from the table: "aggregation.users_chapters" */
  aggregation_users_chapters_aggregate: Aggregation_Users_Chapters_Aggregate;
  /** fetch data from the table: "aggregation.users_lections" */
  aggregation_users_lections: Array<Aggregation_Users_Lections>;
  /** fetch aggregated fields from the table: "aggregation.users_lections" */
  aggregation_users_lections_aggregate: Aggregation_Users_Lections_Aggregate;
  /** fetch data from the table: "aggregation.users_requirements" */
  aggregation_users_requirements: Array<Aggregation_Users_Requirements>;
  /** fetch aggregated fields from the table: "aggregation.users_requirements" */
  aggregation_users_requirements_aggregate: Aggregation_Users_Requirements_Aggregate;
  allContactPoint: Array<ContactPoint>;
  allDocument: Array<Document>;
  allLection: Array<Lection>;
  allLevel: Array<Level>;
  allMediaTag: Array<MediaTag>;
  allQuiz: Array<Quiz>;
  allSanityFileAsset: Array<SanityFileAsset>;
  allSanityImageAsset: Array<SanityImageAsset>;
  /** fetch data from the table: "answer_enums.all" */
  answer_enums_all: Array<Answer_Enums_All>;
  /** fetch aggregated fields from the table: "answer_enums.all" */
  answer_enums_all_aggregate: Answer_Enums_All_Aggregate;
  /** fetch data from the table: "answer_enums.bicycle_type" */
  answer_enums_bicycle_type: Array<Answer_Enums_Bicycle_Type>;
  /** fetch aggregated fields from the table: "answer_enums.bicycle_type" */
  answer_enums_bicycle_type_aggregate: Answer_Enums_Bicycle_Type_Aggregate;
  /** fetch data from the table: "answer_enums.bicycle_type" using primary key columns */
  answer_enums_bicycle_type_by_pk?: Maybe<Answer_Enums_Bicycle_Type>;
  /** fetch data from the table: "answer_enums.driving_duration" */
  answer_enums_driving_duration: Array<Answer_Enums_Driving_Duration>;
  /** fetch aggregated fields from the table: "answer_enums.driving_duration" */
  answer_enums_driving_duration_aggregate: Answer_Enums_Driving_Duration_Aggregate;
  /** fetch data from the table: "answer_enums.driving_duration" using primary key columns */
  answer_enums_driving_duration_by_pk?: Maybe<Answer_Enums_Driving_Duration>;
  /** fetch data from the table: "answer_enums.driving_frequency" */
  answer_enums_driving_frequency: Array<Answer_Enums_Driving_Frequency>;
  /** fetch aggregated fields from the table: "answer_enums.driving_frequency" */
  answer_enums_driving_frequency_aggregate: Answer_Enums_Driving_Frequency_Aggregate;
  /** fetch data from the table: "answer_enums.driving_frequency" using primary key columns */
  answer_enums_driving_frequency_by_pk?: Maybe<Answer_Enums_Driving_Frequency>;
  /** fetch data from the table: "answer_enums.e_bike" */
  answer_enums_e_bike: Array<Answer_Enums_E_Bike>;
  /** fetch aggregated fields from the table: "answer_enums.e_bike" */
  answer_enums_e_bike_aggregate: Answer_Enums_E_Bike_Aggregate;
  /** fetch data from the table: "answer_enums.e_bike" using primary key columns */
  answer_enums_e_bike_by_pk?: Maybe<Answer_Enums_E_Bike>;
  /** fetch data from the table: "answer_enums.foot_position" */
  answer_enums_foot_position: Array<Answer_Enums_Foot_Position>;
  /** fetch aggregated fields from the table: "answer_enums.foot_position" */
  answer_enums_foot_position_aggregate: Answer_Enums_Foot_Position_Aggregate;
  /** fetch data from the table: "answer_enums.foot_position" using primary key columns */
  answer_enums_foot_position_by_pk?: Maybe<Answer_Enums_Foot_Position>;
  /** fetch data from the table: "answer_enums.foot_problems" */
  answer_enums_foot_problems: Array<Answer_Enums_Foot_Problems>;
  /** fetch aggregated fields from the table: "answer_enums.foot_problems" */
  answer_enums_foot_problems_aggregate: Answer_Enums_Foot_Problems_Aggregate;
  /** fetch data from the table: "answer_enums.foot_problems" using primary key columns */
  answer_enums_foot_problems_by_pk?: Maybe<Answer_Enums_Foot_Problems>;
  /** fetch data from the table: "answer_enums.foot_type" */
  answer_enums_foot_type: Array<Answer_Enums_Foot_Type>;
  /** fetch aggregated fields from the table: "answer_enums.foot_type" */
  answer_enums_foot_type_aggregate: Answer_Enums_Foot_Type_Aggregate;
  /** fetch data from the table: "answer_enums.foot_type" using primary key columns */
  answer_enums_foot_type_by_pk?: Maybe<Answer_Enums_Foot_Type>;
  /** fetch data from the table: "answer_enums.gripping_distance" */
  answer_enums_gripping_distance: Array<Answer_Enums_Gripping_Distance>;
  /** fetch aggregated fields from the table: "answer_enums.gripping_distance" */
  answer_enums_gripping_distance_aggregate: Answer_Enums_Gripping_Distance_Aggregate;
  /** fetch data from the table: "answer_enums.gripping_distance" using primary key columns */
  answer_enums_gripping_distance_by_pk?: Maybe<Answer_Enums_Gripping_Distance>;
  /** fetch data from the table: "answer_enums.hand_problems" */
  answer_enums_hand_problems: Array<Answer_Enums_Hand_Problems>;
  /** fetch aggregated fields from the table: "answer_enums.hand_problems" */
  answer_enums_hand_problems_aggregate: Answer_Enums_Hand_Problems_Aggregate;
  /** fetch data from the table: "answer_enums.hand_problems" using primary key columns */
  answer_enums_hand_problems_by_pk?: Maybe<Answer_Enums_Hand_Problems>;
  /** fetch data from the table: "answer_enums.leg_axis" */
  answer_enums_leg_axis: Array<Answer_Enums_Leg_Axis>;
  /** fetch aggregated fields from the table: "answer_enums.leg_axis" */
  answer_enums_leg_axis_aggregate: Answer_Enums_Leg_Axis_Aggregate;
  /** fetch data from the table: "answer_enums.leg_axis" using primary key columns */
  answer_enums_leg_axis_by_pk?: Maybe<Answer_Enums_Leg_Axis>;
  /** fetch data from the table: "answer_enums.pelvic_problems" */
  answer_enums_pelvic_problems: Array<Answer_Enums_Pelvic_Problems>;
  /** fetch aggregated fields from the table: "answer_enums.pelvic_problems" */
  answer_enums_pelvic_problems_aggregate: Answer_Enums_Pelvic_Problems_Aggregate;
  /** fetch data from the table: "answer_enums.pelvic_problems" using primary key columns */
  answer_enums_pelvic_problems_by_pk?: Maybe<Answer_Enums_Pelvic_Problems>;
  /** fetch data from the table: "answer_enums.seat_position" */
  answer_enums_seat_position: Array<Answer_Enums_Seat_Position>;
  /** fetch aggregated fields from the table: "answer_enums.seat_position" */
  answer_enums_seat_position_aggregate: Answer_Enums_Seat_Position_Aggregate;
  /** fetch data from the table: "answer_enums.seat_position" using primary key columns */
  answer_enums_seat_position_by_pk?: Maybe<Answer_Enums_Seat_Position>;
  /** fetch data from the table: "answer_enums.sex" */
  answer_enums_sex: Array<Answer_Enums_Sex>;
  /** fetch aggregated fields from the table: "answer_enums.sex" */
  answer_enums_sex_aggregate: Answer_Enums_Sex_Aggregate;
  /** fetch data from the table: "answer_enums.sex" using primary key columns */
  answer_enums_sex_by_pk?: Maybe<Answer_Enums_Sex>;
  /** fetch data from the table: "answer_enums.unmatched_answers" */
  answer_enums_unmatched_answers: Array<Answer_Enums_Unmatched_Answers>;
  /** fetch aggregated fields from the table: "answer_enums.unmatched_answers" */
  answer_enums_unmatched_answers_aggregate: Answer_Enums_Unmatched_Answers_Aggregate;
  /** fetch data from the table: "answer_enums.unmatched_enums" */
  answer_enums_unmatched_enums: Array<Answer_Enums_Unmatched_Enums>;
  /** fetch aggregated fields from the table: "answer_enums.unmatched_enums" */
  answer_enums_unmatched_enums_aggregate: Answer_Enums_Unmatched_Enums_Aggregate;
  /** An array relationship */
  answers: Array<Answers>;
  /** An aggregate relationship */
  answers_aggregate: Answers_Aggregate;
  /** fetch data from the table: "answers" using primary key columns */
  answers_by_pk?: Maybe<Answers>;
  /** fetch data from the table: "article_group" */
  article_group: Array<Article_Group>;
  /** fetch aggregated fields from the table: "article_group" */
  article_group_aggregate: Article_Group_Aggregate;
  /** fetch data from the table: "article_group" using primary key columns */
  article_group_by_pk?: Maybe<Article_Group>;
  /** An array relationship */
  article_information_i18n: Array<Article_Information_I18n>;
  /** An aggregate relationship */
  article_information_i18n_aggregate: Article_Information_I18n_Aggregate;
  /** fetch data from the table: "article_information_i18n" using primary key columns */
  article_information_i18n_by_pk?: Maybe<Article_Information_I18n>;
  /** fetch data from the table: "article_ranking" */
  article_ranking: Array<Article_Ranking>;
  /** fetch aggregated fields from the table: "article_ranking" */
  article_ranking_aggregate: Article_Ranking_Aggregate;
  /** fetch data from the table: "article_ranking" using primary key columns */
  article_ranking_by_pk?: Maybe<Article_Ranking>;
  /** fetch data from the table: "article_type" */
  article_type: Array<Article_Type>;
  /** fetch aggregated fields from the table: "article_type" */
  article_type_aggregate: Article_Type_Aggregate;
  /** fetch data from the table: "article_type" using primary key columns */
  article_type_by_pk?: Maybe<Article_Type>;
  /** fetch data from the table: "auth.one_time_token" */
  auth_one_time_token: Array<Auth_One_Time_Token>;
  /** fetch aggregated fields from the table: "auth.one_time_token" */
  auth_one_time_token_aggregate: Auth_One_Time_Token_Aggregate;
  /** fetch data from the table: "auth.one_time_token" using primary key columns */
  auth_one_time_token_by_pk?: Maybe<Auth_One_Time_Token>;
  /** fetch data from the table: "bikecenter_user_info" */
  bikecenter_user_info: Array<Bikecenter_User_Info>;
  /** fetch aggregated fields from the table: "bikecenter_user_info" */
  bikecenter_user_info_aggregate: Bikecenter_User_Info_Aggregate;
  /** fetch data from the table: "bikefitting.bikefitting_session" */
  bikefitting_bikefitting_session: Array<Bikefitting_Bikefitting_Session>;
  /** fetch aggregated fields from the table: "bikefitting.bikefitting_session" */
  bikefitting_bikefitting_session_aggregate: Bikefitting_Bikefitting_Session_Aggregate;
  /** fetch data from the table: "bikefitting.bikefitting_session" using primary key columns */
  bikefitting_bikefitting_session_by_pk?: Maybe<Bikefitting_Bikefitting_Session>;
  /** fetch data from the table: "commission.account" */
  commission_account: Array<Commission_Account>;
  /** fetch aggregated fields from the table: "commission.account" */
  commission_account_aggregate: Commission_Account_Aggregate;
  /** fetch data from the table: "commission.account_balance" */
  commission_account_balance: Array<Commission_Account_Balance>;
  /** fetch aggregated fields from the table: "commission.account_balance" */
  commission_account_balance_aggregate: Commission_Account_Balance_Aggregate;
  /** fetch data from the table: "commission.account" using primary key columns */
  commission_account_by_pk?: Maybe<Commission_Account>;
  /** fetch data from the table: "commission.boost" */
  commission_boost: Array<Commission_Boost>;
  /** fetch aggregated fields from the table: "commission.boost" */
  commission_boost_aggregate: Commission_Boost_Aggregate;
  /** fetch data from the table: "commission.boost" using primary key columns */
  commission_boost_by_pk?: Maybe<Commission_Boost>;
  /** fetch data from the table: "commission.detailed_account_balance" */
  commission_detailed_account_balance: Array<Commission_Detailed_Account_Balance>;
  /** fetch aggregated fields from the table: "commission.detailed_account_balance" */
  commission_detailed_account_balance_aggregate: Commission_Detailed_Account_Balance_Aggregate;
  /** fetch data from the table: "commission.eligible_answers" */
  commission_eligible_answers: Array<Commission_Eligible_Answers>;
  /** fetch aggregated fields from the table: "commission.eligible_answers" */
  commission_eligible_answers_aggregate: Commission_Eligible_Answers_Aggregate;
  /** fetch data from the table: "commission.eligible_orders" */
  commission_eligible_orders: Array<Commission_Eligible_Orders>;
  /** fetch aggregated fields from the table: "commission.eligible_orders" */
  commission_eligible_orders_aggregate: Commission_Eligible_Orders_Aggregate;
  /** fetch data from the table: "commission.payout_log" */
  commission_payout_log: Array<Commission_Payout_Log>;
  /** fetch aggregated fields from the table: "commission.payout_log" */
  commission_payout_log_aggregate: Commission_Payout_Log_Aggregate;
  /** fetch data from the table: "commission.payout_log" using primary key columns */
  commission_payout_log_by_pk?: Maybe<Commission_Payout_Log>;
  /** fetch data from the table: "commission.type" */
  commission_type: Array<Commission_Type>;
  /** fetch aggregated fields from the table: "commission.type" */
  commission_type_aggregate: Commission_Type_Aggregate;
  /** fetch data from the table: "commission.type" using primary key columns */
  commission_type_by_pk?: Maybe<Commission_Type>;
  /** fetch data from the table: "commission.user_overview" */
  commission_user_overview: Array<Commission_User_Overview>;
  /** fetch aggregated fields from the table: "commission.user_overview" */
  commission_user_overview_aggregate: Commission_User_Overview_Aggregate;
  /** fetch data from the table: "commission.vendor_overview" */
  commission_vendor_overview: Array<Commission_Vendor_Overview>;
  /** fetch aggregated fields from the table: "commission.vendor_overview" */
  commission_vendor_overview_aggregate: Commission_Vendor_Overview_Aggregate;
  /** fetch data from the table: "commission.webshop_data" */
  commission_webshop_data: Array<Commission_Webshop_Data>;
  /** fetch aggregated fields from the table: "commission.webshop_data" */
  commission_webshop_data_aggregate: Commission_Webshop_Data_Aggregate;
  /** fetch data from the table: "commission.webshop_data" using primary key columns */
  commission_webshop_data_by_pk?: Maybe<Commission_Webshop_Data>;
  /** fetch data from the table: "content.chapters" */
  content_chapters: Array<Content_Chapters>;
  /** fetch aggregated fields from the table: "content.chapters" */
  content_chapters_aggregate: Content_Chapters_Aggregate;
  /** fetch data from the table: "content.chapters" using primary key columns */
  content_chapters_by_pk?: Maybe<Content_Chapters>;
  /** fetch data from the table: "content.choices" */
  content_choices: Array<Content_Choices>;
  /** fetch aggregated fields from the table: "content.choices" */
  content_choices_aggregate: Content_Choices_Aggregate;
  /** fetch data from the table: "content.choices" using primary key columns */
  content_choices_by_pk?: Maybe<Content_Choices>;
  /** fetch data from the table: "content.contactpoints" */
  content_contactpoints: Array<Content_Contactpoints>;
  /** fetch aggregated fields from the table: "content.contactpoints" */
  content_contactpoints_aggregate: Content_Contactpoints_Aggregate;
  /** fetch data from the table: "content.contactpoints" using primary key columns */
  content_contactpoints_by_pk?: Maybe<Content_Contactpoints>;
  /** fetch data from the table: "content.lection_requirements" */
  content_lection_requirements: Array<Content_Lection_Requirements>;
  /** fetch aggregated fields from the table: "content.lection_requirements" */
  content_lection_requirements_aggregate: Content_Lection_Requirements_Aggregate;
  /** fetch data from the table: "content.lection_requirements" using primary key columns */
  content_lection_requirements_by_pk?: Maybe<Content_Lection_Requirements>;
  /** fetch data from the table: "content.lection_requirements_hierarchical_structure" */
  content_lection_requirements_hierarchical_structure: Array<Content_Lection_Requirements_Hierarchical_Structure>;
  /** fetch aggregated fields from the table: "content.lection_requirements_hierarchical_structure" */
  content_lection_requirements_hierarchical_structure_aggregate: Content_Lection_Requirements_Hierarchical_Structure_Aggregate;
  /** fetch data from the table: "content.lections" */
  content_lections: Array<Content_Lections>;
  /** fetch aggregated fields from the table: "content.lections" */
  content_lections_aggregate: Content_Lections_Aggregate;
  /** fetch data from the table: "content.lections" using primary key columns */
  content_lections_by_pk?: Maybe<Content_Lections>;
  /** fetch data from the table: "content.levels" */
  content_levels: Array<Content_Levels>;
  /** fetch aggregated fields from the table: "content.levels" */
  content_levels_aggregate: Content_Levels_Aggregate;
  /** fetch data from the table: "content.levels" using primary key columns */
  content_levels_by_pk?: Maybe<Content_Levels>;
  /** fetch data from the table: "content.questions" */
  content_questions: Array<Content_Questions>;
  /** fetch aggregated fields from the table: "content.questions" */
  content_questions_aggregate: Content_Questions_Aggregate;
  /** fetch data from the table: "content.questions" using primary key columns */
  content_questions_by_pk?: Maybe<Content_Questions>;
  /** fetch data from the table: "content.quiz_type" */
  content_quiz_type: Array<Content_Quiz_Type>;
  /** fetch aggregated fields from the table: "content.quiz_type" */
  content_quiz_type_aggregate: Content_Quiz_Type_Aggregate;
  /** fetch data from the table: "content.quiz_type" using primary key columns */
  content_quiz_type_by_pk?: Maybe<Content_Quiz_Type>;
  /** fetch data from the table: "content.quizzes" */
  content_quizzes: Array<Content_Quizzes>;
  /** fetch aggregated fields from the table: "content.quizzes" */
  content_quizzes_aggregate: Content_Quizzes_Aggregate;
  /** fetch data from the table: "content.quizzes" using primary key columns */
  content_quizzes_by_pk?: Maybe<Content_Quizzes>;
  /** fetch data from the table: "country_code" */
  country_code: Array<Country_Code>;
  /** fetch aggregated fields from the table: "country_code" */
  country_code_aggregate: Country_Code_Aggregate;
  /** fetch data from the table: "country_code" using primary key columns */
  country_code_by_pk?: Maybe<Country_Code>;
  /** fetch data from the table: "currency" */
  currency: Array<Currency>;
  /** fetch aggregated fields from the table: "currency" */
  currency_aggregate: Currency_Aggregate;
  /** fetch data from the table: "currency" using primary key columns */
  currency_by_pk?: Maybe<Currency>;
  /** fetch data from the table: "current_user" */
  current_user: Array<Current_User>;
  /** fetch aggregated fields from the table: "current_user" */
  current_user_aggregate: Current_User_Aggregate;
  /** fetch data from the table: "customer.answer" */
  customer_answer: Array<Customer_Answer>;
  /** fetch aggregated fields from the table: "customer.answer" */
  customer_answer_aggregate: Customer_Answer_Aggregate;
  /** fetch data from the table: "customer.answer_article_event" */
  customer_answer_article_event: Array<Customer_Answer_Article_Event>;
  /** fetch aggregated fields from the table: "customer.answer_article_event" */
  customer_answer_article_event_aggregate: Customer_Answer_Article_Event_Aggregate;
  /** fetch data from the table: "customer.answer_article_event" using primary key columns */
  customer_answer_article_event_by_pk?: Maybe<Customer_Answer_Article_Event>;
  /** fetch data from the table: "customer.answer" using primary key columns */
  customer_answer_by_pk?: Maybe<Customer_Answer>;
  /** fetch data from the table: "customer.answer_foot_problems" */
  customer_answer_foot_problems: Array<Customer_Answer_Foot_Problems>;
  /** fetch aggregated fields from the table: "customer.answer_foot_problems" */
  customer_answer_foot_problems_aggregate: Customer_Answer_Foot_Problems_Aggregate;
  /** fetch data from the table: "customer.answer_foot_problems" using primary key columns */
  customer_answer_foot_problems_by_pk?: Maybe<Customer_Answer_Foot_Problems>;
  /** fetch data from the table: "customer.answer_hand_problems" */
  customer_answer_hand_problems: Array<Customer_Answer_Hand_Problems>;
  /** fetch aggregated fields from the table: "customer.answer_hand_problems" */
  customer_answer_hand_problems_aggregate: Customer_Answer_Hand_Problems_Aggregate;
  /** fetch data from the table: "customer.answer_hand_problems" using primary key columns */
  customer_answer_hand_problems_by_pk?: Maybe<Customer_Answer_Hand_Problems>;
  /** fetch data from the table: "customer.answer_pelvic_problems" */
  customer_answer_pelvic_problems: Array<Customer_Answer_Pelvic_Problems>;
  /** fetch aggregated fields from the table: "customer.answer_pelvic_problems" */
  customer_answer_pelvic_problems_aggregate: Customer_Answer_Pelvic_Problems_Aggregate;
  /** fetch data from the table: "customer.answer_pelvic_problems" using primary key columns */
  customer_answer_pelvic_problems_by_pk?: Maybe<Customer_Answer_Pelvic_Problems>;
  /** fetch data from the table: "customer.answer_results" */
  customer_answer_results: Array<Customer_Answer_Results>;
  /** fetch aggregated fields from the table: "customer.answer_results" */
  customer_answer_results_aggregate: Customer_Answer_Results_Aggregate;
  /** fetch data from the table: "customer.answer_results" using primary key columns */
  customer_answer_results_by_pk?: Maybe<Customer_Answer_Results>;
  /** fetch data from the table: "customer_dashboard.event" */
  customer_dashboard_event: Array<Customer_Dashboard_Event>;
  /** fetch aggregated fields from the table: "customer_dashboard.event" */
  customer_dashboard_event_aggregate: Customer_Dashboard_Event_Aggregate;
  /** fetch data from the table: "customer_dashboard.event" using primary key columns */
  customer_dashboard_event_by_pk?: Maybe<Customer_Dashboard_Event>;
  /** fetch data from the table: "customer_dashboard.event_kind" */
  customer_dashboard_event_kind: Array<Customer_Dashboard_Event_Kind>;
  /** fetch aggregated fields from the table: "customer_dashboard.event_kind" */
  customer_dashboard_event_kind_aggregate: Customer_Dashboard_Event_Kind_Aggregate;
  /** fetch data from the table: "customer_dashboard.event_kind" using primary key columns */
  customer_dashboard_event_kind_by_pk?: Maybe<Customer_Dashboard_Event_Kind>;
  /** fetch data from the table: "customer_dashboard.page_visit" */
  customer_dashboard_page_visit: Array<Customer_Dashboard_Page_Visit>;
  /** fetch aggregated fields from the table: "customer_dashboard.page_visit" */
  customer_dashboard_page_visit_aggregate: Customer_Dashboard_Page_Visit_Aggregate;
  /** fetch data from the table: "customer.deleted_answers" */
  customer_deleted_answers: Array<Customer_Deleted_Answers>;
  /** fetch aggregated fields from the table: "customer.deleted_answers" */
  customer_deleted_answers_aggregate: Customer_Deleted_Answers_Aggregate;
  /** fetch data from the table: "customer.deleted_answers" using primary key columns */
  customer_deleted_answers_by_pk?: Maybe<Customer_Deleted_Answers>;
  /** fetch data from the table: "customer.emails" */
  customer_emails: Array<Customer_Emails>;
  /** fetch aggregated fields from the table: "customer.emails" */
  customer_emails_aggregate: Customer_Emails_Aggregate;
  /** fetch data from the table: "customer.emails" using primary key columns */
  customer_emails_by_pk?: Maybe<Customer_Emails>;
  /** fetch data from the table: "customer.registration" */
  customer_registration: Array<Customer_Registration>;
  /** fetch aggregated fields from the table: "customer.registration" */
  customer_registration_aggregate: Customer_Registration_Aggregate;
  /** fetch data from the table: "customer.registration" using primary key columns */
  customer_registration_by_pk?: Maybe<Customer_Registration>;
  /** fetch data from the table: "customer.signature" */
  customer_signature: Array<Customer_Signature>;
  /** fetch aggregated fields from the table: "customer.signature" */
  customer_signature_aggregate: Customer_Signature_Aggregate;
  /** fetch data from the table: "customer.signature" using primary key columns */
  customer_signature_by_pk?: Maybe<Customer_Signature>;
  /** fetch data from the table: "customer.voucher" */
  customer_voucher: Array<Customer_Voucher>;
  /** fetch aggregated fields from the table: "customer.voucher" */
  customer_voucher_aggregate: Customer_Voucher_Aggregate;
  /** fetch data from the table: "customer.voucher" using primary key columns */
  customer_voucher_by_pk?: Maybe<Customer_Voucher>;
  /** fetch data from the table: "customer.web_questionnaire_completion" */
  customer_web_questionnaire_completion: Array<Customer_Web_Questionnaire_Completion>;
  /** fetch aggregated fields from the table: "customer.web_questionnaire_completion" */
  customer_web_questionnaire_completion_aggregate: Customer_Web_Questionnaire_Completion_Aggregate;
  /** fetch data from the table: "customer.web_questionnaire_completion" using primary key columns */
  customer_web_questionnaire_completion_by_pk?: Maybe<Customer_Web_Questionnaire_Completion>;
  /** An array relationship */
  cv_body: Array<Cv_Body>;
  /** An aggregate relationship */
  cv_body_aggregate: Cv_Body_Aggregate;
  /** fetch data from the table: "cv.body" using primary key columns */
  cv_body_by_pk?: Maybe<Cv_Body>;
  /** An array relationship */
  cv_handmarks: Array<Cv_Handmarks>;
  /** An aggregate relationship */
  cv_handmarks_aggregate: Cv_Handmarks_Aggregate;
  /** fetch data from the table: "cv.handmarks" using primary key columns */
  cv_handmarks_by_pk?: Maybe<Cv_Handmarks>;
  /** An array relationship */
  cv_sitbones: Array<Cv_Sitbones>;
  /** An aggregate relationship */
  cv_sitbones_aggregate: Cv_Sitbones_Aggregate;
  /** fetch data from the table: "cv.sitbones" using primary key columns */
  cv_sitbones_by_pk?: Maybe<Cv_Sitbones>;
  /** fetch data from the table: "distributor" */
  distributor: Array<Distributor>;
  /** fetch aggregated fields from the table: "distributor" */
  distributor_aggregate: Distributor_Aggregate;
  /** fetch data from the table: "distributor" using primary key columns */
  distributor_by_pk?: Maybe<Distributor>;
  /** fetch data from the table: "distributors" */
  distributors: Array<Distributors>;
  /** fetch aggregated fields from the table: "distributors" */
  distributors_aggregate: Distributors_Aggregate;
  /** fetch data from the table: "distributors" using primary key columns */
  distributors_by_pk?: Maybe<Distributors>;
  /** fetch data from the table: "event_enums.article_event" */
  event_enums_article_event: Array<Event_Enums_Article_Event>;
  /** fetch aggregated fields from the table: "event_enums.article_event" */
  event_enums_article_event_aggregate: Event_Enums_Article_Event_Aggregate;
  /** fetch data from the table: "event_enums.article_event" using primary key columns */
  event_enums_article_event_by_pk?: Maybe<Event_Enums_Article_Event>;
  /** fetch data from the table: "event_enums.event_source" */
  event_enums_event_source: Array<Event_Enums_Event_Source>;
  /** fetch aggregated fields from the table: "event_enums.event_source" */
  event_enums_event_source_aggregate: Event_Enums_Event_Source_Aggregate;
  /** fetch data from the table: "event_enums.event_source" using primary key columns */
  event_enums_event_source_by_pk?: Maybe<Event_Enums_Event_Source>;
  /** fetch data from the table: "event_enums.log_level" */
  event_enums_log_level: Array<Event_Enums_Log_Level>;
  /** fetch aggregated fields from the table: "event_enums.log_level" */
  event_enums_log_level_aggregate: Event_Enums_Log_Level_Aggregate;
  /** fetch data from the table: "event_enums.log_level" using primary key columns */
  event_enums_log_level_by_pk?: Maybe<Event_Enums_Log_Level>;
  /** fetch data from the table: "event_log.event_types" */
  event_log_event_types: Array<Event_Log_Event_Types>;
  /** fetch aggregated fields from the table: "event_log.event_types" */
  event_log_event_types_aggregate: Event_Log_Event_Types_Aggregate;
  /** fetch data from the table: "event_log.event_types" using primary key columns */
  event_log_event_types_by_pk?: Maybe<Event_Log_Event_Types>;
  /** fetch data from the table: "event_log.events" */
  event_log_events: Array<Event_Log_Events>;
  /** fetch aggregated fields from the table: "event_log.events" */
  event_log_events_aggregate: Event_Log_Events_Aggregate;
  /** fetch data from the table: "event_log.events" using primary key columns */
  event_log_events_by_pk?: Maybe<Event_Log_Events>;
  /** fetch data from the table: "frontend.vendor_support" */
  frontend_vendor_support: Array<Frontend_Vendor_Support>;
  /** fetch aggregated fields from the table: "frontend.vendor_support" */
  frontend_vendor_support_aggregate: Frontend_Vendor_Support_Aggregate;
  /** fetch data from the table: "frontend.vendor_support" using primary key columns */
  frontend_vendor_support_by_pk?: Maybe<Frontend_Vendor_Support>;
  /** fetch data from the table: "health" */
  health: Array<Health>;
  /** fetch aggregated fields from the table: "health" */
  health_aggregate: Health_Aggregate;
  /** fetch data from the table: "iframe.feedback" */
  iframe_feedback: Array<Iframe_Feedback>;
  /** fetch aggregated fields from the table: "iframe.feedback" */
  iframe_feedback_aggregate: Iframe_Feedback_Aggregate;
  /** fetch data from the table: "iframe.feedback" using primary key columns */
  iframe_feedback_by_pk?: Maybe<Iframe_Feedback>;
  /** fetch data from the table: "iframe.newsletter_subscriptions" */
  iframe_newsletter_subscriptions: Array<Iframe_Newsletter_Subscriptions>;
  /** fetch aggregated fields from the table: "iframe.newsletter_subscriptions" */
  iframe_newsletter_subscriptions_aggregate: Iframe_Newsletter_Subscriptions_Aggregate;
  /** fetch data from the table: "iframe.newsletter_subscriptions" using primary key columns */
  iframe_newsletter_subscriptions_by_pk?: Maybe<Iframe_Newsletter_Subscriptions>;
  /** fetch data from the table: "iframe.rating" */
  iframe_rating: Array<Iframe_Rating>;
  /** fetch aggregated fields from the table: "iframe.rating" */
  iframe_rating_aggregate: Iframe_Rating_Aggregate;
  /** fetch data from the table: "iframe.rating" using primary key columns */
  iframe_rating_by_pk?: Maybe<Iframe_Rating>;
  /** fetch data from the table: "iqlab_rewards.types" */
  iqlab_rewards_types: Array<Iqlab_Rewards_Types>;
  /** fetch aggregated fields from the table: "iqlab_rewards.types" */
  iqlab_rewards_types_aggregate: Iqlab_Rewards_Types_Aggregate;
  /** fetch data from the table: "iqlab_rewards.types" using primary key columns */
  iqlab_rewards_types_by_pk?: Maybe<Iqlab_Rewards_Types>;
  /** fetch data from the table: "iqlab_rewards.vouchers" */
  iqlab_rewards_vouchers: Array<Iqlab_Rewards_Vouchers>;
  /** fetch aggregated fields from the table: "iqlab_rewards.vouchers" */
  iqlab_rewards_vouchers_aggregate: Iqlab_Rewards_Vouchers_Aggregate;
  /** fetch data from the table: "iqlab_rewards.vouchers" using primary key columns */
  iqlab_rewards_vouchers_by_pk?: Maybe<Iqlab_Rewards_Vouchers>;
  /** fetch data from the table: "measurement.measure" */
  measurement_measure: Array<Measurement_Measure>;
  /** fetch aggregated fields from the table: "measurement.measure" */
  measurement_measure_aggregate: Measurement_Measure_Aggregate;
  /** fetch data from the table: "measurement.measure" using primary key columns */
  measurement_measure_by_pk?: Maybe<Measurement_Measure>;
  /** fetch data from the table: "measurement.measure_type" */
  measurement_measure_type: Array<Measurement_Measure_Type>;
  /** fetch aggregated fields from the table: "measurement.measure_type" */
  measurement_measure_type_aggregate: Measurement_Measure_Type_Aggregate;
  /** fetch data from the table: "measurement.measure_type" using primary key columns */
  measurement_measure_type_by_pk?: Maybe<Measurement_Measure_Type>;
  /** fetch data from the table: "measurement.session_source" */
  measurement_session_source: Array<Measurement_Session_Source>;
  /** fetch aggregated fields from the table: "measurement.session_source" */
  measurement_session_source_aggregate: Measurement_Session_Source_Aggregate;
  /** fetch data from the table: "measurement.session_source" using primary key columns */
  measurement_session_source_by_pk?: Maybe<Measurement_Session_Source>;
  /** fetch data from the table: "measurement.session_type" */
  measurement_session_type: Array<Measurement_Session_Type>;
  /** fetch aggregated fields from the table: "measurement.session_type" */
  measurement_session_type_aggregate: Measurement_Session_Type_Aggregate;
  /** fetch data from the table: "measurement.session_type" using primary key columns */
  measurement_session_type_by_pk?: Maybe<Measurement_Session_Type>;
  /** fetch data from the table: "measurement.sessions" */
  measurement_sessions: Array<Measurement_Sessions>;
  /** fetch aggregated fields from the table: "measurement.sessions" */
  measurement_sessions_aggregate: Measurement_Sessions_Aggregate;
  /** fetch data from the table: "measurement.sessions" using primary key columns */
  measurement_sessions_by_pk?: Maybe<Measurement_Sessions>;
  /** fetch data from the table: "metric.answer_location" */
  metric_answer_location: Array<Metric_Answer_Location>;
  /** fetch aggregated fields from the table: "metric.answer_location" */
  metric_answer_location_aggregate: Metric_Answer_Location_Aggregate;
  /** fetch data from the table: "metric.answer_location" using primary key columns */
  metric_answer_location_by_pk?: Maybe<Metric_Answer_Location>;
  /** fetch data from the table: "metric.app_events" */
  metric_app_events: Array<Metric_App_Events>;
  /** fetch aggregated fields from the table: "metric.app_events" */
  metric_app_events_aggregate: Metric_App_Events_Aggregate;
  /** fetch data from the table: "metric.app_events" using primary key columns */
  metric_app_events_by_pk?: Maybe<Metric_App_Events>;
  /** fetch data from the table: "metric.avg_questionnaire_count_30_days" */
  metric_avg_questionnaire_count_30_days: Array<Metric_Avg_Questionnaire_Count_30_Days>;
  /** fetch aggregated fields from the table: "metric.avg_questionnaire_count_30_days" */
  metric_avg_questionnaire_count_30_days_aggregate: Metric_Avg_Questionnaire_Count_30_Days_Aggregate;
  /** fetch data from the table: "metric.max_questionnaires_with_day" */
  metric_max_questionnaires_with_day: Array<Metric_Max_Questionnaires_With_Day>;
  /** fetch aggregated fields from the table: "metric.max_questionnaires_with_day" */
  metric_max_questionnaires_with_day_aggregate: Metric_Max_Questionnaires_With_Day_Aggregate;
  /** fetch data from the table: "metric.question_duration" */
  metric_question_duration: Array<Metric_Question_Duration>;
  /** fetch aggregated fields from the table: "metric.question_duration" */
  metric_question_duration_aggregate: Metric_Question_Duration_Aggregate;
  /** fetch data from the table: "metric.question_duration" using primary key columns */
  metric_question_duration_by_pk?: Maybe<Metric_Question_Duration>;
  /** fetch data from the table: "metric.questionnaires_per_vendor_and_year" */
  metric_questionnaires_per_vendor_and_year: Array<Metric_Questionnaires_Per_Vendor_And_Year>;
  /** fetch aggregated fields from the table: "metric.questionnaires_per_vendor_and_year" */
  metric_questionnaires_per_vendor_and_year_aggregate: Metric_Questionnaires_Per_Vendor_And_Year_Aggregate;
  /** fetch data from the table: "metric.usage_information" */
  metric_usage_information: Array<Metric_Usage_Information>;
  /** fetch aggregated fields from the table: "metric.usage_information" */
  metric_usage_information_aggregate: Metric_Usage_Information_Aggregate;
  /** fetch data from the table: "metric.usage_information" using primary key columns */
  metric_usage_information_by_pk?: Maybe<Metric_Usage_Information>;
  /** fetch data from the table: "metric.user_ranking" */
  metric_user_ranking: Array<Metric_User_Ranking>;
  /** fetch aggregated fields from the table: "metric.user_ranking" */
  metric_user_ranking_aggregate: Metric_User_Ranking_Aggregate;
  /** fetch data from the table: "onboarding.emails" */
  onboarding_emails: Array<Onboarding_Emails>;
  /** fetch aggregated fields from the table: "onboarding.emails" */
  onboarding_emails_aggregate: Onboarding_Emails_Aggregate;
  /** fetch data from the table: "onboarding.emails" using primary key columns */
  onboarding_emails_by_pk?: Maybe<Onboarding_Emails>;
  /** fetch data from the table: "organisation" */
  organisation: Array<Organisation>;
  /** fetch aggregated fields from the table: "organisation" */
  organisation_aggregate: Organisation_Aggregate;
  /** fetch data from the table: "organisation" using primary key columns */
  organisation_by_pk?: Maybe<Organisation>;
  /** fetch data from the table: "preferences.user_preferences" */
  preferences_user_preferences: Array<Preferences_User_Preferences>;
  /** fetch aggregated fields from the table: "preferences.user_preferences" */
  preferences_user_preferences_aggregate: Preferences_User_Preferences_Aggregate;
  /** fetch data from the table: "preferences.user_preferences" using primary key columns */
  preferences_user_preferences_by_pk?: Maybe<Preferences_User_Preferences>;
  /** fetch data from the table: "preferences.vendor_preferences" */
  preferences_vendor_preferences: Array<Preferences_Vendor_Preferences>;
  /** fetch aggregated fields from the table: "preferences.vendor_preferences" */
  preferences_vendor_preferences_aggregate: Preferences_Vendor_Preferences_Aggregate;
  /** fetch data from the table: "preferences.vendor_preferences" using primary key columns */
  preferences_vendor_preferences_by_pk?: Maybe<Preferences_Vendor_Preferences>;
  /** fetch data from the table: "progress.chapter_overview" */
  progress_chapter_overview: Array<Progress_Chapter_Overview>;
  /** fetch aggregated fields from the table: "progress.chapter_overview" */
  progress_chapter_overview_aggregate: Progress_Chapter_Overview_Aggregate;
  /** fetch data from the table: "progress.completed_chapters" */
  progress_completed_chapters: Array<Progress_Completed_Chapters>;
  /** fetch aggregated fields from the table: "progress.completed_chapters" */
  progress_completed_chapters_aggregate: Progress_Completed_Chapters_Aggregate;
  /** fetch data from the table: "progress.completed_chapters" using primary key columns */
  progress_completed_chapters_by_pk?: Maybe<Progress_Completed_Chapters>;
  /** fetch data from the table: "progress.completed_contactpoints" */
  progress_completed_contactpoints: Array<Progress_Completed_Contactpoints>;
  /** fetch aggregated fields from the table: "progress.completed_contactpoints" */
  progress_completed_contactpoints_aggregate: Progress_Completed_Contactpoints_Aggregate;
  /** fetch data from the table: "progress.completed_contactpoints" using primary key columns */
  progress_completed_contactpoints_by_pk?: Maybe<Progress_Completed_Contactpoints>;
  /** fetch data from the table: "progress.completed_lections" */
  progress_completed_lections: Array<Progress_Completed_Lections>;
  /** fetch aggregated fields from the table: "progress.completed_lections" */
  progress_completed_lections_aggregate: Progress_Completed_Lections_Aggregate;
  /** fetch data from the table: "progress.completed_lections" using primary key columns */
  progress_completed_lections_by_pk?: Maybe<Progress_Completed_Lections>;
  /** fetch data from the table: "progress.contactpoint_overview" */
  progress_contactpoint_overview: Array<Progress_Contactpoint_Overview>;
  /** fetch aggregated fields from the table: "progress.contactpoint_overview" */
  progress_contactpoint_overview_aggregate: Progress_Contactpoint_Overview_Aggregate;
  /** fetch data from the table: "progress.lection_overview" */
  progress_lection_overview: Array<Progress_Lection_Overview>;
  /** fetch aggregated fields from the table: "progress.lection_overview" */
  progress_lection_overview_aggregate: Progress_Lection_Overview_Aggregate;
  /** fetch data from the table: "recommendation.own_articles" */
  recommendation_own_articles: Array<Recommendation_Own_Articles>;
  /** fetch aggregated fields from the table: "recommendation.own_articles" */
  recommendation_own_articles_aggregate: Recommendation_Own_Articles_Aggregate;
  /** fetch data from the table: "recommendation.own_articles" using primary key columns */
  recommendation_own_articles_by_pk?: Maybe<Recommendation_Own_Articles>;
  /** fetch data from the table: "recommendation.return_search_article" */
  recommendation_return_search_article: Array<Recommendation_Return_Search_Article>;
  /** fetch aggregated fields from the table: "recommendation.return_search_article" */
  recommendation_return_search_article_aggregate: Recommendation_Return_Search_Article_Aggregate;
  /** fetch data from the table: "recommendation.return_search_article" using primary key columns */
  recommendation_return_search_article_by_pk?: Maybe<Recommendation_Return_Search_Article>;
  /** execute function "recommendation.search_articles" which returns "recommendation.return_search_article" */
  recommendation_search_articles: Array<Recommendation_Return_Search_Article>;
  /** execute function "recommendation.search_articles" and query aggregates on result of table type "recommendation.return_search_article" */
  recommendation_search_articles_aggregate: Recommendation_Return_Search_Article_Aggregate;
  /** fetch data from the table: "rewards.benefit_category" */
  rewards_benefit_category: Array<Rewards_Benefit_Category>;
  /** fetch aggregated fields from the table: "rewards.benefit_category" */
  rewards_benefit_category_aggregate: Rewards_Benefit_Category_Aggregate;
  /** fetch data from the table: "rewards.benefit_category" using primary key columns */
  rewards_benefit_category_by_pk?: Maybe<Rewards_Benefit_Category>;
  /** fetch data from the table: "rewards.benefits" */
  rewards_benefits: Array<Rewards_Benefits>;
  /** fetch aggregated fields from the table: "rewards.benefits" */
  rewards_benefits_aggregate: Rewards_Benefits_Aggregate;
  /** fetch data from the table: "rewards.benefits" using primary key columns */
  rewards_benefits_by_pk?: Maybe<Rewards_Benefits>;
  /** fetch data from the table: "rewards.boosts" */
  rewards_boosts: Array<Rewards_Boosts>;
  /** fetch aggregated fields from the table: "rewards.boosts" */
  rewards_boosts_aggregate: Rewards_Boosts_Aggregate;
  /** fetch data from the table: "rewards.boosts" using primary key columns */
  rewards_boosts_by_pk?: Maybe<Rewards_Boosts>;
  /** fetch data from the table: "rewards.internal_ranking" */
  rewards_internal_ranking: Array<Rewards_Internal_Ranking>;
  /** fetch aggregated fields from the table: "rewards.internal_ranking" */
  rewards_internal_ranking_aggregate: Rewards_Internal_Ranking_Aggregate;
  /** fetch data from the table: "rewards.international_ranking" */
  rewards_international_ranking: Array<Rewards_International_Ranking>;
  /** fetch aggregated fields from the table: "rewards.international_ranking" */
  rewards_international_ranking_aggregate: Rewards_International_Ranking_Aggregate;
  /** fetch data from the table: "rewards.ranking_balances" */
  rewards_ranking_balances: Array<Rewards_Ranking_Balances>;
  /** fetch aggregated fields from the table: "rewards.ranking_balances" */
  rewards_ranking_balances_aggregate: Rewards_Ranking_Balances_Aggregate;
  /** fetch data from the table: "rewards.reward_balances" */
  rewards_reward_balances: Array<Rewards_Reward_Balances>;
  /** fetch aggregated fields from the table: "rewards.reward_balances" */
  rewards_reward_balances_aggregate: Rewards_Reward_Balances_Aggregate;
  /** fetch data from the table: "rewards.transactions" */
  rewards_transactions: Array<Rewards_Transactions>;
  /** fetch aggregated fields from the table: "rewards.transactions" */
  rewards_transactions_aggregate: Rewards_Transactions_Aggregate;
  /** fetch data from the table: "rewards.transactions" using primary key columns */
  rewards_transactions_by_pk?: Maybe<Rewards_Transactions>;
  /** An array relationship */
  sessions: Array<Sessions>;
  /** An aggregate relationship */
  sessions_aggregate: Sessions_Aggregate;
  /** fetch data from the table: "sessions" using primary key columns */
  sessions_by_pk?: Maybe<Sessions>;
  /** fetch data from the table: "sqbase_event_log.event_types" */
  sqbase_event_log_event_types: Array<Sqbase_Event_Log_Event_Types>;
  /** fetch aggregated fields from the table: "sqbase_event_log.event_types" */
  sqbase_event_log_event_types_aggregate: Sqbase_Event_Log_Event_Types_Aggregate;
  /** fetch data from the table: "sqbase_event_log.event_types" using primary key columns */
  sqbase_event_log_event_types_by_pk?: Maybe<Sqbase_Event_Log_Event_Types>;
  /** fetch data from the table: "sqbase_event_log.events" */
  sqbase_event_log_events: Array<Sqbase_Event_Log_Events>;
  /** fetch aggregated fields from the table: "sqbase_event_log.events" */
  sqbase_event_log_events_aggregate: Sqbase_Event_Log_Events_Aggregate;
  /** fetch data from the table: "sqbase_event_log.events" using primary key columns */
  sqbase_event_log_events_by_pk?: Maybe<Sqbase_Event_Log_Events>;
  /** fetch data from the table: "sqlab_contacts" */
  sqlab_contacts: Array<Sqlab_Contacts>;
  /** fetch aggregated fields from the table: "sqlab_contacts" */
  sqlab_contacts_aggregate: Sqlab_Contacts_Aggregate;
  /** fetch data from the table: "sqlab_contacts" using primary key columns */
  sqlab_contacts_by_pk?: Maybe<Sqlab_Contacts>;
  /** fetch data from the table: "support.expiring_boosts" */
  support_expiring_boosts: Array<Support_Expiring_Boosts>;
  /** fetch aggregated fields from the table: "support.expiring_boosts" */
  support_expiring_boosts_aggregate: Support_Expiring_Boosts_Aggregate;
  /** fetch data from the table: "support.follow_up_tasks" */
  support_follow_up_tasks: Array<Support_Follow_Up_Tasks>;
  /** fetch aggregated fields from the table: "support.follow_up_tasks" */
  support_follow_up_tasks_aggregate: Support_Follow_Up_Tasks_Aggregate;
  /** fetch data from the table: "support.interactions" */
  support_interactions: Array<Support_Interactions>;
  /** fetch aggregated fields from the table: "support.interactions" */
  support_interactions_aggregate: Support_Interactions_Aggregate;
  /** fetch data from the table: "support.interactions" using primary key columns */
  support_interactions_by_pk?: Maybe<Support_Interactions>;
  /** fetch data from the table: "support.registrations_without_answers" */
  support_registrations_without_answers: Array<Support_Registrations_Without_Answers>;
  /** fetch aggregated fields from the table: "support.registrations_without_answers" */
  support_registrations_without_answers_aggregate: Support_Registrations_Without_Answers_Aggregate;
  /** fetch data from the table: "support.registrations_without_progress_two_weeks" */
  support_registrations_without_progress_two_weeks: Array<Support_Registrations_Without_Progress_Two_Weeks>;
  /** fetch aggregated fields from the table: "support.registrations_without_progress_two_weeks" */
  support_registrations_without_progress_two_weeks_aggregate: Support_Registrations_Without_Progress_Two_Weeks_Aggregate;
  /** fetch data from the table: "support.vendor_support" */
  support_vendor_support: Array<Support_Vendor_Support>;
  /** fetch aggregated fields from the table: "support.vendor_support" */
  support_vendor_support_aggregate: Support_Vendor_Support_Aggregate;
  /** fetch data from the table: "support.vendor_support" using primary key columns */
  support_vendor_support_by_pk?: Maybe<Support_Vendor_Support>;
  /** fetch data from the table: "support.vendor_support_type" */
  support_vendor_support_type: Array<Support_Vendor_Support_Type>;
  /** fetch aggregated fields from the table: "support.vendor_support_type" */
  support_vendor_support_type_aggregate: Support_Vendor_Support_Type_Aggregate;
  /** fetch data from the table: "support.vendor_support_type" using primary key columns */
  support_vendor_support_type_by_pk?: Maybe<Support_Vendor_Support_Type>;
  /** fetch data from the table: "tracker.article_prices" */
  tracker_article_prices: Array<Tracker_Article_Prices>;
  /** fetch aggregated fields from the table: "tracker.article_prices" */
  tracker_article_prices_aggregate: Tracker_Article_Prices_Aggregate;
  /** fetch data from the table: "tracker.article_prices" using primary key columns */
  tracker_article_prices_by_pk?: Maybe<Tracker_Article_Prices>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "user_details" */
  user_details: Array<User_Details>;
  /** fetch aggregated fields from the table: "user_details" */
  user_details_aggregate: User_Details_Aggregate;
  /** fetch data from the table: "user_details" using primary key columns */
  user_details_by_pk?: Maybe<User_Details>;
};


export type Query_RootContactPointArgs = {
  id: Scalars['ID'];
};


export type Query_RootDocumentArgs = {
  id: Scalars['ID'];
};


export type Query_RootLectionArgs = {
  id: Scalars['ID'];
};


export type Query_RootLevelArgs = {
  id: Scalars['ID'];
};


export type Query_RootMediaTagArgs = {
  id: Scalars['ID'];
};


export type Query_RootQuizArgs = {
  id: Scalars['ID'];
};


export type Query_RootSanityFileAssetArgs = {
  id: Scalars['ID'];
};


export type Query_RootSanityImageAssetArgs = {
  id: Scalars['ID'];
};


export type Query_RootAggregation_Lectures_Completed_By_Vendor_Per_YearArgs = {
  distinct_on?: InputMaybe<Array<Aggregation_Lectures_Completed_By_Vendor_Per_Year_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregation_Lectures_Completed_By_Vendor_Per_Year_Order_By>>;
  where?: InputMaybe<Aggregation_Lectures_Completed_By_Vendor_Per_Year_Bool_Exp>;
};


export type Query_RootAggregation_Lectures_Completed_By_Vendor_Per_Year_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Aggregation_Lectures_Completed_By_Vendor_Per_Year_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregation_Lectures_Completed_By_Vendor_Per_Year_Order_By>>;
  where?: InputMaybe<Aggregation_Lectures_Completed_By_Vendor_Per_Year_Bool_Exp>;
};


export type Query_RootAggregation_Required_Lections_CountArgs = {
  distinct_on?: InputMaybe<Array<Aggregation_Required_Lections_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregation_Required_Lections_Count_Order_By>>;
  where?: InputMaybe<Aggregation_Required_Lections_Count_Bool_Exp>;
};


export type Query_RootAggregation_Required_Lections_Count_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Aggregation_Required_Lections_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregation_Required_Lections_Count_Order_By>>;
  where?: InputMaybe<Aggregation_Required_Lections_Count_Bool_Exp>;
};


export type Query_RootAggregation_Requirements_OverviewArgs = {
  distinct_on?: InputMaybe<Array<Aggregation_Requirements_Overview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregation_Requirements_Overview_Order_By>>;
  where?: InputMaybe<Aggregation_Requirements_Overview_Bool_Exp>;
};


export type Query_RootAggregation_Requirements_Overview_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Aggregation_Requirements_Overview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregation_Requirements_Overview_Order_By>>;
  where?: InputMaybe<Aggregation_Requirements_Overview_Bool_Exp>;
};


export type Query_RootAggregation_Users_ChaptersArgs = {
  distinct_on?: InputMaybe<Array<Aggregation_Users_Chapters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregation_Users_Chapters_Order_By>>;
  where?: InputMaybe<Aggregation_Users_Chapters_Bool_Exp>;
};


export type Query_RootAggregation_Users_Chapters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Aggregation_Users_Chapters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregation_Users_Chapters_Order_By>>;
  where?: InputMaybe<Aggregation_Users_Chapters_Bool_Exp>;
};


export type Query_RootAggregation_Users_LectionsArgs = {
  distinct_on?: InputMaybe<Array<Aggregation_Users_Lections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregation_Users_Lections_Order_By>>;
  where?: InputMaybe<Aggregation_Users_Lections_Bool_Exp>;
};


export type Query_RootAggregation_Users_Lections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Aggregation_Users_Lections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregation_Users_Lections_Order_By>>;
  where?: InputMaybe<Aggregation_Users_Lections_Bool_Exp>;
};


export type Query_RootAggregation_Users_RequirementsArgs = {
  distinct_on?: InputMaybe<Array<Aggregation_Users_Requirements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregation_Users_Requirements_Order_By>>;
  where?: InputMaybe<Aggregation_Users_Requirements_Bool_Exp>;
};


export type Query_RootAggregation_Users_Requirements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Aggregation_Users_Requirements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregation_Users_Requirements_Order_By>>;
  where?: InputMaybe<Aggregation_Users_Requirements_Bool_Exp>;
};


export type Query_RootAllContactPointArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<ContactPointSorting>>;
  where?: InputMaybe<ContactPointFilter>;
};


export type Query_RootAllDocumentArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<DocumentSorting>>;
  where?: InputMaybe<DocumentFilter>;
};


export type Query_RootAllLectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<LectionSorting>>;
  where?: InputMaybe<LectionFilter>;
};


export type Query_RootAllLevelArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<LevelSorting>>;
  where?: InputMaybe<LevelFilter>;
};


export type Query_RootAllMediaTagArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<MediaTagSorting>>;
  where?: InputMaybe<MediaTagFilter>;
};


export type Query_RootAllQuizArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<QuizSorting>>;
  where?: InputMaybe<QuizFilter>;
};


export type Query_RootAllSanityFileAssetArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SanityFileAssetSorting>>;
  where?: InputMaybe<SanityFileAssetFilter>;
};


export type Query_RootAllSanityImageAssetArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SanityImageAssetSorting>>;
  where?: InputMaybe<SanityImageAssetFilter>;
};


export type Query_RootAnswer_Enums_AllArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_All_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_All_Order_By>>;
  where?: InputMaybe<Answer_Enums_All_Bool_Exp>;
};


export type Query_RootAnswer_Enums_All_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_All_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_All_Order_By>>;
  where?: InputMaybe<Answer_Enums_All_Bool_Exp>;
};


export type Query_RootAnswer_Enums_Bicycle_TypeArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Bicycle_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Bicycle_Type_Order_By>>;
  where?: InputMaybe<Answer_Enums_Bicycle_Type_Bool_Exp>;
};


export type Query_RootAnswer_Enums_Bicycle_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Bicycle_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Bicycle_Type_Order_By>>;
  where?: InputMaybe<Answer_Enums_Bicycle_Type_Bool_Exp>;
};


export type Query_RootAnswer_Enums_Bicycle_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootAnswer_Enums_Driving_DurationArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Driving_Duration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Driving_Duration_Order_By>>;
  where?: InputMaybe<Answer_Enums_Driving_Duration_Bool_Exp>;
};


export type Query_RootAnswer_Enums_Driving_Duration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Driving_Duration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Driving_Duration_Order_By>>;
  where?: InputMaybe<Answer_Enums_Driving_Duration_Bool_Exp>;
};


export type Query_RootAnswer_Enums_Driving_Duration_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootAnswer_Enums_Driving_FrequencyArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Driving_Frequency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Driving_Frequency_Order_By>>;
  where?: InputMaybe<Answer_Enums_Driving_Frequency_Bool_Exp>;
};


export type Query_RootAnswer_Enums_Driving_Frequency_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Driving_Frequency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Driving_Frequency_Order_By>>;
  where?: InputMaybe<Answer_Enums_Driving_Frequency_Bool_Exp>;
};


export type Query_RootAnswer_Enums_Driving_Frequency_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootAnswer_Enums_E_BikeArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_E_Bike_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_E_Bike_Order_By>>;
  where?: InputMaybe<Answer_Enums_E_Bike_Bool_Exp>;
};


export type Query_RootAnswer_Enums_E_Bike_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_E_Bike_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_E_Bike_Order_By>>;
  where?: InputMaybe<Answer_Enums_E_Bike_Bool_Exp>;
};


export type Query_RootAnswer_Enums_E_Bike_By_PkArgs = {
  value: Scalars['Boolean'];
};


export type Query_RootAnswer_Enums_Foot_PositionArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Foot_Position_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Foot_Position_Order_By>>;
  where?: InputMaybe<Answer_Enums_Foot_Position_Bool_Exp>;
};


export type Query_RootAnswer_Enums_Foot_Position_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Foot_Position_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Foot_Position_Order_By>>;
  where?: InputMaybe<Answer_Enums_Foot_Position_Bool_Exp>;
};


export type Query_RootAnswer_Enums_Foot_Position_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootAnswer_Enums_Foot_ProblemsArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Foot_Problems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Foot_Problems_Order_By>>;
  where?: InputMaybe<Answer_Enums_Foot_Problems_Bool_Exp>;
};


export type Query_RootAnswer_Enums_Foot_Problems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Foot_Problems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Foot_Problems_Order_By>>;
  where?: InputMaybe<Answer_Enums_Foot_Problems_Bool_Exp>;
};


export type Query_RootAnswer_Enums_Foot_Problems_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootAnswer_Enums_Foot_TypeArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Foot_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Foot_Type_Order_By>>;
  where?: InputMaybe<Answer_Enums_Foot_Type_Bool_Exp>;
};


export type Query_RootAnswer_Enums_Foot_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Foot_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Foot_Type_Order_By>>;
  where?: InputMaybe<Answer_Enums_Foot_Type_Bool_Exp>;
};


export type Query_RootAnswer_Enums_Foot_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootAnswer_Enums_Gripping_DistanceArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Gripping_Distance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Gripping_Distance_Order_By>>;
  where?: InputMaybe<Answer_Enums_Gripping_Distance_Bool_Exp>;
};


export type Query_RootAnswer_Enums_Gripping_Distance_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Gripping_Distance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Gripping_Distance_Order_By>>;
  where?: InputMaybe<Answer_Enums_Gripping_Distance_Bool_Exp>;
};


export type Query_RootAnswer_Enums_Gripping_Distance_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootAnswer_Enums_Hand_ProblemsArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Hand_Problems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Hand_Problems_Order_By>>;
  where?: InputMaybe<Answer_Enums_Hand_Problems_Bool_Exp>;
};


export type Query_RootAnswer_Enums_Hand_Problems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Hand_Problems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Hand_Problems_Order_By>>;
  where?: InputMaybe<Answer_Enums_Hand_Problems_Bool_Exp>;
};


export type Query_RootAnswer_Enums_Hand_Problems_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootAnswer_Enums_Leg_AxisArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Leg_Axis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Leg_Axis_Order_By>>;
  where?: InputMaybe<Answer_Enums_Leg_Axis_Bool_Exp>;
};


export type Query_RootAnswer_Enums_Leg_Axis_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Leg_Axis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Leg_Axis_Order_By>>;
  where?: InputMaybe<Answer_Enums_Leg_Axis_Bool_Exp>;
};


export type Query_RootAnswer_Enums_Leg_Axis_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootAnswer_Enums_Pelvic_ProblemsArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Pelvic_Problems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Pelvic_Problems_Order_By>>;
  where?: InputMaybe<Answer_Enums_Pelvic_Problems_Bool_Exp>;
};


export type Query_RootAnswer_Enums_Pelvic_Problems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Pelvic_Problems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Pelvic_Problems_Order_By>>;
  where?: InputMaybe<Answer_Enums_Pelvic_Problems_Bool_Exp>;
};


export type Query_RootAnswer_Enums_Pelvic_Problems_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootAnswer_Enums_Seat_PositionArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Seat_Position_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Seat_Position_Order_By>>;
  where?: InputMaybe<Answer_Enums_Seat_Position_Bool_Exp>;
};


export type Query_RootAnswer_Enums_Seat_Position_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Seat_Position_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Seat_Position_Order_By>>;
  where?: InputMaybe<Answer_Enums_Seat_Position_Bool_Exp>;
};


export type Query_RootAnswer_Enums_Seat_Position_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootAnswer_Enums_SexArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Sex_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Sex_Order_By>>;
  where?: InputMaybe<Answer_Enums_Sex_Bool_Exp>;
};


export type Query_RootAnswer_Enums_Sex_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Sex_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Sex_Order_By>>;
  where?: InputMaybe<Answer_Enums_Sex_Bool_Exp>;
};


export type Query_RootAnswer_Enums_Sex_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootAnswer_Enums_Unmatched_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Unmatched_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Unmatched_Answers_Order_By>>;
  where?: InputMaybe<Answer_Enums_Unmatched_Answers_Bool_Exp>;
};


export type Query_RootAnswer_Enums_Unmatched_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Unmatched_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Unmatched_Answers_Order_By>>;
  where?: InputMaybe<Answer_Enums_Unmatched_Answers_Bool_Exp>;
};


export type Query_RootAnswer_Enums_Unmatched_EnumsArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Unmatched_Enums_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Unmatched_Enums_Order_By>>;
  where?: InputMaybe<Answer_Enums_Unmatched_Enums_Bool_Exp>;
};


export type Query_RootAnswer_Enums_Unmatched_Enums_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Unmatched_Enums_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Unmatched_Enums_Order_By>>;
  where?: InputMaybe<Answer_Enums_Unmatched_Enums_Bool_Exp>;
};


export type Query_RootAnswersArgs = {
  distinct_on?: InputMaybe<Array<Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answers_Order_By>>;
  where?: InputMaybe<Answers_Bool_Exp>;
};


export type Query_RootAnswers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answers_Order_By>>;
  where?: InputMaybe<Answers_Bool_Exp>;
};


export type Query_RootAnswers_By_PkArgs = {
  choice_id: Scalars['Int'];
  session_id: Scalars['uuid'];
};


export type Query_RootArticle_GroupArgs = {
  distinct_on?: InputMaybe<Array<Article_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Article_Group_Order_By>>;
  where?: InputMaybe<Article_Group_Bool_Exp>;
};


export type Query_RootArticle_Group_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Article_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Article_Group_Order_By>>;
  where?: InputMaybe<Article_Group_Bool_Exp>;
};


export type Query_RootArticle_Group_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootArticle_Information_I18nArgs = {
  distinct_on?: InputMaybe<Array<Article_Information_I18n_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Article_Information_I18n_Order_By>>;
  where?: InputMaybe<Article_Information_I18n_Bool_Exp>;
};


export type Query_RootArticle_Information_I18n_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Article_Information_I18n_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Article_Information_I18n_Order_By>>;
  where?: InputMaybe<Article_Information_I18n_Bool_Exp>;
};


export type Query_RootArticle_Information_I18n_By_PkArgs = {
  article_id: Scalars['String'];
  language: Country_Code_Enum;
};


export type Query_RootArticle_RankingArgs = {
  distinct_on?: InputMaybe<Array<Article_Ranking_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Article_Ranking_Order_By>>;
  where?: InputMaybe<Article_Ranking_Bool_Exp>;
};


export type Query_RootArticle_Ranking_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Article_Ranking_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Article_Ranking_Order_By>>;
  where?: InputMaybe<Article_Ranking_Bool_Exp>;
};


export type Query_RootArticle_Ranking_By_PkArgs = {
  answer: Scalars['String'];
  article_id: Scalars['String'];
};


export type Query_RootArticle_TypeArgs = {
  distinct_on?: InputMaybe<Array<Article_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Article_Type_Order_By>>;
  where?: InputMaybe<Article_Type_Bool_Exp>;
};


export type Query_RootArticle_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Article_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Article_Type_Order_By>>;
  where?: InputMaybe<Article_Type_Bool_Exp>;
};


export type Query_RootArticle_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootAuth_One_Time_TokenArgs = {
  distinct_on?: InputMaybe<Array<Auth_One_Time_Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_One_Time_Token_Order_By>>;
  where?: InputMaybe<Auth_One_Time_Token_Bool_Exp>;
};


export type Query_RootAuth_One_Time_Token_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_One_Time_Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_One_Time_Token_Order_By>>;
  where?: InputMaybe<Auth_One_Time_Token_Bool_Exp>;
};


export type Query_RootAuth_One_Time_Token_By_PkArgs = {
  value: Scalars['uuid'];
};


export type Query_RootBikecenter_User_InfoArgs = {
  distinct_on?: InputMaybe<Array<Bikecenter_User_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bikecenter_User_Info_Order_By>>;
  where?: InputMaybe<Bikecenter_User_Info_Bool_Exp>;
};


export type Query_RootBikecenter_User_Info_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bikecenter_User_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bikecenter_User_Info_Order_By>>;
  where?: InputMaybe<Bikecenter_User_Info_Bool_Exp>;
};


export type Query_RootBikefitting_Bikefitting_SessionArgs = {
  distinct_on?: InputMaybe<Array<Bikefitting_Bikefitting_Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bikefitting_Bikefitting_Session_Order_By>>;
  where?: InputMaybe<Bikefitting_Bikefitting_Session_Bool_Exp>;
};


export type Query_RootBikefitting_Bikefitting_Session_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bikefitting_Bikefitting_Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bikefitting_Bikefitting_Session_Order_By>>;
  where?: InputMaybe<Bikefitting_Bikefitting_Session_Bool_Exp>;
};


export type Query_RootBikefitting_Bikefitting_Session_By_PkArgs = {
  bikefitting_id: Scalars['uuid'];
};


export type Query_RootCommission_AccountArgs = {
  distinct_on?: InputMaybe<Array<Commission_Account_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_Account_Order_By>>;
  where?: InputMaybe<Commission_Account_Bool_Exp>;
};


export type Query_RootCommission_Account_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Commission_Account_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_Account_Order_By>>;
  where?: InputMaybe<Commission_Account_Bool_Exp>;
};


export type Query_RootCommission_Account_BalanceArgs = {
  distinct_on?: InputMaybe<Array<Commission_Account_Balance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_Account_Balance_Order_By>>;
  where?: InputMaybe<Commission_Account_Balance_Bool_Exp>;
};


export type Query_RootCommission_Account_Balance_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Commission_Account_Balance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_Account_Balance_Order_By>>;
  where?: InputMaybe<Commission_Account_Balance_Bool_Exp>;
};


export type Query_RootCommission_Account_By_PkArgs = {
  answer_id: Scalars['String'];
  booking_time: Scalars['timestamptz'];
  type: Commission_Type_Enum;
  vendor_id: Scalars['String'];
};


export type Query_RootCommission_BoostArgs = {
  distinct_on?: InputMaybe<Array<Commission_Boost_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_Boost_Order_By>>;
  where?: InputMaybe<Commission_Boost_Bool_Exp>;
};


export type Query_RootCommission_Boost_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Commission_Boost_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_Boost_Order_By>>;
  where?: InputMaybe<Commission_Boost_Bool_Exp>;
};


export type Query_RootCommission_Boost_By_PkArgs = {
  start_date: Scalars['date'];
  vendor_id: Scalars['String'];
};


export type Query_RootCommission_Detailed_Account_BalanceArgs = {
  distinct_on?: InputMaybe<Array<Commission_Detailed_Account_Balance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_Detailed_Account_Balance_Order_By>>;
  where?: InputMaybe<Commission_Detailed_Account_Balance_Bool_Exp>;
};


export type Query_RootCommission_Detailed_Account_Balance_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Commission_Detailed_Account_Balance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_Detailed_Account_Balance_Order_By>>;
  where?: InputMaybe<Commission_Detailed_Account_Balance_Bool_Exp>;
};


export type Query_RootCommission_Eligible_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Commission_Eligible_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_Eligible_Answers_Order_By>>;
  where?: InputMaybe<Commission_Eligible_Answers_Bool_Exp>;
};


export type Query_RootCommission_Eligible_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Commission_Eligible_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_Eligible_Answers_Order_By>>;
  where?: InputMaybe<Commission_Eligible_Answers_Bool_Exp>;
};


export type Query_RootCommission_Eligible_OrdersArgs = {
  distinct_on?: InputMaybe<Array<Commission_Eligible_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_Eligible_Orders_Order_By>>;
  where?: InputMaybe<Commission_Eligible_Orders_Bool_Exp>;
};


export type Query_RootCommission_Eligible_Orders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Commission_Eligible_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_Eligible_Orders_Order_By>>;
  where?: InputMaybe<Commission_Eligible_Orders_Bool_Exp>;
};


export type Query_RootCommission_Payout_LogArgs = {
  distinct_on?: InputMaybe<Array<Commission_Payout_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_Payout_Log_Order_By>>;
  where?: InputMaybe<Commission_Payout_Log_Bool_Exp>;
};


export type Query_RootCommission_Payout_Log_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Commission_Payout_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_Payout_Log_Order_By>>;
  where?: InputMaybe<Commission_Payout_Log_Bool_Exp>;
};


export type Query_RootCommission_Payout_Log_By_PkArgs = {
  quarter: Scalars['Int'];
  vendor_id: Scalars['String'];
  year: Scalars['Int'];
};


export type Query_RootCommission_TypeArgs = {
  distinct_on?: InputMaybe<Array<Commission_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_Type_Order_By>>;
  where?: InputMaybe<Commission_Type_Bool_Exp>;
};


export type Query_RootCommission_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Commission_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_Type_Order_By>>;
  where?: InputMaybe<Commission_Type_Bool_Exp>;
};


export type Query_RootCommission_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootCommission_User_OverviewArgs = {
  distinct_on?: InputMaybe<Array<Commission_User_Overview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_User_Overview_Order_By>>;
  where?: InputMaybe<Commission_User_Overview_Bool_Exp>;
};


export type Query_RootCommission_User_Overview_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Commission_User_Overview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_User_Overview_Order_By>>;
  where?: InputMaybe<Commission_User_Overview_Bool_Exp>;
};


export type Query_RootCommission_Vendor_OverviewArgs = {
  distinct_on?: InputMaybe<Array<Commission_Vendor_Overview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_Vendor_Overview_Order_By>>;
  where?: InputMaybe<Commission_Vendor_Overview_Bool_Exp>;
};


export type Query_RootCommission_Vendor_Overview_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Commission_Vendor_Overview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_Vendor_Overview_Order_By>>;
  where?: InputMaybe<Commission_Vendor_Overview_Bool_Exp>;
};


export type Query_RootCommission_Webshop_DataArgs = {
  distinct_on?: InputMaybe<Array<Commission_Webshop_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_Webshop_Data_Order_By>>;
  where?: InputMaybe<Commission_Webshop_Data_Bool_Exp>;
};


export type Query_RootCommission_Webshop_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Commission_Webshop_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_Webshop_Data_Order_By>>;
  where?: InputMaybe<Commission_Webshop_Data_Bool_Exp>;
};


export type Query_RootCommission_Webshop_Data_By_PkArgs = {
  ox_order_id: Scalars['Int'];
};


export type Query_RootContent_ChaptersArgs = {
  distinct_on?: InputMaybe<Array<Content_Chapters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Chapters_Order_By>>;
  where?: InputMaybe<Content_Chapters_Bool_Exp>;
};


export type Query_RootContent_Chapters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Content_Chapters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Chapters_Order_By>>;
  where?: InputMaybe<Content_Chapters_Bool_Exp>;
};


export type Query_RootContent_Chapters_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootContent_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Content_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Choices_Order_By>>;
  where?: InputMaybe<Content_Choices_Bool_Exp>;
};


export type Query_RootContent_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Content_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Choices_Order_By>>;
  where?: InputMaybe<Content_Choices_Bool_Exp>;
};


export type Query_RootContent_Choices_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootContent_ContactpointsArgs = {
  distinct_on?: InputMaybe<Array<Content_Contactpoints_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Contactpoints_Order_By>>;
  where?: InputMaybe<Content_Contactpoints_Bool_Exp>;
};


export type Query_RootContent_Contactpoints_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Content_Contactpoints_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Contactpoints_Order_By>>;
  where?: InputMaybe<Content_Contactpoints_Bool_Exp>;
};


export type Query_RootContent_Contactpoints_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootContent_Lection_RequirementsArgs = {
  distinct_on?: InputMaybe<Array<Content_Lection_Requirements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Lection_Requirements_Order_By>>;
  where?: InputMaybe<Content_Lection_Requirements_Bool_Exp>;
};


export type Query_RootContent_Lection_Requirements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Content_Lection_Requirements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Lection_Requirements_Order_By>>;
  where?: InputMaybe<Content_Lection_Requirements_Bool_Exp>;
};


export type Query_RootContent_Lection_Requirements_By_PkArgs = {
  lection_id: Scalars['Int'];
  required_lection_id: Scalars['Int'];
};


export type Query_RootContent_Lection_Requirements_Hierarchical_StructureArgs = {
  distinct_on?: InputMaybe<Array<Content_Lection_Requirements_Hierarchical_Structure_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Lection_Requirements_Hierarchical_Structure_Order_By>>;
  where?: InputMaybe<Content_Lection_Requirements_Hierarchical_Structure_Bool_Exp>;
};


export type Query_RootContent_Lection_Requirements_Hierarchical_Structure_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Content_Lection_Requirements_Hierarchical_Structure_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Lection_Requirements_Hierarchical_Structure_Order_By>>;
  where?: InputMaybe<Content_Lection_Requirements_Hierarchical_Structure_Bool_Exp>;
};


export type Query_RootContent_LectionsArgs = {
  distinct_on?: InputMaybe<Array<Content_Lections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Lections_Order_By>>;
  where?: InputMaybe<Content_Lections_Bool_Exp>;
};


export type Query_RootContent_Lections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Content_Lections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Lections_Order_By>>;
  where?: InputMaybe<Content_Lections_Bool_Exp>;
};


export type Query_RootContent_Lections_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootContent_LevelsArgs = {
  distinct_on?: InputMaybe<Array<Content_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Levels_Order_By>>;
  where?: InputMaybe<Content_Levels_Bool_Exp>;
};


export type Query_RootContent_Levels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Content_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Levels_Order_By>>;
  where?: InputMaybe<Content_Levels_Bool_Exp>;
};


export type Query_RootContent_Levels_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootContent_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Content_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Questions_Order_By>>;
  where?: InputMaybe<Content_Questions_Bool_Exp>;
};


export type Query_RootContent_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Content_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Questions_Order_By>>;
  where?: InputMaybe<Content_Questions_Bool_Exp>;
};


export type Query_RootContent_Questions_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootContent_Quiz_TypeArgs = {
  distinct_on?: InputMaybe<Array<Content_Quiz_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Quiz_Type_Order_By>>;
  where?: InputMaybe<Content_Quiz_Type_Bool_Exp>;
};


export type Query_RootContent_Quiz_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Content_Quiz_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Quiz_Type_Order_By>>;
  where?: InputMaybe<Content_Quiz_Type_Bool_Exp>;
};


export type Query_RootContent_Quiz_Type_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootContent_QuizzesArgs = {
  distinct_on?: InputMaybe<Array<Content_Quizzes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Quizzes_Order_By>>;
  where?: InputMaybe<Content_Quizzes_Bool_Exp>;
};


export type Query_RootContent_Quizzes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Content_Quizzes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Quizzes_Order_By>>;
  where?: InputMaybe<Content_Quizzes_Bool_Exp>;
};


export type Query_RootContent_Quizzes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCountry_CodeArgs = {
  distinct_on?: InputMaybe<Array<Country_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Country_Code_Order_By>>;
  where?: InputMaybe<Country_Code_Bool_Exp>;
};


export type Query_RootCountry_Code_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Country_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Country_Code_Order_By>>;
  where?: InputMaybe<Country_Code_Bool_Exp>;
};


export type Query_RootCountry_Code_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootCurrencyArgs = {
  distinct_on?: InputMaybe<Array<Currency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Currency_Order_By>>;
  where?: InputMaybe<Currency_Bool_Exp>;
};


export type Query_RootCurrency_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Currency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Currency_Order_By>>;
  where?: InputMaybe<Currency_Bool_Exp>;
};


export type Query_RootCurrency_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootCurrent_UserArgs = {
  distinct_on?: InputMaybe<Array<Current_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Current_User_Order_By>>;
  where?: InputMaybe<Current_User_Bool_Exp>;
};


export type Query_RootCurrent_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Current_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Current_User_Order_By>>;
  where?: InputMaybe<Current_User_Bool_Exp>;
};


export type Query_RootCustomer_AnswerArgs = {
  distinct_on?: InputMaybe<Array<Customer_Answer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Answer_Order_By>>;
  where?: InputMaybe<Customer_Answer_Bool_Exp>;
};


export type Query_RootCustomer_Answer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Answer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Answer_Order_By>>;
  where?: InputMaybe<Customer_Answer_Bool_Exp>;
};


export type Query_RootCustomer_Answer_Article_EventArgs = {
  distinct_on?: InputMaybe<Array<Customer_Answer_Article_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Answer_Article_Event_Order_By>>;
  where?: InputMaybe<Customer_Answer_Article_Event_Bool_Exp>;
};


export type Query_RootCustomer_Answer_Article_Event_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Answer_Article_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Answer_Article_Event_Order_By>>;
  where?: InputMaybe<Customer_Answer_Article_Event_Bool_Exp>;
};


export type Query_RootCustomer_Answer_Article_Event_By_PkArgs = {
  answer_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
};


export type Query_RootCustomer_Answer_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootCustomer_Answer_Foot_ProblemsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Answer_Foot_Problems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Answer_Foot_Problems_Order_By>>;
  where?: InputMaybe<Customer_Answer_Foot_Problems_Bool_Exp>;
};


export type Query_RootCustomer_Answer_Foot_Problems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Answer_Foot_Problems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Answer_Foot_Problems_Order_By>>;
  where?: InputMaybe<Customer_Answer_Foot_Problems_Bool_Exp>;
};


export type Query_RootCustomer_Answer_Foot_Problems_By_PkArgs = {
  answer_id: Scalars['String'];
  foot_problem_id: Answer_Enums_Foot_Problems_Enum;
};


export type Query_RootCustomer_Answer_Hand_ProblemsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Answer_Hand_Problems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Answer_Hand_Problems_Order_By>>;
  where?: InputMaybe<Customer_Answer_Hand_Problems_Bool_Exp>;
};


export type Query_RootCustomer_Answer_Hand_Problems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Answer_Hand_Problems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Answer_Hand_Problems_Order_By>>;
  where?: InputMaybe<Customer_Answer_Hand_Problems_Bool_Exp>;
};


export type Query_RootCustomer_Answer_Hand_Problems_By_PkArgs = {
  answer_id: Scalars['String'];
  hand_problem_id: Answer_Enums_Hand_Problems_Enum;
};


export type Query_RootCustomer_Answer_Pelvic_ProblemsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Answer_Pelvic_Problems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Answer_Pelvic_Problems_Order_By>>;
  where?: InputMaybe<Customer_Answer_Pelvic_Problems_Bool_Exp>;
};


export type Query_RootCustomer_Answer_Pelvic_Problems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Answer_Pelvic_Problems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Answer_Pelvic_Problems_Order_By>>;
  where?: InputMaybe<Customer_Answer_Pelvic_Problems_Bool_Exp>;
};


export type Query_RootCustomer_Answer_Pelvic_Problems_By_PkArgs = {
  answer_id: Scalars['String'];
  pelvic_problem_id: Answer_Enums_Pelvic_Problems_Enum;
};


export type Query_RootCustomer_Answer_ResultsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Answer_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Answer_Results_Order_By>>;
  where?: InputMaybe<Customer_Answer_Results_Bool_Exp>;
};


export type Query_RootCustomer_Answer_Results_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Answer_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Answer_Results_Order_By>>;
  where?: InputMaybe<Customer_Answer_Results_Bool_Exp>;
};


export type Query_RootCustomer_Answer_Results_By_PkArgs = {
  answer_id: Scalars['String'];
};


export type Query_RootCustomer_Dashboard_EventArgs = {
  distinct_on?: InputMaybe<Array<Customer_Dashboard_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Dashboard_Event_Order_By>>;
  where?: InputMaybe<Customer_Dashboard_Event_Bool_Exp>;
};


export type Query_RootCustomer_Dashboard_Event_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Dashboard_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Dashboard_Event_Order_By>>;
  where?: InputMaybe<Customer_Dashboard_Event_Bool_Exp>;
};


export type Query_RootCustomer_Dashboard_Event_By_PkArgs = {
  answer_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
};


export type Query_RootCustomer_Dashboard_Event_KindArgs = {
  distinct_on?: InputMaybe<Array<Customer_Dashboard_Event_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Dashboard_Event_Kind_Order_By>>;
  where?: InputMaybe<Customer_Dashboard_Event_Kind_Bool_Exp>;
};


export type Query_RootCustomer_Dashboard_Event_Kind_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Dashboard_Event_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Dashboard_Event_Kind_Order_By>>;
  where?: InputMaybe<Customer_Dashboard_Event_Kind_Bool_Exp>;
};


export type Query_RootCustomer_Dashboard_Event_Kind_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootCustomer_Dashboard_Page_VisitArgs = {
  distinct_on?: InputMaybe<Array<Customer_Dashboard_Page_Visit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Dashboard_Page_Visit_Order_By>>;
  where?: InputMaybe<Customer_Dashboard_Page_Visit_Bool_Exp>;
};


export type Query_RootCustomer_Dashboard_Page_Visit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Dashboard_Page_Visit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Dashboard_Page_Visit_Order_By>>;
  where?: InputMaybe<Customer_Dashboard_Page_Visit_Bool_Exp>;
};


export type Query_RootCustomer_Deleted_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Customer_Deleted_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Deleted_Answers_Order_By>>;
  where?: InputMaybe<Customer_Deleted_Answers_Bool_Exp>;
};


export type Query_RootCustomer_Deleted_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Deleted_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Deleted_Answers_Order_By>>;
  where?: InputMaybe<Customer_Deleted_Answers_Bool_Exp>;
};


export type Query_RootCustomer_Deleted_Answers_By_PkArgs = {
  answer_id: Scalars['String'];
};


export type Query_RootCustomer_EmailsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Emails_Order_By>>;
  where?: InputMaybe<Customer_Emails_Bool_Exp>;
};


export type Query_RootCustomer_Emails_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Emails_Order_By>>;
  where?: InputMaybe<Customer_Emails_Bool_Exp>;
};


export type Query_RootCustomer_Emails_By_PkArgs = {
  answer_id: Scalars['String'];
  type: Scalars['String'];
};


export type Query_RootCustomer_RegistrationArgs = {
  distinct_on?: InputMaybe<Array<Customer_Registration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Registration_Order_By>>;
  where?: InputMaybe<Customer_Registration_Bool_Exp>;
};


export type Query_RootCustomer_Registration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Registration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Registration_Order_By>>;
  where?: InputMaybe<Customer_Registration_Bool_Exp>;
};


export type Query_RootCustomer_Registration_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCustomer_SignatureArgs = {
  distinct_on?: InputMaybe<Array<Customer_Signature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Signature_Order_By>>;
  where?: InputMaybe<Customer_Signature_Bool_Exp>;
};


export type Query_RootCustomer_Signature_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Signature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Signature_Order_By>>;
  where?: InputMaybe<Customer_Signature_Bool_Exp>;
};


export type Query_RootCustomer_Signature_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCustomer_VoucherArgs = {
  distinct_on?: InputMaybe<Array<Customer_Voucher_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Voucher_Order_By>>;
  where?: InputMaybe<Customer_Voucher_Bool_Exp>;
};


export type Query_RootCustomer_Voucher_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Voucher_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Voucher_Order_By>>;
  where?: InputMaybe<Customer_Voucher_Bool_Exp>;
};


export type Query_RootCustomer_Voucher_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCustomer_Web_Questionnaire_CompletionArgs = {
  distinct_on?: InputMaybe<Array<Customer_Web_Questionnaire_Completion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Web_Questionnaire_Completion_Order_By>>;
  where?: InputMaybe<Customer_Web_Questionnaire_Completion_Bool_Exp>;
};


export type Query_RootCustomer_Web_Questionnaire_Completion_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Web_Questionnaire_Completion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Web_Questionnaire_Completion_Order_By>>;
  where?: InputMaybe<Customer_Web_Questionnaire_Completion_Bool_Exp>;
};


export type Query_RootCustomer_Web_Questionnaire_Completion_By_PkArgs = {
  answer_id: Scalars['String'];
};


export type Query_RootCv_BodyArgs = {
  distinct_on?: InputMaybe<Array<Cv_Body_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cv_Body_Order_By>>;
  where?: InputMaybe<Cv_Body_Bool_Exp>;
};


export type Query_RootCv_Body_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cv_Body_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cv_Body_Order_By>>;
  where?: InputMaybe<Cv_Body_Bool_Exp>;
};


export type Query_RootCv_Body_By_PkArgs = {
  answer_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
};


export type Query_RootCv_HandmarksArgs = {
  distinct_on?: InputMaybe<Array<Cv_Handmarks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cv_Handmarks_Order_By>>;
  where?: InputMaybe<Cv_Handmarks_Bool_Exp>;
};


export type Query_RootCv_Handmarks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cv_Handmarks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cv_Handmarks_Order_By>>;
  where?: InputMaybe<Cv_Handmarks_Bool_Exp>;
};


export type Query_RootCv_Handmarks_By_PkArgs = {
  answer_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
};


export type Query_RootCv_SitbonesArgs = {
  distinct_on?: InputMaybe<Array<Cv_Sitbones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cv_Sitbones_Order_By>>;
  where?: InputMaybe<Cv_Sitbones_Bool_Exp>;
};


export type Query_RootCv_Sitbones_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cv_Sitbones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cv_Sitbones_Order_By>>;
  where?: InputMaybe<Cv_Sitbones_Bool_Exp>;
};


export type Query_RootCv_Sitbones_By_PkArgs = {
  answer_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
};


export type Query_RootDistributorArgs = {
  distinct_on?: InputMaybe<Array<Distributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Distributor_Order_By>>;
  where?: InputMaybe<Distributor_Bool_Exp>;
};


export type Query_RootDistributor_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Distributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Distributor_Order_By>>;
  where?: InputMaybe<Distributor_Bool_Exp>;
};


export type Query_RootDistributor_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootDistributorsArgs = {
  distinct_on?: InputMaybe<Array<Distributors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Distributors_Order_By>>;
  where?: InputMaybe<Distributors_Bool_Exp>;
};


export type Query_RootDistributors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Distributors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Distributors_Order_By>>;
  where?: InputMaybe<Distributors_Bool_Exp>;
};


export type Query_RootDistributors_By_PkArgs = {
  country_code: Scalars['String'];
};


export type Query_RootEvent_Enums_Article_EventArgs = {
  distinct_on?: InputMaybe<Array<Event_Enums_Article_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Enums_Article_Event_Order_By>>;
  where?: InputMaybe<Event_Enums_Article_Event_Bool_Exp>;
};


export type Query_RootEvent_Enums_Article_Event_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Enums_Article_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Enums_Article_Event_Order_By>>;
  where?: InputMaybe<Event_Enums_Article_Event_Bool_Exp>;
};


export type Query_RootEvent_Enums_Article_Event_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEvent_Enums_Event_SourceArgs = {
  distinct_on?: InputMaybe<Array<Event_Enums_Event_Source_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Enums_Event_Source_Order_By>>;
  where?: InputMaybe<Event_Enums_Event_Source_Bool_Exp>;
};


export type Query_RootEvent_Enums_Event_Source_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Enums_Event_Source_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Enums_Event_Source_Order_By>>;
  where?: InputMaybe<Event_Enums_Event_Source_Bool_Exp>;
};


export type Query_RootEvent_Enums_Event_Source_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEvent_Enums_Log_LevelArgs = {
  distinct_on?: InputMaybe<Array<Event_Enums_Log_Level_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Enums_Log_Level_Order_By>>;
  where?: InputMaybe<Event_Enums_Log_Level_Bool_Exp>;
};


export type Query_RootEvent_Enums_Log_Level_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Enums_Log_Level_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Enums_Log_Level_Order_By>>;
  where?: InputMaybe<Event_Enums_Log_Level_Bool_Exp>;
};


export type Query_RootEvent_Enums_Log_Level_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEvent_Log_Event_TypesArgs = {
  distinct_on?: InputMaybe<Array<Event_Log_Event_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Log_Event_Types_Order_By>>;
  where?: InputMaybe<Event_Log_Event_Types_Bool_Exp>;
};


export type Query_RootEvent_Log_Event_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Log_Event_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Log_Event_Types_Order_By>>;
  where?: InputMaybe<Event_Log_Event_Types_Bool_Exp>;
};


export type Query_RootEvent_Log_Event_Types_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootEvent_Log_EventsArgs = {
  distinct_on?: InputMaybe<Array<Event_Log_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Log_Events_Order_By>>;
  where?: InputMaybe<Event_Log_Events_Bool_Exp>;
};


export type Query_RootEvent_Log_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Log_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Log_Events_Order_By>>;
  where?: InputMaybe<Event_Log_Events_Bool_Exp>;
};


export type Query_RootEvent_Log_Events_By_PkArgs = {
  created_at: Scalars['timestamptz'];
  type: Scalars['String'];
  user_id: Scalars['uuid'];
};


export type Query_RootFrontend_Vendor_SupportArgs = {
  distinct_on?: InputMaybe<Array<Frontend_Vendor_Support_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Frontend_Vendor_Support_Order_By>>;
  where?: InputMaybe<Frontend_Vendor_Support_Bool_Exp>;
};


export type Query_RootFrontend_Vendor_Support_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Frontend_Vendor_Support_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Frontend_Vendor_Support_Order_By>>;
  where?: InputMaybe<Frontend_Vendor_Support_Bool_Exp>;
};


export type Query_RootFrontend_Vendor_Support_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootHealthArgs = {
  distinct_on?: InputMaybe<Array<Health_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Order_By>>;
  where?: InputMaybe<Health_Bool_Exp>;
};


export type Query_RootHealth_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Health_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Order_By>>;
  where?: InputMaybe<Health_Bool_Exp>;
};


export type Query_RootIframe_FeedbackArgs = {
  distinct_on?: InputMaybe<Array<Iframe_Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Iframe_Feedback_Order_By>>;
  where?: InputMaybe<Iframe_Feedback_Bool_Exp>;
};


export type Query_RootIframe_Feedback_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Iframe_Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Iframe_Feedback_Order_By>>;
  where?: InputMaybe<Iframe_Feedback_Bool_Exp>;
};


export type Query_RootIframe_Feedback_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootIframe_Newsletter_SubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Iframe_Newsletter_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Iframe_Newsletter_Subscriptions_Order_By>>;
  where?: InputMaybe<Iframe_Newsletter_Subscriptions_Bool_Exp>;
};


export type Query_RootIframe_Newsletter_Subscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Iframe_Newsletter_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Iframe_Newsletter_Subscriptions_Order_By>>;
  where?: InputMaybe<Iframe_Newsletter_Subscriptions_Bool_Exp>;
};


export type Query_RootIframe_Newsletter_Subscriptions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootIframe_RatingArgs = {
  distinct_on?: InputMaybe<Array<Iframe_Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Iframe_Rating_Order_By>>;
  where?: InputMaybe<Iframe_Rating_Bool_Exp>;
};


export type Query_RootIframe_Rating_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Iframe_Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Iframe_Rating_Order_By>>;
  where?: InputMaybe<Iframe_Rating_Bool_Exp>;
};


export type Query_RootIframe_Rating_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootIqlab_Rewards_TypesArgs = {
  distinct_on?: InputMaybe<Array<Iqlab_Rewards_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Iqlab_Rewards_Types_Order_By>>;
  where?: InputMaybe<Iqlab_Rewards_Types_Bool_Exp>;
};


export type Query_RootIqlab_Rewards_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Iqlab_Rewards_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Iqlab_Rewards_Types_Order_By>>;
  where?: InputMaybe<Iqlab_Rewards_Types_Bool_Exp>;
};


export type Query_RootIqlab_Rewards_Types_By_PkArgs = {
  type: Scalars['String'];
};


export type Query_RootIqlab_Rewards_VouchersArgs = {
  distinct_on?: InputMaybe<Array<Iqlab_Rewards_Vouchers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Iqlab_Rewards_Vouchers_Order_By>>;
  where?: InputMaybe<Iqlab_Rewards_Vouchers_Bool_Exp>;
};


export type Query_RootIqlab_Rewards_Vouchers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Iqlab_Rewards_Vouchers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Iqlab_Rewards_Vouchers_Order_By>>;
  where?: InputMaybe<Iqlab_Rewards_Vouchers_Bool_Exp>;
};


export type Query_RootIqlab_Rewards_Vouchers_By_PkArgs = {
  code: Scalars['String'];
  created_at: Scalars['timestamptz'];
};


export type Query_RootMeasurement_MeasureArgs = {
  distinct_on?: InputMaybe<Array<Measurement_Measure_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Measurement_Measure_Order_By>>;
  where?: InputMaybe<Measurement_Measure_Bool_Exp>;
};


export type Query_RootMeasurement_Measure_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Measurement_Measure_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Measurement_Measure_Order_By>>;
  where?: InputMaybe<Measurement_Measure_Bool_Exp>;
};


export type Query_RootMeasurement_Measure_By_PkArgs = {
  session_id: Scalars['Int'];
  type: Scalars['String'];
};


export type Query_RootMeasurement_Measure_TypeArgs = {
  distinct_on?: InputMaybe<Array<Measurement_Measure_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Measurement_Measure_Type_Order_By>>;
  where?: InputMaybe<Measurement_Measure_Type_Bool_Exp>;
};


export type Query_RootMeasurement_Measure_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Measurement_Measure_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Measurement_Measure_Type_Order_By>>;
  where?: InputMaybe<Measurement_Measure_Type_Bool_Exp>;
};


export type Query_RootMeasurement_Measure_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootMeasurement_Session_SourceArgs = {
  distinct_on?: InputMaybe<Array<Measurement_Session_Source_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Measurement_Session_Source_Order_By>>;
  where?: InputMaybe<Measurement_Session_Source_Bool_Exp>;
};


export type Query_RootMeasurement_Session_Source_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Measurement_Session_Source_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Measurement_Session_Source_Order_By>>;
  where?: InputMaybe<Measurement_Session_Source_Bool_Exp>;
};


export type Query_RootMeasurement_Session_Source_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootMeasurement_Session_TypeArgs = {
  distinct_on?: InputMaybe<Array<Measurement_Session_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Measurement_Session_Type_Order_By>>;
  where?: InputMaybe<Measurement_Session_Type_Bool_Exp>;
};


export type Query_RootMeasurement_Session_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Measurement_Session_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Measurement_Session_Type_Order_By>>;
  where?: InputMaybe<Measurement_Session_Type_Bool_Exp>;
};


export type Query_RootMeasurement_Session_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootMeasurement_SessionsArgs = {
  distinct_on?: InputMaybe<Array<Measurement_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Measurement_Sessions_Order_By>>;
  where?: InputMaybe<Measurement_Sessions_Bool_Exp>;
};


export type Query_RootMeasurement_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Measurement_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Measurement_Sessions_Order_By>>;
  where?: InputMaybe<Measurement_Sessions_Bool_Exp>;
};


export type Query_RootMeasurement_Sessions_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootMetric_Answer_LocationArgs = {
  distinct_on?: InputMaybe<Array<Metric_Answer_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Metric_Answer_Location_Order_By>>;
  where?: InputMaybe<Metric_Answer_Location_Bool_Exp>;
};


export type Query_RootMetric_Answer_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Metric_Answer_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Metric_Answer_Location_Order_By>>;
  where?: InputMaybe<Metric_Answer_Location_Bool_Exp>;
};


export type Query_RootMetric_Answer_Location_By_PkArgs = {
  answer_id: Scalars['String'];
};


export type Query_RootMetric_App_EventsArgs = {
  distinct_on?: InputMaybe<Array<Metric_App_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Metric_App_Events_Order_By>>;
  where?: InputMaybe<Metric_App_Events_Bool_Exp>;
};


export type Query_RootMetric_App_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Metric_App_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Metric_App_Events_Order_By>>;
  where?: InputMaybe<Metric_App_Events_Bool_Exp>;
};


export type Query_RootMetric_App_Events_By_PkArgs = {
  created_at: Scalars['timestamptz'];
  user_id: Scalars['uuid'];
};


export type Query_RootMetric_Avg_Questionnaire_Count_30_DaysArgs = {
  distinct_on?: InputMaybe<Array<Metric_Avg_Questionnaire_Count_30_Days_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Metric_Avg_Questionnaire_Count_30_Days_Order_By>>;
  where?: InputMaybe<Metric_Avg_Questionnaire_Count_30_Days_Bool_Exp>;
};


export type Query_RootMetric_Avg_Questionnaire_Count_30_Days_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Metric_Avg_Questionnaire_Count_30_Days_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Metric_Avg_Questionnaire_Count_30_Days_Order_By>>;
  where?: InputMaybe<Metric_Avg_Questionnaire_Count_30_Days_Bool_Exp>;
};


export type Query_RootMetric_Max_Questionnaires_With_DayArgs = {
  distinct_on?: InputMaybe<Array<Metric_Max_Questionnaires_With_Day_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Metric_Max_Questionnaires_With_Day_Order_By>>;
  where?: InputMaybe<Metric_Max_Questionnaires_With_Day_Bool_Exp>;
};


export type Query_RootMetric_Max_Questionnaires_With_Day_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Metric_Max_Questionnaires_With_Day_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Metric_Max_Questionnaires_With_Day_Order_By>>;
  where?: InputMaybe<Metric_Max_Questionnaires_With_Day_Bool_Exp>;
};


export type Query_RootMetric_Question_DurationArgs = {
  distinct_on?: InputMaybe<Array<Metric_Question_Duration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Metric_Question_Duration_Order_By>>;
  where?: InputMaybe<Metric_Question_Duration_Bool_Exp>;
};


export type Query_RootMetric_Question_Duration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Metric_Question_Duration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Metric_Question_Duration_Order_By>>;
  where?: InputMaybe<Metric_Question_Duration_Bool_Exp>;
};


export type Query_RootMetric_Question_Duration_By_PkArgs = {
  created_at: Scalars['timestamptz'];
  customer_answer_id: Scalars['String'];
  question_id: Scalars['String'];
};


export type Query_RootMetric_Questionnaires_Per_Vendor_And_YearArgs = {
  distinct_on?: InputMaybe<Array<Metric_Questionnaires_Per_Vendor_And_Year_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Metric_Questionnaires_Per_Vendor_And_Year_Order_By>>;
  where?: InputMaybe<Metric_Questionnaires_Per_Vendor_And_Year_Bool_Exp>;
};


export type Query_RootMetric_Questionnaires_Per_Vendor_And_Year_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Metric_Questionnaires_Per_Vendor_And_Year_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Metric_Questionnaires_Per_Vendor_And_Year_Order_By>>;
  where?: InputMaybe<Metric_Questionnaires_Per_Vendor_And_Year_Bool_Exp>;
};


export type Query_RootMetric_Usage_InformationArgs = {
  distinct_on?: InputMaybe<Array<Metric_Usage_Information_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Metric_Usage_Information_Order_By>>;
  where?: InputMaybe<Metric_Usage_Information_Bool_Exp>;
};


export type Query_RootMetric_Usage_Information_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Metric_Usage_Information_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Metric_Usage_Information_Order_By>>;
  where?: InputMaybe<Metric_Usage_Information_Bool_Exp>;
};


export type Query_RootMetric_Usage_Information_By_PkArgs = {
  device_id: Scalars['String'];
  user_id: Scalars['uuid'];
  vendor_id: Scalars['String'];
};


export type Query_RootMetric_User_RankingArgs = {
  distinct_on?: InputMaybe<Array<Metric_User_Ranking_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Metric_User_Ranking_Order_By>>;
  where?: InputMaybe<Metric_User_Ranking_Bool_Exp>;
};


export type Query_RootMetric_User_Ranking_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Metric_User_Ranking_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Metric_User_Ranking_Order_By>>;
  where?: InputMaybe<Metric_User_Ranking_Bool_Exp>;
};


export type Query_RootOnboarding_EmailsArgs = {
  distinct_on?: InputMaybe<Array<Onboarding_Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Onboarding_Emails_Order_By>>;
  where?: InputMaybe<Onboarding_Emails_Bool_Exp>;
};


export type Query_RootOnboarding_Emails_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Onboarding_Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Onboarding_Emails_Order_By>>;
  where?: InputMaybe<Onboarding_Emails_Bool_Exp>;
};


export type Query_RootOnboarding_Emails_By_PkArgs = {
  registration_id: Scalars['Int'];
  type: Scalars['String'];
};


export type Query_RootOrganisationArgs = {
  distinct_on?: InputMaybe<Array<Organisation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organisation_Order_By>>;
  where?: InputMaybe<Organisation_Bool_Exp>;
};


export type Query_RootOrganisation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organisation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organisation_Order_By>>;
  where?: InputMaybe<Organisation_Bool_Exp>;
};


export type Query_RootOrganisation_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootPreferences_User_PreferencesArgs = {
  distinct_on?: InputMaybe<Array<Preferences_User_Preferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Preferences_User_Preferences_Order_By>>;
  where?: InputMaybe<Preferences_User_Preferences_Bool_Exp>;
};


export type Query_RootPreferences_User_Preferences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Preferences_User_Preferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Preferences_User_Preferences_Order_By>>;
  where?: InputMaybe<Preferences_User_Preferences_Bool_Exp>;
};


export type Query_RootPreferences_User_Preferences_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Query_RootPreferences_Vendor_PreferencesArgs = {
  distinct_on?: InputMaybe<Array<Preferences_Vendor_Preferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Preferences_Vendor_Preferences_Order_By>>;
  where?: InputMaybe<Preferences_Vendor_Preferences_Bool_Exp>;
};


export type Query_RootPreferences_Vendor_Preferences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Preferences_Vendor_Preferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Preferences_Vendor_Preferences_Order_By>>;
  where?: InputMaybe<Preferences_Vendor_Preferences_Bool_Exp>;
};


export type Query_RootPreferences_Vendor_Preferences_By_PkArgs = {
  vendor_id: Scalars['String'];
};


export type Query_RootProgress_Chapter_OverviewArgs = {
  distinct_on?: InputMaybe<Array<Progress_Chapter_Overview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Progress_Chapter_Overview_Order_By>>;
  where?: InputMaybe<Progress_Chapter_Overview_Bool_Exp>;
};


export type Query_RootProgress_Chapter_Overview_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Progress_Chapter_Overview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Progress_Chapter_Overview_Order_By>>;
  where?: InputMaybe<Progress_Chapter_Overview_Bool_Exp>;
};


export type Query_RootProgress_Completed_ChaptersArgs = {
  distinct_on?: InputMaybe<Array<Progress_Completed_Chapters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Progress_Completed_Chapters_Order_By>>;
  where?: InputMaybe<Progress_Completed_Chapters_Bool_Exp>;
};


export type Query_RootProgress_Completed_Chapters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Progress_Completed_Chapters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Progress_Completed_Chapters_Order_By>>;
  where?: InputMaybe<Progress_Completed_Chapters_Bool_Exp>;
};


export type Query_RootProgress_Completed_Chapters_By_PkArgs = {
  chapter_id: Scalars['Int'];
  user_id: Scalars['uuid'];
};


export type Query_RootProgress_Completed_ContactpointsArgs = {
  distinct_on?: InputMaybe<Array<Progress_Completed_Contactpoints_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Progress_Completed_Contactpoints_Order_By>>;
  where?: InputMaybe<Progress_Completed_Contactpoints_Bool_Exp>;
};


export type Query_RootProgress_Completed_Contactpoints_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Progress_Completed_Contactpoints_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Progress_Completed_Contactpoints_Order_By>>;
  where?: InputMaybe<Progress_Completed_Contactpoints_Bool_Exp>;
};


export type Query_RootProgress_Completed_Contactpoints_By_PkArgs = {
  contactpoint_id: Scalars['Int'];
  user_id: Scalars['uuid'];
};


export type Query_RootProgress_Completed_LectionsArgs = {
  distinct_on?: InputMaybe<Array<Progress_Completed_Lections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Progress_Completed_Lections_Order_By>>;
  where?: InputMaybe<Progress_Completed_Lections_Bool_Exp>;
};


export type Query_RootProgress_Completed_Lections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Progress_Completed_Lections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Progress_Completed_Lections_Order_By>>;
  where?: InputMaybe<Progress_Completed_Lections_Bool_Exp>;
};


export type Query_RootProgress_Completed_Lections_By_PkArgs = {
  lection_id: Scalars['Int'];
  user_id: Scalars['uuid'];
};


export type Query_RootProgress_Contactpoint_OverviewArgs = {
  distinct_on?: InputMaybe<Array<Progress_Contactpoint_Overview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Progress_Contactpoint_Overview_Order_By>>;
  where?: InputMaybe<Progress_Contactpoint_Overview_Bool_Exp>;
};


export type Query_RootProgress_Contactpoint_Overview_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Progress_Contactpoint_Overview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Progress_Contactpoint_Overview_Order_By>>;
  where?: InputMaybe<Progress_Contactpoint_Overview_Bool_Exp>;
};


export type Query_RootProgress_Lection_OverviewArgs = {
  distinct_on?: InputMaybe<Array<Progress_Lection_Overview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Progress_Lection_Overview_Order_By>>;
  where?: InputMaybe<Progress_Lection_Overview_Bool_Exp>;
};


export type Query_RootProgress_Lection_Overview_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Progress_Lection_Overview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Progress_Lection_Overview_Order_By>>;
  where?: InputMaybe<Progress_Lection_Overview_Bool_Exp>;
};


export type Query_RootRecommendation_Own_ArticlesArgs = {
  distinct_on?: InputMaybe<Array<Recommendation_Own_Articles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendation_Own_Articles_Order_By>>;
  where?: InputMaybe<Recommendation_Own_Articles_Bool_Exp>;
};


export type Query_RootRecommendation_Own_Articles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Recommendation_Own_Articles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendation_Own_Articles_Order_By>>;
  where?: InputMaybe<Recommendation_Own_Articles_Bool_Exp>;
};


export type Query_RootRecommendation_Own_Articles_By_PkArgs = {
  answer_id: Scalars['String'];
  article_id: Scalars['String'];
};


export type Query_RootRecommendation_Return_Search_ArticleArgs = {
  distinct_on?: InputMaybe<Array<Recommendation_Return_Search_Article_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendation_Return_Search_Article_Order_By>>;
  where?: InputMaybe<Recommendation_Return_Search_Article_Bool_Exp>;
};


export type Query_RootRecommendation_Return_Search_Article_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Recommendation_Return_Search_Article_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendation_Return_Search_Article_Order_By>>;
  where?: InputMaybe<Recommendation_Return_Search_Article_Bool_Exp>;
};


export type Query_RootRecommendation_Return_Search_Article_By_PkArgs = {
  article_id: Scalars['String'];
};


export type Query_RootRecommendation_Search_ArticlesArgs = {
  args: Recommendation_Search_Articles_Args;
  distinct_on?: InputMaybe<Array<Recommendation_Return_Search_Article_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendation_Return_Search_Article_Order_By>>;
  where?: InputMaybe<Recommendation_Return_Search_Article_Bool_Exp>;
};


export type Query_RootRecommendation_Search_Articles_AggregateArgs = {
  args: Recommendation_Search_Articles_Args;
  distinct_on?: InputMaybe<Array<Recommendation_Return_Search_Article_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendation_Return_Search_Article_Order_By>>;
  where?: InputMaybe<Recommendation_Return_Search_Article_Bool_Exp>;
};


export type Query_RootRewards_Benefit_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Rewards_Benefit_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rewards_Benefit_Category_Order_By>>;
  where?: InputMaybe<Rewards_Benefit_Category_Bool_Exp>;
};


export type Query_RootRewards_Benefit_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rewards_Benefit_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rewards_Benefit_Category_Order_By>>;
  where?: InputMaybe<Rewards_Benefit_Category_Bool_Exp>;
};


export type Query_RootRewards_Benefit_Category_By_PkArgs = {
  category: Scalars['String'];
};


export type Query_RootRewards_BenefitsArgs = {
  distinct_on?: InputMaybe<Array<Rewards_Benefits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rewards_Benefits_Order_By>>;
  where?: InputMaybe<Rewards_Benefits_Bool_Exp>;
};


export type Query_RootRewards_Benefits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rewards_Benefits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rewards_Benefits_Order_By>>;
  where?: InputMaybe<Rewards_Benefits_Bool_Exp>;
};


export type Query_RootRewards_Benefits_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootRewards_BoostsArgs = {
  distinct_on?: InputMaybe<Array<Rewards_Boosts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rewards_Boosts_Order_By>>;
  where?: InputMaybe<Rewards_Boosts_Bool_Exp>;
};


export type Query_RootRewards_Boosts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rewards_Boosts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rewards_Boosts_Order_By>>;
  where?: InputMaybe<Rewards_Boosts_Bool_Exp>;
};


export type Query_RootRewards_Boosts_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootRewards_Internal_RankingArgs = {
  distinct_on?: InputMaybe<Array<Rewards_Internal_Ranking_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rewards_Internal_Ranking_Order_By>>;
  where?: InputMaybe<Rewards_Internal_Ranking_Bool_Exp>;
};


export type Query_RootRewards_Internal_Ranking_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rewards_Internal_Ranking_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rewards_Internal_Ranking_Order_By>>;
  where?: InputMaybe<Rewards_Internal_Ranking_Bool_Exp>;
};


export type Query_RootRewards_International_RankingArgs = {
  distinct_on?: InputMaybe<Array<Rewards_International_Ranking_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rewards_International_Ranking_Order_By>>;
  where?: InputMaybe<Rewards_International_Ranking_Bool_Exp>;
};


export type Query_RootRewards_International_Ranking_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rewards_International_Ranking_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rewards_International_Ranking_Order_By>>;
  where?: InputMaybe<Rewards_International_Ranking_Bool_Exp>;
};


export type Query_RootRewards_Ranking_BalancesArgs = {
  distinct_on?: InputMaybe<Array<Rewards_Ranking_Balances_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rewards_Ranking_Balances_Order_By>>;
  where?: InputMaybe<Rewards_Ranking_Balances_Bool_Exp>;
};


export type Query_RootRewards_Ranking_Balances_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rewards_Ranking_Balances_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rewards_Ranking_Balances_Order_By>>;
  where?: InputMaybe<Rewards_Ranking_Balances_Bool_Exp>;
};


export type Query_RootRewards_Reward_BalancesArgs = {
  distinct_on?: InputMaybe<Array<Rewards_Reward_Balances_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rewards_Reward_Balances_Order_By>>;
  where?: InputMaybe<Rewards_Reward_Balances_Bool_Exp>;
};


export type Query_RootRewards_Reward_Balances_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rewards_Reward_Balances_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rewards_Reward_Balances_Order_By>>;
  where?: InputMaybe<Rewards_Reward_Balances_Bool_Exp>;
};


export type Query_RootRewards_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<Rewards_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rewards_Transactions_Order_By>>;
  where?: InputMaybe<Rewards_Transactions_Bool_Exp>;
};


export type Query_RootRewards_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rewards_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rewards_Transactions_Order_By>>;
  where?: InputMaybe<Rewards_Transactions_Bool_Exp>;
};


export type Query_RootRewards_Transactions_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootSessionsArgs = {
  distinct_on?: InputMaybe<Array<Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sessions_Order_By>>;
  where?: InputMaybe<Sessions_Bool_Exp>;
};


export type Query_RootSessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sessions_Order_By>>;
  where?: InputMaybe<Sessions_Bool_Exp>;
};


export type Query_RootSessions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSqbase_Event_Log_Event_TypesArgs = {
  distinct_on?: InputMaybe<Array<Sqbase_Event_Log_Event_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sqbase_Event_Log_Event_Types_Order_By>>;
  where?: InputMaybe<Sqbase_Event_Log_Event_Types_Bool_Exp>;
};


export type Query_RootSqbase_Event_Log_Event_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sqbase_Event_Log_Event_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sqbase_Event_Log_Event_Types_Order_By>>;
  where?: InputMaybe<Sqbase_Event_Log_Event_Types_Bool_Exp>;
};


export type Query_RootSqbase_Event_Log_Event_Types_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootSqbase_Event_Log_EventsArgs = {
  distinct_on?: InputMaybe<Array<Sqbase_Event_Log_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sqbase_Event_Log_Events_Order_By>>;
  where?: InputMaybe<Sqbase_Event_Log_Events_Bool_Exp>;
};


export type Query_RootSqbase_Event_Log_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sqbase_Event_Log_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sqbase_Event_Log_Events_Order_By>>;
  where?: InputMaybe<Sqbase_Event_Log_Events_Bool_Exp>;
};


export type Query_RootSqbase_Event_Log_Events_By_PkArgs = {
  created_at: Scalars['timestamptz'];
  type: Scalars['String'];
  user_id: Scalars['uuid'];
};


export type Query_RootSqlab_ContactsArgs = {
  distinct_on?: InputMaybe<Array<Sqlab_Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sqlab_Contacts_Order_By>>;
  where?: InputMaybe<Sqlab_Contacts_Bool_Exp>;
};


export type Query_RootSqlab_Contacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sqlab_Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sqlab_Contacts_Order_By>>;
  where?: InputMaybe<Sqlab_Contacts_Bool_Exp>;
};


export type Query_RootSqlab_Contacts_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootSupport_Expiring_BoostsArgs = {
  distinct_on?: InputMaybe<Array<Support_Expiring_Boosts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Support_Expiring_Boosts_Order_By>>;
  where?: InputMaybe<Support_Expiring_Boosts_Bool_Exp>;
};


export type Query_RootSupport_Expiring_Boosts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Support_Expiring_Boosts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Support_Expiring_Boosts_Order_By>>;
  where?: InputMaybe<Support_Expiring_Boosts_Bool_Exp>;
};


export type Query_RootSupport_Follow_Up_TasksArgs = {
  distinct_on?: InputMaybe<Array<Support_Follow_Up_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Support_Follow_Up_Tasks_Order_By>>;
  where?: InputMaybe<Support_Follow_Up_Tasks_Bool_Exp>;
};


export type Query_RootSupport_Follow_Up_Tasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Support_Follow_Up_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Support_Follow_Up_Tasks_Order_By>>;
  where?: InputMaybe<Support_Follow_Up_Tasks_Bool_Exp>;
};


export type Query_RootSupport_InteractionsArgs = {
  distinct_on?: InputMaybe<Array<Support_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Support_Interactions_Order_By>>;
  where?: InputMaybe<Support_Interactions_Bool_Exp>;
};


export type Query_RootSupport_Interactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Support_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Support_Interactions_Order_By>>;
  where?: InputMaybe<Support_Interactions_Bool_Exp>;
};


export type Query_RootSupport_Interactions_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootSupport_Registrations_Without_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Support_Registrations_Without_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Support_Registrations_Without_Answers_Order_By>>;
  where?: InputMaybe<Support_Registrations_Without_Answers_Bool_Exp>;
};


export type Query_RootSupport_Registrations_Without_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Support_Registrations_Without_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Support_Registrations_Without_Answers_Order_By>>;
  where?: InputMaybe<Support_Registrations_Without_Answers_Bool_Exp>;
};


export type Query_RootSupport_Registrations_Without_Progress_Two_WeeksArgs = {
  distinct_on?: InputMaybe<Array<Support_Registrations_Without_Progress_Two_Weeks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Support_Registrations_Without_Progress_Two_Weeks_Order_By>>;
  where?: InputMaybe<Support_Registrations_Without_Progress_Two_Weeks_Bool_Exp>;
};


export type Query_RootSupport_Registrations_Without_Progress_Two_Weeks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Support_Registrations_Without_Progress_Two_Weeks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Support_Registrations_Without_Progress_Two_Weeks_Order_By>>;
  where?: InputMaybe<Support_Registrations_Without_Progress_Two_Weeks_Bool_Exp>;
};


export type Query_RootSupport_Vendor_SupportArgs = {
  distinct_on?: InputMaybe<Array<Support_Vendor_Support_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Support_Vendor_Support_Order_By>>;
  where?: InputMaybe<Support_Vendor_Support_Bool_Exp>;
};


export type Query_RootSupport_Vendor_Support_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Support_Vendor_Support_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Support_Vendor_Support_Order_By>>;
  where?: InputMaybe<Support_Vendor_Support_Bool_Exp>;
};


export type Query_RootSupport_Vendor_Support_By_PkArgs = {
  request_id: Scalars['uuid'];
};


export type Query_RootSupport_Vendor_Support_TypeArgs = {
  distinct_on?: InputMaybe<Array<Support_Vendor_Support_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Support_Vendor_Support_Type_Order_By>>;
  where?: InputMaybe<Support_Vendor_Support_Type_Bool_Exp>;
};


export type Query_RootSupport_Vendor_Support_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Support_Vendor_Support_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Support_Vendor_Support_Type_Order_By>>;
  where?: InputMaybe<Support_Vendor_Support_Type_Bool_Exp>;
};


export type Query_RootSupport_Vendor_Support_Type_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootTracker_Article_PricesArgs = {
  distinct_on?: InputMaybe<Array<Tracker_Article_Prices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tracker_Article_Prices_Order_By>>;
  where?: InputMaybe<Tracker_Article_Prices_Bool_Exp>;
};


export type Query_RootTracker_Article_Prices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tracker_Article_Prices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tracker_Article_Prices_Order_By>>;
  where?: InputMaybe<Tracker_Article_Prices_Bool_Exp>;
};


export type Query_RootTracker_Article_Prices_By_PkArgs = {
  created_at: Scalars['timestamptz'];
  ean: Scalars['String'];
  shop_domain: Scalars['String'];
};


export type Query_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Query_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Query_RootUser_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUser_DetailsArgs = {
  distinct_on?: InputMaybe<Array<User_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Details_Order_By>>;
  where?: InputMaybe<User_Details_Bool_Exp>;
};


export type Query_RootUser_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Details_Order_By>>;
  where?: InputMaybe<User_Details_Bool_Exp>;
};


export type Query_RootUser_Details_By_PkArgs = {
  user_id: Scalars['uuid'];
};

/** matrix recommendations */
export type Recommendation_Own_Articles = {
  __typename?: 'recommendation_own_articles';
  /** An object relationship */
  answer: Customer_Answer;
  answer_id: Scalars['String'];
  article_id: Scalars['String'];
  /** An array relationship */
  article_informaton_i18n: Array<Article_Information_I18n>;
  /** An aggregate relationship */
  article_informaton_i18n_aggregate: Article_Information_I18n_Aggregate;
  created_at: Scalars['timestamptz'];
  fitting: Scalars['numeric'];
  reasons: Scalars['jsonb'];
};


/** matrix recommendations */
export type Recommendation_Own_ArticlesArticle_Informaton_I18nArgs = {
  distinct_on?: InputMaybe<Array<Article_Information_I18n_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Article_Information_I18n_Order_By>>;
  where?: InputMaybe<Article_Information_I18n_Bool_Exp>;
};


/** matrix recommendations */
export type Recommendation_Own_ArticlesArticle_Informaton_I18n_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Article_Information_I18n_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Article_Information_I18n_Order_By>>;
  where?: InputMaybe<Article_Information_I18n_Bool_Exp>;
};


/** matrix recommendations */
export type Recommendation_Own_ArticlesReasonsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "recommendation.own_articles" */
export type Recommendation_Own_Articles_Aggregate = {
  __typename?: 'recommendation_own_articles_aggregate';
  aggregate?: Maybe<Recommendation_Own_Articles_Aggregate_Fields>;
  nodes: Array<Recommendation_Own_Articles>;
};

export type Recommendation_Own_Articles_Aggregate_Bool_Exp = {
  count?: InputMaybe<Recommendation_Own_Articles_Aggregate_Bool_Exp_Count>;
};

export type Recommendation_Own_Articles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Recommendation_Own_Articles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Recommendation_Own_Articles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "recommendation.own_articles" */
export type Recommendation_Own_Articles_Aggregate_Fields = {
  __typename?: 'recommendation_own_articles_aggregate_fields';
  avg?: Maybe<Recommendation_Own_Articles_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Recommendation_Own_Articles_Max_Fields>;
  min?: Maybe<Recommendation_Own_Articles_Min_Fields>;
  stddev?: Maybe<Recommendation_Own_Articles_Stddev_Fields>;
  stddev_pop?: Maybe<Recommendation_Own_Articles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Recommendation_Own_Articles_Stddev_Samp_Fields>;
  sum?: Maybe<Recommendation_Own_Articles_Sum_Fields>;
  var_pop?: Maybe<Recommendation_Own_Articles_Var_Pop_Fields>;
  var_samp?: Maybe<Recommendation_Own_Articles_Var_Samp_Fields>;
  variance?: Maybe<Recommendation_Own_Articles_Variance_Fields>;
};


/** aggregate fields of "recommendation.own_articles" */
export type Recommendation_Own_Articles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Recommendation_Own_Articles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "recommendation.own_articles" */
export type Recommendation_Own_Articles_Aggregate_Order_By = {
  avg?: InputMaybe<Recommendation_Own_Articles_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Recommendation_Own_Articles_Max_Order_By>;
  min?: InputMaybe<Recommendation_Own_Articles_Min_Order_By>;
  stddev?: InputMaybe<Recommendation_Own_Articles_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Recommendation_Own_Articles_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Recommendation_Own_Articles_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Recommendation_Own_Articles_Sum_Order_By>;
  var_pop?: InputMaybe<Recommendation_Own_Articles_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Recommendation_Own_Articles_Var_Samp_Order_By>;
  variance?: InputMaybe<Recommendation_Own_Articles_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Recommendation_Own_Articles_Append_Input = {
  reasons?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "recommendation.own_articles" */
export type Recommendation_Own_Articles_Arr_Rel_Insert_Input = {
  data: Array<Recommendation_Own_Articles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Recommendation_Own_Articles_On_Conflict>;
};

/** aggregate avg on columns */
export type Recommendation_Own_Articles_Avg_Fields = {
  __typename?: 'recommendation_own_articles_avg_fields';
  fitting?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "recommendation.own_articles" */
export type Recommendation_Own_Articles_Avg_Order_By = {
  fitting?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "recommendation.own_articles". All fields are combined with a logical 'AND'. */
export type Recommendation_Own_Articles_Bool_Exp = {
  _and?: InputMaybe<Array<Recommendation_Own_Articles_Bool_Exp>>;
  _not?: InputMaybe<Recommendation_Own_Articles_Bool_Exp>;
  _or?: InputMaybe<Array<Recommendation_Own_Articles_Bool_Exp>>;
  answer?: InputMaybe<Customer_Answer_Bool_Exp>;
  answer_id?: InputMaybe<String_Comparison_Exp>;
  article_id?: InputMaybe<String_Comparison_Exp>;
  article_informaton_i18n?: InputMaybe<Article_Information_I18n_Bool_Exp>;
  article_informaton_i18n_aggregate?: InputMaybe<Article_Information_I18n_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  fitting?: InputMaybe<Numeric_Comparison_Exp>;
  reasons?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "recommendation.own_articles" */
export enum Recommendation_Own_Articles_Constraint {
  /** unique or primary key constraint on columns "answer_id", "article_id" */
  OwnArticlesPkey = 'own_articles_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Recommendation_Own_Articles_Delete_At_Path_Input = {
  reasons?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Recommendation_Own_Articles_Delete_Elem_Input = {
  reasons?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Recommendation_Own_Articles_Delete_Key_Input = {
  reasons?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "recommendation.own_articles" */
export type Recommendation_Own_Articles_Inc_Input = {
  fitting?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "recommendation.own_articles" */
export type Recommendation_Own_Articles_Insert_Input = {
  answer?: InputMaybe<Customer_Answer_Obj_Rel_Insert_Input>;
  answer_id?: InputMaybe<Scalars['String']>;
  article_id?: InputMaybe<Scalars['String']>;
  article_informaton_i18n?: InputMaybe<Article_Information_I18n_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  fitting?: InputMaybe<Scalars['numeric']>;
  reasons?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type Recommendation_Own_Articles_Max_Fields = {
  __typename?: 'recommendation_own_articles_max_fields';
  answer_id?: Maybe<Scalars['String']>;
  article_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fitting?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "recommendation.own_articles" */
export type Recommendation_Own_Articles_Max_Order_By = {
  answer_id?: InputMaybe<Order_By>;
  article_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  fitting?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Recommendation_Own_Articles_Min_Fields = {
  __typename?: 'recommendation_own_articles_min_fields';
  answer_id?: Maybe<Scalars['String']>;
  article_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fitting?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "recommendation.own_articles" */
export type Recommendation_Own_Articles_Min_Order_By = {
  answer_id?: InputMaybe<Order_By>;
  article_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  fitting?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "recommendation.own_articles" */
export type Recommendation_Own_Articles_Mutation_Response = {
  __typename?: 'recommendation_own_articles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Recommendation_Own_Articles>;
};

/** on_conflict condition type for table "recommendation.own_articles" */
export type Recommendation_Own_Articles_On_Conflict = {
  constraint: Recommendation_Own_Articles_Constraint;
  update_columns?: Array<Recommendation_Own_Articles_Update_Column>;
  where?: InputMaybe<Recommendation_Own_Articles_Bool_Exp>;
};

/** Ordering options when selecting data from "recommendation.own_articles". */
export type Recommendation_Own_Articles_Order_By = {
  answer?: InputMaybe<Customer_Answer_Order_By>;
  answer_id?: InputMaybe<Order_By>;
  article_id?: InputMaybe<Order_By>;
  article_informaton_i18n_aggregate?: InputMaybe<Article_Information_I18n_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  fitting?: InputMaybe<Order_By>;
  reasons?: InputMaybe<Order_By>;
};

/** primary key columns input for table: recommendation.own_articles */
export type Recommendation_Own_Articles_Pk_Columns_Input = {
  answer_id: Scalars['String'];
  article_id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Recommendation_Own_Articles_Prepend_Input = {
  reasons?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "recommendation.own_articles" */
export enum Recommendation_Own_Articles_Select_Column {
  /** column name */
  AnswerId = 'answer_id',
  /** column name */
  ArticleId = 'article_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Fitting = 'fitting',
  /** column name */
  Reasons = 'reasons'
}

/** input type for updating data in table "recommendation.own_articles" */
export type Recommendation_Own_Articles_Set_Input = {
  answer_id?: InputMaybe<Scalars['String']>;
  article_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  fitting?: InputMaybe<Scalars['numeric']>;
  reasons?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate stddev on columns */
export type Recommendation_Own_Articles_Stddev_Fields = {
  __typename?: 'recommendation_own_articles_stddev_fields';
  fitting?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "recommendation.own_articles" */
export type Recommendation_Own_Articles_Stddev_Order_By = {
  fitting?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Recommendation_Own_Articles_Stddev_Pop_Fields = {
  __typename?: 'recommendation_own_articles_stddev_pop_fields';
  fitting?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "recommendation.own_articles" */
export type Recommendation_Own_Articles_Stddev_Pop_Order_By = {
  fitting?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Recommendation_Own_Articles_Stddev_Samp_Fields = {
  __typename?: 'recommendation_own_articles_stddev_samp_fields';
  fitting?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "recommendation.own_articles" */
export type Recommendation_Own_Articles_Stddev_Samp_Order_By = {
  fitting?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "recommendation_own_articles" */
export type Recommendation_Own_Articles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Recommendation_Own_Articles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recommendation_Own_Articles_Stream_Cursor_Value_Input = {
  answer_id?: InputMaybe<Scalars['String']>;
  article_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  fitting?: InputMaybe<Scalars['numeric']>;
  reasons?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate sum on columns */
export type Recommendation_Own_Articles_Sum_Fields = {
  __typename?: 'recommendation_own_articles_sum_fields';
  fitting?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "recommendation.own_articles" */
export type Recommendation_Own_Articles_Sum_Order_By = {
  fitting?: InputMaybe<Order_By>;
};

/** update columns of table "recommendation.own_articles" */
export enum Recommendation_Own_Articles_Update_Column {
  /** column name */
  AnswerId = 'answer_id',
  /** column name */
  ArticleId = 'article_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Fitting = 'fitting',
  /** column name */
  Reasons = 'reasons'
}

export type Recommendation_Own_Articles_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Recommendation_Own_Articles_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Recommendation_Own_Articles_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Recommendation_Own_Articles_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Recommendation_Own_Articles_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Recommendation_Own_Articles_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Recommendation_Own_Articles_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Recommendation_Own_Articles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Recommendation_Own_Articles_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Recommendation_Own_Articles_Var_Pop_Fields = {
  __typename?: 'recommendation_own_articles_var_pop_fields';
  fitting?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "recommendation.own_articles" */
export type Recommendation_Own_Articles_Var_Pop_Order_By = {
  fitting?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Recommendation_Own_Articles_Var_Samp_Fields = {
  __typename?: 'recommendation_own_articles_var_samp_fields';
  fitting?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "recommendation.own_articles" */
export type Recommendation_Own_Articles_Var_Samp_Order_By = {
  fitting?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Recommendation_Own_Articles_Variance_Fields = {
  __typename?: 'recommendation_own_articles_variance_fields';
  fitting?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "recommendation.own_articles" */
export type Recommendation_Own_Articles_Variance_Order_By = {
  fitting?: InputMaybe<Order_By>;
};

/** columns and relationships of "recommendation.return_search_article" */
export type Recommendation_Return_Search_Article = {
  __typename?: 'recommendation_return_search_article';
  article_id: Scalars['String'];
  /** An array relationship */
  article_information_i18n: Array<Article_Information_I18n>;
  /** An aggregate relationship */
  article_information_i18n_aggregate: Article_Information_I18n_Aggregate;
  fitting: Scalars['numeric'];
  reasons: Scalars['jsonb'];
};


/** columns and relationships of "recommendation.return_search_article" */
export type Recommendation_Return_Search_ArticleArticle_Information_I18nArgs = {
  distinct_on?: InputMaybe<Array<Article_Information_I18n_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Article_Information_I18n_Order_By>>;
  where?: InputMaybe<Article_Information_I18n_Bool_Exp>;
};


/** columns and relationships of "recommendation.return_search_article" */
export type Recommendation_Return_Search_ArticleArticle_Information_I18n_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Article_Information_I18n_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Article_Information_I18n_Order_By>>;
  where?: InputMaybe<Article_Information_I18n_Bool_Exp>;
};


/** columns and relationships of "recommendation.return_search_article" */
export type Recommendation_Return_Search_ArticleReasonsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "recommendation.return_search_article" */
export type Recommendation_Return_Search_Article_Aggregate = {
  __typename?: 'recommendation_return_search_article_aggregate';
  aggregate?: Maybe<Recommendation_Return_Search_Article_Aggregate_Fields>;
  nodes: Array<Recommendation_Return_Search_Article>;
};

/** aggregate fields of "recommendation.return_search_article" */
export type Recommendation_Return_Search_Article_Aggregate_Fields = {
  __typename?: 'recommendation_return_search_article_aggregate_fields';
  avg?: Maybe<Recommendation_Return_Search_Article_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Recommendation_Return_Search_Article_Max_Fields>;
  min?: Maybe<Recommendation_Return_Search_Article_Min_Fields>;
  stddev?: Maybe<Recommendation_Return_Search_Article_Stddev_Fields>;
  stddev_pop?: Maybe<Recommendation_Return_Search_Article_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Recommendation_Return_Search_Article_Stddev_Samp_Fields>;
  sum?: Maybe<Recommendation_Return_Search_Article_Sum_Fields>;
  var_pop?: Maybe<Recommendation_Return_Search_Article_Var_Pop_Fields>;
  var_samp?: Maybe<Recommendation_Return_Search_Article_Var_Samp_Fields>;
  variance?: Maybe<Recommendation_Return_Search_Article_Variance_Fields>;
};


/** aggregate fields of "recommendation.return_search_article" */
export type Recommendation_Return_Search_Article_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Recommendation_Return_Search_Article_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Recommendation_Return_Search_Article_Append_Input = {
  reasons?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Recommendation_Return_Search_Article_Avg_Fields = {
  __typename?: 'recommendation_return_search_article_avg_fields';
  fitting?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "recommendation.return_search_article". All fields are combined with a logical 'AND'. */
export type Recommendation_Return_Search_Article_Bool_Exp = {
  _and?: InputMaybe<Array<Recommendation_Return_Search_Article_Bool_Exp>>;
  _not?: InputMaybe<Recommendation_Return_Search_Article_Bool_Exp>;
  _or?: InputMaybe<Array<Recommendation_Return_Search_Article_Bool_Exp>>;
  article_id?: InputMaybe<String_Comparison_Exp>;
  article_information_i18n?: InputMaybe<Article_Information_I18n_Bool_Exp>;
  article_information_i18n_aggregate?: InputMaybe<Article_Information_I18n_Aggregate_Bool_Exp>;
  fitting?: InputMaybe<Numeric_Comparison_Exp>;
  reasons?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "recommendation.return_search_article" */
export enum Recommendation_Return_Search_Article_Constraint {
  /** unique or primary key constraint on columns "article_id" */
  ReturnSearchArticlePkey = 'return_search_article_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Recommendation_Return_Search_Article_Delete_At_Path_Input = {
  reasons?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Recommendation_Return_Search_Article_Delete_Elem_Input = {
  reasons?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Recommendation_Return_Search_Article_Delete_Key_Input = {
  reasons?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "recommendation.return_search_article" */
export type Recommendation_Return_Search_Article_Inc_Input = {
  fitting?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "recommendation.return_search_article" */
export type Recommendation_Return_Search_Article_Insert_Input = {
  article_id?: InputMaybe<Scalars['String']>;
  article_information_i18n?: InputMaybe<Article_Information_I18n_Arr_Rel_Insert_Input>;
  fitting?: InputMaybe<Scalars['numeric']>;
  reasons?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type Recommendation_Return_Search_Article_Max_Fields = {
  __typename?: 'recommendation_return_search_article_max_fields';
  article_id?: Maybe<Scalars['String']>;
  fitting?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Recommendation_Return_Search_Article_Min_Fields = {
  __typename?: 'recommendation_return_search_article_min_fields';
  article_id?: Maybe<Scalars['String']>;
  fitting?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "recommendation.return_search_article" */
export type Recommendation_Return_Search_Article_Mutation_Response = {
  __typename?: 'recommendation_return_search_article_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Recommendation_Return_Search_Article>;
};

/** on_conflict condition type for table "recommendation.return_search_article" */
export type Recommendation_Return_Search_Article_On_Conflict = {
  constraint: Recommendation_Return_Search_Article_Constraint;
  update_columns?: Array<Recommendation_Return_Search_Article_Update_Column>;
  where?: InputMaybe<Recommendation_Return_Search_Article_Bool_Exp>;
};

/** Ordering options when selecting data from "recommendation.return_search_article". */
export type Recommendation_Return_Search_Article_Order_By = {
  article_id?: InputMaybe<Order_By>;
  article_information_i18n_aggregate?: InputMaybe<Article_Information_I18n_Aggregate_Order_By>;
  fitting?: InputMaybe<Order_By>;
  reasons?: InputMaybe<Order_By>;
};

/** primary key columns input for table: recommendation.return_search_article */
export type Recommendation_Return_Search_Article_Pk_Columns_Input = {
  article_id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Recommendation_Return_Search_Article_Prepend_Input = {
  reasons?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "recommendation.return_search_article" */
export enum Recommendation_Return_Search_Article_Select_Column {
  /** column name */
  ArticleId = 'article_id',
  /** column name */
  Fitting = 'fitting',
  /** column name */
  Reasons = 'reasons'
}

/** input type for updating data in table "recommendation.return_search_article" */
export type Recommendation_Return_Search_Article_Set_Input = {
  article_id?: InputMaybe<Scalars['String']>;
  fitting?: InputMaybe<Scalars['numeric']>;
  reasons?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate stddev on columns */
export type Recommendation_Return_Search_Article_Stddev_Fields = {
  __typename?: 'recommendation_return_search_article_stddev_fields';
  fitting?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Recommendation_Return_Search_Article_Stddev_Pop_Fields = {
  __typename?: 'recommendation_return_search_article_stddev_pop_fields';
  fitting?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Recommendation_Return_Search_Article_Stddev_Samp_Fields = {
  __typename?: 'recommendation_return_search_article_stddev_samp_fields';
  fitting?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "recommendation_return_search_article" */
export type Recommendation_Return_Search_Article_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Recommendation_Return_Search_Article_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recommendation_Return_Search_Article_Stream_Cursor_Value_Input = {
  article_id?: InputMaybe<Scalars['String']>;
  fitting?: InputMaybe<Scalars['numeric']>;
  reasons?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate sum on columns */
export type Recommendation_Return_Search_Article_Sum_Fields = {
  __typename?: 'recommendation_return_search_article_sum_fields';
  fitting?: Maybe<Scalars['numeric']>;
};

/** update columns of table "recommendation.return_search_article" */
export enum Recommendation_Return_Search_Article_Update_Column {
  /** column name */
  ArticleId = 'article_id',
  /** column name */
  Fitting = 'fitting',
  /** column name */
  Reasons = 'reasons'
}

export type Recommendation_Return_Search_Article_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Recommendation_Return_Search_Article_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Recommendation_Return_Search_Article_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Recommendation_Return_Search_Article_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Recommendation_Return_Search_Article_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Recommendation_Return_Search_Article_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Recommendation_Return_Search_Article_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Recommendation_Return_Search_Article_Set_Input>;
  /** filter the rows which have to be updated */
  where: Recommendation_Return_Search_Article_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Recommendation_Return_Search_Article_Var_Pop_Fields = {
  __typename?: 'recommendation_return_search_article_var_pop_fields';
  fitting?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Recommendation_Return_Search_Article_Var_Samp_Fields = {
  __typename?: 'recommendation_return_search_article_var_samp_fields';
  fitting?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Recommendation_Return_Search_Article_Variance_Fields = {
  __typename?: 'recommendation_return_search_article_variance_fields';
  fitting?: Maybe<Scalars['Float']>;
};

export type Recommendation_Search_Articles_Args = {
  answers?: InputMaybe<Scalars['jsonb']>;
  excluded_manufacturers?: InputMaybe<Scalars['jsonb']>;
  included_article_types?: InputMaybe<Scalars['jsonb']>;
};

/** Enum Table for storing the possible category of benefits */
export type Rewards_Benefit_Category = {
  __typename?: 'rewards_benefit_category';
  category: Scalars['String'];
};

/** aggregated selection of "rewards.benefit_category" */
export type Rewards_Benefit_Category_Aggregate = {
  __typename?: 'rewards_benefit_category_aggregate';
  aggregate?: Maybe<Rewards_Benefit_Category_Aggregate_Fields>;
  nodes: Array<Rewards_Benefit_Category>;
};

/** aggregate fields of "rewards.benefit_category" */
export type Rewards_Benefit_Category_Aggregate_Fields = {
  __typename?: 'rewards_benefit_category_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Rewards_Benefit_Category_Max_Fields>;
  min?: Maybe<Rewards_Benefit_Category_Min_Fields>;
};


/** aggregate fields of "rewards.benefit_category" */
export type Rewards_Benefit_Category_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rewards_Benefit_Category_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "rewards.benefit_category". All fields are combined with a logical 'AND'. */
export type Rewards_Benefit_Category_Bool_Exp = {
  _and?: InputMaybe<Array<Rewards_Benefit_Category_Bool_Exp>>;
  _not?: InputMaybe<Rewards_Benefit_Category_Bool_Exp>;
  _or?: InputMaybe<Array<Rewards_Benefit_Category_Bool_Exp>>;
  category?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "rewards.benefit_category" */
export enum Rewards_Benefit_Category_Constraint {
  /** unique or primary key constraint on columns "category" */
  BenefitCategoryCategoryKey = 'benefit_category_category_key',
  /** unique or primary key constraint on columns "category" */
  BenefitCategoryPkey = 'benefit_category_pkey'
}

export enum Rewards_Benefit_Category_Enum {
  Clothing = 'clothing',
  Eurobike = 'eurobike',
  Evoc = 'evoc',
  GripsInnerbarends = 'grips_innerbarends',
  Handlebar = 'handlebar',
  Ion = 'ion',
  Other = 'other',
  Pedal = 'pedal',
  Saddle = 'saddle',
  Stem = 'stem'
}

/** Boolean expression to compare columns of type "rewards_benefit_category_enum". All fields are combined with logical 'AND'. */
export type Rewards_Benefit_Category_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Rewards_Benefit_Category_Enum>;
  _in?: InputMaybe<Array<Rewards_Benefit_Category_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Rewards_Benefit_Category_Enum>;
  _nin?: InputMaybe<Array<Rewards_Benefit_Category_Enum>>;
};

/** input type for inserting data into table "rewards.benefit_category" */
export type Rewards_Benefit_Category_Insert_Input = {
  category?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Rewards_Benefit_Category_Max_Fields = {
  __typename?: 'rewards_benefit_category_max_fields';
  category?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Rewards_Benefit_Category_Min_Fields = {
  __typename?: 'rewards_benefit_category_min_fields';
  category?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "rewards.benefit_category" */
export type Rewards_Benefit_Category_Mutation_Response = {
  __typename?: 'rewards_benefit_category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Rewards_Benefit_Category>;
};

/** on_conflict condition type for table "rewards.benefit_category" */
export type Rewards_Benefit_Category_On_Conflict = {
  constraint: Rewards_Benefit_Category_Constraint;
  update_columns?: Array<Rewards_Benefit_Category_Update_Column>;
  where?: InputMaybe<Rewards_Benefit_Category_Bool_Exp>;
};

/** Ordering options when selecting data from "rewards.benefit_category". */
export type Rewards_Benefit_Category_Order_By = {
  category?: InputMaybe<Order_By>;
};

/** primary key columns input for table: rewards.benefit_category */
export type Rewards_Benefit_Category_Pk_Columns_Input = {
  category: Scalars['String'];
};

/** select columns of table "rewards.benefit_category" */
export enum Rewards_Benefit_Category_Select_Column {
  /** column name */
  Category = 'category'
}

/** input type for updating data in table "rewards.benefit_category" */
export type Rewards_Benefit_Category_Set_Input = {
  category?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "rewards_benefit_category" */
export type Rewards_Benefit_Category_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rewards_Benefit_Category_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rewards_Benefit_Category_Stream_Cursor_Value_Input = {
  category?: InputMaybe<Scalars['String']>;
};

/** update columns of table "rewards.benefit_category" */
export enum Rewards_Benefit_Category_Update_Column {
  /** column name */
  Category = 'category'
}

export type Rewards_Benefit_Category_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Rewards_Benefit_Category_Set_Input>;
  /** filter the rows which have to be updated */
  where: Rewards_Benefit_Category_Bool_Exp;
};

/** This table contains all products exchangeable for rewardTokens. */
export type Rewards_Benefits = {
  __typename?: 'rewards_benefits';
  category: Rewards_Benefit_Category_Enum;
  comment?: Maybe<Scalars['String']>;
  cost: Scalars['Int'];
  description: Scalars['String'];
  id: Scalars['Int'];
  image_url?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** Possible variants of the benefit (commonly sizes or colors) */
  variants: Scalars['jsonb'];
};


/** This table contains all products exchangeable for rewardTokens. */
export type Rewards_BenefitsVariantsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "rewards.benefits" */
export type Rewards_Benefits_Aggregate = {
  __typename?: 'rewards_benefits_aggregate';
  aggregate?: Maybe<Rewards_Benefits_Aggregate_Fields>;
  nodes: Array<Rewards_Benefits>;
};

/** aggregate fields of "rewards.benefits" */
export type Rewards_Benefits_Aggregate_Fields = {
  __typename?: 'rewards_benefits_aggregate_fields';
  avg?: Maybe<Rewards_Benefits_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Rewards_Benefits_Max_Fields>;
  min?: Maybe<Rewards_Benefits_Min_Fields>;
  stddev?: Maybe<Rewards_Benefits_Stddev_Fields>;
  stddev_pop?: Maybe<Rewards_Benefits_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rewards_Benefits_Stddev_Samp_Fields>;
  sum?: Maybe<Rewards_Benefits_Sum_Fields>;
  var_pop?: Maybe<Rewards_Benefits_Var_Pop_Fields>;
  var_samp?: Maybe<Rewards_Benefits_Var_Samp_Fields>;
  variance?: Maybe<Rewards_Benefits_Variance_Fields>;
};


/** aggregate fields of "rewards.benefits" */
export type Rewards_Benefits_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rewards_Benefits_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Rewards_Benefits_Append_Input = {
  /** Possible variants of the benefit (commonly sizes or colors) */
  variants?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Rewards_Benefits_Avg_Fields = {
  __typename?: 'rewards_benefits_avg_fields';
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "rewards.benefits". All fields are combined with a logical 'AND'. */
export type Rewards_Benefits_Bool_Exp = {
  _and?: InputMaybe<Array<Rewards_Benefits_Bool_Exp>>;
  _not?: InputMaybe<Rewards_Benefits_Bool_Exp>;
  _or?: InputMaybe<Array<Rewards_Benefits_Bool_Exp>>;
  category?: InputMaybe<Rewards_Benefit_Category_Enum_Comparison_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  cost?: InputMaybe<Int_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  image_url?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  variants?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "rewards.benefits" */
export enum Rewards_Benefits_Constraint {
  /** unique or primary key constraint on columns "id" */
  BenefitsPkey = 'benefits_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Rewards_Benefits_Delete_At_Path_Input = {
  /** Possible variants of the benefit (commonly sizes or colors) */
  variants?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Rewards_Benefits_Delete_Elem_Input = {
  /** Possible variants of the benefit (commonly sizes or colors) */
  variants?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Rewards_Benefits_Delete_Key_Input = {
  /** Possible variants of the benefit (commonly sizes or colors) */
  variants?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "rewards.benefits" */
export type Rewards_Benefits_Inc_Input = {
  cost?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "rewards.benefits" */
export type Rewards_Benefits_Insert_Input = {
  category?: InputMaybe<Rewards_Benefit_Category_Enum>;
  comment?: InputMaybe<Scalars['String']>;
  cost?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  image_url?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  /** Possible variants of the benefit (commonly sizes or colors) */
  variants?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type Rewards_Benefits_Max_Fields = {
  __typename?: 'rewards_benefits_max_fields';
  comment?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image_url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Rewards_Benefits_Min_Fields = {
  __typename?: 'rewards_benefits_min_fields';
  comment?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image_url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "rewards.benefits" */
export type Rewards_Benefits_Mutation_Response = {
  __typename?: 'rewards_benefits_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Rewards_Benefits>;
};

/** on_conflict condition type for table "rewards.benefits" */
export type Rewards_Benefits_On_Conflict = {
  constraint: Rewards_Benefits_Constraint;
  update_columns?: Array<Rewards_Benefits_Update_Column>;
  where?: InputMaybe<Rewards_Benefits_Bool_Exp>;
};

/** Ordering options when selecting data from "rewards.benefits". */
export type Rewards_Benefits_Order_By = {
  category?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_url?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  variants?: InputMaybe<Order_By>;
};

/** primary key columns input for table: rewards.benefits */
export type Rewards_Benefits_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Rewards_Benefits_Prepend_Input = {
  /** Possible variants of the benefit (commonly sizes or colors) */
  variants?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "rewards.benefits" */
export enum Rewards_Benefits_Select_Column {
  /** column name */
  Category = 'category',
  /** column name */
  Comment = 'comment',
  /** column name */
  Cost = 'cost',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  Name = 'name',
  /** column name */
  Variants = 'variants'
}

/** input type for updating data in table "rewards.benefits" */
export type Rewards_Benefits_Set_Input = {
  category?: InputMaybe<Rewards_Benefit_Category_Enum>;
  comment?: InputMaybe<Scalars['String']>;
  cost?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  image_url?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  /** Possible variants of the benefit (commonly sizes or colors) */
  variants?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate stddev on columns */
export type Rewards_Benefits_Stddev_Fields = {
  __typename?: 'rewards_benefits_stddev_fields';
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Rewards_Benefits_Stddev_Pop_Fields = {
  __typename?: 'rewards_benefits_stddev_pop_fields';
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Rewards_Benefits_Stddev_Samp_Fields = {
  __typename?: 'rewards_benefits_stddev_samp_fields';
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "rewards_benefits" */
export type Rewards_Benefits_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rewards_Benefits_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rewards_Benefits_Stream_Cursor_Value_Input = {
  category?: InputMaybe<Rewards_Benefit_Category_Enum>;
  comment?: InputMaybe<Scalars['String']>;
  cost?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  image_url?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  /** Possible variants of the benefit (commonly sizes or colors) */
  variants?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate sum on columns */
export type Rewards_Benefits_Sum_Fields = {
  __typename?: 'rewards_benefits_sum_fields';
  cost?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "rewards.benefits" */
export enum Rewards_Benefits_Update_Column {
  /** column name */
  Category = 'category',
  /** column name */
  Comment = 'comment',
  /** column name */
  Cost = 'cost',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  Name = 'name',
  /** column name */
  Variants = 'variants'
}

export type Rewards_Benefits_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Rewards_Benefits_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Rewards_Benefits_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Rewards_Benefits_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Rewards_Benefits_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Rewards_Benefits_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Rewards_Benefits_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Rewards_Benefits_Set_Input>;
  /** filter the rows which have to be updated */
  where: Rewards_Benefits_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Rewards_Benefits_Var_Pop_Fields = {
  __typename?: 'rewards_benefits_var_pop_fields';
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Rewards_Benefits_Var_Samp_Fields = {
  __typename?: 'rewards_benefits_var_samp_fields';
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Rewards_Benefits_Variance_Fields = {
  __typename?: 'rewards_benefits_variance_fields';
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** This table contains all past, current and future boosts of users. */
export type Rewards_Boosts = {
  __typename?: 'rewards_boosts';
  /** List of articles groups (see public.article_groups) that this boost is valid for. `NULL` means valid for every article group. */
  article_groups?: Maybe<Scalars['jsonb']>;
  comment?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  expiration_date: Scalars['date'];
  factor: Scalars['Int'];
  id: Scalars['Int'];
  starting_date: Scalars['date'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<User>;
  /** `NULL` means that the boost should be applied to every user that belongs to `vendor_id` */
  user_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  vendor?: Maybe<Organisation>;
  /** `NULL` means that the boost should be applied for every vendor */
  vendor_id?: Maybe<Scalars['String']>;
};


/** This table contains all past, current and future boosts of users. */
export type Rewards_BoostsArticle_GroupsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "rewards.boosts" */
export type Rewards_Boosts_Aggregate = {
  __typename?: 'rewards_boosts_aggregate';
  aggregate?: Maybe<Rewards_Boosts_Aggregate_Fields>;
  nodes: Array<Rewards_Boosts>;
};

/** aggregate fields of "rewards.boosts" */
export type Rewards_Boosts_Aggregate_Fields = {
  __typename?: 'rewards_boosts_aggregate_fields';
  avg?: Maybe<Rewards_Boosts_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Rewards_Boosts_Max_Fields>;
  min?: Maybe<Rewards_Boosts_Min_Fields>;
  stddev?: Maybe<Rewards_Boosts_Stddev_Fields>;
  stddev_pop?: Maybe<Rewards_Boosts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rewards_Boosts_Stddev_Samp_Fields>;
  sum?: Maybe<Rewards_Boosts_Sum_Fields>;
  var_pop?: Maybe<Rewards_Boosts_Var_Pop_Fields>;
  var_samp?: Maybe<Rewards_Boosts_Var_Samp_Fields>;
  variance?: Maybe<Rewards_Boosts_Variance_Fields>;
};


/** aggregate fields of "rewards.boosts" */
export type Rewards_Boosts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rewards_Boosts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Rewards_Boosts_Append_Input = {
  /** List of articles groups (see public.article_groups) that this boost is valid for. `NULL` means valid for every article group. */
  article_groups?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Rewards_Boosts_Avg_Fields = {
  __typename?: 'rewards_boosts_avg_fields';
  factor?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "rewards.boosts". All fields are combined with a logical 'AND'. */
export type Rewards_Boosts_Bool_Exp = {
  _and?: InputMaybe<Array<Rewards_Boosts_Bool_Exp>>;
  _not?: InputMaybe<Rewards_Boosts_Bool_Exp>;
  _or?: InputMaybe<Array<Rewards_Boosts_Bool_Exp>>;
  article_groups?: InputMaybe<Jsonb_Comparison_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  expiration_date?: InputMaybe<Date_Comparison_Exp>;
  factor?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  starting_date?: InputMaybe<Date_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  vendor?: InputMaybe<Organisation_Bool_Exp>;
  vendor_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "rewards.boosts" */
export enum Rewards_Boosts_Constraint {
  /** unique or primary key constraint on columns "id" */
  BoostsPkey = 'boosts_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Rewards_Boosts_Delete_At_Path_Input = {
  /** List of articles groups (see public.article_groups) that this boost is valid for. `NULL` means valid for every article group. */
  article_groups?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Rewards_Boosts_Delete_Elem_Input = {
  /** List of articles groups (see public.article_groups) that this boost is valid for. `NULL` means valid for every article group. */
  article_groups?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Rewards_Boosts_Delete_Key_Input = {
  /** List of articles groups (see public.article_groups) that this boost is valid for. `NULL` means valid for every article group. */
  article_groups?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "rewards.boosts" */
export type Rewards_Boosts_Inc_Input = {
  factor?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "rewards.boosts" */
export type Rewards_Boosts_Insert_Input = {
  /** List of articles groups (see public.article_groups) that this boost is valid for. `NULL` means valid for every article group. */
  article_groups?: InputMaybe<Scalars['jsonb']>;
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  expiration_date?: InputMaybe<Scalars['date']>;
  factor?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  starting_date?: InputMaybe<Scalars['date']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  /** `NULL` means that the boost should be applied to every user that belongs to `vendor_id` */
  user_id?: InputMaybe<Scalars['uuid']>;
  vendor?: InputMaybe<Organisation_Obj_Rel_Insert_Input>;
  /** `NULL` means that the boost should be applied for every vendor */
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Rewards_Boosts_Max_Fields = {
  __typename?: 'rewards_boosts_max_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expiration_date?: Maybe<Scalars['date']>;
  factor?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  starting_date?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** `NULL` means that the boost should be applied to every user that belongs to `vendor_id` */
  user_id?: Maybe<Scalars['uuid']>;
  /** `NULL` means that the boost should be applied for every vendor */
  vendor_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Rewards_Boosts_Min_Fields = {
  __typename?: 'rewards_boosts_min_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expiration_date?: Maybe<Scalars['date']>;
  factor?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  starting_date?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** `NULL` means that the boost should be applied to every user that belongs to `vendor_id` */
  user_id?: Maybe<Scalars['uuid']>;
  /** `NULL` means that the boost should be applied for every vendor */
  vendor_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "rewards.boosts" */
export type Rewards_Boosts_Mutation_Response = {
  __typename?: 'rewards_boosts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Rewards_Boosts>;
};

/** on_conflict condition type for table "rewards.boosts" */
export type Rewards_Boosts_On_Conflict = {
  constraint: Rewards_Boosts_Constraint;
  update_columns?: Array<Rewards_Boosts_Update_Column>;
  where?: InputMaybe<Rewards_Boosts_Bool_Exp>;
};

/** Ordering options when selecting data from "rewards.boosts". */
export type Rewards_Boosts_Order_By = {
  article_groups?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  factor?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  starting_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
  vendor?: InputMaybe<Organisation_Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: rewards.boosts */
export type Rewards_Boosts_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Rewards_Boosts_Prepend_Input = {
  /** List of articles groups (see public.article_groups) that this boost is valid for. `NULL` means valid for every article group. */
  article_groups?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "rewards.boosts" */
export enum Rewards_Boosts_Select_Column {
  /** column name */
  ArticleGroups = 'article_groups',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpirationDate = 'expiration_date',
  /** column name */
  Factor = 'factor',
  /** column name */
  Id = 'id',
  /** column name */
  StartingDate = 'starting_date',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VendorId = 'vendor_id'
}

/** input type for updating data in table "rewards.boosts" */
export type Rewards_Boosts_Set_Input = {
  /** List of articles groups (see public.article_groups) that this boost is valid for. `NULL` means valid for every article group. */
  article_groups?: InputMaybe<Scalars['jsonb']>;
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  expiration_date?: InputMaybe<Scalars['date']>;
  factor?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  starting_date?: InputMaybe<Scalars['date']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** `NULL` means that the boost should be applied to every user that belongs to `vendor_id` */
  user_id?: InputMaybe<Scalars['uuid']>;
  /** `NULL` means that the boost should be applied for every vendor */
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Rewards_Boosts_Stddev_Fields = {
  __typename?: 'rewards_boosts_stddev_fields';
  factor?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Rewards_Boosts_Stddev_Pop_Fields = {
  __typename?: 'rewards_boosts_stddev_pop_fields';
  factor?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Rewards_Boosts_Stddev_Samp_Fields = {
  __typename?: 'rewards_boosts_stddev_samp_fields';
  factor?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "rewards_boosts" */
export type Rewards_Boosts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rewards_Boosts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rewards_Boosts_Stream_Cursor_Value_Input = {
  /** List of articles groups (see public.article_groups) that this boost is valid for. `NULL` means valid for every article group. */
  article_groups?: InputMaybe<Scalars['jsonb']>;
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  expiration_date?: InputMaybe<Scalars['date']>;
  factor?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  starting_date?: InputMaybe<Scalars['date']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** `NULL` means that the boost should be applied to every user that belongs to `vendor_id` */
  user_id?: InputMaybe<Scalars['uuid']>;
  /** `NULL` means that the boost should be applied for every vendor */
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Rewards_Boosts_Sum_Fields = {
  __typename?: 'rewards_boosts_sum_fields';
  factor?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "rewards.boosts" */
export enum Rewards_Boosts_Update_Column {
  /** column name */
  ArticleGroups = 'article_groups',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpirationDate = 'expiration_date',
  /** column name */
  Factor = 'factor',
  /** column name */
  Id = 'id',
  /** column name */
  StartingDate = 'starting_date',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VendorId = 'vendor_id'
}

export type Rewards_Boosts_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Rewards_Boosts_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Rewards_Boosts_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Rewards_Boosts_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Rewards_Boosts_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Rewards_Boosts_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Rewards_Boosts_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Rewards_Boosts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Rewards_Boosts_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Rewards_Boosts_Var_Pop_Fields = {
  __typename?: 'rewards_boosts_var_pop_fields';
  factor?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Rewards_Boosts_Var_Samp_Fields = {
  __typename?: 'rewards_boosts_var_samp_fields';
  factor?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Rewards_Boosts_Variance_Fields = {
  __typename?: 'rewards_boosts_variance_fields';
  factor?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "rewards.internal_ranking" */
export type Rewards_Internal_Ranking = {
  __typename?: 'rewards_internal_ranking';
  month?: Maybe<Scalars['float8']>;
  questionnaire_count?: Maybe<Scalars['bigint']>;
  ranking?: Maybe<Scalars['bigint']>;
  token_balance?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['uuid']>;
  user_name?: Maybe<Scalars['String']>;
  vendor_id?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['float8']>;
};

/** aggregated selection of "rewards.internal_ranking" */
export type Rewards_Internal_Ranking_Aggregate = {
  __typename?: 'rewards_internal_ranking_aggregate';
  aggregate?: Maybe<Rewards_Internal_Ranking_Aggregate_Fields>;
  nodes: Array<Rewards_Internal_Ranking>;
};

/** aggregate fields of "rewards.internal_ranking" */
export type Rewards_Internal_Ranking_Aggregate_Fields = {
  __typename?: 'rewards_internal_ranking_aggregate_fields';
  avg?: Maybe<Rewards_Internal_Ranking_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Rewards_Internal_Ranking_Max_Fields>;
  min?: Maybe<Rewards_Internal_Ranking_Min_Fields>;
  stddev?: Maybe<Rewards_Internal_Ranking_Stddev_Fields>;
  stddev_pop?: Maybe<Rewards_Internal_Ranking_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rewards_Internal_Ranking_Stddev_Samp_Fields>;
  sum?: Maybe<Rewards_Internal_Ranking_Sum_Fields>;
  var_pop?: Maybe<Rewards_Internal_Ranking_Var_Pop_Fields>;
  var_samp?: Maybe<Rewards_Internal_Ranking_Var_Samp_Fields>;
  variance?: Maybe<Rewards_Internal_Ranking_Variance_Fields>;
};


/** aggregate fields of "rewards.internal_ranking" */
export type Rewards_Internal_Ranking_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rewards_Internal_Ranking_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Rewards_Internal_Ranking_Avg_Fields = {
  __typename?: 'rewards_internal_ranking_avg_fields';
  month?: Maybe<Scalars['Float']>;
  questionnaire_count?: Maybe<Scalars['Float']>;
  ranking?: Maybe<Scalars['Float']>;
  token_balance?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "rewards.internal_ranking". All fields are combined with a logical 'AND'. */
export type Rewards_Internal_Ranking_Bool_Exp = {
  _and?: InputMaybe<Array<Rewards_Internal_Ranking_Bool_Exp>>;
  _not?: InputMaybe<Rewards_Internal_Ranking_Bool_Exp>;
  _or?: InputMaybe<Array<Rewards_Internal_Ranking_Bool_Exp>>;
  month?: InputMaybe<Float8_Comparison_Exp>;
  questionnaire_count?: InputMaybe<Bigint_Comparison_Exp>;
  ranking?: InputMaybe<Bigint_Comparison_Exp>;
  token_balance?: InputMaybe<Bigint_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_name?: InputMaybe<String_Comparison_Exp>;
  vendor_id?: InputMaybe<String_Comparison_Exp>;
  year?: InputMaybe<Float8_Comparison_Exp>;
};

/** aggregate max on columns */
export type Rewards_Internal_Ranking_Max_Fields = {
  __typename?: 'rewards_internal_ranking_max_fields';
  month?: Maybe<Scalars['float8']>;
  questionnaire_count?: Maybe<Scalars['bigint']>;
  ranking?: Maybe<Scalars['bigint']>;
  token_balance?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_name?: Maybe<Scalars['String']>;
  vendor_id?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['float8']>;
};

/** aggregate min on columns */
export type Rewards_Internal_Ranking_Min_Fields = {
  __typename?: 'rewards_internal_ranking_min_fields';
  month?: Maybe<Scalars['float8']>;
  questionnaire_count?: Maybe<Scalars['bigint']>;
  ranking?: Maybe<Scalars['bigint']>;
  token_balance?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_name?: Maybe<Scalars['String']>;
  vendor_id?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['float8']>;
};

/** Ordering options when selecting data from "rewards.internal_ranking". */
export type Rewards_Internal_Ranking_Order_By = {
  month?: InputMaybe<Order_By>;
  questionnaire_count?: InputMaybe<Order_By>;
  ranking?: InputMaybe<Order_By>;
  token_balance?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_name?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** select columns of table "rewards.internal_ranking" */
export enum Rewards_Internal_Ranking_Select_Column {
  /** column name */
  Month = 'month',
  /** column name */
  QuestionnaireCount = 'questionnaire_count',
  /** column name */
  Ranking = 'ranking',
  /** column name */
  TokenBalance = 'token_balance',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserName = 'user_name',
  /** column name */
  VendorId = 'vendor_id',
  /** column name */
  Year = 'year'
}

/** aggregate stddev on columns */
export type Rewards_Internal_Ranking_Stddev_Fields = {
  __typename?: 'rewards_internal_ranking_stddev_fields';
  month?: Maybe<Scalars['Float']>;
  questionnaire_count?: Maybe<Scalars['Float']>;
  ranking?: Maybe<Scalars['Float']>;
  token_balance?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Rewards_Internal_Ranking_Stddev_Pop_Fields = {
  __typename?: 'rewards_internal_ranking_stddev_pop_fields';
  month?: Maybe<Scalars['Float']>;
  questionnaire_count?: Maybe<Scalars['Float']>;
  ranking?: Maybe<Scalars['Float']>;
  token_balance?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Rewards_Internal_Ranking_Stddev_Samp_Fields = {
  __typename?: 'rewards_internal_ranking_stddev_samp_fields';
  month?: Maybe<Scalars['Float']>;
  questionnaire_count?: Maybe<Scalars['Float']>;
  ranking?: Maybe<Scalars['Float']>;
  token_balance?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "rewards_internal_ranking" */
export type Rewards_Internal_Ranking_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rewards_Internal_Ranking_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rewards_Internal_Ranking_Stream_Cursor_Value_Input = {
  month?: InputMaybe<Scalars['float8']>;
  questionnaire_count?: InputMaybe<Scalars['bigint']>;
  ranking?: InputMaybe<Scalars['bigint']>;
  token_balance?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_name?: InputMaybe<Scalars['String']>;
  vendor_id?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['float8']>;
};

/** aggregate sum on columns */
export type Rewards_Internal_Ranking_Sum_Fields = {
  __typename?: 'rewards_internal_ranking_sum_fields';
  month?: Maybe<Scalars['float8']>;
  questionnaire_count?: Maybe<Scalars['bigint']>;
  ranking?: Maybe<Scalars['bigint']>;
  token_balance?: Maybe<Scalars['bigint']>;
  year?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type Rewards_Internal_Ranking_Var_Pop_Fields = {
  __typename?: 'rewards_internal_ranking_var_pop_fields';
  month?: Maybe<Scalars['Float']>;
  questionnaire_count?: Maybe<Scalars['Float']>;
  ranking?: Maybe<Scalars['Float']>;
  token_balance?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Rewards_Internal_Ranking_Var_Samp_Fields = {
  __typename?: 'rewards_internal_ranking_var_samp_fields';
  month?: Maybe<Scalars['Float']>;
  questionnaire_count?: Maybe<Scalars['Float']>;
  ranking?: Maybe<Scalars['Float']>;
  token_balance?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Rewards_Internal_Ranking_Variance_Fields = {
  __typename?: 'rewards_internal_ranking_variance_fields';
  month?: Maybe<Scalars['Float']>;
  questionnaire_count?: Maybe<Scalars['Float']>;
  ranking?: Maybe<Scalars['Float']>;
  token_balance?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "rewards.international_ranking" */
export type Rewards_International_Ranking = {
  __typename?: 'rewards_international_ranking';
  month?: Maybe<Scalars['float8']>;
  questionnaire_count?: Maybe<Scalars['bigint']>;
  ranking?: Maybe<Scalars['bigint']>;
  token_balance?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
  vendor_id?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['float8']>;
};

/** aggregated selection of "rewards.international_ranking" */
export type Rewards_International_Ranking_Aggregate = {
  __typename?: 'rewards_international_ranking_aggregate';
  aggregate?: Maybe<Rewards_International_Ranking_Aggregate_Fields>;
  nodes: Array<Rewards_International_Ranking>;
};

/** aggregate fields of "rewards.international_ranking" */
export type Rewards_International_Ranking_Aggregate_Fields = {
  __typename?: 'rewards_international_ranking_aggregate_fields';
  avg?: Maybe<Rewards_International_Ranking_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Rewards_International_Ranking_Max_Fields>;
  min?: Maybe<Rewards_International_Ranking_Min_Fields>;
  stddev?: Maybe<Rewards_International_Ranking_Stddev_Fields>;
  stddev_pop?: Maybe<Rewards_International_Ranking_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rewards_International_Ranking_Stddev_Samp_Fields>;
  sum?: Maybe<Rewards_International_Ranking_Sum_Fields>;
  var_pop?: Maybe<Rewards_International_Ranking_Var_Pop_Fields>;
  var_samp?: Maybe<Rewards_International_Ranking_Var_Samp_Fields>;
  variance?: Maybe<Rewards_International_Ranking_Variance_Fields>;
};


/** aggregate fields of "rewards.international_ranking" */
export type Rewards_International_Ranking_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rewards_International_Ranking_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Rewards_International_Ranking_Avg_Fields = {
  __typename?: 'rewards_international_ranking_avg_fields';
  month?: Maybe<Scalars['Float']>;
  questionnaire_count?: Maybe<Scalars['Float']>;
  ranking?: Maybe<Scalars['Float']>;
  token_balance?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "rewards.international_ranking". All fields are combined with a logical 'AND'. */
export type Rewards_International_Ranking_Bool_Exp = {
  _and?: InputMaybe<Array<Rewards_International_Ranking_Bool_Exp>>;
  _not?: InputMaybe<Rewards_International_Ranking_Bool_Exp>;
  _or?: InputMaybe<Array<Rewards_International_Ranking_Bool_Exp>>;
  month?: InputMaybe<Float8_Comparison_Exp>;
  questionnaire_count?: InputMaybe<Bigint_Comparison_Exp>;
  ranking?: InputMaybe<Bigint_Comparison_Exp>;
  token_balance?: InputMaybe<Bigint_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  vendor_id?: InputMaybe<String_Comparison_Exp>;
  year?: InputMaybe<Float8_Comparison_Exp>;
};

/** aggregate max on columns */
export type Rewards_International_Ranking_Max_Fields = {
  __typename?: 'rewards_international_ranking_max_fields';
  month?: Maybe<Scalars['float8']>;
  questionnaire_count?: Maybe<Scalars['bigint']>;
  ranking?: Maybe<Scalars['bigint']>;
  token_balance?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
  vendor_id?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['float8']>;
};

/** aggregate min on columns */
export type Rewards_International_Ranking_Min_Fields = {
  __typename?: 'rewards_international_ranking_min_fields';
  month?: Maybe<Scalars['float8']>;
  questionnaire_count?: Maybe<Scalars['bigint']>;
  ranking?: Maybe<Scalars['bigint']>;
  token_balance?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
  vendor_id?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['float8']>;
};

/** Ordering options when selecting data from "rewards.international_ranking". */
export type Rewards_International_Ranking_Order_By = {
  month?: InputMaybe<Order_By>;
  questionnaire_count?: InputMaybe<Order_By>;
  ranking?: InputMaybe<Order_By>;
  token_balance?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** select columns of table "rewards.international_ranking" */
export enum Rewards_International_Ranking_Select_Column {
  /** column name */
  Month = 'month',
  /** column name */
  QuestionnaireCount = 'questionnaire_count',
  /** column name */
  Ranking = 'ranking',
  /** column name */
  TokenBalance = 'token_balance',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VendorId = 'vendor_id',
  /** column name */
  Year = 'year'
}

/** aggregate stddev on columns */
export type Rewards_International_Ranking_Stddev_Fields = {
  __typename?: 'rewards_international_ranking_stddev_fields';
  month?: Maybe<Scalars['Float']>;
  questionnaire_count?: Maybe<Scalars['Float']>;
  ranking?: Maybe<Scalars['Float']>;
  token_balance?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Rewards_International_Ranking_Stddev_Pop_Fields = {
  __typename?: 'rewards_international_ranking_stddev_pop_fields';
  month?: Maybe<Scalars['Float']>;
  questionnaire_count?: Maybe<Scalars['Float']>;
  ranking?: Maybe<Scalars['Float']>;
  token_balance?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Rewards_International_Ranking_Stddev_Samp_Fields = {
  __typename?: 'rewards_international_ranking_stddev_samp_fields';
  month?: Maybe<Scalars['Float']>;
  questionnaire_count?: Maybe<Scalars['Float']>;
  ranking?: Maybe<Scalars['Float']>;
  token_balance?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "rewards_international_ranking" */
export type Rewards_International_Ranking_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rewards_International_Ranking_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rewards_International_Ranking_Stream_Cursor_Value_Input = {
  month?: InputMaybe<Scalars['float8']>;
  questionnaire_count?: InputMaybe<Scalars['bigint']>;
  ranking?: InputMaybe<Scalars['bigint']>;
  token_balance?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  vendor_id?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['float8']>;
};

/** aggregate sum on columns */
export type Rewards_International_Ranking_Sum_Fields = {
  __typename?: 'rewards_international_ranking_sum_fields';
  month?: Maybe<Scalars['float8']>;
  questionnaire_count?: Maybe<Scalars['bigint']>;
  ranking?: Maybe<Scalars['bigint']>;
  token_balance?: Maybe<Scalars['bigint']>;
  year?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type Rewards_International_Ranking_Var_Pop_Fields = {
  __typename?: 'rewards_international_ranking_var_pop_fields';
  month?: Maybe<Scalars['Float']>;
  questionnaire_count?: Maybe<Scalars['Float']>;
  ranking?: Maybe<Scalars['Float']>;
  token_balance?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Rewards_International_Ranking_Var_Samp_Fields = {
  __typename?: 'rewards_international_ranking_var_samp_fields';
  month?: Maybe<Scalars['Float']>;
  questionnaire_count?: Maybe<Scalars['Float']>;
  ranking?: Maybe<Scalars['Float']>;
  token_balance?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Rewards_International_Ranking_Variance_Fields = {
  __typename?: 'rewards_international_ranking_variance_fields';
  month?: Maybe<Scalars['Float']>;
  questionnaire_count?: Maybe<Scalars['Float']>;
  ranking?: Maybe<Scalars['Float']>;
  token_balance?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "rewards.ranking_balances" */
export type Rewards_Ranking_Balances = {
  __typename?: 'rewards_ranking_balances';
  month?: Maybe<Scalars['float8']>;
  token_balance?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
  vendor_id?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['float8']>;
};

/** aggregated selection of "rewards.ranking_balances" */
export type Rewards_Ranking_Balances_Aggregate = {
  __typename?: 'rewards_ranking_balances_aggregate';
  aggregate?: Maybe<Rewards_Ranking_Balances_Aggregate_Fields>;
  nodes: Array<Rewards_Ranking_Balances>;
};

/** aggregate fields of "rewards.ranking_balances" */
export type Rewards_Ranking_Balances_Aggregate_Fields = {
  __typename?: 'rewards_ranking_balances_aggregate_fields';
  avg?: Maybe<Rewards_Ranking_Balances_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Rewards_Ranking_Balances_Max_Fields>;
  min?: Maybe<Rewards_Ranking_Balances_Min_Fields>;
  stddev?: Maybe<Rewards_Ranking_Balances_Stddev_Fields>;
  stddev_pop?: Maybe<Rewards_Ranking_Balances_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rewards_Ranking_Balances_Stddev_Samp_Fields>;
  sum?: Maybe<Rewards_Ranking_Balances_Sum_Fields>;
  var_pop?: Maybe<Rewards_Ranking_Balances_Var_Pop_Fields>;
  var_samp?: Maybe<Rewards_Ranking_Balances_Var_Samp_Fields>;
  variance?: Maybe<Rewards_Ranking_Balances_Variance_Fields>;
};


/** aggregate fields of "rewards.ranking_balances" */
export type Rewards_Ranking_Balances_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rewards_Ranking_Balances_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Rewards_Ranking_Balances_Avg_Fields = {
  __typename?: 'rewards_ranking_balances_avg_fields';
  month?: Maybe<Scalars['Float']>;
  token_balance?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "rewards.ranking_balances". All fields are combined with a logical 'AND'. */
export type Rewards_Ranking_Balances_Bool_Exp = {
  _and?: InputMaybe<Array<Rewards_Ranking_Balances_Bool_Exp>>;
  _not?: InputMaybe<Rewards_Ranking_Balances_Bool_Exp>;
  _or?: InputMaybe<Array<Rewards_Ranking_Balances_Bool_Exp>>;
  month?: InputMaybe<Float8_Comparison_Exp>;
  token_balance?: InputMaybe<Bigint_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  vendor_id?: InputMaybe<String_Comparison_Exp>;
  year?: InputMaybe<Float8_Comparison_Exp>;
};

/** aggregate max on columns */
export type Rewards_Ranking_Balances_Max_Fields = {
  __typename?: 'rewards_ranking_balances_max_fields';
  month?: Maybe<Scalars['float8']>;
  token_balance?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
  vendor_id?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['float8']>;
};

/** aggregate min on columns */
export type Rewards_Ranking_Balances_Min_Fields = {
  __typename?: 'rewards_ranking_balances_min_fields';
  month?: Maybe<Scalars['float8']>;
  token_balance?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
  vendor_id?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['float8']>;
};

/** Ordering options when selecting data from "rewards.ranking_balances". */
export type Rewards_Ranking_Balances_Order_By = {
  month?: InputMaybe<Order_By>;
  token_balance?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** select columns of table "rewards.ranking_balances" */
export enum Rewards_Ranking_Balances_Select_Column {
  /** column name */
  Month = 'month',
  /** column name */
  TokenBalance = 'token_balance',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VendorId = 'vendor_id',
  /** column name */
  Year = 'year'
}

/** aggregate stddev on columns */
export type Rewards_Ranking_Balances_Stddev_Fields = {
  __typename?: 'rewards_ranking_balances_stddev_fields';
  month?: Maybe<Scalars['Float']>;
  token_balance?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Rewards_Ranking_Balances_Stddev_Pop_Fields = {
  __typename?: 'rewards_ranking_balances_stddev_pop_fields';
  month?: Maybe<Scalars['Float']>;
  token_balance?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Rewards_Ranking_Balances_Stddev_Samp_Fields = {
  __typename?: 'rewards_ranking_balances_stddev_samp_fields';
  month?: Maybe<Scalars['Float']>;
  token_balance?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "rewards_ranking_balances" */
export type Rewards_Ranking_Balances_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rewards_Ranking_Balances_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rewards_Ranking_Balances_Stream_Cursor_Value_Input = {
  month?: InputMaybe<Scalars['float8']>;
  token_balance?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  vendor_id?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['float8']>;
};

/** aggregate sum on columns */
export type Rewards_Ranking_Balances_Sum_Fields = {
  __typename?: 'rewards_ranking_balances_sum_fields';
  month?: Maybe<Scalars['float8']>;
  token_balance?: Maybe<Scalars['bigint']>;
  year?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type Rewards_Ranking_Balances_Var_Pop_Fields = {
  __typename?: 'rewards_ranking_balances_var_pop_fields';
  month?: Maybe<Scalars['Float']>;
  token_balance?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Rewards_Ranking_Balances_Var_Samp_Fields = {
  __typename?: 'rewards_ranking_balances_var_samp_fields';
  month?: Maybe<Scalars['Float']>;
  token_balance?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Rewards_Ranking_Balances_Variance_Fields = {
  __typename?: 'rewards_ranking_balances_variance_fields';
  month?: Maybe<Scalars['Float']>;
  token_balance?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "rewards.reward_balances" */
export type Rewards_Reward_Balances = {
  __typename?: 'rewards_reward_balances';
  token_balance?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "rewards.reward_balances" */
export type Rewards_Reward_Balances_Aggregate = {
  __typename?: 'rewards_reward_balances_aggregate';
  aggregate?: Maybe<Rewards_Reward_Balances_Aggregate_Fields>;
  nodes: Array<Rewards_Reward_Balances>;
};

/** aggregate fields of "rewards.reward_balances" */
export type Rewards_Reward_Balances_Aggregate_Fields = {
  __typename?: 'rewards_reward_balances_aggregate_fields';
  avg?: Maybe<Rewards_Reward_Balances_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Rewards_Reward_Balances_Max_Fields>;
  min?: Maybe<Rewards_Reward_Balances_Min_Fields>;
  stddev?: Maybe<Rewards_Reward_Balances_Stddev_Fields>;
  stddev_pop?: Maybe<Rewards_Reward_Balances_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rewards_Reward_Balances_Stddev_Samp_Fields>;
  sum?: Maybe<Rewards_Reward_Balances_Sum_Fields>;
  var_pop?: Maybe<Rewards_Reward_Balances_Var_Pop_Fields>;
  var_samp?: Maybe<Rewards_Reward_Balances_Var_Samp_Fields>;
  variance?: Maybe<Rewards_Reward_Balances_Variance_Fields>;
};


/** aggregate fields of "rewards.reward_balances" */
export type Rewards_Reward_Balances_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rewards_Reward_Balances_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Rewards_Reward_Balances_Avg_Fields = {
  __typename?: 'rewards_reward_balances_avg_fields';
  token_balance?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "rewards.reward_balances". All fields are combined with a logical 'AND'. */
export type Rewards_Reward_Balances_Bool_Exp = {
  _and?: InputMaybe<Array<Rewards_Reward_Balances_Bool_Exp>>;
  _not?: InputMaybe<Rewards_Reward_Balances_Bool_Exp>;
  _or?: InputMaybe<Array<Rewards_Reward_Balances_Bool_Exp>>;
  token_balance?: InputMaybe<Bigint_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  vendor_id?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Rewards_Reward_Balances_Max_Fields = {
  __typename?: 'rewards_reward_balances_max_fields';
  token_balance?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Rewards_Reward_Balances_Min_Fields = {
  __typename?: 'rewards_reward_balances_min_fields';
  token_balance?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "rewards.reward_balances". */
export type Rewards_Reward_Balances_Order_By = {
  token_balance?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** select columns of table "rewards.reward_balances" */
export enum Rewards_Reward_Balances_Select_Column {
  /** column name */
  TokenBalance = 'token_balance',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VendorId = 'vendor_id'
}

/** aggregate stddev on columns */
export type Rewards_Reward_Balances_Stddev_Fields = {
  __typename?: 'rewards_reward_balances_stddev_fields';
  token_balance?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Rewards_Reward_Balances_Stddev_Pop_Fields = {
  __typename?: 'rewards_reward_balances_stddev_pop_fields';
  token_balance?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Rewards_Reward_Balances_Stddev_Samp_Fields = {
  __typename?: 'rewards_reward_balances_stddev_samp_fields';
  token_balance?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "rewards_reward_balances" */
export type Rewards_Reward_Balances_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rewards_Reward_Balances_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rewards_Reward_Balances_Stream_Cursor_Value_Input = {
  token_balance?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Rewards_Reward_Balances_Sum_Fields = {
  __typename?: 'rewards_reward_balances_sum_fields';
  token_balance?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Rewards_Reward_Balances_Var_Pop_Fields = {
  __typename?: 'rewards_reward_balances_var_pop_fields';
  token_balance?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Rewards_Reward_Balances_Var_Samp_Fields = {
  __typename?: 'rewards_reward_balances_var_samp_fields';
  token_balance?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Rewards_Reward_Balances_Variance_Fields = {
  __typename?: 'rewards_reward_balances_variance_fields';
  token_balance?: Maybe<Scalars['Float']>;
};

/** This table stores all transactions of user tokens, i.e. both customer answers and the redemption of tokens. */
export type Rewards_Transactions = {
  __typename?: 'rewards_transactions';
  comment?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  details: Scalars['jsonb'];
  id: Scalars['Int'];
  ranking_tokens: Scalars['Int'];
  reward_tokens: Scalars['Int'];
  /** An object relationship */
  user?: Maybe<User>;
  user_id: Scalars['uuid'];
  /** An object relationship */
  vendor?: Maybe<Organisation>;
  vendor_id: Scalars['String'];
};


/** This table stores all transactions of user tokens, i.e. both customer answers and the redemption of tokens. */
export type Rewards_TransactionsDetailsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "rewards.transactions" */
export type Rewards_Transactions_Aggregate = {
  __typename?: 'rewards_transactions_aggregate';
  aggregate?: Maybe<Rewards_Transactions_Aggregate_Fields>;
  nodes: Array<Rewards_Transactions>;
};

/** aggregate fields of "rewards.transactions" */
export type Rewards_Transactions_Aggregate_Fields = {
  __typename?: 'rewards_transactions_aggregate_fields';
  avg?: Maybe<Rewards_Transactions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Rewards_Transactions_Max_Fields>;
  min?: Maybe<Rewards_Transactions_Min_Fields>;
  stddev?: Maybe<Rewards_Transactions_Stddev_Fields>;
  stddev_pop?: Maybe<Rewards_Transactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rewards_Transactions_Stddev_Samp_Fields>;
  sum?: Maybe<Rewards_Transactions_Sum_Fields>;
  var_pop?: Maybe<Rewards_Transactions_Var_Pop_Fields>;
  var_samp?: Maybe<Rewards_Transactions_Var_Samp_Fields>;
  variance?: Maybe<Rewards_Transactions_Variance_Fields>;
};


/** aggregate fields of "rewards.transactions" */
export type Rewards_Transactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rewards_Transactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Rewards_Transactions_Append_Input = {
  details?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Rewards_Transactions_Avg_Fields = {
  __typename?: 'rewards_transactions_avg_fields';
  id?: Maybe<Scalars['Float']>;
  ranking_tokens?: Maybe<Scalars['Float']>;
  reward_tokens?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "rewards.transactions". All fields are combined with a logical 'AND'. */
export type Rewards_Transactions_Bool_Exp = {
  _and?: InputMaybe<Array<Rewards_Transactions_Bool_Exp>>;
  _not?: InputMaybe<Rewards_Transactions_Bool_Exp>;
  _or?: InputMaybe<Array<Rewards_Transactions_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  details?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  ranking_tokens?: InputMaybe<Int_Comparison_Exp>;
  reward_tokens?: InputMaybe<Int_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  vendor?: InputMaybe<Organisation_Bool_Exp>;
  vendor_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "rewards.transactions" */
export enum Rewards_Transactions_Constraint {
  /** unique or primary key constraint on columns "id" */
  TransactionsPkey = 'transactions_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Rewards_Transactions_Delete_At_Path_Input = {
  details?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Rewards_Transactions_Delete_Elem_Input = {
  details?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Rewards_Transactions_Delete_Key_Input = {
  details?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "rewards.transactions" */
export type Rewards_Transactions_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  ranking_tokens?: InputMaybe<Scalars['Int']>;
  reward_tokens?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "rewards.transactions" */
export type Rewards_Transactions_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  details?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  ranking_tokens?: InputMaybe<Scalars['Int']>;
  reward_tokens?: InputMaybe<Scalars['Int']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
  vendor?: InputMaybe<Organisation_Obj_Rel_Insert_Input>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Rewards_Transactions_Max_Fields = {
  __typename?: 'rewards_transactions_max_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  ranking_tokens?: Maybe<Scalars['Int']>;
  reward_tokens?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Rewards_Transactions_Min_Fields = {
  __typename?: 'rewards_transactions_min_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  ranking_tokens?: Maybe<Scalars['Int']>;
  reward_tokens?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "rewards.transactions" */
export type Rewards_Transactions_Mutation_Response = {
  __typename?: 'rewards_transactions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Rewards_Transactions>;
};

/** on_conflict condition type for table "rewards.transactions" */
export type Rewards_Transactions_On_Conflict = {
  constraint: Rewards_Transactions_Constraint;
  update_columns?: Array<Rewards_Transactions_Update_Column>;
  where?: InputMaybe<Rewards_Transactions_Bool_Exp>;
};

/** Ordering options when selecting data from "rewards.transactions". */
export type Rewards_Transactions_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  details?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ranking_tokens?: InputMaybe<Order_By>;
  reward_tokens?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
  vendor?: InputMaybe<Organisation_Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: rewards.transactions */
export type Rewards_Transactions_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Rewards_Transactions_Prepend_Input = {
  details?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "rewards.transactions" */
export enum Rewards_Transactions_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Details = 'details',
  /** column name */
  Id = 'id',
  /** column name */
  RankingTokens = 'ranking_tokens',
  /** column name */
  RewardTokens = 'reward_tokens',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VendorId = 'vendor_id'
}

/** input type for updating data in table "rewards.transactions" */
export type Rewards_Transactions_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  details?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  ranking_tokens?: InputMaybe<Scalars['Int']>;
  reward_tokens?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Rewards_Transactions_Stddev_Fields = {
  __typename?: 'rewards_transactions_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  ranking_tokens?: Maybe<Scalars['Float']>;
  reward_tokens?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Rewards_Transactions_Stddev_Pop_Fields = {
  __typename?: 'rewards_transactions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  ranking_tokens?: Maybe<Scalars['Float']>;
  reward_tokens?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Rewards_Transactions_Stddev_Samp_Fields = {
  __typename?: 'rewards_transactions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  ranking_tokens?: Maybe<Scalars['Float']>;
  reward_tokens?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "rewards_transactions" */
export type Rewards_Transactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rewards_Transactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rewards_Transactions_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  details?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  ranking_tokens?: InputMaybe<Scalars['Int']>;
  reward_tokens?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Rewards_Transactions_Sum_Fields = {
  __typename?: 'rewards_transactions_sum_fields';
  id?: Maybe<Scalars['Int']>;
  ranking_tokens?: Maybe<Scalars['Int']>;
  reward_tokens?: Maybe<Scalars['Int']>;
};

/** update columns of table "rewards.transactions" */
export enum Rewards_Transactions_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Details = 'details',
  /** column name */
  Id = 'id',
  /** column name */
  RankingTokens = 'ranking_tokens',
  /** column name */
  RewardTokens = 'reward_tokens',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VendorId = 'vendor_id'
}

export type Rewards_Transactions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Rewards_Transactions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Rewards_Transactions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Rewards_Transactions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Rewards_Transactions_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Rewards_Transactions_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Rewards_Transactions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Rewards_Transactions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Rewards_Transactions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Rewards_Transactions_Var_Pop_Fields = {
  __typename?: 'rewards_transactions_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  ranking_tokens?: Maybe<Scalars['Float']>;
  reward_tokens?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Rewards_Transactions_Var_Samp_Fields = {
  __typename?: 'rewards_transactions_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  ranking_tokens?: Maybe<Scalars['Float']>;
  reward_tokens?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Rewards_Transactions_Variance_Fields = {
  __typename?: 'rewards_transactions_variance_fields';
  id?: Maybe<Scalars['Float']>;
  ranking_tokens?: Maybe<Scalars['Float']>;
  reward_tokens?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "sessions" */
export type Sessions = {
  __typename?: 'sessions';
  /** An array relationship */
  answers: Array<Answers>;
  /** An aggregate relationship */
  answers_aggregate: Answers_Aggregate;
  finished_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An object relationship */
  quiz: Content_Quizzes;
  quiz_id: Scalars['Int'];
  started_at: Scalars['timestamp'];
  /** An object relationship */
  user?: Maybe<User>;
  /** An object relationship */
  user_detail: User_Details;
  user_id: Scalars['uuid'];
  vendor_id: Scalars['String'];
};


/** columns and relationships of "sessions" */
export type SessionsAnswersArgs = {
  distinct_on?: InputMaybe<Array<Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answers_Order_By>>;
  where?: InputMaybe<Answers_Bool_Exp>;
};


/** columns and relationships of "sessions" */
export type SessionsAnswers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answers_Order_By>>;
  where?: InputMaybe<Answers_Bool_Exp>;
};

/** aggregated selection of "sessions" */
export type Sessions_Aggregate = {
  __typename?: 'sessions_aggregate';
  aggregate?: Maybe<Sessions_Aggregate_Fields>;
  nodes: Array<Sessions>;
};

export type Sessions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Sessions_Aggregate_Bool_Exp_Count>;
};

export type Sessions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Sessions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Sessions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "sessions" */
export type Sessions_Aggregate_Fields = {
  __typename?: 'sessions_aggregate_fields';
  avg?: Maybe<Sessions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Sessions_Max_Fields>;
  min?: Maybe<Sessions_Min_Fields>;
  stddev?: Maybe<Sessions_Stddev_Fields>;
  stddev_pop?: Maybe<Sessions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sessions_Stddev_Samp_Fields>;
  sum?: Maybe<Sessions_Sum_Fields>;
  var_pop?: Maybe<Sessions_Var_Pop_Fields>;
  var_samp?: Maybe<Sessions_Var_Samp_Fields>;
  variance?: Maybe<Sessions_Variance_Fields>;
};


/** aggregate fields of "sessions" */
export type Sessions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sessions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "sessions" */
export type Sessions_Aggregate_Order_By = {
  avg?: InputMaybe<Sessions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Sessions_Max_Order_By>;
  min?: InputMaybe<Sessions_Min_Order_By>;
  stddev?: InputMaybe<Sessions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Sessions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Sessions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Sessions_Sum_Order_By>;
  var_pop?: InputMaybe<Sessions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Sessions_Var_Samp_Order_By>;
  variance?: InputMaybe<Sessions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "sessions" */
export type Sessions_Arr_Rel_Insert_Input = {
  data: Array<Sessions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Sessions_On_Conflict>;
};

/** aggregate avg on columns */
export type Sessions_Avg_Fields = {
  __typename?: 'sessions_avg_fields';
  quiz_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "sessions" */
export type Sessions_Avg_Order_By = {
  quiz_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "sessions". All fields are combined with a logical 'AND'. */
export type Sessions_Bool_Exp = {
  _and?: InputMaybe<Array<Sessions_Bool_Exp>>;
  _not?: InputMaybe<Sessions_Bool_Exp>;
  _or?: InputMaybe<Array<Sessions_Bool_Exp>>;
  answers?: InputMaybe<Answers_Bool_Exp>;
  answers_aggregate?: InputMaybe<Answers_Aggregate_Bool_Exp>;
  finished_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  quiz?: InputMaybe<Content_Quizzes_Bool_Exp>;
  quiz_id?: InputMaybe<Int_Comparison_Exp>;
  started_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_detail?: InputMaybe<User_Details_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  vendor_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "sessions" */
export enum Sessions_Constraint {
  /** unique or primary key constraint on columns "id" */
  SessionsPkey = 'sessions_pkey'
}

/** input type for incrementing numeric columns in table "sessions" */
export type Sessions_Inc_Input = {
  quiz_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "sessions" */
export type Sessions_Insert_Input = {
  answers?: InputMaybe<Answers_Arr_Rel_Insert_Input>;
  finished_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  quiz?: InputMaybe<Content_Quizzes_Obj_Rel_Insert_Input>;
  quiz_id?: InputMaybe<Scalars['Int']>;
  started_at?: InputMaybe<Scalars['timestamp']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_detail?: InputMaybe<User_Details_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Sessions_Max_Fields = {
  __typename?: 'sessions_max_fields';
  finished_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  quiz_id?: Maybe<Scalars['Int']>;
  started_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "sessions" */
export type Sessions_Max_Order_By = {
  finished_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quiz_id?: InputMaybe<Order_By>;
  started_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Sessions_Min_Fields = {
  __typename?: 'sessions_min_fields';
  finished_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  quiz_id?: Maybe<Scalars['Int']>;
  started_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "sessions" */
export type Sessions_Min_Order_By = {
  finished_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quiz_id?: InputMaybe<Order_By>;
  started_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "sessions" */
export type Sessions_Mutation_Response = {
  __typename?: 'sessions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Sessions>;
};

/** input type for inserting object relation for remote table "sessions" */
export type Sessions_Obj_Rel_Insert_Input = {
  data: Sessions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Sessions_On_Conflict>;
};

/** on_conflict condition type for table "sessions" */
export type Sessions_On_Conflict = {
  constraint: Sessions_Constraint;
  update_columns?: Array<Sessions_Update_Column>;
  where?: InputMaybe<Sessions_Bool_Exp>;
};

/** Ordering options when selecting data from "sessions". */
export type Sessions_Order_By = {
  answers_aggregate?: InputMaybe<Answers_Aggregate_Order_By>;
  finished_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  quiz?: InputMaybe<Content_Quizzes_Order_By>;
  quiz_id?: InputMaybe<Order_By>;
  started_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_detail?: InputMaybe<User_Details_Order_By>;
  user_id?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: sessions */
export type Sessions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "sessions" */
export enum Sessions_Select_Column {
  /** column name */
  FinishedAt = 'finished_at',
  /** column name */
  Id = 'id',
  /** column name */
  QuizId = 'quiz_id',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VendorId = 'vendor_id'
}

/** input type for updating data in table "sessions" */
export type Sessions_Set_Input = {
  finished_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  quiz_id?: InputMaybe<Scalars['Int']>;
  started_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Sessions_Stddev_Fields = {
  __typename?: 'sessions_stddev_fields';
  quiz_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "sessions" */
export type Sessions_Stddev_Order_By = {
  quiz_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Sessions_Stddev_Pop_Fields = {
  __typename?: 'sessions_stddev_pop_fields';
  quiz_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "sessions" */
export type Sessions_Stddev_Pop_Order_By = {
  quiz_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Sessions_Stddev_Samp_Fields = {
  __typename?: 'sessions_stddev_samp_fields';
  quiz_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "sessions" */
export type Sessions_Stddev_Samp_Order_By = {
  quiz_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "sessions" */
export type Sessions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sessions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sessions_Stream_Cursor_Value_Input = {
  finished_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  quiz_id?: InputMaybe<Scalars['Int']>;
  started_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Sessions_Sum_Fields = {
  __typename?: 'sessions_sum_fields';
  quiz_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "sessions" */
export type Sessions_Sum_Order_By = {
  quiz_id?: InputMaybe<Order_By>;
};

/** update columns of table "sessions" */
export enum Sessions_Update_Column {
  /** column name */
  FinishedAt = 'finished_at',
  /** column name */
  Id = 'id',
  /** column name */
  QuizId = 'quiz_id',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VendorId = 'vendor_id'
}

export type Sessions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Sessions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Sessions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sessions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Sessions_Var_Pop_Fields = {
  __typename?: 'sessions_var_pop_fields';
  quiz_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "sessions" */
export type Sessions_Var_Pop_Order_By = {
  quiz_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Sessions_Var_Samp_Fields = {
  __typename?: 'sessions_var_samp_fields';
  quiz_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "sessions" */
export type Sessions_Var_Samp_Order_By = {
  quiz_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Sessions_Variance_Fields = {
  __typename?: 'sessions_variance_fields';
  quiz_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "sessions" */
export type Sessions_Variance_Order_By = {
  quiz_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "sqbase_event_log.event_types" */
export type Sqbase_Event_Log_Event_Types = {
  __typename?: 'sqbase_event_log_event_types';
  id: Scalars['String'];
};

/** aggregated selection of "sqbase_event_log.event_types" */
export type Sqbase_Event_Log_Event_Types_Aggregate = {
  __typename?: 'sqbase_event_log_event_types_aggregate';
  aggregate?: Maybe<Sqbase_Event_Log_Event_Types_Aggregate_Fields>;
  nodes: Array<Sqbase_Event_Log_Event_Types>;
};

/** aggregate fields of "sqbase_event_log.event_types" */
export type Sqbase_Event_Log_Event_Types_Aggregate_Fields = {
  __typename?: 'sqbase_event_log_event_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Sqbase_Event_Log_Event_Types_Max_Fields>;
  min?: Maybe<Sqbase_Event_Log_Event_Types_Min_Fields>;
};


/** aggregate fields of "sqbase_event_log.event_types" */
export type Sqbase_Event_Log_Event_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sqbase_Event_Log_Event_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "sqbase_event_log.event_types". All fields are combined with a logical 'AND'. */
export type Sqbase_Event_Log_Event_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Sqbase_Event_Log_Event_Types_Bool_Exp>>;
  _not?: InputMaybe<Sqbase_Event_Log_Event_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Sqbase_Event_Log_Event_Types_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "sqbase_event_log.event_types" */
export enum Sqbase_Event_Log_Event_Types_Constraint {
  /** unique or primary key constraint on columns "id" */
  EventTypesPkey = 'event_types_pkey'
}

/** input type for inserting data into table "sqbase_event_log.event_types" */
export type Sqbase_Event_Log_Event_Types_Insert_Input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Sqbase_Event_Log_Event_Types_Max_Fields = {
  __typename?: 'sqbase_event_log_event_types_max_fields';
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Sqbase_Event_Log_Event_Types_Min_Fields = {
  __typename?: 'sqbase_event_log_event_types_min_fields';
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "sqbase_event_log.event_types" */
export type Sqbase_Event_Log_Event_Types_Mutation_Response = {
  __typename?: 'sqbase_event_log_event_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Sqbase_Event_Log_Event_Types>;
};

/** on_conflict condition type for table "sqbase_event_log.event_types" */
export type Sqbase_Event_Log_Event_Types_On_Conflict = {
  constraint: Sqbase_Event_Log_Event_Types_Constraint;
  update_columns?: Array<Sqbase_Event_Log_Event_Types_Update_Column>;
  where?: InputMaybe<Sqbase_Event_Log_Event_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "sqbase_event_log.event_types". */
export type Sqbase_Event_Log_Event_Types_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: sqbase_event_log.event_types */
export type Sqbase_Event_Log_Event_Types_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "sqbase_event_log.event_types" */
export enum Sqbase_Event_Log_Event_Types_Select_Column {
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "sqbase_event_log.event_types" */
export type Sqbase_Event_Log_Event_Types_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "sqbase_event_log_event_types" */
export type Sqbase_Event_Log_Event_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sqbase_Event_Log_Event_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sqbase_Event_Log_Event_Types_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "sqbase_event_log.event_types" */
export enum Sqbase_Event_Log_Event_Types_Update_Column {
  /** column name */
  Id = 'id'
}

export type Sqbase_Event_Log_Event_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Sqbase_Event_Log_Event_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sqbase_Event_Log_Event_Types_Bool_Exp;
};

/** columns and relationships of "sqbase_event_log.events" */
export type Sqbase_Event_Log_Events = {
  __typename?: 'sqbase_event_log_events';
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  organisation_id: Scalars['String'];
  parameters: Scalars['jsonb'];
  type: Scalars['String'];
  user_id: Scalars['uuid'];
};


/** columns and relationships of "sqbase_event_log.events" */
export type Sqbase_Event_Log_EventsParametersArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "sqbase_event_log.events" */
export type Sqbase_Event_Log_Events_Aggregate = {
  __typename?: 'sqbase_event_log_events_aggregate';
  aggregate?: Maybe<Sqbase_Event_Log_Events_Aggregate_Fields>;
  nodes: Array<Sqbase_Event_Log_Events>;
};

/** aggregate fields of "sqbase_event_log.events" */
export type Sqbase_Event_Log_Events_Aggregate_Fields = {
  __typename?: 'sqbase_event_log_events_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Sqbase_Event_Log_Events_Max_Fields>;
  min?: Maybe<Sqbase_Event_Log_Events_Min_Fields>;
};


/** aggregate fields of "sqbase_event_log.events" */
export type Sqbase_Event_Log_Events_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sqbase_Event_Log_Events_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Sqbase_Event_Log_Events_Append_Input = {
  parameters?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "sqbase_event_log.events". All fields are combined with a logical 'AND'. */
export type Sqbase_Event_Log_Events_Bool_Exp = {
  _and?: InputMaybe<Array<Sqbase_Event_Log_Events_Bool_Exp>>;
  _not?: InputMaybe<Sqbase_Event_Log_Events_Bool_Exp>;
  _or?: InputMaybe<Array<Sqbase_Event_Log_Events_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  organisation_id?: InputMaybe<String_Comparison_Exp>;
  parameters?: InputMaybe<Jsonb_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "sqbase_event_log.events" */
export enum Sqbase_Event_Log_Events_Constraint {
  /** unique or primary key constraint on columns "user_id", "created_at", "type" */
  EventsPkey = 'events_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Sqbase_Event_Log_Events_Delete_At_Path_Input = {
  parameters?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Sqbase_Event_Log_Events_Delete_Elem_Input = {
  parameters?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Sqbase_Event_Log_Events_Delete_Key_Input = {
  parameters?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "sqbase_event_log.events" */
export type Sqbase_Event_Log_Events_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  organisation_id?: InputMaybe<Scalars['String']>;
  parameters?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Sqbase_Event_Log_Events_Max_Fields = {
  __typename?: 'sqbase_event_log_events_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  organisation_id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Sqbase_Event_Log_Events_Min_Fields = {
  __typename?: 'sqbase_event_log_events_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  organisation_id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "sqbase_event_log.events" */
export type Sqbase_Event_Log_Events_Mutation_Response = {
  __typename?: 'sqbase_event_log_events_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Sqbase_Event_Log_Events>;
};

/** on_conflict condition type for table "sqbase_event_log.events" */
export type Sqbase_Event_Log_Events_On_Conflict = {
  constraint: Sqbase_Event_Log_Events_Constraint;
  update_columns?: Array<Sqbase_Event_Log_Events_Update_Column>;
  where?: InputMaybe<Sqbase_Event_Log_Events_Bool_Exp>;
};

/** Ordering options when selecting data from "sqbase_event_log.events". */
export type Sqbase_Event_Log_Events_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  parameters?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: sqbase_event_log.events */
export type Sqbase_Event_Log_Events_Pk_Columns_Input = {
  created_at: Scalars['timestamptz'];
  type: Scalars['String'];
  user_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Sqbase_Event_Log_Events_Prepend_Input = {
  parameters?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "sqbase_event_log.events" */
export enum Sqbase_Event_Log_Events_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  OrganisationId = 'organisation_id',
  /** column name */
  Parameters = 'parameters',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "sqbase_event_log.events" */
export type Sqbase_Event_Log_Events_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  organisation_id?: InputMaybe<Scalars['String']>;
  parameters?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "sqbase_event_log_events" */
export type Sqbase_Event_Log_Events_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sqbase_Event_Log_Events_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sqbase_Event_Log_Events_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  organisation_id?: InputMaybe<Scalars['String']>;
  parameters?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "sqbase_event_log.events" */
export enum Sqbase_Event_Log_Events_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  OrganisationId = 'organisation_id',
  /** column name */
  Parameters = 'parameters',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

export type Sqbase_Event_Log_Events_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Sqbase_Event_Log_Events_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Sqbase_Event_Log_Events_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Sqbase_Event_Log_Events_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Sqbase_Event_Log_Events_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Sqbase_Event_Log_Events_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Sqbase_Event_Log_Events_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sqbase_Event_Log_Events_Bool_Exp;
};

/** columns and relationships of "sqlab_contacts" */
export type Sqlab_Contacts = {
  __typename?: 'sqlab_contacts';
  email_address: Scalars['String'];
  name: Scalars['String'];
  phone_number: Scalars['String'];
};

/** aggregated selection of "sqlab_contacts" */
export type Sqlab_Contacts_Aggregate = {
  __typename?: 'sqlab_contacts_aggregate';
  aggregate?: Maybe<Sqlab_Contacts_Aggregate_Fields>;
  nodes: Array<Sqlab_Contacts>;
};

/** aggregate fields of "sqlab_contacts" */
export type Sqlab_Contacts_Aggregate_Fields = {
  __typename?: 'sqlab_contacts_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Sqlab_Contacts_Max_Fields>;
  min?: Maybe<Sqlab_Contacts_Min_Fields>;
};


/** aggregate fields of "sqlab_contacts" */
export type Sqlab_Contacts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sqlab_Contacts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "sqlab_contacts". All fields are combined with a logical 'AND'. */
export type Sqlab_Contacts_Bool_Exp = {
  _and?: InputMaybe<Array<Sqlab_Contacts_Bool_Exp>>;
  _not?: InputMaybe<Sqlab_Contacts_Bool_Exp>;
  _or?: InputMaybe<Array<Sqlab_Contacts_Bool_Exp>>;
  email_address?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "sqlab_contacts" */
export enum Sqlab_Contacts_Constraint {
  /** unique or primary key constraint on columns "name" */
  SqlabContactsPkey = 'sqlab_contacts_pkey'
}

/** input type for inserting data into table "sqlab_contacts" */
export type Sqlab_Contacts_Insert_Input = {
  email_address?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Sqlab_Contacts_Max_Fields = {
  __typename?: 'sqlab_contacts_max_fields';
  email_address?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Sqlab_Contacts_Min_Fields = {
  __typename?: 'sqlab_contacts_min_fields';
  email_address?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "sqlab_contacts" */
export type Sqlab_Contacts_Mutation_Response = {
  __typename?: 'sqlab_contacts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Sqlab_Contacts>;
};

/** input type for inserting object relation for remote table "sqlab_contacts" */
export type Sqlab_Contacts_Obj_Rel_Insert_Input = {
  data: Sqlab_Contacts_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Sqlab_Contacts_On_Conflict>;
};

/** on_conflict condition type for table "sqlab_contacts" */
export type Sqlab_Contacts_On_Conflict = {
  constraint: Sqlab_Contacts_Constraint;
  update_columns?: Array<Sqlab_Contacts_Update_Column>;
  where?: InputMaybe<Sqlab_Contacts_Bool_Exp>;
};

/** Ordering options when selecting data from "sqlab_contacts". */
export type Sqlab_Contacts_Order_By = {
  email_address?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
};

/** primary key columns input for table: sqlab_contacts */
export type Sqlab_Contacts_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "sqlab_contacts" */
export enum Sqlab_Contacts_Select_Column {
  /** column name */
  EmailAddress = 'email_address',
  /** column name */
  Name = 'name',
  /** column name */
  PhoneNumber = 'phone_number'
}

/** input type for updating data in table "sqlab_contacts" */
export type Sqlab_Contacts_Set_Input = {
  email_address?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "sqlab_contacts" */
export type Sqlab_Contacts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sqlab_Contacts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sqlab_Contacts_Stream_Cursor_Value_Input = {
  email_address?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
};

/** update columns of table "sqlab_contacts" */
export enum Sqlab_Contacts_Update_Column {
  /** column name */
  EmailAddress = 'email_address',
  /** column name */
  Name = 'name',
  /** column name */
  PhoneNumber = 'phone_number'
}

export type Sqlab_Contacts_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Sqlab_Contacts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sqlab_Contacts_Bool_Exp;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "aggregation.lectures_completed_by_vendor_per_year" */
  aggregation_lectures_completed_by_vendor_per_year: Array<Aggregation_Lectures_Completed_By_Vendor_Per_Year>;
  /** fetch aggregated fields from the table: "aggregation.lectures_completed_by_vendor_per_year" */
  aggregation_lectures_completed_by_vendor_per_year_aggregate: Aggregation_Lectures_Completed_By_Vendor_Per_Year_Aggregate;
  /** fetch data from the table in a streaming manner: "aggregation.lectures_completed_by_vendor_per_year" */
  aggregation_lectures_completed_by_vendor_per_year_stream: Array<Aggregation_Lectures_Completed_By_Vendor_Per_Year>;
  /** fetch data from the table: "aggregation.required_lections_count" */
  aggregation_required_lections_count: Array<Aggregation_Required_Lections_Count>;
  /** fetch aggregated fields from the table: "aggregation.required_lections_count" */
  aggregation_required_lections_count_aggregate: Aggregation_Required_Lections_Count_Aggregate;
  /** fetch data from the table in a streaming manner: "aggregation.required_lections_count" */
  aggregation_required_lections_count_stream: Array<Aggregation_Required_Lections_Count>;
  /** fetch data from the table: "aggregation.requirements_overview" */
  aggregation_requirements_overview: Array<Aggregation_Requirements_Overview>;
  /** fetch aggregated fields from the table: "aggregation.requirements_overview" */
  aggregation_requirements_overview_aggregate: Aggregation_Requirements_Overview_Aggregate;
  /** fetch data from the table in a streaming manner: "aggregation.requirements_overview" */
  aggregation_requirements_overview_stream: Array<Aggregation_Requirements_Overview>;
  /** fetch data from the table: "aggregation.users_chapters" */
  aggregation_users_chapters: Array<Aggregation_Users_Chapters>;
  /** fetch aggregated fields from the table: "aggregation.users_chapters" */
  aggregation_users_chapters_aggregate: Aggregation_Users_Chapters_Aggregate;
  /** fetch data from the table in a streaming manner: "aggregation.users_chapters" */
  aggregation_users_chapters_stream: Array<Aggregation_Users_Chapters>;
  /** fetch data from the table: "aggregation.users_lections" */
  aggregation_users_lections: Array<Aggregation_Users_Lections>;
  /** fetch aggregated fields from the table: "aggregation.users_lections" */
  aggregation_users_lections_aggregate: Aggregation_Users_Lections_Aggregate;
  /** fetch data from the table in a streaming manner: "aggregation.users_lections" */
  aggregation_users_lections_stream: Array<Aggregation_Users_Lections>;
  /** fetch data from the table: "aggregation.users_requirements" */
  aggregation_users_requirements: Array<Aggregation_Users_Requirements>;
  /** fetch aggregated fields from the table: "aggregation.users_requirements" */
  aggregation_users_requirements_aggregate: Aggregation_Users_Requirements_Aggregate;
  /** fetch data from the table in a streaming manner: "aggregation.users_requirements" */
  aggregation_users_requirements_stream: Array<Aggregation_Users_Requirements>;
  /** fetch data from the table: "answer_enums.all" */
  answer_enums_all: Array<Answer_Enums_All>;
  /** fetch aggregated fields from the table: "answer_enums.all" */
  answer_enums_all_aggregate: Answer_Enums_All_Aggregate;
  /** fetch data from the table in a streaming manner: "answer_enums.all" */
  answer_enums_all_stream: Array<Answer_Enums_All>;
  /** fetch data from the table: "answer_enums.bicycle_type" */
  answer_enums_bicycle_type: Array<Answer_Enums_Bicycle_Type>;
  /** fetch aggregated fields from the table: "answer_enums.bicycle_type" */
  answer_enums_bicycle_type_aggregate: Answer_Enums_Bicycle_Type_Aggregate;
  /** fetch data from the table: "answer_enums.bicycle_type" using primary key columns */
  answer_enums_bicycle_type_by_pk?: Maybe<Answer_Enums_Bicycle_Type>;
  /** fetch data from the table in a streaming manner: "answer_enums.bicycle_type" */
  answer_enums_bicycle_type_stream: Array<Answer_Enums_Bicycle_Type>;
  /** fetch data from the table: "answer_enums.driving_duration" */
  answer_enums_driving_duration: Array<Answer_Enums_Driving_Duration>;
  /** fetch aggregated fields from the table: "answer_enums.driving_duration" */
  answer_enums_driving_duration_aggregate: Answer_Enums_Driving_Duration_Aggregate;
  /** fetch data from the table: "answer_enums.driving_duration" using primary key columns */
  answer_enums_driving_duration_by_pk?: Maybe<Answer_Enums_Driving_Duration>;
  /** fetch data from the table in a streaming manner: "answer_enums.driving_duration" */
  answer_enums_driving_duration_stream: Array<Answer_Enums_Driving_Duration>;
  /** fetch data from the table: "answer_enums.driving_frequency" */
  answer_enums_driving_frequency: Array<Answer_Enums_Driving_Frequency>;
  /** fetch aggregated fields from the table: "answer_enums.driving_frequency" */
  answer_enums_driving_frequency_aggregate: Answer_Enums_Driving_Frequency_Aggregate;
  /** fetch data from the table: "answer_enums.driving_frequency" using primary key columns */
  answer_enums_driving_frequency_by_pk?: Maybe<Answer_Enums_Driving_Frequency>;
  /** fetch data from the table in a streaming manner: "answer_enums.driving_frequency" */
  answer_enums_driving_frequency_stream: Array<Answer_Enums_Driving_Frequency>;
  /** fetch data from the table: "answer_enums.e_bike" */
  answer_enums_e_bike: Array<Answer_Enums_E_Bike>;
  /** fetch aggregated fields from the table: "answer_enums.e_bike" */
  answer_enums_e_bike_aggregate: Answer_Enums_E_Bike_Aggregate;
  /** fetch data from the table: "answer_enums.e_bike" using primary key columns */
  answer_enums_e_bike_by_pk?: Maybe<Answer_Enums_E_Bike>;
  /** fetch data from the table in a streaming manner: "answer_enums.e_bike" */
  answer_enums_e_bike_stream: Array<Answer_Enums_E_Bike>;
  /** fetch data from the table: "answer_enums.foot_position" */
  answer_enums_foot_position: Array<Answer_Enums_Foot_Position>;
  /** fetch aggregated fields from the table: "answer_enums.foot_position" */
  answer_enums_foot_position_aggregate: Answer_Enums_Foot_Position_Aggregate;
  /** fetch data from the table: "answer_enums.foot_position" using primary key columns */
  answer_enums_foot_position_by_pk?: Maybe<Answer_Enums_Foot_Position>;
  /** fetch data from the table in a streaming manner: "answer_enums.foot_position" */
  answer_enums_foot_position_stream: Array<Answer_Enums_Foot_Position>;
  /** fetch data from the table: "answer_enums.foot_problems" */
  answer_enums_foot_problems: Array<Answer_Enums_Foot_Problems>;
  /** fetch aggregated fields from the table: "answer_enums.foot_problems" */
  answer_enums_foot_problems_aggregate: Answer_Enums_Foot_Problems_Aggregate;
  /** fetch data from the table: "answer_enums.foot_problems" using primary key columns */
  answer_enums_foot_problems_by_pk?: Maybe<Answer_Enums_Foot_Problems>;
  /** fetch data from the table in a streaming manner: "answer_enums.foot_problems" */
  answer_enums_foot_problems_stream: Array<Answer_Enums_Foot_Problems>;
  /** fetch data from the table: "answer_enums.foot_type" */
  answer_enums_foot_type: Array<Answer_Enums_Foot_Type>;
  /** fetch aggregated fields from the table: "answer_enums.foot_type" */
  answer_enums_foot_type_aggregate: Answer_Enums_Foot_Type_Aggregate;
  /** fetch data from the table: "answer_enums.foot_type" using primary key columns */
  answer_enums_foot_type_by_pk?: Maybe<Answer_Enums_Foot_Type>;
  /** fetch data from the table in a streaming manner: "answer_enums.foot_type" */
  answer_enums_foot_type_stream: Array<Answer_Enums_Foot_Type>;
  /** fetch data from the table: "answer_enums.gripping_distance" */
  answer_enums_gripping_distance: Array<Answer_Enums_Gripping_Distance>;
  /** fetch aggregated fields from the table: "answer_enums.gripping_distance" */
  answer_enums_gripping_distance_aggregate: Answer_Enums_Gripping_Distance_Aggregate;
  /** fetch data from the table: "answer_enums.gripping_distance" using primary key columns */
  answer_enums_gripping_distance_by_pk?: Maybe<Answer_Enums_Gripping_Distance>;
  /** fetch data from the table in a streaming manner: "answer_enums.gripping_distance" */
  answer_enums_gripping_distance_stream: Array<Answer_Enums_Gripping_Distance>;
  /** fetch data from the table: "answer_enums.hand_problems" */
  answer_enums_hand_problems: Array<Answer_Enums_Hand_Problems>;
  /** fetch aggregated fields from the table: "answer_enums.hand_problems" */
  answer_enums_hand_problems_aggregate: Answer_Enums_Hand_Problems_Aggregate;
  /** fetch data from the table: "answer_enums.hand_problems" using primary key columns */
  answer_enums_hand_problems_by_pk?: Maybe<Answer_Enums_Hand_Problems>;
  /** fetch data from the table in a streaming manner: "answer_enums.hand_problems" */
  answer_enums_hand_problems_stream: Array<Answer_Enums_Hand_Problems>;
  /** fetch data from the table: "answer_enums.leg_axis" */
  answer_enums_leg_axis: Array<Answer_Enums_Leg_Axis>;
  /** fetch aggregated fields from the table: "answer_enums.leg_axis" */
  answer_enums_leg_axis_aggregate: Answer_Enums_Leg_Axis_Aggregate;
  /** fetch data from the table: "answer_enums.leg_axis" using primary key columns */
  answer_enums_leg_axis_by_pk?: Maybe<Answer_Enums_Leg_Axis>;
  /** fetch data from the table in a streaming manner: "answer_enums.leg_axis" */
  answer_enums_leg_axis_stream: Array<Answer_Enums_Leg_Axis>;
  /** fetch data from the table: "answer_enums.pelvic_problems" */
  answer_enums_pelvic_problems: Array<Answer_Enums_Pelvic_Problems>;
  /** fetch aggregated fields from the table: "answer_enums.pelvic_problems" */
  answer_enums_pelvic_problems_aggregate: Answer_Enums_Pelvic_Problems_Aggregate;
  /** fetch data from the table: "answer_enums.pelvic_problems" using primary key columns */
  answer_enums_pelvic_problems_by_pk?: Maybe<Answer_Enums_Pelvic_Problems>;
  /** fetch data from the table in a streaming manner: "answer_enums.pelvic_problems" */
  answer_enums_pelvic_problems_stream: Array<Answer_Enums_Pelvic_Problems>;
  /** fetch data from the table: "answer_enums.seat_position" */
  answer_enums_seat_position: Array<Answer_Enums_Seat_Position>;
  /** fetch aggregated fields from the table: "answer_enums.seat_position" */
  answer_enums_seat_position_aggregate: Answer_Enums_Seat_Position_Aggregate;
  /** fetch data from the table: "answer_enums.seat_position" using primary key columns */
  answer_enums_seat_position_by_pk?: Maybe<Answer_Enums_Seat_Position>;
  /** fetch data from the table in a streaming manner: "answer_enums.seat_position" */
  answer_enums_seat_position_stream: Array<Answer_Enums_Seat_Position>;
  /** fetch data from the table: "answer_enums.sex" */
  answer_enums_sex: Array<Answer_Enums_Sex>;
  /** fetch aggregated fields from the table: "answer_enums.sex" */
  answer_enums_sex_aggregate: Answer_Enums_Sex_Aggregate;
  /** fetch data from the table: "answer_enums.sex" using primary key columns */
  answer_enums_sex_by_pk?: Maybe<Answer_Enums_Sex>;
  /** fetch data from the table in a streaming manner: "answer_enums.sex" */
  answer_enums_sex_stream: Array<Answer_Enums_Sex>;
  /** fetch data from the table: "answer_enums.unmatched_answers" */
  answer_enums_unmatched_answers: Array<Answer_Enums_Unmatched_Answers>;
  /** fetch aggregated fields from the table: "answer_enums.unmatched_answers" */
  answer_enums_unmatched_answers_aggregate: Answer_Enums_Unmatched_Answers_Aggregate;
  /** fetch data from the table in a streaming manner: "answer_enums.unmatched_answers" */
  answer_enums_unmatched_answers_stream: Array<Answer_Enums_Unmatched_Answers>;
  /** fetch data from the table: "answer_enums.unmatched_enums" */
  answer_enums_unmatched_enums: Array<Answer_Enums_Unmatched_Enums>;
  /** fetch aggregated fields from the table: "answer_enums.unmatched_enums" */
  answer_enums_unmatched_enums_aggregate: Answer_Enums_Unmatched_Enums_Aggregate;
  /** fetch data from the table in a streaming manner: "answer_enums.unmatched_enums" */
  answer_enums_unmatched_enums_stream: Array<Answer_Enums_Unmatched_Enums>;
  /** An array relationship */
  answers: Array<Answers>;
  /** An aggregate relationship */
  answers_aggregate: Answers_Aggregate;
  /** fetch data from the table: "answers" using primary key columns */
  answers_by_pk?: Maybe<Answers>;
  /** fetch data from the table in a streaming manner: "answers" */
  answers_stream: Array<Answers>;
  /** fetch data from the table: "article_group" */
  article_group: Array<Article_Group>;
  /** fetch aggregated fields from the table: "article_group" */
  article_group_aggregate: Article_Group_Aggregate;
  /** fetch data from the table: "article_group" using primary key columns */
  article_group_by_pk?: Maybe<Article_Group>;
  /** fetch data from the table in a streaming manner: "article_group" */
  article_group_stream: Array<Article_Group>;
  /** An array relationship */
  article_information_i18n: Array<Article_Information_I18n>;
  /** An aggregate relationship */
  article_information_i18n_aggregate: Article_Information_I18n_Aggregate;
  /** fetch data from the table: "article_information_i18n" using primary key columns */
  article_information_i18n_by_pk?: Maybe<Article_Information_I18n>;
  /** fetch data from the table in a streaming manner: "article_information_i18n" */
  article_information_i18n_stream: Array<Article_Information_I18n>;
  /** fetch data from the table: "article_ranking" */
  article_ranking: Array<Article_Ranking>;
  /** fetch aggregated fields from the table: "article_ranking" */
  article_ranking_aggregate: Article_Ranking_Aggregate;
  /** fetch data from the table: "article_ranking" using primary key columns */
  article_ranking_by_pk?: Maybe<Article_Ranking>;
  /** fetch data from the table in a streaming manner: "article_ranking" */
  article_ranking_stream: Array<Article_Ranking>;
  /** fetch data from the table: "article_type" */
  article_type: Array<Article_Type>;
  /** fetch aggregated fields from the table: "article_type" */
  article_type_aggregate: Article_Type_Aggregate;
  /** fetch data from the table: "article_type" using primary key columns */
  article_type_by_pk?: Maybe<Article_Type>;
  /** fetch data from the table in a streaming manner: "article_type" */
  article_type_stream: Array<Article_Type>;
  /** fetch data from the table: "auth.one_time_token" */
  auth_one_time_token: Array<Auth_One_Time_Token>;
  /** fetch aggregated fields from the table: "auth.one_time_token" */
  auth_one_time_token_aggregate: Auth_One_Time_Token_Aggregate;
  /** fetch data from the table: "auth.one_time_token" using primary key columns */
  auth_one_time_token_by_pk?: Maybe<Auth_One_Time_Token>;
  /** fetch data from the table in a streaming manner: "auth.one_time_token" */
  auth_one_time_token_stream: Array<Auth_One_Time_Token>;
  /** fetch data from the table: "bikecenter_user_info" */
  bikecenter_user_info: Array<Bikecenter_User_Info>;
  /** fetch aggregated fields from the table: "bikecenter_user_info" */
  bikecenter_user_info_aggregate: Bikecenter_User_Info_Aggregate;
  /** fetch data from the table in a streaming manner: "bikecenter_user_info" */
  bikecenter_user_info_stream: Array<Bikecenter_User_Info>;
  /** fetch data from the table: "bikefitting.bikefitting_session" */
  bikefitting_bikefitting_session: Array<Bikefitting_Bikefitting_Session>;
  /** fetch aggregated fields from the table: "bikefitting.bikefitting_session" */
  bikefitting_bikefitting_session_aggregate: Bikefitting_Bikefitting_Session_Aggregate;
  /** fetch data from the table: "bikefitting.bikefitting_session" using primary key columns */
  bikefitting_bikefitting_session_by_pk?: Maybe<Bikefitting_Bikefitting_Session>;
  /** fetch data from the table in a streaming manner: "bikefitting.bikefitting_session" */
  bikefitting_bikefitting_session_stream: Array<Bikefitting_Bikefitting_Session>;
  /** fetch data from the table: "commission.account" */
  commission_account: Array<Commission_Account>;
  /** fetch aggregated fields from the table: "commission.account" */
  commission_account_aggregate: Commission_Account_Aggregate;
  /** fetch data from the table: "commission.account_balance" */
  commission_account_balance: Array<Commission_Account_Balance>;
  /** fetch aggregated fields from the table: "commission.account_balance" */
  commission_account_balance_aggregate: Commission_Account_Balance_Aggregate;
  /** fetch data from the table in a streaming manner: "commission.account_balance" */
  commission_account_balance_stream: Array<Commission_Account_Balance>;
  /** fetch data from the table: "commission.account" using primary key columns */
  commission_account_by_pk?: Maybe<Commission_Account>;
  /** fetch data from the table in a streaming manner: "commission.account" */
  commission_account_stream: Array<Commission_Account>;
  /** fetch data from the table: "commission.boost" */
  commission_boost: Array<Commission_Boost>;
  /** fetch aggregated fields from the table: "commission.boost" */
  commission_boost_aggregate: Commission_Boost_Aggregate;
  /** fetch data from the table: "commission.boost" using primary key columns */
  commission_boost_by_pk?: Maybe<Commission_Boost>;
  /** fetch data from the table in a streaming manner: "commission.boost" */
  commission_boost_stream: Array<Commission_Boost>;
  /** fetch data from the table: "commission.detailed_account_balance" */
  commission_detailed_account_balance: Array<Commission_Detailed_Account_Balance>;
  /** fetch aggregated fields from the table: "commission.detailed_account_balance" */
  commission_detailed_account_balance_aggregate: Commission_Detailed_Account_Balance_Aggregate;
  /** fetch data from the table in a streaming manner: "commission.detailed_account_balance" */
  commission_detailed_account_balance_stream: Array<Commission_Detailed_Account_Balance>;
  /** fetch data from the table: "commission.eligible_answers" */
  commission_eligible_answers: Array<Commission_Eligible_Answers>;
  /** fetch aggregated fields from the table: "commission.eligible_answers" */
  commission_eligible_answers_aggregate: Commission_Eligible_Answers_Aggregate;
  /** fetch data from the table in a streaming manner: "commission.eligible_answers" */
  commission_eligible_answers_stream: Array<Commission_Eligible_Answers>;
  /** fetch data from the table: "commission.eligible_orders" */
  commission_eligible_orders: Array<Commission_Eligible_Orders>;
  /** fetch aggregated fields from the table: "commission.eligible_orders" */
  commission_eligible_orders_aggregate: Commission_Eligible_Orders_Aggregate;
  /** fetch data from the table in a streaming manner: "commission.eligible_orders" */
  commission_eligible_orders_stream: Array<Commission_Eligible_Orders>;
  /** fetch data from the table: "commission.payout_log" */
  commission_payout_log: Array<Commission_Payout_Log>;
  /** fetch aggregated fields from the table: "commission.payout_log" */
  commission_payout_log_aggregate: Commission_Payout_Log_Aggregate;
  /** fetch data from the table: "commission.payout_log" using primary key columns */
  commission_payout_log_by_pk?: Maybe<Commission_Payout_Log>;
  /** fetch data from the table in a streaming manner: "commission.payout_log" */
  commission_payout_log_stream: Array<Commission_Payout_Log>;
  /** fetch data from the table: "commission.type" */
  commission_type: Array<Commission_Type>;
  /** fetch aggregated fields from the table: "commission.type" */
  commission_type_aggregate: Commission_Type_Aggregate;
  /** fetch data from the table: "commission.type" using primary key columns */
  commission_type_by_pk?: Maybe<Commission_Type>;
  /** fetch data from the table in a streaming manner: "commission.type" */
  commission_type_stream: Array<Commission_Type>;
  /** fetch data from the table: "commission.user_overview" */
  commission_user_overview: Array<Commission_User_Overview>;
  /** fetch aggregated fields from the table: "commission.user_overview" */
  commission_user_overview_aggregate: Commission_User_Overview_Aggregate;
  /** fetch data from the table in a streaming manner: "commission.user_overview" */
  commission_user_overview_stream: Array<Commission_User_Overview>;
  /** fetch data from the table: "commission.vendor_overview" */
  commission_vendor_overview: Array<Commission_Vendor_Overview>;
  /** fetch aggregated fields from the table: "commission.vendor_overview" */
  commission_vendor_overview_aggregate: Commission_Vendor_Overview_Aggregate;
  /** fetch data from the table in a streaming manner: "commission.vendor_overview" */
  commission_vendor_overview_stream: Array<Commission_Vendor_Overview>;
  /** fetch data from the table: "commission.webshop_data" */
  commission_webshop_data: Array<Commission_Webshop_Data>;
  /** fetch aggregated fields from the table: "commission.webshop_data" */
  commission_webshop_data_aggregate: Commission_Webshop_Data_Aggregate;
  /** fetch data from the table: "commission.webshop_data" using primary key columns */
  commission_webshop_data_by_pk?: Maybe<Commission_Webshop_Data>;
  /** fetch data from the table in a streaming manner: "commission.webshop_data" */
  commission_webshop_data_stream: Array<Commission_Webshop_Data>;
  /** fetch data from the table: "content.chapters" */
  content_chapters: Array<Content_Chapters>;
  /** fetch aggregated fields from the table: "content.chapters" */
  content_chapters_aggregate: Content_Chapters_Aggregate;
  /** fetch data from the table: "content.chapters" using primary key columns */
  content_chapters_by_pk?: Maybe<Content_Chapters>;
  /** fetch data from the table in a streaming manner: "content.chapters" */
  content_chapters_stream: Array<Content_Chapters>;
  /** fetch data from the table: "content.choices" */
  content_choices: Array<Content_Choices>;
  /** fetch aggregated fields from the table: "content.choices" */
  content_choices_aggregate: Content_Choices_Aggregate;
  /** fetch data from the table: "content.choices" using primary key columns */
  content_choices_by_pk?: Maybe<Content_Choices>;
  /** fetch data from the table in a streaming manner: "content.choices" */
  content_choices_stream: Array<Content_Choices>;
  /** fetch data from the table: "content.contactpoints" */
  content_contactpoints: Array<Content_Contactpoints>;
  /** fetch aggregated fields from the table: "content.contactpoints" */
  content_contactpoints_aggregate: Content_Contactpoints_Aggregate;
  /** fetch data from the table: "content.contactpoints" using primary key columns */
  content_contactpoints_by_pk?: Maybe<Content_Contactpoints>;
  /** fetch data from the table in a streaming manner: "content.contactpoints" */
  content_contactpoints_stream: Array<Content_Contactpoints>;
  /** fetch data from the table: "content.lection_requirements" */
  content_lection_requirements: Array<Content_Lection_Requirements>;
  /** fetch aggregated fields from the table: "content.lection_requirements" */
  content_lection_requirements_aggregate: Content_Lection_Requirements_Aggregate;
  /** fetch data from the table: "content.lection_requirements" using primary key columns */
  content_lection_requirements_by_pk?: Maybe<Content_Lection_Requirements>;
  /** fetch data from the table: "content.lection_requirements_hierarchical_structure" */
  content_lection_requirements_hierarchical_structure: Array<Content_Lection_Requirements_Hierarchical_Structure>;
  /** fetch aggregated fields from the table: "content.lection_requirements_hierarchical_structure" */
  content_lection_requirements_hierarchical_structure_aggregate: Content_Lection_Requirements_Hierarchical_Structure_Aggregate;
  /** fetch data from the table in a streaming manner: "content.lection_requirements_hierarchical_structure" */
  content_lection_requirements_hierarchical_structure_stream: Array<Content_Lection_Requirements_Hierarchical_Structure>;
  /** fetch data from the table in a streaming manner: "content.lection_requirements" */
  content_lection_requirements_stream: Array<Content_Lection_Requirements>;
  /** fetch data from the table: "content.lections" */
  content_lections: Array<Content_Lections>;
  /** fetch aggregated fields from the table: "content.lections" */
  content_lections_aggregate: Content_Lections_Aggregate;
  /** fetch data from the table: "content.lections" using primary key columns */
  content_lections_by_pk?: Maybe<Content_Lections>;
  /** fetch data from the table in a streaming manner: "content.lections" */
  content_lections_stream: Array<Content_Lections>;
  /** fetch data from the table: "content.levels" */
  content_levels: Array<Content_Levels>;
  /** fetch aggregated fields from the table: "content.levels" */
  content_levels_aggregate: Content_Levels_Aggregate;
  /** fetch data from the table: "content.levels" using primary key columns */
  content_levels_by_pk?: Maybe<Content_Levels>;
  /** fetch data from the table in a streaming manner: "content.levels" */
  content_levels_stream: Array<Content_Levels>;
  /** fetch data from the table: "content.questions" */
  content_questions: Array<Content_Questions>;
  /** fetch aggregated fields from the table: "content.questions" */
  content_questions_aggregate: Content_Questions_Aggregate;
  /** fetch data from the table: "content.questions" using primary key columns */
  content_questions_by_pk?: Maybe<Content_Questions>;
  /** fetch data from the table in a streaming manner: "content.questions" */
  content_questions_stream: Array<Content_Questions>;
  /** fetch data from the table: "content.quiz_type" */
  content_quiz_type: Array<Content_Quiz_Type>;
  /** fetch aggregated fields from the table: "content.quiz_type" */
  content_quiz_type_aggregate: Content_Quiz_Type_Aggregate;
  /** fetch data from the table: "content.quiz_type" using primary key columns */
  content_quiz_type_by_pk?: Maybe<Content_Quiz_Type>;
  /** fetch data from the table in a streaming manner: "content.quiz_type" */
  content_quiz_type_stream: Array<Content_Quiz_Type>;
  /** fetch data from the table: "content.quizzes" */
  content_quizzes: Array<Content_Quizzes>;
  /** fetch aggregated fields from the table: "content.quizzes" */
  content_quizzes_aggregate: Content_Quizzes_Aggregate;
  /** fetch data from the table: "content.quizzes" using primary key columns */
  content_quizzes_by_pk?: Maybe<Content_Quizzes>;
  /** fetch data from the table in a streaming manner: "content.quizzes" */
  content_quizzes_stream: Array<Content_Quizzes>;
  /** fetch data from the table: "country_code" */
  country_code: Array<Country_Code>;
  /** fetch aggregated fields from the table: "country_code" */
  country_code_aggregate: Country_Code_Aggregate;
  /** fetch data from the table: "country_code" using primary key columns */
  country_code_by_pk?: Maybe<Country_Code>;
  /** fetch data from the table in a streaming manner: "country_code" */
  country_code_stream: Array<Country_Code>;
  /** fetch data from the table: "currency" */
  currency: Array<Currency>;
  /** fetch aggregated fields from the table: "currency" */
  currency_aggregate: Currency_Aggregate;
  /** fetch data from the table: "currency" using primary key columns */
  currency_by_pk?: Maybe<Currency>;
  /** fetch data from the table in a streaming manner: "currency" */
  currency_stream: Array<Currency>;
  /** fetch data from the table: "current_user" */
  current_user: Array<Current_User>;
  /** fetch aggregated fields from the table: "current_user" */
  current_user_aggregate: Current_User_Aggregate;
  /** fetch data from the table in a streaming manner: "current_user" */
  current_user_stream: Array<Current_User>;
  /** fetch data from the table: "customer.answer" */
  customer_answer: Array<Customer_Answer>;
  /** fetch aggregated fields from the table: "customer.answer" */
  customer_answer_aggregate: Customer_Answer_Aggregate;
  /** fetch data from the table: "customer.answer_article_event" */
  customer_answer_article_event: Array<Customer_Answer_Article_Event>;
  /** fetch aggregated fields from the table: "customer.answer_article_event" */
  customer_answer_article_event_aggregate: Customer_Answer_Article_Event_Aggregate;
  /** fetch data from the table: "customer.answer_article_event" using primary key columns */
  customer_answer_article_event_by_pk?: Maybe<Customer_Answer_Article_Event>;
  /** fetch data from the table in a streaming manner: "customer.answer_article_event" */
  customer_answer_article_event_stream: Array<Customer_Answer_Article_Event>;
  /** fetch data from the table: "customer.answer" using primary key columns */
  customer_answer_by_pk?: Maybe<Customer_Answer>;
  /** fetch data from the table: "customer.answer_foot_problems" */
  customer_answer_foot_problems: Array<Customer_Answer_Foot_Problems>;
  /** fetch aggregated fields from the table: "customer.answer_foot_problems" */
  customer_answer_foot_problems_aggregate: Customer_Answer_Foot_Problems_Aggregate;
  /** fetch data from the table: "customer.answer_foot_problems" using primary key columns */
  customer_answer_foot_problems_by_pk?: Maybe<Customer_Answer_Foot_Problems>;
  /** fetch data from the table in a streaming manner: "customer.answer_foot_problems" */
  customer_answer_foot_problems_stream: Array<Customer_Answer_Foot_Problems>;
  /** fetch data from the table: "customer.answer_hand_problems" */
  customer_answer_hand_problems: Array<Customer_Answer_Hand_Problems>;
  /** fetch aggregated fields from the table: "customer.answer_hand_problems" */
  customer_answer_hand_problems_aggregate: Customer_Answer_Hand_Problems_Aggregate;
  /** fetch data from the table: "customer.answer_hand_problems" using primary key columns */
  customer_answer_hand_problems_by_pk?: Maybe<Customer_Answer_Hand_Problems>;
  /** fetch data from the table in a streaming manner: "customer.answer_hand_problems" */
  customer_answer_hand_problems_stream: Array<Customer_Answer_Hand_Problems>;
  /** fetch data from the table: "customer.answer_pelvic_problems" */
  customer_answer_pelvic_problems: Array<Customer_Answer_Pelvic_Problems>;
  /** fetch aggregated fields from the table: "customer.answer_pelvic_problems" */
  customer_answer_pelvic_problems_aggregate: Customer_Answer_Pelvic_Problems_Aggregate;
  /** fetch data from the table: "customer.answer_pelvic_problems" using primary key columns */
  customer_answer_pelvic_problems_by_pk?: Maybe<Customer_Answer_Pelvic_Problems>;
  /** fetch data from the table in a streaming manner: "customer.answer_pelvic_problems" */
  customer_answer_pelvic_problems_stream: Array<Customer_Answer_Pelvic_Problems>;
  /** fetch data from the table: "customer.answer_results" */
  customer_answer_results: Array<Customer_Answer_Results>;
  /** fetch aggregated fields from the table: "customer.answer_results" */
  customer_answer_results_aggregate: Customer_Answer_Results_Aggregate;
  /** fetch data from the table: "customer.answer_results" using primary key columns */
  customer_answer_results_by_pk?: Maybe<Customer_Answer_Results>;
  /** fetch data from the table in a streaming manner: "customer.answer_results" */
  customer_answer_results_stream: Array<Customer_Answer_Results>;
  /** fetch data from the table in a streaming manner: "customer.answer" */
  customer_answer_stream: Array<Customer_Answer>;
  /** fetch data from the table: "customer_dashboard.event" */
  customer_dashboard_event: Array<Customer_Dashboard_Event>;
  /** fetch aggregated fields from the table: "customer_dashboard.event" */
  customer_dashboard_event_aggregate: Customer_Dashboard_Event_Aggregate;
  /** fetch data from the table: "customer_dashboard.event" using primary key columns */
  customer_dashboard_event_by_pk?: Maybe<Customer_Dashboard_Event>;
  /** fetch data from the table: "customer_dashboard.event_kind" */
  customer_dashboard_event_kind: Array<Customer_Dashboard_Event_Kind>;
  /** fetch aggregated fields from the table: "customer_dashboard.event_kind" */
  customer_dashboard_event_kind_aggregate: Customer_Dashboard_Event_Kind_Aggregate;
  /** fetch data from the table: "customer_dashboard.event_kind" using primary key columns */
  customer_dashboard_event_kind_by_pk?: Maybe<Customer_Dashboard_Event_Kind>;
  /** fetch data from the table in a streaming manner: "customer_dashboard.event_kind" */
  customer_dashboard_event_kind_stream: Array<Customer_Dashboard_Event_Kind>;
  /** fetch data from the table in a streaming manner: "customer_dashboard.event" */
  customer_dashboard_event_stream: Array<Customer_Dashboard_Event>;
  /** fetch data from the table: "customer_dashboard.page_visit" */
  customer_dashboard_page_visit: Array<Customer_Dashboard_Page_Visit>;
  /** fetch aggregated fields from the table: "customer_dashboard.page_visit" */
  customer_dashboard_page_visit_aggregate: Customer_Dashboard_Page_Visit_Aggregate;
  /** fetch data from the table in a streaming manner: "customer_dashboard.page_visit" */
  customer_dashboard_page_visit_stream: Array<Customer_Dashboard_Page_Visit>;
  /** fetch data from the table: "customer.deleted_answers" */
  customer_deleted_answers: Array<Customer_Deleted_Answers>;
  /** fetch aggregated fields from the table: "customer.deleted_answers" */
  customer_deleted_answers_aggregate: Customer_Deleted_Answers_Aggregate;
  /** fetch data from the table: "customer.deleted_answers" using primary key columns */
  customer_deleted_answers_by_pk?: Maybe<Customer_Deleted_Answers>;
  /** fetch data from the table in a streaming manner: "customer.deleted_answers" */
  customer_deleted_answers_stream: Array<Customer_Deleted_Answers>;
  /** fetch data from the table: "customer.emails" */
  customer_emails: Array<Customer_Emails>;
  /** fetch aggregated fields from the table: "customer.emails" */
  customer_emails_aggregate: Customer_Emails_Aggregate;
  /** fetch data from the table: "customer.emails" using primary key columns */
  customer_emails_by_pk?: Maybe<Customer_Emails>;
  /** fetch data from the table in a streaming manner: "customer.emails" */
  customer_emails_stream: Array<Customer_Emails>;
  /** fetch data from the table: "customer.registration" */
  customer_registration: Array<Customer_Registration>;
  /** fetch aggregated fields from the table: "customer.registration" */
  customer_registration_aggregate: Customer_Registration_Aggregate;
  /** fetch data from the table: "customer.registration" using primary key columns */
  customer_registration_by_pk?: Maybe<Customer_Registration>;
  /** fetch data from the table in a streaming manner: "customer.registration" */
  customer_registration_stream: Array<Customer_Registration>;
  /** fetch data from the table: "customer.signature" */
  customer_signature: Array<Customer_Signature>;
  /** fetch aggregated fields from the table: "customer.signature" */
  customer_signature_aggregate: Customer_Signature_Aggregate;
  /** fetch data from the table: "customer.signature" using primary key columns */
  customer_signature_by_pk?: Maybe<Customer_Signature>;
  /** fetch data from the table in a streaming manner: "customer.signature" */
  customer_signature_stream: Array<Customer_Signature>;
  /** fetch data from the table: "customer.voucher" */
  customer_voucher: Array<Customer_Voucher>;
  /** fetch aggregated fields from the table: "customer.voucher" */
  customer_voucher_aggregate: Customer_Voucher_Aggregate;
  /** fetch data from the table: "customer.voucher" using primary key columns */
  customer_voucher_by_pk?: Maybe<Customer_Voucher>;
  /** fetch data from the table in a streaming manner: "customer.voucher" */
  customer_voucher_stream: Array<Customer_Voucher>;
  /** fetch data from the table: "customer.web_questionnaire_completion" */
  customer_web_questionnaire_completion: Array<Customer_Web_Questionnaire_Completion>;
  /** fetch aggregated fields from the table: "customer.web_questionnaire_completion" */
  customer_web_questionnaire_completion_aggregate: Customer_Web_Questionnaire_Completion_Aggregate;
  /** fetch data from the table: "customer.web_questionnaire_completion" using primary key columns */
  customer_web_questionnaire_completion_by_pk?: Maybe<Customer_Web_Questionnaire_Completion>;
  /** fetch data from the table in a streaming manner: "customer.web_questionnaire_completion" */
  customer_web_questionnaire_completion_stream: Array<Customer_Web_Questionnaire_Completion>;
  /** An array relationship */
  cv_body: Array<Cv_Body>;
  /** An aggregate relationship */
  cv_body_aggregate: Cv_Body_Aggregate;
  /** fetch data from the table: "cv.body" using primary key columns */
  cv_body_by_pk?: Maybe<Cv_Body>;
  /** fetch data from the table in a streaming manner: "cv.body" */
  cv_body_stream: Array<Cv_Body>;
  /** An array relationship */
  cv_handmarks: Array<Cv_Handmarks>;
  /** An aggregate relationship */
  cv_handmarks_aggregate: Cv_Handmarks_Aggregate;
  /** fetch data from the table: "cv.handmarks" using primary key columns */
  cv_handmarks_by_pk?: Maybe<Cv_Handmarks>;
  /** fetch data from the table in a streaming manner: "cv.handmarks" */
  cv_handmarks_stream: Array<Cv_Handmarks>;
  /** An array relationship */
  cv_sitbones: Array<Cv_Sitbones>;
  /** An aggregate relationship */
  cv_sitbones_aggregate: Cv_Sitbones_Aggregate;
  /** fetch data from the table: "cv.sitbones" using primary key columns */
  cv_sitbones_by_pk?: Maybe<Cv_Sitbones>;
  /** fetch data from the table in a streaming manner: "cv.sitbones" */
  cv_sitbones_stream: Array<Cv_Sitbones>;
  /** fetch data from the table: "distributor" */
  distributor: Array<Distributor>;
  /** fetch aggregated fields from the table: "distributor" */
  distributor_aggregate: Distributor_Aggregate;
  /** fetch data from the table: "distributor" using primary key columns */
  distributor_by_pk?: Maybe<Distributor>;
  /** fetch data from the table in a streaming manner: "distributor" */
  distributor_stream: Array<Distributor>;
  /** fetch data from the table: "distributors" */
  distributors: Array<Distributors>;
  /** fetch aggregated fields from the table: "distributors" */
  distributors_aggregate: Distributors_Aggregate;
  /** fetch data from the table: "distributors" using primary key columns */
  distributors_by_pk?: Maybe<Distributors>;
  /** fetch data from the table in a streaming manner: "distributors" */
  distributors_stream: Array<Distributors>;
  /** fetch data from the table: "event_enums.article_event" */
  event_enums_article_event: Array<Event_Enums_Article_Event>;
  /** fetch aggregated fields from the table: "event_enums.article_event" */
  event_enums_article_event_aggregate: Event_Enums_Article_Event_Aggregate;
  /** fetch data from the table: "event_enums.article_event" using primary key columns */
  event_enums_article_event_by_pk?: Maybe<Event_Enums_Article_Event>;
  /** fetch data from the table in a streaming manner: "event_enums.article_event" */
  event_enums_article_event_stream: Array<Event_Enums_Article_Event>;
  /** fetch data from the table: "event_enums.event_source" */
  event_enums_event_source: Array<Event_Enums_Event_Source>;
  /** fetch aggregated fields from the table: "event_enums.event_source" */
  event_enums_event_source_aggregate: Event_Enums_Event_Source_Aggregate;
  /** fetch data from the table: "event_enums.event_source" using primary key columns */
  event_enums_event_source_by_pk?: Maybe<Event_Enums_Event_Source>;
  /** fetch data from the table in a streaming manner: "event_enums.event_source" */
  event_enums_event_source_stream: Array<Event_Enums_Event_Source>;
  /** fetch data from the table: "event_enums.log_level" */
  event_enums_log_level: Array<Event_Enums_Log_Level>;
  /** fetch aggregated fields from the table: "event_enums.log_level" */
  event_enums_log_level_aggregate: Event_Enums_Log_Level_Aggregate;
  /** fetch data from the table: "event_enums.log_level" using primary key columns */
  event_enums_log_level_by_pk?: Maybe<Event_Enums_Log_Level>;
  /** fetch data from the table in a streaming manner: "event_enums.log_level" */
  event_enums_log_level_stream: Array<Event_Enums_Log_Level>;
  /** fetch data from the table: "event_log.event_types" */
  event_log_event_types: Array<Event_Log_Event_Types>;
  /** fetch aggregated fields from the table: "event_log.event_types" */
  event_log_event_types_aggregate: Event_Log_Event_Types_Aggregate;
  /** fetch data from the table: "event_log.event_types" using primary key columns */
  event_log_event_types_by_pk?: Maybe<Event_Log_Event_Types>;
  /** fetch data from the table in a streaming manner: "event_log.event_types" */
  event_log_event_types_stream: Array<Event_Log_Event_Types>;
  /** fetch data from the table: "event_log.events" */
  event_log_events: Array<Event_Log_Events>;
  /** fetch aggregated fields from the table: "event_log.events" */
  event_log_events_aggregate: Event_Log_Events_Aggregate;
  /** fetch data from the table: "event_log.events" using primary key columns */
  event_log_events_by_pk?: Maybe<Event_Log_Events>;
  /** fetch data from the table in a streaming manner: "event_log.events" */
  event_log_events_stream: Array<Event_Log_Events>;
  /** fetch data from the table: "frontend.vendor_support" */
  frontend_vendor_support: Array<Frontend_Vendor_Support>;
  /** fetch aggregated fields from the table: "frontend.vendor_support" */
  frontend_vendor_support_aggregate: Frontend_Vendor_Support_Aggregate;
  /** fetch data from the table: "frontend.vendor_support" using primary key columns */
  frontend_vendor_support_by_pk?: Maybe<Frontend_Vendor_Support>;
  /** fetch data from the table in a streaming manner: "frontend.vendor_support" */
  frontend_vendor_support_stream: Array<Frontend_Vendor_Support>;
  /** fetch data from the table: "health" */
  health: Array<Health>;
  /** fetch aggregated fields from the table: "health" */
  health_aggregate: Health_Aggregate;
  /** fetch data from the table in a streaming manner: "health" */
  health_stream: Array<Health>;
  /** fetch data from the table: "iframe.feedback" */
  iframe_feedback: Array<Iframe_Feedback>;
  /** fetch aggregated fields from the table: "iframe.feedback" */
  iframe_feedback_aggregate: Iframe_Feedback_Aggregate;
  /** fetch data from the table: "iframe.feedback" using primary key columns */
  iframe_feedback_by_pk?: Maybe<Iframe_Feedback>;
  /** fetch data from the table in a streaming manner: "iframe.feedback" */
  iframe_feedback_stream: Array<Iframe_Feedback>;
  /** fetch data from the table: "iframe.newsletter_subscriptions" */
  iframe_newsletter_subscriptions: Array<Iframe_Newsletter_Subscriptions>;
  /** fetch aggregated fields from the table: "iframe.newsletter_subscriptions" */
  iframe_newsletter_subscriptions_aggregate: Iframe_Newsletter_Subscriptions_Aggregate;
  /** fetch data from the table: "iframe.newsletter_subscriptions" using primary key columns */
  iframe_newsletter_subscriptions_by_pk?: Maybe<Iframe_Newsletter_Subscriptions>;
  /** fetch data from the table in a streaming manner: "iframe.newsletter_subscriptions" */
  iframe_newsletter_subscriptions_stream: Array<Iframe_Newsletter_Subscriptions>;
  /** fetch data from the table: "iframe.rating" */
  iframe_rating: Array<Iframe_Rating>;
  /** fetch aggregated fields from the table: "iframe.rating" */
  iframe_rating_aggregate: Iframe_Rating_Aggregate;
  /** fetch data from the table: "iframe.rating" using primary key columns */
  iframe_rating_by_pk?: Maybe<Iframe_Rating>;
  /** fetch data from the table in a streaming manner: "iframe.rating" */
  iframe_rating_stream: Array<Iframe_Rating>;
  /** fetch data from the table: "iqlab_rewards.types" */
  iqlab_rewards_types: Array<Iqlab_Rewards_Types>;
  /** fetch aggregated fields from the table: "iqlab_rewards.types" */
  iqlab_rewards_types_aggregate: Iqlab_Rewards_Types_Aggregate;
  /** fetch data from the table: "iqlab_rewards.types" using primary key columns */
  iqlab_rewards_types_by_pk?: Maybe<Iqlab_Rewards_Types>;
  /** fetch data from the table in a streaming manner: "iqlab_rewards.types" */
  iqlab_rewards_types_stream: Array<Iqlab_Rewards_Types>;
  /** fetch data from the table: "iqlab_rewards.vouchers" */
  iqlab_rewards_vouchers: Array<Iqlab_Rewards_Vouchers>;
  /** fetch aggregated fields from the table: "iqlab_rewards.vouchers" */
  iqlab_rewards_vouchers_aggregate: Iqlab_Rewards_Vouchers_Aggregate;
  /** fetch data from the table: "iqlab_rewards.vouchers" using primary key columns */
  iqlab_rewards_vouchers_by_pk?: Maybe<Iqlab_Rewards_Vouchers>;
  /** fetch data from the table in a streaming manner: "iqlab_rewards.vouchers" */
  iqlab_rewards_vouchers_stream: Array<Iqlab_Rewards_Vouchers>;
  /** fetch data from the table: "measurement.measure" */
  measurement_measure: Array<Measurement_Measure>;
  /** fetch aggregated fields from the table: "measurement.measure" */
  measurement_measure_aggregate: Measurement_Measure_Aggregate;
  /** fetch data from the table: "measurement.measure" using primary key columns */
  measurement_measure_by_pk?: Maybe<Measurement_Measure>;
  /** fetch data from the table in a streaming manner: "measurement.measure" */
  measurement_measure_stream: Array<Measurement_Measure>;
  /** fetch data from the table: "measurement.measure_type" */
  measurement_measure_type: Array<Measurement_Measure_Type>;
  /** fetch aggregated fields from the table: "measurement.measure_type" */
  measurement_measure_type_aggregate: Measurement_Measure_Type_Aggregate;
  /** fetch data from the table: "measurement.measure_type" using primary key columns */
  measurement_measure_type_by_pk?: Maybe<Measurement_Measure_Type>;
  /** fetch data from the table in a streaming manner: "measurement.measure_type" */
  measurement_measure_type_stream: Array<Measurement_Measure_Type>;
  /** fetch data from the table: "measurement.session_source" */
  measurement_session_source: Array<Measurement_Session_Source>;
  /** fetch aggregated fields from the table: "measurement.session_source" */
  measurement_session_source_aggregate: Measurement_Session_Source_Aggregate;
  /** fetch data from the table: "measurement.session_source" using primary key columns */
  measurement_session_source_by_pk?: Maybe<Measurement_Session_Source>;
  /** fetch data from the table in a streaming manner: "measurement.session_source" */
  measurement_session_source_stream: Array<Measurement_Session_Source>;
  /** fetch data from the table: "measurement.session_type" */
  measurement_session_type: Array<Measurement_Session_Type>;
  /** fetch aggregated fields from the table: "measurement.session_type" */
  measurement_session_type_aggregate: Measurement_Session_Type_Aggregate;
  /** fetch data from the table: "measurement.session_type" using primary key columns */
  measurement_session_type_by_pk?: Maybe<Measurement_Session_Type>;
  /** fetch data from the table in a streaming manner: "measurement.session_type" */
  measurement_session_type_stream: Array<Measurement_Session_Type>;
  /** fetch data from the table: "measurement.sessions" */
  measurement_sessions: Array<Measurement_Sessions>;
  /** fetch aggregated fields from the table: "measurement.sessions" */
  measurement_sessions_aggregate: Measurement_Sessions_Aggregate;
  /** fetch data from the table: "measurement.sessions" using primary key columns */
  measurement_sessions_by_pk?: Maybe<Measurement_Sessions>;
  /** fetch data from the table in a streaming manner: "measurement.sessions" */
  measurement_sessions_stream: Array<Measurement_Sessions>;
  /** fetch data from the table: "metric.answer_location" */
  metric_answer_location: Array<Metric_Answer_Location>;
  /** fetch aggregated fields from the table: "metric.answer_location" */
  metric_answer_location_aggregate: Metric_Answer_Location_Aggregate;
  /** fetch data from the table: "metric.answer_location" using primary key columns */
  metric_answer_location_by_pk?: Maybe<Metric_Answer_Location>;
  /** fetch data from the table in a streaming manner: "metric.answer_location" */
  metric_answer_location_stream: Array<Metric_Answer_Location>;
  /** fetch data from the table: "metric.app_events" */
  metric_app_events: Array<Metric_App_Events>;
  /** fetch aggregated fields from the table: "metric.app_events" */
  metric_app_events_aggregate: Metric_App_Events_Aggregate;
  /** fetch data from the table: "metric.app_events" using primary key columns */
  metric_app_events_by_pk?: Maybe<Metric_App_Events>;
  /** fetch data from the table in a streaming manner: "metric.app_events" */
  metric_app_events_stream: Array<Metric_App_Events>;
  /** fetch data from the table: "metric.avg_questionnaire_count_30_days" */
  metric_avg_questionnaire_count_30_days: Array<Metric_Avg_Questionnaire_Count_30_Days>;
  /** fetch aggregated fields from the table: "metric.avg_questionnaire_count_30_days" */
  metric_avg_questionnaire_count_30_days_aggregate: Metric_Avg_Questionnaire_Count_30_Days_Aggregate;
  /** fetch data from the table in a streaming manner: "metric.avg_questionnaire_count_30_days" */
  metric_avg_questionnaire_count_30_days_stream: Array<Metric_Avg_Questionnaire_Count_30_Days>;
  /** fetch data from the table: "metric.max_questionnaires_with_day" */
  metric_max_questionnaires_with_day: Array<Metric_Max_Questionnaires_With_Day>;
  /** fetch aggregated fields from the table: "metric.max_questionnaires_with_day" */
  metric_max_questionnaires_with_day_aggregate: Metric_Max_Questionnaires_With_Day_Aggregate;
  /** fetch data from the table in a streaming manner: "metric.max_questionnaires_with_day" */
  metric_max_questionnaires_with_day_stream: Array<Metric_Max_Questionnaires_With_Day>;
  /** fetch data from the table: "metric.question_duration" */
  metric_question_duration: Array<Metric_Question_Duration>;
  /** fetch aggregated fields from the table: "metric.question_duration" */
  metric_question_duration_aggregate: Metric_Question_Duration_Aggregate;
  /** fetch data from the table: "metric.question_duration" using primary key columns */
  metric_question_duration_by_pk?: Maybe<Metric_Question_Duration>;
  /** fetch data from the table in a streaming manner: "metric.question_duration" */
  metric_question_duration_stream: Array<Metric_Question_Duration>;
  /** fetch data from the table: "metric.questionnaires_per_vendor_and_year" */
  metric_questionnaires_per_vendor_and_year: Array<Metric_Questionnaires_Per_Vendor_And_Year>;
  /** fetch aggregated fields from the table: "metric.questionnaires_per_vendor_and_year" */
  metric_questionnaires_per_vendor_and_year_aggregate: Metric_Questionnaires_Per_Vendor_And_Year_Aggregate;
  /** fetch data from the table in a streaming manner: "metric.questionnaires_per_vendor_and_year" */
  metric_questionnaires_per_vendor_and_year_stream: Array<Metric_Questionnaires_Per_Vendor_And_Year>;
  /** fetch data from the table: "metric.usage_information" */
  metric_usage_information: Array<Metric_Usage_Information>;
  /** fetch aggregated fields from the table: "metric.usage_information" */
  metric_usage_information_aggregate: Metric_Usage_Information_Aggregate;
  /** fetch data from the table: "metric.usage_information" using primary key columns */
  metric_usage_information_by_pk?: Maybe<Metric_Usage_Information>;
  /** fetch data from the table in a streaming manner: "metric.usage_information" */
  metric_usage_information_stream: Array<Metric_Usage_Information>;
  /** fetch data from the table: "metric.user_ranking" */
  metric_user_ranking: Array<Metric_User_Ranking>;
  /** fetch aggregated fields from the table: "metric.user_ranking" */
  metric_user_ranking_aggregate: Metric_User_Ranking_Aggregate;
  /** fetch data from the table in a streaming manner: "metric.user_ranking" */
  metric_user_ranking_stream: Array<Metric_User_Ranking>;
  /** fetch data from the table: "onboarding.emails" */
  onboarding_emails: Array<Onboarding_Emails>;
  /** fetch aggregated fields from the table: "onboarding.emails" */
  onboarding_emails_aggregate: Onboarding_Emails_Aggregate;
  /** fetch data from the table: "onboarding.emails" using primary key columns */
  onboarding_emails_by_pk?: Maybe<Onboarding_Emails>;
  /** fetch data from the table in a streaming manner: "onboarding.emails" */
  onboarding_emails_stream: Array<Onboarding_Emails>;
  /** fetch data from the table: "organisation" */
  organisation: Array<Organisation>;
  /** fetch aggregated fields from the table: "organisation" */
  organisation_aggregate: Organisation_Aggregate;
  /** fetch data from the table: "organisation" using primary key columns */
  organisation_by_pk?: Maybe<Organisation>;
  /** fetch data from the table in a streaming manner: "organisation" */
  organisation_stream: Array<Organisation>;
  /** fetch data from the table: "preferences.user_preferences" */
  preferences_user_preferences: Array<Preferences_User_Preferences>;
  /** fetch aggregated fields from the table: "preferences.user_preferences" */
  preferences_user_preferences_aggregate: Preferences_User_Preferences_Aggregate;
  /** fetch data from the table: "preferences.user_preferences" using primary key columns */
  preferences_user_preferences_by_pk?: Maybe<Preferences_User_Preferences>;
  /** fetch data from the table in a streaming manner: "preferences.user_preferences" */
  preferences_user_preferences_stream: Array<Preferences_User_Preferences>;
  /** fetch data from the table: "preferences.vendor_preferences" */
  preferences_vendor_preferences: Array<Preferences_Vendor_Preferences>;
  /** fetch aggregated fields from the table: "preferences.vendor_preferences" */
  preferences_vendor_preferences_aggregate: Preferences_Vendor_Preferences_Aggregate;
  /** fetch data from the table: "preferences.vendor_preferences" using primary key columns */
  preferences_vendor_preferences_by_pk?: Maybe<Preferences_Vendor_Preferences>;
  /** fetch data from the table in a streaming manner: "preferences.vendor_preferences" */
  preferences_vendor_preferences_stream: Array<Preferences_Vendor_Preferences>;
  /** fetch data from the table: "progress.chapter_overview" */
  progress_chapter_overview: Array<Progress_Chapter_Overview>;
  /** fetch aggregated fields from the table: "progress.chapter_overview" */
  progress_chapter_overview_aggregate: Progress_Chapter_Overview_Aggregate;
  /** fetch data from the table in a streaming manner: "progress.chapter_overview" */
  progress_chapter_overview_stream: Array<Progress_Chapter_Overview>;
  /** fetch data from the table: "progress.completed_chapters" */
  progress_completed_chapters: Array<Progress_Completed_Chapters>;
  /** fetch aggregated fields from the table: "progress.completed_chapters" */
  progress_completed_chapters_aggregate: Progress_Completed_Chapters_Aggregate;
  /** fetch data from the table: "progress.completed_chapters" using primary key columns */
  progress_completed_chapters_by_pk?: Maybe<Progress_Completed_Chapters>;
  /** fetch data from the table in a streaming manner: "progress.completed_chapters" */
  progress_completed_chapters_stream: Array<Progress_Completed_Chapters>;
  /** fetch data from the table: "progress.completed_contactpoints" */
  progress_completed_contactpoints: Array<Progress_Completed_Contactpoints>;
  /** fetch aggregated fields from the table: "progress.completed_contactpoints" */
  progress_completed_contactpoints_aggregate: Progress_Completed_Contactpoints_Aggregate;
  /** fetch data from the table: "progress.completed_contactpoints" using primary key columns */
  progress_completed_contactpoints_by_pk?: Maybe<Progress_Completed_Contactpoints>;
  /** fetch data from the table in a streaming manner: "progress.completed_contactpoints" */
  progress_completed_contactpoints_stream: Array<Progress_Completed_Contactpoints>;
  /** fetch data from the table: "progress.completed_lections" */
  progress_completed_lections: Array<Progress_Completed_Lections>;
  /** fetch aggregated fields from the table: "progress.completed_lections" */
  progress_completed_lections_aggregate: Progress_Completed_Lections_Aggregate;
  /** fetch data from the table: "progress.completed_lections" using primary key columns */
  progress_completed_lections_by_pk?: Maybe<Progress_Completed_Lections>;
  /** fetch data from the table in a streaming manner: "progress.completed_lections" */
  progress_completed_lections_stream: Array<Progress_Completed_Lections>;
  /** fetch data from the table: "progress.contactpoint_overview" */
  progress_contactpoint_overview: Array<Progress_Contactpoint_Overview>;
  /** fetch aggregated fields from the table: "progress.contactpoint_overview" */
  progress_contactpoint_overview_aggregate: Progress_Contactpoint_Overview_Aggregate;
  /** fetch data from the table in a streaming manner: "progress.contactpoint_overview" */
  progress_contactpoint_overview_stream: Array<Progress_Contactpoint_Overview>;
  /** fetch data from the table: "progress.lection_overview" */
  progress_lection_overview: Array<Progress_Lection_Overview>;
  /** fetch aggregated fields from the table: "progress.lection_overview" */
  progress_lection_overview_aggregate: Progress_Lection_Overview_Aggregate;
  /** fetch data from the table in a streaming manner: "progress.lection_overview" */
  progress_lection_overview_stream: Array<Progress_Lection_Overview>;
  /** fetch data from the table: "recommendation.own_articles" */
  recommendation_own_articles: Array<Recommendation_Own_Articles>;
  /** fetch aggregated fields from the table: "recommendation.own_articles" */
  recommendation_own_articles_aggregate: Recommendation_Own_Articles_Aggregate;
  /** fetch data from the table: "recommendation.own_articles" using primary key columns */
  recommendation_own_articles_by_pk?: Maybe<Recommendation_Own_Articles>;
  /** fetch data from the table in a streaming manner: "recommendation.own_articles" */
  recommendation_own_articles_stream: Array<Recommendation_Own_Articles>;
  /** fetch data from the table: "recommendation.return_search_article" */
  recommendation_return_search_article: Array<Recommendation_Return_Search_Article>;
  /** fetch aggregated fields from the table: "recommendation.return_search_article" */
  recommendation_return_search_article_aggregate: Recommendation_Return_Search_Article_Aggregate;
  /** fetch data from the table: "recommendation.return_search_article" using primary key columns */
  recommendation_return_search_article_by_pk?: Maybe<Recommendation_Return_Search_Article>;
  /** fetch data from the table in a streaming manner: "recommendation.return_search_article" */
  recommendation_return_search_article_stream: Array<Recommendation_Return_Search_Article>;
  /** execute function "recommendation.search_articles" which returns "recommendation.return_search_article" */
  recommendation_search_articles: Array<Recommendation_Return_Search_Article>;
  /** execute function "recommendation.search_articles" and query aggregates on result of table type "recommendation.return_search_article" */
  recommendation_search_articles_aggregate: Recommendation_Return_Search_Article_Aggregate;
  /** fetch data from the table: "rewards.benefit_category" */
  rewards_benefit_category: Array<Rewards_Benefit_Category>;
  /** fetch aggregated fields from the table: "rewards.benefit_category" */
  rewards_benefit_category_aggregate: Rewards_Benefit_Category_Aggregate;
  /** fetch data from the table: "rewards.benefit_category" using primary key columns */
  rewards_benefit_category_by_pk?: Maybe<Rewards_Benefit_Category>;
  /** fetch data from the table in a streaming manner: "rewards.benefit_category" */
  rewards_benefit_category_stream: Array<Rewards_Benefit_Category>;
  /** fetch data from the table: "rewards.benefits" */
  rewards_benefits: Array<Rewards_Benefits>;
  /** fetch aggregated fields from the table: "rewards.benefits" */
  rewards_benefits_aggregate: Rewards_Benefits_Aggregate;
  /** fetch data from the table: "rewards.benefits" using primary key columns */
  rewards_benefits_by_pk?: Maybe<Rewards_Benefits>;
  /** fetch data from the table in a streaming manner: "rewards.benefits" */
  rewards_benefits_stream: Array<Rewards_Benefits>;
  /** fetch data from the table: "rewards.boosts" */
  rewards_boosts: Array<Rewards_Boosts>;
  /** fetch aggregated fields from the table: "rewards.boosts" */
  rewards_boosts_aggregate: Rewards_Boosts_Aggregate;
  /** fetch data from the table: "rewards.boosts" using primary key columns */
  rewards_boosts_by_pk?: Maybe<Rewards_Boosts>;
  /** fetch data from the table in a streaming manner: "rewards.boosts" */
  rewards_boosts_stream: Array<Rewards_Boosts>;
  /** fetch data from the table: "rewards.internal_ranking" */
  rewards_internal_ranking: Array<Rewards_Internal_Ranking>;
  /** fetch aggregated fields from the table: "rewards.internal_ranking" */
  rewards_internal_ranking_aggregate: Rewards_Internal_Ranking_Aggregate;
  /** fetch data from the table in a streaming manner: "rewards.internal_ranking" */
  rewards_internal_ranking_stream: Array<Rewards_Internal_Ranking>;
  /** fetch data from the table: "rewards.international_ranking" */
  rewards_international_ranking: Array<Rewards_International_Ranking>;
  /** fetch aggregated fields from the table: "rewards.international_ranking" */
  rewards_international_ranking_aggregate: Rewards_International_Ranking_Aggregate;
  /** fetch data from the table in a streaming manner: "rewards.international_ranking" */
  rewards_international_ranking_stream: Array<Rewards_International_Ranking>;
  /** fetch data from the table: "rewards.ranking_balances" */
  rewards_ranking_balances: Array<Rewards_Ranking_Balances>;
  /** fetch aggregated fields from the table: "rewards.ranking_balances" */
  rewards_ranking_balances_aggregate: Rewards_Ranking_Balances_Aggregate;
  /** fetch data from the table in a streaming manner: "rewards.ranking_balances" */
  rewards_ranking_balances_stream: Array<Rewards_Ranking_Balances>;
  /** fetch data from the table: "rewards.reward_balances" */
  rewards_reward_balances: Array<Rewards_Reward_Balances>;
  /** fetch aggregated fields from the table: "rewards.reward_balances" */
  rewards_reward_balances_aggregate: Rewards_Reward_Balances_Aggregate;
  /** fetch data from the table in a streaming manner: "rewards.reward_balances" */
  rewards_reward_balances_stream: Array<Rewards_Reward_Balances>;
  /** fetch data from the table: "rewards.transactions" */
  rewards_transactions: Array<Rewards_Transactions>;
  /** fetch aggregated fields from the table: "rewards.transactions" */
  rewards_transactions_aggregate: Rewards_Transactions_Aggregate;
  /** fetch data from the table: "rewards.transactions" using primary key columns */
  rewards_transactions_by_pk?: Maybe<Rewards_Transactions>;
  /** fetch data from the table in a streaming manner: "rewards.transactions" */
  rewards_transactions_stream: Array<Rewards_Transactions>;
  /** An array relationship */
  sessions: Array<Sessions>;
  /** An aggregate relationship */
  sessions_aggregate: Sessions_Aggregate;
  /** fetch data from the table: "sessions" using primary key columns */
  sessions_by_pk?: Maybe<Sessions>;
  /** fetch data from the table in a streaming manner: "sessions" */
  sessions_stream: Array<Sessions>;
  /** fetch data from the table: "sqbase_event_log.event_types" */
  sqbase_event_log_event_types: Array<Sqbase_Event_Log_Event_Types>;
  /** fetch aggregated fields from the table: "sqbase_event_log.event_types" */
  sqbase_event_log_event_types_aggregate: Sqbase_Event_Log_Event_Types_Aggregate;
  /** fetch data from the table: "sqbase_event_log.event_types" using primary key columns */
  sqbase_event_log_event_types_by_pk?: Maybe<Sqbase_Event_Log_Event_Types>;
  /** fetch data from the table in a streaming manner: "sqbase_event_log.event_types" */
  sqbase_event_log_event_types_stream: Array<Sqbase_Event_Log_Event_Types>;
  /** fetch data from the table: "sqbase_event_log.events" */
  sqbase_event_log_events: Array<Sqbase_Event_Log_Events>;
  /** fetch aggregated fields from the table: "sqbase_event_log.events" */
  sqbase_event_log_events_aggregate: Sqbase_Event_Log_Events_Aggregate;
  /** fetch data from the table: "sqbase_event_log.events" using primary key columns */
  sqbase_event_log_events_by_pk?: Maybe<Sqbase_Event_Log_Events>;
  /** fetch data from the table in a streaming manner: "sqbase_event_log.events" */
  sqbase_event_log_events_stream: Array<Sqbase_Event_Log_Events>;
  /** fetch data from the table: "sqlab_contacts" */
  sqlab_contacts: Array<Sqlab_Contacts>;
  /** fetch aggregated fields from the table: "sqlab_contacts" */
  sqlab_contacts_aggregate: Sqlab_Contacts_Aggregate;
  /** fetch data from the table: "sqlab_contacts" using primary key columns */
  sqlab_contacts_by_pk?: Maybe<Sqlab_Contacts>;
  /** fetch data from the table in a streaming manner: "sqlab_contacts" */
  sqlab_contacts_stream: Array<Sqlab_Contacts>;
  /** fetch data from the table: "support.expiring_boosts" */
  support_expiring_boosts: Array<Support_Expiring_Boosts>;
  /** fetch aggregated fields from the table: "support.expiring_boosts" */
  support_expiring_boosts_aggregate: Support_Expiring_Boosts_Aggregate;
  /** fetch data from the table in a streaming manner: "support.expiring_boosts" */
  support_expiring_boosts_stream: Array<Support_Expiring_Boosts>;
  /** fetch data from the table: "support.follow_up_tasks" */
  support_follow_up_tasks: Array<Support_Follow_Up_Tasks>;
  /** fetch aggregated fields from the table: "support.follow_up_tasks" */
  support_follow_up_tasks_aggregate: Support_Follow_Up_Tasks_Aggregate;
  /** fetch data from the table in a streaming manner: "support.follow_up_tasks" */
  support_follow_up_tasks_stream: Array<Support_Follow_Up_Tasks>;
  /** fetch data from the table: "support.interactions" */
  support_interactions: Array<Support_Interactions>;
  /** fetch aggregated fields from the table: "support.interactions" */
  support_interactions_aggregate: Support_Interactions_Aggregate;
  /** fetch data from the table: "support.interactions" using primary key columns */
  support_interactions_by_pk?: Maybe<Support_Interactions>;
  /** fetch data from the table in a streaming manner: "support.interactions" */
  support_interactions_stream: Array<Support_Interactions>;
  /** fetch data from the table: "support.registrations_without_answers" */
  support_registrations_without_answers: Array<Support_Registrations_Without_Answers>;
  /** fetch aggregated fields from the table: "support.registrations_without_answers" */
  support_registrations_without_answers_aggregate: Support_Registrations_Without_Answers_Aggregate;
  /** fetch data from the table in a streaming manner: "support.registrations_without_answers" */
  support_registrations_without_answers_stream: Array<Support_Registrations_Without_Answers>;
  /** fetch data from the table: "support.registrations_without_progress_two_weeks" */
  support_registrations_without_progress_two_weeks: Array<Support_Registrations_Without_Progress_Two_Weeks>;
  /** fetch aggregated fields from the table: "support.registrations_without_progress_two_weeks" */
  support_registrations_without_progress_two_weeks_aggregate: Support_Registrations_Without_Progress_Two_Weeks_Aggregate;
  /** fetch data from the table in a streaming manner: "support.registrations_without_progress_two_weeks" */
  support_registrations_without_progress_two_weeks_stream: Array<Support_Registrations_Without_Progress_Two_Weeks>;
  /** fetch data from the table: "support.vendor_support" */
  support_vendor_support: Array<Support_Vendor_Support>;
  /** fetch aggregated fields from the table: "support.vendor_support" */
  support_vendor_support_aggregate: Support_Vendor_Support_Aggregate;
  /** fetch data from the table: "support.vendor_support" using primary key columns */
  support_vendor_support_by_pk?: Maybe<Support_Vendor_Support>;
  /** fetch data from the table in a streaming manner: "support.vendor_support" */
  support_vendor_support_stream: Array<Support_Vendor_Support>;
  /** fetch data from the table: "support.vendor_support_type" */
  support_vendor_support_type: Array<Support_Vendor_Support_Type>;
  /** fetch aggregated fields from the table: "support.vendor_support_type" */
  support_vendor_support_type_aggregate: Support_Vendor_Support_Type_Aggregate;
  /** fetch data from the table: "support.vendor_support_type" using primary key columns */
  support_vendor_support_type_by_pk?: Maybe<Support_Vendor_Support_Type>;
  /** fetch data from the table in a streaming manner: "support.vendor_support_type" */
  support_vendor_support_type_stream: Array<Support_Vendor_Support_Type>;
  /** fetch data from the table: "tracker.article_prices" */
  tracker_article_prices: Array<Tracker_Article_Prices>;
  /** fetch aggregated fields from the table: "tracker.article_prices" */
  tracker_article_prices_aggregate: Tracker_Article_Prices_Aggregate;
  /** fetch data from the table: "tracker.article_prices" using primary key columns */
  tracker_article_prices_by_pk?: Maybe<Tracker_Article_Prices>;
  /** fetch data from the table in a streaming manner: "tracker.article_prices" */
  tracker_article_prices_stream: Array<Tracker_Article_Prices>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "user_details" */
  user_details: Array<User_Details>;
  /** fetch aggregated fields from the table: "user_details" */
  user_details_aggregate: User_Details_Aggregate;
  /** fetch data from the table: "user_details" using primary key columns */
  user_details_by_pk?: Maybe<User_Details>;
  /** fetch data from the table in a streaming manner: "user_details" */
  user_details_stream: Array<User_Details>;
  /** fetch data from the table in a streaming manner: "user" */
  user_stream: Array<User>;
};


export type Subscription_RootAggregation_Lectures_Completed_By_Vendor_Per_YearArgs = {
  distinct_on?: InputMaybe<Array<Aggregation_Lectures_Completed_By_Vendor_Per_Year_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregation_Lectures_Completed_By_Vendor_Per_Year_Order_By>>;
  where?: InputMaybe<Aggregation_Lectures_Completed_By_Vendor_Per_Year_Bool_Exp>;
};


export type Subscription_RootAggregation_Lectures_Completed_By_Vendor_Per_Year_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Aggregation_Lectures_Completed_By_Vendor_Per_Year_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregation_Lectures_Completed_By_Vendor_Per_Year_Order_By>>;
  where?: InputMaybe<Aggregation_Lectures_Completed_By_Vendor_Per_Year_Bool_Exp>;
};


export type Subscription_RootAggregation_Lectures_Completed_By_Vendor_Per_Year_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Aggregation_Lectures_Completed_By_Vendor_Per_Year_Stream_Cursor_Input>>;
  where?: InputMaybe<Aggregation_Lectures_Completed_By_Vendor_Per_Year_Bool_Exp>;
};


export type Subscription_RootAggregation_Required_Lections_CountArgs = {
  distinct_on?: InputMaybe<Array<Aggregation_Required_Lections_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregation_Required_Lections_Count_Order_By>>;
  where?: InputMaybe<Aggregation_Required_Lections_Count_Bool_Exp>;
};


export type Subscription_RootAggregation_Required_Lections_Count_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Aggregation_Required_Lections_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregation_Required_Lections_Count_Order_By>>;
  where?: InputMaybe<Aggregation_Required_Lections_Count_Bool_Exp>;
};


export type Subscription_RootAggregation_Required_Lections_Count_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Aggregation_Required_Lections_Count_Stream_Cursor_Input>>;
  where?: InputMaybe<Aggregation_Required_Lections_Count_Bool_Exp>;
};


export type Subscription_RootAggregation_Requirements_OverviewArgs = {
  distinct_on?: InputMaybe<Array<Aggregation_Requirements_Overview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregation_Requirements_Overview_Order_By>>;
  where?: InputMaybe<Aggregation_Requirements_Overview_Bool_Exp>;
};


export type Subscription_RootAggregation_Requirements_Overview_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Aggregation_Requirements_Overview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregation_Requirements_Overview_Order_By>>;
  where?: InputMaybe<Aggregation_Requirements_Overview_Bool_Exp>;
};


export type Subscription_RootAggregation_Requirements_Overview_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Aggregation_Requirements_Overview_Stream_Cursor_Input>>;
  where?: InputMaybe<Aggregation_Requirements_Overview_Bool_Exp>;
};


export type Subscription_RootAggregation_Users_ChaptersArgs = {
  distinct_on?: InputMaybe<Array<Aggregation_Users_Chapters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregation_Users_Chapters_Order_By>>;
  where?: InputMaybe<Aggregation_Users_Chapters_Bool_Exp>;
};


export type Subscription_RootAggregation_Users_Chapters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Aggregation_Users_Chapters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregation_Users_Chapters_Order_By>>;
  where?: InputMaybe<Aggregation_Users_Chapters_Bool_Exp>;
};


export type Subscription_RootAggregation_Users_Chapters_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Aggregation_Users_Chapters_Stream_Cursor_Input>>;
  where?: InputMaybe<Aggregation_Users_Chapters_Bool_Exp>;
};


export type Subscription_RootAggregation_Users_LectionsArgs = {
  distinct_on?: InputMaybe<Array<Aggregation_Users_Lections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregation_Users_Lections_Order_By>>;
  where?: InputMaybe<Aggregation_Users_Lections_Bool_Exp>;
};


export type Subscription_RootAggregation_Users_Lections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Aggregation_Users_Lections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregation_Users_Lections_Order_By>>;
  where?: InputMaybe<Aggregation_Users_Lections_Bool_Exp>;
};


export type Subscription_RootAggregation_Users_Lections_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Aggregation_Users_Lections_Stream_Cursor_Input>>;
  where?: InputMaybe<Aggregation_Users_Lections_Bool_Exp>;
};


export type Subscription_RootAggregation_Users_RequirementsArgs = {
  distinct_on?: InputMaybe<Array<Aggregation_Users_Requirements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregation_Users_Requirements_Order_By>>;
  where?: InputMaybe<Aggregation_Users_Requirements_Bool_Exp>;
};


export type Subscription_RootAggregation_Users_Requirements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Aggregation_Users_Requirements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Aggregation_Users_Requirements_Order_By>>;
  where?: InputMaybe<Aggregation_Users_Requirements_Bool_Exp>;
};


export type Subscription_RootAggregation_Users_Requirements_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Aggregation_Users_Requirements_Stream_Cursor_Input>>;
  where?: InputMaybe<Aggregation_Users_Requirements_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_AllArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_All_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_All_Order_By>>;
  where?: InputMaybe<Answer_Enums_All_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_All_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_All_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_All_Order_By>>;
  where?: InputMaybe<Answer_Enums_All_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_All_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Answer_Enums_All_Stream_Cursor_Input>>;
  where?: InputMaybe<Answer_Enums_All_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_Bicycle_TypeArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Bicycle_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Bicycle_Type_Order_By>>;
  where?: InputMaybe<Answer_Enums_Bicycle_Type_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_Bicycle_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Bicycle_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Bicycle_Type_Order_By>>;
  where?: InputMaybe<Answer_Enums_Bicycle_Type_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_Bicycle_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootAnswer_Enums_Bicycle_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Answer_Enums_Bicycle_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Answer_Enums_Bicycle_Type_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_Driving_DurationArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Driving_Duration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Driving_Duration_Order_By>>;
  where?: InputMaybe<Answer_Enums_Driving_Duration_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_Driving_Duration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Driving_Duration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Driving_Duration_Order_By>>;
  where?: InputMaybe<Answer_Enums_Driving_Duration_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_Driving_Duration_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootAnswer_Enums_Driving_Duration_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Answer_Enums_Driving_Duration_Stream_Cursor_Input>>;
  where?: InputMaybe<Answer_Enums_Driving_Duration_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_Driving_FrequencyArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Driving_Frequency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Driving_Frequency_Order_By>>;
  where?: InputMaybe<Answer_Enums_Driving_Frequency_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_Driving_Frequency_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Driving_Frequency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Driving_Frequency_Order_By>>;
  where?: InputMaybe<Answer_Enums_Driving_Frequency_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_Driving_Frequency_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootAnswer_Enums_Driving_Frequency_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Answer_Enums_Driving_Frequency_Stream_Cursor_Input>>;
  where?: InputMaybe<Answer_Enums_Driving_Frequency_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_E_BikeArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_E_Bike_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_E_Bike_Order_By>>;
  where?: InputMaybe<Answer_Enums_E_Bike_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_E_Bike_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_E_Bike_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_E_Bike_Order_By>>;
  where?: InputMaybe<Answer_Enums_E_Bike_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_E_Bike_By_PkArgs = {
  value: Scalars['Boolean'];
};


export type Subscription_RootAnswer_Enums_E_Bike_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Answer_Enums_E_Bike_Stream_Cursor_Input>>;
  where?: InputMaybe<Answer_Enums_E_Bike_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_Foot_PositionArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Foot_Position_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Foot_Position_Order_By>>;
  where?: InputMaybe<Answer_Enums_Foot_Position_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_Foot_Position_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Foot_Position_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Foot_Position_Order_By>>;
  where?: InputMaybe<Answer_Enums_Foot_Position_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_Foot_Position_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootAnswer_Enums_Foot_Position_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Answer_Enums_Foot_Position_Stream_Cursor_Input>>;
  where?: InputMaybe<Answer_Enums_Foot_Position_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_Foot_ProblemsArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Foot_Problems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Foot_Problems_Order_By>>;
  where?: InputMaybe<Answer_Enums_Foot_Problems_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_Foot_Problems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Foot_Problems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Foot_Problems_Order_By>>;
  where?: InputMaybe<Answer_Enums_Foot_Problems_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_Foot_Problems_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootAnswer_Enums_Foot_Problems_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Answer_Enums_Foot_Problems_Stream_Cursor_Input>>;
  where?: InputMaybe<Answer_Enums_Foot_Problems_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_Foot_TypeArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Foot_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Foot_Type_Order_By>>;
  where?: InputMaybe<Answer_Enums_Foot_Type_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_Foot_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Foot_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Foot_Type_Order_By>>;
  where?: InputMaybe<Answer_Enums_Foot_Type_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_Foot_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootAnswer_Enums_Foot_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Answer_Enums_Foot_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Answer_Enums_Foot_Type_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_Gripping_DistanceArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Gripping_Distance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Gripping_Distance_Order_By>>;
  where?: InputMaybe<Answer_Enums_Gripping_Distance_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_Gripping_Distance_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Gripping_Distance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Gripping_Distance_Order_By>>;
  where?: InputMaybe<Answer_Enums_Gripping_Distance_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_Gripping_Distance_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootAnswer_Enums_Gripping_Distance_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Answer_Enums_Gripping_Distance_Stream_Cursor_Input>>;
  where?: InputMaybe<Answer_Enums_Gripping_Distance_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_Hand_ProblemsArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Hand_Problems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Hand_Problems_Order_By>>;
  where?: InputMaybe<Answer_Enums_Hand_Problems_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_Hand_Problems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Hand_Problems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Hand_Problems_Order_By>>;
  where?: InputMaybe<Answer_Enums_Hand_Problems_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_Hand_Problems_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootAnswer_Enums_Hand_Problems_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Answer_Enums_Hand_Problems_Stream_Cursor_Input>>;
  where?: InputMaybe<Answer_Enums_Hand_Problems_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_Leg_AxisArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Leg_Axis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Leg_Axis_Order_By>>;
  where?: InputMaybe<Answer_Enums_Leg_Axis_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_Leg_Axis_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Leg_Axis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Leg_Axis_Order_By>>;
  where?: InputMaybe<Answer_Enums_Leg_Axis_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_Leg_Axis_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootAnswer_Enums_Leg_Axis_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Answer_Enums_Leg_Axis_Stream_Cursor_Input>>;
  where?: InputMaybe<Answer_Enums_Leg_Axis_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_Pelvic_ProblemsArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Pelvic_Problems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Pelvic_Problems_Order_By>>;
  where?: InputMaybe<Answer_Enums_Pelvic_Problems_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_Pelvic_Problems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Pelvic_Problems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Pelvic_Problems_Order_By>>;
  where?: InputMaybe<Answer_Enums_Pelvic_Problems_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_Pelvic_Problems_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootAnswer_Enums_Pelvic_Problems_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Answer_Enums_Pelvic_Problems_Stream_Cursor_Input>>;
  where?: InputMaybe<Answer_Enums_Pelvic_Problems_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_Seat_PositionArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Seat_Position_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Seat_Position_Order_By>>;
  where?: InputMaybe<Answer_Enums_Seat_Position_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_Seat_Position_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Seat_Position_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Seat_Position_Order_By>>;
  where?: InputMaybe<Answer_Enums_Seat_Position_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_Seat_Position_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootAnswer_Enums_Seat_Position_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Answer_Enums_Seat_Position_Stream_Cursor_Input>>;
  where?: InputMaybe<Answer_Enums_Seat_Position_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_SexArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Sex_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Sex_Order_By>>;
  where?: InputMaybe<Answer_Enums_Sex_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_Sex_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Sex_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Sex_Order_By>>;
  where?: InputMaybe<Answer_Enums_Sex_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_Sex_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootAnswer_Enums_Sex_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Answer_Enums_Sex_Stream_Cursor_Input>>;
  where?: InputMaybe<Answer_Enums_Sex_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_Unmatched_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Unmatched_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Unmatched_Answers_Order_By>>;
  where?: InputMaybe<Answer_Enums_Unmatched_Answers_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_Unmatched_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Unmatched_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Unmatched_Answers_Order_By>>;
  where?: InputMaybe<Answer_Enums_Unmatched_Answers_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_Unmatched_Answers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Answer_Enums_Unmatched_Answers_Stream_Cursor_Input>>;
  where?: InputMaybe<Answer_Enums_Unmatched_Answers_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_Unmatched_EnumsArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Unmatched_Enums_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Unmatched_Enums_Order_By>>;
  where?: InputMaybe<Answer_Enums_Unmatched_Enums_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_Unmatched_Enums_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Answer_Enums_Unmatched_Enums_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answer_Enums_Unmatched_Enums_Order_By>>;
  where?: InputMaybe<Answer_Enums_Unmatched_Enums_Bool_Exp>;
};


export type Subscription_RootAnswer_Enums_Unmatched_Enums_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Answer_Enums_Unmatched_Enums_Stream_Cursor_Input>>;
  where?: InputMaybe<Answer_Enums_Unmatched_Enums_Bool_Exp>;
};


export type Subscription_RootAnswersArgs = {
  distinct_on?: InputMaybe<Array<Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answers_Order_By>>;
  where?: InputMaybe<Answers_Bool_Exp>;
};


export type Subscription_RootAnswers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answers_Order_By>>;
  where?: InputMaybe<Answers_Bool_Exp>;
};


export type Subscription_RootAnswers_By_PkArgs = {
  choice_id: Scalars['Int'];
  session_id: Scalars['uuid'];
};


export type Subscription_RootAnswers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Answers_Stream_Cursor_Input>>;
  where?: InputMaybe<Answers_Bool_Exp>;
};


export type Subscription_RootArticle_GroupArgs = {
  distinct_on?: InputMaybe<Array<Article_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Article_Group_Order_By>>;
  where?: InputMaybe<Article_Group_Bool_Exp>;
};


export type Subscription_RootArticle_Group_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Article_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Article_Group_Order_By>>;
  where?: InputMaybe<Article_Group_Bool_Exp>;
};


export type Subscription_RootArticle_Group_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootArticle_Group_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Article_Group_Stream_Cursor_Input>>;
  where?: InputMaybe<Article_Group_Bool_Exp>;
};


export type Subscription_RootArticle_Information_I18nArgs = {
  distinct_on?: InputMaybe<Array<Article_Information_I18n_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Article_Information_I18n_Order_By>>;
  where?: InputMaybe<Article_Information_I18n_Bool_Exp>;
};


export type Subscription_RootArticle_Information_I18n_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Article_Information_I18n_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Article_Information_I18n_Order_By>>;
  where?: InputMaybe<Article_Information_I18n_Bool_Exp>;
};


export type Subscription_RootArticle_Information_I18n_By_PkArgs = {
  article_id: Scalars['String'];
  language: Country_Code_Enum;
};


export type Subscription_RootArticle_Information_I18n_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Article_Information_I18n_Stream_Cursor_Input>>;
  where?: InputMaybe<Article_Information_I18n_Bool_Exp>;
};


export type Subscription_RootArticle_RankingArgs = {
  distinct_on?: InputMaybe<Array<Article_Ranking_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Article_Ranking_Order_By>>;
  where?: InputMaybe<Article_Ranking_Bool_Exp>;
};


export type Subscription_RootArticle_Ranking_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Article_Ranking_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Article_Ranking_Order_By>>;
  where?: InputMaybe<Article_Ranking_Bool_Exp>;
};


export type Subscription_RootArticle_Ranking_By_PkArgs = {
  answer: Scalars['String'];
  article_id: Scalars['String'];
};


export type Subscription_RootArticle_Ranking_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Article_Ranking_Stream_Cursor_Input>>;
  where?: InputMaybe<Article_Ranking_Bool_Exp>;
};


export type Subscription_RootArticle_TypeArgs = {
  distinct_on?: InputMaybe<Array<Article_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Article_Type_Order_By>>;
  where?: InputMaybe<Article_Type_Bool_Exp>;
};


export type Subscription_RootArticle_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Article_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Article_Type_Order_By>>;
  where?: InputMaybe<Article_Type_Bool_Exp>;
};


export type Subscription_RootArticle_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootArticle_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Article_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Article_Type_Bool_Exp>;
};


export type Subscription_RootAuth_One_Time_TokenArgs = {
  distinct_on?: InputMaybe<Array<Auth_One_Time_Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_One_Time_Token_Order_By>>;
  where?: InputMaybe<Auth_One_Time_Token_Bool_Exp>;
};


export type Subscription_RootAuth_One_Time_Token_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_One_Time_Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_One_Time_Token_Order_By>>;
  where?: InputMaybe<Auth_One_Time_Token_Bool_Exp>;
};


export type Subscription_RootAuth_One_Time_Token_By_PkArgs = {
  value: Scalars['uuid'];
};


export type Subscription_RootAuth_One_Time_Token_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Auth_One_Time_Token_Stream_Cursor_Input>>;
  where?: InputMaybe<Auth_One_Time_Token_Bool_Exp>;
};


export type Subscription_RootBikecenter_User_InfoArgs = {
  distinct_on?: InputMaybe<Array<Bikecenter_User_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bikecenter_User_Info_Order_By>>;
  where?: InputMaybe<Bikecenter_User_Info_Bool_Exp>;
};


export type Subscription_RootBikecenter_User_Info_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bikecenter_User_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bikecenter_User_Info_Order_By>>;
  where?: InputMaybe<Bikecenter_User_Info_Bool_Exp>;
};


export type Subscription_RootBikecenter_User_Info_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Bikecenter_User_Info_Stream_Cursor_Input>>;
  where?: InputMaybe<Bikecenter_User_Info_Bool_Exp>;
};


export type Subscription_RootBikefitting_Bikefitting_SessionArgs = {
  distinct_on?: InputMaybe<Array<Bikefitting_Bikefitting_Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bikefitting_Bikefitting_Session_Order_By>>;
  where?: InputMaybe<Bikefitting_Bikefitting_Session_Bool_Exp>;
};


export type Subscription_RootBikefitting_Bikefitting_Session_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bikefitting_Bikefitting_Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bikefitting_Bikefitting_Session_Order_By>>;
  where?: InputMaybe<Bikefitting_Bikefitting_Session_Bool_Exp>;
};


export type Subscription_RootBikefitting_Bikefitting_Session_By_PkArgs = {
  bikefitting_id: Scalars['uuid'];
};


export type Subscription_RootBikefitting_Bikefitting_Session_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Bikefitting_Bikefitting_Session_Stream_Cursor_Input>>;
  where?: InputMaybe<Bikefitting_Bikefitting_Session_Bool_Exp>;
};


export type Subscription_RootCommission_AccountArgs = {
  distinct_on?: InputMaybe<Array<Commission_Account_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_Account_Order_By>>;
  where?: InputMaybe<Commission_Account_Bool_Exp>;
};


export type Subscription_RootCommission_Account_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Commission_Account_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_Account_Order_By>>;
  where?: InputMaybe<Commission_Account_Bool_Exp>;
};


export type Subscription_RootCommission_Account_BalanceArgs = {
  distinct_on?: InputMaybe<Array<Commission_Account_Balance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_Account_Balance_Order_By>>;
  where?: InputMaybe<Commission_Account_Balance_Bool_Exp>;
};


export type Subscription_RootCommission_Account_Balance_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Commission_Account_Balance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_Account_Balance_Order_By>>;
  where?: InputMaybe<Commission_Account_Balance_Bool_Exp>;
};


export type Subscription_RootCommission_Account_Balance_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Commission_Account_Balance_Stream_Cursor_Input>>;
  where?: InputMaybe<Commission_Account_Balance_Bool_Exp>;
};


export type Subscription_RootCommission_Account_By_PkArgs = {
  answer_id: Scalars['String'];
  booking_time: Scalars['timestamptz'];
  type: Commission_Type_Enum;
  vendor_id: Scalars['String'];
};


export type Subscription_RootCommission_Account_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Commission_Account_Stream_Cursor_Input>>;
  where?: InputMaybe<Commission_Account_Bool_Exp>;
};


export type Subscription_RootCommission_BoostArgs = {
  distinct_on?: InputMaybe<Array<Commission_Boost_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_Boost_Order_By>>;
  where?: InputMaybe<Commission_Boost_Bool_Exp>;
};


export type Subscription_RootCommission_Boost_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Commission_Boost_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_Boost_Order_By>>;
  where?: InputMaybe<Commission_Boost_Bool_Exp>;
};


export type Subscription_RootCommission_Boost_By_PkArgs = {
  start_date: Scalars['date'];
  vendor_id: Scalars['String'];
};


export type Subscription_RootCommission_Boost_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Commission_Boost_Stream_Cursor_Input>>;
  where?: InputMaybe<Commission_Boost_Bool_Exp>;
};


export type Subscription_RootCommission_Detailed_Account_BalanceArgs = {
  distinct_on?: InputMaybe<Array<Commission_Detailed_Account_Balance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_Detailed_Account_Balance_Order_By>>;
  where?: InputMaybe<Commission_Detailed_Account_Balance_Bool_Exp>;
};


export type Subscription_RootCommission_Detailed_Account_Balance_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Commission_Detailed_Account_Balance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_Detailed_Account_Balance_Order_By>>;
  where?: InputMaybe<Commission_Detailed_Account_Balance_Bool_Exp>;
};


export type Subscription_RootCommission_Detailed_Account_Balance_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Commission_Detailed_Account_Balance_Stream_Cursor_Input>>;
  where?: InputMaybe<Commission_Detailed_Account_Balance_Bool_Exp>;
};


export type Subscription_RootCommission_Eligible_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Commission_Eligible_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_Eligible_Answers_Order_By>>;
  where?: InputMaybe<Commission_Eligible_Answers_Bool_Exp>;
};


export type Subscription_RootCommission_Eligible_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Commission_Eligible_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_Eligible_Answers_Order_By>>;
  where?: InputMaybe<Commission_Eligible_Answers_Bool_Exp>;
};


export type Subscription_RootCommission_Eligible_Answers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Commission_Eligible_Answers_Stream_Cursor_Input>>;
  where?: InputMaybe<Commission_Eligible_Answers_Bool_Exp>;
};


export type Subscription_RootCommission_Eligible_OrdersArgs = {
  distinct_on?: InputMaybe<Array<Commission_Eligible_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_Eligible_Orders_Order_By>>;
  where?: InputMaybe<Commission_Eligible_Orders_Bool_Exp>;
};


export type Subscription_RootCommission_Eligible_Orders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Commission_Eligible_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_Eligible_Orders_Order_By>>;
  where?: InputMaybe<Commission_Eligible_Orders_Bool_Exp>;
};


export type Subscription_RootCommission_Eligible_Orders_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Commission_Eligible_Orders_Stream_Cursor_Input>>;
  where?: InputMaybe<Commission_Eligible_Orders_Bool_Exp>;
};


export type Subscription_RootCommission_Payout_LogArgs = {
  distinct_on?: InputMaybe<Array<Commission_Payout_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_Payout_Log_Order_By>>;
  where?: InputMaybe<Commission_Payout_Log_Bool_Exp>;
};


export type Subscription_RootCommission_Payout_Log_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Commission_Payout_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_Payout_Log_Order_By>>;
  where?: InputMaybe<Commission_Payout_Log_Bool_Exp>;
};


export type Subscription_RootCommission_Payout_Log_By_PkArgs = {
  quarter: Scalars['Int'];
  vendor_id: Scalars['String'];
  year: Scalars['Int'];
};


export type Subscription_RootCommission_Payout_Log_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Commission_Payout_Log_Stream_Cursor_Input>>;
  where?: InputMaybe<Commission_Payout_Log_Bool_Exp>;
};


export type Subscription_RootCommission_TypeArgs = {
  distinct_on?: InputMaybe<Array<Commission_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_Type_Order_By>>;
  where?: InputMaybe<Commission_Type_Bool_Exp>;
};


export type Subscription_RootCommission_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Commission_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_Type_Order_By>>;
  where?: InputMaybe<Commission_Type_Bool_Exp>;
};


export type Subscription_RootCommission_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCommission_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Commission_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Commission_Type_Bool_Exp>;
};


export type Subscription_RootCommission_User_OverviewArgs = {
  distinct_on?: InputMaybe<Array<Commission_User_Overview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_User_Overview_Order_By>>;
  where?: InputMaybe<Commission_User_Overview_Bool_Exp>;
};


export type Subscription_RootCommission_User_Overview_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Commission_User_Overview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_User_Overview_Order_By>>;
  where?: InputMaybe<Commission_User_Overview_Bool_Exp>;
};


export type Subscription_RootCommission_User_Overview_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Commission_User_Overview_Stream_Cursor_Input>>;
  where?: InputMaybe<Commission_User_Overview_Bool_Exp>;
};


export type Subscription_RootCommission_Vendor_OverviewArgs = {
  distinct_on?: InputMaybe<Array<Commission_Vendor_Overview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_Vendor_Overview_Order_By>>;
  where?: InputMaybe<Commission_Vendor_Overview_Bool_Exp>;
};


export type Subscription_RootCommission_Vendor_Overview_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Commission_Vendor_Overview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_Vendor_Overview_Order_By>>;
  where?: InputMaybe<Commission_Vendor_Overview_Bool_Exp>;
};


export type Subscription_RootCommission_Vendor_Overview_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Commission_Vendor_Overview_Stream_Cursor_Input>>;
  where?: InputMaybe<Commission_Vendor_Overview_Bool_Exp>;
};


export type Subscription_RootCommission_Webshop_DataArgs = {
  distinct_on?: InputMaybe<Array<Commission_Webshop_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_Webshop_Data_Order_By>>;
  where?: InputMaybe<Commission_Webshop_Data_Bool_Exp>;
};


export type Subscription_RootCommission_Webshop_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Commission_Webshop_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_Webshop_Data_Order_By>>;
  where?: InputMaybe<Commission_Webshop_Data_Bool_Exp>;
};


export type Subscription_RootCommission_Webshop_Data_By_PkArgs = {
  ox_order_id: Scalars['Int'];
};


export type Subscription_RootCommission_Webshop_Data_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Commission_Webshop_Data_Stream_Cursor_Input>>;
  where?: InputMaybe<Commission_Webshop_Data_Bool_Exp>;
};


export type Subscription_RootContent_ChaptersArgs = {
  distinct_on?: InputMaybe<Array<Content_Chapters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Chapters_Order_By>>;
  where?: InputMaybe<Content_Chapters_Bool_Exp>;
};


export type Subscription_RootContent_Chapters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Content_Chapters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Chapters_Order_By>>;
  where?: InputMaybe<Content_Chapters_Bool_Exp>;
};


export type Subscription_RootContent_Chapters_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootContent_Chapters_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Content_Chapters_Stream_Cursor_Input>>;
  where?: InputMaybe<Content_Chapters_Bool_Exp>;
};


export type Subscription_RootContent_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Content_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Choices_Order_By>>;
  where?: InputMaybe<Content_Choices_Bool_Exp>;
};


export type Subscription_RootContent_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Content_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Choices_Order_By>>;
  where?: InputMaybe<Content_Choices_Bool_Exp>;
};


export type Subscription_RootContent_Choices_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootContent_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Content_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Content_Choices_Bool_Exp>;
};


export type Subscription_RootContent_ContactpointsArgs = {
  distinct_on?: InputMaybe<Array<Content_Contactpoints_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Contactpoints_Order_By>>;
  where?: InputMaybe<Content_Contactpoints_Bool_Exp>;
};


export type Subscription_RootContent_Contactpoints_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Content_Contactpoints_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Contactpoints_Order_By>>;
  where?: InputMaybe<Content_Contactpoints_Bool_Exp>;
};


export type Subscription_RootContent_Contactpoints_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootContent_Contactpoints_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Content_Contactpoints_Stream_Cursor_Input>>;
  where?: InputMaybe<Content_Contactpoints_Bool_Exp>;
};


export type Subscription_RootContent_Lection_RequirementsArgs = {
  distinct_on?: InputMaybe<Array<Content_Lection_Requirements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Lection_Requirements_Order_By>>;
  where?: InputMaybe<Content_Lection_Requirements_Bool_Exp>;
};


export type Subscription_RootContent_Lection_Requirements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Content_Lection_Requirements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Lection_Requirements_Order_By>>;
  where?: InputMaybe<Content_Lection_Requirements_Bool_Exp>;
};


export type Subscription_RootContent_Lection_Requirements_By_PkArgs = {
  lection_id: Scalars['Int'];
  required_lection_id: Scalars['Int'];
};


export type Subscription_RootContent_Lection_Requirements_Hierarchical_StructureArgs = {
  distinct_on?: InputMaybe<Array<Content_Lection_Requirements_Hierarchical_Structure_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Lection_Requirements_Hierarchical_Structure_Order_By>>;
  where?: InputMaybe<Content_Lection_Requirements_Hierarchical_Structure_Bool_Exp>;
};


export type Subscription_RootContent_Lection_Requirements_Hierarchical_Structure_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Content_Lection_Requirements_Hierarchical_Structure_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Lection_Requirements_Hierarchical_Structure_Order_By>>;
  where?: InputMaybe<Content_Lection_Requirements_Hierarchical_Structure_Bool_Exp>;
};


export type Subscription_RootContent_Lection_Requirements_Hierarchical_Structure_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Content_Lection_Requirements_Hierarchical_Structure_Stream_Cursor_Input>>;
  where?: InputMaybe<Content_Lection_Requirements_Hierarchical_Structure_Bool_Exp>;
};


export type Subscription_RootContent_Lection_Requirements_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Content_Lection_Requirements_Stream_Cursor_Input>>;
  where?: InputMaybe<Content_Lection_Requirements_Bool_Exp>;
};


export type Subscription_RootContent_LectionsArgs = {
  distinct_on?: InputMaybe<Array<Content_Lections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Lections_Order_By>>;
  where?: InputMaybe<Content_Lections_Bool_Exp>;
};


export type Subscription_RootContent_Lections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Content_Lections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Lections_Order_By>>;
  where?: InputMaybe<Content_Lections_Bool_Exp>;
};


export type Subscription_RootContent_Lections_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootContent_Lections_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Content_Lections_Stream_Cursor_Input>>;
  where?: InputMaybe<Content_Lections_Bool_Exp>;
};


export type Subscription_RootContent_LevelsArgs = {
  distinct_on?: InputMaybe<Array<Content_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Levels_Order_By>>;
  where?: InputMaybe<Content_Levels_Bool_Exp>;
};


export type Subscription_RootContent_Levels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Content_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Levels_Order_By>>;
  where?: InputMaybe<Content_Levels_Bool_Exp>;
};


export type Subscription_RootContent_Levels_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootContent_Levels_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Content_Levels_Stream_Cursor_Input>>;
  where?: InputMaybe<Content_Levels_Bool_Exp>;
};


export type Subscription_RootContent_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Content_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Questions_Order_By>>;
  where?: InputMaybe<Content_Questions_Bool_Exp>;
};


export type Subscription_RootContent_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Content_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Questions_Order_By>>;
  where?: InputMaybe<Content_Questions_Bool_Exp>;
};


export type Subscription_RootContent_Questions_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootContent_Questions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Content_Questions_Stream_Cursor_Input>>;
  where?: InputMaybe<Content_Questions_Bool_Exp>;
};


export type Subscription_RootContent_Quiz_TypeArgs = {
  distinct_on?: InputMaybe<Array<Content_Quiz_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Quiz_Type_Order_By>>;
  where?: InputMaybe<Content_Quiz_Type_Bool_Exp>;
};


export type Subscription_RootContent_Quiz_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Content_Quiz_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Quiz_Type_Order_By>>;
  where?: InputMaybe<Content_Quiz_Type_Bool_Exp>;
};


export type Subscription_RootContent_Quiz_Type_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootContent_Quiz_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Content_Quiz_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Content_Quiz_Type_Bool_Exp>;
};


export type Subscription_RootContent_QuizzesArgs = {
  distinct_on?: InputMaybe<Array<Content_Quizzes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Quizzes_Order_By>>;
  where?: InputMaybe<Content_Quizzes_Bool_Exp>;
};


export type Subscription_RootContent_Quizzes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Content_Quizzes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Quizzes_Order_By>>;
  where?: InputMaybe<Content_Quizzes_Bool_Exp>;
};


export type Subscription_RootContent_Quizzes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootContent_Quizzes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Content_Quizzes_Stream_Cursor_Input>>;
  where?: InputMaybe<Content_Quizzes_Bool_Exp>;
};


export type Subscription_RootCountry_CodeArgs = {
  distinct_on?: InputMaybe<Array<Country_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Country_Code_Order_By>>;
  where?: InputMaybe<Country_Code_Bool_Exp>;
};


export type Subscription_RootCountry_Code_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Country_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Country_Code_Order_By>>;
  where?: InputMaybe<Country_Code_Bool_Exp>;
};


export type Subscription_RootCountry_Code_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCountry_Code_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Country_Code_Stream_Cursor_Input>>;
  where?: InputMaybe<Country_Code_Bool_Exp>;
};


export type Subscription_RootCurrencyArgs = {
  distinct_on?: InputMaybe<Array<Currency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Currency_Order_By>>;
  where?: InputMaybe<Currency_Bool_Exp>;
};


export type Subscription_RootCurrency_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Currency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Currency_Order_By>>;
  where?: InputMaybe<Currency_Bool_Exp>;
};


export type Subscription_RootCurrency_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCurrency_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Currency_Stream_Cursor_Input>>;
  where?: InputMaybe<Currency_Bool_Exp>;
};


export type Subscription_RootCurrent_UserArgs = {
  distinct_on?: InputMaybe<Array<Current_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Current_User_Order_By>>;
  where?: InputMaybe<Current_User_Bool_Exp>;
};


export type Subscription_RootCurrent_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Current_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Current_User_Order_By>>;
  where?: InputMaybe<Current_User_Bool_Exp>;
};


export type Subscription_RootCurrent_User_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Current_User_Stream_Cursor_Input>>;
  where?: InputMaybe<Current_User_Bool_Exp>;
};


export type Subscription_RootCustomer_AnswerArgs = {
  distinct_on?: InputMaybe<Array<Customer_Answer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Answer_Order_By>>;
  where?: InputMaybe<Customer_Answer_Bool_Exp>;
};


export type Subscription_RootCustomer_Answer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Answer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Answer_Order_By>>;
  where?: InputMaybe<Customer_Answer_Bool_Exp>;
};


export type Subscription_RootCustomer_Answer_Article_EventArgs = {
  distinct_on?: InputMaybe<Array<Customer_Answer_Article_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Answer_Article_Event_Order_By>>;
  where?: InputMaybe<Customer_Answer_Article_Event_Bool_Exp>;
};


export type Subscription_RootCustomer_Answer_Article_Event_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Answer_Article_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Answer_Article_Event_Order_By>>;
  where?: InputMaybe<Customer_Answer_Article_Event_Bool_Exp>;
};


export type Subscription_RootCustomer_Answer_Article_Event_By_PkArgs = {
  answer_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
};


export type Subscription_RootCustomer_Answer_Article_Event_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Answer_Article_Event_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Answer_Article_Event_Bool_Exp>;
};


export type Subscription_RootCustomer_Answer_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootCustomer_Answer_Foot_ProblemsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Answer_Foot_Problems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Answer_Foot_Problems_Order_By>>;
  where?: InputMaybe<Customer_Answer_Foot_Problems_Bool_Exp>;
};


export type Subscription_RootCustomer_Answer_Foot_Problems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Answer_Foot_Problems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Answer_Foot_Problems_Order_By>>;
  where?: InputMaybe<Customer_Answer_Foot_Problems_Bool_Exp>;
};


export type Subscription_RootCustomer_Answer_Foot_Problems_By_PkArgs = {
  answer_id: Scalars['String'];
  foot_problem_id: Answer_Enums_Foot_Problems_Enum;
};


export type Subscription_RootCustomer_Answer_Foot_Problems_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Answer_Foot_Problems_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Answer_Foot_Problems_Bool_Exp>;
};


export type Subscription_RootCustomer_Answer_Hand_ProblemsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Answer_Hand_Problems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Answer_Hand_Problems_Order_By>>;
  where?: InputMaybe<Customer_Answer_Hand_Problems_Bool_Exp>;
};


export type Subscription_RootCustomer_Answer_Hand_Problems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Answer_Hand_Problems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Answer_Hand_Problems_Order_By>>;
  where?: InputMaybe<Customer_Answer_Hand_Problems_Bool_Exp>;
};


export type Subscription_RootCustomer_Answer_Hand_Problems_By_PkArgs = {
  answer_id: Scalars['String'];
  hand_problem_id: Answer_Enums_Hand_Problems_Enum;
};


export type Subscription_RootCustomer_Answer_Hand_Problems_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Answer_Hand_Problems_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Answer_Hand_Problems_Bool_Exp>;
};


export type Subscription_RootCustomer_Answer_Pelvic_ProblemsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Answer_Pelvic_Problems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Answer_Pelvic_Problems_Order_By>>;
  where?: InputMaybe<Customer_Answer_Pelvic_Problems_Bool_Exp>;
};


export type Subscription_RootCustomer_Answer_Pelvic_Problems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Answer_Pelvic_Problems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Answer_Pelvic_Problems_Order_By>>;
  where?: InputMaybe<Customer_Answer_Pelvic_Problems_Bool_Exp>;
};


export type Subscription_RootCustomer_Answer_Pelvic_Problems_By_PkArgs = {
  answer_id: Scalars['String'];
  pelvic_problem_id: Answer_Enums_Pelvic_Problems_Enum;
};


export type Subscription_RootCustomer_Answer_Pelvic_Problems_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Answer_Pelvic_Problems_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Answer_Pelvic_Problems_Bool_Exp>;
};


export type Subscription_RootCustomer_Answer_ResultsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Answer_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Answer_Results_Order_By>>;
  where?: InputMaybe<Customer_Answer_Results_Bool_Exp>;
};


export type Subscription_RootCustomer_Answer_Results_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Answer_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Answer_Results_Order_By>>;
  where?: InputMaybe<Customer_Answer_Results_Bool_Exp>;
};


export type Subscription_RootCustomer_Answer_Results_By_PkArgs = {
  answer_id: Scalars['String'];
};


export type Subscription_RootCustomer_Answer_Results_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Answer_Results_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Answer_Results_Bool_Exp>;
};


export type Subscription_RootCustomer_Answer_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Answer_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Answer_Bool_Exp>;
};


export type Subscription_RootCustomer_Dashboard_EventArgs = {
  distinct_on?: InputMaybe<Array<Customer_Dashboard_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Dashboard_Event_Order_By>>;
  where?: InputMaybe<Customer_Dashboard_Event_Bool_Exp>;
};


export type Subscription_RootCustomer_Dashboard_Event_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Dashboard_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Dashboard_Event_Order_By>>;
  where?: InputMaybe<Customer_Dashboard_Event_Bool_Exp>;
};


export type Subscription_RootCustomer_Dashboard_Event_By_PkArgs = {
  answer_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
};


export type Subscription_RootCustomer_Dashboard_Event_KindArgs = {
  distinct_on?: InputMaybe<Array<Customer_Dashboard_Event_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Dashboard_Event_Kind_Order_By>>;
  where?: InputMaybe<Customer_Dashboard_Event_Kind_Bool_Exp>;
};


export type Subscription_RootCustomer_Dashboard_Event_Kind_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Dashboard_Event_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Dashboard_Event_Kind_Order_By>>;
  where?: InputMaybe<Customer_Dashboard_Event_Kind_Bool_Exp>;
};


export type Subscription_RootCustomer_Dashboard_Event_Kind_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCustomer_Dashboard_Event_Kind_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Dashboard_Event_Kind_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Dashboard_Event_Kind_Bool_Exp>;
};


export type Subscription_RootCustomer_Dashboard_Event_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Dashboard_Event_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Dashboard_Event_Bool_Exp>;
};


export type Subscription_RootCustomer_Dashboard_Page_VisitArgs = {
  distinct_on?: InputMaybe<Array<Customer_Dashboard_Page_Visit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Dashboard_Page_Visit_Order_By>>;
  where?: InputMaybe<Customer_Dashboard_Page_Visit_Bool_Exp>;
};


export type Subscription_RootCustomer_Dashboard_Page_Visit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Dashboard_Page_Visit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Dashboard_Page_Visit_Order_By>>;
  where?: InputMaybe<Customer_Dashboard_Page_Visit_Bool_Exp>;
};


export type Subscription_RootCustomer_Dashboard_Page_Visit_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Dashboard_Page_Visit_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Dashboard_Page_Visit_Bool_Exp>;
};


export type Subscription_RootCustomer_Deleted_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Customer_Deleted_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Deleted_Answers_Order_By>>;
  where?: InputMaybe<Customer_Deleted_Answers_Bool_Exp>;
};


export type Subscription_RootCustomer_Deleted_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Deleted_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Deleted_Answers_Order_By>>;
  where?: InputMaybe<Customer_Deleted_Answers_Bool_Exp>;
};


export type Subscription_RootCustomer_Deleted_Answers_By_PkArgs = {
  answer_id: Scalars['String'];
};


export type Subscription_RootCustomer_Deleted_Answers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Deleted_Answers_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Deleted_Answers_Bool_Exp>;
};


export type Subscription_RootCustomer_EmailsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Emails_Order_By>>;
  where?: InputMaybe<Customer_Emails_Bool_Exp>;
};


export type Subscription_RootCustomer_Emails_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Emails_Order_By>>;
  where?: InputMaybe<Customer_Emails_Bool_Exp>;
};


export type Subscription_RootCustomer_Emails_By_PkArgs = {
  answer_id: Scalars['String'];
  type: Scalars['String'];
};


export type Subscription_RootCustomer_Emails_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Emails_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Emails_Bool_Exp>;
};


export type Subscription_RootCustomer_RegistrationArgs = {
  distinct_on?: InputMaybe<Array<Customer_Registration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Registration_Order_By>>;
  where?: InputMaybe<Customer_Registration_Bool_Exp>;
};


export type Subscription_RootCustomer_Registration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Registration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Registration_Order_By>>;
  where?: InputMaybe<Customer_Registration_Bool_Exp>;
};


export type Subscription_RootCustomer_Registration_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCustomer_Registration_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Registration_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Registration_Bool_Exp>;
};


export type Subscription_RootCustomer_SignatureArgs = {
  distinct_on?: InputMaybe<Array<Customer_Signature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Signature_Order_By>>;
  where?: InputMaybe<Customer_Signature_Bool_Exp>;
};


export type Subscription_RootCustomer_Signature_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Signature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Signature_Order_By>>;
  where?: InputMaybe<Customer_Signature_Bool_Exp>;
};


export type Subscription_RootCustomer_Signature_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCustomer_Signature_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Signature_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Signature_Bool_Exp>;
};


export type Subscription_RootCustomer_VoucherArgs = {
  distinct_on?: InputMaybe<Array<Customer_Voucher_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Voucher_Order_By>>;
  where?: InputMaybe<Customer_Voucher_Bool_Exp>;
};


export type Subscription_RootCustomer_Voucher_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Voucher_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Voucher_Order_By>>;
  where?: InputMaybe<Customer_Voucher_Bool_Exp>;
};


export type Subscription_RootCustomer_Voucher_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCustomer_Voucher_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Voucher_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Voucher_Bool_Exp>;
};


export type Subscription_RootCustomer_Web_Questionnaire_CompletionArgs = {
  distinct_on?: InputMaybe<Array<Customer_Web_Questionnaire_Completion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Web_Questionnaire_Completion_Order_By>>;
  where?: InputMaybe<Customer_Web_Questionnaire_Completion_Bool_Exp>;
};


export type Subscription_RootCustomer_Web_Questionnaire_Completion_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Web_Questionnaire_Completion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Web_Questionnaire_Completion_Order_By>>;
  where?: InputMaybe<Customer_Web_Questionnaire_Completion_Bool_Exp>;
};


export type Subscription_RootCustomer_Web_Questionnaire_Completion_By_PkArgs = {
  answer_id: Scalars['String'];
};


export type Subscription_RootCustomer_Web_Questionnaire_Completion_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Web_Questionnaire_Completion_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Web_Questionnaire_Completion_Bool_Exp>;
};


export type Subscription_RootCv_BodyArgs = {
  distinct_on?: InputMaybe<Array<Cv_Body_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cv_Body_Order_By>>;
  where?: InputMaybe<Cv_Body_Bool_Exp>;
};


export type Subscription_RootCv_Body_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cv_Body_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cv_Body_Order_By>>;
  where?: InputMaybe<Cv_Body_Bool_Exp>;
};


export type Subscription_RootCv_Body_By_PkArgs = {
  answer_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
};


export type Subscription_RootCv_Body_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Cv_Body_Stream_Cursor_Input>>;
  where?: InputMaybe<Cv_Body_Bool_Exp>;
};


export type Subscription_RootCv_HandmarksArgs = {
  distinct_on?: InputMaybe<Array<Cv_Handmarks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cv_Handmarks_Order_By>>;
  where?: InputMaybe<Cv_Handmarks_Bool_Exp>;
};


export type Subscription_RootCv_Handmarks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cv_Handmarks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cv_Handmarks_Order_By>>;
  where?: InputMaybe<Cv_Handmarks_Bool_Exp>;
};


export type Subscription_RootCv_Handmarks_By_PkArgs = {
  answer_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
};


export type Subscription_RootCv_Handmarks_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Cv_Handmarks_Stream_Cursor_Input>>;
  where?: InputMaybe<Cv_Handmarks_Bool_Exp>;
};


export type Subscription_RootCv_SitbonesArgs = {
  distinct_on?: InputMaybe<Array<Cv_Sitbones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cv_Sitbones_Order_By>>;
  where?: InputMaybe<Cv_Sitbones_Bool_Exp>;
};


export type Subscription_RootCv_Sitbones_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cv_Sitbones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cv_Sitbones_Order_By>>;
  where?: InputMaybe<Cv_Sitbones_Bool_Exp>;
};


export type Subscription_RootCv_Sitbones_By_PkArgs = {
  answer_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
};


export type Subscription_RootCv_Sitbones_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Cv_Sitbones_Stream_Cursor_Input>>;
  where?: InputMaybe<Cv_Sitbones_Bool_Exp>;
};


export type Subscription_RootDistributorArgs = {
  distinct_on?: InputMaybe<Array<Distributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Distributor_Order_By>>;
  where?: InputMaybe<Distributor_Bool_Exp>;
};


export type Subscription_RootDistributor_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Distributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Distributor_Order_By>>;
  where?: InputMaybe<Distributor_Bool_Exp>;
};


export type Subscription_RootDistributor_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootDistributor_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Distributor_Stream_Cursor_Input>>;
  where?: InputMaybe<Distributor_Bool_Exp>;
};


export type Subscription_RootDistributorsArgs = {
  distinct_on?: InputMaybe<Array<Distributors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Distributors_Order_By>>;
  where?: InputMaybe<Distributors_Bool_Exp>;
};


export type Subscription_RootDistributors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Distributors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Distributors_Order_By>>;
  where?: InputMaybe<Distributors_Bool_Exp>;
};


export type Subscription_RootDistributors_By_PkArgs = {
  country_code: Scalars['String'];
};


export type Subscription_RootDistributors_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Distributors_Stream_Cursor_Input>>;
  where?: InputMaybe<Distributors_Bool_Exp>;
};


export type Subscription_RootEvent_Enums_Article_EventArgs = {
  distinct_on?: InputMaybe<Array<Event_Enums_Article_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Enums_Article_Event_Order_By>>;
  where?: InputMaybe<Event_Enums_Article_Event_Bool_Exp>;
};


export type Subscription_RootEvent_Enums_Article_Event_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Enums_Article_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Enums_Article_Event_Order_By>>;
  where?: InputMaybe<Event_Enums_Article_Event_Bool_Exp>;
};


export type Subscription_RootEvent_Enums_Article_Event_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEvent_Enums_Article_Event_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Event_Enums_Article_Event_Stream_Cursor_Input>>;
  where?: InputMaybe<Event_Enums_Article_Event_Bool_Exp>;
};


export type Subscription_RootEvent_Enums_Event_SourceArgs = {
  distinct_on?: InputMaybe<Array<Event_Enums_Event_Source_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Enums_Event_Source_Order_By>>;
  where?: InputMaybe<Event_Enums_Event_Source_Bool_Exp>;
};


export type Subscription_RootEvent_Enums_Event_Source_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Enums_Event_Source_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Enums_Event_Source_Order_By>>;
  where?: InputMaybe<Event_Enums_Event_Source_Bool_Exp>;
};


export type Subscription_RootEvent_Enums_Event_Source_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEvent_Enums_Event_Source_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Event_Enums_Event_Source_Stream_Cursor_Input>>;
  where?: InputMaybe<Event_Enums_Event_Source_Bool_Exp>;
};


export type Subscription_RootEvent_Enums_Log_LevelArgs = {
  distinct_on?: InputMaybe<Array<Event_Enums_Log_Level_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Enums_Log_Level_Order_By>>;
  where?: InputMaybe<Event_Enums_Log_Level_Bool_Exp>;
};


export type Subscription_RootEvent_Enums_Log_Level_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Enums_Log_Level_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Enums_Log_Level_Order_By>>;
  where?: InputMaybe<Event_Enums_Log_Level_Bool_Exp>;
};


export type Subscription_RootEvent_Enums_Log_Level_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEvent_Enums_Log_Level_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Event_Enums_Log_Level_Stream_Cursor_Input>>;
  where?: InputMaybe<Event_Enums_Log_Level_Bool_Exp>;
};


export type Subscription_RootEvent_Log_Event_TypesArgs = {
  distinct_on?: InputMaybe<Array<Event_Log_Event_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Log_Event_Types_Order_By>>;
  where?: InputMaybe<Event_Log_Event_Types_Bool_Exp>;
};


export type Subscription_RootEvent_Log_Event_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Log_Event_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Log_Event_Types_Order_By>>;
  where?: InputMaybe<Event_Log_Event_Types_Bool_Exp>;
};


export type Subscription_RootEvent_Log_Event_Types_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootEvent_Log_Event_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Event_Log_Event_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Event_Log_Event_Types_Bool_Exp>;
};


export type Subscription_RootEvent_Log_EventsArgs = {
  distinct_on?: InputMaybe<Array<Event_Log_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Log_Events_Order_By>>;
  where?: InputMaybe<Event_Log_Events_Bool_Exp>;
};


export type Subscription_RootEvent_Log_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Log_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Log_Events_Order_By>>;
  where?: InputMaybe<Event_Log_Events_Bool_Exp>;
};


export type Subscription_RootEvent_Log_Events_By_PkArgs = {
  created_at: Scalars['timestamptz'];
  type: Scalars['String'];
  user_id: Scalars['uuid'];
};


export type Subscription_RootEvent_Log_Events_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Event_Log_Events_Stream_Cursor_Input>>;
  where?: InputMaybe<Event_Log_Events_Bool_Exp>;
};


export type Subscription_RootFrontend_Vendor_SupportArgs = {
  distinct_on?: InputMaybe<Array<Frontend_Vendor_Support_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Frontend_Vendor_Support_Order_By>>;
  where?: InputMaybe<Frontend_Vendor_Support_Bool_Exp>;
};


export type Subscription_RootFrontend_Vendor_Support_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Frontend_Vendor_Support_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Frontend_Vendor_Support_Order_By>>;
  where?: InputMaybe<Frontend_Vendor_Support_Bool_Exp>;
};


export type Subscription_RootFrontend_Vendor_Support_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootFrontend_Vendor_Support_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Frontend_Vendor_Support_Stream_Cursor_Input>>;
  where?: InputMaybe<Frontend_Vendor_Support_Bool_Exp>;
};


export type Subscription_RootHealthArgs = {
  distinct_on?: InputMaybe<Array<Health_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Order_By>>;
  where?: InputMaybe<Health_Bool_Exp>;
};


export type Subscription_RootHealth_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Health_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Order_By>>;
  where?: InputMaybe<Health_Bool_Exp>;
};


export type Subscription_RootHealth_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Health_Stream_Cursor_Input>>;
  where?: InputMaybe<Health_Bool_Exp>;
};


export type Subscription_RootIframe_FeedbackArgs = {
  distinct_on?: InputMaybe<Array<Iframe_Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Iframe_Feedback_Order_By>>;
  where?: InputMaybe<Iframe_Feedback_Bool_Exp>;
};


export type Subscription_RootIframe_Feedback_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Iframe_Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Iframe_Feedback_Order_By>>;
  where?: InputMaybe<Iframe_Feedback_Bool_Exp>;
};


export type Subscription_RootIframe_Feedback_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootIframe_Feedback_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Iframe_Feedback_Stream_Cursor_Input>>;
  where?: InputMaybe<Iframe_Feedback_Bool_Exp>;
};


export type Subscription_RootIframe_Newsletter_SubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Iframe_Newsletter_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Iframe_Newsletter_Subscriptions_Order_By>>;
  where?: InputMaybe<Iframe_Newsletter_Subscriptions_Bool_Exp>;
};


export type Subscription_RootIframe_Newsletter_Subscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Iframe_Newsletter_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Iframe_Newsletter_Subscriptions_Order_By>>;
  where?: InputMaybe<Iframe_Newsletter_Subscriptions_Bool_Exp>;
};


export type Subscription_RootIframe_Newsletter_Subscriptions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootIframe_Newsletter_Subscriptions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Iframe_Newsletter_Subscriptions_Stream_Cursor_Input>>;
  where?: InputMaybe<Iframe_Newsletter_Subscriptions_Bool_Exp>;
};


export type Subscription_RootIframe_RatingArgs = {
  distinct_on?: InputMaybe<Array<Iframe_Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Iframe_Rating_Order_By>>;
  where?: InputMaybe<Iframe_Rating_Bool_Exp>;
};


export type Subscription_RootIframe_Rating_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Iframe_Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Iframe_Rating_Order_By>>;
  where?: InputMaybe<Iframe_Rating_Bool_Exp>;
};


export type Subscription_RootIframe_Rating_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootIframe_Rating_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Iframe_Rating_Stream_Cursor_Input>>;
  where?: InputMaybe<Iframe_Rating_Bool_Exp>;
};


export type Subscription_RootIqlab_Rewards_TypesArgs = {
  distinct_on?: InputMaybe<Array<Iqlab_Rewards_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Iqlab_Rewards_Types_Order_By>>;
  where?: InputMaybe<Iqlab_Rewards_Types_Bool_Exp>;
};


export type Subscription_RootIqlab_Rewards_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Iqlab_Rewards_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Iqlab_Rewards_Types_Order_By>>;
  where?: InputMaybe<Iqlab_Rewards_Types_Bool_Exp>;
};


export type Subscription_RootIqlab_Rewards_Types_By_PkArgs = {
  type: Scalars['String'];
};


export type Subscription_RootIqlab_Rewards_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Iqlab_Rewards_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Iqlab_Rewards_Types_Bool_Exp>;
};


export type Subscription_RootIqlab_Rewards_VouchersArgs = {
  distinct_on?: InputMaybe<Array<Iqlab_Rewards_Vouchers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Iqlab_Rewards_Vouchers_Order_By>>;
  where?: InputMaybe<Iqlab_Rewards_Vouchers_Bool_Exp>;
};


export type Subscription_RootIqlab_Rewards_Vouchers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Iqlab_Rewards_Vouchers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Iqlab_Rewards_Vouchers_Order_By>>;
  where?: InputMaybe<Iqlab_Rewards_Vouchers_Bool_Exp>;
};


export type Subscription_RootIqlab_Rewards_Vouchers_By_PkArgs = {
  code: Scalars['String'];
  created_at: Scalars['timestamptz'];
};


export type Subscription_RootIqlab_Rewards_Vouchers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Iqlab_Rewards_Vouchers_Stream_Cursor_Input>>;
  where?: InputMaybe<Iqlab_Rewards_Vouchers_Bool_Exp>;
};


export type Subscription_RootMeasurement_MeasureArgs = {
  distinct_on?: InputMaybe<Array<Measurement_Measure_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Measurement_Measure_Order_By>>;
  where?: InputMaybe<Measurement_Measure_Bool_Exp>;
};


export type Subscription_RootMeasurement_Measure_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Measurement_Measure_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Measurement_Measure_Order_By>>;
  where?: InputMaybe<Measurement_Measure_Bool_Exp>;
};


export type Subscription_RootMeasurement_Measure_By_PkArgs = {
  session_id: Scalars['Int'];
  type: Scalars['String'];
};


export type Subscription_RootMeasurement_Measure_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Measurement_Measure_Stream_Cursor_Input>>;
  where?: InputMaybe<Measurement_Measure_Bool_Exp>;
};


export type Subscription_RootMeasurement_Measure_TypeArgs = {
  distinct_on?: InputMaybe<Array<Measurement_Measure_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Measurement_Measure_Type_Order_By>>;
  where?: InputMaybe<Measurement_Measure_Type_Bool_Exp>;
};


export type Subscription_RootMeasurement_Measure_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Measurement_Measure_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Measurement_Measure_Type_Order_By>>;
  where?: InputMaybe<Measurement_Measure_Type_Bool_Exp>;
};


export type Subscription_RootMeasurement_Measure_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootMeasurement_Measure_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Measurement_Measure_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Measurement_Measure_Type_Bool_Exp>;
};


export type Subscription_RootMeasurement_Session_SourceArgs = {
  distinct_on?: InputMaybe<Array<Measurement_Session_Source_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Measurement_Session_Source_Order_By>>;
  where?: InputMaybe<Measurement_Session_Source_Bool_Exp>;
};


export type Subscription_RootMeasurement_Session_Source_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Measurement_Session_Source_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Measurement_Session_Source_Order_By>>;
  where?: InputMaybe<Measurement_Session_Source_Bool_Exp>;
};


export type Subscription_RootMeasurement_Session_Source_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootMeasurement_Session_Source_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Measurement_Session_Source_Stream_Cursor_Input>>;
  where?: InputMaybe<Measurement_Session_Source_Bool_Exp>;
};


export type Subscription_RootMeasurement_Session_TypeArgs = {
  distinct_on?: InputMaybe<Array<Measurement_Session_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Measurement_Session_Type_Order_By>>;
  where?: InputMaybe<Measurement_Session_Type_Bool_Exp>;
};


export type Subscription_RootMeasurement_Session_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Measurement_Session_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Measurement_Session_Type_Order_By>>;
  where?: InputMaybe<Measurement_Session_Type_Bool_Exp>;
};


export type Subscription_RootMeasurement_Session_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootMeasurement_Session_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Measurement_Session_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Measurement_Session_Type_Bool_Exp>;
};


export type Subscription_RootMeasurement_SessionsArgs = {
  distinct_on?: InputMaybe<Array<Measurement_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Measurement_Sessions_Order_By>>;
  where?: InputMaybe<Measurement_Sessions_Bool_Exp>;
};


export type Subscription_RootMeasurement_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Measurement_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Measurement_Sessions_Order_By>>;
  where?: InputMaybe<Measurement_Sessions_Bool_Exp>;
};


export type Subscription_RootMeasurement_Sessions_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootMeasurement_Sessions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Measurement_Sessions_Stream_Cursor_Input>>;
  where?: InputMaybe<Measurement_Sessions_Bool_Exp>;
};


export type Subscription_RootMetric_Answer_LocationArgs = {
  distinct_on?: InputMaybe<Array<Metric_Answer_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Metric_Answer_Location_Order_By>>;
  where?: InputMaybe<Metric_Answer_Location_Bool_Exp>;
};


export type Subscription_RootMetric_Answer_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Metric_Answer_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Metric_Answer_Location_Order_By>>;
  where?: InputMaybe<Metric_Answer_Location_Bool_Exp>;
};


export type Subscription_RootMetric_Answer_Location_By_PkArgs = {
  answer_id: Scalars['String'];
};


export type Subscription_RootMetric_Answer_Location_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Metric_Answer_Location_Stream_Cursor_Input>>;
  where?: InputMaybe<Metric_Answer_Location_Bool_Exp>;
};


export type Subscription_RootMetric_App_EventsArgs = {
  distinct_on?: InputMaybe<Array<Metric_App_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Metric_App_Events_Order_By>>;
  where?: InputMaybe<Metric_App_Events_Bool_Exp>;
};


export type Subscription_RootMetric_App_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Metric_App_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Metric_App_Events_Order_By>>;
  where?: InputMaybe<Metric_App_Events_Bool_Exp>;
};


export type Subscription_RootMetric_App_Events_By_PkArgs = {
  created_at: Scalars['timestamptz'];
  user_id: Scalars['uuid'];
};


export type Subscription_RootMetric_App_Events_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Metric_App_Events_Stream_Cursor_Input>>;
  where?: InputMaybe<Metric_App_Events_Bool_Exp>;
};


export type Subscription_RootMetric_Avg_Questionnaire_Count_30_DaysArgs = {
  distinct_on?: InputMaybe<Array<Metric_Avg_Questionnaire_Count_30_Days_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Metric_Avg_Questionnaire_Count_30_Days_Order_By>>;
  where?: InputMaybe<Metric_Avg_Questionnaire_Count_30_Days_Bool_Exp>;
};


export type Subscription_RootMetric_Avg_Questionnaire_Count_30_Days_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Metric_Avg_Questionnaire_Count_30_Days_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Metric_Avg_Questionnaire_Count_30_Days_Order_By>>;
  where?: InputMaybe<Metric_Avg_Questionnaire_Count_30_Days_Bool_Exp>;
};


export type Subscription_RootMetric_Avg_Questionnaire_Count_30_Days_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Metric_Avg_Questionnaire_Count_30_Days_Stream_Cursor_Input>>;
  where?: InputMaybe<Metric_Avg_Questionnaire_Count_30_Days_Bool_Exp>;
};


export type Subscription_RootMetric_Max_Questionnaires_With_DayArgs = {
  distinct_on?: InputMaybe<Array<Metric_Max_Questionnaires_With_Day_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Metric_Max_Questionnaires_With_Day_Order_By>>;
  where?: InputMaybe<Metric_Max_Questionnaires_With_Day_Bool_Exp>;
};


export type Subscription_RootMetric_Max_Questionnaires_With_Day_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Metric_Max_Questionnaires_With_Day_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Metric_Max_Questionnaires_With_Day_Order_By>>;
  where?: InputMaybe<Metric_Max_Questionnaires_With_Day_Bool_Exp>;
};


export type Subscription_RootMetric_Max_Questionnaires_With_Day_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Metric_Max_Questionnaires_With_Day_Stream_Cursor_Input>>;
  where?: InputMaybe<Metric_Max_Questionnaires_With_Day_Bool_Exp>;
};


export type Subscription_RootMetric_Question_DurationArgs = {
  distinct_on?: InputMaybe<Array<Metric_Question_Duration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Metric_Question_Duration_Order_By>>;
  where?: InputMaybe<Metric_Question_Duration_Bool_Exp>;
};


export type Subscription_RootMetric_Question_Duration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Metric_Question_Duration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Metric_Question_Duration_Order_By>>;
  where?: InputMaybe<Metric_Question_Duration_Bool_Exp>;
};


export type Subscription_RootMetric_Question_Duration_By_PkArgs = {
  created_at: Scalars['timestamptz'];
  customer_answer_id: Scalars['String'];
  question_id: Scalars['String'];
};


export type Subscription_RootMetric_Question_Duration_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Metric_Question_Duration_Stream_Cursor_Input>>;
  where?: InputMaybe<Metric_Question_Duration_Bool_Exp>;
};


export type Subscription_RootMetric_Questionnaires_Per_Vendor_And_YearArgs = {
  distinct_on?: InputMaybe<Array<Metric_Questionnaires_Per_Vendor_And_Year_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Metric_Questionnaires_Per_Vendor_And_Year_Order_By>>;
  where?: InputMaybe<Metric_Questionnaires_Per_Vendor_And_Year_Bool_Exp>;
};


export type Subscription_RootMetric_Questionnaires_Per_Vendor_And_Year_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Metric_Questionnaires_Per_Vendor_And_Year_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Metric_Questionnaires_Per_Vendor_And_Year_Order_By>>;
  where?: InputMaybe<Metric_Questionnaires_Per_Vendor_And_Year_Bool_Exp>;
};


export type Subscription_RootMetric_Questionnaires_Per_Vendor_And_Year_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Metric_Questionnaires_Per_Vendor_And_Year_Stream_Cursor_Input>>;
  where?: InputMaybe<Metric_Questionnaires_Per_Vendor_And_Year_Bool_Exp>;
};


export type Subscription_RootMetric_Usage_InformationArgs = {
  distinct_on?: InputMaybe<Array<Metric_Usage_Information_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Metric_Usage_Information_Order_By>>;
  where?: InputMaybe<Metric_Usage_Information_Bool_Exp>;
};


export type Subscription_RootMetric_Usage_Information_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Metric_Usage_Information_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Metric_Usage_Information_Order_By>>;
  where?: InputMaybe<Metric_Usage_Information_Bool_Exp>;
};


export type Subscription_RootMetric_Usage_Information_By_PkArgs = {
  device_id: Scalars['String'];
  user_id: Scalars['uuid'];
  vendor_id: Scalars['String'];
};


export type Subscription_RootMetric_Usage_Information_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Metric_Usage_Information_Stream_Cursor_Input>>;
  where?: InputMaybe<Metric_Usage_Information_Bool_Exp>;
};


export type Subscription_RootMetric_User_RankingArgs = {
  distinct_on?: InputMaybe<Array<Metric_User_Ranking_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Metric_User_Ranking_Order_By>>;
  where?: InputMaybe<Metric_User_Ranking_Bool_Exp>;
};


export type Subscription_RootMetric_User_Ranking_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Metric_User_Ranking_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Metric_User_Ranking_Order_By>>;
  where?: InputMaybe<Metric_User_Ranking_Bool_Exp>;
};


export type Subscription_RootMetric_User_Ranking_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Metric_User_Ranking_Stream_Cursor_Input>>;
  where?: InputMaybe<Metric_User_Ranking_Bool_Exp>;
};


export type Subscription_RootOnboarding_EmailsArgs = {
  distinct_on?: InputMaybe<Array<Onboarding_Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Onboarding_Emails_Order_By>>;
  where?: InputMaybe<Onboarding_Emails_Bool_Exp>;
};


export type Subscription_RootOnboarding_Emails_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Onboarding_Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Onboarding_Emails_Order_By>>;
  where?: InputMaybe<Onboarding_Emails_Bool_Exp>;
};


export type Subscription_RootOnboarding_Emails_By_PkArgs = {
  registration_id: Scalars['Int'];
  type: Scalars['String'];
};


export type Subscription_RootOnboarding_Emails_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Onboarding_Emails_Stream_Cursor_Input>>;
  where?: InputMaybe<Onboarding_Emails_Bool_Exp>;
};


export type Subscription_RootOrganisationArgs = {
  distinct_on?: InputMaybe<Array<Organisation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organisation_Order_By>>;
  where?: InputMaybe<Organisation_Bool_Exp>;
};


export type Subscription_RootOrganisation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organisation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organisation_Order_By>>;
  where?: InputMaybe<Organisation_Bool_Exp>;
};


export type Subscription_RootOrganisation_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootOrganisation_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Organisation_Stream_Cursor_Input>>;
  where?: InputMaybe<Organisation_Bool_Exp>;
};


export type Subscription_RootPreferences_User_PreferencesArgs = {
  distinct_on?: InputMaybe<Array<Preferences_User_Preferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Preferences_User_Preferences_Order_By>>;
  where?: InputMaybe<Preferences_User_Preferences_Bool_Exp>;
};


export type Subscription_RootPreferences_User_Preferences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Preferences_User_Preferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Preferences_User_Preferences_Order_By>>;
  where?: InputMaybe<Preferences_User_Preferences_Bool_Exp>;
};


export type Subscription_RootPreferences_User_Preferences_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Subscription_RootPreferences_User_Preferences_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Preferences_User_Preferences_Stream_Cursor_Input>>;
  where?: InputMaybe<Preferences_User_Preferences_Bool_Exp>;
};


export type Subscription_RootPreferences_Vendor_PreferencesArgs = {
  distinct_on?: InputMaybe<Array<Preferences_Vendor_Preferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Preferences_Vendor_Preferences_Order_By>>;
  where?: InputMaybe<Preferences_Vendor_Preferences_Bool_Exp>;
};


export type Subscription_RootPreferences_Vendor_Preferences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Preferences_Vendor_Preferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Preferences_Vendor_Preferences_Order_By>>;
  where?: InputMaybe<Preferences_Vendor_Preferences_Bool_Exp>;
};


export type Subscription_RootPreferences_Vendor_Preferences_By_PkArgs = {
  vendor_id: Scalars['String'];
};


export type Subscription_RootPreferences_Vendor_Preferences_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Preferences_Vendor_Preferences_Stream_Cursor_Input>>;
  where?: InputMaybe<Preferences_Vendor_Preferences_Bool_Exp>;
};


export type Subscription_RootProgress_Chapter_OverviewArgs = {
  distinct_on?: InputMaybe<Array<Progress_Chapter_Overview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Progress_Chapter_Overview_Order_By>>;
  where?: InputMaybe<Progress_Chapter_Overview_Bool_Exp>;
};


export type Subscription_RootProgress_Chapter_Overview_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Progress_Chapter_Overview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Progress_Chapter_Overview_Order_By>>;
  where?: InputMaybe<Progress_Chapter_Overview_Bool_Exp>;
};


export type Subscription_RootProgress_Chapter_Overview_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Progress_Chapter_Overview_Stream_Cursor_Input>>;
  where?: InputMaybe<Progress_Chapter_Overview_Bool_Exp>;
};


export type Subscription_RootProgress_Completed_ChaptersArgs = {
  distinct_on?: InputMaybe<Array<Progress_Completed_Chapters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Progress_Completed_Chapters_Order_By>>;
  where?: InputMaybe<Progress_Completed_Chapters_Bool_Exp>;
};


export type Subscription_RootProgress_Completed_Chapters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Progress_Completed_Chapters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Progress_Completed_Chapters_Order_By>>;
  where?: InputMaybe<Progress_Completed_Chapters_Bool_Exp>;
};


export type Subscription_RootProgress_Completed_Chapters_By_PkArgs = {
  chapter_id: Scalars['Int'];
  user_id: Scalars['uuid'];
};


export type Subscription_RootProgress_Completed_Chapters_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Progress_Completed_Chapters_Stream_Cursor_Input>>;
  where?: InputMaybe<Progress_Completed_Chapters_Bool_Exp>;
};


export type Subscription_RootProgress_Completed_ContactpointsArgs = {
  distinct_on?: InputMaybe<Array<Progress_Completed_Contactpoints_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Progress_Completed_Contactpoints_Order_By>>;
  where?: InputMaybe<Progress_Completed_Contactpoints_Bool_Exp>;
};


export type Subscription_RootProgress_Completed_Contactpoints_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Progress_Completed_Contactpoints_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Progress_Completed_Contactpoints_Order_By>>;
  where?: InputMaybe<Progress_Completed_Contactpoints_Bool_Exp>;
};


export type Subscription_RootProgress_Completed_Contactpoints_By_PkArgs = {
  contactpoint_id: Scalars['Int'];
  user_id: Scalars['uuid'];
};


export type Subscription_RootProgress_Completed_Contactpoints_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Progress_Completed_Contactpoints_Stream_Cursor_Input>>;
  where?: InputMaybe<Progress_Completed_Contactpoints_Bool_Exp>;
};


export type Subscription_RootProgress_Completed_LectionsArgs = {
  distinct_on?: InputMaybe<Array<Progress_Completed_Lections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Progress_Completed_Lections_Order_By>>;
  where?: InputMaybe<Progress_Completed_Lections_Bool_Exp>;
};


export type Subscription_RootProgress_Completed_Lections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Progress_Completed_Lections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Progress_Completed_Lections_Order_By>>;
  where?: InputMaybe<Progress_Completed_Lections_Bool_Exp>;
};


export type Subscription_RootProgress_Completed_Lections_By_PkArgs = {
  lection_id: Scalars['Int'];
  user_id: Scalars['uuid'];
};


export type Subscription_RootProgress_Completed_Lections_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Progress_Completed_Lections_Stream_Cursor_Input>>;
  where?: InputMaybe<Progress_Completed_Lections_Bool_Exp>;
};


export type Subscription_RootProgress_Contactpoint_OverviewArgs = {
  distinct_on?: InputMaybe<Array<Progress_Contactpoint_Overview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Progress_Contactpoint_Overview_Order_By>>;
  where?: InputMaybe<Progress_Contactpoint_Overview_Bool_Exp>;
};


export type Subscription_RootProgress_Contactpoint_Overview_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Progress_Contactpoint_Overview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Progress_Contactpoint_Overview_Order_By>>;
  where?: InputMaybe<Progress_Contactpoint_Overview_Bool_Exp>;
};


export type Subscription_RootProgress_Contactpoint_Overview_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Progress_Contactpoint_Overview_Stream_Cursor_Input>>;
  where?: InputMaybe<Progress_Contactpoint_Overview_Bool_Exp>;
};


export type Subscription_RootProgress_Lection_OverviewArgs = {
  distinct_on?: InputMaybe<Array<Progress_Lection_Overview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Progress_Lection_Overview_Order_By>>;
  where?: InputMaybe<Progress_Lection_Overview_Bool_Exp>;
};


export type Subscription_RootProgress_Lection_Overview_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Progress_Lection_Overview_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Progress_Lection_Overview_Order_By>>;
  where?: InputMaybe<Progress_Lection_Overview_Bool_Exp>;
};


export type Subscription_RootProgress_Lection_Overview_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Progress_Lection_Overview_Stream_Cursor_Input>>;
  where?: InputMaybe<Progress_Lection_Overview_Bool_Exp>;
};


export type Subscription_RootRecommendation_Own_ArticlesArgs = {
  distinct_on?: InputMaybe<Array<Recommendation_Own_Articles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendation_Own_Articles_Order_By>>;
  where?: InputMaybe<Recommendation_Own_Articles_Bool_Exp>;
};


export type Subscription_RootRecommendation_Own_Articles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Recommendation_Own_Articles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendation_Own_Articles_Order_By>>;
  where?: InputMaybe<Recommendation_Own_Articles_Bool_Exp>;
};


export type Subscription_RootRecommendation_Own_Articles_By_PkArgs = {
  answer_id: Scalars['String'];
  article_id: Scalars['String'];
};


export type Subscription_RootRecommendation_Own_Articles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Recommendation_Own_Articles_Stream_Cursor_Input>>;
  where?: InputMaybe<Recommendation_Own_Articles_Bool_Exp>;
};


export type Subscription_RootRecommendation_Return_Search_ArticleArgs = {
  distinct_on?: InputMaybe<Array<Recommendation_Return_Search_Article_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendation_Return_Search_Article_Order_By>>;
  where?: InputMaybe<Recommendation_Return_Search_Article_Bool_Exp>;
};


export type Subscription_RootRecommendation_Return_Search_Article_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Recommendation_Return_Search_Article_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendation_Return_Search_Article_Order_By>>;
  where?: InputMaybe<Recommendation_Return_Search_Article_Bool_Exp>;
};


export type Subscription_RootRecommendation_Return_Search_Article_By_PkArgs = {
  article_id: Scalars['String'];
};


export type Subscription_RootRecommendation_Return_Search_Article_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Recommendation_Return_Search_Article_Stream_Cursor_Input>>;
  where?: InputMaybe<Recommendation_Return_Search_Article_Bool_Exp>;
};


export type Subscription_RootRecommendation_Search_ArticlesArgs = {
  args: Recommendation_Search_Articles_Args;
  distinct_on?: InputMaybe<Array<Recommendation_Return_Search_Article_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendation_Return_Search_Article_Order_By>>;
  where?: InputMaybe<Recommendation_Return_Search_Article_Bool_Exp>;
};


export type Subscription_RootRecommendation_Search_Articles_AggregateArgs = {
  args: Recommendation_Search_Articles_Args;
  distinct_on?: InputMaybe<Array<Recommendation_Return_Search_Article_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recommendation_Return_Search_Article_Order_By>>;
  where?: InputMaybe<Recommendation_Return_Search_Article_Bool_Exp>;
};


export type Subscription_RootRewards_Benefit_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Rewards_Benefit_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rewards_Benefit_Category_Order_By>>;
  where?: InputMaybe<Rewards_Benefit_Category_Bool_Exp>;
};


export type Subscription_RootRewards_Benefit_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rewards_Benefit_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rewards_Benefit_Category_Order_By>>;
  where?: InputMaybe<Rewards_Benefit_Category_Bool_Exp>;
};


export type Subscription_RootRewards_Benefit_Category_By_PkArgs = {
  category: Scalars['String'];
};


export type Subscription_RootRewards_Benefit_Category_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Rewards_Benefit_Category_Stream_Cursor_Input>>;
  where?: InputMaybe<Rewards_Benefit_Category_Bool_Exp>;
};


export type Subscription_RootRewards_BenefitsArgs = {
  distinct_on?: InputMaybe<Array<Rewards_Benefits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rewards_Benefits_Order_By>>;
  where?: InputMaybe<Rewards_Benefits_Bool_Exp>;
};


export type Subscription_RootRewards_Benefits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rewards_Benefits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rewards_Benefits_Order_By>>;
  where?: InputMaybe<Rewards_Benefits_Bool_Exp>;
};


export type Subscription_RootRewards_Benefits_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootRewards_Benefits_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Rewards_Benefits_Stream_Cursor_Input>>;
  where?: InputMaybe<Rewards_Benefits_Bool_Exp>;
};


export type Subscription_RootRewards_BoostsArgs = {
  distinct_on?: InputMaybe<Array<Rewards_Boosts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rewards_Boosts_Order_By>>;
  where?: InputMaybe<Rewards_Boosts_Bool_Exp>;
};


export type Subscription_RootRewards_Boosts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rewards_Boosts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rewards_Boosts_Order_By>>;
  where?: InputMaybe<Rewards_Boosts_Bool_Exp>;
};


export type Subscription_RootRewards_Boosts_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootRewards_Boosts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Rewards_Boosts_Stream_Cursor_Input>>;
  where?: InputMaybe<Rewards_Boosts_Bool_Exp>;
};


export type Subscription_RootRewards_Internal_RankingArgs = {
  distinct_on?: InputMaybe<Array<Rewards_Internal_Ranking_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rewards_Internal_Ranking_Order_By>>;
  where?: InputMaybe<Rewards_Internal_Ranking_Bool_Exp>;
};


export type Subscription_RootRewards_Internal_Ranking_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rewards_Internal_Ranking_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rewards_Internal_Ranking_Order_By>>;
  where?: InputMaybe<Rewards_Internal_Ranking_Bool_Exp>;
};


export type Subscription_RootRewards_Internal_Ranking_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Rewards_Internal_Ranking_Stream_Cursor_Input>>;
  where?: InputMaybe<Rewards_Internal_Ranking_Bool_Exp>;
};


export type Subscription_RootRewards_International_RankingArgs = {
  distinct_on?: InputMaybe<Array<Rewards_International_Ranking_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rewards_International_Ranking_Order_By>>;
  where?: InputMaybe<Rewards_International_Ranking_Bool_Exp>;
};


export type Subscription_RootRewards_International_Ranking_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rewards_International_Ranking_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rewards_International_Ranking_Order_By>>;
  where?: InputMaybe<Rewards_International_Ranking_Bool_Exp>;
};


export type Subscription_RootRewards_International_Ranking_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Rewards_International_Ranking_Stream_Cursor_Input>>;
  where?: InputMaybe<Rewards_International_Ranking_Bool_Exp>;
};


export type Subscription_RootRewards_Ranking_BalancesArgs = {
  distinct_on?: InputMaybe<Array<Rewards_Ranking_Balances_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rewards_Ranking_Balances_Order_By>>;
  where?: InputMaybe<Rewards_Ranking_Balances_Bool_Exp>;
};


export type Subscription_RootRewards_Ranking_Balances_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rewards_Ranking_Balances_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rewards_Ranking_Balances_Order_By>>;
  where?: InputMaybe<Rewards_Ranking_Balances_Bool_Exp>;
};


export type Subscription_RootRewards_Ranking_Balances_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Rewards_Ranking_Balances_Stream_Cursor_Input>>;
  where?: InputMaybe<Rewards_Ranking_Balances_Bool_Exp>;
};


export type Subscription_RootRewards_Reward_BalancesArgs = {
  distinct_on?: InputMaybe<Array<Rewards_Reward_Balances_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rewards_Reward_Balances_Order_By>>;
  where?: InputMaybe<Rewards_Reward_Balances_Bool_Exp>;
};


export type Subscription_RootRewards_Reward_Balances_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rewards_Reward_Balances_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rewards_Reward_Balances_Order_By>>;
  where?: InputMaybe<Rewards_Reward_Balances_Bool_Exp>;
};


export type Subscription_RootRewards_Reward_Balances_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Rewards_Reward_Balances_Stream_Cursor_Input>>;
  where?: InputMaybe<Rewards_Reward_Balances_Bool_Exp>;
};


export type Subscription_RootRewards_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<Rewards_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rewards_Transactions_Order_By>>;
  where?: InputMaybe<Rewards_Transactions_Bool_Exp>;
};


export type Subscription_RootRewards_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rewards_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rewards_Transactions_Order_By>>;
  where?: InputMaybe<Rewards_Transactions_Bool_Exp>;
};


export type Subscription_RootRewards_Transactions_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootRewards_Transactions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Rewards_Transactions_Stream_Cursor_Input>>;
  where?: InputMaybe<Rewards_Transactions_Bool_Exp>;
};


export type Subscription_RootSessionsArgs = {
  distinct_on?: InputMaybe<Array<Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sessions_Order_By>>;
  where?: InputMaybe<Sessions_Bool_Exp>;
};


export type Subscription_RootSessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sessions_Order_By>>;
  where?: InputMaybe<Sessions_Bool_Exp>;
};


export type Subscription_RootSessions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSessions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Sessions_Stream_Cursor_Input>>;
  where?: InputMaybe<Sessions_Bool_Exp>;
};


export type Subscription_RootSqbase_Event_Log_Event_TypesArgs = {
  distinct_on?: InputMaybe<Array<Sqbase_Event_Log_Event_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sqbase_Event_Log_Event_Types_Order_By>>;
  where?: InputMaybe<Sqbase_Event_Log_Event_Types_Bool_Exp>;
};


export type Subscription_RootSqbase_Event_Log_Event_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sqbase_Event_Log_Event_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sqbase_Event_Log_Event_Types_Order_By>>;
  where?: InputMaybe<Sqbase_Event_Log_Event_Types_Bool_Exp>;
};


export type Subscription_RootSqbase_Event_Log_Event_Types_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootSqbase_Event_Log_Event_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Sqbase_Event_Log_Event_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Sqbase_Event_Log_Event_Types_Bool_Exp>;
};


export type Subscription_RootSqbase_Event_Log_EventsArgs = {
  distinct_on?: InputMaybe<Array<Sqbase_Event_Log_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sqbase_Event_Log_Events_Order_By>>;
  where?: InputMaybe<Sqbase_Event_Log_Events_Bool_Exp>;
};


export type Subscription_RootSqbase_Event_Log_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sqbase_Event_Log_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sqbase_Event_Log_Events_Order_By>>;
  where?: InputMaybe<Sqbase_Event_Log_Events_Bool_Exp>;
};


export type Subscription_RootSqbase_Event_Log_Events_By_PkArgs = {
  created_at: Scalars['timestamptz'];
  type: Scalars['String'];
  user_id: Scalars['uuid'];
};


export type Subscription_RootSqbase_Event_Log_Events_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Sqbase_Event_Log_Events_Stream_Cursor_Input>>;
  where?: InputMaybe<Sqbase_Event_Log_Events_Bool_Exp>;
};


export type Subscription_RootSqlab_ContactsArgs = {
  distinct_on?: InputMaybe<Array<Sqlab_Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sqlab_Contacts_Order_By>>;
  where?: InputMaybe<Sqlab_Contacts_Bool_Exp>;
};


export type Subscription_RootSqlab_Contacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sqlab_Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sqlab_Contacts_Order_By>>;
  where?: InputMaybe<Sqlab_Contacts_Bool_Exp>;
};


export type Subscription_RootSqlab_Contacts_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootSqlab_Contacts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Sqlab_Contacts_Stream_Cursor_Input>>;
  where?: InputMaybe<Sqlab_Contacts_Bool_Exp>;
};


export type Subscription_RootSupport_Expiring_BoostsArgs = {
  distinct_on?: InputMaybe<Array<Support_Expiring_Boosts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Support_Expiring_Boosts_Order_By>>;
  where?: InputMaybe<Support_Expiring_Boosts_Bool_Exp>;
};


export type Subscription_RootSupport_Expiring_Boosts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Support_Expiring_Boosts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Support_Expiring_Boosts_Order_By>>;
  where?: InputMaybe<Support_Expiring_Boosts_Bool_Exp>;
};


export type Subscription_RootSupport_Expiring_Boosts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Support_Expiring_Boosts_Stream_Cursor_Input>>;
  where?: InputMaybe<Support_Expiring_Boosts_Bool_Exp>;
};


export type Subscription_RootSupport_Follow_Up_TasksArgs = {
  distinct_on?: InputMaybe<Array<Support_Follow_Up_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Support_Follow_Up_Tasks_Order_By>>;
  where?: InputMaybe<Support_Follow_Up_Tasks_Bool_Exp>;
};


export type Subscription_RootSupport_Follow_Up_Tasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Support_Follow_Up_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Support_Follow_Up_Tasks_Order_By>>;
  where?: InputMaybe<Support_Follow_Up_Tasks_Bool_Exp>;
};


export type Subscription_RootSupport_Follow_Up_Tasks_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Support_Follow_Up_Tasks_Stream_Cursor_Input>>;
  where?: InputMaybe<Support_Follow_Up_Tasks_Bool_Exp>;
};


export type Subscription_RootSupport_InteractionsArgs = {
  distinct_on?: InputMaybe<Array<Support_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Support_Interactions_Order_By>>;
  where?: InputMaybe<Support_Interactions_Bool_Exp>;
};


export type Subscription_RootSupport_Interactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Support_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Support_Interactions_Order_By>>;
  where?: InputMaybe<Support_Interactions_Bool_Exp>;
};


export type Subscription_RootSupport_Interactions_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootSupport_Interactions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Support_Interactions_Stream_Cursor_Input>>;
  where?: InputMaybe<Support_Interactions_Bool_Exp>;
};


export type Subscription_RootSupport_Registrations_Without_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Support_Registrations_Without_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Support_Registrations_Without_Answers_Order_By>>;
  where?: InputMaybe<Support_Registrations_Without_Answers_Bool_Exp>;
};


export type Subscription_RootSupport_Registrations_Without_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Support_Registrations_Without_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Support_Registrations_Without_Answers_Order_By>>;
  where?: InputMaybe<Support_Registrations_Without_Answers_Bool_Exp>;
};


export type Subscription_RootSupport_Registrations_Without_Answers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Support_Registrations_Without_Answers_Stream_Cursor_Input>>;
  where?: InputMaybe<Support_Registrations_Without_Answers_Bool_Exp>;
};


export type Subscription_RootSupport_Registrations_Without_Progress_Two_WeeksArgs = {
  distinct_on?: InputMaybe<Array<Support_Registrations_Without_Progress_Two_Weeks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Support_Registrations_Without_Progress_Two_Weeks_Order_By>>;
  where?: InputMaybe<Support_Registrations_Without_Progress_Two_Weeks_Bool_Exp>;
};


export type Subscription_RootSupport_Registrations_Without_Progress_Two_Weeks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Support_Registrations_Without_Progress_Two_Weeks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Support_Registrations_Without_Progress_Two_Weeks_Order_By>>;
  where?: InputMaybe<Support_Registrations_Without_Progress_Two_Weeks_Bool_Exp>;
};


export type Subscription_RootSupport_Registrations_Without_Progress_Two_Weeks_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Support_Registrations_Without_Progress_Two_Weeks_Stream_Cursor_Input>>;
  where?: InputMaybe<Support_Registrations_Without_Progress_Two_Weeks_Bool_Exp>;
};


export type Subscription_RootSupport_Vendor_SupportArgs = {
  distinct_on?: InputMaybe<Array<Support_Vendor_Support_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Support_Vendor_Support_Order_By>>;
  where?: InputMaybe<Support_Vendor_Support_Bool_Exp>;
};


export type Subscription_RootSupport_Vendor_Support_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Support_Vendor_Support_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Support_Vendor_Support_Order_By>>;
  where?: InputMaybe<Support_Vendor_Support_Bool_Exp>;
};


export type Subscription_RootSupport_Vendor_Support_By_PkArgs = {
  request_id: Scalars['uuid'];
};


export type Subscription_RootSupport_Vendor_Support_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Support_Vendor_Support_Stream_Cursor_Input>>;
  where?: InputMaybe<Support_Vendor_Support_Bool_Exp>;
};


export type Subscription_RootSupport_Vendor_Support_TypeArgs = {
  distinct_on?: InputMaybe<Array<Support_Vendor_Support_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Support_Vendor_Support_Type_Order_By>>;
  where?: InputMaybe<Support_Vendor_Support_Type_Bool_Exp>;
};


export type Subscription_RootSupport_Vendor_Support_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Support_Vendor_Support_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Support_Vendor_Support_Type_Order_By>>;
  where?: InputMaybe<Support_Vendor_Support_Type_Bool_Exp>;
};


export type Subscription_RootSupport_Vendor_Support_Type_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootSupport_Vendor_Support_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Support_Vendor_Support_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Support_Vendor_Support_Type_Bool_Exp>;
};


export type Subscription_RootTracker_Article_PricesArgs = {
  distinct_on?: InputMaybe<Array<Tracker_Article_Prices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tracker_Article_Prices_Order_By>>;
  where?: InputMaybe<Tracker_Article_Prices_Bool_Exp>;
};


export type Subscription_RootTracker_Article_Prices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tracker_Article_Prices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tracker_Article_Prices_Order_By>>;
  where?: InputMaybe<Tracker_Article_Prices_Bool_Exp>;
};


export type Subscription_RootTracker_Article_Prices_By_PkArgs = {
  created_at: Scalars['timestamptz'];
  ean: Scalars['String'];
  shop_domain: Scalars['String'];
};


export type Subscription_RootTracker_Article_Prices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tracker_Article_Prices_Stream_Cursor_Input>>;
  where?: InputMaybe<Tracker_Article_Prices_Bool_Exp>;
};


export type Subscription_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUser_DetailsArgs = {
  distinct_on?: InputMaybe<Array<User_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Details_Order_By>>;
  where?: InputMaybe<User_Details_Bool_Exp>;
};


export type Subscription_RootUser_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Details_Order_By>>;
  where?: InputMaybe<User_Details_Bool_Exp>;
};


export type Subscription_RootUser_Details_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Subscription_RootUser_Details_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Details_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Details_Bool_Exp>;
};


export type Subscription_RootUser_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** columns and relationships of "support.expiring_boosts" */
export type Support_Expiring_Boosts = {
  __typename?: 'support_expiring_boosts';
  comment?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['date']>;
  factor?: Maybe<Scalars['float8']>;
  start_date?: Maybe<Scalars['date']>;
  /** An object relationship */
  vendor?: Maybe<Organisation>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "support.expiring_boosts" */
export type Support_Expiring_Boosts_Aggregate = {
  __typename?: 'support_expiring_boosts_aggregate';
  aggregate?: Maybe<Support_Expiring_Boosts_Aggregate_Fields>;
  nodes: Array<Support_Expiring_Boosts>;
};

/** aggregate fields of "support.expiring_boosts" */
export type Support_Expiring_Boosts_Aggregate_Fields = {
  __typename?: 'support_expiring_boosts_aggregate_fields';
  avg?: Maybe<Support_Expiring_Boosts_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Support_Expiring_Boosts_Max_Fields>;
  min?: Maybe<Support_Expiring_Boosts_Min_Fields>;
  stddev?: Maybe<Support_Expiring_Boosts_Stddev_Fields>;
  stddev_pop?: Maybe<Support_Expiring_Boosts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Support_Expiring_Boosts_Stddev_Samp_Fields>;
  sum?: Maybe<Support_Expiring_Boosts_Sum_Fields>;
  var_pop?: Maybe<Support_Expiring_Boosts_Var_Pop_Fields>;
  var_samp?: Maybe<Support_Expiring_Boosts_Var_Samp_Fields>;
  variance?: Maybe<Support_Expiring_Boosts_Variance_Fields>;
};


/** aggregate fields of "support.expiring_boosts" */
export type Support_Expiring_Boosts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Support_Expiring_Boosts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Support_Expiring_Boosts_Avg_Fields = {
  __typename?: 'support_expiring_boosts_avg_fields';
  factor?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "support.expiring_boosts". All fields are combined with a logical 'AND'. */
export type Support_Expiring_Boosts_Bool_Exp = {
  _and?: InputMaybe<Array<Support_Expiring_Boosts_Bool_Exp>>;
  _not?: InputMaybe<Support_Expiring_Boosts_Bool_Exp>;
  _or?: InputMaybe<Array<Support_Expiring_Boosts_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  end_date?: InputMaybe<Date_Comparison_Exp>;
  factor?: InputMaybe<Float8_Comparison_Exp>;
  start_date?: InputMaybe<Date_Comparison_Exp>;
  vendor?: InputMaybe<Organisation_Bool_Exp>;
  vendor_id?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Support_Expiring_Boosts_Max_Fields = {
  __typename?: 'support_expiring_boosts_max_fields';
  comment?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['date']>;
  factor?: Maybe<Scalars['float8']>;
  start_date?: Maybe<Scalars['date']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Support_Expiring_Boosts_Min_Fields = {
  __typename?: 'support_expiring_boosts_min_fields';
  comment?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['date']>;
  factor?: Maybe<Scalars['float8']>;
  start_date?: Maybe<Scalars['date']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "support.expiring_boosts". */
export type Support_Expiring_Boosts_Order_By = {
  comment?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  factor?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  vendor?: InputMaybe<Organisation_Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** select columns of table "support.expiring_boosts" */
export enum Support_Expiring_Boosts_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Factor = 'factor',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  VendorId = 'vendor_id'
}

/** aggregate stddev on columns */
export type Support_Expiring_Boosts_Stddev_Fields = {
  __typename?: 'support_expiring_boosts_stddev_fields';
  factor?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Support_Expiring_Boosts_Stddev_Pop_Fields = {
  __typename?: 'support_expiring_boosts_stddev_pop_fields';
  factor?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Support_Expiring_Boosts_Stddev_Samp_Fields = {
  __typename?: 'support_expiring_boosts_stddev_samp_fields';
  factor?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "support_expiring_boosts" */
export type Support_Expiring_Boosts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Support_Expiring_Boosts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Support_Expiring_Boosts_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  end_date?: InputMaybe<Scalars['date']>;
  factor?: InputMaybe<Scalars['float8']>;
  start_date?: InputMaybe<Scalars['date']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Support_Expiring_Boosts_Sum_Fields = {
  __typename?: 'support_expiring_boosts_sum_fields';
  factor?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type Support_Expiring_Boosts_Var_Pop_Fields = {
  __typename?: 'support_expiring_boosts_var_pop_fields';
  factor?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Support_Expiring_Boosts_Var_Samp_Fields = {
  __typename?: 'support_expiring_boosts_var_samp_fields';
  factor?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Support_Expiring_Boosts_Variance_Fields = {
  __typename?: 'support_expiring_boosts_variance_fields';
  factor?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "support.follow_up_tasks" */
export type Support_Follow_Up_Tasks = {
  __typename?: 'support_follow_up_tasks';
  comment?: Maybe<Scalars['String']>;
  follow_up_at?: Maybe<Scalars['date']>;
  /** An object relationship */
  vendor?: Maybe<Organisation>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "support.follow_up_tasks" */
export type Support_Follow_Up_Tasks_Aggregate = {
  __typename?: 'support_follow_up_tasks_aggregate';
  aggregate?: Maybe<Support_Follow_Up_Tasks_Aggregate_Fields>;
  nodes: Array<Support_Follow_Up_Tasks>;
};

/** aggregate fields of "support.follow_up_tasks" */
export type Support_Follow_Up_Tasks_Aggregate_Fields = {
  __typename?: 'support_follow_up_tasks_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Support_Follow_Up_Tasks_Max_Fields>;
  min?: Maybe<Support_Follow_Up_Tasks_Min_Fields>;
};


/** aggregate fields of "support.follow_up_tasks" */
export type Support_Follow_Up_Tasks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Support_Follow_Up_Tasks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "support.follow_up_tasks". All fields are combined with a logical 'AND'. */
export type Support_Follow_Up_Tasks_Bool_Exp = {
  _and?: InputMaybe<Array<Support_Follow_Up_Tasks_Bool_Exp>>;
  _not?: InputMaybe<Support_Follow_Up_Tasks_Bool_Exp>;
  _or?: InputMaybe<Array<Support_Follow_Up_Tasks_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  follow_up_at?: InputMaybe<Date_Comparison_Exp>;
  vendor?: InputMaybe<Organisation_Bool_Exp>;
  vendor_id?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Support_Follow_Up_Tasks_Max_Fields = {
  __typename?: 'support_follow_up_tasks_max_fields';
  comment?: Maybe<Scalars['String']>;
  follow_up_at?: Maybe<Scalars['date']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Support_Follow_Up_Tasks_Min_Fields = {
  __typename?: 'support_follow_up_tasks_min_fields';
  comment?: Maybe<Scalars['String']>;
  follow_up_at?: Maybe<Scalars['date']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "support.follow_up_tasks". */
export type Support_Follow_Up_Tasks_Order_By = {
  comment?: InputMaybe<Order_By>;
  follow_up_at?: InputMaybe<Order_By>;
  vendor?: InputMaybe<Organisation_Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** select columns of table "support.follow_up_tasks" */
export enum Support_Follow_Up_Tasks_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  FollowUpAt = 'follow_up_at',
  /** column name */
  VendorId = 'vendor_id'
}

/** Streaming cursor of the table "support_follow_up_tasks" */
export type Support_Follow_Up_Tasks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Support_Follow_Up_Tasks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Support_Follow_Up_Tasks_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  follow_up_at?: InputMaybe<Scalars['date']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** Tracks all interactions between SQlab and the vendors, e.g. phone calls or visits in the shop. */
export type Support_Interactions = {
  __typename?: 'support_interactions';
  comment?: Maybe<Scalars['String']>;
  contact_person?: Maybe<Scalars['String']>;
  contacted_at: Scalars['date'];
  context?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  follow_up_at?: Maybe<Scalars['date']>;
  id: Scalars['Int'];
  /** An object relationship */
  team_member?: Maybe<Sqlab_Contacts>;
  team_member_name: Scalars['String'];
  type: Scalars['String'];
  /** An object relationship */
  vendor?: Maybe<Organisation>;
  vendor_id: Scalars['String'];
};

/** aggregated selection of "support.interactions" */
export type Support_Interactions_Aggregate = {
  __typename?: 'support_interactions_aggregate';
  aggregate?: Maybe<Support_Interactions_Aggregate_Fields>;
  nodes: Array<Support_Interactions>;
};

/** aggregate fields of "support.interactions" */
export type Support_Interactions_Aggregate_Fields = {
  __typename?: 'support_interactions_aggregate_fields';
  avg?: Maybe<Support_Interactions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Support_Interactions_Max_Fields>;
  min?: Maybe<Support_Interactions_Min_Fields>;
  stddev?: Maybe<Support_Interactions_Stddev_Fields>;
  stddev_pop?: Maybe<Support_Interactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Support_Interactions_Stddev_Samp_Fields>;
  sum?: Maybe<Support_Interactions_Sum_Fields>;
  var_pop?: Maybe<Support_Interactions_Var_Pop_Fields>;
  var_samp?: Maybe<Support_Interactions_Var_Samp_Fields>;
  variance?: Maybe<Support_Interactions_Variance_Fields>;
};


/** aggregate fields of "support.interactions" */
export type Support_Interactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Support_Interactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Support_Interactions_Avg_Fields = {
  __typename?: 'support_interactions_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "support.interactions". All fields are combined with a logical 'AND'. */
export type Support_Interactions_Bool_Exp = {
  _and?: InputMaybe<Array<Support_Interactions_Bool_Exp>>;
  _not?: InputMaybe<Support_Interactions_Bool_Exp>;
  _or?: InputMaybe<Array<Support_Interactions_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  contact_person?: InputMaybe<String_Comparison_Exp>;
  contacted_at?: InputMaybe<Date_Comparison_Exp>;
  context?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  follow_up_at?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  team_member?: InputMaybe<Sqlab_Contacts_Bool_Exp>;
  team_member_name?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  vendor?: InputMaybe<Organisation_Bool_Exp>;
  vendor_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "support.interactions" */
export enum Support_Interactions_Constraint {
  /** unique or primary key constraint on columns "id" */
  InteractionsPkey = 'interactions_pkey'
}

/** input type for incrementing numeric columns in table "support.interactions" */
export type Support_Interactions_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "support.interactions" */
export type Support_Interactions_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  contact_person?: InputMaybe<Scalars['String']>;
  contacted_at?: InputMaybe<Scalars['date']>;
  context?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  follow_up_at?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  team_member?: InputMaybe<Sqlab_Contacts_Obj_Rel_Insert_Input>;
  team_member_name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  vendor?: InputMaybe<Organisation_Obj_Rel_Insert_Input>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Support_Interactions_Max_Fields = {
  __typename?: 'support_interactions_max_fields';
  comment?: Maybe<Scalars['String']>;
  contact_person?: Maybe<Scalars['String']>;
  contacted_at?: Maybe<Scalars['date']>;
  context?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  follow_up_at?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  team_member_name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Support_Interactions_Min_Fields = {
  __typename?: 'support_interactions_min_fields';
  comment?: Maybe<Scalars['String']>;
  contact_person?: Maybe<Scalars['String']>;
  contacted_at?: Maybe<Scalars['date']>;
  context?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  follow_up_at?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  team_member_name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "support.interactions" */
export type Support_Interactions_Mutation_Response = {
  __typename?: 'support_interactions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Support_Interactions>;
};

/** on_conflict condition type for table "support.interactions" */
export type Support_Interactions_On_Conflict = {
  constraint: Support_Interactions_Constraint;
  update_columns?: Array<Support_Interactions_Update_Column>;
  where?: InputMaybe<Support_Interactions_Bool_Exp>;
};

/** Ordering options when selecting data from "support.interactions". */
export type Support_Interactions_Order_By = {
  comment?: InputMaybe<Order_By>;
  contact_person?: InputMaybe<Order_By>;
  contacted_at?: InputMaybe<Order_By>;
  context?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  follow_up_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_member?: InputMaybe<Sqlab_Contacts_Order_By>;
  team_member_name?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  vendor?: InputMaybe<Organisation_Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: support.interactions */
export type Support_Interactions_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "support.interactions" */
export enum Support_Interactions_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  ContactPerson = 'contact_person',
  /** column name */
  ContactedAt = 'contacted_at',
  /** column name */
  Context = 'context',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FollowUpAt = 'follow_up_at',
  /** column name */
  Id = 'id',
  /** column name */
  TeamMemberName = 'team_member_name',
  /** column name */
  Type = 'type',
  /** column name */
  VendorId = 'vendor_id'
}

/** input type for updating data in table "support.interactions" */
export type Support_Interactions_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  contact_person?: InputMaybe<Scalars['String']>;
  contacted_at?: InputMaybe<Scalars['date']>;
  context?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  follow_up_at?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  team_member_name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Support_Interactions_Stddev_Fields = {
  __typename?: 'support_interactions_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Support_Interactions_Stddev_Pop_Fields = {
  __typename?: 'support_interactions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Support_Interactions_Stddev_Samp_Fields = {
  __typename?: 'support_interactions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "support_interactions" */
export type Support_Interactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Support_Interactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Support_Interactions_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  contact_person?: InputMaybe<Scalars['String']>;
  contacted_at?: InputMaybe<Scalars['date']>;
  context?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  follow_up_at?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  team_member_name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Support_Interactions_Sum_Fields = {
  __typename?: 'support_interactions_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "support.interactions" */
export enum Support_Interactions_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  ContactPerson = 'contact_person',
  /** column name */
  ContactedAt = 'contacted_at',
  /** column name */
  Context = 'context',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FollowUpAt = 'follow_up_at',
  /** column name */
  Id = 'id',
  /** column name */
  TeamMemberName = 'team_member_name',
  /** column name */
  Type = 'type',
  /** column name */
  VendorId = 'vendor_id'
}

export type Support_Interactions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Support_Interactions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Support_Interactions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Support_Interactions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Support_Interactions_Var_Pop_Fields = {
  __typename?: 'support_interactions_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Support_Interactions_Var_Samp_Fields = {
  __typename?: 'support_interactions_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Support_Interactions_Variance_Fields = {
  __typename?: 'support_interactions_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "support.registrations_without_answers" */
export type Support_Registrations_Without_Answers = {
  __typename?: 'support_registrations_without_answers';
  country?: Maybe<Scalars['String']>;
  email_address?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  ipad_available?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  registration_email_sent_at?: Maybe<Scalars['timestamptz']>;
  surname?: Maybe<Scalars['String']>;
  /** An object relationship */
  vendor?: Maybe<Organisation>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "support.registrations_without_answers" */
export type Support_Registrations_Without_Answers_Aggregate = {
  __typename?: 'support_registrations_without_answers_aggregate';
  aggregate?: Maybe<Support_Registrations_Without_Answers_Aggregate_Fields>;
  nodes: Array<Support_Registrations_Without_Answers>;
};

/** aggregate fields of "support.registrations_without_answers" */
export type Support_Registrations_Without_Answers_Aggregate_Fields = {
  __typename?: 'support_registrations_without_answers_aggregate_fields';
  avg?: Maybe<Support_Registrations_Without_Answers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Support_Registrations_Without_Answers_Max_Fields>;
  min?: Maybe<Support_Registrations_Without_Answers_Min_Fields>;
  stddev?: Maybe<Support_Registrations_Without_Answers_Stddev_Fields>;
  stddev_pop?: Maybe<Support_Registrations_Without_Answers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Support_Registrations_Without_Answers_Stddev_Samp_Fields>;
  sum?: Maybe<Support_Registrations_Without_Answers_Sum_Fields>;
  var_pop?: Maybe<Support_Registrations_Without_Answers_Var_Pop_Fields>;
  var_samp?: Maybe<Support_Registrations_Without_Answers_Var_Samp_Fields>;
  variance?: Maybe<Support_Registrations_Without_Answers_Variance_Fields>;
};


/** aggregate fields of "support.registrations_without_answers" */
export type Support_Registrations_Without_Answers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Support_Registrations_Without_Answers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Support_Registrations_Without_Answers_Avg_Fields = {
  __typename?: 'support_registrations_without_answers_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "support.registrations_without_answers". All fields are combined with a logical 'AND'. */
export type Support_Registrations_Without_Answers_Bool_Exp = {
  _and?: InputMaybe<Array<Support_Registrations_Without_Answers_Bool_Exp>>;
  _not?: InputMaybe<Support_Registrations_Without_Answers_Bool_Exp>;
  _or?: InputMaybe<Array<Support_Registrations_Without_Answers_Bool_Exp>>;
  country?: InputMaybe<String_Comparison_Exp>;
  email_address?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  ipad_available?: InputMaybe<Boolean_Comparison_Exp>;
  language?: InputMaybe<String_Comparison_Exp>;
  registration_email_sent_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  surname?: InputMaybe<String_Comparison_Exp>;
  vendor?: InputMaybe<Organisation_Bool_Exp>;
  vendor_id?: InputMaybe<String_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "support.registrations_without_answers" */
export type Support_Registrations_Without_Answers_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "support.registrations_without_answers" */
export type Support_Registrations_Without_Answers_Insert_Input = {
  country?: InputMaybe<Scalars['String']>;
  email_address?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  ipad_available?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  registration_email_sent_at?: InputMaybe<Scalars['timestamptz']>;
  surname?: InputMaybe<Scalars['String']>;
  vendor?: InputMaybe<Organisation_Obj_Rel_Insert_Input>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Support_Registrations_Without_Answers_Max_Fields = {
  __typename?: 'support_registrations_without_answers_max_fields';
  country?: Maybe<Scalars['String']>;
  email_address?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['String']>;
  registration_email_sent_at?: Maybe<Scalars['timestamptz']>;
  surname?: Maybe<Scalars['String']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Support_Registrations_Without_Answers_Min_Fields = {
  __typename?: 'support_registrations_without_answers_min_fields';
  country?: Maybe<Scalars['String']>;
  email_address?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['String']>;
  registration_email_sent_at?: Maybe<Scalars['timestamptz']>;
  surname?: Maybe<Scalars['String']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "support.registrations_without_answers" */
export type Support_Registrations_Without_Answers_Mutation_Response = {
  __typename?: 'support_registrations_without_answers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Support_Registrations_Without_Answers>;
};

/** Ordering options when selecting data from "support.registrations_without_answers". */
export type Support_Registrations_Without_Answers_Order_By = {
  country?: InputMaybe<Order_By>;
  email_address?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ipad_available?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  registration_email_sent_at?: InputMaybe<Order_By>;
  surname?: InputMaybe<Order_By>;
  vendor?: InputMaybe<Organisation_Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** select columns of table "support.registrations_without_answers" */
export enum Support_Registrations_Without_Answers_Select_Column {
  /** column name */
  Country = 'country',
  /** column name */
  EmailAddress = 'email_address',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IpadAvailable = 'ipad_available',
  /** column name */
  Language = 'language',
  /** column name */
  RegistrationEmailSentAt = 'registration_email_sent_at',
  /** column name */
  Surname = 'surname',
  /** column name */
  VendorId = 'vendor_id'
}

/** input type for updating data in table "support.registrations_without_answers" */
export type Support_Registrations_Without_Answers_Set_Input = {
  country?: InputMaybe<Scalars['String']>;
  email_address?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  ipad_available?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  registration_email_sent_at?: InputMaybe<Scalars['timestamptz']>;
  surname?: InputMaybe<Scalars['String']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Support_Registrations_Without_Answers_Stddev_Fields = {
  __typename?: 'support_registrations_without_answers_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Support_Registrations_Without_Answers_Stddev_Pop_Fields = {
  __typename?: 'support_registrations_without_answers_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Support_Registrations_Without_Answers_Stddev_Samp_Fields = {
  __typename?: 'support_registrations_without_answers_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "support_registrations_without_answers" */
export type Support_Registrations_Without_Answers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Support_Registrations_Without_Answers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Support_Registrations_Without_Answers_Stream_Cursor_Value_Input = {
  country?: InputMaybe<Scalars['String']>;
  email_address?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  ipad_available?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  registration_email_sent_at?: InputMaybe<Scalars['timestamptz']>;
  surname?: InputMaybe<Scalars['String']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Support_Registrations_Without_Answers_Sum_Fields = {
  __typename?: 'support_registrations_without_answers_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

export type Support_Registrations_Without_Answers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Support_Registrations_Without_Answers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Support_Registrations_Without_Answers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Support_Registrations_Without_Answers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Support_Registrations_Without_Answers_Var_Pop_Fields = {
  __typename?: 'support_registrations_without_answers_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Support_Registrations_Without_Answers_Var_Samp_Fields = {
  __typename?: 'support_registrations_without_answers_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Support_Registrations_Without_Answers_Variance_Fields = {
  __typename?: 'support_registrations_without_answers_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "support.registrations_without_progress_two_weeks" */
export type Support_Registrations_Without_Progress_Two_Weeks = {
  __typename?: 'support_registrations_without_progress_two_weeks';
  answer_count?: Maybe<Scalars['bigint']>;
  country?: Maybe<Scalars['String']>;
  email_address?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  ipad_available?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  registration_email_sent_at?: Maybe<Scalars['timestamptz']>;
  surname?: Maybe<Scalars['String']>;
  /** An object relationship */
  vendor?: Maybe<Organisation>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "support.registrations_without_progress_two_weeks" */
export type Support_Registrations_Without_Progress_Two_Weeks_Aggregate = {
  __typename?: 'support_registrations_without_progress_two_weeks_aggregate';
  aggregate?: Maybe<Support_Registrations_Without_Progress_Two_Weeks_Aggregate_Fields>;
  nodes: Array<Support_Registrations_Without_Progress_Two_Weeks>;
};

/** aggregate fields of "support.registrations_without_progress_two_weeks" */
export type Support_Registrations_Without_Progress_Two_Weeks_Aggregate_Fields = {
  __typename?: 'support_registrations_without_progress_two_weeks_aggregate_fields';
  avg?: Maybe<Support_Registrations_Without_Progress_Two_Weeks_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Support_Registrations_Without_Progress_Two_Weeks_Max_Fields>;
  min?: Maybe<Support_Registrations_Without_Progress_Two_Weeks_Min_Fields>;
  stddev?: Maybe<Support_Registrations_Without_Progress_Two_Weeks_Stddev_Fields>;
  stddev_pop?: Maybe<Support_Registrations_Without_Progress_Two_Weeks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Support_Registrations_Without_Progress_Two_Weeks_Stddev_Samp_Fields>;
  sum?: Maybe<Support_Registrations_Without_Progress_Two_Weeks_Sum_Fields>;
  var_pop?: Maybe<Support_Registrations_Without_Progress_Two_Weeks_Var_Pop_Fields>;
  var_samp?: Maybe<Support_Registrations_Without_Progress_Two_Weeks_Var_Samp_Fields>;
  variance?: Maybe<Support_Registrations_Without_Progress_Two_Weeks_Variance_Fields>;
};


/** aggregate fields of "support.registrations_without_progress_two_weeks" */
export type Support_Registrations_Without_Progress_Two_Weeks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Support_Registrations_Without_Progress_Two_Weeks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Support_Registrations_Without_Progress_Two_Weeks_Avg_Fields = {
  __typename?: 'support_registrations_without_progress_two_weeks_avg_fields';
  answer_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "support.registrations_without_progress_two_weeks". All fields are combined with a logical 'AND'. */
export type Support_Registrations_Without_Progress_Two_Weeks_Bool_Exp = {
  _and?: InputMaybe<Array<Support_Registrations_Without_Progress_Two_Weeks_Bool_Exp>>;
  _not?: InputMaybe<Support_Registrations_Without_Progress_Two_Weeks_Bool_Exp>;
  _or?: InputMaybe<Array<Support_Registrations_Without_Progress_Two_Weeks_Bool_Exp>>;
  answer_count?: InputMaybe<Bigint_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  email_address?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  ipad_available?: InputMaybe<Boolean_Comparison_Exp>;
  language?: InputMaybe<String_Comparison_Exp>;
  registration_email_sent_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  surname?: InputMaybe<String_Comparison_Exp>;
  vendor?: InputMaybe<Organisation_Bool_Exp>;
  vendor_id?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Support_Registrations_Without_Progress_Two_Weeks_Max_Fields = {
  __typename?: 'support_registrations_without_progress_two_weeks_max_fields';
  answer_count?: Maybe<Scalars['bigint']>;
  country?: Maybe<Scalars['String']>;
  email_address?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['String']>;
  registration_email_sent_at?: Maybe<Scalars['timestamptz']>;
  surname?: Maybe<Scalars['String']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Support_Registrations_Without_Progress_Two_Weeks_Min_Fields = {
  __typename?: 'support_registrations_without_progress_two_weeks_min_fields';
  answer_count?: Maybe<Scalars['bigint']>;
  country?: Maybe<Scalars['String']>;
  email_address?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['String']>;
  registration_email_sent_at?: Maybe<Scalars['timestamptz']>;
  surname?: Maybe<Scalars['String']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "support.registrations_without_progress_two_weeks". */
export type Support_Registrations_Without_Progress_Two_Weeks_Order_By = {
  answer_count?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  email_address?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ipad_available?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  registration_email_sent_at?: InputMaybe<Order_By>;
  surname?: InputMaybe<Order_By>;
  vendor?: InputMaybe<Organisation_Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** select columns of table "support.registrations_without_progress_two_weeks" */
export enum Support_Registrations_Without_Progress_Two_Weeks_Select_Column {
  /** column name */
  AnswerCount = 'answer_count',
  /** column name */
  Country = 'country',
  /** column name */
  EmailAddress = 'email_address',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IpadAvailable = 'ipad_available',
  /** column name */
  Language = 'language',
  /** column name */
  RegistrationEmailSentAt = 'registration_email_sent_at',
  /** column name */
  Surname = 'surname',
  /** column name */
  VendorId = 'vendor_id'
}

/** aggregate stddev on columns */
export type Support_Registrations_Without_Progress_Two_Weeks_Stddev_Fields = {
  __typename?: 'support_registrations_without_progress_two_weeks_stddev_fields';
  answer_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Support_Registrations_Without_Progress_Two_Weeks_Stddev_Pop_Fields = {
  __typename?: 'support_registrations_without_progress_two_weeks_stddev_pop_fields';
  answer_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Support_Registrations_Without_Progress_Two_Weeks_Stddev_Samp_Fields = {
  __typename?: 'support_registrations_without_progress_two_weeks_stddev_samp_fields';
  answer_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "support_registrations_without_progress_two_weeks" */
export type Support_Registrations_Without_Progress_Two_Weeks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Support_Registrations_Without_Progress_Two_Weeks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Support_Registrations_Without_Progress_Two_Weeks_Stream_Cursor_Value_Input = {
  answer_count?: InputMaybe<Scalars['bigint']>;
  country?: InputMaybe<Scalars['String']>;
  email_address?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  ipad_available?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  registration_email_sent_at?: InputMaybe<Scalars['timestamptz']>;
  surname?: InputMaybe<Scalars['String']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Support_Registrations_Without_Progress_Two_Weeks_Sum_Fields = {
  __typename?: 'support_registrations_without_progress_two_weeks_sum_fields';
  answer_count?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Support_Registrations_Without_Progress_Two_Weeks_Var_Pop_Fields = {
  __typename?: 'support_registrations_without_progress_two_weeks_var_pop_fields';
  answer_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Support_Registrations_Without_Progress_Two_Weeks_Var_Samp_Fields = {
  __typename?: 'support_registrations_without_progress_two_weeks_var_samp_fields';
  answer_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Support_Registrations_Without_Progress_Two_Weeks_Variance_Fields = {
  __typename?: 'support_registrations_without_progress_two_weeks_variance_fields';
  answer_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Used to track support request of vendors */
export type Support_Vendor_Support = {
  __typename?: 'support_vendor_support';
  created_at?: Maybe<Scalars['timestamptz']>;
  request: Scalars['String'];
  request_id: Scalars['uuid'];
  request_type: Support_Vendor_Support_Type_Enum;
  user_id: Scalars['uuid'];
  vendor_id: Scalars['String'];
};

/** aggregated selection of "support.vendor_support" */
export type Support_Vendor_Support_Aggregate = {
  __typename?: 'support_vendor_support_aggregate';
  aggregate?: Maybe<Support_Vendor_Support_Aggregate_Fields>;
  nodes: Array<Support_Vendor_Support>;
};

/** aggregate fields of "support.vendor_support" */
export type Support_Vendor_Support_Aggregate_Fields = {
  __typename?: 'support_vendor_support_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Support_Vendor_Support_Max_Fields>;
  min?: Maybe<Support_Vendor_Support_Min_Fields>;
};


/** aggregate fields of "support.vendor_support" */
export type Support_Vendor_Support_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Support_Vendor_Support_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "support.vendor_support". All fields are combined with a logical 'AND'. */
export type Support_Vendor_Support_Bool_Exp = {
  _and?: InputMaybe<Array<Support_Vendor_Support_Bool_Exp>>;
  _not?: InputMaybe<Support_Vendor_Support_Bool_Exp>;
  _or?: InputMaybe<Array<Support_Vendor_Support_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  request?: InputMaybe<String_Comparison_Exp>;
  request_id?: InputMaybe<Uuid_Comparison_Exp>;
  request_type?: InputMaybe<Support_Vendor_Support_Type_Enum_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  vendor_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "support.vendor_support" */
export enum Support_Vendor_Support_Constraint {
  /** unique or primary key constraint on columns "request_id" */
  VendorSupportPkey = 'vendor_support_pkey'
}

/** input type for inserting data into table "support.vendor_support" */
export type Support_Vendor_Support_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  request?: InputMaybe<Scalars['String']>;
  request_id?: InputMaybe<Scalars['uuid']>;
  request_type?: InputMaybe<Support_Vendor_Support_Type_Enum>;
  user_id?: InputMaybe<Scalars['uuid']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Support_Vendor_Support_Max_Fields = {
  __typename?: 'support_vendor_support_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  request?: Maybe<Scalars['String']>;
  request_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Support_Vendor_Support_Min_Fields = {
  __typename?: 'support_vendor_support_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  request?: Maybe<Scalars['String']>;
  request_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "support.vendor_support" */
export type Support_Vendor_Support_Mutation_Response = {
  __typename?: 'support_vendor_support_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Support_Vendor_Support>;
};

/** on_conflict condition type for table "support.vendor_support" */
export type Support_Vendor_Support_On_Conflict = {
  constraint: Support_Vendor_Support_Constraint;
  update_columns?: Array<Support_Vendor_Support_Update_Column>;
  where?: InputMaybe<Support_Vendor_Support_Bool_Exp>;
};

/** Ordering options when selecting data from "support.vendor_support". */
export type Support_Vendor_Support_Order_By = {
  created_at?: InputMaybe<Order_By>;
  request?: InputMaybe<Order_By>;
  request_id?: InputMaybe<Order_By>;
  request_type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: support.vendor_support */
export type Support_Vendor_Support_Pk_Columns_Input = {
  request_id: Scalars['uuid'];
};

/** select columns of table "support.vendor_support" */
export enum Support_Vendor_Support_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Request = 'request',
  /** column name */
  RequestId = 'request_id',
  /** column name */
  RequestType = 'request_type',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VendorId = 'vendor_id'
}

/** input type for updating data in table "support.vendor_support" */
export type Support_Vendor_Support_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  request?: InputMaybe<Scalars['String']>;
  request_id?: InputMaybe<Scalars['uuid']>;
  request_type?: InputMaybe<Support_Vendor_Support_Type_Enum>;
  user_id?: InputMaybe<Scalars['uuid']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "support_vendor_support" */
export type Support_Vendor_Support_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Support_Vendor_Support_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Support_Vendor_Support_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  request?: InputMaybe<Scalars['String']>;
  request_id?: InputMaybe<Scalars['uuid']>;
  request_type?: InputMaybe<Support_Vendor_Support_Type_Enum>;
  user_id?: InputMaybe<Scalars['uuid']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** Types of vendor support requests */
export type Support_Vendor_Support_Type = {
  __typename?: 'support_vendor_support_type';
  name: Scalars['String'];
};

/** aggregated selection of "support.vendor_support_type" */
export type Support_Vendor_Support_Type_Aggregate = {
  __typename?: 'support_vendor_support_type_aggregate';
  aggregate?: Maybe<Support_Vendor_Support_Type_Aggregate_Fields>;
  nodes: Array<Support_Vendor_Support_Type>;
};

/** aggregate fields of "support.vendor_support_type" */
export type Support_Vendor_Support_Type_Aggregate_Fields = {
  __typename?: 'support_vendor_support_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Support_Vendor_Support_Type_Max_Fields>;
  min?: Maybe<Support_Vendor_Support_Type_Min_Fields>;
};


/** aggregate fields of "support.vendor_support_type" */
export type Support_Vendor_Support_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Support_Vendor_Support_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "support.vendor_support_type". All fields are combined with a logical 'AND'. */
export type Support_Vendor_Support_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Support_Vendor_Support_Type_Bool_Exp>>;
  _not?: InputMaybe<Support_Vendor_Support_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Support_Vendor_Support_Type_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "support.vendor_support_type" */
export enum Support_Vendor_Support_Type_Constraint {
  /** unique or primary key constraint on columns "name" */
  VendorSupportTypePkey = 'vendor_support_type_pkey'
}

export enum Support_Vendor_Support_Type_Enum {
  Bikefitting = 'BIKEFITTING',
  General = 'GENERAL',
  Other = 'OTHER'
}

/** Boolean expression to compare columns of type "support_vendor_support_type_enum". All fields are combined with logical 'AND'. */
export type Support_Vendor_Support_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Support_Vendor_Support_Type_Enum>;
  _in?: InputMaybe<Array<Support_Vendor_Support_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Support_Vendor_Support_Type_Enum>;
  _nin?: InputMaybe<Array<Support_Vendor_Support_Type_Enum>>;
};

/** input type for inserting data into table "support.vendor_support_type" */
export type Support_Vendor_Support_Type_Insert_Input = {
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Support_Vendor_Support_Type_Max_Fields = {
  __typename?: 'support_vendor_support_type_max_fields';
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Support_Vendor_Support_Type_Min_Fields = {
  __typename?: 'support_vendor_support_type_min_fields';
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "support.vendor_support_type" */
export type Support_Vendor_Support_Type_Mutation_Response = {
  __typename?: 'support_vendor_support_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Support_Vendor_Support_Type>;
};

/** on_conflict condition type for table "support.vendor_support_type" */
export type Support_Vendor_Support_Type_On_Conflict = {
  constraint: Support_Vendor_Support_Type_Constraint;
  update_columns?: Array<Support_Vendor_Support_Type_Update_Column>;
  where?: InputMaybe<Support_Vendor_Support_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "support.vendor_support_type". */
export type Support_Vendor_Support_Type_Order_By = {
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: support.vendor_support_type */
export type Support_Vendor_Support_Type_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "support.vendor_support_type" */
export enum Support_Vendor_Support_Type_Select_Column {
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "support.vendor_support_type" */
export type Support_Vendor_Support_Type_Set_Input = {
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "support_vendor_support_type" */
export type Support_Vendor_Support_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Support_Vendor_Support_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Support_Vendor_Support_Type_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "support.vendor_support_type" */
export enum Support_Vendor_Support_Type_Update_Column {
  /** column name */
  Name = 'name'
}

export type Support_Vendor_Support_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Support_Vendor_Support_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Support_Vendor_Support_Type_Bool_Exp;
};

/** update columns of table "support.vendor_support" */
export enum Support_Vendor_Support_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Request = 'request',
  /** column name */
  RequestId = 'request_id',
  /** column name */
  RequestType = 'request_type',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VendorId = 'vendor_id'
}

export type Support_Vendor_Support_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Support_Vendor_Support_Set_Input>;
  /** filter the rows which have to be updated */
  where: Support_Vendor_Support_Bool_Exp;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** In this table the retail prices of SQlab articles on the web and the difference to the SQlab price recommendation are stored. */
export type Tracker_Article_Prices = {
  __typename?: 'tracker_article_prices';
  article_id: Scalars['String'];
  article_name: Scalars['String'];
  article_size: Scalars['String'];
  article_url: Scalars['String'];
  created_at: Scalars['timestamptz'];
  /** Absolute difference of prices at the time of checking */
  difference: Scalars['float8'];
  /** European Article Number */
  ean: Scalars['String'];
  /** Article price offered by the shop at the time of checking */
  price_shop: Scalars['float8'];
  /** Article price specified by SQlab at the time of checking */
  price_sqlab: Scalars['float8'];
  shop_domain: Scalars['String'];
  shop_name: Scalars['String'];
};

/** aggregated selection of "tracker.article_prices" */
export type Tracker_Article_Prices_Aggregate = {
  __typename?: 'tracker_article_prices_aggregate';
  aggregate?: Maybe<Tracker_Article_Prices_Aggregate_Fields>;
  nodes: Array<Tracker_Article_Prices>;
};

/** aggregate fields of "tracker.article_prices" */
export type Tracker_Article_Prices_Aggregate_Fields = {
  __typename?: 'tracker_article_prices_aggregate_fields';
  avg?: Maybe<Tracker_Article_Prices_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tracker_Article_Prices_Max_Fields>;
  min?: Maybe<Tracker_Article_Prices_Min_Fields>;
  stddev?: Maybe<Tracker_Article_Prices_Stddev_Fields>;
  stddev_pop?: Maybe<Tracker_Article_Prices_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tracker_Article_Prices_Stddev_Samp_Fields>;
  sum?: Maybe<Tracker_Article_Prices_Sum_Fields>;
  var_pop?: Maybe<Tracker_Article_Prices_Var_Pop_Fields>;
  var_samp?: Maybe<Tracker_Article_Prices_Var_Samp_Fields>;
  variance?: Maybe<Tracker_Article_Prices_Variance_Fields>;
};


/** aggregate fields of "tracker.article_prices" */
export type Tracker_Article_Prices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tracker_Article_Prices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Tracker_Article_Prices_Avg_Fields = {
  __typename?: 'tracker_article_prices_avg_fields';
  /** Absolute difference of prices at the time of checking */
  difference?: Maybe<Scalars['Float']>;
  /** Article price offered by the shop at the time of checking */
  price_shop?: Maybe<Scalars['Float']>;
  /** Article price specified by SQlab at the time of checking */
  price_sqlab?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "tracker.article_prices". All fields are combined with a logical 'AND'. */
export type Tracker_Article_Prices_Bool_Exp = {
  _and?: InputMaybe<Array<Tracker_Article_Prices_Bool_Exp>>;
  _not?: InputMaybe<Tracker_Article_Prices_Bool_Exp>;
  _or?: InputMaybe<Array<Tracker_Article_Prices_Bool_Exp>>;
  article_id?: InputMaybe<String_Comparison_Exp>;
  article_name?: InputMaybe<String_Comparison_Exp>;
  article_size?: InputMaybe<String_Comparison_Exp>;
  article_url?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  difference?: InputMaybe<Float8_Comparison_Exp>;
  ean?: InputMaybe<String_Comparison_Exp>;
  price_shop?: InputMaybe<Float8_Comparison_Exp>;
  price_sqlab?: InputMaybe<Float8_Comparison_Exp>;
  shop_domain?: InputMaybe<String_Comparison_Exp>;
  shop_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "tracker.article_prices" */
export enum Tracker_Article_Prices_Constraint {
  /** unique or primary key constraint on columns "ean", "created_at", "shop_domain" */
  ArticlePricesPkey = 'article_prices_pkey'
}

/** input type for incrementing numeric columns in table "tracker.article_prices" */
export type Tracker_Article_Prices_Inc_Input = {
  /** Absolute difference of prices at the time of checking */
  difference?: InputMaybe<Scalars['float8']>;
  /** Article price offered by the shop at the time of checking */
  price_shop?: InputMaybe<Scalars['float8']>;
  /** Article price specified by SQlab at the time of checking */
  price_sqlab?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "tracker.article_prices" */
export type Tracker_Article_Prices_Insert_Input = {
  article_id?: InputMaybe<Scalars['String']>;
  article_name?: InputMaybe<Scalars['String']>;
  article_size?: InputMaybe<Scalars['String']>;
  article_url?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** Absolute difference of prices at the time of checking */
  difference?: InputMaybe<Scalars['float8']>;
  /** European Article Number */
  ean?: InputMaybe<Scalars['String']>;
  /** Article price offered by the shop at the time of checking */
  price_shop?: InputMaybe<Scalars['float8']>;
  /** Article price specified by SQlab at the time of checking */
  price_sqlab?: InputMaybe<Scalars['float8']>;
  shop_domain?: InputMaybe<Scalars['String']>;
  shop_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Tracker_Article_Prices_Max_Fields = {
  __typename?: 'tracker_article_prices_max_fields';
  article_id?: Maybe<Scalars['String']>;
  article_name?: Maybe<Scalars['String']>;
  article_size?: Maybe<Scalars['String']>;
  article_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Absolute difference of prices at the time of checking */
  difference?: Maybe<Scalars['float8']>;
  /** European Article Number */
  ean?: Maybe<Scalars['String']>;
  /** Article price offered by the shop at the time of checking */
  price_shop?: Maybe<Scalars['float8']>;
  /** Article price specified by SQlab at the time of checking */
  price_sqlab?: Maybe<Scalars['float8']>;
  shop_domain?: Maybe<Scalars['String']>;
  shop_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Tracker_Article_Prices_Min_Fields = {
  __typename?: 'tracker_article_prices_min_fields';
  article_id?: Maybe<Scalars['String']>;
  article_name?: Maybe<Scalars['String']>;
  article_size?: Maybe<Scalars['String']>;
  article_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Absolute difference of prices at the time of checking */
  difference?: Maybe<Scalars['float8']>;
  /** European Article Number */
  ean?: Maybe<Scalars['String']>;
  /** Article price offered by the shop at the time of checking */
  price_shop?: Maybe<Scalars['float8']>;
  /** Article price specified by SQlab at the time of checking */
  price_sqlab?: Maybe<Scalars['float8']>;
  shop_domain?: Maybe<Scalars['String']>;
  shop_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "tracker.article_prices" */
export type Tracker_Article_Prices_Mutation_Response = {
  __typename?: 'tracker_article_prices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tracker_Article_Prices>;
};

/** on_conflict condition type for table "tracker.article_prices" */
export type Tracker_Article_Prices_On_Conflict = {
  constraint: Tracker_Article_Prices_Constraint;
  update_columns?: Array<Tracker_Article_Prices_Update_Column>;
  where?: InputMaybe<Tracker_Article_Prices_Bool_Exp>;
};

/** Ordering options when selecting data from "tracker.article_prices". */
export type Tracker_Article_Prices_Order_By = {
  article_id?: InputMaybe<Order_By>;
  article_name?: InputMaybe<Order_By>;
  article_size?: InputMaybe<Order_By>;
  article_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  difference?: InputMaybe<Order_By>;
  ean?: InputMaybe<Order_By>;
  price_shop?: InputMaybe<Order_By>;
  price_sqlab?: InputMaybe<Order_By>;
  shop_domain?: InputMaybe<Order_By>;
  shop_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tracker.article_prices */
export type Tracker_Article_Prices_Pk_Columns_Input = {
  created_at: Scalars['timestamptz'];
  /** European Article Number */
  ean: Scalars['String'];
  shop_domain: Scalars['String'];
};

/** select columns of table "tracker.article_prices" */
export enum Tracker_Article_Prices_Select_Column {
  /** column name */
  ArticleId = 'article_id',
  /** column name */
  ArticleName = 'article_name',
  /** column name */
  ArticleSize = 'article_size',
  /** column name */
  ArticleUrl = 'article_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Difference = 'difference',
  /** column name */
  Ean = 'ean',
  /** column name */
  PriceShop = 'price_shop',
  /** column name */
  PriceSqlab = 'price_sqlab',
  /** column name */
  ShopDomain = 'shop_domain',
  /** column name */
  ShopName = 'shop_name'
}

/** input type for updating data in table "tracker.article_prices" */
export type Tracker_Article_Prices_Set_Input = {
  article_id?: InputMaybe<Scalars['String']>;
  article_name?: InputMaybe<Scalars['String']>;
  article_size?: InputMaybe<Scalars['String']>;
  article_url?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** Absolute difference of prices at the time of checking */
  difference?: InputMaybe<Scalars['float8']>;
  /** European Article Number */
  ean?: InputMaybe<Scalars['String']>;
  /** Article price offered by the shop at the time of checking */
  price_shop?: InputMaybe<Scalars['float8']>;
  /** Article price specified by SQlab at the time of checking */
  price_sqlab?: InputMaybe<Scalars['float8']>;
  shop_domain?: InputMaybe<Scalars['String']>;
  shop_name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Tracker_Article_Prices_Stddev_Fields = {
  __typename?: 'tracker_article_prices_stddev_fields';
  /** Absolute difference of prices at the time of checking */
  difference?: Maybe<Scalars['Float']>;
  /** Article price offered by the shop at the time of checking */
  price_shop?: Maybe<Scalars['Float']>;
  /** Article price specified by SQlab at the time of checking */
  price_sqlab?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Tracker_Article_Prices_Stddev_Pop_Fields = {
  __typename?: 'tracker_article_prices_stddev_pop_fields';
  /** Absolute difference of prices at the time of checking */
  difference?: Maybe<Scalars['Float']>;
  /** Article price offered by the shop at the time of checking */
  price_shop?: Maybe<Scalars['Float']>;
  /** Article price specified by SQlab at the time of checking */
  price_sqlab?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Tracker_Article_Prices_Stddev_Samp_Fields = {
  __typename?: 'tracker_article_prices_stddev_samp_fields';
  /** Absolute difference of prices at the time of checking */
  difference?: Maybe<Scalars['Float']>;
  /** Article price offered by the shop at the time of checking */
  price_shop?: Maybe<Scalars['Float']>;
  /** Article price specified by SQlab at the time of checking */
  price_sqlab?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "tracker_article_prices" */
export type Tracker_Article_Prices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tracker_Article_Prices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tracker_Article_Prices_Stream_Cursor_Value_Input = {
  article_id?: InputMaybe<Scalars['String']>;
  article_name?: InputMaybe<Scalars['String']>;
  article_size?: InputMaybe<Scalars['String']>;
  article_url?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** Absolute difference of prices at the time of checking */
  difference?: InputMaybe<Scalars['float8']>;
  /** European Article Number */
  ean?: InputMaybe<Scalars['String']>;
  /** Article price offered by the shop at the time of checking */
  price_shop?: InputMaybe<Scalars['float8']>;
  /** Article price specified by SQlab at the time of checking */
  price_sqlab?: InputMaybe<Scalars['float8']>;
  shop_domain?: InputMaybe<Scalars['String']>;
  shop_name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Tracker_Article_Prices_Sum_Fields = {
  __typename?: 'tracker_article_prices_sum_fields';
  /** Absolute difference of prices at the time of checking */
  difference?: Maybe<Scalars['float8']>;
  /** Article price offered by the shop at the time of checking */
  price_shop?: Maybe<Scalars['float8']>;
  /** Article price specified by SQlab at the time of checking */
  price_sqlab?: Maybe<Scalars['float8']>;
};

/** update columns of table "tracker.article_prices" */
export enum Tracker_Article_Prices_Update_Column {
  /** column name */
  ArticleId = 'article_id',
  /** column name */
  ArticleName = 'article_name',
  /** column name */
  ArticleSize = 'article_size',
  /** column name */
  ArticleUrl = 'article_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Difference = 'difference',
  /** column name */
  Ean = 'ean',
  /** column name */
  PriceShop = 'price_shop',
  /** column name */
  PriceSqlab = 'price_sqlab',
  /** column name */
  ShopDomain = 'shop_domain',
  /** column name */
  ShopName = 'shop_name'
}

export type Tracker_Article_Prices_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tracker_Article_Prices_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tracker_Article_Prices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tracker_Article_Prices_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tracker_Article_Prices_Var_Pop_Fields = {
  __typename?: 'tracker_article_prices_var_pop_fields';
  /** Absolute difference of prices at the time of checking */
  difference?: Maybe<Scalars['Float']>;
  /** Article price offered by the shop at the time of checking */
  price_shop?: Maybe<Scalars['Float']>;
  /** Article price specified by SQlab at the time of checking */
  price_sqlab?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Tracker_Article_Prices_Var_Samp_Fields = {
  __typename?: 'tracker_article_prices_var_samp_fields';
  /** Absolute difference of prices at the time of checking */
  difference?: Maybe<Scalars['Float']>;
  /** Article price offered by the shop at the time of checking */
  price_shop?: Maybe<Scalars['Float']>;
  /** Article price specified by SQlab at the time of checking */
  price_sqlab?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Tracker_Article_Prices_Variance_Fields = {
  __typename?: 'tracker_article_prices_variance_fields';
  /** Absolute difference of prices at the time of checking */
  difference?: Maybe<Scalars['Float']>;
  /** Article price offered by the shop at the time of checking */
  price_shop?: Maybe<Scalars['Float']>;
  /** Article price specified by SQlab at the time of checking */
  price_sqlab?: Maybe<Scalars['Float']>;
};

/** represents a user with an login */
export type User = {
  __typename?: 'user';
  account_number?: Maybe<Scalars['String']>;
  avatar_url?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  deactivated_at?: Maybe<Scalars['timestamp']>;
  email_address?: Maybe<Scalars['String']>;
  first_name: Scalars['String'];
  /** random id for identifying a user. */
  id: Scalars['uuid'];
  is_temporary_password: Scalars['Boolean'];
  language: Scalars['String'];
  last_login_at?: Maybe<Scalars['timestamp']>;
  name: Scalars['String'];
  /** An object relationship */
  organisation: Organisation;
  /** assigned organisation */
  organisation_id: Scalars['String'];
  /** this text is a pin in our case. */
  password?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  /** defines if the user account is protected or not. */
  secured: Scalars['Boolean'];
  surname: Scalars['String'];
};

/** aggregated selection of "user" */
export type User_Aggregate = {
  __typename?: 'user_aggregate';
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

export type User_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<User_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<User_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<User_Aggregate_Bool_Exp_Count>;
};

export type User_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Select_Column_User_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Select_Column_User_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  __typename?: 'user_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
};


/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user" */
export type User_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Max_Order_By>;
  min?: InputMaybe<User_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user" */
export type User_Arr_Rel_Insert_Input = {
  data: Array<User_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: InputMaybe<Array<User_Bool_Exp>>;
  _not?: InputMaybe<User_Bool_Exp>;
  _or?: InputMaybe<Array<User_Bool_Exp>>;
  account_number?: InputMaybe<String_Comparison_Exp>;
  avatar_url?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deactivated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  email_address?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_temporary_password?: InputMaybe<Boolean_Comparison_Exp>;
  language?: InputMaybe<String_Comparison_Exp>;
  last_login_at?: InputMaybe<Timestamp_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organisation?: InputMaybe<Organisation_Bool_Exp>;
  organisation_id?: InputMaybe<String_Comparison_Exp>;
  password?: InputMaybe<String_Comparison_Exp>;
  password_hash?: InputMaybe<String_Comparison_Exp>;
  secured?: InputMaybe<Boolean_Comparison_Exp>;
  surname?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint on columns "account_number" */
  UserAccountNumberKey = 'user_account_number_key',
  /** unique or primary key constraint on columns "id" */
  UserPkey = 'user_pkey'
}

/** holds default information about a user */
export type User_Details = {
  __typename?: 'user_details';
  /** An array relationship */
  completed_chapters: Array<Progress_Completed_Chapters>;
  /** An aggregate relationship */
  completed_chapters_aggregate: Progress_Completed_Chapters_Aggregate;
  /** An array relationship */
  completed_contactpoints: Array<Progress_Completed_Contactpoints>;
  /** An aggregate relationship */
  completed_contactpoints_aggregate: Progress_Completed_Contactpoints_Aggregate;
  /** An array relationship */
  completed_lections: Array<Progress_Completed_Lections>;
  /** An aggregate relationship */
  completed_lections_aggregate: Progress_Completed_Lections_Aggregate;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  distributor_id?: Maybe<Scalars['String']>;
  email_address?: Maybe<Scalars['String']>;
  exp_points: Scalars['Int'];
  /** An object relationship */
  level: Content_Levels;
  level_id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  /** An array relationship */
  sessions: Array<Sessions>;
  /** An aggregate relationship */
  sessions_aggregate: Sessions_Aggregate;
  shop_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user?: Maybe<User>;
  user_id: Scalars['uuid'];
  vendor_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  vouchers: Array<Iqlab_Rewards_Vouchers>;
  /** An aggregate relationship */
  vouchers_aggregate: Iqlab_Rewards_Vouchers_Aggregate;
};


/** holds default information about a user */
export type User_DetailsCompleted_ChaptersArgs = {
  distinct_on?: InputMaybe<Array<Progress_Completed_Chapters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Progress_Completed_Chapters_Order_By>>;
  where?: InputMaybe<Progress_Completed_Chapters_Bool_Exp>;
};


/** holds default information about a user */
export type User_DetailsCompleted_Chapters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Progress_Completed_Chapters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Progress_Completed_Chapters_Order_By>>;
  where?: InputMaybe<Progress_Completed_Chapters_Bool_Exp>;
};


/** holds default information about a user */
export type User_DetailsCompleted_ContactpointsArgs = {
  distinct_on?: InputMaybe<Array<Progress_Completed_Contactpoints_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Progress_Completed_Contactpoints_Order_By>>;
  where?: InputMaybe<Progress_Completed_Contactpoints_Bool_Exp>;
};


/** holds default information about a user */
export type User_DetailsCompleted_Contactpoints_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Progress_Completed_Contactpoints_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Progress_Completed_Contactpoints_Order_By>>;
  where?: InputMaybe<Progress_Completed_Contactpoints_Bool_Exp>;
};


/** holds default information about a user */
export type User_DetailsCompleted_LectionsArgs = {
  distinct_on?: InputMaybe<Array<Progress_Completed_Lections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Progress_Completed_Lections_Order_By>>;
  where?: InputMaybe<Progress_Completed_Lections_Bool_Exp>;
};


/** holds default information about a user */
export type User_DetailsCompleted_Lections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Progress_Completed_Lections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Progress_Completed_Lections_Order_By>>;
  where?: InputMaybe<Progress_Completed_Lections_Bool_Exp>;
};


/** holds default information about a user */
export type User_DetailsSessionsArgs = {
  distinct_on?: InputMaybe<Array<Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sessions_Order_By>>;
  where?: InputMaybe<Sessions_Bool_Exp>;
};


/** holds default information about a user */
export type User_DetailsSessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sessions_Order_By>>;
  where?: InputMaybe<Sessions_Bool_Exp>;
};


/** holds default information about a user */
export type User_DetailsVouchersArgs = {
  distinct_on?: InputMaybe<Array<Iqlab_Rewards_Vouchers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Iqlab_Rewards_Vouchers_Order_By>>;
  where?: InputMaybe<Iqlab_Rewards_Vouchers_Bool_Exp>;
};


/** holds default information about a user */
export type User_DetailsVouchers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Iqlab_Rewards_Vouchers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Iqlab_Rewards_Vouchers_Order_By>>;
  where?: InputMaybe<Iqlab_Rewards_Vouchers_Bool_Exp>;
};

/** aggregated selection of "user_details" */
export type User_Details_Aggregate = {
  __typename?: 'user_details_aggregate';
  aggregate?: Maybe<User_Details_Aggregate_Fields>;
  nodes: Array<User_Details>;
};

/** aggregate fields of "user_details" */
export type User_Details_Aggregate_Fields = {
  __typename?: 'user_details_aggregate_fields';
  avg?: Maybe<User_Details_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Details_Max_Fields>;
  min?: Maybe<User_Details_Min_Fields>;
  stddev?: Maybe<User_Details_Stddev_Fields>;
  stddev_pop?: Maybe<User_Details_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Details_Stddev_Samp_Fields>;
  sum?: Maybe<User_Details_Sum_Fields>;
  var_pop?: Maybe<User_Details_Var_Pop_Fields>;
  var_samp?: Maybe<User_Details_Var_Samp_Fields>;
  variance?: Maybe<User_Details_Variance_Fields>;
};


/** aggregate fields of "user_details" */
export type User_Details_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Details_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type User_Details_Avg_Fields = {
  __typename?: 'user_details_avg_fields';
  exp_points?: Maybe<Scalars['Float']>;
  level_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user_details". All fields are combined with a logical 'AND'. */
export type User_Details_Bool_Exp = {
  _and?: InputMaybe<Array<User_Details_Bool_Exp>>;
  _not?: InputMaybe<User_Details_Bool_Exp>;
  _or?: InputMaybe<Array<User_Details_Bool_Exp>>;
  completed_chapters?: InputMaybe<Progress_Completed_Chapters_Bool_Exp>;
  completed_chapters_aggregate?: InputMaybe<Progress_Completed_Chapters_Aggregate_Bool_Exp>;
  completed_contactpoints?: InputMaybe<Progress_Completed_Contactpoints_Bool_Exp>;
  completed_contactpoints_aggregate?: InputMaybe<Progress_Completed_Contactpoints_Aggregate_Bool_Exp>;
  completed_lections?: InputMaybe<Progress_Completed_Lections_Bool_Exp>;
  completed_lections_aggregate?: InputMaybe<Progress_Completed_Lections_Aggregate_Bool_Exp>;
  country_code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  distributor_id?: InputMaybe<String_Comparison_Exp>;
  email_address?: InputMaybe<String_Comparison_Exp>;
  exp_points?: InputMaybe<Int_Comparison_Exp>;
  level?: InputMaybe<Content_Levels_Bool_Exp>;
  level_id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  sessions?: InputMaybe<Sessions_Bool_Exp>;
  sessions_aggregate?: InputMaybe<Sessions_Aggregate_Bool_Exp>;
  shop_name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  vendor_id?: InputMaybe<String_Comparison_Exp>;
  vouchers?: InputMaybe<Iqlab_Rewards_Vouchers_Bool_Exp>;
  vouchers_aggregate?: InputMaybe<Iqlab_Rewards_Vouchers_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "user_details" */
export enum User_Details_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  UserDetailsPkey = 'user_details_pkey'
}

/** input type for incrementing numeric columns in table "user_details" */
export type User_Details_Inc_Input = {
  exp_points?: InputMaybe<Scalars['Int']>;
  level_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_details" */
export type User_Details_Insert_Input = {
  completed_chapters?: InputMaybe<Progress_Completed_Chapters_Arr_Rel_Insert_Input>;
  completed_contactpoints?: InputMaybe<Progress_Completed_Contactpoints_Arr_Rel_Insert_Input>;
  completed_lections?: InputMaybe<Progress_Completed_Lections_Arr_Rel_Insert_Input>;
  country_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  distributor_id?: InputMaybe<Scalars['String']>;
  email_address?: InputMaybe<Scalars['String']>;
  exp_points?: InputMaybe<Scalars['Int']>;
  level?: InputMaybe<Content_Levels_Obj_Rel_Insert_Input>;
  level_id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  sessions?: InputMaybe<Sessions_Arr_Rel_Insert_Input>;
  shop_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
  vendor_id?: InputMaybe<Scalars['String']>;
  vouchers?: InputMaybe<Iqlab_Rewards_Vouchers_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Details_Max_Fields = {
  __typename?: 'user_details_max_fields';
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  distributor_id?: Maybe<Scalars['String']>;
  email_address?: Maybe<Scalars['String']>;
  exp_points?: Maybe<Scalars['Int']>;
  level_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  shop_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type User_Details_Min_Fields = {
  __typename?: 'user_details_min_fields';
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  distributor_id?: Maybe<Scalars['String']>;
  email_address?: Maybe<Scalars['String']>;
  exp_points?: Maybe<Scalars['Int']>;
  level_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  shop_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "user_details" */
export type User_Details_Mutation_Response = {
  __typename?: 'user_details_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Details>;
};

/** input type for inserting object relation for remote table "user_details" */
export type User_Details_Obj_Rel_Insert_Input = {
  data: User_Details_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Details_On_Conflict>;
};

/** on_conflict condition type for table "user_details" */
export type User_Details_On_Conflict = {
  constraint: User_Details_Constraint;
  update_columns?: Array<User_Details_Update_Column>;
  where?: InputMaybe<User_Details_Bool_Exp>;
};

/** Ordering options when selecting data from "user_details". */
export type User_Details_Order_By = {
  completed_chapters_aggregate?: InputMaybe<Progress_Completed_Chapters_Aggregate_Order_By>;
  completed_contactpoints_aggregate?: InputMaybe<Progress_Completed_Contactpoints_Aggregate_Order_By>;
  completed_lections_aggregate?: InputMaybe<Progress_Completed_Lections_Aggregate_Order_By>;
  country_code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  distributor_id?: InputMaybe<Order_By>;
  email_address?: InputMaybe<Order_By>;
  exp_points?: InputMaybe<Order_By>;
  level?: InputMaybe<Content_Levels_Order_By>;
  level_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  sessions_aggregate?: InputMaybe<Sessions_Aggregate_Order_By>;
  shop_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
  vouchers_aggregate?: InputMaybe<Iqlab_Rewards_Vouchers_Aggregate_Order_By>;
};

/** primary key columns input for table: user_details */
export type User_Details_Pk_Columns_Input = {
  user_id: Scalars['uuid'];
};

/** select columns of table "user_details" */
export enum User_Details_Select_Column {
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DistributorId = 'distributor_id',
  /** column name */
  EmailAddress = 'email_address',
  /** column name */
  ExpPoints = 'exp_points',
  /** column name */
  LevelId = 'level_id',
  /** column name */
  Name = 'name',
  /** column name */
  ShopName = 'shop_name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VendorId = 'vendor_id'
}

/** input type for updating data in table "user_details" */
export type User_Details_Set_Input = {
  country_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  distributor_id?: InputMaybe<Scalars['String']>;
  email_address?: InputMaybe<Scalars['String']>;
  exp_points?: InputMaybe<Scalars['Int']>;
  level_id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  shop_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type User_Details_Stddev_Fields = {
  __typename?: 'user_details_stddev_fields';
  exp_points?: Maybe<Scalars['Float']>;
  level_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Details_Stddev_Pop_Fields = {
  __typename?: 'user_details_stddev_pop_fields';
  exp_points?: Maybe<Scalars['Float']>;
  level_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Details_Stddev_Samp_Fields = {
  __typename?: 'user_details_stddev_samp_fields';
  exp_points?: Maybe<Scalars['Float']>;
  level_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user_details" */
export type User_Details_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Details_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Details_Stream_Cursor_Value_Input = {
  country_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  distributor_id?: InputMaybe<Scalars['String']>;
  email_address?: InputMaybe<Scalars['String']>;
  exp_points?: InputMaybe<Scalars['Int']>;
  level_id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  shop_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type User_Details_Sum_Fields = {
  __typename?: 'user_details_sum_fields';
  exp_points?: Maybe<Scalars['Int']>;
  level_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "user_details" */
export enum User_Details_Update_Column {
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DistributorId = 'distributor_id',
  /** column name */
  EmailAddress = 'email_address',
  /** column name */
  ExpPoints = 'exp_points',
  /** column name */
  LevelId = 'level_id',
  /** column name */
  Name = 'name',
  /** column name */
  ShopName = 'shop_name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VendorId = 'vendor_id'
}

export type User_Details_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Details_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Details_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Details_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Details_Var_Pop_Fields = {
  __typename?: 'user_details_var_pop_fields';
  exp_points?: Maybe<Scalars['Float']>;
  level_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Details_Var_Samp_Fields = {
  __typename?: 'user_details_var_samp_fields';
  exp_points?: Maybe<Scalars['Float']>;
  level_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Details_Variance_Fields = {
  __typename?: 'user_details_variance_fields';
  exp_points?: Maybe<Scalars['Float']>;
  level_id?: Maybe<Scalars['Float']>;
};

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  account_number?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deactivated_at?: InputMaybe<Scalars['timestamp']>;
  email_address?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  /** random id for identifying a user. */
  id?: InputMaybe<Scalars['uuid']>;
  is_temporary_password?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  last_login_at?: InputMaybe<Scalars['timestamp']>;
  name?: InputMaybe<Scalars['String']>;
  organisation?: InputMaybe<Organisation_Obj_Rel_Insert_Input>;
  /** assigned organisation */
  organisation_id?: InputMaybe<Scalars['String']>;
  /** this text is a pin in our case. */
  password?: InputMaybe<Scalars['String']>;
  password_hash?: InputMaybe<Scalars['String']>;
  /** defines if the user account is protected or not. */
  secured?: InputMaybe<Scalars['Boolean']>;
  surname?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'user_max_fields';
  account_number?: Maybe<Scalars['String']>;
  avatar_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deactivated_at?: Maybe<Scalars['timestamp']>;
  email_address?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  /** random id for identifying a user. */
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  last_login_at?: Maybe<Scalars['timestamp']>;
  name?: Maybe<Scalars['String']>;
  /** assigned organisation */
  organisation_id?: Maybe<Scalars['String']>;
  /** this text is a pin in our case. */
  password?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "user" */
export type User_Max_Order_By = {
  account_number?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deactivated_at?: InputMaybe<Order_By>;
  email_address?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  /** random id for identifying a user. */
  id?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  last_login_at?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  /** assigned organisation */
  organisation_id?: InputMaybe<Order_By>;
  /** this text is a pin in our case. */
  password?: InputMaybe<Order_By>;
  password_hash?: InputMaybe<Order_By>;
  surname?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'user_min_fields';
  account_number?: Maybe<Scalars['String']>;
  avatar_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deactivated_at?: Maybe<Scalars['timestamp']>;
  email_address?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  /** random id for identifying a user. */
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  last_login_at?: Maybe<Scalars['timestamp']>;
  name?: Maybe<Scalars['String']>;
  /** assigned organisation */
  organisation_id?: Maybe<Scalars['String']>;
  /** this text is a pin in our case. */
  password?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "user" */
export type User_Min_Order_By = {
  account_number?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deactivated_at?: InputMaybe<Order_By>;
  email_address?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  /** random id for identifying a user. */
  id?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  last_login_at?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  /** assigned organisation */
  organisation_id?: InputMaybe<Order_By>;
  /** this text is a pin in our case. */
  password?: InputMaybe<Order_By>;
  password_hash?: InputMaybe<Order_By>;
  surname?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  __typename?: 'user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User>;
};

/** input type for inserting object relation for remote table "user" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_On_Conflict>;
};

/** on_conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns?: Array<User_Update_Column>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** Ordering options when selecting data from "user". */
export type User_Order_By = {
  account_number?: InputMaybe<Order_By>;
  avatar_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deactivated_at?: InputMaybe<Order_By>;
  email_address?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_temporary_password?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  last_login_at?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation?: InputMaybe<Organisation_Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  password?: InputMaybe<Order_By>;
  password_hash?: InputMaybe<Order_By>;
  secured?: InputMaybe<Order_By>;
  surname?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user */
export type User_Pk_Columns_Input = {
  /** random id for identifying a user. */
  id: Scalars['uuid'];
};

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  AccountNumber = 'account_number',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeactivatedAt = 'deactivated_at',
  /** column name */
  EmailAddress = 'email_address',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsTemporaryPassword = 'is_temporary_password',
  /** column name */
  Language = 'language',
  /** column name */
  LastLoginAt = 'last_login_at',
  /** column name */
  Name = 'name',
  /** column name */
  OrganisationId = 'organisation_id',
  /** column name */
  Password = 'password',
  /** column name */
  PasswordHash = 'password_hash',
  /** column name */
  Secured = 'secured',
  /** column name */
  Surname = 'surname'
}

/** select "user_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user" */
export enum User_Select_Column_User_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsTemporaryPassword = 'is_temporary_password',
  /** column name */
  Secured = 'secured'
}

/** select "user_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user" */
export enum User_Select_Column_User_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsTemporaryPassword = 'is_temporary_password',
  /** column name */
  Secured = 'secured'
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  account_number?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deactivated_at?: InputMaybe<Scalars['timestamp']>;
  email_address?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  /** random id for identifying a user. */
  id?: InputMaybe<Scalars['uuid']>;
  is_temporary_password?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  last_login_at?: InputMaybe<Scalars['timestamp']>;
  name?: InputMaybe<Scalars['String']>;
  /** assigned organisation */
  organisation_id?: InputMaybe<Scalars['String']>;
  /** this text is a pin in our case. */
  password?: InputMaybe<Scalars['String']>;
  password_hash?: InputMaybe<Scalars['String']>;
  /** defines if the user account is protected or not. */
  secured?: InputMaybe<Scalars['Boolean']>;
  surname?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "user" */
export type User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Stream_Cursor_Value_Input = {
  account_number?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deactivated_at?: InputMaybe<Scalars['timestamp']>;
  email_address?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  /** random id for identifying a user. */
  id?: InputMaybe<Scalars['uuid']>;
  is_temporary_password?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  last_login_at?: InputMaybe<Scalars['timestamp']>;
  name?: InputMaybe<Scalars['String']>;
  /** assigned organisation */
  organisation_id?: InputMaybe<Scalars['String']>;
  /** this text is a pin in our case. */
  password?: InputMaybe<Scalars['String']>;
  password_hash?: InputMaybe<Scalars['String']>;
  /** defines if the user account is protected or not. */
  secured?: InputMaybe<Scalars['Boolean']>;
  surname?: InputMaybe<Scalars['String']>;
};

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  AccountNumber = 'account_number',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeactivatedAt = 'deactivated_at',
  /** column name */
  EmailAddress = 'email_address',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsTemporaryPassword = 'is_temporary_password',
  /** column name */
  Language = 'language',
  /** column name */
  LastLoginAt = 'last_login_at',
  /** column name */
  Name = 'name',
  /** column name */
  OrganisationId = 'organisation_id',
  /** column name */
  Password = 'password',
  /** column name */
  PasswordHash = 'password_hash',
  /** column name */
  Secured = 'secured',
  /** column name */
  Surname = 'surname'
}

export type User_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Bool_Exp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

export type QuestionnaireCreateResultFragment = { __typename?: 'customer_answer', id: string, short_id?: string | null };

export type CreateNewQuestionnaireMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateNewQuestionnaireMutation = { __typename?: 'mutation_root', createAnswer?: { __typename?: 'AnswerResponse', customer_answer?: { __typename?: 'customer_answer', id: string, short_id?: string | null } | null } | null };

export type CustomerAnswerSubscriptionVariables = Exact<{
  id: Scalars['String'];
}>;


export type CustomerAnswerSubscription = { __typename?: 'subscription_root', customerAnswer: Array<{ __typename?: 'customer_answer', articleGroups?: any | null, bicycleTypeId?: Answer_Enums_Bicycle_Type_Enum | null, bodyHeight?: any | null }> };

export type DeleteQuestionnaireMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteQuestionnaireMutation = { __typename?: 'mutation_root', delete_customer_answer_by_pk?: { __typename?: 'customer_answer', id: string } | null };

export type EvaluateCustomerAnswerMutationVariables = Exact<{
  customerAnswerId: Scalars['String'];
}>;


export type EvaluateCustomerAnswerMutation = { __typename?: 'mutation_root', evaluateResponse?: { __typename?: 'EvaluateResponse', success?: boolean | null } | null };

export type GetRecommendedProductsQueryVariables = Exact<{
  answer_id: Scalars['String'];
  language: Country_Code_Enum;
}>;


export type GetRecommendedProductsQuery = { __typename?: 'query_root', recommendation_own_articles: Array<{ __typename?: 'recommendation_own_articles', fitting: any, reasons: any, article_informaton_i18n: Array<{ __typename?: 'article_information_i18n', name?: string | null, brand: string, description?: string | null, images?: any | null, price?: any | null, article_type: Article_Type_Enum, article_id: string, size?: string | null, shop_url?: string | null }>, answer: { __typename?: 'customer_answer', bicycle_type_id?: Answer_Enums_Bicycle_Type_Enum | null, step_length?: any | null } }>, sold_articles: Array<{ __typename?: 'customer_answer_article_event', article_id: string }>, customer_answer: Array<{ __typename?: 'customer_answer', bicycle_type_id?: Answer_Enums_Bicycle_Type_Enum | null, article_groups?: any | null, step_length?: any | null }>, customer_answer_by_pk?: { __typename?: 'customer_answer', bicycle_type_id?: Answer_Enums_Bicycle_Type_Enum | null, step_length?: any | null } | null };

export type ManualMeasurementInputMutationVariables = Exact<{
  seatBoneDistance?: InputMaybe<Scalars['Int']>;
  bodyHeight?: InputMaybe<Scalars['float8']>;
  stepLength?: InputMaybe<Scalars['float8']>;
  shoulderWidth?: InputMaybe<Scalars['float8']>;
  armLength?: InputMaybe<Scalars['float8']>;
  handWidth?: InputMaybe<Scalars['float8']>;
  gripWidth?: InputMaybe<Scalars['float8']>;
  answerId?: InputMaybe<Scalars['String']>;
  shoeSize?: InputMaybe<Scalars['Int']>;
}>;


export type ManualMeasurementInputMutation = { __typename?: 'mutation_root', update_customer_answer?: { __typename?: 'customer_answer_mutation_response', returning: Array<{ __typename?: 'customer_answer', id: string }> } | null };

export type RecommendationSubscriptionVariables = Exact<{
  id: Scalars['String'];
}>;


export type RecommendationSubscription = { __typename?: 'subscription_root', recommendations: Array<{ __typename?: 'recommendation_own_articles', answer_id: string }> };

export type UpsertCustomerAnswerMutationVariables = Exact<{
  id: Scalars['String'];
  article_groups: Scalars['jsonb'];
  sex?: InputMaybe<Answer_Enums_Sex_Enum>;
  bicycle_type?: InputMaybe<Answer_Enums_Bicycle_Type_Enum>;
  e_bike?: InputMaybe<Scalars['Boolean']>;
  driving_frequency?: InputMaybe<Answer_Enums_Driving_Frequency_Enum>;
  driving_duration?: InputMaybe<Answer_Enums_Driving_Duration_Enum>;
  seat_position?: InputMaybe<Answer_Enums_Seat_Position_Enum>;
  weight?: InputMaybe<Scalars['Int']>;
  pelvic_problems: Array<Customer_Answer_Pelvic_Problems_Insert_Input> | Customer_Answer_Pelvic_Problems_Insert_Input;
  hand_problems: Array<Customer_Answer_Hand_Problems_Insert_Input> | Customer_Answer_Hand_Problems_Insert_Input;
  leg_axis?: InputMaybe<Answer_Enums_Leg_Axis_Enum>;
  foot_type?: InputMaybe<Answer_Enums_Foot_Type_Enum>;
  foot_position?: InputMaybe<Answer_Enums_Foot_Position_Enum>;
  foot_problems: Array<Customer_Answer_Foot_Problems_Insert_Input> | Customer_Answer_Foot_Problems_Insert_Input;
}>;


export type UpsertCustomerAnswerMutation = { __typename?: 'mutation_root', delete_customer_answer_foot_problems?: { __typename?: 'customer_answer_foot_problems_mutation_response', affected_rows: number } | null, delete_customer_answer_hand_problems?: { __typename?: 'customer_answer_hand_problems_mutation_response', affected_rows: number } | null, delete_customer_answer_pelvic_problems?: { __typename?: 'customer_answer_pelvic_problems_mutation_response', affected_rows: number } | null, insert_customer_answer_one?: { __typename?: 'customer_answer', id: string, short_id?: string | null } | null };

export type UserAuthorizationQueryVariables = Exact<{ [key: string]: never; }>;


export type UserAuthorizationQuery = { __typename?: 'query_root', answer: Array<{ __typename?: 'customer_answer', id: string }> };

export const QuestionnaireCreateResultFragmentDoc = gql`
    fragment QuestionnaireCreateResult on customer_answer {
  id
  short_id
}
    `;
export const CreateNewQuestionnaireDocument = gql`
    mutation CreateNewQuestionnaire {
  createAnswer {
    customer_answer {
      id
      short_id
    }
  }
}
    `;
export type CreateNewQuestionnaireMutationFn = Apollo.MutationFunction<CreateNewQuestionnaireMutation, CreateNewQuestionnaireMutationVariables>;

/**
 * __useCreateNewQuestionnaireMutation__
 *
 * To run a mutation, you first call `useCreateNewQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewQuestionnaireMutation, { data, loading, error }] = useCreateNewQuestionnaireMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateNewQuestionnaireMutation(baseOptions?: Apollo.MutationHookOptions<CreateNewQuestionnaireMutation, CreateNewQuestionnaireMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNewQuestionnaireMutation, CreateNewQuestionnaireMutationVariables>(CreateNewQuestionnaireDocument, options);
      }
export type CreateNewQuestionnaireMutationHookResult = ReturnType<typeof useCreateNewQuestionnaireMutation>;
export type CreateNewQuestionnaireMutationResult = Apollo.MutationResult<CreateNewQuestionnaireMutation>;
export type CreateNewQuestionnaireMutationOptions = Apollo.BaseMutationOptions<CreateNewQuestionnaireMutation, CreateNewQuestionnaireMutationVariables>;
export const CustomerAnswerDocument = gql`
    subscription CustomerAnswer($id: String!) {
  customerAnswer: customer_answer(where: {id: {_eq: $id}}) {
    articleGroups: article_groups
    bicycleTypeId: bicycle_type_id
    bodyHeight: body_height
  }
}
    `;

/**
 * __useCustomerAnswerSubscription__
 *
 * To run a query within a React component, call `useCustomerAnswerSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCustomerAnswerSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerAnswerSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerAnswerSubscription(baseOptions: Apollo.SubscriptionHookOptions<CustomerAnswerSubscription, CustomerAnswerSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CustomerAnswerSubscription, CustomerAnswerSubscriptionVariables>(CustomerAnswerDocument, options);
      }
export type CustomerAnswerSubscriptionHookResult = ReturnType<typeof useCustomerAnswerSubscription>;
export type CustomerAnswerSubscriptionResult = Apollo.SubscriptionResult<CustomerAnswerSubscription>;
export const DeleteQuestionnaireDocument = gql`
    mutation deleteQuestionnaire($id: String!) {
  delete_customer_answer_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteQuestionnaireMutationFn = Apollo.MutationFunction<DeleteQuestionnaireMutation, DeleteQuestionnaireMutationVariables>;

/**
 * __useDeleteQuestionnaireMutation__
 *
 * To run a mutation, you first call `useDeleteQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuestionnaireMutation, { data, loading, error }] = useDeleteQuestionnaireMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteQuestionnaireMutation(baseOptions?: Apollo.MutationHookOptions<DeleteQuestionnaireMutation, DeleteQuestionnaireMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteQuestionnaireMutation, DeleteQuestionnaireMutationVariables>(DeleteQuestionnaireDocument, options);
      }
export type DeleteQuestionnaireMutationHookResult = ReturnType<typeof useDeleteQuestionnaireMutation>;
export type DeleteQuestionnaireMutationResult = Apollo.MutationResult<DeleteQuestionnaireMutation>;
export type DeleteQuestionnaireMutationOptions = Apollo.BaseMutationOptions<DeleteQuestionnaireMutation, DeleteQuestionnaireMutationVariables>;
export const EvaluateCustomerAnswerDocument = gql`
    mutation EvaluateCustomerAnswer($customerAnswerId: String!) {
  evaluateResponse(customerAnswerId: $customerAnswerId) {
    success
  }
}
    `;
export type EvaluateCustomerAnswerMutationFn = Apollo.MutationFunction<EvaluateCustomerAnswerMutation, EvaluateCustomerAnswerMutationVariables>;

/**
 * __useEvaluateCustomerAnswerMutation__
 *
 * To run a mutation, you first call `useEvaluateCustomerAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEvaluateCustomerAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [evaluateCustomerAnswerMutation, { data, loading, error }] = useEvaluateCustomerAnswerMutation({
 *   variables: {
 *      customerAnswerId: // value for 'customerAnswerId'
 *   },
 * });
 */
export function useEvaluateCustomerAnswerMutation(baseOptions?: Apollo.MutationHookOptions<EvaluateCustomerAnswerMutation, EvaluateCustomerAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EvaluateCustomerAnswerMutation, EvaluateCustomerAnswerMutationVariables>(EvaluateCustomerAnswerDocument, options);
      }
export type EvaluateCustomerAnswerMutationHookResult = ReturnType<typeof useEvaluateCustomerAnswerMutation>;
export type EvaluateCustomerAnswerMutationResult = Apollo.MutationResult<EvaluateCustomerAnswerMutation>;
export type EvaluateCustomerAnswerMutationOptions = Apollo.BaseMutationOptions<EvaluateCustomerAnswerMutation, EvaluateCustomerAnswerMutationVariables>;
export const GetRecommendedProductsDocument = gql`
    query GetRecommendedProducts($answer_id: String!, $language: country_code_enum!) {
  recommendation_own_articles(
    where: {answer_id: {_eq: $answer_id}}
    order_by: {fitting: desc}
  ) {
    article_informaton_i18n(where: {language: {_eq: $language}}) {
      name
      brand
      description
      images
      price
      article_type
      article_id
      size
      shop_url
    }
    fitting
    reasons
    answer {
      bicycle_type_id
      step_length
    }
  }
  sold_articles: customer_answer_article_event(
    where: {_and: {answer_id: {_eq: $answer_id}, event_type: {_eq: PRODUCT_SOLD}}}
  ) {
    article_id
  }
  customer_answer(where: {id: {_eq: $answer_id}}) {
    bicycle_type_id
    article_groups
    step_length
  }
  customer_answer_by_pk(id: $answer_id) {
    bicycle_type_id
    step_length
  }
}
    `;

/**
 * __useGetRecommendedProductsQuery__
 *
 * To run a query within a React component, call `useGetRecommendedProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecommendedProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecommendedProductsQuery({
 *   variables: {
 *      answer_id: // value for 'answer_id'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetRecommendedProductsQuery(baseOptions: Apollo.QueryHookOptions<GetRecommendedProductsQuery, GetRecommendedProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecommendedProductsQuery, GetRecommendedProductsQueryVariables>(GetRecommendedProductsDocument, options);
      }
export function useGetRecommendedProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecommendedProductsQuery, GetRecommendedProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecommendedProductsQuery, GetRecommendedProductsQueryVariables>(GetRecommendedProductsDocument, options);
        }
export type GetRecommendedProductsQueryHookResult = ReturnType<typeof useGetRecommendedProductsQuery>;
export type GetRecommendedProductsLazyQueryHookResult = ReturnType<typeof useGetRecommendedProductsLazyQuery>;
export type GetRecommendedProductsQueryResult = Apollo.QueryResult<GetRecommendedProductsQuery, GetRecommendedProductsQueryVariables>;
export const ManualMeasurementInputDocument = gql`
    mutation ManualMeasurementInput($seatBoneDistance: Int, $bodyHeight: float8, $stepLength: float8, $shoulderWidth: float8, $armLength: float8, $handWidth: float8, $gripWidth: float8, $answerId: String, $shoeSize: Int) {
  update_customer_answer(
    _set: {seat_bone_distance_cm: $seatBoneDistance, body_height: $bodyHeight, step_length: $stepLength, shoulder_width_cm: $shoulderWidth, arm_length_cm: $armLength, hand_width_cm: $handWidth, grip_width_cm: $gripWidth, shoe_size: $shoeSize}
    where: {id: {_eq: $answerId}}
  ) {
    returning {
      id
    }
  }
}
    `;
export type ManualMeasurementInputMutationFn = Apollo.MutationFunction<ManualMeasurementInputMutation, ManualMeasurementInputMutationVariables>;

/**
 * __useManualMeasurementInputMutation__
 *
 * To run a mutation, you first call `useManualMeasurementInputMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManualMeasurementInputMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manualMeasurementInputMutation, { data, loading, error }] = useManualMeasurementInputMutation({
 *   variables: {
 *      seatBoneDistance: // value for 'seatBoneDistance'
 *      bodyHeight: // value for 'bodyHeight'
 *      stepLength: // value for 'stepLength'
 *      shoulderWidth: // value for 'shoulderWidth'
 *      armLength: // value for 'armLength'
 *      handWidth: // value for 'handWidth'
 *      gripWidth: // value for 'gripWidth'
 *      answerId: // value for 'answerId'
 *      shoeSize: // value for 'shoeSize'
 *   },
 * });
 */
export function useManualMeasurementInputMutation(baseOptions?: Apollo.MutationHookOptions<ManualMeasurementInputMutation, ManualMeasurementInputMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManualMeasurementInputMutation, ManualMeasurementInputMutationVariables>(ManualMeasurementInputDocument, options);
      }
export type ManualMeasurementInputMutationHookResult = ReturnType<typeof useManualMeasurementInputMutation>;
export type ManualMeasurementInputMutationResult = Apollo.MutationResult<ManualMeasurementInputMutation>;
export type ManualMeasurementInputMutationOptions = Apollo.BaseMutationOptions<ManualMeasurementInputMutation, ManualMeasurementInputMutationVariables>;
export const RecommendationDocument = gql`
    subscription Recommendation($id: String!) {
  recommendations: recommendation_own_articles(where: {answer_id: {_eq: $id}}) {
    answer_id
  }
}
    `;

/**
 * __useRecommendationSubscription__
 *
 * To run a query within a React component, call `useRecommendationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRecommendationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecommendationSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRecommendationSubscription(baseOptions: Apollo.SubscriptionHookOptions<RecommendationSubscription, RecommendationSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<RecommendationSubscription, RecommendationSubscriptionVariables>(RecommendationDocument, options);
      }
export type RecommendationSubscriptionHookResult = ReturnType<typeof useRecommendationSubscription>;
export type RecommendationSubscriptionResult = Apollo.SubscriptionResult<RecommendationSubscription>;
export const UpsertCustomerAnswerDocument = gql`
    mutation UpsertCustomerAnswer($id: String!, $article_groups: jsonb!, $sex: answer_enums_sex_enum, $bicycle_type: answer_enums_bicycle_type_enum, $e_bike: Boolean, $driving_frequency: answer_enums_driving_frequency_enum, $driving_duration: answer_enums_driving_duration_enum, $seat_position: answer_enums_seat_position_enum, $weight: Int, $pelvic_problems: [customer_answer_pelvic_problems_insert_input!]!, $hand_problems: [customer_answer_hand_problems_insert_input!]!, $leg_axis: answer_enums_leg_axis_enum, $foot_type: answer_enums_foot_type_enum, $foot_position: answer_enums_foot_position_enum, $foot_problems: [customer_answer_foot_problems_insert_input!]!) {
  delete_customer_answer_foot_problems(where: {answer_id: {_eq: $id}}) {
    affected_rows
  }
  delete_customer_answer_hand_problems(where: {answer_id: {_eq: $id}}) {
    affected_rows
  }
  delete_customer_answer_pelvic_problems(where: {answer_id: {_eq: $id}}) {
    affected_rows
  }
  insert_customer_answer_one(
    object: {id: $id, article_groups: $article_groups, sex_id: $sex, bicycle_type_id: $bicycle_type, e_bike_id: $e_bike, seat_position_id: $seat_position, driving_frequency_id: $driving_frequency, driving_duration_id: $driving_duration, weight: $weight, foot_type_id: $foot_type, leg_axis_id: $leg_axis, foot_position_id: $foot_position, pelvic_problems: {data: $pelvic_problems, on_conflict: {update_columns: [pelvic_problem_id], constraint: user_answer_pelvic_problems_pkey}}, hand_problems: {data: $hand_problems, on_conflict: {update_columns: [hand_problem_id], constraint: user_answer_hand_problems_pkey}}, foot_problems: {data: $foot_problems, on_conflict: {update_columns: [foot_problem_id], constraint: user_answer_foot_problems_pkey}}}
    on_conflict: {constraint: user_response_pkey, update_columns: [bicycle_type_id, e_bike_id, seat_position_id, driving_frequency_id, driving_duration_id, weight, sex_id, foot_type_id, leg_axis_id, foot_position_id, article_groups]}
  ) {
    id
    short_id
  }
}
    `;
export type UpsertCustomerAnswerMutationFn = Apollo.MutationFunction<UpsertCustomerAnswerMutation, UpsertCustomerAnswerMutationVariables>;

/**
 * __useUpsertCustomerAnswerMutation__
 *
 * To run a mutation, you first call `useUpsertCustomerAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertCustomerAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertCustomerAnswerMutation, { data, loading, error }] = useUpsertCustomerAnswerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      article_groups: // value for 'article_groups'
 *      sex: // value for 'sex'
 *      bicycle_type: // value for 'bicycle_type'
 *      e_bike: // value for 'e_bike'
 *      driving_frequency: // value for 'driving_frequency'
 *      driving_duration: // value for 'driving_duration'
 *      seat_position: // value for 'seat_position'
 *      weight: // value for 'weight'
 *      pelvic_problems: // value for 'pelvic_problems'
 *      hand_problems: // value for 'hand_problems'
 *      leg_axis: // value for 'leg_axis'
 *      foot_type: // value for 'foot_type'
 *      foot_position: // value for 'foot_position'
 *      foot_problems: // value for 'foot_problems'
 *   },
 * });
 */
export function useUpsertCustomerAnswerMutation(baseOptions?: Apollo.MutationHookOptions<UpsertCustomerAnswerMutation, UpsertCustomerAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertCustomerAnswerMutation, UpsertCustomerAnswerMutationVariables>(UpsertCustomerAnswerDocument, options);
      }
export type UpsertCustomerAnswerMutationHookResult = ReturnType<typeof useUpsertCustomerAnswerMutation>;
export type UpsertCustomerAnswerMutationResult = Apollo.MutationResult<UpsertCustomerAnswerMutation>;
export type UpsertCustomerAnswerMutationOptions = Apollo.BaseMutationOptions<UpsertCustomerAnswerMutation, UpsertCustomerAnswerMutationVariables>;
export const UserAuthorizationDocument = gql`
    query UserAuthorization {
  answer: customer_answer(limit: 1) {
    id
  }
}
    `;

/**
 * __useUserAuthorizationQuery__
 *
 * To run a query within a React component, call `useUserAuthorizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAuthorizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAuthorizationQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserAuthorizationQuery(baseOptions?: Apollo.QueryHookOptions<UserAuthorizationQuery, UserAuthorizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserAuthorizationQuery, UserAuthorizationQueryVariables>(UserAuthorizationDocument, options);
      }
export function useUserAuthorizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserAuthorizationQuery, UserAuthorizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserAuthorizationQuery, UserAuthorizationQueryVariables>(UserAuthorizationDocument, options);
        }
export type UserAuthorizationQueryHookResult = ReturnType<typeof useUserAuthorizationQuery>;
export type UserAuthorizationLazyQueryHookResult = ReturnType<typeof useUserAuthorizationLazyQuery>;
export type UserAuthorizationQueryResult = Apollo.QueryResult<UserAuthorizationQuery, UserAuthorizationQueryVariables>;